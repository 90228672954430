const typeSiz = [
  {
    'dependence': '1.1.1',
    'label': 'Обувь специальная для защиты от скольжения',
    'speciesSIZ':
      'Ботинки /  Сапоги /  Полуботинки /  Полусапоги /  Полуботинки с перфорацией',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(С ИЛИ Сж ИЛИ См)',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '1',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.1.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от скольжения',
    'speciesSIZ': 'Сапоги /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(С ИЛИ Сж ИЛИ См)',
    'markerBase': '2',
    'markerRubber': '1',
    'markerSlip': '1',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.2.1',
    'label': 'Средства индивидуальной защиты от падения с высоты',
    'speciesSIZ':
      'Пояс предохранительный, его составные части и комплектующие к нему; \nПривязи страховочные;\nПривязи спасательные;\nПривязи и стропы для удерживания и позиционирования;\nПривязи для положения сидя;\nСтропы (в том числе с амортизаторами);\nСоединительные элементы;\nАнкерные устройства;\nСредства защиты втягивающего типа;\nУстройство для спуска;\nУстройства для подъема;\nПетли спасательные;\nКанаты с сердечником низкого растяжения;\nКанаты страховочные;\nСредства защиты от падения с высоты ползункового типа на жесткой анкерной линии;\nСредства защиты от падения с высоты ползункового типа на гибкой анкерной линии',
    'issuanceRate': '1 шт на 120 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart':
      'Отдельные стандарты по каждому компоненту, указанному в нормах',
    'OperatingLevel': '',
    'markerBase': '70',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.3.1',
    'label':
      'Обувь специальная для защиты от механических воздействий (ударов в носочной части, проколов, порезов)',
    'speciesSIZ': 'Ботинки /  Сапоги /  Полуботинки /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel':
      ' с ударопрочным подноском 200 Дж (МУН200), с проколозащитной стелькой 1200 Н (Мп)',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.3.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов (сапоги, полусапоги) для защиты от механических воздействий (ударов в носочной части, проколов, порезов)',
    'speciesSIZ': 'Сапоги /  Полусапоги Наличие ударопрочного подноска 200 Дж',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel':
      ' с ударопрочным подноском 200 Дж (МУН200), с проколозащитной стелькой 1200 Н (Мп)',
    'markerBase': '2',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.3.1',
    'label':
      'Средства индивидуальной защиты головы от механических воздействий',
    'speciesSIZ': 'Каска защитная /  Каска шахтерская',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 397',
    'OperatingLevel': '',
    'markerBase': '17',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.3.1',
    'label': 'Средства индивидуальной защиты рук от механических воздействий',
    'speciesSIZ': 'Перчатки Наличие противоударных накладок',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 388:2016 ',
    'OperatingLevel': '(P) ИЛИ наличие противоударных накладок',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '1.4.1',
    'label':
      'Средства индивидуальной защиты головы от механических воздействий',
    'speciesSIZ': 'Каска защитная /  Каска шахтерская',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 397',
    'OperatingLevel': '',
    'markerBase': '17',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.4.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от механических воздействий',
    'speciesSIZ':
      'Очки защитные от механических воздействий, в том числе с покрытием от запотевания не менее 1F /  Очки защитные от механических воздействий с корригирующим эффектом',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.253-2013 (EN 166:2002)',
    'OperatingLevel': '',
    'markerBase': '69',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.4.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от механических воздействий',
    'speciesSIZ':
      'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart':
      'ГОСТ 12.4.253-2013 (EN 166:2002) или ГОСТ EN 1731-2014 (металлическая сетка)',
    'OperatingLevel': '',
    'markerBase': '54',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.5.1',
    'label':
      'Обувь специальная для защиты от механических воздействий (ударов в носочной части, проколов, порезов)',
    'speciesSIZ': 'Ботинки /  Сапоги /  Полуботинки /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel':
      ' с ударопрочным подноском 200 Дж (МУН200), с проколозащитной стелькой 1200 Н (Мп)',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.5.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от механических воздействий (ударов в носочной части, проколов, порезов)',
    'speciesSIZ': 'Сапоги /  Полусапоги Наличие ударопрочного подноска 200 Дж',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel':
      ' с ударопрочным подноском 200 Дж (МУН200), с проколозащитной стелькой 1200 Н (Мп)',
    'markerBase': '2',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.5.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от механических воздействий',
    'speciesSIZ':
      'Очки защитные от механических воздействий, в том числе с покрытием от запотевания не менее 1F /  Очки защитные от механических воздействий с корригирующим эффектом',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.253-2013 (EN 166:2002)',
    'OperatingLevel': '',
    'markerBase': '69',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.5.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от механических воздействий',
    'speciesSIZ':
      'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart':
      'ГОСТ 12.4.253-2013 (EN 166:2002) или ГОСТ EN 1731-2014 (металлическая сетка)',
    'OperatingLevel': '',
    'markerBase': '54',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.5.1',
    'label':
      'Средства индивидуальной защиты головы от механических воздействий',
    'speciesSIZ': 'Каска защитная /  Каска шахтерская',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 397',
    'OperatingLevel': '',
    'markerBase': '17',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.5.1',
    'label': 'Средства индивидуальной защиты рук от механических воздействий',
    'speciesSIZ': 'Перчатки Наличие противоударных накладок',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 388:2016 ',
    'OperatingLevel': '(P) ИЛИ наличие противоударных накладок',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '1.6.1',
    'label':
      'Одежда специальная для защиты от механических воздействий (порезов)',
    'speciesSIZ':
      'Костюм, в том числе отдельными предметами: куртка, брюки, полукомбинезон',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Фартук /  Комбинезон',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.280-2014 И/ИЛИ ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Мп)',
    'markerBase': '1',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.6.1',
    'label':
      'Обувь специальная для защиты от механических воздействий (ударов в носочной части, проколов, порезов)',
    'speciesSIZ': 'Ботинки /  Сапоги /  Полуботинки /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel':
      ' с ударопрочным подноском 200 Дж (МУН200), с проколозащитной стелькой 1200 Н (Мп)',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.6.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от механических воздействий (ударов в носочной части, проколов, порезов)',
    'speciesSIZ':
      'Сапоги /  Полусапоги Наличие ударопрочного подноска 200 Дж Наличие проколозащитной стельки 1200 Н',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel':
      ' с ударопрочным подноском 200 Дж (МУН200), с проколозащитной стелькой 1200 Н (Мп)',
    'markerBase': '2',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.6.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от механических воздействий (порезов)',
    'speciesSIZ': 'Рукавицы /  Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.010-75 ИЛИ EN 388:2016 ',
    'OperatingLevel':
      '(Мп), сопротивление порезу эксплуатационный уровень не ниже 3',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '1',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '1.6.1',
    'label':
      'Средства индивидуальной защиты головы от механических воздействий',
    'speciesSIZ': 'Каска защитная',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 397',
    'OperatingLevel': '',
    'markerBase': '17',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.6.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от механических воздействий',
    'speciesSIZ':
      'Очки защитные от механических воздействий, в том числе с покрытием от запотевания Не менее 1F /  Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки /  Очки защитные от механических воздействий с корригирующим эффектом',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart':
      'ГОСТ 12.4.253-2013 (EN 166:2002) или ГОСТ EN 1731-2014 (металлическая сетка)',
    'OperatingLevel': '',
    'markerBase': '69',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.7.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от механических воздействий',
    'speciesSIZ':
      'Очки защитные от механических воздействий, в том числе с покрытием от запотевания Не менее 1F /  Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки /  Очки защитные от механических воздействий с корригирующим эффектом',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart':
      'ГОСТ 12.4.253-2013 (EN 166:2002) или ГОСТ EN 1731-2014 (металлическая сетка)',
    'OperatingLevel': '',
    'markerBase': '69',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.7.1',
    'label':
      'Обувь специальная для защиты от механических воздействий (ударов в носочной части, проколов, порезов)',
    'speciesSIZ': 'Ботинки /  Сапоги /  Полуботинки /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel':
      ' с ударопрочным подноском 200 Дж (МУН200), с проколозащитной стелькой 1200 Н (Мп)',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.7.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от механических воздействий (ударов в носочной части, проколов, порезов)',
    'speciesSIZ': 'Сапоги /  Полусапоги Наличие ударопрочного подноска 200 Дж',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel':
      ' с ударопрочным подноском 200 Дж (МУН200), с проколозащитной стелькой 1200 Н (Мп)',
    'markerBase': '2',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.7.1',
    'label': 'Средства индивидуальной защиты рук от механических воздействий',
    'speciesSIZ': 'Перчатки Наличие противоударных накладок',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 388:2016 ',
    'OperatingLevel': 'ИЛИ наличие противоударных накладок',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '1.7.1',
    'label':
      'Средства индивидуальной защиты головы от механических воздействий',
    'speciesSIZ': 'Каска защитная /  Каскетка защитная (на 1 год)',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 397 ИЛИ ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '17',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.8.1',
    'label':
      'Средства индивидуальной защиты головы от механических воздействий',
    'speciesSIZ':
      'Каска защитная /  Каскетка защитная (на 1 год) /  Каска шахтерская',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 397 ИЛИ ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '17',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.8.1',
    'label':
      'Обувь специальная для защиты от механических воздействий (ударов в носочной части, проколов, порезов)',
    'speciesSIZ': 'Ботинки /  Сапоги /  Полуботинки /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel':
      ' с ударопрочным подноском 200 Дж (МУН200), с проколозащитной стелькой 1200 Н (Мп)',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.8.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от механических воздействий (ударов в носочной части, проколов, порезов)',
    'speciesSIZ': 'Сапоги /  Полусапоги Наличие ударопрочного подноска 200 Дж',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel':
      ' с ударопрочным подноском 200 Дж (МУН200), с проколозащитной стелькой 1200 Н (Мп)',
    'markerBase': '2',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.8.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от механических воздействий',
    'speciesSIZ':
      'Очки защитные от механических воздействий, в том числе с покрытием от запотевания Не менее 1F /  Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки /  Очки защитные от механических воздействий с корригирующим эффектом',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart':
      'ГОСТ 12.4.253-2013 (EN 166:2002) или ГОСТ EN 1731-2014 (металлическая сетка)',
    'OperatingLevel': '',
    'markerBase': '69',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.9.1.',
    'label': 'Другое 1.9.1',
    'speciesSIZ': 'СИЗ для Другое 1.9.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.10.1',
    'label':
      'Одежда специальная для защиты от механических воздействий (проколов)',
    'speciesSIZ':
      'Костюм, в том числе отдельными предметами: куртка, брюки, полукомбинезон',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Фартук /  Комбинезон',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.280-2014 И/ИЛИ ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Мп)',
    'markerBase': '1',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.10.1',
    'label':
      'Обувь специальная для защиты от механических воздействий (ударов в носочной части, проколов, порезов)',
    'speciesSIZ': 'Ботинки /  Сапоги /  Полуботинки /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel':
      ' с ударопрочным подноском 200 Дж (МУН200), с проколозащитной стелькой 1200 Н (Мп)',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.10.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от механических воздействий (ударов в носочной части, проколов, порезов)',
    'speciesSIZ': 'Сапоги /  Полусапоги Наличие проколозащитной стельки 1200Н',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel':
      ' с ударопрочным подноском 200 Дж (МУН200), с проколозащитной стелькой 1200 Н (Мп)',
    'markerBase': '2',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.10.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от механических воздействий (проколов, порезов)',
    'speciesSIZ': 'Рукавицы /  Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.010-75 ИЛИ EN 388:2016 ',
    'OperatingLevel':
      '(Мп), сопротивление порезу эксплуатационный уровень не ниже 3, стойкость к проколу \\эксплуатационный уровень не ниже 3',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '1',
    'markerGlovesPuncture': '1',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '1.10.1',
    'label':
      'Средства индивидуальной защиты головы от механических воздействий',
    'speciesSIZ': 'Каска защитная /  Каска шахтерская',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 397',
    'OperatingLevel': '',
    'markerBase': '17',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.10.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от механических воздействий',
    'speciesSIZ':
      'Очки защитные от механических воздействий, в том числе с покрытием от запотевания Не менее 1F /  Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки /  Очки защитные от механических воздействий с корригирующим эффектом',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart':
      'ГОСТ 12.4.253-2013 (EN 166:2002) или ГОСТ EN 1731-2014 (металлическая сетка)',
    'OperatingLevel': '',
    'markerBase': '69',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.11.1',
    'label': 'Другое 1.11.1',
    'speciesSIZ': 'СИЗ для Другое 1.11.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.12.1',
    'label':
      'Одежда специальная для защиты от возможного захвата движущимися частями механизма',
    'speciesSIZ':
      'Костюм, в том числе отдельными предметами: куртка, брюки, полукомбинезон',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans':
      'Нарукавники (срок определяется документами производителя) /  Комбинезон',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.280-2014 И/ИЛИ ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Мд)',
    'markerBase': '73',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.12.1',
    'label':
      'Средства индивидуальной защиты головы: головные уборы для защиты от общих производственных загрязнений и механических воздействий (истирания)',
    'speciesSIZ':
      'Подшлемник /  Шапочка /  Колпак /  Косынка /  Шапка /  Берет',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011, или ТР ТС 017/2011, или ГОСТ 12.4.280-2014',
    'OperatingLevel': '',
    'markerBase': '4',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.13.1',
    'label': 'Другое 1.13.1',
    'speciesSIZ': 'СИЗ для Другое 1.13.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.14.1',
    'label':
      'Одежда специальная для защиты от механических воздействий (истирания)',
    'speciesSIZ':
      'Костюм, в том числе отдельными предметами: куртка, брюки, полукомбинезон',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Фартук /  Комбинезон',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.280-2014 И/ИЛИ ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Ми)',
    'markerBase': '1',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.14.1',
    'label':
      'Обувь специальная для защиты от механических воздействий (истирания)',
    'speciesSIZ': 'Ботинки /  Сапоги /  Полуботинки /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Ми)',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.14.1',
    'label':
      'Обувь специальная резиновая или\nиз полимерных материалов для защиты от механических воздействий (истирания)',
    'speciesSIZ': 'Сапоги /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Ми)',
    'markerBase': '2',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.14.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от механических воздействий (истирания)',
    'speciesSIZ': 'Рукавицы /  Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.010-75 ИЛИ EN 388:2016 ',
    'OperatingLevel':
      '(Ми), стойкость к истиранию эксплуатационный уровень не менее 3',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '1',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '1.14.1',
    'label':
      'Средства индивидуальной защиты головы для защиты от механических воздействий (истирания и/или ударов)',
    'speciesSIZ': 'Каска защитная /  Каскетка защитная',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans':
      'Шлем пескоструйщика (абразивоструйщика); Сменные элементы (защитные стекла)',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'EN 397 ИЛИ ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '17',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.14.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от механических воздействий',
    'speciesSIZ':
      'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart':
      'ГОСТ 12.4.253-2013 (EN 166:2002) или ГОСТ EN 1731-2014 (металлическая сетка)',
    'OperatingLevel': '',
    'markerBase': '54',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.15.1',
    'label': 'Другое 1.15.1',
    'speciesSIZ': 'СИЗ для Другое 1.15.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.16.1',
    'label':
      'Одежда специальная для защиты от механических воздействий (проколов, порезов)',
    'speciesSIZ':
      'Костюм, в том числе отдельными предметами: куртка, брюки, полукомбинезон',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Фартук /  Комбинезон',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.280-2014 И/ИЛИ ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Мп)',
    'markerBase': '1',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.16.1',
    'label':
      'Обувь специальная для защиты от механических воздействий (проколов, порезов)',
    'speciesSIZ': 'Ботинки / Сапоги /  Полуботинки /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Мп); наличие проколозащитной стельки 1200 Н',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.16.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов (сапоги, полусапоги) для защиты от механических воздействий (проколов, порезов)',
    'speciesSIZ': 'Сапоги /  Полусапоги Наличие проколозащитной стельки 1200 Н',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Мп); наличие проколозащитной стельки 1200 Н',
    'markerBase': '2',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.16.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от механических воздействий (порезов)',
    'speciesSIZ': 'Рукавицы /  Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.010-75 ИЛИ EN 388:2016 ',
    'OperatingLevel':
      '(Мп), сопротивление порезу эксплуатационный уровень не ниже 3',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '1',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '1.16.1',
    'label':
      'Средства индивидуальной защиты головы от механических воздействий',
    'speciesSIZ': 'Каска защитная',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 397',
    'OperatingLevel': '',
    'markerBase': '17',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.16.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от механических воздействий',
    'speciesSIZ':
      'Очки защитные от механических воздействий, в том числе с покрытием от запотевания Не менее 1F /  Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки /  Очки защитные от механических воздействий с корригирующим эффектом',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart':
      'ГОСТ 12.4.253-2013 (EN 166:2002) или ГОСТ EN 1731-2014 (металлическая сетка)',
    'OperatingLevel': '',
    'markerBase': '69',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.17.1',
    'label':
      'Одежда специальная для защиты от механических воздействий (проколов, порезов)',
    'speciesSIZ':
      'Костюм, в том числе отдельными предметами: куртка, брюки, полукомбинезон',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Фартук /  Комбинезон',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.280-2014 И/ИЛИ ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Мп)',
    'markerBase': '1',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.17.1',
    'label':
      'Обувь специальная для защиты от механических воздействий (проколов, порезов)',
    'speciesSIZ': 'Ботинки /  Сапоги /  Полуботинки /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Мп); наличие проколозащитной стельки 1200 Н',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.17.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от механических воздействий (проколов, порезов)',
    'speciesSIZ': 'Сапоги /  Полусапоги Наличие проколозащитной стельки 1200 Н',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Мп); наличие проколозащитной стельки 1200 Н',
    'markerBase': '2',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.17.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от механических воздействий (порезов)',
    'speciesSIZ': 'Рукавицы /  Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.010-75 ИЛИ EN 388:2016 ',
    'OperatingLevel':
      '(Мп), сопротивление порезу эксплуатационный уровень не ниже 3',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '1',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '1.18.1',
    'label':
      'Одежда специальная для защиты от механических воздействий (проколов, порезов)',
    'speciesSIZ':
      'Костюм, в том числе отдельными предметами: куртка, брюки, полукомбинезон',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Фартук /  Комбинезон',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.280-2014 И/ИЛИ ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Мп)',
    'markerBase': '1',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.18.1',
    'label':
      'Обувь специальная для защиты от механических воздействий (проколов, порезов)',
    'speciesSIZ': 'Ботинки /  Сапоги /  Полуботинки /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Мп); наличие проколозащитной стельки 1200 Н',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.18.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов (сапоги, полусапоги) для защиты от механических воздействий (проколов, порезов)',
    'speciesSIZ': 'Сапоги /  Полусапоги Наличие проколозащитной стельки 1200 Н',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Мп); наличие проколозащитной стельки 1200 Н',
    'markerBase': '2',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.18.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от механических воздействии (порезов)',
    'speciesSIZ': 'Рукавицы /  Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.010-75 ИЛИ EN 388:2016 ',
    'OperatingLevel':
      '(Мп), сопротивление порезу эксплуатационный уровень не ниже 3',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '1',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '1.18.1',
    'label':
      'Средства индивидуальной защиты головы от механических воздействий',
    'speciesSIZ': 'Каска защитная',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 397',
    'OperatingLevel': '',
    'markerBase': '17',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.18.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от механических воздействий',
    'speciesSIZ':
      'Очки защитные от механических воздействий, в том числе с покрытием от запотевания Не менее 1F /  Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки /  Очки защитные от механических воздействий с корригирующим эффектом',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart':
      'ГОСТ 12.4.253-2013 (EN 166:2002) или ГОСТ EN 1731-2014 (металлическая сетка)',
    'OperatingLevel': '',
    'markerBase': '69',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.19.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от механических воздействий (порезов).',
    'speciesSIZ': 'Перчатки /  Защитные приспособления кольчужные',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans':
      'Перчатка; Фартук /  Разделенный фартук; Защитные брюки; Защитная куртка; Защитная поверхность',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'EN 388:2016',
    'OperatingLevel':
      '(Мп), сопротивление порезу эксплуатационный уровень не ниже 3',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '1',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '1.20.1',
    'label':
      'Одежда специальная для защиты от режущего воздействия ручной цепной пилой',
    'speciesSIZ':
      'Защитные приспособления (накидка, пелерина, съемный рукав, части брюк, щитки для голени и бедра, гетры) /  Костюм, в том числе отдельными предметами: куртка, брюки, полукомбинезон /  Комбинезон',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.277-2014 (EN 381-5:1995; EN 381-11:2002)',
    'OperatingLevel': '',
    'markerBase': '28',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.20.1',
    'label':
      'Обувь специальная для защиты от режущего воздействия ручной цепной пилой',
    'speciesSIZ': 'Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ Р 12.4.291-2013 (ИСО 17249:2004',
    'OperatingLevel': '',
    'markerBase': '28',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.20.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от режущего воздействия ручной цепной пилой',
    'speciesSIZ': 'Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ EN 381-1-2014',
    'OperatingLevel': 'ГОСТ 12.4.277-2014 (EN 381-5:1995; EN 381-11:2002)',
    'markerBase': '29',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '1.21.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от механических воздействий (порезов)',
    'speciesSIZ': 'Рукавицы /  Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.010-75 ИЛИ EN 388:2016 ',
    'OperatingLevel':
      '(Мп), сопротивление порезу эксплуатационный уровень не ниже 3',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '1',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '1.21.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от механических воздействий',
    'speciesSIZ':
      'Очки защитные от механических воздействий, в том числе с покрытием от запотевания не менее F1 /  Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart':
      'ГОСТ 12.4.253-2013 (EN 166:2002) или ГОСТ EN 1731-2014 (металлическая сетка)',
    'OperatingLevel': '',
    'markerBase': '69',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.22.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от механических воздействий (порезов)',
    'speciesSIZ': 'Рукавицы /  Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.010-75 ИЛИ EN 388:2016 ',
    'OperatingLevel':
      '(Мп), сопротивление порезу эксплуатационный уровень не ниже 3',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '1',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '1.23.1',
    'label': 'Другое 1.23.1',
    'speciesSIZ': 'СИЗ для Другое 1.23.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.24.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от механических воздействий',
    'speciesSIZ':
      'Очки защитные, в том числе с покрытием от запотевания /  Очки защитные от грубодисперсных аэрозолей (пыли) /  Щиток защитный лицевой, в том числе из металлической сетки /  Очки защитные от механических воздействий с корригирующим эффектом',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart':
      'ГОСТ 12.4.253-2013 (EN 166:2002) или ГОСТ EN 1731-2014 (металлическая сетка)',
    'OperatingLevel': '',
    'markerBase': '62',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.25.1',
    'label': 'Другое 1.25.1',
    'speciesSIZ': 'СИЗ для Другое 1.25.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.26.1',
    'label':
      'Одежда специальная для защиты от общих производственных загрязнений и механических воздействий',
    'speciesSIZ':
      'Костюм шахтерский: куртка, брюки, полукомбинезон, жилет утепленный, в том числе профилактический пояс',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Пояс для аккумуляторных ламп',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ГОСТ 12.4.280-2014 И/ИЛИ ГОСТ 12.4.103-2020',
    'OperatingLevel': '(З,  Ми)',
    'markerBase': '1',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.26.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от механических воздействий',
    'speciesSIZ':
      'Очки защитные, в том числе с покрытием от запотевания /  Очки защитные от грубодисперсных аэрозолей (пыли) /  Щиток защитный лицевой, в том числе из металлической сетки /  Очки защитные от механических воздействий с корригирующим эффектом',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart':
      'ГОСТ 12.4.253-2013 (EN 166:2002) или ГОСТ EN 1731-2014 (металлическая сетка)',
    'OperatingLevel': '',
    'markerBase': '62',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.26.1',
    'label':
      'Обувь специальная для защиты от механических воздействий (проколов, порезов)',
    'speciesSIZ': 'Ботинки /  Сапоги /  Полуботинки /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Мп); наличие проколозащитной стельки 1200 Н',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.26.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от механических воздействий (проколов, порезов)',
    'speciesSIZ': 'Сапоги /  Полусапоги Наличие проколозащитной стельки 1200 Н',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Мп); наличие проколозащитной стельки 1200 Н',
    'markerBase': '2',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '1',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.26.1',
    'label': 'Средства индивидуальной защиты головы',
    'speciesSIZ':
      'Головные уборы для защиты от общих производственных загрязнений и механических воздействий (истирания)',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Подшлемник',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ТР ТС 019/2011 и/или ТР ТС 017/2011',
    'OperatingLevel': '',
    'markerBase': '4',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.26.1',
    'label':
      'Средства индивидуальной защиты рук от общих производственных загрязнений и механических воздействий',
    'speciesSIZ': 'Рукавицы /  Перчатки',
    'issuanceRate': '24 пары на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 388:2016 ',
    'OperatingLevel':
      '(подтверждение защитных свойств в декларации или сертификате)',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '1.26.1',
    'label':
      'Средства индивидуальной защиты головы для защиты от механических воздействий',
    'speciesSIZ': 'Каска защитная /  Каска шахтерская',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 397',
    'OperatingLevel': '',
    'markerBase': '17',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.26.1',
    'label':
      'Средства индивидуальной защиты органов дыхания изолирующие на химически связанном кислороде',
    'speciesSIZ': 'Дыхательные аппараты /  Самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Респиратор /  Противогаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.26.1',
    'label':
      'Средства индивидуальной защиты органов дыхания изолирующие со сжатым воздухом',
    'speciesSIZ': 'Дыхательные аппараты /  Самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.26.1',
    'label':
      'Средства индивидуальной защиты органов дыхания изолирующие со сжатым кислородом',
    'speciesSIZ': 'Дыхательные аппараты /  Самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.26.1',
    'label':
      'Средства индивидуальной защиты органов дыхания фильтрующего типа противоаэрозольные, противогазоаэрозольные (комбинированные)',
    'speciesSIZ':
      'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски /  Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) для средств индивидуальной защиты /  Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': '',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.27.1',
    'label': 'Другое 1.27.1',
    'speciesSIZ': 'СИЗ для Другое 1.27.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '1.28.1',
    'label': 'Другое 1.28.1',
    'speciesSIZ': 'СИЗ для Другое 1.28.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '2.1.1',
    'label':
      'Одежда специальная для защиты от общих производственных загрязнений',
    'speciesSIZ':
      'Костюм, в том числе отдельными предметами: куртка, брюки, полукомбинезон',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans':
      'Комбинезон /  Фартук (1 шт.); Белье специальное (футболки, фуфайки, кальсоны, панталоны) (2 пары); Изделия носочно-чулочные (12 пар); Нарукавники (6 пар); Изделия бельевые: полотенца (определяется документами изготовителя)',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.280-2014 И/ИЛИ ГОСТ 12.4.103-2020',
    'OperatingLevel': '(З)',
    'markerBase': '1',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '2.1.1',
    'label':
      'Средства индивидуальной защиты рук от общих производственных загрязнений',
    'speciesSIZ': 'Рукавицы /  Перчатки',
    'issuanceRate': '24 пары на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 388:2016 ',
    'OperatingLevel':
      '(подтверждение защитных свойств в декларации или сертификате)',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '2.1.1',
    'label':
      'Средства индивидуальной защиты головы: головные уборы для защиты от общих производственных загрязнений и механических воздействий (истирания)',
    'speciesSIZ':
      'Подшлемник /  Шапочка /  Кепка /  Бейсболка /  Колпак /  Козырек /  Косынка /  Пилотка /  Шляпа /  Шапка /  Берет',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011, или ТР ТС 017/2011, или ГОСТ 12.4.280-2014',
    'OperatingLevel': '',
    'markerBase': '4',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '2.1.1',
    'label':
      'Обувь специальная для защиты от общих производственных загрязнений',
    'speciesSIZ': 'Полуботинки /  Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans':
      'Туфли /  Туфли ""сабо"" /  Туфли ""тапочки"" /  Сандалеты (полуботинки с перфорацией)',
    'AdditionalIssuanceRate': '1 пара на 12 мес',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '2.1.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов (сапоги, полусапоги) для защиты от общих производственных загрязнений',
    'speciesSIZ': 'Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans':
      'Обувь пляжная из полимерных материалов (тапочки для душа)',
    'AdditionalIssuanceRate': '1 пара на 36 мес',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '2',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '2.1.1',
    'label':
      'Дерматологические средства индивидуальной защиты защитного типа: средства гидрофобного действия',
    'speciesSIZ': 'Крем /  Лосьон ИЛ Гель /  Спрей',
    'issuanceRate': '1200 мл на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ Р 12.4.301-2018 ',
    'OperatingLevel': 'категория направленной эффективности',
    'markerBase': '71',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '2.1.1',
    'label':
      'Дерматологические средства индивидуальной защиты защитного типа: средства гидрофильного действия',
    'speciesSIZ': 'Крем /  Лосьон ИЛ Гель /  Спрей',
    'issuanceRate': '1200 мл на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ Р 12.4.301-2018 ',
    'OperatingLevel': 'категория направленной эффективности',
    'markerBase': '71',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '2.1.1',
    'label':
      'Дерматологические средства индивидуальной защиты защитного типа: средства комбинированного (универсального) действия',
    'speciesSIZ': 'Крем /  Лосьон ИЛ Гель /  Спрей',
    'issuanceRate': '1200 мл на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ Р 12.4.301-2018 ',
    'OperatingLevel': 'категория направленной эффективности',
    'markerBase': '71',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '2.1.1',
    'label':
      'ДСИЗ очищающего типа: средства для очищения от неустойчивых загрязнений',
    'speciesSIZ': 'Раствор /  Гель /  Пенка',
    'issuanceRate': '2400 мл на 12 мес',
    'additionalMeans':
      'ДСИЗ очищающего типа: средства для очищения от устойчивых загрязнений',
    'AdditionalIssuanceRate': '2400 мл на 12 мес',
    'standart': 'ГОСТ Р 12.4.301-2018 ',
    'OperatingLevel': 'категория направленной эффективности',
    'markerBase': '71',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '2.1.1',
    'label': 'ДСИЗ регенерирующего (восстанавливающего) типа',
    'speciesSIZ': 'Крем /  Лосьон /  Гель /  Спрей',
    'issuanceRate': '1200 мл на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ Р 12.4.301-2018 ',
    'OperatingLevel': 'с категорией направленной эффективности',
    'markerBase': '71',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '2.1.2',
    'label': 'Другое 2.1.2',
    'speciesSIZ': 'СИЗ для Другое 2.1.2',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.1.1',
    'label': 'Средства индивидуальной защиты рук диэлектрические',
    'speciesSIZ': 'Перчатки до 1000 В',
    'issuanceRate': '2 пары на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.307-2016',
    'OperatingLevel': 'класс 0',
    'markerBase': '34',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '3.1.1',
    'label':
      'Средства индивидуальной защиты головы от поражения электрическим током',
    'speciesSIZ': 'Каска защитная Электроизоляция 440 В или 1000 В',
    'issuanceRate': '1 на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 397',
    'OperatingLevel': '',
    'markerBase': '19',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.1.1',
    'label':
      'Обувь специальная диэлектрическая резиновая или из полимерных материалов',
    'speciesSIZ': 'Галоши /  Боты',
    'issuanceRate': 'дежурные: 1 пара на 36 мес',
    'additionalMeans': 'Коврик диэлектрический',
    'AdditionalIssuanceRate': 'дежурный: 1 шт. на 36 мес',
    'standart': 'ГОСТ 13385-78',
    'OperatingLevel': 'От поражения электрическим током Эв, класс 0',
    'markerBase': '35',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.1.2',
    'label': 'Средства индивидуальной защиты рук диэлектрические',
    'speciesSIZ': 'Перчатки свыше 1000 В',
    'issuanceRate': '2 пары на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.307-2016',
    'OperatingLevel': 'класс 0',
    'markerBase': '34',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '3.1.2',
    'label':
      'Средства индивидуальной защиты головы от поражения электрическим током',
    'speciesSIZ': 'Каска защитная Электроизоляция 1000 В',
    'issuanceRate': '1 на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 397',
    'OperatingLevel': '',
    'markerBase': '19',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.1.2',
    'label':
      'Обувь специальная диэлектрическая резиновая или из полимерных материалов',
    'speciesSIZ': 'Галоши /  Боты',
    'issuanceRate': 'дежурные: 1 пара на 36 мес',
    'additionalMeans': 'Коврик диэлектрический',
    'AdditionalIssuanceRate': 'дежурный: 1 шт. на 36 мес',
    'standart': 'ГОСТ 13385-78',
    'OperatingLevel': 'От поражения электрическим током Эв, класс 0',
    'markerBase': '35',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.2.1',
    'label':
      'Обувь специальная диэлектрическая резиновая или из полимерных материалов',
    'speciesSIZ': 'Галоши /  Боты',
    'issuanceRate': 'дежурные: 1 пара на 36 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': 'дежурный: 1 шт. на 36 мес',
    'standart': 'ГОСТ 13385-78',
    'OperatingLevel': 'От поражения электрическим током Эв, класс 0',
    'markerBase': '35',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.3.1',
    'label':
      'Одежда специальная для защиты от воздействия статического электричества',
    'speciesSIZ': 'Костюм',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Комбинезон',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.280-2014 И/ИЛИ ГОСТ 12.4.103-2020',
    'OperatingLevel': '(ЭС)',
    'markerBase': '5',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.3.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от воздействия статического электричества',
    'speciesSIZ': 'Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(ЭС)',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '3.3.1',
    'label': 'Обувь специальная для защиты от статического электричества',
    'speciesSIZ': 'Ботинки /  Полуботинки /  Сапоги /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans':
      'Браслет антиэлектростатический /  Кольцо антиэлектростатическое',
    'AdditionalIssuanceRate': '2 шт. на 2 мес',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Эс)',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.4.1',
    'label': 'Средства защиты от поражения током от наведенного напряжения',
    'speciesSIZ':
      'Комплект для защиты от воздействия электрических полей промышленной частоты и поражения электрическим током наведенного напряжения (1 комп. На 2 года) /  Перчатки экранирующие электропроводящие',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.283-2019',
    'OperatingLevel': '',
    'markerBase': '50',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.5.1',
    'label':
      'Одежда специальная для защиты от термических рисков электрической дуги',
    'speciesSIZ':
      'Костюм (по уровням защиты) 1 шт. на 2 года; Куртка-накидка 1 шт. на 2 года; Куртка-рубашка 1 шт.; Комбинезон 1 шт. на 2 года; Плащ 1 шт. на 3 года; Жилет повышенной видимости 2 класса защиты из огнестойких материалов 1 шт.',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ Р 12.4.234',
    'OperatingLevel': '',
    'markerBase': '23',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.5.1',
    'label': 'Белье специальное хлопчатобумажное',
    'speciesSIZ': 'Футболка; Кальсоны/панталоны',
    'issuanceRate': '2 компл. на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 31408 ИЛИ ГОСТ 31405',
    'OperatingLevel': '',
    'markerBase': '23',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.5.1',
    'label': 'Белье специальное термостойкое',
    'speciesSIZ': 'Футболка; Кальсоны/панталоны; Фуфайка-свитер',
    'issuanceRate': '2 компл. на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ Р 12.4.234 ИЛИ ГОСТ ISO 14116 ИЛИ ГОСТ ISO 11612',
    'OperatingLevel': '',
    'markerBase': '23',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.5.1',
    'label':
      'Обувь специальная для защиты от термических рисков электрической дуги',
    'speciesSIZ':
      'Ботинки /  Полусапоги /  Сапоги /  Сапоги резиновые термостойкие',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.032',
    'OperatingLevel':
      'отсутствие металлических элементов обуви, наличие термостойких шнурков',
    'markerBase': '26',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.5.1',
    'label':
      'Средства индивидуальной защиты головы для защиты от повышенных температур',
    'speciesSIZ': 'Подшлемник термостойкий',
    'issuanceRate': '2 шт. на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ Р 12.4.234 ИЛИ ГОСТ ISO 14116 ИЛИ ГОСТ ISO 11612',
    'OperatingLevel': '(Тр, То, Тт, Ти, ЗЭТВ)',
    'markerBase': '32',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.5.1',
    'label':
      'Средства индивидуальной защиты головы для защиты от повышенных температур и поражения электрическим током',
    'speciesSIZ': 'Каска термостойкая защитная',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ EN 397',
    'OperatingLevel': 'Температурный режим:до +150 °С',
    'markerBase': '18',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.5.1',
    'label':
      'Средства индивидуальной защиты лица от термических рисков электрической дуги',
    'speciesSIZ': 'Щиток защитный лицевой с термостойкой окантовкой',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.023 или ГОСТ 12.4.253',
    'OperatingLevel': '',
    'markerBase': '42',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '3.5.1',
    'label': 'Средства индивидуальной защиты рук термостойкие',
    'speciesSIZ': 'Перчатки термостойкие',
    'issuanceRate': '6 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ Р 12.4.234 ИЛИ ГОСТ ISO 14116',
    'OperatingLevel': '(ЗЭТВ)',
    'markerBase': '48',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '3.6.1',
    'label': 'Другое 3.6.1',
    'speciesSIZ': 'СИЗ для Другое 3.6.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.1.1',
    'label': 'Одежда специальная для защиты от конвективной теплоты',
    'speciesSIZ': 'Костюм',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Комбинезон',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ Р 12.4.297 ИЛИ ГОСТ ISO 11612 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel':
      '(Тт),  эксплуатационный уровень (защита от конвективной теплоты) не ниже B1',
    'markerBase': '22',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.1.1',
    'label':
      'Обувь специальная для защиты от контакта с нагретыми поверхностями',
    'speciesSIZ': 'Полуботинки /  Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.032 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel':
      '(Тп), при контакте с поверхностью до (300 ± 5)°С в течение (60 ± 1) с, с термостойкой подошвой',
    'markerBase': '27',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.1.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от конвективной теплоты',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники для защиты от конвективной теплоты',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ EN 407 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel':
      'эксплуатационный уровень  не ниже 1 ИЛИ Защита от контакта с нагретыми поверхностями (Тп100, Тп400, Тв)',
    'markerBase': '43',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '4.1.1',
    'label':
      'Средства индивидуальной защиты головы: головные уборы для защиты от конвективной теплоты',
    'speciesSIZ': 'Подшлемник',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': 'Шлем /  Шапка',
    'AdditionalIssuanceRate': '1 шт. на 24 мес',
    'standart': 'ГОСТ 12.4.250-2013',
    'OperatingLevel': '(Тт)',
    'markerBase': '33',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.1.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от конвективной теплоты',
    'speciesSIZ': 'Щиток защитный лицевой',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.023 или ГОСТ 12.4.253',
    'OperatingLevel': 'ГОСТ 12.4.254-2013',
    'markerBase': '55',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.2.1',
    'label': 'Одежда специальная для защиты от конвективной теплоты',
    'speciesSIZ': 'Костюм',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Комбинезон',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ Р 12.4.297 ИЛИ ГОСТ ISO 11612 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel':
      '(Тт),  эксплуатационный уровень (защита от конвективной теплоты) не ниже B1',
    'markerBase': '22',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.2.1',
    'label':
      'Обувь специальная для защиты от контакта с нагретыми поверхностями',
    'speciesSIZ': 'Полуботинки /  Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.032 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel':
      '(Тп), при контакте с поверхностью до (300 ± 5)°С в течение (60 ± 1) с, с термостойкой подошвой',
    'markerBase': '27',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.2.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от конвективной теплоты',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники для защиты от конвективной теплоты',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ EN 407',
    'OperatingLevel': 'эксплуатационный уровень не ниже 1',
    'markerBase': '43',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '4.2.1',
    'label':
      'Средства индивидуальной защиты головы для защиты от повышенных температур',
    'speciesSIZ': 'Каска защитная до +150 °C',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans':
      'Головной убор для защиты от конвективной теплоты: подшлемник, шлем',
    'AdditionalIssuanceRate': '1 шт. на 24 мес',
    'standart': 'ГОСТ EN 397',
    'OperatingLevel': 'Температурный режим:до +150 °С',
    'markerBase': '18',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.2.1',
    'label': 'Средства индивидуальной защиты лица от брызг жидкостей',
    'speciesSIZ': 'Щиток защитный',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.023 или ГОСТ 12.4.253',
    'OperatingLevel': '',
    'markerBase': '71',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.2.1',
    'label':
      'Средства индивидуальной защиты органов дыхания фильтрующего типа противоаэрозольные, противогазоаэрозольные (комбинированные)',
    'speciesSIZ':
      'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски /  Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) для средств индивидуальной защиты /  Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха /  Фильтрующие самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Респиратор /  Противогаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart':
      'ГОСТ 12.4.294 ИЛИ ГОСТ 12.4.293 и ГОСТ 12.4.235 ИЛИ ГОСТ 12.4.234 (EN 12941:1998) ИЛИ ГОСТ 12.4.285',
    'OperatingLevel':
      'не ниже FFP1 ИЛИ изолирующая лицевая часть и фильтры не ниже 1-го класса защиты',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.3.1',
    'label': 'Другое 4.3.1',
    'speciesSIZ': 'СИЗ для Другое 4.3.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.4.1',
    'label':
      'Одежда специальная для защиты от кратковременного воздействия открытого пламени',
    'speciesSIZ': 'Костюм',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Комбинезон',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ Р 12.4.297 ИЛИ ГОСТ ISO 11612 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel': '(То), эксплуатационный уровень огнестойкости A1 или A2 ',
    'markerBase': '22',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.4.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от кратковременного воздействия открытого пламени',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ EN 407 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel': '(То), огнестойкость не ниже 1',
    'markerBase': '43',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '4.4.1',
    'label':
      'Обувь специальная для защиты от кратковременного воздействия открытого пламени и от контакта с нагретыми поверхностями',
    'speciesSIZ': 'Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.032 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel':
      '(То), от кратковременного воздействия открытого плавмени',
    'markerBase': '27',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.4.1',
    'label':
      'Средства индивидуальной защиты головы: головной убор для защиты от кратковременного воздействия открытого пламени',
    'speciesSIZ': 'Подшлемник',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': 'Шлем /  Шапка',
    'AdditionalIssuanceRate': '1 шт. на 24 мес',
    'standart': '',
    'OperatingLevel': '(То)',
    'markerBase': '33',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.4.1',
    'label':
      'Средства индивидуальной защиты головы для защиты от повышенных температур',
    'speciesSIZ': 'Каска защитная +150 °C',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ EN 397',
    'OperatingLevel': 'Температурный режим:до +150 °С',
    'markerBase': '18',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.4.1',
    'label': 'Средства индивидуальной защиты лица от теплового излучения',
    'speciesSIZ': 'Щиток защитный лицевой',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.023 или ГОСТ 12.4.253',
    'OperatingLevel': '',
    'markerBase': '55',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.4.2',
    'label': 'Средства индивидуальной защиты лица от теплового излучения',
    'speciesSIZ': 'Очки защитные /  Щиток защитный',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.023 или ГОСТ 12.4.253',
    'OperatingLevel': '',
    'markerBase': '55',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.4.3',
    'label':
      'Одежда специальная для защиты от искр и брызг расплавленного металла, окалины',
    'speciesSIZ': 'Костюм по классам защиты',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans':
      'Комбинезон по классам защиты /  Фартук для защиты от искр и брызг расплавленного металла, окалины',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.250 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel': '(Тр), не ниже 1-го класса защиты',
    'markerBase': '24',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.4.3',
    'label':
      'Обувь специальная для защиты искр и брызг расплавленного металла, окалины',
    'speciesSIZ': 'Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.032 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel':
      '(Тр), с защитными съемными или несъемными приспособлениями, подошвой из термостойкой резины',
    'markerBase': '25',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.4.3',
    'label':
      'Средства индивидуальной защиты рук для защиты от искр и брызг расплавленного металла, окалины',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ EN 407 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel': '(Тр), эксплуатационный уровень не ниже 1',
    'markerBase': '36',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '4.4.3',
    'label':
      'Средства индивидуальной защиты головы: головной убор для защиты от искр и брызг расплавленного металла, окалины',
    'speciesSIZ': 'Подшлемник',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Пелерина /  Шлем',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': '',
    'OperatingLevel': '(Тр)',
    'markerBase': '31',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.4.3',
    'label':
      'Средства индивидуальной защиты глаз и лица от брызг расплавленного металла и горячих частиц',
    'speciesSIZ':
      'Очки защитные /  Щиток защитный в том числе с автоматическим светофильтром',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.253 ИЛИ ГОСТ 12.4.023 ИЛИ ГОСТ 12.4.254',
    'OperatingLevel': '',
    'markerBase': '56',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.4.4',
    'label': 'Одежда для защиты от выплесков расплавленного металла',
    'speciesSIZ': 'Костюм /  Плащ',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans':
      'Комбинезон /  Плащ /  Пелерина для защиты от выплесков расплавленного металла',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ Р 12.4.297 ИЛИ ГОСТ ISO 11612 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel':
      '(Тм),  эксплуатационные уровни - не ниже D1 (выплеск расплавленного алюминия),  не ниже E1 (выплеск расплавленного железа)',
    'markerBase': '22',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.4.4',
    'label':
      'Средства индивидуальной защиты рук от выплесков расплавленного металла',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans':
      'Нарукавники для защиты от выплесков расплавленного металла',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ EN 407',
    'OperatingLevel': 'эксплуатационный уровень не ниже 1',
    'markerBase': '43',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '4.4.4',
    'label':
      'Обувь специальная для защиты от контакта с нагретыми поверхностями',
    'speciesSIZ': 'Полуботинки /  Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.032 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel':
      '(Тп), при контакте с поверхностью до (300 ± 5)°С в течение (60 ± 1) с, с термостойкой подошвой',
    'markerBase': '27',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.4.4',
    'label':
      'Средства индивидуальной защиты головы для защиты от повышенных температур',
    'speciesSIZ': 'Каска защитная +150 °C',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ EN 397',
    'OperatingLevel': 'Температурный режим:до +150 °С',
    'markerBase': '18',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.4.4',
    'label':
      'Средства индивидуальной защиты глаз и лица от брызг расплавленного металла и горячих частиц',
    'speciesSIZ':
      'Очки защитные /  Щиток защитный в том числе с автоматическим светофильтром',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.253 ИЛИ ГОСТ 12.4.023 ИЛИ ГОСТ 12.4.254',
    'OperatingLevel': '',
    'markerBase': '56',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.4.5',
    'label':
      'В случае идентификации иных опасностей на рабочем месте работник обеспечивается средствами индивидуальной защиты, необходимыми для защиты от идентифицированных опасностей. Номенклатуру, количество и сроки носки определяет работодатель в соответствии с Правилами и на основании нормативно-технической документации изготовителя',
    'speciesSIZ': 'СИЗ для Другое 4.4.5',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.5.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от контакта с охлажденными поверхностями',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020 ИЛИ ГОСТ EN 511-2012',
    'OperatingLevel': '(Тн)',
    'markerBase': '74',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '4.5.1',
    'label':
      'Средства индивидуальной защиты ног для защиты от контакта с охлажденными поверхностями',
    'speciesSIZ': 'СИЗ',
    'issuanceRate': '',
    'additionalMeans': 'Наколенники',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': '',
    'OperatingLevel': '',
    'markerBase': '76',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.5.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов) для защиты от воды',
    'speciesSIZ': 'Галоши /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': 'водонепроницаемая (В) ',
    'markerBase': '8',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.5.1',
    'label':
      'Обувь специальная для защиты от воды и растворов нетоксичных веществ',
    'speciesSIZ': 'Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '',
    'markerBase': '8',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.6.1',
    'label': 'Другое 4.6.1',
    'speciesSIZ': 'СИЗ для Другое 4.6.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.7.1',
    'label':
      'Одежда специальная для защиты от пониженных температур, пониженных температур и ветра',
    'speciesSIZ':
      'Костюм/комплект (в том числе отдельными предметами: куртка, брюки, полукомбинезон, жилет)',
    'issuanceRate': '1 комп. на 24 мес',
    'additionalMeans':
      'Комбинезон /  Белье специальное утепленное (кальсоны/панталоны утепленные, фуфайка, утепленная) 2 шт. /  Термобелье специальное (кальсоны/панталоны, фуфайка) 2 шт.; Изделия носочно-чулочные 12 пар',
    'AdditionalIssuanceRate': '1 комп. на 24 мес',
    'standart': 'ГОСТ 12.4.303-2016 ',
    'OperatingLevel':
      '(Тнв), для III климатического пояса, не ниже 3-го класса защиты',
    'markerBase': '77',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '1',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.7.1',
    'label': 'Обувь специальная для защиты от пониженных температур',
    'speciesSIZ': 'Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 18 мес',
    'additionalMeans': 'Обувь валяная /  Унты (сапоги) меховые',
    'AdditionalIssuanceRate': '1 пара на 36 мес',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': 'для III климатического пояса, до минус 40°С (Тн40)',
    'markerBase': '76',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '1',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.7.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от пониженных температур',
    'speciesSIZ': 'Перчатки /  Рукавицы по климатическим поясам',
    'issuanceRate': '4 пары на 12 мес',
    'additionalMeans': 'Рукавицы меховые',
    'AdditionalIssuanceRate': '1 пара на 24 мес',
    'standart': 'ГОСТ 12.4.103-2020 ИЛИ ГОСТ EN 511-2012',
    'OperatingLevel': '(Тн), для III климатического пояса',
    'markerBase': '74',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '4.7.1',
    'label':
      'Средства индивидуальной защиты головы: головной убор для защиты от пониженных температур',
    'speciesSIZ': 'Подшлемник по климатическим поясам',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': 'Подшлемник меховой /  Шапка /  Шлем',
    'AdditionalIssuanceRate': '1 шт. на 36 мес',
    'standart': '',
    'OperatingLevel': 'для III климатического пояса',
    'markerBase': '78',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.8.1',
    'label':
      'Одежда специальная для защиты от общих производственных загрязнений',
    'speciesSIZ':
      'Костюм (в том числе отдельными предметами: куртка, брюки, полукомбинезон) облегченные',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans':
      'Костюм (в том числе отдельными предметами: рубашка, сорочка, юбка) /  Комбинезон /  Халат облегченные\n1 шт.',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(З)',
    'markerBase': '1',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.8.1',
    'label':
      'Обувь специальная для защиты от общих производственных загрязнений',
    'speciesSIZ':
      'Полуботинки (в том числе с перфорационными отверстиями) /  Полуботинки',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': 'Туфли (сабо)',
    'AdditionalIssuanceRate': '1 пара на 12 мес',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.8.1',
    'label':
      'Средства индивидуальной защиты головы: головной убор для защиты от общих производственных загрязнений',
    'speciesSIZ':
      'Кепка /  Бейсболка /  Колпак /  Козырек /  Косынка /  Пилотка',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011, или ТР ТС 017/2011, или ГОСТ 12.4.280-2014',
    'OperatingLevel': '',
    'markerBase': '4',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.8.2',
    'label': 'Одежда для защиты от теплового излучения',
    'speciesSIZ': 'Костюм /  Плащ',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Комбинезон',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ Р 12.4.297 ИЛИ ГОСТ ISO 11612 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel': '(Ти), эксплуатационный уровень не ниже C1',
    'markerBase': '22',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.8.2',
    'label':
      'Средства индивидуальной защиты рук для защиты от теплового излучения',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники для защиты от теплового излучения',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ EN 407 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel':
      '(Ти), тепловое излучение не ниже 1 эксплуатационного уровня',
    'markerBase': '43',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '4.8.2',
    'label':
      'Обувь специальная для защиты от контакта с нагретыми поверхностями',
    'speciesSIZ': 'Полуботинки /  Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.032 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel':
      '(Тп), при контакте с поверхностью до (300 ± 5)°С в течение (60 ± 1) с, с термостойкой подошвой',
    'markerBase': '27',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.8.2',
    'label': 'Головной убор для защиты от теплового излучения',
    'speciesSIZ': 'Шлем',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Подшлемник /  Шапка',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': '',
    'OperatingLevel': '(Ти), эксплуатационный уровень не ниже С1',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.8.2',
    'label': 'Средства индивидуальной защиты лица от теплового излучения',
    'speciesSIZ': 'Щиток',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.023 или ГОСТ 12.4.253',
    'OperatingLevel': '',
    'markerBase': '55',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.9.1',
    'label': 'Одежда специальная для защиты от воды',
    'speciesSIZ':
      'Пальто /  полупальто /  плащ /  Костюм, в том числе отдельными предметами: куртка, брюки, полукомбинезон по классам защиты',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans':
      'Ветровка штормовка /  куртка-накидка /  Ветровка штормовка или куртка-накидка /  Комбинезон по классам защиты',
    'AdditionalIssuanceRate': '1 шт. на 24 мес',
    'standart': 'ГОСТ Р 12.4.288-2013 ',
    'OperatingLevel': ' (Во), не менее 1 класса защиты',
    'markerBase': '7',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.9.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от воды и растворов нетоксичных веществ',
    'speciesSIZ': 'Перчатки /  Рукавицы водонепроницаемые',
    'issuanceRate': '6 пар на 12 мес',
    'additionalMeans': 'Нарукавники',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ГОСТ ISO 374-1-2019',
    'OperatingLevel': '(Вн)',
    'markerBase': '9',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '4.9.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от воды',
    'speciesSIZ': 'Галоши /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': 'водонепроницаемая (В) ',
    'markerBase': '8',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.9.1',
    'label':
      'Обувь специальная для защиты от воды и растворов нетоксичных веществ',
    'speciesSIZ': 'Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '',
    'markerBase': '8',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.10.1',
    'label': 'Одежда специальная для защиты от пониженных температур и ветра',
    'speciesSIZ':
      'Костюм (в том числе отдельными предметами: куртка, брюки, полукомбинезон, жилет) по климатическим поясам и классам защиты',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans':
      'Комбинезон /  Одежда специальная для защиты от прохладной окружающей среды и ветра /  Костюм (в том числе отдельными предметами: куртка, брюки, полукомбинезон, жилет)',
    'AdditionalIssuanceRate': '1 шт. на 24 мес',
    'standart': 'ГОСТ 12.4.303-2016 ',
    'OperatingLevel':
      '(Тнв), для III климатического пояса, не ниже 3-го класса защиты',
    'markerBase': '77',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '1',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.10.1',
    'label':
      'Средства индивидуальной защиты головы: головной убор для защиты от пониженных температур',
    'speciesSIZ': 'Подшлемник по климатическим поясам',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': 'Шапка /  Шлем по климатическим поясам 1 шт.',
    'AdditionalIssuanceRate': '1 шт. на 24 мес',
    'standart': '',
    'OperatingLevel': 'для III климатического пояса',
    'markerBase': '78',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.10.1',
    'label':
      'Дерматологические средства индивидуальной защиты защитного типа средства при негативном влиянии окружающей среды от воздействия низких температур, ветра',
    'speciesSIZ': 'Крем /  Лосьон /  Гель /  Спрей',
    'issuanceRate': '600 мл на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ Р 12.4.301-2018 ',
    'OperatingLevel': 'категория направленной эффективности',
    'markerBase': '71',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.10.1',
    'label':
      'Дерматологические средства индивидуальной защиты регенерирующего (восстанавливающего) типа',
    'speciesSIZ': 'Крем /  Лосьон /  Гель /  Спрей',
    'issuanceRate': '600 мл на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ Р 12.4.301-2018 ',
    'OperatingLevel': 'категория направленной эффективности',
    'markerBase': '71',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '4.10.2',
    'label':
      'В случае идентификации иных опасностей на рабочем месте работник обеспечивается средствами индивидуальной защиты, необходимыми для защиты от идентифицированных опасностей. Номенклатуру, количество и сроки носки определяет работодатель в соответствии с Правилами и на основании нормативно-технической документации изготовителя',
    'speciesSIZ': 'СИЗ для Другое 4.10.2',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '5.1.1',
    'label':
      'Средства индивидуальной защиты органов дыхания изолирующие на химически связанном кислороде ИЛИ Средства индивидуальной защиты органов дыхания изолирующие со сжатым воздухом ИЛИ Средства индивидуальной защиты органов дыхания со сжатым кислородом ИЛИ Средства индивидуальной защиты органов дыхания изолирующие неавтономные с подачей чистого воздуха по шлангу/магистрали',
    'speciesSIZ': 'Дыхательные аппараты /  Самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '5.2.2',
    'label':
      'Средства индивидуальной защиты органов изолирующие дыхания на химически связанном кислороде ИЛИ Средства индивидуальной защиты органов дыхания изолирующие со сжатым воздухом ИЛИ Средства индивидуальной защиты органов дыхания изолирующие со сжатым кислородом ИЛИ Средства индивидуальной защиты органов дыхания неавтономные с подачей чистого воздуха по шлангу/магистрали',
    'speciesSIZ': 'Дыхательные аппараты /  Самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '5.3.1',
    'label': 'Другое 5.3.1',
    'speciesSIZ': 'СИЗ для Другое 5.3.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.1.1',
    'label':
      'Одежда специальная для защиты от воды и растворов нетоксичных веществ',
    'speciesSIZ': 'Фартук /  Костюм',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Гидрокостюмы для работников морских платформ',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ГОСТ Р 12.4.288-2013 ',
    'OperatingLevel': ' (Во), не менее 1 класса защиты',
    'markerBase': '7',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.1.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от воды и растворов нетоксичных веществ',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ EN374-1-2019',
    'OperatingLevel': 'водонепроницаемая (ВН) ИЛИ  Тип С и выше',
    'markerBase': '9',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '6.1.1',
    'label':
      'Обувь специальная для защиты от воды и растворов нетоксичных веществ',
    'speciesSIZ': 'Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '',
    'markerBase': '8',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.1.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от химических факторов',
    'speciesSIZ':
      'Очки защитные, в том числе с покрытием от запотевания /  Очки защитные от грубодисперсных аэрозолей (пыли) /  Щиток защитный лицевой /  Очки защитные от механических воздействий с корригирующим эффектом',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.023 или ГОСТ 12.4.253',
    'OperatingLevel':
      'с наружным покрытием линз для защиты от химикатов, с защитой от среднеэнергетического удара (120 м/с) – B; с механической прочностью не ниже S,  с сопротивлением поверхностному разрушению мелкодисперсными аэрозолями – K, с устойчивостью к запотеванию – N, с оптическим классом 1.',
    'markerBase': '62',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.1.2',
    'label':
      'В случае идентификации иных опасностей на рабочем месте работник обеспечивается средствами индивидуальной защиты, необходимыми для защиты от идентифицированных опасностей. Номенклатуру, количество и сроки носки определяет работодатель в соответствии с Правилами и на основании нормативно-технической документации изготовителя',
    'speciesSIZ': 'СИЗ для Другое 6.1.2',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.2.1',
    'label':
      'Одежда специальная изолирующая для защиты от химических факторов (в том числе применяемая для защиты от биологических факторов (микроорганизмов)',
    'speciesSIZ': 'Костюм изолирующий /  Комбинезон',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ ISO 16602-2019',
    'OperatingLevel': '(К, Щ)',
    'markerBase': '60',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.2.1',
    'label':
      'Одежда специальная изолирующая для защиты от химических факторов (в том числе применяемый для защиты от биологических факторов (микроорганизмов) с принудительной подачей воздуха',
    'speciesSIZ': 'Комбинезон изолирующий с принудительной подачей воздуха',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ ISO 16602-2019',
    'OperatingLevel': '(К, Щ)',
    'markerBase': '60',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.2.1',
    'label': 'Одежда специальная для ограниченной защиты от токсичных веществ',
    'speciesSIZ': 'Комбинезон',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.101-93, ГОСТ 12.4.258-2014',
    'OperatingLevel': '',
    'markerBase': '58',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.2.1',
    'label':
      'Одежда специальная ограниченного срока использования для защиты от токсичных веществ',
    'speciesSIZ': 'Комбинезон',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.101-93, ГОСТ 12.4.258-2014',
    'OperatingLevel': '',
    'markerBase': '58',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.2.1',
    'label':
      'Средства индивидуальной защиты рук от биологических факторов (микроорганизмов)',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ ISO 374-5-2020',
    'OperatingLevel': '(Бм)',
    'markerBase': '75',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '6.2.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от кислот',
    'speciesSIZ': 'Сапоги /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': 'эксплуатационный уровень не менее К20',
    'markerBase': '15',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.2.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от щелочей',
    'speciesSIZ': 'Сапоги /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': 'эксплуатационный уровень не менее Щ20',
    'markerBase': '15',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.2.1',
    'label':
      'Средства индивидуальной защиты органов дыхания фильтрующего типа противоаэрозольные',
    'speciesSIZ':
      'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Респиратор /  Противогаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ГОСТ 12.4.294-2015\n(EN 149:2001+A1:2009); ГОСТ Р 12.4.191-99',
    'OperatingLevel': 'обеспечивает защиту FFP',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.2.1',
    'label':
      'Средства индивидуальной защиты органов дыхания фильтрующего типа Противогазоаэрозольные (комбинированные)',
    'speciesSIZ':
      'Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) для средств индивидуальной защиты',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.244-2013\n(EN 149:2001+A1:2009)',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.2.1',
    'label':
      'Средства индивидуальной защиты органов дыхания изолирующие с принудительной подачей воздуха',
    'speciesSIZ': 'Дыхательные аппараты /  Самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.2.1',
    'label':
      'Средства индивидуальной защиты органов изолирующие дыхания на химически связанном кислороде',
    'speciesSIZ': 'Дыхательные аппараты /  Самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.2.1',
    'label':
      'Средства индивидуальной защиты органов дыхания изолирующие со сжатым воздухом',
    'speciesSIZ': 'Дыхательные аппараты /  Самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.2.1',
    'label':
      'Средства индивидуальной защиты органов дыхания изолирующие со сжатым кислородом',
    'speciesSIZ': 'Дыхательные аппараты /  Самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.2.1',
    'label':
      'Средства индивидуальной защиты органов дыхания изолирующие неавтономные с подачей чистого воздуха по шлангу/магистрали',
    'speciesSIZ': 'Дыхательные аппараты',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.3.1',
    'label':
      'Одежда специальная для защиты от химических факторов: щелочей и/или растворов кислот',
    'speciesSIZ':
      'Костюм для защиты от растворов кислот /  Комбинезон для защиты от растворов кислот /  Костюм для защиты от щелочей /  Комбинезон для защиты от щелочей',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans':
      'Фартук для защиты от растворов кислот /  Фартук для защиты от щелочей /  Халат для защиты от растворов кислот /  Халат для защиты от щелочей',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ ISO 16602-2019 ИЛИ ГОСТ 12.4.279-2014',
    'OperatingLevel': 'Костюм/комбинезон: (К, Щ), Тип 5; Фартук (К,Щ)',
    'markerBase': '14',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.3.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от кислот',
    'speciesSIZ': 'Сапоги /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': 'эксплуатационный уровень не менее К20',
    'markerBase': '15',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.3.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от щелочей',
    'speciesSIZ': 'Сапоги /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': 'эксплуатационный уровень не менее Щ20',
    'markerBase': '15',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.3.1',
    'label':
      'Средства индивидуальной защиты рук от химических факторов: растворов кислот',
    'speciesSIZ': 'Перчатки, в том числе перчатки камерные /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ ISO 374-5-2020',
    'OperatingLevel': '(К), Тип С и выше',
    'markerBase': '16',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '6.3.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от химических факторов',
    'speciesSIZ': 'Очки закрытого или открытого типа',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Щиток',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.253-2013 (EN 166:2002)',
    'OperatingLevel': '',
    'markerBase': '',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence':
      'с механической прочностью не ниже S, с наружным покрытием линз для защиты от химикатов, оптический класс: 1."',
    'label': '68',
    'speciesSIZ': '',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': '',
    'OperatingLevel': '',
    'markerBase': '',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.4.1',
    'label': 'Средства индивидуальной защиты органов дыхания фильтрующие',
    'speciesSIZ':
      'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски /  Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) для средств индивидуальной защиты /  Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха /  Фильтрующие самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Респиратор /  Противогаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart':
      'ГОСТ 12.4.294 ИЛИ ГОСТ 12.4.293 и ГОСТ 12.4.235 ИЛИ ГОСТ 12.4.234 (EN 12941:1998) ИЛИ ГОСТ 12.4.285',
    'OperatingLevel':
      'не ниже FFP1 ИЛИ изолирующая лицевая часть и фильтры не ниже 1-го класса защиты',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.5.1',
    'label': 'Средства индивидуальной защиты глаз от химических факторов',
    'speciesSIZ': 'Очки закрытого типа',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Жидкость для промывания глаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ГОСТ 12.4.253-2013 (EN 166:2002)',
    'OperatingLevel': '',
    'markerBase': '',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence':
      'с механической прочностью не ниже S, с наружным покрытием линз для защиты от химикатов, оптический класс: 1."',
    'label': '68',
    'speciesSIZ': '',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': '',
    'OperatingLevel': '',
    'markerBase': '',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.6.1',
    'label':
      'Средства индивидуальной защиты органов дыхания фильтрующего типа противоаэрозольные',
    'speciesSIZ':
      'Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противогазовые, противогазоаэрозольные (комбинированные) для средств индивидуальной защиты /  Фильтрующие самоспасатели /  Противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Респиратор /  Противогаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ГОСТ 12.4.244-2013\n(EN 149:2001+A1:2009)',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.6.1',
    'label':
      'Средства индивидуальной защиты органов дыхания фильтрующего типа Противогазоаэрозольные (комбинированные)',
    'speciesSIZ':
      'Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противогазовые, противогазоаэрозольные (комбинированные) для средств индивидуальной защиты /  Фильтрующие самоспасатели /  Противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Респиратор /  Противогаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ГОСТ 12.4.244-2013\n(EN 149:2001+A1:2009)',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.7.1',
    'label': 'Одежда специальная для защиты от нефти и/или нефтепродуктов',
    'speciesSIZ': 'Костюм',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans':
      'Комбинезон /  Плащ (1 на 2 года) /  Одежда специальная ограниченного срока использования для защиты от токсичных веществ (определяется документами изготовителя)',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.310-2020 ',
    'OperatingLevel': '(Нс, Нм, Нл)',
    'markerBase': '10',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.7.1',
    'label': 'Средства индивидуальной защиты рук от нефти и нефтепродуктов',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ 12.4.103-2020 ',
    'OperatingLevel': '( Нс,Нл,Нм,Нж)',
    'markerBase': '12',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '6.7.1',
    'label': 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
    'speciesSIZ': 'Ботинки /  Полуботинки /  Сапоги /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Нс, Нл, Нм)',
    'markerBase': '11',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.7.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от нефти и/или нефтепродуктов и жиров',
    'speciesSIZ': 'Сапоги /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Нм)',
    'markerBase': '11',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.8.1',
    'label':
      'Одежда специальная для защиты от воды и растворов нетоксичных веществ',
    'speciesSIZ':
      'Костюм, в том числе отдельными предметами: куртка, брюки, полукомбинезон /  Фартук',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans':
      'Одежда специальная ограниченного срока использования для защиты от токсичных веществ',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ГОСТ Р 12.4.288-2013 ',
    'OperatingLevel': ' (Во), не менее 1 класса защиты',
    'markerBase': '7',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '6.8.1',
    'label':
      'Средства индивидуальной защиты органов рук для защиты от воды и растворов нетоксичных веществ',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ ISO 374-1-2019',
    'OperatingLevel': '(Вн)',
    'markerBase': '9',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '6.9.1',
    'label': 'Другое 6.9.1',
    'speciesSIZ': 'СИЗ для Другое 6.9.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '7.1.1',
    'label':
      'Средства индивидуальной защиты глаз от механических воздействий, газов и мелкодисперсных аэрозолей',
    'speciesSIZ': 'Очки защитные закрытые',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.253-2013 (EN 166:2002)',
    'OperatingLevel': '',
    'markerBase': '66',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '7.1.1',
    'label':
      'Средства индивидуальной защиты глаз от механических воздействий, от грубодисперсных аэрозолей (пыли)',
    'speciesSIZ': 'Очки защитные закрытые',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.253-2013 (EN 166:2002)',
    'OperatingLevel': '',
    'markerBase': '66',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '7.1.2',
    'label':
      'Средства индивидуальной защиты органов дыхания фильтрующего типа противоаэрозольные',
    'speciesSIZ':
      'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски /  Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) для средств индивидуальной защиты /  Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Респиратор /  Противогаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ГОСТ 12.4.294-2015\n(EN 149:2001+A1:2009)',
    'OperatingLevel': 'обеспечивает защиту FFP',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '7.1.3',
    'label': 'Одежда специальная для защиты от нетоксичной пыли',
    'speciesSIZ': 'Костюм, в том числе с головным убором',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans':
      'Одежда специальная ограниченного срока использования комбинезон, в том числе с головным убором',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ГОСТ Р 12.4.289-2013',
    'OperatingLevel': 'у нас в порфеле таких костюмов нет',
    'markerBase': '58',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '7.1.3',
    'label':
      'Средства индивидуальной защиты рук для защиты от нетоксичной пыли',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Пм ИЛИ Пк)',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '7.2.1',
    'label':
      'Средства индивидуальной защиты органов дыхания фильтрующего типа противоаэрозольные, противогазоаэрозольные (комбинированные)',
    'speciesSIZ':
      'Противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски /  Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) для средств индивидуальной защиты /  Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Фильтрующие самоспасатели /  Респиратор /  Противогаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': '',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '7.2.1',
    'label': 'Одежда специальная для ограниченной защиты от токсичных веществ',
    'speciesSIZ': 'Костюм, в том числе с головным убором',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans':
      'Одежда специальная ограниченного срока использования Комбинезон, в том числе с головным убором',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ГОСТ 12.4.101-93',
    'OperatingLevel': 'у нас в порфеле таких костюмов нет',
    'markerBase': '58',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '7.2.1',
    'label':
      'Средства индивидуальной защиты глаз от механических воздействий, газов и мелкодисперсных аэрозолей',
    'speciesSIZ': 'Очки защитные закрытые',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.253-2013 (EN 166:2002)',
    'OperatingLevel': '',
    'markerBase': '66',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '7.2.1',
    'label':
      'Средства индивидуальной защиты глаз от механических воздействий, от грубодисперсных аэрозолей (пыли)',
    'speciesSIZ': 'Очки защитные закрытые',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.253-2013 (EN 166:2002)',
    'OperatingLevel': '',
    'markerBase': '66',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '7.3.1',
    'label':
      'Средства индивидуальной защиты органов дыхания фильтрующего типа противоаэрозольные, противогазоаэрозольные (комбинированные)',
    'speciesSIZ':
      'Противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски /  Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) для средств индивидуальной защиты /  Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Респиратор /  Противогаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': '',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '7.4.1',
    'label': 'Другое 7.4.1',
    'speciesSIZ': 'СИЗ для Другое 7.4.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '8.1.1',
    'label':
      'Одежда специальная изолирующая для защиты от химических факторов (в том числе применяемая для защиты от биологических факторов (микроорганизмов)',
    'speciesSIZ': 'Комбинезон',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Одежда специальная ограниченного срока использования',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': '',
    'OperatingLevel': '(К, Щ)',
    'markerBase': '58',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '8.1.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от биологических факторов (микроорганизмов)',
    'speciesSIZ': 'Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ ISO 374-5-2020',
    'OperatingLevel': '(К, Щ)',
    'markerBase': '75',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '8.1.1',
    'label':
      'Средства индивидуальной защиты органов дыхания фильтрующего типа противоаэрозольные',
    'speciesSIZ':
      'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски /  Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) для средств индивидуальной защиты /  Фильтрующие самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Респиратор /  Противогаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart':
      'ГОСТ 12.4.294 ИЛИ ГОСТ 12.4.293 и ГОСТ 12.4.235 ИЛИ ГОСТ 12.4.234 (EN 12941:1998) ИЛИ ГОСТ 12.4.285',
    'OperatingLevel':
      'не ниже FFP1 ИЛИ изолирующая лицевая часть и фильтры не ниже 1-го класса защиты',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '8.1.1',
    'label':
      'Средства индивидуальной защиты органов дыхания фильтрующего типа Противогазоаэрозольные (комбинированные)',
    'speciesSIZ':
      'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски /  Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) для средств индивидуальной защиты /  Фильтрующие самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Респиратор /  Противогаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart':
      'ГОСТ 12.4.294 ИЛИ ГОСТ 12.4.293 и ГОСТ 12.4.235 ИЛИ ГОСТ 12.4.234 (EN 12941:1998) ИЛИ ГОСТ 12.4.285',
    'OperatingLevel':
      'не ниже FFP1 ИЛИ изолирующая лицевая часть и фильтры не ниже 1-го класса защиты',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '8.2.1',
    'label':
      'Одежда специальная изолирующая для защиты от химических факторов (в том числе применяемая для защиты от биологических факторов (микроорганизмов)',
    'speciesSIZ': 'Комбинезон',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Одежда специальная ограниченного срока использования',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': '',
    'OperatingLevel': '(К, Щ)',
    'markerBase': '58',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '8.2.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от биологических факторов (микроорганизмов)',
    'speciesSIZ': 'Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ ISO 374-5-2020',
    'OperatingLevel': '(К, Щ)',
    'markerBase': '75',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '8.2.1',
    'label':
      'Средства индивидуальной защиты органов дыхания фильтрующего типа противоаэрозольные',
    'speciesSIZ':
      'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски /  Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) для средств индивидуальной защиты',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Респиратор /  Противогаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ГОСТ 12.4.294-2015\n(EN 149:2001+A1:2009)',
    'OperatingLevel': 'обеспечивает защиту FFP',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '8.2.1',
    'label':
      'Средства индивидуальной защиты органов дыхания фильтрующего типа Противогазоаэрозольные (комбинированные)',
    'speciesSIZ':
      'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски /  Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) для средств индивидуальной защиты',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Респиратор /  Противогаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': '',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '8.3.1',
    'label':
      'Одежда специальная от вредных биологических факторов паукообразных (клещей) и насекомых',
    'speciesSIZ': 'Костюм, в том числе в комплекте с головным убором',
    'issuanceRate': '1 комп. на 12 мес',
    'additionalMeans': 'Комбинезон, в том числе в комплекте с головным убором',
    'AdditionalIssuanceRate': '1 комп. на 12 мес',
    'standart': 'ГОСТ Р 12.4.296-2013',
    'OperatingLevel': '(Бнг, Бнк) ',
    'markerBase': '79',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '8.3.1',
    'label':
      'Обувь специальная для защиты от вредных биологических факторов (клещей и кровососущих насекомых)',
    'speciesSIZ': 'Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Бнг, Бнк, Бнб) ',
    'markerBase': '80',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '8.3.1',
    'label':
      'Средства индивидуальной защиты головы: головной убор для защиты от биологических факторов (кровососущих насекомых)',
    'speciesSIZ':
      'Головной убор для защиты от биологических факторов (кровососущих насекомых)',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Накомарник /  Сетка наголовная',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Бн)',
    'markerBase': '4',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '8.4.1',
    'label': 'Другое 8.4.1',
    'speciesSIZ': 'СИЗ для Другое 8.7.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '9.1.1',
    'label':
      'Средства индивидуальной защиты опорно-двигательного аппарата от статических нагрузок (от утомляемости)',
    'speciesSIZ': 'Нет СИЗ, использовать дополнительные средства защиты',
    'issuanceRate': '',
    'additionalMeans':
      'Поддерживающий ремень для живота и поясницы /  Промышленные экзоскелеты /  Наколенники',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': '',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '9.2.1',
    'label':
      'Средства индивидуальной защиты опорно-двигательного аппарата от статических нагрузок (от утомляемости)',
    'speciesSIZ': 'Нет СИЗ, использовать дополнительные средства защиты',
    'issuanceRate': '',
    'additionalMeans':
      'Поддерживающий ремень для живота и поясницы /  Промышленные экзоскелеты',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': '',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '9.3.1',
    'label':
      'Средства индивидуальной защиты опорно-двигательного аппарата от статических нагрузок (от утомляемости)',
    'speciesSIZ': 'Нет СИЗ, использовать дополнительные средства защиты',
    'issuanceRate': '',
    'additionalMeans':
      'Поддерживающий ремень для живота и поясницы /  Промышленные экзоскелеты',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': '',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '9.4.1',
    'label': 'Другое 9.4.1',
    'speciesSIZ': 'СИЗ для Другое 9.4.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '10.1.1',
    'label': 'Средства индивидуальной защиты органа слуха',
    'speciesSIZ':
      'Противошумные наушники и их комплектующие /  Противошумные вкладыши (беруши); Выбираются в зависимости от уровня шума на рабочем месте с учетом необходимости коммуникации и возможности слышать звук специальных и аварийных сигналов и шум потенциально опасных машин и механизмов',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': 'с показателем SNR',
    'markerBase': '20',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '10.2.1',
    'label': 'Средства индивидуальной защиты рук от механических воздействий',
    'speciesSIZ': 'Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.1.001-89, СанПиН 2.2.4/2.1.8.582-96',
    'OperatingLevel': '',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '10.2.1',
    'label': 'Средства индивидуальной защиты органа слуха',
    'speciesSIZ':
      'Противошумные наушники и их комплектующие /  Противошумные вкладыши (беруши); Выбираются в зависимости от уровня шума на рабочем месте с учетом необходимости коммуникации и возможности слышать звук специальных и аварийных сигналов и шум потенциально опасных машин и механизмов',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': 'Шлемофон летный',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': 'с показателем SNR',
    'markerBase': '20',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '10.3.1',
    'label': 'Другое 10.3.1',
    'speciesSIZ': 'СИЗ для Другое 10.3.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '11.1.1',
    'label': 'Средства индивидуальной защиты рук от вибрации',
    'speciesSIZ':
      'Перчатки /  Рукавицы; Выбираются по уровням защиты при контактных колебаниях',
    'issuanceRate':
      'определяется документами изготовителя, но не менее 6 пар на 12 мес',
    'additionalMeans':
      'Митенки; Выбираются по уровням защиты при контактных колебаниях',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ГОСТ 12.4.002-97',
    'OperatingLevel': '(Мв), тип 2А ИЛИ 2Б',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '11.2.1',
    'label': 'Обувь специальная для защиты от вибрации',
    'speciesSIZ':
      'Полуботинки с перфорацией /  Полуботинки /  Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': 'Антивибрационные коврики',
    'AdditionalIssuanceRate':
      'определяется документами изготовителя, но не менее 1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Мв)',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '11.3.1',
    'label': 'Другое 11.3.1',
    'speciesSIZ': 'СИЗ для Другое 11.3.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '12.1.1',
    'label': 'Одежда специальная сигнальная повышенной видимости',
    'speciesSIZ': 'Костюм /  Комбинезон /  Плащ /  Жилет',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.281-2021',
    'OperatingLevel': '(Со), класс защиты не ниже 1 ',
    'markerBase': '6',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '12.2.1',
    'label':
      'Средства индивидуальной защиты глаз от слепящей яркости и от ультрафиолетового излучения',
    'speciesSIZ':
      'Очки защитные открытые с затемненной линзой /  Очки защитные от механических воздействий с корригирующим эффектом',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.253-2013 (EN 166:2002)',
    'OperatingLevel':
      '(шкальный номер 5 - солнцезащитный светофильтр без нормирования по инфракрасному излучению)',
    'markerBase': '61',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '12.2.1',
    'label':
      'Средства индивидуальной защиты головы: головные уборы для защиты от общих производственных загрязнений',
    'speciesSIZ': 'Кепка /  Бейсболка /  Козырек /  Шляпа',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011, или ТР ТС 017/2011, или ГОСТ 12.4.280-2014',
    'OperatingLevel': '',
    'markerBase': '4',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '12.3.1',
    'label':
      'Средства индивидуальной защиты глаз от механических воздействий, в том числе с покрытием от запотевания',
    'speciesSIZ':
      'Очки защитные открытые (с желтыми линзами) /  Очки защитные от механических воздействий с корригирующим эффектом',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.253-2013 (EN 166:2002)',
    'OperatingLevel': '',
    'markerBase': '62',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '12.3.1',
    'label': 'Одежда специальная сигнальная повышенной видимости',
    'speciesSIZ': 'Костюм /  Комбинезон /  Плащ /  Жилет',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.281-2021',
    'OperatingLevel': '(Со), класс защиты не ниже 1 ',
    'markerBase': '6',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '12.4.1',
    'label': 'Другое 12.4.1',
    'speciesSIZ': 'СИЗ для Другое 12.4.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '13.1.1',
    'label':
      'Одежда специальная для защиты от воздействия статического электричества',
    'speciesSIZ': 'Костюм /  Комбинезон',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.280-2014 ',
    'OperatingLevel': '(ЭС)',
    'markerBase': '5',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '13.1.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от воздействия статического электричества',
    'speciesSIZ': 'Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(ЭС)',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '13.1.1',
    'label': 'Обувь специальная для защиты от статического электричества',
    'speciesSIZ': 'Ботинки /  Полуботинки /  Сапоги /  Полусапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans':
      'Браслет антиэлектростатический /  Кольцо антиэлектростатическое 2 шт.',
    'AdditionalIssuanceRate': '2 шт. на 2 мес',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Эс)',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '13.2.1',
    'label':
      'Комплект для защиты от воздействия электромагнитных полей радиочастотного диапазона',
    'speciesSIZ':
      'Комплектность: Экранирующий комбинезон с капюшоном и защитным экраном - 1 шт.; Экранирующие перчатки - 2 пары; Экранирующие носки - 4 пары',
    'issuanceRate': '1 на 18 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': '',
    'OperatingLevel': '',
    'markerBase': '50',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '13.3.1',
    'label':
      'Комплект для защиты от воздействия электрических полей промышленной частоты',
    'speciesSIZ':
      'Комплектность: Накасник экранирующий; Куртка экранирующая; Полукомбинезон/брюки экранирующие; Перчатки экранирующие; Ботинки экранирующие',
    'issuanceRate': '1 на 18 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Эп)',
    'markerBase': '50',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '13.4.1',
    'label': 'Одежда для защиты от теплового излучения',
    'speciesSIZ': 'Костюм',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Комбинезон /  Плащ',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': '',
    'OperatingLevel': '(Ти), эксплуатационный уровень не ниже С1',
    'markerBase': '22',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '13.4.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от теплового излучения',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники для защиты от теплового излучения',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ EN 407 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel':
      '(Ти), тепловое излучение не ниже 1 эксплуатационного уровня',
    'markerBase': '43',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '13.4.1',
    'label':
      'Обувь специальная для защиты от контакта с нагретыми поверхностями',
    'speciesSIZ': 'Полуботинки /  Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Тп)',
    'markerBase': '27',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '13.4.1',
    'label': 'Головной убор для защиты от теплового излучения',
    'speciesSIZ': 'Подшлемник',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Шапка /  Шлем',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': '',
    'OperatingLevel': '(Ти), эксплуатационный уровень не ниже С1',
    'markerBase': '33',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '13.4.1',
    'label': 'Средства индивидуальной защиты лица от теплового излучения',
    'speciesSIZ': 'Щиток',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.023 или ГОСТ 12.4.253',
    'OperatingLevel': '',
    'markerBase': '55',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '13.5.1',
    'label': 'Средства индивидуальной защиты глаз от лазерных излучений',
    'speciesSIZ': 'Очки защитные открытые',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.308-2016\n(EN 207:2009)',
    'OperatingLevel': '',
    'markerBase': '65',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '13.6.1',
    'label': 'Средства индивидуальной защиты глаз от ультрафиолета',
    'speciesSIZ': 'Очки защитные открытые',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.253-2013 (EN 166:2002)',
    'OperatingLevel': '',
    'markerBase': '62',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '13.7.1',
    'label': 'Другое 13.7.1',
    'speciesSIZ': 'СИЗ для Другое 13.7.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '14.1.1',
    'label': 'Одежда специальная для защиты от радиационных факторов',
    'speciesSIZ':
      'Комбинезон изолирующий для защиты кожи и органов дыхания от радиоактивных веществ /  Одежда специальная для защиты от радиоактивных веществ и ионизирующих излучений',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 33717-2015 и/или ГОСТ 12.4.265-2014',
    'OperatingLevel': '',
    'markerBase': '59',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '14.1.1',
    'label':
      'Одежда специальная защитная от радиоактивных веществ и ионизирующих излучений',
    'speciesSIZ':
      'Комбинезон изолирующий для защиты кожи и органов дыхания от радиоактивных веществ',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 33717-2015 и/или ГОСТ 12.4.265-2014',
    'OperatingLevel': '',
    'markerBase': '59',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '14.1.1',
    'label':
      'Обувь специальная для защиты от радиоактивных веществ и ионизирующих излучений',
    'speciesSIZ': 'Ботинки',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(Рз, Ри)',
    'markerBase': '59',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '14.1.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от радиоактивных веществ и ионизирующих излучений',
    'speciesSIZ': 'Перчатки, в том числе перчатки камерные',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.264-2014\n(EN 421:1994)',
    'OperatingLevel': '(Рз, Ри)',
    'markerBase': '59',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '14.1.1',
    'label':
      'Средства индивидуальной защиты глаз и лица от ионизирующих излучений',
    'speciesSIZ': 'Очки защитные /  Щиток защитный лицевой',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.217-2001',
    'OperatingLevel': '',
    'markerBase': '59',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '14.1.1',
    'label':
      'Средства индивидуальной защиты органов дыхания от радиоактивных веществ',
    'speciesSIZ':
      'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски /  Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) для средств индивидуальной защиты /  Фильтрующие самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans':
      'Средства индивидуальной защиты органов дыхания изолирующие на химически связанном кислороде (дыхательные аппараты, самоспасатели) /  Средства индивидуальной защиты органов дыхания изолирующие со сжатым воздухом (дыхательные аппараты, самоспасатели) /  Средства индивидуальной защиты органов дыхания изолирующие со сжатым кислородом (дыхательные аппараты, самоспасатели) /  Респиратор /  Противогаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart':
      'ГОСТ 12.4.294 ИЛИ ГОСТ 12.4.293 и ГОСТ 12.4.235 ИЛИ ГОСТ 12.4.234 (EN 12941:1998) ИЛИ ГОСТ 12.4.285',
    'OperatingLevel':
      'не ниже FFP1 ИЛИ изолирующая лицевая часть и фильтры не ниже 1-го класса защиты',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '14.2.1',
    'label':
      'Одежда специальная защитная от радиоактивных веществ и ионизирующих излучений',
    'speciesSIZ': 'Фартук',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 33717-2015 и/или ГОСТ 12.4.265-2014',
    'OperatingLevel': '',
    'markerBase': '59',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '5',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '14.2.1',
    'label':
      'Средства индивидуальной защиты рук от радиоактивных веществ и ионизирующих излучений',
    'speciesSIZ': 'Перчатки, в том числе перчатки камерные',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.264-2014\n(EN 421:1994)',
    'OperatingLevel': '(Рз, Ри)',
    'markerBase': '59',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '14.2.1',
    'label':
      'Средства индивидуальной защиты глаз и лица защитные от ионизирующих излучений',
    'speciesSIZ': 'Очки защитные /  Щиток защитный лицевой',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.217-2001',
    'OperatingLevel': '',
    'markerBase': '59',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '14.3.1',
    'label':
      'Одежда специальная защитная от радиоактивных веществ и ионизирующих излучений',
    'speciesSIZ':
      'Комбинезоны изолирующие для защиты кожи и органов дыхания от радиоактивных веществ',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 33717-2015 и/или ГОСТ 12.4.265-2014',
    'OperatingLevel': '',
    'markerBase': '59',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '14.3.1',
    'label':
      'Средства индивидуальной защиты рук от радиоактивных веществ и ионизирующих излучений',
    'speciesSIZ': 'Перчатки, в том числе перчатки камерные',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.264-2014\n(EN 421:1994)',
    'OperatingLevel': '(Рз, Ри)',
    'markerBase': '59',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '14.3.1',
    'label':
      'Средства индивидуальной защиты органов дыхания (в том числе фильтрующие) от радиоактивных веществ',
    'speciesSIZ':
      'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски /  Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) для средств индивидуальной защиты /  Фильтрующие самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans':
      'Средства индивидуальной защиты органов дыхания изолирующие на химически связанном кислороде (дыхательные аппараты, самоспасатели) /  Средства индивидуальной защиты органов дыхания изолирующие со сжатым воздухом (дыхательные аппараты, самоспасатели) /  Средства индивидуальной защиты органов дыхания изолирующие со сжатым кислородом (дыхательные аппараты, самоспасатели) /  Респиратор /  Противогаз',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart':
      'ГОСТ 12.4.294 ИЛИ ГОСТ 12.4.293 и ГОСТ 12.4.235 ИЛИ ГОСТ 12.4.234 (EN 12941:1998) ИЛИ ГОСТ 12.4.285',
    'OperatingLevel':
      'не ниже FFP1 ИЛИ изолирующая лицевая часть и фильтры не ниже 1-го класса защиты',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '14.4.1',
    'label': 'Другое 14.4.1',
    'speciesSIZ': 'СИЗ для Другое 14.4.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '15.1.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от механических воздействий (проколов, порезов)',
    'speciesSIZ': 'Рукавицы /  Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.010-75 ИЛИ EN 388:2016 ',
    'OperatingLevel':
      '(Мп), сопротивление порезу эксплуатационный уровень не ниже 3, стойкость к проколу \\эксплуатационный уровень не ниже 3',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '1',
    'markerGlovesPuncture': '1',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '15.1.2',
    'label':
      'Дерматологические средства индивидуальной защиты защитного типа: средства для защиты от воздействия биологических факторов (микроорганизмов): с антибактериальным (антимикробным) действием',
    'speciesSIZ': 'Спрей /  Гель',
    'issuanceRate': '1200 мл на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.1.007-76, Свидетельство Гос. Регистрации',
    'OperatingLevel': 'категория направленной эффективности',
    'markerBase': '71',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '15.1.2',
    'label':
      'Дерматологические средства индивидуальной защиты защитного типа: средства для защиты от воздействия биологических факторов (микроорганизмов): с противогрибковым (фунгицидным) действием)',
    'speciesSIZ': 'Спрей /  Гель',
    'issuanceRate': '1200 мл на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.1.007-76, Свидетельство Гос. Регистрации',
    'OperatingLevel': 'категория направленной эффективности',
    'markerBase': '71',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '15.1.2',
    'label':
      'Средства индивидуальной защиты рук для защиты от воды и растворов нетоксичных веществ',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники для защиты от',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ ISO 374-1-2019',
    'OperatingLevel': '(Вн)',
    'markerBase': '9',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '15.2.1',
    'label': 'Другое 15.2.1',
    'speciesSIZ': 'СИЗ для Другое 15.2.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '16.1.1',
    'label':
      'Средства индивидуальной защиты органов дыхания фильтрующего типа противоаэрозольные, противогазоаэрозольные (комбинированные)',
    'speciesSIZ':
      'Противоаэрозольные средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаскиПротивоаэрозольные средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски /  Изолирующие лицевые части (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами); Сменные фильтры (фильтрующие элементы: противоаэрозольные) для средств индивидуальной защиты',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans':
      'Респиратор /  Противогаз /  Одежда специальная ограниченного срока использования',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': '',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '16.1.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от биологических факторов (микроорганизмов)',
    'speciesSIZ': 'Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ ISO 374-5-2020',
    'OperatingLevel': '(Бм)',
    'markerBase': '75',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '16.1.1',
    'label':
      'Средства индивидуальной защиты рук от общих производственных загрязнений',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'EN 388:2016 ',
    'OperatingLevel':
      '(подтверждение защитных свойств в декларации или сертификате)',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '16.2.1',
    'label':
      'Одежда специальная для защиты от общих производственных загрязнений',
    'speciesSIZ':
      'Костюм, в том числе отдельными предметами: куртка, брюки, полукомбинезон',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans':
      'Одежда специальная ограниченного срока использования (определяется документами изготовителя) /  Комбинезон (1 шт.)',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ГОСТ 12.4.280-2014 ',
    'OperatingLevel': '(З)',
    'markerBase': '1',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '16.2.1',
    'label':
      'Обувь специальная для защиты от общих производственных загрязнений',
    'speciesSIZ':
      'Полуботинки с перфорацией /  Полуботинки /  Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '16.2.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от общих производственных загрязнений',
    'speciesSIZ': 'Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '2',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '16.2.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от биологических факторов (микроорганизмов)',
    'speciesSIZ': 'Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ ISO 374-5-2020',
    'OperatingLevel': '(Бм)',
    'markerBase': '75',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '16.2.1',
    'label':
      'Средства индивидуальной защиты рук от общих производственных загрязнений',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 388:2016 ',
    'OperatingLevel':
      '(подтверждение защитных свойств в декларации или сертификате)',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '16.2.1',
    'label':
      'Средства индивидуальной защиты глаз и лица для защиты от брызг жидкостей',
    'speciesSIZ': 'Очки',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Щиток',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ 12.4.253-2013 (EN 166:2002)',
    'OperatingLevel': '',
    'markerBase': '63',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '16.3.1',
    'label':
      'Одежда специальная для защиты от общих производственных загрязнений',
    'speciesSIZ':
      'Костюм, в том числе отдельными предметами: куртка, брюки, полукомбинезон',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans':
      'Одежда специальная ограниченного срока использования (определяется документами изготовителя) /  Комбинезон (1 шт.)',
    'AdditionalIssuanceRate': 'определяется документами изготовителя',
    'standart': 'ГОСТ 12.4.280-2014 ',
    'OperatingLevel': '(З)',
    'markerBase': '1',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '16.3.1',
    'label':
      'Обувь специальная для защиты от общих производственных загрязнений',
    'speciesSIZ':
      'Полуботинки с перфорацией /  Полуботинки /  Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '2',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '16.3.1',
    'label':
      'Обувь специальная резиновая или из полимерных материалов для защиты от общих производственных загрязнений',
    'speciesSIZ': 'Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '2',
    'markerRubber': '1',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '16.3.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от биологических факторов (микроорганизмов)',
    'speciesSIZ': 'Перчатки',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ ISO 374-5-2020',
    'OperatingLevel': '(Бм)',
    'markerBase': '75',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '16.3.1',
    'label':
      'Средства индивидуальной защиты рук от общих производственных загрязнений',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 388:2016 ',
    'OperatingLevel':
      '(подтверждение защитных свойств в декларации или сертификате)',
    'markerBase': '3',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '16.3.1',
    'label':
      'Средства индивидуальной защиты головы: головные уборы для защиты от общих производственных загрязнений',
    'speciesSIZ': 'Кепка /  Бейсболка /  Косынка /  Шляпа',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011, или ТР ТС 017/2011, или ГОСТ 12.4.280-2014',
    'OperatingLevel': '',
    'markerBase': '4',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '16.4.1',
    'label': 'Другое 16.4.1',
    'speciesSIZ': 'СИЗ для Другое 16.4.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '17.1.1',
    'label':
      'Средства индивидуальной защиты органов дыхания фильтрующего типа противоаэрозольные, противогазоаэрозольные (комбинированные)',
    'speciesSIZ': 'Фильтрующие самоспасатели',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '21',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '17.2.1',
    'label': 'Другое 17.2.1',
    'speciesSIZ': 'СИЗ для Другое 17.2.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '18.1.1',
    'label': 'Одежда сигнальная повышенной видимости',
    'speciesSIZ': 'Костюм /  Комбинезон /  Плащ /  Жилет',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.281-2021',
    'OperatingLevel': '(Со), класс защиты не ниже 1 ',
    'markerBase': '6',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '18.1.2',
    'label': 'Одежда сигнальная повышенной видимости',
    'speciesSIZ': 'Костюм /  Комбинезон /  Плащ /  Жилет',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.281-2021',
    'OperatingLevel': '(Со), класс защиты не ниже 1 ',
    'markerBase': '6',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '18.2.1',
    'label': 'Другое 18.2.1',
    'speciesSIZ': 'СИЗ для Другое 18.2.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '19.1.1',
    'label':
      'Одежда специальная для защиты от кратковременного воздействия открытого пламени и статического электричества',
    'speciesSIZ': 'Костюм',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Комбинезон',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ ISO 11612-\n2020',
    'OperatingLevel':
      '(То, Эс), эксплуатационный уровень по огнестойкости А1/ А1+А2, ',
    'markerBase': '22',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '19.1.1',
    'label':
      'Обувь специальная для защиты от кратковременного воздействия открытого пламени, статического электричества и от контакта с нагретыми поверхностями',
    'speciesSIZ': 'Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(То, Тп, Эс)',
    'markerBase': '27',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '19.1.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от кратковременного воздействия открытого пламени',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ EN 407 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel': '(То), огнестойкость не ниже 1',
    'markerBase': '43',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '19.1.1',
    'label':
      'Средства индивидуальной защиты головы для защиты от повышенных температур',
    'speciesSIZ': 'Каска защитная до +150 °C',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 397',
    'OperatingLevel': 'Температурный режим:до +150 °С',
    'markerBase': '18',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '19.1.1',
    'label':
      'Средства индивидуальной защиты головы: головной убор для защиты от кратковременного воздействия открытого пламени',
    'speciesSIZ': 'Подшлемник',
    'issuanceRate': '1 шт.  на 12 мес',
    'additionalMeans': 'Шлем /  Шапка',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': '',
    'OperatingLevel': '(То)',
    'markerBase': '33',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '19.2.1',
    'label':
      'Одежда специальная для защиты от кратковременного воздействия открытого пламени',
    'speciesSIZ': 'Костюм',
    'issuanceRate': '1 пара на 12 мес',
    'additionalMeans': 'Комбинезон',
    'AdditionalIssuanceRate': '1 шт. на 12 мес',
    'standart': 'ГОСТ ISO 11612-\n2020',
    'OperatingLevel': '(То), огнестойкость A1/ A1+A2',
    'markerBase': '22',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '1',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '19.2.1',
    'label':
      'Обувь специальная для защиты от кратковременного воздействия открытого пламени и от контакта с нагретыми поверхностями',
    'speciesSIZ': 'Ботинки /  Полусапоги /  Сапоги',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ 12.4.103-2020',
    'OperatingLevel': '(То, Тр)',
    'markerBase': '27',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '1',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '19.2.1',
    'label':
      'Средства индивидуальной защиты рук для защиты от кратковременного воздействия открытого пламени',
    'speciesSIZ': 'Перчатки /  Рукавицы',
    'issuanceRate': '12 пар на 12 мес',
    'additionalMeans': 'Нарукавники',
    'AdditionalIssuanceRate': '12 пар на 12 мес',
    'standart': 'ГОСТ EN 407 ИЛИ ГОСТ 12.4.103',
    'OperatingLevel': '(То), огнестойкость не ниже 1',
    'markerBase': '43',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '1',
  },
  {
    'dependence': '19.2.1',
    'label':
      'Средства индивидуальной защиты головы для защиты от повышенных температур',
    'speciesSIZ': 'Каска защитная до +150 °C',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 397',
    'OperatingLevel': 'Температурный режим:до +150 °С',
    'markerBase': '18',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '19.2.1',
    'label':
      'Средства индивидуальной защиты головы: головной убор для защиты от кратковременного воздействия открытого пламени',
    'speciesSIZ': 'Подшлемник',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': 'Шлем /  Шапка',
    'AdditionalIssuanceRate': '1 шт. на 24 мес',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '(То)',
    'markerBase': '33',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '19.3.1',
    'label':
      'Средства индивидуальной защиты головы для защиты от повышенных температур',
    'speciesSIZ': 'Каска защитная',
    'issuanceRate': '1 шт. на 24 мес',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'EN 397',
    'OperatingLevel': 'Температурный режим:до +150 °С',
    'markerBase': '17',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '19.3.1',
    'label': 'Средства индивидуальной защиты органа слуха',
    'speciesSIZ':
      'Противошумные наушники и их комплектующие /  Противошумные вкладыши (беруши); Выбираются в зависимости от уровня шума на рабочем месте с учетом необходимости коммуникации и возможности слышать звук специальных и аварийных сигналов и шум потенциально опасных машин и механизмов',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': 'с показателем SNR',
    'markerBase': '20',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '19.4.1',
    'label': 'Другое 19.4.1',
    'speciesSIZ': 'СИЗ для Дргуое 19.4.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': '',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '20.1.1',
    'label':
      'Одежда специальная ограниченного срока использования для защиты кожи от трудно смываемых загрязнителей, в том числе в связи с выходом из строя одежды специальной многократного применения',
    'speciesSIZ': 'Комбинезон',
    'issuanceRate': 'определяется документами изготовителя',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ГОСТ ISO 16602-2019 ИЛИ ГОСТ 12.4.279-2014',
    'OperatingLevel': '(З), Тип 5 ИЛИ тип 6',
    'markerBase': '58',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
  {
    'dependence': '20.2.1',
    'label': 'Другое 20.2.1',
    'speciesSIZ': 'СИЗ для Другое 20.2.1',
    'issuanceRate': '',
    'additionalMeans': '',
    'AdditionalIssuanceRate': '',
    'standart': 'ТР ТС 019/2011',
    'OperatingLevel': '',
    'markerBase': '0',
    'markerRubber': '',
    'markerSlip': '',
    'markerPuncture': '',
    'markerGlovesAbrasion': '',
    'markerGlovesCut': '',
    'markerGlovesPuncture': '',
    'markerWinterShoes': '',
    'markerWinterclothes': '',
    'markerHierarchyOfClothing': '',
    'markerHierarchyOfShoes': '',
    'markerHierarchyOfGloves': '',
  },
];
export default typeSiz;
