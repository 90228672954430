const danger = [
  {
    ID: '1.1',
    label: 'Скользкие, обледенелые, зажиренные, мокрые поверхности',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.2',
    label: 'Перепад высот, отсутствие ограждения на высоте',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.3',
    label:
      'Груз, инструмент или предмет, перемещаемый или поднимаемый, в том числе на высоту',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.4',
    label: 'Плохо или неправильно закрепленные детали или заготовки',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.5',
    label: 'Вращающиеся или движущие детали оборудования или инструменты',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.6',
    label: 'Осколки оборудования, деталей, инструмента, стекла',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.7',
    label:
      'Упругий элемент применяемых материалов или оборудования, или конструкций',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.8',
    label:
      'Предметы и элементы конструкции, расположенные на путях следования, в том числе из-за неправильной организации рабочего места',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.9',
    label: 'Другие опасности, связанные с получением работником удара',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.10',
    label:
      'Неподвижная или подвижная, в том числе вращающаяся колющая поверхность (острие)',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.11',
    label:
      'Другие опасности, связанные с получением работником колотой раны или прокола',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.12',
    label: 'Подвижные части машин и механизмов',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.13',
    label:
      'Другие опасности, связанные с затягиванием работника в механизмы и агрегаты',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.14',
    label:
      'Абразивные материалы, в том числе необработанная древесина (в том числе вертикальные и горизонтальные поверхности)',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.15',
    label:
      'Другие опасности, связанные с трением или абразивным воздействием при соприкосновении',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.16',
    label: 'Движущиеся режущие части механизмов, машин',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.17',
    label: 'Острые кромки и заусенцы',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.18',
    label: 'Дисковые ножи, дисковые пилы и другое (кроме ножей поварских)',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.19',
    label: 'Ножи и приспособления, применяемые при обвалке мяса',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.20',
    label: 'Ручная цепная пила',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.21',
    label:
      'Металлическая стружка с острыми кромками, возникающая при механической обработке металлических заготовок и деталей',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.22',
    label:
      'Стеклянные, фарфоровые или керамические предметы, а также пластиковые изделия острыми кромками',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.23',
    label: 'Другие опасности, связанные с порезом частей тела работника',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.24',
    label: 'Мелкие стружки, мелкие осколки, крупнодисперсная пыль',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.25',
    label:
      'Другие опасности, связанные с повреждением глаз вследствие попадания инородного тела',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.26',
    label:
      'Выполнение работ на значительной глубине в туннелях, под землей, в открытых разрезах, в том числе связанных с добычей полезных ископаемых и эксплуатацией шахт',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.27',
    label: 'Другие опасности, связанные с работой на глубине',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '1.28',
    label: 'Прочие механические опасности',
    dependence: 'Механические опасности',
    dependenceID: 1,
  },
  {
    ID: '2.1',
    label: 'Общие производственные загрязнения',
    dependence:
      'Опасность, связанная с воздействием общих производственных загрязнений',
    dependenceID: 2,
  },
  {
    ID: '3.1',
    label: 'Электрический ток',
    dependence:
      'Опасности, связанные с воздействием электрического тока, статического электричества, а также с воздействием термических рисков электрической дуги',
    dependenceID: 3,
  },
  {
    ID: '3.2',
    label: 'Шаговое напряжение',
    dependence:
      'Опасности, связанные с воздействием электрического тока, статического электричества, а также с воздействием термических рисков электрической дуги',
    dependenceID: 3,
  },
  {
    ID: '3.3',
    label:
      'Искры, возникающие вследствие накопления статического электричества, в том числе при работе во взрывопожарноопасной среде',
    dependence:
      'Опасности, связанные с воздействием электрического тока, статического электричества, а также с воздействием термических рисков электрической дуги',
    dependenceID: 3,
  },
  {
    ID: '3.4',
    label:
      'Наведенное напряжение в отключенной электрической цепи (электромагнитное воздействие параллельной воздушной электрической линии или электричества, циркулирующего в контактной сети)',
    dependence:
      'Опасности, связанные с воздействием электрического тока, статического электричества, а также с воздействием термических рисков электрической дуги',
    dependenceID: 3,
  },
  {
    ID: '3.5',
    label: 'Энергия, выделяемая при возникновении электрической дуги',
    dependence:
      'Опасности, связанные с воздействием электрического тока, статического электричества, а также с воздействием термических рисков электрической дуги',
    dependenceID: 3,
  },
  {
    ID: '3.6',
    label:
      'Другие опасности, связанные с воздействием электрического тока, статического электричества, а также с воздействием термических рисков электрической дуги',
    dependence:
      'Опасности, связанные с воздействием электрического тока, статического электричества, а также с воздействием термических рисков электрической дуги',
    dependenceID: 3,
  },
  {
    ID: '4.1',
    label:
      'Поверхности, имеющие высокую температуру (воздействие конвективной теплоты)',
    dependence:
      'Опасности, связанные с воздействием повышенных/пониженных температур',
    dependenceID: 4,
  },
  {
    ID: '4.2',
    label: 'Материал, жидкость или газ, имеющие высокую температуру',
    dependence:
      'Опасности, связанные с воздействием повышенных/пониженных температур',
    dependenceID: 4,
  },
  {
    ID: '4.3',
    label: 'Другие опасности, связанные с воздействием конвективной теплоты',
    dependence:
      'Опасности, связанные с воздействием повышенных/пониженных температур',
    dependenceID: 4,
  },
  {
    ID: '4.4',
    label:
      'Энергия открытого пламени, выплесков металлов, искр и брызг расплавленного металла и металлической окалины',
    dependence:
      'Опасности, связанные с воздействием повышенных/пониженных температур',
    dependenceID: 4,
  },
  {
    ID: '4.5',
    label: 'Охлажденная поверхность, охлажденная жидкость или газ',
    dependence:
      'Опасности, связанные с воздействием повышенных/пониженных температур',
    dependenceID: 4,
  },
  {
    ID: '4.6',
    label:
      'Другие опасности, связанные с повышенными и пониженными температурами',
    dependence:
      'Опасности, связанные с воздействием повышенных/пониженных температур',
    dependenceID: 4,
  },
  {
    ID: '4.7',
    label:
      'Низкая температура окружающей среды в рабочей зоне, в том числе связанная с климатом',
    dependence:
      'Опасности, связанные с воздействием повышенных/пониженных температур',
    dependenceID: 4,
  },
  {
    ID: '4.8',
    label:
      'Высокая температура окружающей среды, в рабочей зоне, в том числе связанная с климатом',
    dependence:
      'Опасности, связанные с воздействием повышенных/пониженных температур',
    dependenceID: 4,
  },
  {
    ID: '4.9',
    label:
      'Высокая влажность окружающей среды в рабочей зоне, в том числе, связанная с климатом',
    dependence:
      'Опасности, связанные с воздействием повышенных/пониженных температур',
    dependenceID: 4,
  },
  {
    ID: '4.10',
    label: 'Низкая температура окружающей среды и ветер на рабочем месте',
    dependence:
      'Опасности, связанные с воздействием повышенных/пониженных температур',
    dependenceID: 4,
  },
  {
    ID: '5.1',
    label:
      'Недостаток кислорода в воздухе рабочей зоны в замкнутых технологических емкостях, из-за вытеснения его другими газами или жидкостями',
    dependence: 'Опасности, связанные с недостатком кислорода',
    dependenceID: 5,
  },
  {
    ID: '5.2',
    label:
      'Недостаток кислорода в воздухе рабочей зоны при работе в подземных сооружениях',
    dependence: 'Опасности, связанные с недостатком кислорода',
    dependenceID: 5,
  },
  {
    ID: '5.3',
    label:
      'Другие опасности, связанные с недостатком кислорода в воздухе рабочей зоны',
    dependence: 'Опасности, связанные с недостатком кислорода',
    dependenceID: 5,
  },
  {
    ID: '6.1',
    label: 'Вода и растворы нетоксичных веществ',
    dependence: 'Химические опасности',
    dependenceID: 6,
  },
  {
    ID: '6.2',
    label: 'Высокоопасные вещества',
    dependence: 'Химические опасности',
    dependenceID: 6,
  },
  {
    ID: '6.3',
    label:
      'Растворы кислот, щелочей, смазочно-охлаждающих жидкостей на водной основе, щелочемасляных эмульсий',
    dependence: 'Химические опасности',
    dependenceID: 6,
  },
  {
    ID: '6.4',
    label:
      'Повышенная концентрация паров вредных жидкостей, газов в воздухе рабочей зоны (а также пыль, туман, дым)',
    dependence: 'Химические опасности',
    dependenceID: 6,
  },
  {
    ID: '6.5',
    label: 'Вещества, способные вызвать химический ожог роговицы глаза',
    dependence: 'Химические опасности',
    dependenceID: 6,
  },
  {
    ID: '6.6',
    label:
      'Токсичные пары, газы, аэрозоли, выделяемые при нагревании или горении, не связанном с технологическим процессом производства, в том числе при пожаре',
    dependence: 'Химические опасности',
    dependenceID: 6,
  },
  {
    ID: '6.7',
    label:
      'Нефть, нефтепродукты, смазочные масла, воздействующие на кожные покровы',
    dependence: 'Химические опасности',
    dependenceID: 6,
  },
  {
    ID: '6.8',
    label: 'Чистящие и обеззараживающие, дезинфицирующие вещества',
    dependence: 'Химические опасности',
    dependenceID: 6,
  },
  {
    ID: '6.9',
    label:
      'Другие опасности, связанные с воздействием химического фактора на работника',
    dependence: 'Химические опасности',
    dependenceID: 6,
  },
  {
    ID: '7.1',
    label: 'Пыль в воздухе рабочей зоны',
    dependence:
      'Опасность воздействия повышенной концентрации аэрозолей преимущественно фиброгенного действия (АПФД)',
    dependenceID: 7,
  },
  {
    ID: '7.2',
    label: 'Взвеси вредных химических веществ в воздухе рабочей зоны',
    dependence:
      'Опасность воздействия повышенной концентрации аэрозолей преимущественно фиброгенного действия (АПФД)',
    dependenceID: 7,
  },
  {
    ID: '7.3',
    label:
      'Аэрозоли, воздушные взвеси, содержащие смазочные масла, чистящие и обезжиривающие вещества в воздухе рабочей зоны',
    dependence:
      'Опасность воздействия повышенной концентрации аэрозолей преимущественно фиброгенного действия (АПФД)',
    dependenceID: 7,
  },
  {
    ID: '7.4',
    label: 'Другие опасности, связанные с воздействием АПФД на работника',
    dependence:
      'Опасность воздействия повышенной концентрации аэрозолей преимущественно фиброгенного действия (АПФД)',
    dependenceID: 7,
  },
  {
    ID: '8.1',
    label:
      'Наличие микроорганизмов-продуцентов, препаратов, содержащих живые клетки и споры микроорганизмов в окружающей среде: воздухе, воде, на поверхностях',
    dependence:
      'Опасность воздействия биологического фактора (микроорганизмы, насекомые, паукообразные)',
    dependenceID: 8,
  },
  {
    ID: '8.2',
    label: 'Патогенные микроорганизмы',
    dependence:
      'Опасность воздействия биологического фактора (микроорганизмы, насекомые, паукообразные)',
    dependenceID: 8,
  },
  {
    ID: '8.3',
    label:
      'Наличие на рабочем месте паукообразных и кровососущих насекомых, способных являться переносчиками тяжелых инфекций',
    dependence:
      'Опасность воздействия биологического фактора (микроорганизмы, насекомые, паукообразные)',
    dependenceID: 8,
  },
  {
    ID: '8.4',
    label:
      'Другие опасности, связанные с воздействием биологического фактора на работника',
    dependence:
      'Опасность воздействия биологического фактора (микроорганизмы, насекомые, паукообразные)',
    dependenceID: 8,
  },
  {
    ID: '9.1',
    label:
      'Физические перегрузки при чрезмерных физических усилиях при подъеме предметов и деталей, при перемещении предметов и деталей',
    dependence: 'Опасность физических перегрузок',
    dependenceID: 9,
  },
  {
    ID: '9.2',
    label:
      'Физические перегрузки при стереотипных рабочих движениях, а также при статических нагрузках',
    dependence: 'Опасность физических перегрузок',
    dependenceID: 9,
  },
  {
    ID: '9.3',
    label:
      'Физические перегрузки при неудобной рабочей позе, в том числе при наклонах корпуса тела работника более чем на 30°',
    dependence: 'Опасность физических перегрузок',
    dependenceID: 9,
  },
  {
    ID: '9.4',
    label: 'Другие опасности, связанные с физической перегрузкой',
    dependence: 'Опасность физических перегрузок',
    dependenceID: 9,
  },
  {
    ID: '10.1',
    label:
      'Повышенный уровня шума и другие неблагоприятные характеристики шума',
    dependence: 'Опасность воздействия повышенного уровня шума',
    dependenceID: 10,
  },
  {
    ID: '10.2',
    label:
      'Повышенный (низкочастотный) уровень ультразвуковых колебаний (воздушный и контактный ультразвук)',
    dependence: 'Опасность воздействия повышенного уровня шума',
    dependenceID: 10,
  },
  {
    ID: '10.3',
    label:
      'Другие опасности, связанные с повышенным уровнем шума в рабочей зоне',
    dependence: 'Опасность воздействия повышенного уровня шума',
    dependenceID: 10,
  },
  {
    ID: '11.1',
    label: 'Повышенная вибрация при использовании ручных механизмов',
    dependence: 'Опасность от воздействия повышенной вибрации',
    dependenceID: 11,
  },
  {
    ID: '11.2',
    label: 'Повышенная общая вибрация',
    dependence: 'Опасность от воздействия повышенной вибрации',
    dependenceID: 11,
  },
  {
    ID: '11.3',
    label: 'Другие опасности, связанные с воздействием повышенной вибрации',
    dependence: 'Опасность от воздействия повышенной вибрации',
    dependenceID: 11,
  },
  {
    ID: '12.1',
    label:
      'Недостаточная видимость (различимость) работника для других лиц, в том числе управляющих опасными машинами, механизмами',
    dependence:
      'Опасность, связанная с освещением/контрастностью в рабочей зоне',
    dependenceID: 12,
  },
  {
    ID: '12.2',
    label: 'Повышенная яркость света',
    dependence:
      'Опасность, связанная с освещением/контрастностью в рабочей зоне',
    dependenceID: 12,
  },
  {
    ID: '12.3',
    label: 'Пониженная контрастность',
    dependence:
      'Опасность, связанная с освещением/контрастностью в рабочей зоне',
    dependenceID: 12,
  },
  {
    ID: '12.4',
    label: 'Другие опасности, связанные с воздействием световой среды',
    dependence:
      'Опасность, связанная с освещением/контрастностью в рабочей зоне',
    dependenceID: 12,
  },
  {
    ID: '13.1',
    label: 'Электростатические поля',
    dependence:
      'Опасности, связанные с воздействием повышенного уровня неионизирующих излучений',
    dependenceID: 13,
  },
  {
    ID: '13.2',
    label: 'Электромагнитные поля',
    dependence:
      'Опасности, связанные с воздействием повышенного уровня неионизирующих излучений',
    dependenceID: 13,
  },
  {
    ID: '13.3',
    label: 'Электрические поля промышленной частоты',
    dependence:
      'Опасности, связанные с воздействием повышенного уровня неионизирующих излучений',
    dependenceID: 13,
  },
  {
    ID: '13.4',
    label: 'Тепловое излучение',
    dependence:
      'Опасности, связанные с воздействием повышенного уровня неионизирующих излучений',
    dependenceID: 13,
  },
  {
    ID: '13.5',
    label: 'Лазерное излучение',
    dependence:
      'Опасности, связанные с воздействием повышенного уровня неионизирующих излучений',
    dependenceID: 13,
  },
  {
    ID: '13.6',
    label: 'Ультрафиолетовое излучение',
    dependence:
      'Опасности, связанные с воздействием повышенного уровня неионизирующих излучений',
    dependenceID: 13,
  },
  {
    ID: '13.7',
    label:
      'Другие опасности, связанные с воздействием неионизирующих излучений',
    dependence:
      'Опасности, связанные с воздействием повышенного уровня неионизирующих излучений',
    dependenceID: 13,
  },
  {
    ID: '14.1',
    label: 'Гамма-излучение',
    dependence: 'Опасности, связанные с воздействием ионизирующих излучений',
    dependenceID: 14,
  },
  {
    ID: '14.2',
    label: 'Рентгеновское излучение',
    dependence: 'Опасности, связанные с воздействием ионизирующих излучений',
    dependenceID: 14,
  },
  {
    ID: '14.3',
    label:
      'Альфа-, бета-излучение, электронное или ионное и нейтронное излучение',
    dependence: 'Опасности, связанные с воздействием ионизирующих излучений',
    dependenceID: 14,
  },
  {
    ID: '14.4',
    label:
      'Другие опасности, связанные с воздействием ионизирующих излучений',
    dependence: 'Опасности, связанные с воздействием ионизирующих излучений',
    dependenceID: 14,
  },
  {
    ID: '15.1',
    label: 'Дикие или домашние животные',
    dependence:
      'Опасность нанесения увечий животными (млекопитающими, птицами, рыбами)',
    dependenceID: 15,
  },
  {
    ID: '15.2',
    label: 'Другие опасности, связанные с воздействием животных на работника',
    dependence:
      'Опасность нанесения увечий животными (млекопитающими, птицами, рыбами)',
    dependenceID: 15,
  },
  {
    ID: '16.1',
    label: 'Пыльца, фитонциды и другие вещества, выделяемые растениями',
    dependence: 'Опасность негативного воздействия растений',
    dependenceID: 16,
  },
  {
    ID: '16.2',
    label: 'Растения, выделяющие вещества, приводящие к ожогам',
    dependence: 'Опасность негативного воздействия растений',
    dependenceID: 16,
  },
  {
    ID: '16.3',
    label: 'Растения с листьями и стеблями, способными повредить кожу',
    dependence: 'Опасность негативного воздействия растений',
    dependenceID: 16,
  },
  {
    ID: '16.4',
    label: 'Другие опасности, связанные с воздействием растений',
    dependence: 'Опасность негативного воздействия растений',
    dependenceID: 16,
  },
  {
    ID: '17.1',
    label: 'Наличие в атмосфере дыма, паров вредных газов и пыли при пожаре',
    dependence: 'Опасность, связанная с пожаром',
    dependenceID: 17,
  },
  {
    ID: '17.2',
    label: 'Другие опасности, связанные с пожаром',
    dependence: 'Опасность, связанная с пожаром',
    dependenceID: 17,
  },
  {
    ID: '18.1',
    label: 'Транспортное средство, в том числе погрузчик',
    dependence: 'Опасности, связанные с транспортными средствами',
    dependenceID: 18,
  },
  {
    ID: '18.2',
    label: 'Другие опасности, связанные с транспортными средствами',
    dependence: 'Опасности, связанные с транспортными средствами',
    dependenceID: 18,
  },
  {
    ID: '19.1',
    label: 'Горючие вещества способные к самовозгоранию',
    dependence: 'Опасности, связанные с работой во взрывопожароопасной среде',
    dependenceID: 19,
  },
  {
    ID: '19.2',
    label:
      'Наличие огнеопасных веществ на рабочем месте, способных взорваться при действии открытого пламени, в том числе при пожаре',
    dependence: 'Опасности, связанные с работой во взрывопожароопасной среде',
    dependenceID: 19,
  },
  {
    ID: '19.3',
    label: 'Ударная волна от взрыва',
    dependence: 'Опасности, связанные с работой во взрывопожароопасной среде',
    dependenceID: 19,
  },
  {
    ID: '19.4',
    label:
      'Другие опасности, связанные с работой во взрывопожароопасной среде',
    dependence: 'Опасности, связанные с работой во взрывопожароопасной среде',
    dependenceID: 19,
  },
  {
    ID: '20.1',
    label: 'Трудносмываемые загрязнители',
    dependence: 'Опасность, связанная с загрязнением одежды специальной',
    dependenceID: 20,
  },
  {
    ID: '20.2',
    label: 'Другие опасности, связанные с загрязнением одежды специальной',
    dependence: 'Опасность, связанная с загрязнением одежды специальной',
    dependenceID: 20,
  },
];

export default danger;
