const prof = [
  {
    profId: 1,
    label: 'Авербандщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2,
    label: 'Авиационный механик (техник) по планеру и двигателям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от статического электричества, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3,
    label: 'Авиационный механик (техник) по приборам и спасательным средствам',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от статического электричества, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), воды, нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4,
    label: 'Авиационный механик (техник) по радиооборудованию',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от статического электричества, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5,
    label:
      'Авиационный техник (механик) по парашютным и аварийно-спасательным средствам',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 6,
    label: 'Авиационный техник по горюче-смазочным материалам',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от статического электричества, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 7,
    label: 'Автоклавщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 8,
    label: 'Автоклавщик литья под давлением',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, механических воздействий (истирания)',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 9,
    label: 'Автоклавщик на запарке брикетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 10,
    label:
      'Автоклавщик-сушильщик аккумуляторных пластин в производстве свинцовых аккумуляторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 11,
    label: 'Автоматчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 12,
    label: 'Автоматчик вязальных автоматов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 13,
    label: 'Автоматчик игольно-платинных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 14,
    label: 'Автоматчик картонажного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 15,
    label: 'Автоматчик клеильных полуавтоматов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 16,
    label: 'Автоматчик на узловязальных и навивочных автоматах и станках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 17,
    label: 'Автоматчик по изготовлению деталей клавишных инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 18,
    label: 'Автоматчик ремизных автоматов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 19,
    label: 'Автоматчик холодновысадочных автоматов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 20,
    label: 'Автоматчик элементного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 21,
    label: 'Агломератчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения, кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения, кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 22,
    label: 'Аквадировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 23,
    label: 'Акклиматизатор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 24,
    label: 'Аккумуляторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 25,
    label: 'Алундировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 26,
    label: 'Алюминировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 27,
    label: 'Алюминировщик электротехнических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 28,
    label: 'Ангобировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 29,
    label: 'Ангобировщик санитарно-строительных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: ' ',
      },
    ],
  },
  {
    profId: 30,
    label: 'Анодчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 31,
    label: 'Анодчик в производстве алюминия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты, теплового излучения, кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 32,
    label: 'Антенщик-мачтовик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 33,
    label: 'Антикоррозийщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Одежда специальная для ограниченной защиты от токсичных веществ',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 34,
    label: 'Аппаратчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 35,
    label: 'Аппаратчик абсолютирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 36,
    label: 'Аппаратчик адсорбции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 37,
    label: 'Аппаратчик азотирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 38,
    label: 'Аппаратчик активации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 39,
    label: 'Аппаратчик аммонолиза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 40,
    label: 'Аппаратчик аппретирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 41,
    label: 'Аппаратчик асептического консервирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 42,
    label: 'Аппаратчик ацеталирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 43,
    label: 'Аппаратчик ацилирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 44,
    label: 'Аппаратчик балансовых установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 45,
    label: 'Аппаратчик бельевых сушильных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 46,
    label: 'Аппаратчик бисульфитирования ванилина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 47,
    label: 'Аппаратчик бучения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 48,
    label: 'Аппаратчик вакуумирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 49,
    label: 'Аппаратчик вакуум-приемников',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 50,
    label: 'Аппаратчик вакуум-сушильной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 51,
    label: 'Аппаратчик вакуум-термической печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 52,
    label: 'Аппаратчик валки изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 53,
    label: 'Аппаратчик-вальцевар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 54,
    label: 'Аппаратчик варки утфеля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 55,
    label: 'Аппаратчик витаминизации жира',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 56,
    label: 'Аппаратчик водно-химической обработки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 57,
    label: 'Аппаратчик возгонки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 58,
    label: 'Аппаратчик воздухоразделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 59,
    label: 'Аппаратчик восстановления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 60,
    label: 'Аппаратчик восстановления молока',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 61,
    label: 'Аппаратчик восстановления гексафторида',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 62,
    label: 'Аппаратчик восстановления полупроводниковых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 63,
    label: 'Аппаратчик в производстве драгоценных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 64,
    label: 'Аппаратчик в производстве металлических порошков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 65,
    label:
      'Аппаратчик в производстве ультрадисперсных порошков (металлических)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 66,
    label: 'Аппаратчик в производстве солей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 67,
    label: 'Аппаратчик в производстве твердых сплавов и тугоплавких металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 68,
    label: 'Аппаратчик в производстве титана и редких металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 69,
    label: 'Аппаратчик вспенивания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 70,
    label: 'Аппаратчик вулканизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 71,
    label: 'Аппаратчик выделения ацетофенона',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 72,
    label: 'Аппаратчик выделения карбинола',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 73,
    label: 'Аппаратчик выделения псевдобутилена',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 74,
    label: 'Аппаратчик выделения серы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 75,
    label: 'Аппаратчик выделения фтористого бора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 76,
    label: 'Аппаратчик выпаривания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 77,
    label: 'Аппаратчик выпаривания и гранулирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 78,
    label: 'Аппаратчик выпарных аппаратов для получения жидкого продукта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 79,
    label: 'Аппаратчик выпарных аппаратов для получения твердого продукта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 80,
    label: 'Аппаратчик выращивания дрожжей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 81,
    label: 'Аппаратчик высаждения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 82,
    label: 'Аппаратчик вытопки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 83,
    label: 'Аппаратчик вытяжки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 84,
    label: 'Аппаратчик выщелачивания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 85,
    label: 'Аппаратчик газового консервирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 86,
    label: 'Аппаратчик газовых смесей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 87,
    label: 'Аппаратчик газоочистки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 88,
    label: 'Аппаратчик газоразделительного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 89,
    label: 'Аппаратчик газогенерации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 90,
    label: 'Аппаратчик газоразделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 91,
    label: 'Аппаратчик галоидирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 92,
    label: 'Аппаратчик гидролиза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 93,
    label: 'Аппаратчик гашения извести',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), от растворов кислот',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 94,
    label: 'Аппаратчик гидратации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 95,
    label: 'Аппаратчик гидрирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 96,
    label: 'Аппаратчик гидролиза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 97,
    label: 'Аппаратчик гидролиза жира и печени',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 98,
    label: 'Аппаратчик гидролиза крахмального молока',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 99,
    label: 'Аппаратчик-гидрометаллург',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 100,
    label: 'Аппаратчик гидрохлорирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 101,
    label: 'Аппаратчик гомогенизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 102,
    label: 'Аппаратчик гомогенизации пластических масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 103,
    label: 'Аппаратчик гранулирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 104,
    label: 'Аппаратчик деаэрации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 105,
    label: 'Аппаратчик дегидратации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 106,
    label: 'Аппаратчик дегидрирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 107,
    label: 'Аппаратчик дезодорации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 108,
    label: 'Аппаратчик декарбоксилирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 109,
    label: 'Аппаратчик деполимеризации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 110,
    label: 'Аппаратчик десорбции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 111,
    label: 'Аппаратчик деструкции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 112,
    label: 'Аппаратчик деструкции фторопластов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 113,
    label: 'Аппаратчик десублимации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 114,
    label: 'Аппаратчик дефекосатурации диффузионного сока',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 115,
    label: 'Аппаратчик димеризации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 116,
    label: 'Аппаратчик диспергирования пигментов и красителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 117,
    label: 'Аппаратчик диспергирования щелочных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 118,
    label: 'Аппаратчик диспропорционирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 119,
    label: 'Аппаратчик диффузии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 120,
    label: 'Аппаратчик дозирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 121,
    label: 'Аппаратчик-дозировщик на изготовлении и поливе фотоэмульсий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 122,
    label: 'Аппаратчик дозревания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозбльные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 123,
    label: 'Аппаратчик дубления и сушки белковой оболочки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 124,
    label: 'Аппаратчик дубления (кожевенное и кожсырьевое производство)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 125,
    label: 'Аппаратчик дубления (меховое производство)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 126,
    label: 'Аппаратчик енолизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 127,
    label: 'Аппаратчик желатинизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 128,
    label: 'Аппаратчик жироочистки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 129,
    label: 'Аппаратчик-заварщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 130,
    label: 'Аппаратчик замораживания пищевого сырья и продуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 131,
    label: 'Аппаратчик запаривания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 132,
    label: 'Аппаратчик запаривания одонков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 133,
    label: 'Аппаратчик золения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 134,
    label: 'Аппаратчик золки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 135,
    label: 'Аппаратчик извлечения побочных продуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 136,
    label: 'Аппаратчик изготовления армированных прессовочных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 137,
    label: 'Аппаратчик изготовления баритмассы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 138,
    label: 'Аппаратчик изготовления искусственного шеелита',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 139,
    label: 'Аппаратчик изготовления микросфер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 140,
    label: 'Аппаратчик изготовления нетканых стекловолокнистых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 141,
    label: 'Аппаратчик изготовления пленочных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 142,
    label: 'Аппаратчик изготовления резиновых нитей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 143,
    label: 'Аппаратчик изготовления рентгеновских экранов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 144,
    label: 'Аппаратчик изготовления термопасты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 145,
    label: 'Аппаратчик измельчения и предсозревания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 146,
    label: 'Аппаратчик изомеризации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 147,
    label: 'Аппаратчик имидирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 148,
    label: 'Аппаратчик-индулиновар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 149,
    label: 'Аппаратчик йодирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 150,
    label: 'Аппаратчик ионного обмена',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 151,
    label: 'Аппаратчик ионообменной очистки глицерина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 152,
    label: 'Аппаратчик ионообменной очистки химико-фармацевтических препаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 153,
    label: 'Аппаратчик испарения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 154,
    label: 'Аппаратчик испарительной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 155,
    label: 'Аппаратчик калибровки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 156,
    label: 'Аппаратчик карбидизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 157,
    label: 'Аппаратчик карбонизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 158,
    label: 'Аппаратчик каустификации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 159,
    label: 'Аппаратчик кислотной обработки спилка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от воды, растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 160,
    label: 'Аппаратчик коагулирования шляма',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 161,
    label: 'Аппаратчик коагуляции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 162,
    label: 'Аппаратчик коконозапарочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 163,
    label: 'Аппаратчик комбикормового производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 164,
    label: 'Аппаратчик-комплекторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 165,
    label: 'Аппаратчик конверсии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 166,
    label: 'Аппаратчик-конденсаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 167,
    label: 'Аппаратчик конденсационно-испарительной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 168,
    label: 'Аппаратчик конденсации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 169,
    label: 'Аппаратчик контактирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 170,
    label: 'Аппаратчик контактной выпарки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 171,
    label: 'Аппаратчик концентрирования кислот',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от воды, растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от растворов кислот, общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 172,
    label: 'Аппаратчик коньячного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 173,
    label: 'Аппаратчик коррозионных испытаний',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 174,
    label: 'Аппаратчик-краскотер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 175,
    label: 'Аппаратчик крахмального агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 176,
    label: 'Аппаратчик крашения и жирования кож',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и химических факторов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 177,
    label: 'Аппаратчик кристаллизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 178,
    label: 'Аппаратчик кристаллизации и центрифугирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 179,
    label: 'Аппаратчик крупяного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 180,
    label: 'Аппаратчик ксантогенирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 181,
    label: 'Аппаратчик-лаковар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 182,
    label: 'Аппаратчик лесохимической установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 183,
    label: 'Аппаратчик литья и рубки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 184,
    label: 'Аппаратчик матирования смолы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 185,
    label: 'Аппаратчик мерсеризации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 186,
    label: 'Аппаратчик металлопокрытий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 187,
    label: 'Аппаратчик мокрой классификации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 188,
    label: 'Аппаратчик мукомольного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 189,
    label: 'Аппаратчик мыловарения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 190,
    label: 'Аппаратчик мягчения кожевенных полуфабрикатов и меховых шкурок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 191,
    label: 'Аппаратчик нагрева теплоносителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 192,
    label: 'Аппаратчик-наладчик электролизно-обменной цепочки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 193,
    label: 'Аппаратчик на изготовлении микропроводов в стекольной изоляции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 194,
    label: 'Аппаратчик нанесения полимерных и парафиновых покрытий на сыры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 195,
    label: 'Аппаратчик нанесения металлического порошка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 196,
    label: 'Аппаратчик на плазменных установках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 197,
    label: 'Аппаратчик на приготовлении смесей и растворов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 198,
    label: 'Аппаратчик на пропиточных агрегатах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 199,
    label: 'Аппаратчик напыления металлом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 200,
    label: 'Аппаратчик напыления стекловолокнистых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 201,
    label: 'Аппаратчик насыщения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 202,
    label: 'Аппаратчик на установках деионизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 203,
    label: 'Аппаратчик-нейтрализаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 204,
    label: 'Аппаратчик нейтрализации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 205,
    label: 'Аппаратчик никелирования стеклоткани',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 206,
    label: 'Аппаратчик нитрозного процесса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 207,
    label: 'Аппаратчик обезвоживания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 208,
    label: 'Аппаратчик обезвоживания битума',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 209,
    label: 'Аппаратчик обезвоздушивания и фильтрации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 210,
    label: 'Аппаратчик обезжиривания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 211,
    label: 'Аппаратчик обезжиривания сиропов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 212,
    label: 'Аппаратчик обеззоливания, мягчения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 213,
    label: 'Аппаратчик обессоливания воды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 214,
    label: 'Аппаратчик обесфеноливания и обеспиридинивания масел',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 215,
    label: 'Аппаратчик обжига',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 216,
    label: 'Аппаратчик облагораживания гексола',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 217,
    label: 'Аппаратчик облучения жира',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 218,
    label: 'Аппаратчик обогащения золотосодержащих руд',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 219,
    label: 'Аппаратчик обработки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 220,
    label: 'Аппаратчик обработки зерна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 221,
    label: 'Аппаратчик обработки крови',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 222,
    label: 'Аппаратчик обработки силикагеля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 223,
    label: 'Аппаратчик обработки эфирных масел',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 224,
    label: 'Аппаратчик ожижения водорода и гелия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 225,
    label: 'Аппаратчик окисления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 226,
    label: 'Аппаратчик окисления битума',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 227,
    label: 'Аппаратчик окисления молибденовых отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 228,
    label: 'Аппаратчик окраски квасцов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 229,
    label: 'Аппаратчик оксимирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 230,
    label: 'Аппаратчик оксихлорирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 231,
    label: 'Аппаратчик-олифовар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 232,
    label: 'Аппаратчик омыления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки, для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 233,
    label: 'Аппаратчик опытных процессов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 234,
    label: 'Аппаратчик осаждения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 235,
    label: 'Аппаратчик осаждения глютена',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 236,
    label: 'Аппаратчик осушки газа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), от контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания) и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 237,
    label: 'Аппаратчик ориентации органического стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 238,
    label: 'Аппаратчик отбеливания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 239,
    label: 'Аппаратчик отверждения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 240,
    label: 'Аппаратчик отделки и сушки химической нити',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 241,
    label: 'Аппаратчик отжига кристаллов корунда',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 242,
    label: 'Аппаратчик отжига хрома',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 243,
    label: 'Аппаратчик отжима',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 244,
    label: 'Аппаратчик откатки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 245,
    label: 'Аппаратчик-отливщик кинофотоосновы и техпленок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), от контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 246,
    label: 'Аппаратчик-отливщик магнитных лент',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) от контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 247,
    label: 'Аппаратчик-отливщик пленки бутафоль',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), от контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 248,
    label: 'Аппаратчик отстаивания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 249,
    label: 'Аппаратчик охлаждения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 250,
    label: 'Аппаратчик охлаждения молочных продуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 251,
    label: 'Аппаратчик очистки алмазного концентрата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 252,
    label: 'Аппаратчик очистки газа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), статического электричества, контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания) и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 253,
    label: 'Аппаратчик очистки жидкости',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 254,
    label: 'Аппаратчик очистки сточных вод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), химических факторов (растворов кислот и щелочей)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 255,
    label: 'Аппаратчик парафинирования ткани',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 256,
    label: 'Аппаратчик пароводотермического агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 257,
    label: 'Аппаратчик пассивации газоразделительного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 258,
    label: 'Аппаратчик пастеризации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 259,
    label: 'Аппаратчик перегонки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 260,
    label: 'Аппаратчик перегонки и ректификации спирта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 261,
    label: 'Аппаратчик перегревания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 262,
    label: 'Аппаратчик переработки водородосодержащих соединений лития',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 263,
    label: 'Аппаратчик переработки отходов химического производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 264,
    label: 'Аппаратчик переэтерификации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 265,
    label: 'Аппаратчик перколяции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 266,
    label: 'Аппаратчик печей восстановления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 267,
    label: 'Аппаратчик пиролиза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 268,
    label: 'Аппаратчик плавления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания) и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 269,
    label: 'Аппаратчик плюсования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 270,
    label: 'Аппаратчик по выработке уксуса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), растворов кислот',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), химических факторов (растворов кислот и щелочей)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 271,
    label: 'Аппаратчик по выращиванию монокристаллов и лент',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 272,
    label: 'Аппаратчик по выщелачиванию фибры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 273,
    label: 'Аппаратчик подготовки сырья и отпуска полуфабрикатов и продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 274,
    label: 'Аппаратчик-подслойщик фотостекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов), контакта с разогретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 275,
    label: 'Аппаратчик по загрузке пека',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов), контакта с разогретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 276,
    label: 'Аппаратчик по изготовлению источников ионизирующего излучения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 277,
    label: 'Аппаратчик по изготовлению керамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 278,
    label: 'Аппаратчик по изготовлению клеевой нити',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 279,
    label: 'Аппаратчик по изготовлению пластических составов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 280,
    label: 'Аппаратчик по изготовлению термитных изделий из ксенона',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 281,
    label: 'Аппаратчик по изготовлению шлифовальной шкурки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 282,
    label: 'Аппаратчик-поливщик магнитных лент',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 283,
    label: 'Аппаратчик-поливщик фотоэмульсий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 284,
    label: 'Аппаратчик покрытия изделий из спецматериалов, источников',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 285,
    label: 'Аппаратчик поликонденсации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 286,
    label: 'Аппаратчик полимеризации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 287,
    label: 'Аппаратчик получения высокотемпературного пека',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 288,
    label: 'Аппаратчик получения высокочистых соединений щелочных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 289,
    label: 'Аппаратчик получения гексафторида урана',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 290,
    label: 'Аппаратчик получения гексахлорбензола',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 291,
    label: 'Аппаратчик получения гидроокиси лития',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 292,
    label: 'Аппаратчик получения гидроокисных соединений щелочных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 293,
    label: 'Аппаратчик получения декстрина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 294,
    label: 'Аппаратчик получения закиси азота',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 295,
    label: 'Аппаратчик получения зародышей двуокиси титана',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды, и химических факторов (растворов кислот и щелочей)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 296,
    label: 'Аппаратчик получения карбонатов лития',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 297,
    label: 'Аппаратчик получения комплексных соединений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 298,
    label: 'Аппаратчик получения кукурузного масла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 299,
    label: 'Аппаратчик получения кумароновой смолы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 300,
    label: 'Аппаратчик получения лаков и эмалей на полимеризационных смолах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 301,
    label: 'Аппаратчик получения метатитановой кислоты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 302,
    label: 'Аппаратчик получения микронизированных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 303,
    label: 'Аппаратчик получения нитролигнина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 304,
    label: 'Аппаратчик получения окислов металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), химических факторов (растворов кислот и щелочей)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 305,
    label: 'Аппаратчик получения пата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 306,
    label: 'Аппаратчик получения полых микросфер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 307,
    label: 'Аппаратчик получения сернистой кислоты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 308,
    label: 'Аппаратчик получения сероуглерода-сырца',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 309,
    label:
      'Аппаратчик получения сильнодействующих алкалоидов и кристаллических гликозидов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), и химических факторов (растворов кислот и щелочей)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 310,
    label: 'Аппаратчик получения синтетических гормонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), и химических факторов (растворов кислот и щелочей)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 311,
    label: 'Аппаратчик получения сиропов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), химических факторов (растворов кислот и щелочей)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 312,
    label: 'Аппаратчик получения составов для рентгеновских экранов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 313,
    label: 'Аппаратчик получения сульфата аммония',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 314,
    label: 'Аппаратчик получения сухих кормов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 315,
    label: 'Аппаратчик получения сухого крахмала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 316,
    label: 'Аппаратчик получения сырого бензола',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (нефти и/или нефтепродуктов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 317,
    label: 'Аппаратчик получения сырого крахмала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 318,
    label: 'Аппаратчик получения технического углерода',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 319,
    label: 'Аппаратчик получения трифторида хлора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 320,
    label: 'Аппаратчик получения трихлорпропана и дихлоргидина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (нефти и/или нефтепродуктов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 321,
    label: 'Аппаратчик получения углекислоты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 322,
    label: 'Аппаратчик получения фосфатидов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 323,
    label: 'Аппаратчик получения фотогипосульфита',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 324,
    label: 'Аппаратчик получения фреонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 325,
    label: 'Аппаратчик получения фтористого аммония',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 326,
    label: 'Аппаратчик получения фтористого водорода',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 327,
    label: 'Аппаратчик получения фтористого кальция',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 328,
    label:
      'Аппаратчик получения фтористых соединений редких и редкоземельных элементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 329,
    label: 'Аппаратчик получения фторсодержащих соединений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 330,
    label: 'Аппаратчик получения фурановых соединений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 331,
    label: 'Аппаратчик получения химически чистой плавиковой кислоты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 332,
    label: 'Аппаратчик получения хлорида лития',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 333,
    label: 'Аппаратчик получения хлорного железа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 334,
    label: 'Аппаратчик получения чистого антрацена',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 335,
    label: 'Аппаратчик получения экструзионных продуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 336,
    label: 'Аппаратчик по насасыванию диафрагм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 337,
    label: 'Аппаратчик по напылению селеном',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 338,
    label: 'Аппаратчик по обработке и купажированию уксуса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 339,
    label: 'Аппаратчик по обработке сырого пектина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 340,
    label: 'Аппаратчик по обслуживанию рекуператоров системы и охлаждения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 341,
    label: 'Аппаратчик по окислению кадмия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), от химических факторов (нефти и/или нефтепродуктов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 342,
    label:
      'Аппаратчик по переработке, разделению и очистке химических соединений металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 343,
    label: 'Аппаратчик по подготовке продукта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 344,
    label:
      'Аппаратчик по получению высокочистых материалов для полупроводникового производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 345,
    label: 'Аппаратчик по приготовлению глинозема',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 346,
    label: 'Аппаратчик по приготовлению майонеза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 347,
    label: 'Аппаратчик по приготовлению химреагентов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 348,
    label: 'Аппаратчик по производству ванадия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 349,
    label:
      'Аппаратчик по производству и химической очистке полупроводниковых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 350,
    label: 'Аппаратчик по производству синтетических клеящих смол',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 351,
    label: 'Аппаратчик по производству сухой спичечной соломки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм - для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 352,
    label: 'Аппаратчик по разделению благородных и редкоземельных элементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 353,
    label: 'Аппаратчик по разделению редкоземельных элементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 354,
    label: 'Аппаратчик по регенерации селена',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 355,
    label: 'Аппаратчик по регенерации серы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 356,
    label: 'Аппаратчик по сбору и обогащению шлама',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 357,
    label: 'Аппаратчик по сушке торфа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 358,
    label: 'Аппаратчик по химической обработке полупроводниковых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 359,
    label: 'Аппаратчик приготовления амальгамы натрия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 360,
    label: 'Аппаратчик приготовления брикетной смеси',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 361,
    label: 'Аппаратчик приготовления высокожирных эмульсий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), от химических факторов (нефти и/или нефтепродуктов), от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 362,
    label: 'Аппаратчик приготовления газовых смесей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 363,
    label: 'Аппаратчик приготовления дубильных экстрактов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 364,
    label: 'Аппаратчик приготовления замесов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 365,
    label: 'Аппаратчик приготовления зубоврачебных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 366,
    label: 'Аппаратчик приготовления инвертного сиропа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 367,
    label: 'Аппаратчик приготовления каменноугольного лака',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 368,
    label: 'Аппаратчик приготовления катализатора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 369,
    label: 'Аппаратчик приготовления компаундов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 370,
    label: 'Аппаратчик приготовления косметических средств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 371,
    label: 'Аппаратчик приготовления кулинарных и кондитерских жиров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 372,
    label: 'Аппаратчик приготовления лака',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 373,
    label: 'Аппаратчик приготовления латексной смеси',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 374,
    label: 'Аппаратчик приготовления медицинских масс и мазей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 375,
    label: 'Аппаратчик приготовления мездрового клея',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 376,
    label: 'Аппаратчик приготовления мыльного клея',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 377,
    label: 'Аппаратчик приготовления окисленного крахмала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 378,
    label: 'Аппаратчик приготовления парфюмерных композиций и жидкостей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 379,
    label: 'Аппаратчик приготовления пасты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 380,
    label: 'Аппаратчик приготовления питательных сред',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 381,
    label: 'Аппаратчик приготовления полимеризационной смеси',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 382,
    label: 'Аппаратчик приготовления препарированной смолы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 383,
    label: 'Аппаратчик приготовления пресс-порошков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 384,
    label: 'Аппаратчик приготовления проявляющей пасты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 385,
    label: 'Аппаратчик приготовления прядильных растворов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 386,
    label: 'Аппаратчик приготовления резиновых клеев и покрытий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 387,
    label: 'Аппаратчик приготовления связующих',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 388,
    label: 'Аппаратчик приготовления сернокислого глинозема',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 389,
    label: 'Аппаратчик приготовления стерильных растворов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 390,
    label: 'Аппаратчик приготовления смазок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 391,
    label: 'Аппаратчик приготовления суспензии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 392,
    label: 'Аппаратчик приготовления сырой смеси',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 393,
    label: 'Аппаратчик приготовления тресты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 394,
    label: 'Аппаратчик приготовления ферментного препарата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 395,
    label: 'Аппаратчик приготовления химических растворов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 396,
    label: 'Аппаратчик приготовления электролита',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 397,
    label: 'Аппаратчик приготовления эмульгатора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 398,
    label: 'Аппаратчик приготовления эмульсий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 399,
    label: 'Аппаратчик производства АГ-соли',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 400,
    label: 'Аппаратчик производства адипиновой кислоты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 401,
    label: 'Аппаратчик производства адипонитрила',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 402,
    label: 'Аппаратчик производства азокрасителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 403,
    label: 'Аппаратчик производства альбумина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 404,
    label: 'Аппаратчик производства аммиачной селитры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от, механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 405,
    label: 'Аппаратчик производства аэросилы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 406,
    label: 'Аппаратчик производства бакелитовой пленки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 407,
    label: 'Аппаратчик производства бактерийных препаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 408,
    label: 'Аппаратчик производства бертолетовой соли',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 409,
    label: 'Аппаратчик производства борной кислоты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 410,
    label: 'Аппаратчик производства бульонных кубиков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 411,
    label: 'Аппаратчик производства гематогена и медицинской желчи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 412,
    label: 'Аппаратчик производства гидросульфита натрия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 413,
    label: 'Аппаратчик производства горного воска',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 414,
    label: 'Аппаратчик производства граната',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 415,
    label: 'Аппаратчик производства двуокиси хлора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот',
        norm: ' ',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 416,
    label: 'Аппаратчик производства диметилтерефталата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов щелочей и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов щелочей',
        norm: ' ',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 417,
    label: 'Аппаратчик производства дициандиамида',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 418,
    label: 'Аппаратчик производства дициклопентадиена',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 419,
    label: 'Аппаратчик производства желтого фосфора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 420,
    label: 'Аппаратчик производства жидкого заменителя цельного молока',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 421,
    label: 'Аппаратчик производства заквасок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 422,
    label: 'Аппаратчик производства изотопов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 423,
    label: 'Аппаратчик производства индола',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки или',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 424,
    label: 'Аппаратчик производства казеинового клея',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 425,
    label: 'Аппаратчик производства калиевой селитры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов щелочей и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов щелочей',
        norm: ' ',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 426,
    label: 'Аппаратчик производства кисломолочных и детских молочных продуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 427,
    label: 'Аппаратчик производства контактной массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 428,
    label: 'Аппаратчик производства контактной серной кислоты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 429,
    label: 'Аппаратчик производства корунда',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 430,
    label: 'Аппаратчик производства костного клея',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 431,
    label: 'Аппаратчик производства красителей для меха',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 432,
    label: 'Аппаратчик производства красного фосфора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 433,
    label: 'Аппаратчик производства кремнийорганических лаков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 434,
    label: 'Аппаратчик производства креолина и лизола',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 435,
    label: 'Аппаратчик производства криолита',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защита от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 436,
    label: 'Аппаратчик производства литопона',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 437,
    label: 'Аппаратчик производства малотоннажных продуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 438,
    label: 'Аппаратчик производства мездрового клея',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 439,
    label: 'Аппаратчик производства металлического натрия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 440,
    label: 'Аппаратчик производства молочного сахара',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 441,
    label: 'Аппаратчик производства мочевины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 442,
    label: 'Аппаратчик производства мышьяковистых солей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 443,
    label: 'Аппаратчик производства надперекиси калия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 444,
    label: 'Аппаратчик производства нейтрального кремнегеля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 445,
    label: 'Аппаратчик производства нитрата и нитрита натрия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 446,
    label: 'Аппаратчик производства нитрофоски',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 447,
    label: 'Аппаратчик производства озокерита и озокеритовой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 448,
    label: 'Аппаратчик производства пиридиновых оснований',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 449,
    label: 'Аппаратчик производства пищевых жиров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 450,
    label: 'Аппаратчик производства плавленого сыра',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 451,
    label:
      'Аппаратчик производства полония, препаратов и источников на его основе',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 452,
    label: 'Аппаратчик производства препаратов ионизирующего излучения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 453,
    label:
      'Аппаратчик производства продукции из кожевенных и коллагенсодержащих отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 454,
    label: 'Аппаратчик производства реагентов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 455,
    label: 'Аппаратчик производства регенеративных веществ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 456,
    label: 'Аппаратчик производства светосоставов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 457,
    label: 'Аппаратчик производства силикагелей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 458,
    label: 'Аппаратчик производства силикатного клея',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 459,
    label: 'Аппаратчик производства синтетических красителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 460,
    label: 'Аппаратчик производства смазочного масла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 461,
    label: 'Аппаратчик производства спектрально-чистых газов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов, контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 462,
    label: 'Аппаратчик производства сульфата аммония',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 463,
    label: 'Аппаратчик производства сульфитных солей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 464,
    label: 'Аппаратчик производства сульфомасел и ядохимикатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 465,
    label: 'Аппаратчик производства сухих молочных продуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 466,
    label: 'Аппаратчик производства термической фосфорной кислоты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 467,
    label: 'Аппаратчик производства технической продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 468,
    label: 'Аппаратчик производства ТИ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 469,
    label: 'Аппаратчик производства топленого масла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 470,
    label: 'Аппаратчик производства тормозной жидкости и антифризов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 471,
    label: 'Аппаратчик производства фенилметилуретилана',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 472,
    label: 'Аппаратчик производства ферментов и плазмозаменяющих препаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 473,
    label: 'Аппаратчик производства формованного кокса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 474,
    label: 'Аппаратчик производства фосфора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 475,
    label: 'Аппаратчик производства фосфорных соединений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 476,
    label: 'Аппаратчик производства фталоцианиновых красителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 477,
    label: 'Аппаратчик производства фтористого натрия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 478,
    label: 'Аппаратчик производства химических реактивов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 479,
    label: 'Аппаратчик производства хромовых соединений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 480,
    label: 'Аппаратчик производства цианистых металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 481,
    label: 'Аппаратчик производства цинкового купороса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 482,
    label: 'Аппаратчик производства цинковой пыли',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 483,
    label: 'Аппаратчик производства шампанского',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 484,
    label: 'Аппаратчик прокаливания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 485,
    label: 'Аппаратчик прокалки и восстановления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 486,
    label: 'Аппаратчик промывки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 487,
    label: 'Аппаратчик промывки мездры, шерсти, щетины и волос',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 488,
    label: 'Аппаратчик пропитки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 489,
    label: 'Аппаратчик пропитки и сушки асбостальных листов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 490,
    label: 'Аппаратчик пропитки облицовочных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 491,
    label: 'Аппаратчик процесса брожения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 492,
    label: 'Аппаратчик разгонки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 493,
    label: 'Аппаратчик разложения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 494,
    label: 'Аппаратчик рассева',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 495,
    label: 'Аппаратчик растворения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 496,
    label: 'Аппаратчик растворения лаковых основ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 497,
    label: 'Аппаратчик расщепления жиров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 498,
    label: 'Аппаратчик рафинации жиров и масел',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 499,
    label: 'Аппаратчик рафинирования крахмала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 500,
    label: 'Аппаратчик рафинирования металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 501,
    label: 'Аппаратчик рафинирования щелочных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), химических факторов (растворов кислот и щелочей)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 502,
    label: 'Аппаратчик регенерации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 503,
    label: 'Аппаратчик регенерации воскомассы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 504,
    label: 'Аппаратчик рекристаллизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 505,
    label: 'Аппаратчик ректификации органических соединений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 506,
    label: 'Аппаратчик рекуперации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 507,
    label: 'Аппаратчик сгустителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 508,
    label: 'Аппаратчик сепарирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 509,
    label: 'Аппаратчик сепарирования и флотации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 510,
    label: 'Аппаратчик-сернильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 511,
    label: 'Аппаратчик сжигания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 512,
    label: 'Аппаратчик сжигания сероводорода',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 513,
    label: 'Аппаратчик-сиккативовар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 514,
    label: 'Аппаратчик силиконирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 515,
    label: 'Аппаратчик синтеза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 516,
    label: 'Аппаратчик синтеза меченых соединений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 517,
    label: 'Аппаратчик скипидарной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 518,
    label: 'Аппаратчик смесителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 519,
    label: 'Аппаратчик смешивания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 520,
    label: 'Аппаратчик смешивания красителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 521,
    label: 'Аппаратчик созревания оболочки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 522,
    label: 'Аппаратчик солеобогатительной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 523,
    label: 'Аппаратчик солеобразования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 524,
    label: 'Аппаратчик составления эмалей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 525,
    label: 'Аппаратчик спекания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 526,
    label: 'Аппаратчик сплавления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 527,
    label: 'Аппаратчик средоварения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 528,
    label: 'Аппаратчик стандартизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 529,
    label: 'Аппаратчик стандартизации в производстве пластических масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), от химических факторов (нефти и/или нефтепродуктов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 530,
    label: 'Аппаратчик стерилизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 531,
    label: 'Аппаратчик стерилизации консервов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 532,
    label: 'Аппаратчик сублимационной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 533,
    label: 'Аппаратчик-сульфировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 534,
    label: 'Аппаратчик сульфитации овощей и фруктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 535,
    label: 'Аппаратчик-сульфитировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 536,
    label: 'Аппаратчик сульфоаммонизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 537,
    label: 'Аппаратчик сушильной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 538,
    label: 'Аппаратчик-сушильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 539,
    label: 'Аппаратчик сушки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 540,
    label: 'Аппаратчик сушки и карбонизации лигнина и целлолигнита',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 541,
    label: 'Аппаратчик сушки и окисления магнетита',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 542,
    label: 'Аппаратчик сушки и помола',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 543,
    label: 'Аппаратчик сушки и прокалки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 544,
    label: 'Аппаратчик сушки клея и желатина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 545,
    label: 'Аппаратчик сушки кости-паренки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 546,
    label: 'Аппаратчик сушки спичечных коробок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 547,
    label: 'Аппаратчик талловой установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 548,
    label: 'Аппаратчик теплоутилизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 549,
    label: 'Аппаратчик термической активации углей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 550,
    label: 'Аппаратчик термической коагуляции белковых веществ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 551,
    label: 'Аппаратчик термической обработки мясопродуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 552,
    label: 'Аппаратчик термической обработки субпродуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 553,
    label: 'Аппаратчик термовлажностной обработки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 554,
    label: 'Аппаратчик термообработки коксуемой шихты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 555,
    label: 'Аппаратчик термообработки пластмассовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 556,
    label: 'Аппаратчик термообработки ткани',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 557,
    label: 'Аппаратчик терморелаксации пряжи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 558,
    label: 'Аппаратчик термостабилизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 559,
    label: 'Аппаратчик томатосокового агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 560,
    label: 'Аппаратчик увлажнения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 561,
    label: 'Аппаратчик углеобогащения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 562,
    label: 'Аппаратчик улавливания жиров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 563,
    label: 'Аппаратчик упаривания и сгущения продуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 564,
    label: 'Аппаратчик уплотнения технического углерода',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 565,
    label: 'Аппаратчик установки для отделения мяса от кости',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 566,
    label: 'Аппаратчик установки нейтрального газа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 567,
    label: 'Аппаратчик установки опытного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 568,
    label: 'Аппаратчик установки производства отбельной земли',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 569,
    label: 'Аппаратчик установки разделения стабильных изотопов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 570,
    label: 'Аппаратчик утилизации конфискатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 571,
    label: 'Аппаратчик фенилирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 572,
    label: 'Аппаратчик ферментации затора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 573,
    label: 'Аппаратчик ферментации препаратов биосинтеза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 574,
    label: 'Аппаратчик ферментации эфиромасличного сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 575,
    label: 'Аппаратчик фиксации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 576,
    label: 'Аппаратчик фильтрации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 577,
    label: 'Аппаратчик-фирнисовар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 578,
    label: 'Аппаратчик формования синтетического каучука',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 579,
    label: 'Аппаратчик формования химического волокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 580,
    label: 'Аппаратчик формования целлофановой пленки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 581,
    label: 'Аппаратчик форполимеризации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 582,
    label: 'Аппаратчик фотохимического синтеза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 583,
    label: 'Аппаратчик фторирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 584,
    label: 'Аппаратчик хемосорбции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 585,
    label: 'Аппаратчик химводоочистки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 586,
    label: 'Аппаратчик химводоочистки электростанции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 587,
    label: 'Аппаратчик химической обработки технического сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 588,
    label: 'Аппаратчик химической очистки препаратов биосинтеза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 589,
    label: 'Аппаратчик химической чистки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 590,
    label: 'Аппаратчик хлорирования и станнирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 591,
    label: 'Аппаратчик центрифугирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 592,
    label: 'Аппаратчик циклизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 593,
    label: 'Аппаратчик чешуирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 594,
    label: 'Аппаратчик чистки ковровых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 595,
    label: 'Аппаратчик чистки пухоперовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 596,
    label: 'Аппаратчик шаровых мельниц',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 597,
    label: 'Аппаратчик-щелочник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 598,
    label: 'Аппаратчик экстрагирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ, от нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 599,
    label: 'Аппаратчик экстрагирования таннидов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ, от нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 600,
    label: 'Аппаратчик-экстракторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ, от нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 601,
    label: 'Аппаратчик электродиализа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 602,
    label: 'Аппаратчик электролиза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 603,
    label: 'Аппаратчик электролизно-обменной цепочки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 604,
    label: 'Аппаратчик электролитического обезжиривания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 605,
    label: 'Аппаратчик электрохимического производства тантала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ, от нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 606,
    label: 'Аппаратчик этаноламиновой установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 607,
    label: 'Аппаратчик этерифакации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 608,
    label: 'Аппаратчик этиленгликолевой установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ, от нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 609,
    label: 'Аппретурщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ, от нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 610,
    label: 'Арматурщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 611,
    label: 'Арматурщик железобетонных судов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 612,
    label: 'Арматурщик язычковых инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 613,
    label: 'Армировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 614,
    label: 'Армировщик кабельных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 615,
    label: 'Армировщик санитарно-строительных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 616,
    label: 'Армировщик электрокерамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 617,
    label: 'Ароматизаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 618,
    label: 'Аспираторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 619,
    label: 'Асфальтировщик труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью, нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, конвективной теплоты и теплового излучения, нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 620,
    label: 'Асфальтобетонщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью, нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, конвективной теплоты, теплового излучения, нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 621,
    label: 'Асфальтобетонщик-варильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью, нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, конвективной теплоты, теплового излучения, нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 622,
    label: 'Аэрографист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 623,
    label: 'Аэрографист щипковых инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 624,
    label: 'Аэрографщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 625,
    label: 'Аэродромный рабочий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 626,
    label: 'Аэрозольщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 627,
    label: 'Бакелизаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (истирания)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 628,
    label: 'Бакелитчик (пропитчик)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 629,
    label: 'Балансировщик деталей и узлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 630,
    label: 'Балансировщик-заливщик абразивных кругов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 631,
    label: 'Балансировщик инструментов из алмазов и сверхтвердых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 632,
    label: 'Балансировщик шин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 633,
    label: 'Бандажировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 634,
    label: 'Бандажник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 635,
    label: 'Барильетчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения, кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, контакта с нагретой поверхностью, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 636,
    label: 'Бассейнщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 637,
    label: 'Батанщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 638,
    label: 'Бахромщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 639,
    label: 'Бегунщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 640,
    label: 'Бегунщик смесительных бегунов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 641,
    label: 'Беконщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 642,
    label: 'Бетонщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от вибрации, от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 643,
    label: 'Битумщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от нефти и/или нефтепродуктов и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нефти и нефтепродуктов, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 644,
    label: 'Бланшировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 645,
    label: 'Блокировщик стеклоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 646,
    label: 'Боец скота',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (истирания)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), воды',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '24 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 647,
    label: 'Бондарь',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (истирания)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 648,
    label: 'Бондарь-укупорщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 649,
    label: 'Бортмеханик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 650,
    label: 'Бортовой оператор-испытатель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Куртка летная кожаная',
        norm: '1 шт. на 60 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Полукомбинезон летный кожаный',
        norm: '1 шт. на 60 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 651,
    label: 'Бортоператор по проверке магистральных трубопроводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 652,
    label: 'Бортоператор грузовых самолетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 653,
    label: 'Бортоператор (первый, второй)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 654,
    label: 'Бортпроводник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от общих производственных загрязнений',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 655,
    label: 'Бортрадист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 656,
    label: 'Бортрадист-испытатель, старший бортрадист-испытатель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Куртка летная кожаная',
        norm: '1 шт. на 60 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Полукомбинезон летный кожаный',
        norm: '1 шт. на 60 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 657,
    label: 'Бортштурман',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 658,
    label: 'Боцман',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 659,
    label: 'Боцман береговой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 660,
    label: 'Бригадир бункеров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 661,
    label: 'Бригадир бурильного отделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 662,
    label: 'Бригадир двора изложниц',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'До износа',
      },
    ],
  },
  {
    profId: 663,
    label: 'Бригадир заготовительного отделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 664,
    label: 'Бригадир изыскательской русловой партии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 665,
    label: 'Бригадир колодцев замедленного охлаждения металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 666,
    label: 'Бригадир молотового отделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 667,
    label: 'Бригадир муфтосварочного отделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 668,
    label:
      'Бригадир на отделке, сортировке, приемке, сдаче, пакетировке и упаковке металла и готовой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 669,
    label: 'Бригадир-настройщик трубоэлектросварочных станов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 670,
    label: 'Бригадир на участках основного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 671,
    label: 'Бригадир обдирочно-зачистного отделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'До износа',
      },
    ],
  },
  {
    profId: 672,
    label:
      'Бригадир (освобожденный) по текущему содержанию и ремонту пути и искусственных сооружений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 673,
    label:
      'Бригадир (освобожденный) предприятий железнодорожного транспорта и метрополитенов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 674,
    label: 'Бригадир осмотра и механообработки колес',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 675,
    label: 'Бригадир отделения калибровки, волочения и шлифовки металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'До износа',
      },
    ],
  },
  {
    profId: 676,
    label: 'Бригадир отделения центробежных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 677,
    label: 'Бригадир подготовительного отделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 678,
    label:
      'Бригадир по перемещению сырья, полуфабрикатов и готовой продукции в процессе производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 679,
    label: 'Бригадир поста судоходной обстановки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 680,
    label: 'Бригадир разливочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 681,
    label: 'Бригадир разработки шлакового отвала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 682,
    label: 'Бригадир рудного двора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 683,
    label: 'Бригадир трубоволочильного отделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 684,
    label: 'Бригадир участка прессов-расширителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 685,
    label: 'Бригадир участка приготовления формовочной смеси',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 686,
    label: 'Бригадир участка станов холодной прокатки труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 687,
    label:
      'Бригадир шихтового двора в сталеплавильном и ферросплавном производствах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 688,
    label: 'Брикетировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 689,
    label: 'Брикетировщик стружки из радиоактивных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 690,
    label: 'Брикетировщик формовочной массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 691,
    label: 'Бронеобмотчик проводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 692,
    label: 'Бронзировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 693,
    label: 'Бронзировщик рам клавишных инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 694,
    label: 'Бронировщик кабелей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 695,
    label: 'Брошюровщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 696,
    label: 'Брынзодел',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 697,
    label: 'Брынзодел-мастер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 698,
    label: 'Бункеровщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 699,
    label: 'Бункеровщик доменных печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '24 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 700,
    label: 'Бурильщик капитального ремонта скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов, кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от нефти и/или нефтепродуктов',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 701,
    label: 'Бурильщик плавучего бурильного агрегата в море',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени и механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов), контакта с разогретой поверхностью, нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от нефти и/или нефтепродуктов',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 702,
    label: 'Бурильщик шпуров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от вибрации, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 703,
    label:
      'Бурильщик эксплуатационного и разведочного бурения скважин на нефть и газ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воздействия статического электричества, механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от нефти и/или нефтепродуктов',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воздействия статического электричества, от механических воздействий (истирания), от нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания), статического электричества, кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 704,
    label: 'Буртоукладчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 705,
    label: 'Бутафор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 706,
    label: 'Вагонетчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 707,
    label: 'Вагонетчик воздушно-канатной дороги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 708,
    label: 'Вагранщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения, кратковременного воздействия открытого пламени, выплесков расплавленного металла и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов), контакта с разогретой поверхностью, искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения, выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц, теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 709,
    label: 'Вакуум-прессовщик керамической массы и заготовок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 710,
    label: 'Вакуумщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 711,
    label: 'Вакуумщик по испытанию оборудования и изделий на герметичность',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 712,
    label: 'Вакуумщик-испытатель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 713,
    label: 'Вакуумщик термосных колб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (порезов, истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 714,
    label: 'Вакуумщик триплекса и блоков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (порезов, истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 715,
    label: 'Вальцовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 716,
    label: 'Вальцовщик гидроизоляционных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 717,
    label: 'Вальцовщик игл',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 718,
    label: 'Вальцовщик игольчатых роликов и шариков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 719,
    label: 'Вальцовщик изделий из ВМ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 720,
    label: 'Вальцовщик калибровочного стана',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 721,
    label: 'Вальцовщик керамической пленки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 722,
    label: 'Вальцовщик кожевенных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 723,
    label: 'Вальцовщик косметической массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 724,
    label: 'Вальцовщик массы на вулканитовой связке',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 725,
    label: 'Вальцовщик обкатной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 726,
    label: 'Вальцовщик по сборке и перевалке клетей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 727,
    label: 'Вальцовщик проволоки для спиралей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 728,
    label: 'Вальцовщик профилегибочного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 729,
    label: 'Вальцовщик резиновых смесей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 730,
    label: 'Вальцовщик стана горячего проката труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, конвективной теплоты',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, конвективной теплоты',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: ' ',
      },
    ],
  },
  {
    profId: 731,
    label: 'Вальцовщик стана горячей прокатки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, конвективной теплоты',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), искр и брызг расплавленного металла, металлической окалины',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 732,
    label: 'Вальцовщик стана печной сварки труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, конвективной теплоты',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 733,
    label: 'Вальцовщик стана холодного проката труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 734,
    label: 'Вальцовщик стана холодной прокатки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 735,
    label: 'Вальцовщик сырья и полуфабрикатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 736,
    label: 'Вальцовщик трубоформовочного стана',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 737,
    label: 'Вальцовщик фибровых трубок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 738,
    label: 'Вальцовщик холодного металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 739,
    label: 'Вальщик леса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Защитные приспособления для защиты от режущего воздействия ручной цепной пилой',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от режущего воздействия ручной цепной пилой',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от режущего воздействия ручной цепной пилой, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от режущего воздействия ручной цепной пилой',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 740,
    label: 'Валяльщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 741,
    label: 'Варильщик химических составов для варки отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 742,
    label: 'Варщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 743,
    label: 'Варщик аппрета',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 744,
    label: 'Варщик асфальтовой массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 745,
    label: 'Варщик битума',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 746,
    label: 'Варщик волокнистого сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 747,
    label: 'Варщик восковой, клеевой массы и пропиточной смеси',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 748,
    label: 'Варщик гипса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 749,
    label: 'Варщик жидкого стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 750,
    label: 'Варщик кабельной массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 751,
    label: 'Варщик косметической массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 752,
    label: 'Варщик литейных смазок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения, от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью, нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 753,
    label: 'Варщик пека',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 754,
    label: 'Варщик пищевого сырья и продуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 755,
    label: 'Варщик селена',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью, нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 756,
    label: 'Варщик сиропов, соков, экстрактов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 757,
    label: 'Варщик смолки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 758,
    label: 'Варщик суспензий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 759,
    label: 'Варщик торфомассы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 760,
    label: 'Варщик тряпья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 761,
    label: 'Варщик химической древесной массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 762,
    label: 'Варщик хлопка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 763,
    label: 'Варщик целлюлозы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 764,
    label: 'Варщик шубного лоскута',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 765,
    label: 'Варщик щетины и волоса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 766,
    label: 'Варщик электроизоляционных лаков, смол и мастик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 767,
    label: 'Ватинщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 768,
    label: 'Вафельщик',
    SIZ: [
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 769,
    label: 'Велюрщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 770,
    label: 'Вентилевой гидравлического пресса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 771,
    label: 'Верховой доменной печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания) и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'Определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 772,
    label: 'Весовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 773,
    label: 'Весовщик-счетчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 774,
    label: 'Веяльщик чая',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 775,
    label: 'Вздымщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 776,
    label: 'Взрывник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 777,
    label: 'Взрывник на лесных работах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 778,
    label: 'Виварщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 779,
    label: 'Вивщик колец',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 780,
    label: 'Видеотекарь',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 781,
    label: 'Винипластчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 782,
    label: 'Виноградарь',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 783,
    label: 'Водитель автомобиля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 784,
    label: 'Водитель аэросаней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания) и пониженных температур',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 785,
    label: 'Водитель вездехода',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 786,
    label: 'Водитель дрезины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 787,
    label: 'Водитель-испытатель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 788,
    label: 'Водитель-испытатель боевых и специальных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 789,
    label: 'Водитель мототранспортных средств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 790,
    label: 'Водитель погрузчика',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 791,
    label: 'Водитель трамвая',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 792,
    label: 'Водитель транспортно-уборочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 793,
    label: 'Водитель троллейбуса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 794,
    label: 'Водитель электро- и автотележки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолетового излучения, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 795,
    label: 'Водолаз',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 796,
    label: 'Водопроводчик доменной печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания),',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 797,
    label: 'Водопроводчик шахтной печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания),',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 798,
    label: 'Водораздатчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 799,
    label: 'Водораздатчик порта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 800,
    label: 'Водородчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воздействия статического электричества, механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 801,
    label: 'Возчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 802,
    label: 'Возчик леса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 803,
    label: 'Волнировщик асбестоцементных листов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 804,
    label: 'Волочильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 805,
    label: 'Волочильщик материала для спиралей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 806,
    label: 'Волочильщик проволоки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 807,
    label: 'Волочильщик цветных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 808,
    label: 'Волочильщик труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 809,
    label: 'Воронильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 810,
    label: 'Врач-анестезиолог-реаниматолог',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 811,
    label: 'Врач здравпункта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 812,
    label: 'Врач-инфекционист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Одежда специальная для ограниченной защиты от токсичных веществ',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные средства индивидуальной защиты органов дыхания с лицевой частью из изолирующих материалов',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 813,
    label: 'Врач-бактериолог',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Одежда специальная для ограниченной защиты от токсичных веществ',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные средства индивидуальной защиты органов дыхания с лицевой частью из изолирующих материалов',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 814,
    label: 'Врач-вирусолог',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Одежда специальная для ограниченной защиты от токсичных веществ',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные средства индивидуальной защиты органов дыхания с лицевой частью из изолирующих материалов',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 815,
    label: 'Врач-лаборант',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Одежда специальная для ограниченной защиты от токсичных веществ',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные средства индивидуальной защиты органов дыхания с фильтрующей полумаской',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные средства индивидуальной защиты органов дыхания с лицевой частью из изолирующих материалов',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противогазовые средства индивидуальной защиты органов дыхания с лицевой частью из изолирующих материалов',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 816,
    label: 'Врач - лабораторный миколог',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Одежда специальная для ограниченной защиты от токсичных веществ',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные средства индивидуальной защиты органов дыхания с лицевой частью из изолирующих материалов',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 817,
    label: 'Врач-ортодонт',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 818,
    label: 'Врач-оториноларинголог',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 819,
    label: 'Врач-патологоанатом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Одежда специальная для ограниченной защиты от токсичных веществ',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'лицевые части из изолирующих (маски, полумаски, четвертьмаски) для средств индивидуальной защиты (используемые совместно со сменными фильтрами)',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Сменные фильтры (фильтрующие элементы: противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные)) для средств индивидуальной защиты',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 820,
    label: 'Врач по рентгенэндоваскулярным диагностике и лечению',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 821,
    label: 'Врач-пульмонолог',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 822,
    label: 'Врач-рентгенолог',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 823,
    label: 'Врач скорой медицинской помощи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 824,
    label: 'Врач стоматолог детский',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 825,
    label: 'Врач стоматолог-ортопед',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 826,
    label: 'Врач стоматолог-терапевт',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 827,
    label: 'Врач стоматолог-хирург',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 828,
    label: 'Врач судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 829,
    label: 'Врач-терапевт',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 830,
    label: 'Врач-терапевт подростковый',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 831,
    label: 'Врач-терапевт участковый',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 832,
    label: 'Врач-терапевт участковый цехового врачебного участка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 833,
    label: 'Врач-фтизиатр',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные средства индивидуальной защиты органов дыхания с лицевой частью из изолирующих материалов',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 834,
    label: 'Врач-фтизиатр участковый',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные средства индивидуальной защиты органов дыхания с лицевой частью из изолирующих материалов',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 835,
    label: 'Врач-эндоскопист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 836,
    label: 'Врач-эпидемиолог',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Одежда специальная для ограниченной защиты от токсичных веществ',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные средства индивидуальной защиты органов дыхания с лицевой частью из изолирующих материалов',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 837,
    label: 'Вставщик деталей, изделий и фурнитуры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 838,
    label: 'Вставщик камер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 839,
    label: 'Вулканизаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 840,
    label: 'Вулканизаторщик кабельных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 841,
    label: 'Вулканизаторщик кругов на вулканитовой связке',
    SIZ: [
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 842,
    label: 'Вулканизаторщик печатных форм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 843,
    label: 'Выбивальщик мягкой тары',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 844,
    label: 'Выбивальщик отливок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью, искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 845,
    label: 'Выбивщик блоков мипоры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 846,
    label: 'Выбивщик изделий из гипсовых форм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 847,
    label: 'Выбивщик титановой губки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 848,
    label: 'Выборщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 849,
    label: 'Выборщик камней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 850,
    label: 'Выборщик металлического натрия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей',
        norm: ' ',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, от механических воздействий (истирания), от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 851,
    label: 'Выборщик солодкового корня',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 852,
    label: 'Выборщик-сортировщик огнеупорного лома',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 853,
    label: 'Выборщик-укладчик камня',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 854,
    label: 'Выборщик фарфоровых, фаянсовых и керамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 855,
    label: 'Выгребальщик костры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 856,
    label: 'Выгребальщик очеса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 857,
    label: 'Выгребальщик пуха и отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 858,
    label: 'Выгружальщик хлопка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 859,
    label: 'Выгрузчик блоков полимера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 860,
    label: 'Выгрузчик горячего агломерата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), от защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 861,
    label: 'Выгрузчик древесины из воды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов) и воды',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, механических воздействий (проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 862,
    label: 'Выгрузчик извести из печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), и химических факторов (растворов кислот и щелочей), от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 863,
    label: 'Выгрузчик мышьяка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), и химических факторов (растворов кислот и щелочей), от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 864,
    label: 'Выгрузчик огнеупорных материалов из печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 865,
    label: 'Выгрузчик на отвалах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 866,
    label: 'Выгрузчик пыли',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 867,
    label: 'Выгрузчик шахтных печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 868,
    label: 'Выдувальщик стеклоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 869,
    label: 'Выемщик варочных камер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор от общих производственных загрязнений для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 870,
    label: 'Вжигальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 871,
    label: 'Выжигальщик древесного угля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 872,
    label: 'Выжигальщик по дереву',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 873,
    label: 'Выжигальщик рисунков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 874,
    label: 'Вызывальщик локомотивных и поездных бригад',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 875,
    label: 'Выливщик-заливщик металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от выплесков расплавленного металла',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от выплесков расплавленного металла',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла, конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 876,
    label: 'Выпарщик капо-корня',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 877,
    label: 'Выпарщик соли',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 878,
    label: 'Выпарщик щелоков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от химических факторов (растворов кислот и щелочей), от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 879,
    label: 'Выравнивальщик кожаных деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 880,
    label: 'Выравнивальщик толщины деталей и полуфабрикатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 881,
    label: 'Вырезальщик фестонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 882,
    label: 'Вырубщик деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 883,
    label: 'Вырубщик заготовок и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 884,
    label: 'Вытягивальщик ремней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 885,
    label: 'Выставщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью, кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 886,
    label: 'Выстилальщик кожевенно-мехового сырья и голья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 887,
    label: 'Вышивальщик по коже и меху',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (проколов и порезов)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 888,
    label: 'Вышивальщица',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 889,
    label: 'Вышивальщица текстильно-галантерейных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (проколов и порезов)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 890,
    label: 'Вышкомонтажник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов), контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 891,
    label: 'Вышкомонтажник-сварщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины, для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью, искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 892,
    label: 'Вышкомонтажник-электромонтер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 893,
    label: 'Вязальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 894,
    label: 'Вязальщик польстерных щеток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 895,
    label: 'Вязальщик прутков и проволоки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 896,
    label: 'Вязальщик схемных жгутов, кабелей и шнуров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 897,
    label: 'Вязальщица текстильно-галантерейных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 898,
    label: 'Вязальщица трикотажных изделий, полотна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 899,
    label: 'Газовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воздействия статического электричества, от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 900,
    label: 'Газовщик доменной печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени, от конвективной теплоты, теплового излучения и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воздействия статического электричества, от механических воздействий (ударов), от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания) и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 901,
    label: 'Газовщик коксовых печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воздействия статического электричества, от механических воздействий (ударов), для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 902,
    label: 'Газовщик шахтной печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины, от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 903,
    label: 'Газогенераторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью, нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 904,
    label: 'Газомерщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с разогретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 905,
    label: 'Газорезчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от искр и брызг расплавленного металла и металлической окалины',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины и механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 906,
    label: 'Газосварщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины, от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от искр и брызг расплавленного металла и металлической окалины',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 907,
    label: 'Газоспасатель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени и механических воздействий (истирания), от воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов), кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 908,
    label: 'Галтовщик деталей часов и камней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 909,
    label: 'Галтовщик игольно-платинных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 910,
    label: 'Галтовщик радиодеталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов),',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 911,
    label: 'Гальваник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 912,
    label: 'Гальванотипист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 913,
    label: 'Гардеробщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от общих производственных загрязнений механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 914,
    label: 'Гарнировщик музыкальных инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 915,
    label: 'Гарнитурщик химического прядения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 916,
    label: 'Гасильщик извести',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 917,
    label: 'Геолог карьера, рудника, шахты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор утепленный для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 918,
    label: 'Геолог нефтегазоразведки (партии) структурно-поискового бурения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от вредных биологических факторов (клещей и кровососущих насекомых), в том числе в комплекте с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от биологических факторов (кровососущих насекомых)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 919,
    label: 'Геофизик карьера, рудника, шахты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 920,
    label: 'Гелиотропист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 921,
    label: 'Генераторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 922,
    label: 'Генераторщик ацетиленовой установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 923,
    label: 'Герметизаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 924,
    label: 'Гибщик судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 925,
    label: 'Гибщик труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов, дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 926,
    label: 'Гибщик фибры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 927,
    label: 'Гигиенист стоматологический',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 928,
    label: 'Гидрировщик в производстве лития',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 929,
    label: 'Гидрогенизаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 930,
    label: 'Гидромониторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов), воды',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 931,
    label: 'Гидропескоструйщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (шлем пескоструйщика)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 932,
    label: 'Гидрорезчик специзделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 933,
    label: 'Гидротермист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов), от воды',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 934,
    label: 'Гидроциклонщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов), от воды',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 935,
    label: 'Гидрочистильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов), от воды',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 936,
    label: 'Гильошир',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 937,
    label: 'Гильоширщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 938,
    label: 'Гладильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 939,
    label: 'Глазировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 940,
    label: 'Глазировщик мороженого и сырков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 941,
    label: 'Глазуровщик изделий строительной керамики',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 942,
    label: 'Глазуровщик фарфоровых и фаянсовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 943,
    label: 'Глазуровщик электрокерамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 944,
    label: 'Гнутарь по дереву',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 945,
    label: 'Голлендорщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 946,
    label: 'Гончар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 947,
    label: 'Горничная',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 948,
    label: 'Горновой десульфурации чугуна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, от кратковременного воздействия открытого пламени, от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений, от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 949,
    label: 'Горновой доменной печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, от кратковременного воздействия открытого пламени, от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, от выплесков расплавленного металла, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 950,
    label: 'Горновой на агломерации и обжиге',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, от кратковременного воздействия открытого пламени, от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, от выплесков расплавленного металла, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 951,
    label: 'Горновой ферросплавных печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины, от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины, от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 952,
    label: 'Горновой шахтной печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 953,
    label: 'Горномонтажник подземный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов, проколов, порезов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 954,
    label: 'Горнорабочий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов), воды',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'Определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 955,
    label: 'Горнорабочий на геологических работах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов) и от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 956,
    label: 'Горнорабочий на маркшейдерских работах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов), воды',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 957,
    label: 'Горнорабочий очистного забоя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов, проколов, порезов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 958,
    label: 'Горнорабочий подземный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов, проколов, порезов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 959,
    label: 'Горнорабочий по предупреждению и тушению пожаров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания, порезов), кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов), от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 960,
    label: 'Горнорабочий по ремонту горных выработок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов, проколов, порезов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 961,
    label: 'Горнорабочий разреза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов) и от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 962,
    label: 'Горнорабочий россыпных месторождений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 963,
    label:
      'Горнорабочий у экскаваторов, отвальных мостов и отвалообразователей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 964,
    label: 'Гофрировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 965,
    label: 'Гофрировщик винипласта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 966,
    label: 'Гофрировщик меховых камер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 967,
    label: 'Гофрировщик протезов кровеносных сосудов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 968,
    label: 'Гофрировщик трубок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 969,
    label: 'Гравер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 970,
    label: 'Гравер валов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 971,
    label: 'Гравер оригиналов топографических карт',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 972,
    label: 'Гравер печатных форм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 973,
    label: 'Гравер стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 974,
    label: 'Гравер шрифта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 975,
    label: 'Градуировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 976,
    label: 'Градуировщик оптических деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 977,
    label: 'Градуировщик радиоаппаратуры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 978,
    label: 'Гранулировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 979,
    label: 'Грануляторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нетоксичной пыли, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от нетоксичной пыли',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 980,
    label: 'Грануляторщик доменного шлака',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения, кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 981,
    label: 'Гратосъемщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 982,
    label: 'Графитировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 983,
    label: 'Графитировщик асбестовых технических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 984,
    label: 'Гример-пастижер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от общих производственных загрязнений',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 985,
    label: 'Грохотовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 986,
    label: 'Грохотчик-шуровщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 987,
    label: 'Грузчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 988,
    label: 'Грунтовальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 989,
    label: 'Грунтовщик асбестоцементных и асбестосилитовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 990,
    label: 'Грунтовщик холста и картона художественных полотен',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 991,
    label: 'Грунтовщик художественных изделий из дерева',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 992,
    label: 'Грунтовщик щеточных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 993,
    label: 'Гуммировщик металлоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 994,
    label: 'Гуртовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от общих производственных загрязнений',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 995,
    label: 'Давильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 996,
    label: 'Дверевой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, искр и брызг расплавленного металла, металлической окалины, кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, от выплесков расплавленного металла, от контакта с нагретой поверхностью, от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла, конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 997,
    label: 'Дворник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 998,
    label: 'Девулканизаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 999,
    label: 'Дегоржер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), растворов кислот',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1000,
    label:
      'Дежурный по железнодорожной станции; дежурный по сортировочной горке: дежурный станционного поста централизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 36 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1001,
    label: 'Дежурный по переезду',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 36 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1002,
    label: 'Дежурный стрелочного поста',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 36 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1003,
    label: 'Дежурный у эскалатора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1004,
    label: 'Дезактиваторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм изолирующий для защиты от химических факторов (в том числе применяемый для защиты от биологических факторов (микроорганизмов))',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания неавтономные с подачей чистого воздуха по шлангу/магистрали (дыхательные аппараты)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1005,
    label: 'Дезинфектор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Одежда специальная для ограниченной защиты от токсичных веществ',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1006,
    label: 'Дезодораторщик сои',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1007,
    label: 'Декатировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1008,
    label: 'Декоратор витрин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 1009,
    label: 'Делильщик кружев',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 1010,
    label: 'Денатураторщик спирта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 1011,
    label: 'Дефектовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1012,
    label: 'Дефектовщик авиационной техники',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1013,
    label: 'Дефектоскопист по газовому и жидкостному контролю',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от газов и мелкодисперсных аэрозолей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1014,
    label: 'Дефектоскопист по магнитному и ультразвуковому контролю',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1015,
    label: 'Дефектоскопист рентгено-, гаммаграфирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты рук от радиоактивных веществ и ионизирующих излучений, в том числе перчатки камерные',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ионизирующих излучений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1016,
    label: 'Дефибрерщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1017,
    label: 'Дефростатчик пищевых продуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1018,
    label: 'Дизелист плавучего бурильного агрегата в море',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, от нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1019,
    label: 'Диспетчер подстанции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1020,
    label: 'Дистиллировщик ртути',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания неавтономные с подачей чистого воздуха по шлангу/магистрали (дыхательные аппараты)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1021,
    label: 'Дистилляторщик ртути',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания неавтономные с подачей чистого воздуха по шлангу/магистрали (дыхательные аппараты)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1022,
    label: 'Диффузорщик целлюлозы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1023,
    label: 'Дневальный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1024,
    label: 'Доводчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1025,
    label: 'Доводчик алмазосодержащих концентратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1026,
    label: 'Доводчик деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1027,
    label: 'Доводчик-притирщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1028,
    label: 'Доводчик угольных шайб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1029,
    label: 'Дозиметрист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1030,
    label: 'Дозировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1031,
    label: 'Дозировщик асбеста',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нетоксичной пыли, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1032,
    label: 'Дозировщик волокна и пленки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1033,
    label: 'Дозировщик горячего возврата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Одежда специальная для ограниченной защиты от токсичных веществ',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1034,
    label: 'Дозировщик дробленого камня и битума',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1035,
    label: 'Дозировщик керамических материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1036,
    label: 'Дозировщик компонентов бетонных смесей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1037,
    label: 'Дозировщик медицинских препаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от общих производственных загрязнений',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1038,
    label: 'Дозировщик минерализатора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1039,
    label: 'Дозировщик пищевой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1040,
    label: 'Дозировщик реагентов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1041,
    label: 'Дозировщик ртути',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 1042,
    label: 'Дозировщик-смесительщик на шнеках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1043,
    label: 'Дозировщик сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1044,
    label: 'Долбежник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'Определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1045,
    label: 'Долотозаправщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, от кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1046,
    label: 'Дорожно-путевой рабочий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'Определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 1047,
    label: 'Дорожный рабочий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'Определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1048,
    label: 'Доставщик крепежных материалов в шахту',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'Определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 1049,
    label: 'Доставщик поездных документов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1050,
    label: 'Дояр',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1051,
    label: 'Драгер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Фильтрующий самоспасатель',
        norm: 'одноразовое применение',
      },
    ],
  },
  {
    profId: 1052,
    label: 'Дражировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1053,
    label: 'Драпировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1054,
    label: 'Древопар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1055,
    label: 'Дренажист по добыче мирабилита',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1056,
    label: 'Дренажник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости, для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1057,
    label: 'Дробильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 1058,
    label: 'Дробильщик алмазов и сверхтвердых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1059,
    label: 'Дробильщик извести',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли, от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), химических факторов (растворов кислот и щелочей)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1060,
    label: 'Дробильщик колчедана',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1061,
    label: 'Дробильщик компонентов обмазки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1062,
    label: 'Дробильщик-размольщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1063,
    label: 'Дробильщик-размольщик в производстве ферритовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1064,
    label: 'Дробильщик слюды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1065,
    label: 'Дробильщик теплоизоляционного сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1066,
    label: 'Дробильщик целлюлозы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1067,
    label: 'Дробильщик шлифзерна, шлифпорошков и шихтовых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1068,
    label: 'Дробильщик электроугольного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1069,
    label: 'Дровокол',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Защитные приспособления для защиты от режущего воздействия ручной цепной пилой',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от режущего воздействия ручной цепной пилой',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от режущего воздействия ручной цепной пилой, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (проколов), режущего воздействия ручной цепной пилой',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1070,
    label: 'Дрожжевод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1071,
    label: 'Дублировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1072,
    label: 'Дублировщик деталей и материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1073,
    label: 'Дублировщик листового материала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1074,
    label: 'Егерь',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и вредных биологических факторов (клещей и кровососущих насекомых)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1075,
    label: 'Жаровщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1076,
    label: 'Жестянщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1077,
    label: 'Животновод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды',
        norm: '1 пара на 24 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1078,
    label: 'Животновод по уходу за рабочими животными',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды',
        norm: '1 пара на 24 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1079,
    label: 'Жиловщик мяса и субпродуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1080,
    label: 'Жировальщик кож',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1081,
    label: 'Забойщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1082,
    label: 'Забойщик на отбойных молотках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 1083,
    label: 'Заборщик фарфоровых, фаянсовых и керамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1084,
    label: 'Завальцовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 1085,
    label: 'Завальцовщик радиодеталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1086,
    label: 'Завальщик шихты в вагранки и печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1087,
    label: 'Заварщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1088,
    label: 'Заварщик изоляторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений, кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1089,
    label: 'Заварщик на высокочастотном индукторе',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений, кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1090,
    label: 'Заварщик отливок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений, кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1091,
    label: 'Заварщик пасты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений, кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1092,
    label: 'Заварщик полупроводниковых приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений, кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1093,
    label: 'Заварщик труб и баллонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины, конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от искр и брызг расплавленного металла и металлической окалины',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1094,
    label: 'Заварщик электровакуумных приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1095,
    label:
      'Заведующий фельдшерско-акушерским пунктом - фельдшер (акушер, медицинская сестра)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1096,
    label: 'Завивальщик спиралей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1097,
    label: 'Заготовитель блоков по ботанике',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от общих производственных загрязнений',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 1098,
    label: 'Заготовитель блоков по гистологии, цитологии, эмбриологии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от общих производственных загрязнений',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1099,
    label: 'Заготовитель геологических образцов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1100,
    label: 'Заготовитель продуктов и сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1101,
    label: 'Заготовитель смеси для цементации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1102,
    label: 'Заготовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1103,
    label: 'Заготовщик абразивной массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1104,
    label: 'Заготовщик асбестовой смески',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1105,
    label: 'Заготовщик асбестовых технических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1106,
    label: 'Заготовщик бакелитовой, вулканитовой и эпоксидной массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1107,
    label: 'Заготовщик бинта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1108,
    label: 'Заготовщик битума',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов), контакта с разогретой поверхностью, нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1109,
    label: 'Заготовщик бумажных пакетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1110,
    label: 'Заготовщик водорослей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), воды',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1111,
    label: 'Заготовщик газопоглотителя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1112,
    label:
      'Заготовщик деталей и материалов к ювелирным и художественным изделиям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1113,
    label: 'Заготовщик деталей электровакуумных приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1114,
    label: 'Заготовщик изоляционных деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1115,
    label: 'Заготовщик каркаса спортивных велошин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 1116,
    label: 'Заготовщик карт и паковочных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 1117,
    label: 'Заготовщик клапанов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1118,
    label: 'Заготовщик льда',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), воды',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), воды и растворов нетоксичных веществ',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от контакта с охлажденными поверхностями',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1119,
    label: 'Заготовщик материалов для прессования и отделки игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1120,
    label:
      'Заготовщик материалов для художественных изделий из дерева, бересты, капо-корня',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1121,
    label: 'Заготовщик материалов и деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1122,
    label: 'Заготовщик материалов и деталей игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1123,
    label: 'Заготовщик микалексовой массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1124,
    label: 'Заготовщик мягкой тары',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1125,
    label: 'Заготовщик образцов для испытания шин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1126,
    label: 'Заготовщик основы для моющих средств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1127,
    label: 'Заготовщик пластмасс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1128,
    label: 'Заготовщик пленки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1129,
    label: 'Заготовщик полуфабрикатов для игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1130,
    label: 'Заготовщик полуфабрикатов перевязочных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1131,
    label: 'Заготовщик радиотакелажа и электрорадиоэлементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1132,
    label: 'Заготовщик резиновых изделий и деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1133,
    label: 'Заготовщик слюды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1134,
    label: 'Заготовщик смеси для строительных плит из костры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (нефти и/или нефтепродуктов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1135,
    label: 'Заготовщик такелажа инженерного имущества',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1136,
    label: 'Заготовщик тростника',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1137,
    label: 'Заготовщик химических полуфабрикатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов), от химических факторов (нефти и/или нефтепродуктов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1138,
    label: 'Заготовщик химических полуфабрикатов тугоплавких металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (нефти и/или нефтепродуктов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1139,
    label: 'Заготовщик химических растворов и красок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (нефти и/или нефтепродуктов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1140,
    label: 'Заготовщик чайных ящиков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1141,
    label: 'Заготовщик черни',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 1142,
    label: 'Заготовщик шиноремонтных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1143,
    label: 'Заготовщик шорно-седельных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1144,
    label: 'Заготовщик шприцованных деталей для шин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1145,
    label: 'Заготовщик янтаря',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, воды',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1146,
    label: 'Загрузчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1147,
    label: 'Загрузчик балансов в дефибреры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1148,
    label: 'Загрузчик варочных котлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1149,
    label: 'Загрузчик-выгрузчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1150,
    label:
      'Загрузчик-выгрузчик абразивных изделий в периодические обжигательные печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1151,
    label: 'Загрузчик-выгрузчик вагранок и печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1152,
    label: 'Загрузчик (выгрузчик) диффузоров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1153,
    label: 'Загрузчик-выгрузчик обжигательных печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1154,
    label: 'Загрузчик-выгрузчик обжиговых и графитировочных печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1155,
    label: 'Загрузчик-выгрузчик печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1156,
    label: 'Загрузчик-выгрузчик печей обжига и графитации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1157,
    label: 'Загрузчик-выгрузчик пищевой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1158,
    label: 'Загрузчик-выгрузчик сушил',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1159,
    label: 'Загрузчик-выгрузчик сырья, топлива и стеновых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1160,
    label: 'Загрузчик-выгрузчик термоантрацитовых печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1161,
    label: 'Загрузчик-выгрузчик ферментационных камер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1162,
    label: 'Загрузчик-выгрузчик электролизных ванн',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1163,
    label: 'Загрузчик древесных и костровых плит',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1164,
    label: 'Загрузчик дробильно-помольного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1165,
    label: 'Загрузчик кассет',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1166,
    label: 'Загрузчик колчеданных, серных печей и турм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1167,
    label: 'Загрузчик мелющих тел',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1168,
    label: 'Загрузчик печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов), контакта с разогретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1169,
    label: 'Загрузчик печей сопротивления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов), контакта с разогретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1170,
    label: 'Загрузчик-разгрузчик сушильных печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1171,
    label: 'Загрузчик сульфата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1172,
    label: 'Загрузчик сушильных барабанов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1173,
    label: 'Загрузчик сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1174,
    label: 'Загрузчик сырья и полуфабриката',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1175,
    label: 'Загрузчик термических печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1176,
    label: 'Загрузчик туннельных печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1177,
    label: 'Загрузчик химического сырья в аппараты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1178,
    label: 'Загрузчик шихты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1179,
    label: 'Загрузчик щелочи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов щелочей и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (растворов кислот и щелочей)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов), и химических факторов (растворов кислот и щелочей)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1180,
    label: 'Закальщик изделий с хохломской росписью',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от общих производственных загрязнений',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 1181,
    label: 'Закальщик мороженого',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с охлажденной поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1182,
    label: 'Закальщик стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1183,
    label: 'Закатчик бумажных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1184,
    label: 'Закатчик маканых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1185,
    label: 'Закатчик медицинской продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1186,
    label: 'Заквасчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1187,
    label: 'Закладчик хирургического шовного материала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1188,
    label: 'Закрепщик алмазов и сверхтвердых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от общих производственных загрязнений',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1189,
    label: 'Закройщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 1190,
    label: 'Закройщик картона, фибры и других материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 1191,
    label: 'Закройщик-резак',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1192,
    label: 'Закройщик резиновых изделий и деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1193,
    label: 'Заливщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1194,
    label: 'Заливщик анодов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1195,
    label: 'Заливщик голосовых планок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1196,
    label: 'Заливщик игольно-платинных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от общих производственных загрязнений',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 1197,
    label: 'Заливщик камнелитейных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с разогретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1198,
    label: 'Заливщик кокса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1199,
    label: 'Заливщик компаундами',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1200,
    label: 'Заливщик магнитных сплавов на печах-кристаллизаторах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1201,
    label: 'Заливщик металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от выплесков расплавленного металла, кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от выплесков расплавленного металла, кратковременного воздействия открытого пламени механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1202,
    label: 'Заливщик форм полимеризационной смесью',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1203,
    label: 'Заливщик цоколей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1204,
    label: 'Замачивальщик шелка-сырца',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1205,
    label: 'Замерщик дебитов скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), химических факторов (нефти и/или нефтепродуктов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от нефти и/или нефтепродуктов',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1206,
    label: 'Замерщик на топографо-геодезических и маркшейдерских работах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1207,
    label: 'Замыливальщик ткани',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1208,
    label: 'Запайщик ампул',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1209,
    label: 'Запайщик колб и сосудов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов),',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1210,
    label: 'Запайщик фарфоровых труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов), для защиты от кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1211,
    label: 'Запарщик коконов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1212,
    label: 'Запарщик крученого шелка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1213,
    label: 'Запарщик ткани',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1214,
    label: 'Запарщик шерстяных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1215,
    label: 'Заправщик иглопробивного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1216,
    label: 'Заправщик поливомоечных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1217,
    label: 'Заправщик рулонов картона и бумаги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1218,
    label: 'Заправщик-съемщик тормозной ленты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1219,
    label: 'Заправщик текстильного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1220,
    label: 'Заправщик эмалевых шликеров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1221,
    label: 'Запрессовщик игл',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1222,
    label: 'Запрессовщик фитилей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1223,
    label: 'Зарядчик автоклавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1224,
    label: 'Зарядчик ингаляторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1225,
    label: 'Зарядчик огнетушителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1226,
    label: 'Зарядчик противогазовых коробок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1227,
    label: 'Зарядчик холодильных аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1228,
    label: 'Засольщик мяса и мясопродуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки и защитные приспособления кольчужные',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1229,
    label: 'Засольщик овощей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ.',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1230,
    label: 'Засольщик шкур',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ.',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1231,
    label: 'Засыпщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1232,
    label: 'Засыпщик шихты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нетоксичной пыли, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1233,
    label: 'Заточник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки и защитные приспособления кольчужные',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1234,
    label: 'Заточник деревообрабатывающего инструмента',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки и защитные приспособления кольчужные',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1235,
    label: 'Заточник игольно-платинных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1236,
    label: 'Заточник карандашей, стержней и палочек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1237,
    label: 'Заточник контактной пружины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1238,
    label: 'Заточник медицинского инструмента',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1239,
    label: 'Затяжчик обуви',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1240,
    label: 'Зачистчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1241,
    label: 'Зачистчик электроизоляционных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1242,
    label: 'Зашивальщик мягкой тары',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1243,
    label: 'Зверовод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1244,
    label: 'Зверовод зоологических баз',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1245,
    label: 'Земледел',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1246,
    label: 'Землекоп',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1247,
    label: 'Зоолаборант питомника лабораторных животных',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1248,
    label: 'Зоолаборант серпентария (питомника)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1249,
    label: 'Зубной врач',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1250,
    label: 'Зубополировщик деталей часов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1251,
    label: 'Зуборезчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки и защитные приспособления кольчужные',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1252,
    label: 'Зубофрезеровщик деталей часов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1253,
    label: 'Зубошлифовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1254,
    label: 'Известегасильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1255,
    label: 'Изготовитель абразивных дисков и полировальных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1256,
    label: 'Изготовитель абразивных пилок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1257,
    label: 'Изготовитель асбометаллических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1258,
    label: 'Изготовитель берд',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1259,
    label: 'Изготовитель бисквита',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1260,
    label: 'Изготовитель блоков и панелей из кирпича',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1261,
    label: 'Изготовитель блоков пенополиуретана',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1262,
    label: 'Изготовитель бужей и катетеров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1263,
    label: 'Изготовитель вафель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1264,
    label: 'Изготовитель веревочных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1265,
    label: 'Изготовитель витаминных соков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1266,
    label: 'Изготовитель гипсовых бинтов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1267,
    label: 'Изготовитель глазированных фруктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1268,
    label: 'Изготовитель голосовых планок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1269,
    label: 'Изготовитель декоративных элементов мебели',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1270,
    label: 'Изготовитель деталей для духовых инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1271,
    label: 'Изготовитель деталей и узлов трубопроводов из пластмасс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1272,
    label: 'Изготовитель жгутов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1273,
    label: 'Изготовитель звукопоглощающих клиньев',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1274,
    label: 'Изготовитель зубочисток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1275,
    label: 'Изготовитель игровых кукол',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1276,
    label: 'Изготовитель изделий из вспенивающихся материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1277,
    label: 'Изготовитель изделий из органического стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1278,
    label: 'Изготовитель изделий из тканей с художественной росписью',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1279,
    label: 'Изготовитель изделий из фторопласта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1280,
    label: 'Изготовитель изделий из шлифовальной шкурки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1281,
    label: 'Изготовитель изделий методом намотки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1282,
    label: 'Изготовитель искусственной колбасной оболочки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1283,
    label: 'Изготовитель источников щелочных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1284,
    label: 'Изготовитель казеина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1285,
    label: 'Изготовитель камышовых и соломенных матов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1286,
    label: 'Изготовитель капов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1287,
    label: 'Изготовитель карамели',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1288,
    label: 'Изготовитель каркасов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1289,
    label: 'Изготовитель кетгута',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1290,
    label: 'Изготовитель кожмягчителя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1291,
    label: 'Изготовитель колец',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1292,
    label: 'Изготовитель конфет',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1293,
    label: 'Изготовитель костяного угля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1294,
    label: 'Изготовитель лайки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1295,
    label: 'Изготовитель лекал',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1296,
    label: 'Изготовитель лент и металлосеток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1297,
    label: 'Изготовитель ленточных сердечников',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1298,
    label: 'Изготовитель ленты из фторопласта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1299,
    label: 'Изготовитель маканых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1300,
    label: 'Изготовитель макетов матриц',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1301,
    label: 'Изготовитель мармеладо-пастильных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1302,
    label: 'Изготовитель медицинских изделий из фторопласта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1303,
    label: 'Изготовитель металлорукавов, гибких валов и бронеспиралей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1304,
    label: 'Изготовитель микрофонных порошков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1305,
    label: 'Изготовитель мишурной нити',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1306,
    label: 'Изготовитель многослойных панелей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1307,
    label: 'Изготовитель молдингов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1308,
    label: 'Изготовитель молоточков для клавишных инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1309,
    label: 'Изготовитель мороженого',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1310,
    label: 'Изготовитель музыкальных инструментов по индивидуальным заказам',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1311,
    label: 'Изготовитель мясных полуфабрикатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1312,
    label: 'Изготовитель набивок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1313,
    label: 'Изготовитель-наладчик пресс-проводок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 1314,
    label: 'Изготовитель натуральной колбасной оболочки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1315,
    label: 'Изготовитель орудий лова',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1316,
    label: 'Изготовитель оснастки для жаккардовых машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1317,
    label: 'Изготовитель основы валяльно-войлочных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1318,
    label: 'Изготовитель очковых оправ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1319,
    label: 'Изготовитель парафиновых колец',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1320,
    label: 'Изготовитель перопуховых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1321,
    label: 'Изготовитель пищевых полуфабрикатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1322,
    label: 'Изготовитель пластмассовой аппаратуры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1323,
    label: 'Изготовитель подбор и гужиков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1324,
    label: 'Изготовитель полуфабрикатов из мяса птицы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1325,
    label: 'Изготовитель препаратов драгоценных металлов и люстров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1326,
    label: 'Изготовитель прессовочных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1327,
    label: 'Изготовитель приспособлений для выращивания монокристаллов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1328,
    label: 'Изготовитель пробок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1329,
    label: 'Изготовитель профильных заготовок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1330,
    label: 'Изготовитель пульпоэкстракторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1331,
    label: 'Изготовитель ранта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1332,
    label: 'Изготовитель ремиз',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1333,
    label: 'Изготовитель саговой крупки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1334,
    label: 'Изготовитель светофильтров и поляроидов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1335,
    label: 'Изготовитель свечей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1336,
    label: 'Изготовитель сепараторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1337,
    label: 'Изготовитель сигар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1338,
    label: 'Изготовитель сильфонных компенсаторов и шлангов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1339,
    label: 'Изготовитель синели',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1340,
    label: 'Изготовитель синельки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1341,
    label: 'Изготовитель сметаны',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1342,
    label: 'Изготовитель специальных спичек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1343,
    label: 'Изготовитель стеклоблоков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1344,
    label: 'Изготовитель стекловаренных керамических емкостей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1345,
    label: 'Изготовитель стеклопластиковых гребных винтов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1346,
    label: 'Изготовитель стеклопластиковых изделий намоткой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1347,
    label: 'Изготовитель стеклянной пленки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1348,
    label: 'Изготовитель стержней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1349,
    label: 'Изготовитель струн',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1350,
    label: 'Изготовитель субтитров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1351,
    label: 'Изготовитель сычужного порошка и пищевого пепсина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1352,
    label: 'Изготовитель таблеток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1353,
    label: 'Изготовитель творога',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1354,
    label: 'Изготовитель тензорезисторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1355,
    label: 'Изготовитель трафаретов, шкал и плат',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1356,
    label: 'Изготовитель труб из органического стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1357,
    label: 'Изготовитель труб из фторопласта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1358,
    label: 'Изготовитель филигранных основ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1359,
    label: 'Изготовитель фильер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1360,
    label: 'Изготовитель фильтров и прокладок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1361,
    label: 'Изготовитель фитилей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1362,
    label: 'Изготовитель фольгированных стеклоплит',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1363,
    label: 'Изготовитель форм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1364,
    label: 'Изготовитель форм из дерева',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1365,
    label: 'Изготовитель формодержателей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1366,
    label: 'Изготовитель художественных изделий из бересты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1367,
    label: 'Изготовитель художественных изделий из дерева',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1368,
    label: 'Изготовитель художественных изделий из керамики',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1369,
    label: 'Изготовитель художественных изделий из кожи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1370,
    label: 'Изготовитель художественных изделий из лозы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1371,
    label: 'Изготовитель художественных изделий из льноволокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1372,
    label: 'Изготовитель художественных изделий из металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1373,
    label: 'Изготовитель художественных изделий из пластмасс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1374,
    label: 'Изготовитель художественных изделий из соломки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1375,
    label: 'Изготовитель художественных изделий из янтаря',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1376,
    label: 'Изготовитель целлулоидных колец',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1377,
    label: 'Изготовитель шаблонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1378,
    label: 'Изготовитель шкал и сеток фотоспособом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1379,
    label: 'Изготовитель шоколада',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1380,
    label: 'Изготовитель штампов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1381,
    label: 'Изготовитель щетино-щеточных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1382,
    label: 'Изготовитель электроизоляционных трубок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1383,
    label: 'Измеритель белковой оболочки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1384,
    label: 'Измеритель выпрямителей и элементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1385,
    label: 'Измеритель емкостей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1386,
    label: 'Измеритель кожевенно-мехового сырья и материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1387,
    label: 'Измеритель магнитных свойств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1388,
    label: 'Измеритель электрических параметров микромодулей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1389,
    label: 'Измеритель электрических параметров радиодеталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1390,
    label: 'Измеритель электрофизических параметров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1391,
    label: 'Изолировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1392,
    label: 'Изолировщик в термообработке',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '3 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1393,
    label: 'Изолировщик жил кабеля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1394,
    label: 'Изолировщик колец',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1395,
    label: 'Изолировщик кромок обрезиненного корда',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1396,
    label: 'Изолировщик на гидроизоляции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1397,
    label: 'Изолировщик на термоизоляции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1398,
    label: 'Изолировщик-пленочник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1399,
    label: 'Изолировщик проводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1400,
    label: 'Изолировщик световодов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1401,
    label: 'Изолировщик судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1402,
    label: 'Изолировщик труб на линии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1403,
    label: 'Изолировщик элементного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1404,
    label: 'Индикаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1405,
    label: 'Инспектор-пилот',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 1406,
    label: 'Инструктор-дезинфектор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1407,
    label: 'Интонировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1408,
    label: 'Информатор судоходной обстановки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1409,
    label: 'Иризаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1410,
    label: 'Исполнитель художественно-оформительских работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1411,
    label: 'Испытатель-взрывник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1412,
    label: 'Испытатель абразивов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1413,
    label: 'Испытатель агрегатов, приборов и чувствительных элементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1414,
    label: 'Испытатель баллонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1415,
    label: 'Испытатель бумажных мешков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1416,
    label: 'Испытатель вакуумных покрытий (тонких пленок)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1417,
    label: 'Испытатель вооружения боевых и специальных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1418,
    label: 'Испытатель двигателей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1419,
    label: 'Испытатель детонаторных устройств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1420,
    label: 'Испытатель деталей и приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1421,
    label: 'Испытатель изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1422,
    label: 'Испытатель инструментов из алмазов и сверхтвердых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1423,
    label: 'Испытатель источников тока',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1424,
    label: 'Испытатель карборундовых стержней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1425,
    label: 'Испытатель колец',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1426,
    label: 'Испытатель металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1427,
    label: 'Испытатель металлических канатов и цепей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1428,
    label: 'Испытатель-механик двигателей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от кратковременного воздействия открытого пламени, от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1429,
    label: 'Испытатель на герметичность',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1430,
    label: 'Испытатель на критическом стенде',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1431,
    label: 'Испытатель пожарных лестниц и ограждений крыш',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1432,
    label: 'Испытатель проводов и кабелей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1433,
    label: 'Испытатель протезно-ортопедических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1434,
    label: 'Испытатель резиновых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1435,
    label: 'Испытатель селенового производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1436,
    label: 'Испытатель специзделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1437,
    label: 'Испытатель стекловолокнистых материалов и стеклопластиков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1438,
    label: 'Испытатель стеклоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1439,
    label: 'Испытатель-формировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1440,
    label: 'Испытатель электрических машин, аппаратов и приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1441,
    label: 'Испытатель электрокерамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1442,
    label: 'Испытатель электроугольных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1443,
    label: 'Истопник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1444,
    label: 'Кабельщик-спайщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1445,
    label: 'Кабестанщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1446,
    label: 'Кабинщик-кантовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1447,
    label: 'Каландровщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 1448,
    label: 'Каландровщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1449,
    label: 'Каландровщик на обрезинке металлокордного полотна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1450,
    label: 'Каландровщик резиновых смесей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1451,
    label: 'Каландровщик целлюлозно-бумажного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1452,
    label: 'Калибровщик гипсовых форм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1453,
    label: 'Калибровщик изделий лечебного назначения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1454,
    label: 'Калибровщик карандашных дощечек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1455,
    label: 'Калибровщик катушек электроприборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1456,
    label: 'Калибровщик коконов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1457,
    label: 'Калибровщик магнитопроводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1458,
    label: 'Калибровщик набивок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1459,
    label: 'Калибровщик нитеобразователей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1460,
    label: 'Калибровщик пищевых продуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1461,
    label: 'Калибровщик-прецизионист в производстве масок цветных кинескопов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1462,
    label: 'Калибровщик-раскройщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1463,
    label: 'Калибровщик слюды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1464,
    label: 'Калибровщик стеклоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1465,
    label: 'Калибровщик труб на прессе',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1466,
    label: 'Калибровщик трубок из пластических масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1467,
    label: 'Калибровщик форм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1468,
    label: 'Калибровщик электроугольных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1469,
    label: 'Калильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 1470,
    label: 'Калильщик чепрака и технической кожи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1471,
    label: 'Калильщик янтаря',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1472,
    label: 'Камбузник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1473,
    label: 'Каменщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1474,
    label: 'Каменщик (печник) дежурный у печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от кратковременного воздействия открытого пламени, от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, от кратковременного воздействия открытого пламени, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1475,
    label: 'Камеронщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1476,
    label: 'Камневар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1477,
    label: 'Камнетес',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, ударов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1478,
    label: 'Канавщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1479,
    label: 'Канилировщик проволоки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1480,
    label: 'Кантователь керамических труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1481,
    label: 'Кантовщик-укладчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1482,
    label: 'Капиллярщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1483,
    label: 'Каптажист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1484,
    label: 'Карбидировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1485,
    label: 'Карбонизатор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1486,
    label: 'Карбонизаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1487,
    label: 'Кардовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1488,
    label: 'Каротажник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1489,
    label: 'Картонажник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1490,
    label: 'Карьерщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1491,
    label: 'Кастелянша',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 1492,
    label: 'Катализаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1493,
    label: 'Катодчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1494,
    label: 'Кашировальщик-красильщик фольги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1495,
    label: 'Каюр',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1496,
    label: 'Кварцедув',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1497,
    label: 'Кварцеплавильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1498,
    label: 'Кессонщик-аппаратчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1499,
    label: 'Кессонщик-проходчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1500,
    label: 'Кессонщик-слесарь',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1501,
    label: 'Кессонщик-электромонтажник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1502,
    label: 'Кеттельщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1503,
    label: 'Киномеханик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1504,
    label: 'Кинофотооператор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1505,
    label: 'Киоскер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 1506,
    label: 'Кисловщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1507,
    label: 'Кислотоупорщик-винипластчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1508,
    label: 'Кислотоупорщик-гуммировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1509,
    label: 'Кислотчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1510,
    label: 'Клавиатурщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1511,
    label: 'Кладовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1512,
    label: 'Кладовщик инструментальной кладовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1513,
    label: 'Кладовщик склада спецпродукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1514,
    label: 'Кладчик стекла на поливную машину',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1515,
    label: 'Классировщик шерсти и пуха',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1516,
    label: 'Классификаторщик дробленого камня',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1517,
    label: 'Классификаторщик крокуса и наждака',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1518,
    label: 'Классификаторщик песка и пемзы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1519,
    label: 'Классификаторщик порошков из алмазов и сверхтвердых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1520,
    label: 'Классификаторщик слюды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1521,
    label: 'Классификаторщик шлифпорошков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1522,
    label: 'Клеевар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1523,
    label: 'Клеильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1524,
    label: 'Клеймовщик горячего металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с нагретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1525,
    label: 'Клейщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1526,
    label: 'Клейщик бумаги, картона и изделий из них',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1527,
    label: 'Клейщик изделий из металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1528,
    label: 'Клейщик миканитов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1529,
    label: 'Клейщик-опрессовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1530,
    label: 'Клейщик пленкоэлектрокартона',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1531,
    label: 'Клейщик резиновых, полимерных деталей и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1532,
    label: 'Клейщик силовой арматуры и мягких баков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1533,
    label: 'Клейщик фибры и литых бумажных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1534,
    label: 'Клейщик эбонитовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1535,
    label: 'Клепальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1536,
    label: 'Клеровщик сахара',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1537,
    label: 'Клишист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1538,
    label: 'Коагулировщик пектина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1539,
    label: 'Коагулянщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1540,
    label: 'Ковровщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1541,
    label: 'Ковшевой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от выплесков расплавленного металла, кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от выплесков расплавленного металла, контакта с нагретой поверхностью, кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1542,
    label: 'Кокильщик-сборщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины, от кратковременного воздействия открытого пламени, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1543,
    label: 'Кокономотальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1544,
    label: 'Коконосмесчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1545,
    label: 'Коксоочиститель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1546,
    label: 'Коксоразгрузчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1547,
    label: 'Колорист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1548,
    label: 'Кольщик плит и блоков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1549,
    label: 'Кольщик слюды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1550,
    label: 'Командир (пилот, летчик) воздушного судна инструктор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 1551,
    label: 'Комплектовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1552,
    label: 'Комплектовщик авиационной техники',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1553,
    label: 'Комплектовщик белья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1554,
    label: 'Комплектовщик гипсовых форм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1555,
    label: 'Комплектовщик готовой продукции и отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1556,
    label: 'Комплектовщик деталей игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1557,
    label: 'Комплектовщик деталей и изделий из бумаги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1558,
    label: 'Комплектовщик деталей музыкальных инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 1559,
    label: 'Комплектовщик изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1560,
    label: 'Комплектовщик изделий и инструмента',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1561,
    label: 'Комплектовщик изделий, полуфабрикатов и материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1562,
    label: 'Комплектовщик карандашей и стержней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1563,
    label: 'Комплектовщик костей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1564,
    label: 'Комплектовщик материалов, кроя и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1565,
    label: 'Комплектовщик мебели',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1566,
    label: 'Комплектовщик микромодулей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1567,
    label: 'Комплектовщик моделей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1568,
    label: 'Комплектовщик панелей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1569,
    label: 'Комплектовщик полуфабрикатов медицинских изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1570,
    label: 'Комплектовщик проводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1571,
    label: 'Комплектовщик пряжи, ткани и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1572,
    label: 'Комплектовщик пьезотехнических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1573,
    label: 'Комплектовщик специзделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1574,
    label: 'Комплектовщик стекла и стеклоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1575,
    label: 'Комплектовщик сырья, материалов и готовой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1576,
    label: 'Комплектовщик сухих зоопрепаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1577,
    label: 'Комплектовщик товаров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1578,
    label: 'Комплектовщик туб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1579,
    label: 'Комплектовщик фарфоровых и фаянсовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1580,
    label: 'Комплектовщик фильмокопий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1581,
    label: 'Комплектовщик форм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1582,
    label: 'Комплектовщик форм сусальных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1583,
    label: 'Комплектовщик шрифтовой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1584,
    label: 'Конвертерщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от выплесков расплавленного металла, кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от выплесков расплавленного металла, контакта с нагретой поверхностью, кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1585,
    label: 'Конвертчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1586,
    label: 'Конденсаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей, для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1587,
    label: 'Кондитер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1588,
    label: 'Кондиционерщик зерна и семян',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1589,
    label: 'Кондуктор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 1590,
    label: 'Кондуктор главный грузовых поездов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1591,
    label: 'Кондуктор грузовых поездов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1592,
    label: 'Коневод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (истирания)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1593,
    label: 'Конопатчик электрощеточного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1594,
    label: 'Консервировщик кожевенного и пушно-мехового сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1595,
    label: 'Консервировщик кожевенно-мехового сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1596,
    label: 'Консервировщик оборудования и металлоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1597,
    label: 'Консервировщик пантов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1598,
    label: 'Контролер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1599,
    label: 'Контролер абразивных материалов и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1600,
    label: 'Контролер автоматических пропускных пунктов метрополитена',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1601,
    label: 'Контролер асбестоцементных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1602,
    label: 'Контролер бетонных и железобетонных изделий и конструкций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1603,
    label: 'Контролер в аккумуляторном и элементном производстве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1604,
    label: 'Контролер-визитажник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1605,
    label: 'Контролер внутреннего полигона предприятия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1606,
    label: 'Контролер в литейном производстве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1607,
    label: 'Контролер водопроводного хозяйства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1608,
    label:
      'Контролер в производстве алмазов, сверхтвердых материалов и изделий из них',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1609,
    label: 'Контролер в производстве лития',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1610,
    label: 'Контролер в производстве металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1611,
    label: 'Контролер в производстве черных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1612,
    label: 'Контролер в производстве электроизоляционных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1613,
    label: 'Контролер газового хозяйства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1614,
    label: 'Контролер деревообрабатывающего производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1615,
    label: 'Контролер деталей и приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1616,
    label: 'Контролер детонаторных устройств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1617,
    label: 'Контролер игольно-платинных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1618,
    label: 'Контролер изделий из камня',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1619,
    label: 'Контролер изделий из слюды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1620,
    label: 'Контролер измерения продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 1621,
    label:
      'Контролер изделий на автоматизированных измерительных средствах (системах) контроля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1622,
    label: 'Контролер изделий, полуфабрикатов и материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 1623,
    label: 'Контролер измерительных приборов и специального инструмента',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1624,
    label: 'Контролер кабельных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1625,
    label: 'Контролер качества',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 1626,
    label: 'Контролер качества обработки изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1627,
    label: 'Контролер качества обработки одежды и белья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1628,
    label: 'Контролер качества продукции и технологического процесса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1629,
    label: 'Контролер кип',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1630,
    label: 'Контролер котельных, холодноштамповочных и давильных работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1631,
    label: 'Контролер кровельных и гидроизоляционных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1632,
    label: 'Контролер кузнечно-прессовых работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1633,
    label: 'Контролер лесозаготовительного производства и лесосплава',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1634,
    label: 'Контролер лома и отходов металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1635,
    label: 'Контролер малярных работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1636,
    label: 'Контролер материалов, изделий и лекал',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1637,
    label: 'Контролер материалов и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1638,
    label: 'Контролер материалов, металлов, полуфабрикатов и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1639,
    label: 'Контролер медицинского оборудования и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1640,
    label:
      'Контролер мехового сырья и полуфабрикатов в скорняжном производстве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1641,
    label:
      'Контролер мехового сырья и полуфабрикатов в сырейно-красильном производстве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1642,
    label: 'Контролер монетно-орденского производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1643,
    label: 'Контролер музыкальных инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1644,
    label: 'Контролер наглядных пособий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1645,
    label: 'Контролер контрольно-пропускного пункта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1646,
    label: 'Контролер оптических деталей и приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1647,
    label: 'Контролер основного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1648,
    label: 'Контролер печного хозяйства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1649,
    label: 'Контролер пищевой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1650,
    label: 'Контролер пленки, растворов и фильмовых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1651,
    label: 'Контролер по драгоценной продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1652,
    label: 'Контролер по звучанию',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1653,
    label: 'Контролер по качеству нефти и нефтепродуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1654,
    label: 'Контролер полимерных строительных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1655,
    label: 'Контролер полуфабрикатов и готовой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1656,
    label: 'Контролер по термообработке',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1657,
    label: 'Контролер прессовых и слесарно-сборочных работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1658,
    label: 'Контролер прессовых работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1659,
    label: 'Контролер-приемщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1660,
    label: 'Контролер-приемщик микроэлементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1661,
    label: 'Контролер-приемщик сборок технологических каналов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1662,
    label: 'Контролер-приемщик фарфоровых, фаянсовых и керамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1663,
    label: 'Контролер продукции медицинского назначения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1664,
    label: 'Контролер продукции обогащения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1665,
    label: 'Контролер продукции цветной металлургии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1666,
    label: 'Контролер производства изотопов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1667,
    label: 'Контролер производства керамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1668,
    label: 'Контролер производства стекловолокна и стеклопластиков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1669,
    label: 'Контролер производства ТВЭЛ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1670,
    label: 'Контролер производства ферритовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1671,
    label: 'Контролер работ по металлопокрытиям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1672,
    label: 'Контролер радиоэлектронной аппаратуры и приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1673,
    label: 'Контролер-расчетчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1674,
    label: 'Контролер режимов работы технологического оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1675,
    label: 'Контролер ремизо-бердочного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1676,
    label: 'Контролер сборки, разборки специзделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1677,
    label: 'Контролер сборки, разборки ядерных боеприпасов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1678,
    label: 'Контролер сборки электрических машин, аппаратов и приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1679,
    label: 'Контролер сборочно-монтажных и ремонтных работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1680,
    label: 'Контролер сборочно-снаряжательных работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1681,
    label: 'Контролер сварочных работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1682,
    label: 'Контролер светочувствительных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1683,
    label: 'Контролер сетевых организаций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ультрафиолета, слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1684,
    label: 'Контролер сетеизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1685,
    label: 'Контролер слесарно-сборочных, разборочных работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1686,
    label: 'Контролер спецпроизводства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1687,
    label: 'Контролер станочных и слесарных работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1688,
    label: 'Контролер стекольного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1689,
    label: 'Контролер стеновых и вяжущих материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1690,
    label: 'Контролер струн и сшивок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1691,
    label: 'Контролер судокорпусных, судомонтажных и трубопроводных работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1692,
    label: 'Контролер суспензии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1693,
    label: 'Контролер сырья и полуфабрикатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1694,
    label: 'Контролер теплоизоляционных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1695,
    label:
      'Контролер технического надзора по инструменту и технологической оснастке',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1696,
    label: 'Контролер технического состояния автомототранспортных средств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1697,
    label: 'Контролер технологического процесса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1698,
    label: 'Контролер углеприема',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1699,
    label: 'Контролер-учетчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1700,
    label: 'Контролер художественных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1701,
    label:
      'Контролер хранения, транспортирования ядерных боеприпасов и ядерноделящихся материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1702,
    label: 'Контролер целлюлозно-бумажного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1703,
    label: 'Контролер цехов плавки, дробления, регенерации и рассева',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1704,
    label: 'Контролер часового и камневого производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1705,
    label: 'Контролер шинного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1706,
    label: 'Контролер электровакуумного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1707,
    label: 'Контролер электродного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1708,
    label: 'Контролер электромонтажных работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1709,
    label: 'Контролер эмалевого покрытия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1710,
    label: 'Контролер энергонадзора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1711,
    label: 'Контуровщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1712,
    label: 'Контуровщик стеклопластиковых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1713,
    label: 'Концентраторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1714,
    label: 'Конюх',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1715,
    label: 'Координатографист прецизионной фотолитографии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1716,
    label: 'Копировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1717,
    label: 'Копировщик печатных форм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1718,
    label: 'Копировщик рисунков и карт',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1719,
    label: 'Копировщик фильмовых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1720,
    label: 'Копровщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1721,
    label: 'Копровщик по разделке лома и отходов металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1722,
    label: 'Коптильщик колбасного сыра',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1723,
    label: 'Кормач',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1724,
    label: 'Корректировщик ванн',
    SIZ: [
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1725,
    label: 'Корректировщик шлама',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1726,
    label: 'Корректор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 1727,
    label: 'Корчевщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1728,
    label: 'Косметик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 1729,
    label: 'Костюмер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 1730,
    label: 'Котельщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1731,
    label: 'Котельщик судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1732,
    label: 'Котлочист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы.',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы.',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1733,
    label: 'Кочегар-обжигальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания неавтономные с подачей чистого воздуха по шлангу/магистрали (дыхательные аппараты)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1734,
    label: 'Кочегар паровозов в депо',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки - для защиты от контакта с нагретой поверхностью, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1735,
    label: 'Кочегар производственных печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1736,
    label: 'Кочегар сушильных барабанов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1737,
    label: 'Кочегар сушильных печей и барабанов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1738,
    label: 'Кочегар технологических печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с нагретой поверхностью',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1739,
    label: 'Крановщик-сборщик, разборщик специзделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1740,
    label: 'Крановый электрик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1741,
    label: 'Красильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1742,
    label: 'Красильщик волоса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1743,
    label: 'Красильщик в пастижерском производстве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1744,
    label: 'Красильщик зеркал',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1745,
    label: 'Красильщик карандашей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1746,
    label: 'Красильщик кож',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1747,
    label: 'Красильщик материалов для игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1748,
    label: 'Красильщик меха и шубной овчины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1749,
    label: 'Красковар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1750,
    label: 'Краскосоставитель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1751,
    label: 'Краскотер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды, растворов нетоксичных веществ, растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1752,
    label: 'Крепильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1753,
    label: 'Крепильщик деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1754,
    label: 'Крепильщик мандрен',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1755,
    label: 'Кровельщик по рулонным кровлям и по кровлям из штучных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1756,
    label: 'Кровельщик по стальным кровлям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1757,
    label: 'Кроликовод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1758,
    label: 'Кружевница',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1759,
    label: 'Крутильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1760,
    label: 'Крутильщик жгутов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1761,
    label: 'Крутильщик сеточника',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1762,
    label: 'Крутильщик шнуров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1763,
    label: 'Кубовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1764,
    label: 'Кузнец-бурозаправщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1765,
    label: 'Кузнец драгоценных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1766,
    label: 'Кузнец на молотах и прессах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1767,
    label: 'Кузнец ручной ковки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1768,
    label: 'Кузнец-штамповщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1769,
    label: 'Кузнец-штамповщик на ротационных машинах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1770,
    label: 'Кулинар изделий из рыбы и морепродуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1771,
    label: 'Купажист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1772,
    label: 'Купажист пектинового экстракта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1773,
    label: 'Купажист по табакам',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1774,
    label: 'Купажист по чаю',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1775,
    label: 'Курьер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1776,
    label: 'Кухонный рабочий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1777,
    label: 'Кучер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1778,
    label: 'Лаборант асбестообогатительного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1779,
    label: 'Лаборант аэрозольной лаборатории',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1780,
    label: 'Лаборант взрывных испытаний',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1781,
    label: 'Лаборант входного контроля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1782,
    label: 'Лаборант газового анализа и тонких пленок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1783,
    label: 'Лаборант-геофизик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1784,
    label: 'Лаборант-геохимик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1785,
    label: 'Лаборант-голографист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1786,
    label: 'Лаборант диффузионной сварки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1787,
    label: 'Лаборант испытаний электротехнических материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1788,
    label: 'Лаборант-испытатель спецаппаратуры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1789,
    label: 'Лаборант-испытатель электровакуумных материалов и спаев',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1790,
    label: 'Лаборант-испытатель электровакуумных специзделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1791,
    label: 'Лаборант-коллектор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1792,
    label: 'Лаборант-коррозионист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1793,
    label: 'Лаборант-кристаллооптик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1794,
    label: 'Лаборант лаборатории искусственного старения стеклоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1795,
    label: 'Лаборант лаборатории спецпродуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1796,
    label: 'Лаборант лаборатории чувствительных элементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1797,
    label: 'Лаборант масс-спектрометрист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1798,
    label: 'Лаборант-металлограф',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1799,
    label: 'Лаборант-металлофизик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1800,
    label: 'Лаборант механических и климатических испытаний',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1801,
    label: 'Лаборант-микробиолог',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1802,
    label: 'Лаборант минералогического анализа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1803,
    label: 'Лаборант неразрушающих методов контроля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1804,
    label: 'Лаборант обработки фильмовой информации физического эксперимента',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1805,
    label: 'Лаборант определения плотности',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1806,
    label: 'Лаборант органических материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1807,
    label: 'Лаборант-пирометаллург',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1808,
    label: 'Лаборант по анализу газов в металлах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1809,
    label: 'Лаборант по анализу газов и пыли',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1810,
    label: 'Лаборант по анализу коконов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1811,
    label: 'Лаборант по анализу люминофоров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1812,
    label: 'Лаборант по анализу формовочных и шихтовых смесей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1813,
    label: 'Лаборант по вибрационным испытаниям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1814,
    label: 'Лаборант по выращиванию медицинских пиявок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1815,
    label: 'Лаборант по газодинамическим испытаниям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1816,
    label: 'Лаборант по гренажу',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1817,
    label: 'Лаборант по измерениям физических производственных факторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1818,
    label: 'Лаборант по инерционным испытаниям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1819,
    label: 'Лаборант по испытанию сверхпроводящих материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1820,
    label: 'Лаборант по испытаниям газовых систем специзделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1821,
    label: 'Лаборант по климатическим испытаниям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1822,
    label: 'Лаборант по механической обработке металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1823,
    label: 'Лаборант по обработке металлов давлением',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1824,
    label: 'Лаборант по обработке экспериментальных и расчетных данных',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1825,
    label: 'Лаборант по определению электрических характеристик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1826,
    label: 'Лаборант-полярографист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1827,
    label: 'Лаборант по обработке аэрофотопленок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1828,
    label: 'Лаборант по тепловым испытаниям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 1829,
    label: 'Лаборант порошковой металлургии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 1830,
    label: 'Лаборант по ультразвуковой технике',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1831,
    label: 'Лаборант по физико-механическим испытаниям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1832,
    label: 'Лаборант по электроизоляционным материалам',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1833,
    label: 'Лаборант-препаратор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1834,
    label: 'Лаборант приготовления электродов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1835,
    label: 'Лаборант-приемщик проб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1836,
    label: 'Лаборант пробирного анализа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1837,
    label: 'Лаборант производства бактерийных препаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1838,
    label: 'Лаборант радиоактивационных методов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1839,
    label: 'Лаборант-радиометрист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1840,
    label: 'Лаборант-радиофизик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1841,
    label: 'Лаборант-радиохимик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1842,
    label: 'Лаборант-радиоэлектроник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1843,
    label: 'Лаборант-расчетчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1844,
    label: 'Лаборант-расчетчик характеристик надежности',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1845,
    label: 'Лаборант-рентгеногаммаграфист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1846,
    label: 'Лаборант-рентгенолог газодинамической лаборатории',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1847,
    label: 'Лаборант рентгеноспектрального анализа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1848,
    label: 'Лаборант-рентгеноструктурщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1849,
    label: 'Лаборант-рентгеноэлектронографист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1850,
    label: 'Лаборант-сенситометрист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1851,
    label: 'Лаборант спектрального анализа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1852,
    label: 'Лаборант специальной лаборатории стабильных изотопов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1853,
    label: 'Лаборант теплотехнических стендов и установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1854,
    label: 'Лаборант-тензометрист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1855,
    label: 'Лаборант ускорительной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1856,
    label: 'Лаборант-теплофизик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1857,
    label: 'Лаборант-физик лаборатории спецпродукта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1858,
    label: 'Лаборант физико-химических исследований',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1859,
    label: 'Лаборант физической лаборатории',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1860,
    label: 'Лаборант-физик-гамма-спектрометрист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1861,
    label: 'Лаборант-фотометрист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1862,
    label: 'Лаборант химико-бактериологического анализа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1863,
    label: 'Лаборант химико-технологических исследований',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1864,
    label: 'Лаборант химического анализа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1865,
    label: 'Лаборант хроматографического анализа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1866,
    label: 'Лаборант экспериментальных стендов и установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1867,
    label: 'Лаборант-электроакустик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1868,
    label: 'Лаборант электровакуумных специзделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1869,
    label: 'Лаборант электромагнитной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1870,
    label: 'Лаборант электромеханических испытаний и измерений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1871,
    label: 'Лаборант электротехнической лаборатории',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1872,
    label: 'Лаборант электрофизической обработки материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1873,
    label: 'Лаборант-электрометаллург',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1874,
    label: 'Лаборант-электрохимик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1875,
    label: 'Лаборант ядерной физической лаборатории',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1876,
    label: 'Лаглинщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1877,
    label: 'Лакировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1878,
    label: 'Лакировщик глобусов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1879,
    label: 'Лакировщик деталей часов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1880,
    label: 'Лакировщик жести и труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1881,
    label: 'Лакировщик кож',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1882,
    label: 'Лакировщик оптических деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1883,
    label: 'Лакировщик подносов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1884,
    label: 'Лакировщик проводов и кабелей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1885,
    label: 'Лакировщик туб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1886,
    label: 'Лакировщик фибровых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1887,
    label: 'Лакировщик форм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1888,
    label: 'Лакировщик художественных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1889,
    label: 'Лакировщик электроизоляционных изделий и материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1890,
    label: 'Лаковар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1891,
    label: 'Лакоразводчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1892,
    label: 'Ламповщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1893,
    label: 'Ланолинщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1894,
    label: 'Лебедчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1895,
    label: 'Лебедчик на лесосплаве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от вредных биологических факторов (клещей и кровососущих насекомых), в том числе в комплекте с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от вредных биологических факторов (клещей и кровососущих насекомых), механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1896,
    label: 'Лебедчик на трелевке леса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от вредных биологических факторов (клещей и кровососущих насекомых), в том числе в комплекте с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от вредных биологических факторов (клещей и кровососущих насекомых), механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1897,
    label: 'Лебедчик на штабелевке и погрузке леса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от вредных биологических факторов (клещей и кровососущих насекомых), в том числе в комплекте с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от вредных биологических факторов (клещей и кровососущих насекомых), механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1898,
    label: 'Лентовой уборщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1899,
    label: 'Лепщик архитектурных деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1900,
    label: 'Лепщик скульптурного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1901,
    label: 'Лепщик электрокерамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1902,
    label: 'Лесовод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от вредных биологических факторов (клещей и кровососущих насекомых), в том числе в комплекте с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от вредных биологических факторов (клещей и кровососущих насекомых), механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1903,
    label: 'Лесоруб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Защитные приспособления для защиты от режущего воздействия ручной цепной пилой',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от вредных биологических факторов (клещей и кровососущих насекомых), в том числе в комплекте с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от режущего воздействия ручной цепной пилой',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от режущего воздействия ручной цепной пилой, от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от режущего воздействия ручной цепной пилой',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1904,
    label: 'Летчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Куртка летная кожаная',
        norm: '1 шт. на 60 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Полукомбинезон летный кожаный',
        norm: '1 шт. на 60 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1905,
    label: 'Летчик-испытатель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Куртка летная кожаная',
        norm: '1 шт. на 60 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Полукомбинезон летный кожаный',
        norm: '1 шт. на 60 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1906,
    label: 'Литейщик вакуумного, центробежно-вакуумного и центробежного литья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины, от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, искр и брызг расплавленного металла, металлической окалины, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1907,
    label: 'Литейщик гипсовых форм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1908,
    label: 'Литейщик изделий из свинцовых сплавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от выплесков расплавленного металла',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с нагретыми поверхностями, искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1909,
    label: 'Литейщик катализатора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от выплесков расплавленного металла',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1910,
    label: 'Литейщик керамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1911,
    label: 'Литейщик металлов и сплавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от выплесков расплавленного металла',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, искр и брызг расплавленного металла, металлической окалины, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1912,
    label: 'Литейщик методом направленной кристаллизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты, теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений, конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1913,
    label: 'Литейщик на машинах для литья под давлением',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от выплесков расплавленного металла',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1914,
    label: 'Литейщик облицовочных плиток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений, конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1915,
    label: 'Литейщик пластмасс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений, конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1916,
    label: 'Литейщик-прессовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от выплесков расплавленного металла',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1917,
    label: 'Литейщик радиокерамики и ферритов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений, конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1918,
    label: 'Литейщик санитарно-строительных изделий на конвейере',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1919,
    label: 'Литейщик санитарно-строительных изделий на стенде',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1920,
    label: 'Литейщик стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от выплесков расплавленного металла',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1921,
    label: 'Литейщик художественных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1922,
    label: 'Литейщик цветных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от выплесков расплавленного металла',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1923,
    label: 'Литейщик электрокерамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от выплесков расплавленного металла',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1924,
    label: 'Лифтер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1925,
    label: 'Ловец безнадзорных животных',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1926,
    label: 'Ломщик пода',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1927,
    label: 'Лоцман',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1928,
    label: 'Лудильщик горячим способом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1929,
    label: 'Лудильщик деталей и приборов горячим способом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1930,
    label: 'Лудильщик (оцинковщик) электролитическим методом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1931,
    label: 'Лудильщик проволоки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1932,
    label: 'Лучевальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1933,
    label: 'Лущильщик пленки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1934,
    label: 'Лущильщик шпона',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1935,
    label: 'Люковой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1936,
    label: 'Люминофорщик-экранировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1937,
    label: 'Магнезировщик-вакуумщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1938,
    label: 'Макаль',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Фильтрующий самоспасатель',
        norm: 'одноразовое применение',
      },
    ],
  },
  {
    profId: 1939,
    label: 'Макетчик макетно-модельного проектирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1940,
    label: 'Макетчик театрально-постановочных макетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1941,
    label: 'Макетчик художественных макетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1942,
    label: 'Маляр',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1943,
    label: 'Маляр по отделке декораций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1944,
    label: 'Маривод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1945,
    label: 'Маркировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1946,
    label: 'Маркировщик деталей и приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1947,
    label: 'Маркшейдер карьера, рудника, шахты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 1948,
    label: 'Маслодел',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1949,
    label: 'Маслодел-мастер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1950,
    label: 'Мастер-взрывник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 1951,
    label: 'Мастер газодымозащитной службы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1952,
    label:
      'Мастер дорожный, старший мастер дорожный; мастер мостовой; мастер тоннельный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 36 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 1953,
    label:
      'Мастер-наладчик по техническому обслуживанию машинно-тракторного парка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1954,
    label: 'Мастер по добыче нефти, газа и конденсата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и растворов нетоксичных веществ',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1955,
    label: 'Мастер поезда (восстановительного, рельсосварочного)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1956,
    label: 'Мастер по исследованию скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1957,
    label: 'Мастер по подготовке и стабилизации нефти',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1958,
    label: 'Мастер по проходке горных выработок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 1959,
    label: 'Мастер по сложным работам в бурении (капитальном ремонте) скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1960,
    label: 'Мастер по эксплуатации оборудования газовых объектов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1961,
    label: 'Мастер производства молочного сахара',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1962,
    label:
      'Мастер производства сухого обезжиренного молока и заменителя цельного молока',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1963,
    label: 'Мастер производства цельномолочной и кисломолочной продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1964,
    label:
      'Мастер производственного участка (старший мастер, мастер участка, мастер, мастер по ремонту энергетического оборудования) района электрических сетей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1965,
    label: 'Мастер производственного участка (старший мастер, мастер)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1966,
    label: 'Мастер ремонтно-строительной группы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1967,
    label: 'Мастер строительных и монтажных работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1968,
    label: 'Матировщик-вакуумщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1969,
    label: 'Матрос',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатка для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1970,
    label: 'Матрос береговой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1971,
    label: 'Матрос драги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1972,
    label: 'Матрос пожарный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1973,
    label: 'Матрос-спасатель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений, кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 1974,
    label: 'Мацератор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1975,
    label: 'Мачтовик-антенщик судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1976,
    label: 'Машинист автобетонолома',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1977,
    label: 'Машинист автобетононасоса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1978,
    label: 'Машинист автовышки и автогидроподъемника',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1979,
    label: 'Машинист автогрейдера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1980,
    label: 'Машинист автогудронатора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1981,
    label: 'Машинист автокамерного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1982,
    label: 'Машинист автокомпрессора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1983,
    label: 'Машинист автомата для завертывания книг в суперобложку',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки, для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1984,
    label: 'Машинист автомата по изготовлению обрезных обложек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1985,
    label: 'Машинист автоматической линии по изготовлению книг',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1986,
    label:
      'Машинист автоматической линии по изготовлению контейнеров из полимеров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1987,
    label:
      'Машинист автоматической линии по изготовлению тетрадей для нот и альбомов для рисования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1988,
    label: 'Машинист автомобилеразгрузчика',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1989,
    label: 'Машинист автомотрисы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 36 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 1990,
    label: 'Машинист автоямобура',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 1991,
    label: 'Машинист агрегата безвоздушного распыления высокого давления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1992,
    label: 'Машинист агрегата бесшвейного скрепления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1993,
    label: 'Машинист агрегата изготовления искусственной кожи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1994,
    label: 'Машинист агрегата изготовления стирательной резинки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1995,
    label: 'Машинист агрегата искусственного бархата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1996,
    label: 'Машинист агрегата по изготовлению навивочных рукавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1997,
    label: 'Машинист агрегатов по добыче соли в озере',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1998,
    label:
      'Машинист агрегатов по обслуживанию нефтегазопромыслового оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 1999,
    label: 'Машинист адресовальной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2000,
    label: 'Машинист алфавитной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2001,
    label: 'Машинист барабанного охладителя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2002,
    label: 'Машинист баровой установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2003,
    label: 'Машинист береговых насосных станций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2004,
    label: 'Машинист бетононасосной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2005,
    label: 'Машинист бетоносмесителя передвижного',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2006,
    label: 'Машинист бетоноукладчика',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2007,
    label: 'Машинист битумировочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2008,
    label: 'Машинист битумоплавильной передвижной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2009,
    label: 'Машинист блокообрабатывающего агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2010,
    label: 'Машинист блондочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2011,
    label:
      'Машинист блочного щита управления агрегатами (парогенератор, турбина)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2012,
    label: 'Машинист блочной системы управления агрегатами (котел, турбина)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2013,
    label: 'Машинист брикетного пресса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2014,
    label: 'Машинист-бронзировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2015,
    label: 'Машинист бульдозера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2016,
    label: 'Машинист бумагоделательной (картоноделательной) машины (сеточник)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2017,
    label: 'Машинист бумагокрасильной машины (красильщик)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2018,
    label: 'Машинист бурильнокрановой самоходной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2019,
    label: 'Машинист буровой установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов, проколов, порезов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2020,
    label: 'Машинист буровых установок на нефть и газ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2021,
    label: 'Машинист буртоукладочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2022,
    label: 'Машинист вагон-весов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2023,
    label: 'Машинист вагоноопрокидывателя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2024,
    label: 'Машинист вакуумной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2025,
    label: 'Машинист вакуум-упаковочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2026,
    label: 'Машинист вакуум-формовочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2027,
    label: 'Машинист вакуум-формующей машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2028,
    label: 'Машинист валькосъемной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2029,
    label: 'Машинист вальцовых станков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2030,
    label: 'Машинист ведущего мотора прокатного стана',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2031,
    label: 'Машинист вентиляционной и аспирационной установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2032,
    label: 'Машинист вибровдавливающего погружателя свай самоходного',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2033,
    label: 'Машинист вибропогружателя бескопрового',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2034,
    label: 'Машинист вибропогрузочной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2035,
    label: 'Машинист вибросит резальных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2036,
    label: 'Машинист винтовых насосов (фуллеровщик)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2037,
    label: 'Машинист вкладочно-швейно-резального агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2038,
    label: 'Машинист-водитель машины разграждения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2039,
    label: 'Машинист воздухоразделительных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2040,
    label: 'Машинист воздушно-канатной дороги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2041,
    label: 'Машинист выборочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2042,
    label: 'Машинист выдувных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2043,
    label: 'Машинист вырубально-упаковочного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2044,
    label: 'Машинист высекально-штамповочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2045,
    label: 'Машинист высотно-компрессорной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2046,
    label: 'Машинист вышивальной машины Пантограф',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2047,
    label: 'Машинист газогенераторной станции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2048,
    label: 'Машинист газодувных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2049,
    label: 'Машинист газокаротажной станции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2050,
    label: 'Машинист газораздаточной станции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2051,
    label: 'Машинист газотурбинных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2052,
    label: 'Машинист гидроагрегатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2053,
    label:
      'Машинист гидромониторно-эжекторного плавучего несамоходного снаряда',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2054,
    label: 'Машинист гидроочистки и смазки изложниц',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2055,
    label: 'Машинист гидропневматической установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2056,
    label: 'Машинист гидросеялки самоходной',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2057,
    label: 'Машинист гидротипного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2058,
    label: 'Машинист глинорезной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2059,
    label: 'Машинист горных выемочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов, проколов, порезов), нефти и/или нефтепродуктов и жиров',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2060,
    label: 'Машинист гофрировального агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2061,
    label: 'Машинист гранулирования пластических масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2062,
    label: 'Машинист гранулятора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2063,
    label: 'Машинист грейдера прицепного',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2064,
    label: 'Машинист грейдер-элеватора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2065,
    label: 'Машинист гренировальной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2066,
    label: 'Машинист грузового причала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2067,
    label: 'Машинист гуммировального агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2068,
    label: 'Машинист двигателей внутреннего сгорания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2069,
    label: 'Машинист двоильной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2070,
    label: 'Машинист дизельмолота бескопрового',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2071,
    label: 'Машинист дизельпоезда',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2072,
    label: 'Машинист дистрибутора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2073,
    label: 'Машинист дноклеильной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2074,
    label: 'Машинист дорожно-транспортных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2075,
    label: 'Машинист драги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2076,
    label: 'Машинист дражировочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2077,
    label: 'Машинист дренажной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2078,
    label: 'Машинист дробильно-погрузочного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2079,
    label: 'Машинист дробильно-помольно-сортировочных механизмов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2080,
    label: 'Машинист дробильных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2081,
    label: 'Машинист дробильных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2082,
    label: 'Машинист дублирующего агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2083,
    label: 'Машинист железнодорожного водоснабжения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2084,
    label: 'Машинист железнодорожно-строительных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2085,
    label: 'Машинист завалочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2086,
    label: 'Машинист загрузочных механизмов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2087,
    label: 'Машинист закаточных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2088,
    label: 'Машинист заправочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2089,
    label: 'Машинист землеройно-фрезерной самоходной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2090,
    label: 'Машинист землесосного плавучего несамоходного снаряда',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2091,
    label: 'Машинист землесосной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2092,
    label: 'Машинист зерновых погрузочно-разгрузочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2093,
    label: 'Машинист зумпфового агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2094,
    label:
      'Машинист-инструктор бригад путевых машин и моторно-рельсового транспорта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2095,
    label: 'Машинист-инструктор локомотивных бригад',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2096,
    label: 'Машинист ионизационной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2097,
    label: 'Машинист каландра',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2098,
    label: 'Машинист калибровочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2099,
    label: 'Машинист кальцинаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2100,
    label: 'Машинист камнерезной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2101,
    label: 'Машинист катальной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2102,
    label:
      'Машинист катка самоходного и полуприцепного на пневматических шинах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2103,
    label: 'Машинист катка самоходного с гладкими вальцами',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2104,
    label: 'Машинист клеевого агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2105,
    label: 'Машинист клеильно-загибочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2106,
    label: 'Машинист клеильно-сушильной машины (клейщик)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2107,
    label: 'Машинист книговставочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2108,
    label: 'Машинист коксовых машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2109,
    label: 'Машинист коксопогрузочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2110,
    label:
      'Машинист компрессора передвижного с двигателем внутреннего сгорания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2111,
    label: 'Машинист компрессора передвижного с электродвигателем',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2112,
    label: 'Машинист компрессорных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2113,
    label: 'Машинист конвейера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2114,
    label: 'Машинист конвертной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2115,
    label:
      'Машинист контактно-сварочной установки передвижной для сварки магистральных газонефтепродуктопроводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени, искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины, нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2116,
    label: 'Машинист копра',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2117,
    label: 'Машинист котельной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2118,
    label: 'Машинист котельной установки (кочегар) судна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, от кратковременного воздействия открытого пламени, от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2119,
    label: 'Машинист котлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2120,
    label: 'Машинист (кочегар) котельной',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'До износа',
      },
    ],
  },
  {
    profId: 2121,
    label: 'Машинист крана автомобильного (крана-манипулятора)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2122,
    label: 'Машинист крана (крановщик)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2123,
    label: 'Машинист крана металлургического производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2124,
    label: 'Машинист крана склада сульфата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2125,
    label: 'Машинист-крановщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2126,
    label: 'Машинист красильного и вулканизационного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2127,
    label: 'Машинист кратцера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2128,
    label: 'Машинист креповой машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2129,
    label: 'Машинист крытвенной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2130,
    label: 'Машинист крышкоделательной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2131,
    label: 'Машинист лакировально-гуммировальной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2132,
    label: 'Машинист лакировочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2133,
    label: 'Машинист лаконаносящей машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2134,
    label: 'Машинист ледорезной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2135,
    label: 'Машинист линии нанесения точечного полимерного покрытия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2136,
    label: 'Машинист линии непрерывной ферментации табака',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2137,
    label: 'Машинист линии подготовки табака к ферментации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2138,
    label: 'Машинист линий аэрозольтранспорта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2139,
    label: 'Машинист линовальной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2140,
    label: 'Машинист листоформовочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2141,
    label: 'Машинист локомотива на паромах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2142,
    label: 'Машинист льдопогрузочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2143,
    label: 'Машинист малярной станции передвижной',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2144,
    label: 'Машинист маркировочной машины для разметки автомобильных дорог',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2145,
    label: 'Машинист махорочно-набивных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2146,
    label: 'Машинист машин вытягивания стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2147,
    label: 'Машинист машин и механизмов внутренних водоемов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2148,
    label: 'Машинист машин по добыче и переработке кускового торфа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2149,
    label: 'Машинист машин по добыче и переработке фрезерного торфа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2150,
    label: 'Машинист машин по заготовке тростника',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2151,
    label: 'Машинист машин по подготовке торфяных месторождений к эксплуатации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2152,
    label: 'Машинист машин по чистке и смазке листов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2153,
    label: 'Машинист машины длинноволокнистых бумаг',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2154,
    label: 'Машинист машины для вкладки книг в футляры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2155,
    label: 'Машинист машины для изоляции газонефтепродуктопроводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2156,
    label: 'Машинист машины для ломки футеровки конвертеров и ковшей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2157,
    label: 'Машинист машины для нанесения пленкообразующей жидкости',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2158,
    label: 'Машинист машины для покрытия бумаги полиэтиленовой пленкой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2159,
    label: 'Машинист машины для устройства укрепительных полос',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2160,
    label: 'Машинист машины для устройства швов при выполнении дорожных работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: ' ',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2161,
    label: 'Машинист машины огневой зачистки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений, кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2162,
    label: 'Машинист машины по изготовлению бумаги для табачных фильтров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2163,
    label: 'Машинист машины по изготовлению гильз',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2164,
    label: 'Машинист машины по производству изделий из бумаги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2165,
    label: 'Машинист мельниц',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2166,
    label:
      'Машинист механизированного натяжного устройства для изготовления напряженно-армированных конструкций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2167,
    label:
      'Машинист механизированного оборудования по подъему подвижной (скользящей) опалубки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2168,
    label: 'Машинист механического или флотационного обогащения руды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2169,
    label: 'Машинист механического катка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2170,
    label:
      'Машинист механического оборудования землесосных плавучих несамоходных снарядов и грунтонасосных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2171,
    label: 'Машинист мешалок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2172,
    label: 'Машинист микструдера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2173,
    label: 'Машинист моечной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2174,
    label: 'Машинист моечных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), воды',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), от воды',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2175,
    label: 'Машинист мостового перегружателя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2176,
    label: 'Машинист мотовоза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2177,
    label: 'Машинист мягчильной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2178,
    label: 'Машинист навивочных и намоточных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2179,
    label: 'Машинист на молотах, прессах и манипуляторах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2180,
    label: 'Машинист на припрессовке пленки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2181,
    label: 'Машинист нарезчика швов самоходного',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2182,
    label: 'Машинист наслаивающей машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2183,
    label: 'Машинист насосной станции по закачке рабочего агента в пласт',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2184,
    label: 'Машинист насосных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2185,
    label: 'Машинист низальных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2186,
    label: 'Машинист (обжигальщик) вращающихся печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2187,
    label: 'Машинист (обжигальщик) шахтных печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2188,
    label: 'Машинист обкаточной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2189,
    label: 'Машинист обойно-печатной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2190,
    label: 'Машинист оборудования конвейерных и поточных линий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2191,
    label: 'Машинист оборудования распределительных нефтебаз',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2192,
    label: 'Машинист обсадочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2193,
    label: 'Машинист-обходчик по котельному оборудованию',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2194,
    label: 'Машинист-обходчик по турбинному оборудованию',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2195,
    label: 'Машинист обклеечно-каптальной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2196,
    label: 'Машинист окомкователя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2197,
    label: 'Машинист оплеточной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2198,
    label: 'Машинист опудривателя окатышей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2199,
    label: 'Машинист отвалообразователя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2200,
    label: 'Машинист отвального моста',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2201,
    label: 'Машинист отвального плуга',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2202,
    label:
      'Машинист отжимного оборудования (дубильноэкстрактовое производство)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2203,
    label:
      'Машинист отжимного оборудования (кожевенное и кожсырьевое производство)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2204,
    label: 'Машинист отжимной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2205,
    label: 'Машинист отжимно-промывного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2206,
    label: 'Машинист отливной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2207,
    label: 'Машинист охладителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2208,
    label: 'Машинист очистительного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2209,
    label: 'Машинист очистительных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2210,
    label: 'Машинист ошпарочного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2211,
    label: 'Машинист папочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2212,
    label: 'Машинист паровоза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2213,
    label: 'Машинист паровой машины и локомобиля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2214,
    label: 'Машинист паровой передвижной депарафинизационной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2215,
    label: 'Машинист паровых турбин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2216,
    label: 'Машинист паровых турбин атомных электрических станций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2217,
    label: 'Машинист паровых турбин специального назначения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2218,
    label:
      'Машинист парогенераторной установки по закачке пара в нефтяные пласты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2219,
    label: 'Машинист пергаментной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2220,
    label: 'Машинист перегружателей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2221,
    label: 'Машинист передвижного компрессора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2222,
    label: 'Машинист перекидного желоба',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2223,
    label: 'Машинист перопухообрабатывающих машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2224,
    label: 'Машинист пескоподающей установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2225,
    label: 'Машинист печатно-высекательного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2226,
    label: 'Машинист печатной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2227,
    label: 'Машинист питателя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2228,
    label: 'Машинист плунжерной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2229,
    label: 'Машинист пневматических насосов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2230,
    label: 'Машинист пневматической установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2231,
    label: 'Машинист пневмотранспорта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2232,
    label: 'Машинист поворотной и подъемной машин моста',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2233,
    label: 'Машинист погрузочно-доставочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2234,
    label: 'Машинист погрузочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2235,
    label: 'Машинист подборочно-швейной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2236,
    label: 'Машинист подземных самоходных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов, проколов, порезов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2237,
    label: 'Машинист подземных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов, проколов, порезов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2238,
    label: 'Машинист подъемника',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2239,
    label: 'Машинист подъемника грузопассажирского строительного',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2240,
    label: 'Машинист подъемника каротажной станции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2241,
    label: 'Машинист подъемника мачтового, стоечного и шахтного',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2242,
    label: 'Машинист подъемника по опробованию скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2243,
    label: 'Машинист подъемной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2244,
    label: 'Машинист подъемно-передвижных подмостей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2245,
    label: 'Машинист полуавтоматической линии двоения овчины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2246,
    label: 'Машинист по моторным испытаниям топлива',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2247,
    label: 'Машинист помповой (донкерман)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2248,
    label: 'Машинист по навивке канатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2249,
    label: 'Машинист по обслуживанию силосов и угольной башни',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2250,
    label: 'Машинист по очистке кукурузных початков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2251,
    label: 'Машинист по разматыванию щетинных лент',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2252,
    label: 'Машинист поточно-автоматизированных линий переработки табака',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2253,
    label: 'Машинист поточной линии формования хлебных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2254,
    label:
      'Машинист поточно-механизированных папиросо-сигаретных линий и машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2255,
    label: 'Машинист по цементажу скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2256,
    label: 'Машинист предформователя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2257,
    label: 'Машинист пресса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2258,
    label: 'Машинист пресс-гранулятора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2259,
    label: 'Машинист пресспата (сеточник)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2260,
    label: 'Машинист прикатной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2261,
    label: 'Машинист приклеенной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2262,
    label: 'Машинист прокатной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2263,
    label: 'Машинист прокатной машины термостойкого стекла и стеклопрофилита',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2264,
    label: 'Машинист промывочного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2265,
    label: 'Машинист промывочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2266,
    label: 'Машинист промывочно-сушильно-ширильно-стабилизационной линии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2267,
    label: 'Машинист промывочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2268,
    label: 'Машинист пропиточного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2269,
    label: 'Машинист просеивающих установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2270,
    label: 'Машинист протекторного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2271,
    label: 'Машинист протирочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2272,
    label: 'Машинист профилировщика',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2273,
    label: 'Машинист проходческого комплекса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов, проколов, порезов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2274,
    label: 'Машинист пруфера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2275,
    label: 'Машинист путепередвигателя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2276,
    label: 'Машинист пылевых насосов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2277,
    label: 'Машинист разводной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2278,
    label: 'Машинист разволакнивающей машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2279,
    label: 'Машинист разливочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2280,
    label: 'Машинист разливочно-наполнительных автоматов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2281,
    label: 'Машинист размольного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2282,
    label: 'Машинист размораживающей установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2283,
    label: 'Машинист расплеточной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2284,
    label: 'Машинист распределителя цемента аэрационного самоходного',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2285,
    label: 'Машинист распределителя цемента гравитационного прицепного',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2286,
    label: 'Машинист растворонасоса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2287,
    label: 'Машинист растворосмесителя передвижного',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2288,
    label: 'Машинист расфасовочно-упаковочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2289,
    label: 'Машинист ратинирующей машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2290,
    label: 'Машинист реактивной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2291,
    label: 'Машинист реверсивной паровой машины прокатного стана',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2292,
    label: 'Машинист регенерационной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2293,
    label: 'Машинист-регулировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2294,
    label: 'Машинист резальных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2295,
    label: 'Машинист резиносмесителя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2296,
    label: 'Машинист рекордерной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2297,
    label: 'Машинист релевочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2298,
    label: 'Машинист ритуального оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2299,
    label: 'Машинист рифлевальной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2300,
    label: 'Машинист ровничной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2301,
    label: 'Машинист рубильной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2302,
    label: 'Машинист рудоусреднительной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2303,
    label: 'Машинист рушальных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2304,
    label: 'Машинист рыбомучной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2305,
    label: 'Машинист рыбоподъемника',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов), воды',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов), воды',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2306,
    label: 'Машинист рыбопромысловых машин и механизмов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов, проколов), воды',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов), воды',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2307,
    label: 'Машинист рыхлительных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2308,
    label: 'Машинист самоходного весоповерочного вагона',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2309,
    label: 'Машинист самоходного кабелепередвижчика',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2310,
    label: 'Машинист самоходной газорастворомешалки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2311,
    label: 'Машинист сбивальных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2312,
    label: 'Машинист семеочистительных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2313,
    label: 'Машинист семешлифовальных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2314,
    label: 'Машинист силиконовой машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2315,
    label: 'Машинист сквиджевого станка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2316,
    label: 'Машинист скипового подъемника',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2317,
    label: 'Машинист скрепера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2318,
    label: 'Машинист скрепера (скреперист)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2319,
    label: 'Машинист скреперной лебедки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2320,
    label: 'Машинист слитколомателя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2321,
    label: 'Машинист слюдовыборочной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2322,
    label: 'Машинист слюдопластоделательной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2323,
    label: 'Машинист смесительного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2324,
    label: 'Машинист смесительной установки гидрозакладки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2325,
    label: 'Машинист смесительных барабанов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2326,
    label: 'Машинист смесителя асфальтобетона передвижного',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2327,
    label: 'Машинист солекомбайна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2328,
    label: 'Машинист солеобогатительной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2329,
    label: 'Машинист солеуборочного комбайна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2330,
    label: 'Машинист сортировки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2331,
    label: 'Машинист сортировочного автомата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2332,
    label: 'Машинист сплоточной (сортировочной) машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2333,
    label: 'Машинист сталеструйной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2334,
    label: 'Машинист станции высокого давления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 2335,
    label: 'Машинист стрейнера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2336,
    label: 'Машинист сублимационных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2337,
    label: 'Машинист сухих доковых установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2338,
    label: 'Машинист сушильной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2339,
    label: 'Машинист сушильных агрегатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2340,
    label: 'Машинист сцены',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2341,
    label: 'Машинист сшивальной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2342,
    label: 'Машинист сырьевых мельниц',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2343,
    label: 'Машинист табакорезальных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2344,
    label: 'Машинист-таблетировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2345,
    label: 'Машинист телескопических трапов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2346,
    label: 'Машинист тельфера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2347,
    label: 'Машинист тепловоза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2348,
    label: 'Машинист термодисперсионной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2349,
    label: 'Машинист термостабилизационной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2350,
    label: 'Машинист терочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2351,
    label: 'Машинист тестомесильных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2352,
    label: 'Машинист тесторазделочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2353,
    label: 'Машинист тетрадных агрегатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2354,
    label: 'Машинист технологических компрессоров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2355,
    label: 'Машинист технологических насосов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2356,
    label: 'Машинист топливоподачи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2357,
    label: 'Машинист торфодобывающего экскаватора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2358,
    label: 'Машинист трамбовки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2359,
    label: 'Машинист трелевочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2360,
    label: 'Машинист трубной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2361,
    label: 'Машинист трубогибочной установки передвижной',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2362,
    label: 'Машинист трубоочистительной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2363,
    label: 'Машинист трубоукладчика',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2364,
    label: 'Машинист трубочной машины (трубочник)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2365,
    label: 'Машинист тягового агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2366,
    label: 'Машинист уборочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2367,
    label: 'Машинист угольных мельниц',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2368,
    label: 'Машинист укладчика асфальтобетона',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: ' ',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2369,
    label: 'Машинист упаковочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2370,
    label: 'Машинист уплотняющей и планировочно-уплотняющей машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2371,
    label:
      'Машинист установки передвижной автоматизированной непрерывного действия для приготовления бетонных смесей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2372,
    label:
      'Машинист установки по испытанию железобетонных изделий и конструкций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2373,
    label: 'Машинист установки по обработке транспортных средств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2374,
    label: 'Машинист установки по приготовлению пульпы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2375,
    label:
      'Машинист установки по продавливанию и горизонтальному бурению грунта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2376,
    label: 'Машинист установки по разрушению негабаритов горной массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2377,
    label: 'Машинист установки по расщеплению слюды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2378,
    label: 'Машинист установки самоклеящихся пленок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2379,
    label: 'Машинист установки сухого тушения кокса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2380,
    label: 'Машинист установок обогащения и брикетирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2381,
    label: 'Машинист установок по обслуживанию подвижного состава',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2382,
    label: 'Машинист фальцевальных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2383,
    label: 'Машинист фиксационных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2384,
    label: 'Машинист фильтроделательных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2385,
    label: 'Машинист фильтр-пресса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2386,
    label: 'Машинист финишера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2387,
    label: 'Машинист формовочного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2388,
    label: 'Машинист формовочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2389,
    label: 'Машинист формующе-завертывающего полуавтомата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2390,
    label: 'Машинист формующих машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2391,
    label: 'Машинист фрезагрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2392,
    label: 'Машинист фрезерно-зачистной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2393,
    label: 'Машинист холодильника',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2394,
    label: 'Машинист холодильной установки по замораживанию грунтов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2395,
    label: 'Машинист холодильных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2396,
    label: 'Машинист цементных мельниц',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2397,
    label: 'Машинист центрального теплового щита управления котлами',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2398,
    label: 'Машинист центрального теплового щита управления паровыми турбинами',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2399,
    label: 'Машинист чаезавялочно-фиксационных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2400,
    label: 'Машинист чаезавялочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2401,
    label: 'Машинист чаескручивающих машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2402,
    label: 'Машинист чаесушильных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2403,
    label: 'Машинист чесальных и мешальных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2404,
    label: 'Машинист шахтных холодильных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2405,
    label: 'Машинист швейных машин и автоматов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2406,
    label: 'Машинист шихтоподачи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2407,
    label: 'Машинист шламовых насосов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2408,
    label: 'Машинист шоколадо-отделочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2409,
    label: 'Машинист шпрединг-машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2410,
    label: 'Машинист шприц-машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2411,
    label: 'Машинист шпулезаверточных и конусных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2412,
    label: 'Машинист штабелеформирующей машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2413,
    label: 'Машинист штукатурной станции передвижной',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2414,
    label: 'Машинист штыревого крана',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2415,
    label: 'Машинист шурфопроходческого агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2416,
    label: 'Машинист эксгаустера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2417,
    label: 'Машинист экскаватора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2418,
    label: 'Машинист экскаватора одноковшового',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2419,
    label: 'Машинист экскаватора роторного',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2420,
    label: 'Машинист экструдера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2421,
    label: 'Машинист экструзионного пресса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2422,
    label:
      'Машинист электрического оборудования землесосных плавучих несамоходных снарядов и грунтонасосных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2423,
    label: 'Машинист электровоза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2424,
    label: 'Машинист электровоза шахтного',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий. (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2425,
    label: 'Машинист электровоза металлургического цеха',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2426,
    label: 'Машинист электровоза тушильного вагона',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2427,
    label: 'Машинист электролафета',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2428,
    label: 'Машинист электролебедки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2429,
    label: 'Машинист электропоезда',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2430,
    label:
      'Машинист электросварочного передвижного агрегата с двигателем внутреннего сгорания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2431,
    label: 'Машинист электростанции передвижной',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2432,
    label: 'Машинист энергоблока',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2433,
    label: 'Машинист энсонитной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2434,
    label: 'Машинист эскалатора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2435,
    label: 'Медицинская сестра приемного отделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2436,
    label: 'Медицинская сестра процедурной',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2437,
    label: 'Медицинский дезинфектор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: ' ',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2438,
    label: 'Медник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2439,
    label: 'Медник по изготовлению судовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2440,
    label: 'Мездрильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2441,
    label: 'Мездрильщик шкур',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2442,
    label: 'Мездрильщик шкурок кроликов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2443,
    label: 'Меланжист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2444,
    label: 'Мельник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2445,
    label: 'Мельник деревообрабатывающего производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2446,
    label: 'Мельник извести',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нетоксичной пыли, в том числе с головным убором,',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов),',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нетоксичной пыли',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2447,
    label: 'Мельник минерального сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2448,
    label: 'Мельник эмалевых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2449,
    label: 'Металлизатор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени, от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от кратковременного воздействия открытого пламени, от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2450,
    label: 'Металлизатор электрокерамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2451,
    label:
      'Механизатор (докер-механизатор) комплексной бригады на погрузочно-разгрузочных работах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2452,
    label: 'Механик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2453,
    label: 'Механик-артиллерист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2454,
    label: 'Механик по обслуживанию ветроустановок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2455,
    label: 'Механик по обслуживанию звуковой техники',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2456,
    label: 'Механик по обслуживанию кинотелевизионного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2457,
    label: 'Механик по обслуживанию съемочной техники',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2458,
    label: 'Механик по техническим видам спорта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2459,
    label: 'Механик по обслуживанию телевизионного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2460,
    label: 'Механик судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2461,
    label: 'Механик экспериментальных стендов и установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2462,
    label: 'Механик протезно-ортопедических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2463,
    label: 'Мешальщик керамического шликера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2464,
    label: 'Мешальщик сухой массы (для свинцовых аккумуляторов)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2465,
    label: 'Мешальщик угольных масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2466,
    label: 'Миксеровой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2467,
    label: 'Миксовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2468,
    label: 'Моделировщик искусственных зубов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2469,
    label: 'Модельер колодок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 2470,
    label: 'Модельер коробок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2471,
    label: 'Модельер ортопедической обуви',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2472,
    label: 'Модельщик архитектурных деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2473,
    label: 'Модельщик аэрогидродинамических моделей из металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2474,
    label: 'Модельщик аэрогидродинамических моделей из неметалла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2475,
    label: 'Модельщик выплавляемых моделей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2476,
    label: 'Модельщик гипсовых моделей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2477,
    label: 'Модельщик керамического производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2478,
    label: 'Модельщик наглядных пособий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2479,
    label: 'Модельщик по деревянным моделям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2480,
    label: 'Модельщик по металлическим моделям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2481,
    label: 'Модельщик по моделям из эпоксидных смол',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2482,
    label: 'Модельщик резиновой обуви',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2483,
    label: 'Модельщик скульптурного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2484,
    label: 'Модельщик стеклопластиков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2485,
    label: 'Модистка головных уборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2486,
    label: 'Мозаичник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2487,
    label: 'Мозаичник монументально-декоративной живописи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2488,
    label: 'Мойщик ковров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2489,
    label: 'Мойщик колб с применением кислотных растворов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2490,
    label: 'Мойщик летательных аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2491,
    label: 'Мойщик мездры и волоса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2492,
    label: 'Мойщик мокрых отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2493,
    label: 'Мойщик покрышек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2494,
    label: 'Мойщик посуды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2495,
    label: 'Мойщик посуды и ампул',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2496,
    label: 'Мойщик-сушильщик металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2497,
    label: 'Мойщик сыра',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2498,
    label: 'Мойщик-уборщик подвижного состава',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2499,
    label: 'Мойщик фибры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2500,
    label: 'Мойщик холстов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки, для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2501,
    label: 'Мойщик шерсти (валяльно-войлочное производство)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2502,
    label: 'Мойщик шерсти (шерстяное производство)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2503,
    label: 'Мойщик щетины и волоса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2504,
    label: 'Моллировщик стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2505,
    label: 'Монтажист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2506,
    label: 'Монтажник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2507,
    label: 'Монтажник аппаратуры специальных головных уборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2508,
    label: 'Монтажник-вакуумщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2509,
    label: 'Монтажник геодезических знаков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), вредных биологических факторов (клещей и кровососущих насекомых), в том числе в комплекте с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от вредных биологических факторов (клещей и кровососущих насекомых), механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2510,
    label: 'Монтажник гидроагрегатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2511,
    label: 'Монтажник горного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 2512,
    label: 'Монтажник детонаторных устройств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2513,
    label:
      'Монтажник дробильно-размольного оборудования и оборудования для сортировки и обогащения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2514,
    label: 'Монтажник компрессоров, насосов и вентиляторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2515,
    label: 'Монтажник металлорежущего и кузнечно-прессового оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2516,
    label: 'Монтажник механического оборудования гидротехнических сооружений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2517,
    label: 'Монтажник микромодулей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2518,
    label: 'Монтажник на ремонте ванн',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2519,
    label: 'Монтажник на ремонте печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от кратковременного воздействия открытого пламени, механических воздействий (ударов) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от кратковременного воздействия открытого пламени',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, от кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2520,
    label: 'Монтажник наружных трубопроводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нефти и/или нефтепродуктов, сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2521,
    label: 'Монтажник негатива',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2522,
    label: 'Монтажник оборудования атомных электрических станций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2523,
    label:
      'Монтажник оборудования блокировки и централизации на железнодорожном транспорте',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2524,
    label: 'Монтажник оборудования деревообрабатывающих предприятий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2525,
    label:
      'Монтажник оборудования зернохранилищ и предприятий по промышленной переработке зерна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2526,
    label: 'Монтажник оборудования коксохимических производств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2527,
    label: 'Монтажник оборудования котельных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2528,
    label: 'Монтажник оборудования металлургических заводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2529,
    label: 'Монтажник оборудования предприятий пищевой промышленности',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2530,
    label: 'Монтажник оборудования предприятий полиграфической промышленности',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2531,
    label: 'Монтажник оборудования предприятий строительных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2532,
    label: 'Монтажник оборудования предприятий текстильной промышленности',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2533,
    label:
      'Монтажник оборудования предприятий химической и нефтяной промышленности',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2534,
    label:
      'Монтажник оборудования предприятий целлюлозно-бумажной промышленности',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2535,
    label: 'Монтажник оборудования связи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2536,
    label: 'Монтажник оборудования сортировочных горок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2537,
    label: 'Монтажник оборудования холодильных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2538,
    label:
      'Монтажник подъемно-транспортного оборудования непрерывного действия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2539,
    label: 'Монтажник подъемно-транспортного оборудования прерывного действия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2540,
    label: 'Монтажник позитива',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2541,
    label: 'Монтажник по монтажу стальных и железобетонных конструкций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2542,
    label:
      'Монтажник приборов и аппаратуры автоматического контроля, регулирования и управления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2543,
    label: 'Монтажник радио- и специального оборудования летательных аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2544,
    label: 'Монтажник радиоэлектронной аппаратуры и приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2545,
    label: 'Монтажник реакционных аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2546,
    label: 'Монтажник санитарно-технических систем и оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2547,
    label: 'Монтажник санитарно-технического оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2548,
    label: 'Монтажник связи - антенщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2549,
    label: 'Монтажник связи - кабельщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от воздействия электромагнитных полей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2550,
    label: 'Монтажник связи - линейщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2551,
    label: 'Монтажник связи - спайщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от воздействия электромагнитных полей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2552,
    label: 'Монтажник селеновых выпрямителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2553,
    label: 'Монтажник сельскохозяйственного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2554,
    label:
      'Монтажник систем вентиляции, кондиционирования воздуха, пневмотранспорта и аспирации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2555,
    label: 'Монтажник строительных машин и механизмов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2556,
    label: 'Монтажник тензорезисторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2557,
    label: 'Монтажник технологических трубопроводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нефти и/или нефтепродуктов, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2558,
    label:
      'Монтажник технологического оборудования и связанных с ним конструкций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2559,
    label: 'Монтажник трелевочного и погрузочного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2560,
    label: 'Монтажник турбоагрегатов и синхронных компенсаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2561,
    label: 'Монтажник-установщик внешней арматуры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2562,
    label: 'Монтажник фотокомплекта "Момент"',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2563,
    label: 'Монтажник шахтного оборудования на поверхности',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2564,
    label: 'Монтажник экспозиции и художественно оформительских работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2565,
    label: 'Монтажник электрических подъемников (лифтов)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2566,
    label: 'Монтажник электрооборудования летательных аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2567,
    label: 'Монтажник элементов памяти на ферритах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2568,
    label: 'Монтер кабельного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2569,
    label: 'Монтер по защите подземных трубопроводов от коррозии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2570,
    label: 'Монтер пути',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 36 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2571,
    label: 'Монтер судовых средств безопасности',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2572,
    label: 'Монтер судоходной обстановки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2573,
    label: 'Монтировщик вентилей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2574,
    label: 'Монтировщик влажных препаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2575,
    label: 'Монтировщик изделий из драгоценных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2576,
    label: 'Монтировщик изделий из камня',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2577,
    label: 'Монтировщик искусственных зубов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2578,
    label: 'Монтировщик костных препаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2579,
    label: 'Монтировщик микросрезов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2580,
    label: 'Монтировщик муляжей и моделей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2581,
    label: 'Монтировщик остеологических коллекций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2582,
    label: 'Монтировщик скелетов мелких животных',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2583,
    label: 'Монтировщик стеклометаллизированной нити',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2584,
    label: 'Монтировщик сухих биологических объектов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2585,
    label: 'Монтировщик сцены',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2586,
    label: 'Монтировщик технологических коллекций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2587,
    label: 'Монтировщик шин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2588,
    label: 'Монтировщик шинопневматических муфт',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2589,
    label: 'Мостовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2590,
    label: 'Мотальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2591,
    label: 'Моторист автоматизированной топливоподачи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2592,
    label: 'Моторист багерной (шламовой) насосной',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2593,
    label: 'Моторист бетоносмесительных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2594,
    label: 'Моторист буровой установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2595,
    label: 'Моторист вентиляционной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2596,
    label: 'Моторист водосброса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2597,
    label: 'Моторист (машинист)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2598,
    label: 'Моторист (машинист) рефрижераторных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2599,
    label: 'Моторист механической лопаты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нетоксичной пыли, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2600,
    label: 'Моторист на подаче крокусной суспензии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2601,
    label: 'Моторист передаточной тележки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2602,
    label: 'Моторист поворотного круга',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2603,
    label: 'Моторист по уборке оборудования электростанций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2604,
    label: 'Моторист промывочного прибора по извлечению металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2605,
    label: 'Моторист рапокачки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2606,
    label: 'Моторист самоходной каротажной станции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2607,
    label: 'Моторист-смазчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от нефти и/или нефтепродуктов',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2608,
    label: 'Моторист смесителя и мешалки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2609,
    label: 'Моторист самостоятельного управления судовым двигателем',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2610,
    label: 'Моторист теплой промывки котлов паровозов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания неавтономные с подачей чистого воздуха по шлангу/магистрали (дыхательные аппараты)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2611,
    label: 'Моторист транспортирующих механизмов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2612,
    label: 'Моторист триера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2613,
    label: 'Моторист трюмный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2614,
    label: 'Моторист установки по перекачиванию битума',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2615,
    label: 'Моторист холодильного барабана',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2616,
    label: 'Моторист цементировочного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2617,
    label: 'Моторист цементопескосмесительного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2618,
    label: 'Моторист электродвигателей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2619,
    label: 'Моторист электроразведочной станции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2620,
    label: 'Муляжист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2621,
    label: 'Мягчильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2622,
    label: 'Набивальщик валиков и фильтров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2623,
    label: 'Набивальщик наконечников на шнур',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2624,
    label: 'Набивальщик трубчатых электронагревателей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2625,
    label: 'Набивщик блоков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2626,
    label: 'Набивщик изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2627,
    label: 'Набивщик одонков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2628,
    label: 'Набойщик рисунков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2629,
    label: 'Набойщик рисунков манерами',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2630,
    label: 'Набойщик рисунков на обои',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2631,
    label: 'Наборщик блока из остеклованных стержней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2632,
    label: 'Наборщик бумажных валов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2633,
    label: 'Наборщик ворсовальных рамок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2634,
    label: 'Наборщик вручную',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2635,
    label: 'Наборщик деталей часов и камней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2636,
    label: 'Наборщик зубцов и петель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2637,
    label: 'Наборщик игольно-платинных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2638,
    label: 'Наборщик керамических плиток в ковры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2639,
    label: 'Наборщик ковриков из мозаичной плитки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2640,
    label: 'Наборщик на машинах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2641,
    label: 'Наборщик на наборно-печатающих машинках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2642,
    label: 'Наборщик на наборно-строкоотливных машинах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2643,
    label: 'Наборщик облицовочных материалов для мебели',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2644,
    label: 'Наборщик пакетов листов и труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2645,
    label: 'Наборщик проб в шахте',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 2646,
    label: 'Наборщик ремиз',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2647,
    label: 'Наборщик рисунка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2648,
    label: 'Наборщик рисунчатых валов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2649,
    label: 'Наборщик стекломассы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2650,
    label: 'Наборщик стопоров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2651,
    label: 'Наборщик текстолитовых ободов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2652,
    label: 'Наборщик цен',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2653,
    label: 'Навалоотбойщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2654,
    label: 'Навальщик-свальщик лесоматериалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Защитные приспособления для защиты от режущего воздействия ручной цепной пилой',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от вредных биологических факторов (клещей и кровососущих насекомых), в том числе в комплекте с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от режущего воздействия ручной цепной пилой',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от режущего воздействия ручной цепной пилой, от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от режущего воздействия ручной цепной пилой',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2655,
    label: 'Навальщик соли в бассейнах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2656,
    label: 'Навесчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2657,
    label: 'Навесчик заготовок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2658,
    label: 'Навесчик-составитель асбестовых смесей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2659,
    label: 'Навесчик стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2660,
    label: 'Навивальщик основ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2661,
    label: 'Навивщик картона',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2662,
    label: 'Навивщик магнитопроводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2663,
    label: 'Навивщик металлокордного полотна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2664,
    label: 'Навивщик пружин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2665,
    label: 'Навойщик-проборщик основы металлосеток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2666,
    label: 'Нагревальщик металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2667,
    label: 'Нагревальщик (сварщик) металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины, от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2668,
    label: 'Нагревальщик цветных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2669,
    label: 'Наездник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2670,
    label: 'Наждачник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'или',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2671,
    label: 'Накалывальщик растительного войлока',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2672,
    label: 'Накатчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2673,
    label: 'Накатчик бумагоделательной (картоно-делательной) машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2674,
    label: 'Накатчик изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2675,
    label: 'Накатчик клеильно-сушильной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2676,
    label: 'Накатчик машины для покрытия бумаги полиэтиленовой пленкой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2677,
    label: 'Накатчик-обкатчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2678,
    label: 'Накатчик пергаментной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2679,
    label: 'Накатчик полировальных кругов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2680,
    label: 'Накатчик пресспата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2681,
    label: 'Накатчик резьбы цоколей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2682,
    label: 'Накатчик рисунков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2683,
    label: 'Накатчик ткани, полотна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2684,
    label: 'Накладчик на печатных машинах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2685,
    label: 'Наклейщик вставок для ювелирных и художественных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2686,
    label: 'Наклейщик заготовок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2687,
    label: 'Наклейщик орнамента на багет',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2688,
    label: 'Наладчик автоматизированных вагон-весов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2689,
    label: 'Наладчик автоматических линий и агрегатных станков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2690,
    label: 'Наладчик автоматов и полуавтоматов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2691,
    label: 'Наладчик автоматов сварки выводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2692,
    label: 'Наладчик автоматов элементного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2693,
    label: 'Наладчик асбестоцементного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2694,
    label: 'Наладчик геофизической аппаратуры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2695,
    label: 'Наладчик деревообрабатывающего оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2696,
    label: 'Наладчик зуборезных и резьбофрезерных станков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2697,
    label: 'Наладчик зубофрезерных автоматов и полуавтоматов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2698,
    label: 'Наладчик контрольно-измерительных вагонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2699,
    label: 'Наладчик контрольно-измерительных приборов и автоматики',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2700,
    label: 'Наладчик кузнечно-прессового оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2701,
    label: 'Наладчик литейных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2702,
    label:
      'Наладчик машин и автоматических линий по производству изделий из пластмасс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2703,
    label: 'Наладчик моечных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2704,
    label: 'Наладчик-монтажник испытательного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2705,
    label: 'Наладчик настольных станков и прессов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2706,
    label: 'Наладчик оборудования в бумажном производстве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2707,
    label:
      'Наладчик оборудования в производстве асбестовых технических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2708,
    label: 'Наладчик оборудования в производстве аэрозольных упаковок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2709,
    label: 'Наладчик оборудования в производстве драгоценных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2710,
    label:
      'Наладчик оборудования в производстве металлических канатов, сеток, пружин, щеток и цепей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2711,
    label: 'Наладчик оборудования в производстве пищевой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2712,
    label:
      'Наладчик оборудования в производстве стекловолокна и стеклопластиков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2713,
    label: 'Наладчик оборудования в производстве стеновых и вяжущих материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2714,
    label: 'Наладчик оборудования в производстве теплоизоляционных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2715,
    label: 'Наладчик оборудования железобетонного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 2716,
    label: 'Наладчик оборудования жестяно-баночного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2717,
    label: 'Наладчик оборудования и агрегатов в термообработке',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2718,
    label: 'Наладчик оборудования игольного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2719,
    label: 'Наладчик оборудования керамического производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2720,
    label: 'Наладчик оборудования лакокрасочных покрытий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2721,
    label: 'Наладчик оборудования металлопокрытия и окраски',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2722,
    label: 'Наладчик оборудования оптического производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2723,
    label: 'Наладчик оборудования по обработке камня',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2724,
    label: 'Наладчик оборудования по производству резиновых изделий и обуви',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2725,
    label: 'Наладчик окомковательных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2726,
    label: 'Наладчик полиграфического оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2727,
    label: 'Наладчик полуавтоматических установок аккумуляторного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2728,
    label: 'Наладчик поточно-автоматизированных линий производства сигарет',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2729,
    label:
      'Наладчик приборов, аппаратуры и систем автоматического контроля, регулирования и управления (наладчик КИП и автоматики)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2730,
    label: 'Наладчик железнодорожно-строительных машин и механизмов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2731,
    label: 'Наладчик ремизо-бердочного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2732,
    label: 'Наладчик сборочных автоматов, полуавтоматов и автоматических линий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2733,
    label: 'Наладчик сварочного и газоплазморезательного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2734,
    label: 'Наладчик сельскохозяйственных машин и тракторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2735,
    label: 'Наладчик сортировочных автоматов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2736,
    label: 'Наладчик станков и манипуляторов с программным управлением',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2737,
    label: 'Наладчик стекольных автоматов и полуавтоматов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2738,
    label: 'Наладчик строительных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2739,
    label: 'Наладчик технологического оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2740,
    label: 'Наладчик ускорителей заряженных частиц',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2741,
    label: 'Наладчик установок для синтеза алмазов и сверхтвердых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2742,
    label: 'Наладчик формовочных и стержневых машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2743,
    label: 'Наладчик холодноштамповочного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2744,
    label: 'Наладчик шлифовальных станков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2745,
    label: 'Намазчик аккумуляторных пластин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2746,
    label: 'Намазчик деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2747,
    label: 'Намазчик пасты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2748,
    label: 'Намазчик спичечных коробок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2749,
    label: 'Намазчик целлулоида',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2750,
    label: 'Намазчик электропроводного слоя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2751,
    label: 'Намотчик бумажных цилиндров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2752,
    label: 'Намотчик иглиц',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2753,
    label: 'Намотчик изоляционных остовов вводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2754,
    label: 'Намотчик катушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2755,
    label: 'Намотчик катушек для электроприборов и аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2756,
    label: 'Намотчик катушек и секций электромашин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2757,
    label: 'Намотчик катушек трансформаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2758,
    label: 'Намотчик материалов и полуфабрикатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2759,
    label: 'Намотчик проволоки и тросов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2760,
    label: 'Намотчик пряжи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2761,
    label: 'Намотчик резисторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2762,
    label: 'Намотчик ровничной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2763,
    label: 'Намотчик рулонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2764,
    label: 'Намотчик секций конденсаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2765,
    label: 'Намотчик секций силовых конденсаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2766,
    label: 'Намотчик серпантина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2767,
    label: 'Намотчик сеток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2768,
    label: 'Намотчик спиралей и сеток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2769,
    label: 'Намотчик утка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2770,
    label: 'Намотчик фильтров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2771,
    label: 'Намотчик электроизоляционных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2772,
    label: 'Намотчик электромагнитных сердечников',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2773,
    label: 'Напайщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2774,
    label: 'Наплавщик пластмассы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2775,
    label: 'Наполнитель ампул',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2776,
    label: 'Наполнитель баллонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2777,
    label: 'Наполнитель контейнеров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2778,
    label: 'Наполнитель приборов газами',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени, воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2779,
    label: 'Наполнитель приборов жидкостями',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2780,
    label: 'Нарезчик изделий из кости и рога',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2781,
    label: 'Насадчик бобин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2782,
    label: 'Насадчик валов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2783,
    label: 'Насадчик дефибрерных камней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2784,
    label: 'Насадчик манжет',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2785,
    label: 'Насадчик обуви',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2786,
    label: 'Насекальщик карт',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2787,
    label: 'Насекальщик мелющих камней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2788,
    label: 'Насекальщик напильников, рашпилей и пил',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2789,
    label: 'Настильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2790,
    label: 'Настильщик материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2791,
    label: 'Настильщик стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2792,
    label: 'Настильщик фильтр-полотен',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2793,
    label: 'Настройщик духовых инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2794,
    label: 'Настройщик музыкальных игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2795,
    label: 'Настройщик пианино и роялей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2796,
    label: 'Настройщик полупроводниковых приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2797,
    label: 'Настройщик пьезорезонаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2798,
    label: 'Настройщик-регулировщик смычковых инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2799,
    label: 'Настройщик щипковых инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2800,
    label: 'Настройщик язычковых инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2801,
    label: 'Насыпщик цемента',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2802,
    label: 'Натяжчик сеток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2803,
    label: 'Нейтрализаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2804,
    label: 'Нейтрализаторщик хромовой стружки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2805,
    label: 'Нейтрализаторщик цианистых растворов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2806,
    label: 'Нормализаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2807,
    label: 'Носильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2808,
    label: 'Нотогравер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2809,
    label: 'Нотографик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2810,
    label: 'Нумеровщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2811,
    label: 'Няня',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 2812,
    label: 'Обвальщик мяса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки и защитные приспособления кольчужные',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2813,
    label: 'Обвальщик тушек птицы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки и защитные приспособления кольчужные',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2814,
    label: 'Обвязчик агломератов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2815,
    label: 'Обвязчик электроугольных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2816,
    label: 'Обдирщик алмазов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2817,
    label: 'Обдувщик абразивных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2818,
    label: 'Обезвоживатель шлифзерна и шлифпорошков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2819,
    label: 'Обжарщик пищевых продуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2820,
    label: 'Обжигальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: ' ',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2821,
    label: 'Обжигальщик в производстве стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2822,
    label: 'Обжигальщик в производстве теплоизоляционных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2823,
    label: 'Обжигальщик графитовых стержней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2824,
    label: 'Обжигальщик игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2825,
    label: 'Обжигальщик извести',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски до износа',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2826,
    label: 'Обжигальщик изделий строительной керамики',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски до износа',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2827,
    label: 'Обжигальщик керамических пигментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски до износа',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2828,
    label: 'Обжигальщик ламп',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2829,
    label: 'Обжигальщик массивных шин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2830,
    label: 'Обжигальщик материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2831,
    label: 'Обжигальщик металлической тары',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2832,
    label: 'Обжигальщик на печах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2833,
    label: 'Обжигальщик отходов металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2834,
    label: 'Обжигальщик отходов производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2835,
    label: 'Обжигальщик прядильных деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2836,
    label: 'Обжигальщик радиокерамики, пьезокерамики и ферритов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2837,
    label: 'Обжигальщик рисовального угля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2838,
    label: 'Обжигальщик слюды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2839,
    label: 'Обжигальщик стеновых и вяжущих материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2840,
    label: 'Обжигальщик фарфоровых и фаянсовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2841,
    label: 'Обжигальщик электрокерамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2842,
    label: 'Обжигальщик электроугольных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2843,
    label: 'Обжигальщик эмали',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2844,
    label: 'Обжимщик электросоединителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2845,
    label: 'Обкатчик клюквы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2846,
    label: 'Обкатчик подшипников',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2847,
    label: 'Обкатчик труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2848,
    label: 'Облицовщик деталей мебели',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2849,
    label: 'Облицовщик-мозаичник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2850,
    label: 'Облицовщик-мраморщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2851,
    label: 'Облицовщик музыкальных инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2852,
    label: 'Облицовщик-плиточник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2853,
    label: 'Облицовщик-полировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2854,
    label: 'Облицовщик синтетическими материалами',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2855,
    label: 'Обмазчик заслонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2856,
    label: 'Обмазчик ковшей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2857,
    label: 'Обмазчик листов и труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2858,
    label: 'Обмолотчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нетоксичной пыли, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2859,
    label: 'Обмотчик элементов электрических машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2860,
    label: 'Обмуровщик кислотных резервуаров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания неавтономные с подачей чистого воздуха по шлангу/магистрали (дыхательные аппараты)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2861,
    label: 'Обогатитель графита',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2862,
    label: 'Обогатитель микропорошков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2863,
    label: 'Обогатитель отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2864,
    label: 'Обогатитель шлифзерна и шлифпорошков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: ' ',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2865,
    label: 'Обогатитель шлихов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2866,
    label: 'Обойщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2867,
    label: 'Обойщик мебели',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2868,
    label: 'Оборщик горных выработок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 2869,
    label: 'Обработчик вентилей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2870,
    label: 'Обработчик ветсанбрака',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2871,
    label: 'Обработчик винного сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2872,
    label: 'Обработчик виноматериалов и вина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2873,
    label: 'Обработчик воды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2874,
    label: 'Обработчик волокна и ткани',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2875,
    label: 'Обработчик волоса, шерсти и щетины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2876,
    label: 'Обработчик вторичных шламов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: ' ',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2877,
    label: 'Обработчик деталей, полуфабрикатов и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2878,
    label: 'Обработчик дрота',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2879,
    label: 'Обработчик заготовок для туб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2880,
    label: 'Обработчик заготовок из стекловолокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2881,
    label: 'Обработчик зуба берда',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2882,
    label: 'Обработчик изделий из кости и рога',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2883,
    label: 'Обработчик изделий из пластмасс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2884,
    label: 'Обработчик изделий из янтаря',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2885,
    label: 'Обработчик икры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2886,
    label: 'Обработчик кожевенно-мехового сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2887,
    label: 'Обработчик колбасных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2888,
    label: 'Обработчик коньячных спиртов и коньяков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2889,
    label: 'Обработчик крабов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2890,
    label: 'Обработчик кроликов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2891,
    label: 'Обработчик материалов латексом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2892,
    label: 'Обработчик матричных листов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2893,
    label: 'Обработчик меховых шкурок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2894,
    label: 'Обработчик морепродуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2895,
    label: 'Обработчик морского зверя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2896,
    label: 'Обработчик мясных туш',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2897,
    label: 'Обработчик натриевых болванок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от кратковременного воздействия открытого пламени, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты и теплового излучения, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2898,
    label: 'Обработчик основы металлосеток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2899,
    label: 'Обработчик отходов виноделия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2900,
    label: 'Обработчик перламутра',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2901,
    label: 'Обработчик пищевых продуктов и тары',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2902,
    label: 'Обработчик поверхностных пороков металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового воздействия',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2903,
    label: 'Обработчик подошв',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2904,
    label: 'Обработчик пробковых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2905,
    label: 'Обработчик прутков сормайта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2906,
    label: 'Обработчик птицы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2907,
    label: 'Обработчик резиновых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2908,
    label: 'Обработчик рогов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2909,
    label: 'Обработчик рыбы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2910,
    label: 'Обработчик сепараторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2911,
    label: 'Обработчик синтетического каучука',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2912,
    label: 'Обработчик соапстока',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2913,
    label: 'Обработчик стеклоблоков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2914,
    label: 'Обработчик сусла и соков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2915,
    label: 'Обработчик твердосплавных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2916,
    label: 'Обработчик технологических емкостей и тары',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2917,
    label: 'Обработчик триплекса и стеклопакетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2918,
    label: 'Обработчик художественных изделий из дерева и папье-маше',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2919,
    label: 'Обработчик шкур',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2920,
    label: 'Обработчик шкур волососгонной смесью',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2921,
    label: 'Обработчик эмалированных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2922,
    label: 'Обрезчик анатомического материала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2923,
    label: 'Обрезчик материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2924,
    label: 'Обрезчик резиновых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2925,
    label: 'Обрубщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2926,
    label: 'Обрубщик ватников',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2927,
    label: 'Обрубщик облоя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2928,
    label: 'Обрубщик сучьев',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Защитные приспособления для защиты от режущего воздействия ручной цепной пилой',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от вредных биологических факторов (клещей и кровососущих насекомых), в том числе в комплекте с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от режущего воздействия ручной цепной пилой',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от режущего воздействия ручной цепной пилой и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2929,
    label: 'Обрывщик волокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2930,
    label: 'Обрядчик сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2931,
    label: 'Обсыпщик кондитерских изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2932,
    label: 'Обувщик по индивидуальному пошиву обуви',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2933,
    label: 'Обувщик по пошиву ортопедической обуви',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2934,
    label: 'Обувщик по ремонту обуви',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2935,
    label: 'Обходчик водопроводно-канализационной сети',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 2936,
    label: 'Обходчик гидросооружений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2937,
    label: 'Обходчик линейный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2938,
    label: 'Обходчик пути и искусственных сооружений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2939,
    label: 'Обходчик трассы гидрозолоудаления и золоотвалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2940,
    label: 'Обшивщик медицинских изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2941,
    label: 'Обшивщик цилиндров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2942,
    label: 'Объездчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2943,
    label: 'Овощевод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2944,
    label: 'Огнеупорщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от кратковременного воздействия открытого пламени, контакта с нагретыми поверхностями, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2945,
    label: 'Огранщик алмазов в бриллианты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2946,
    label: 'Огранщик вставок для ювелирных и художественных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2947,
    label: 'Озонаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2948,
    label: 'Окантовщик киноэкранов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2949,
    label: 'Окантовщик сепараторных пластин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2950,
    label: 'Окантовщик фотоотпечатков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2951,
    label: 'Оклейщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2952,
    label: 'Оклейщик батарей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2953,
    label: 'Оклейщик глобусов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2954,
    label: 'Оклейщик изделий из бересты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2955,
    label: 'Оклейщик органического стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2956,
    label: 'Оконтуровщик оригиналов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2957,
    label: 'Окорщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2958,
    label: 'Окрасчик игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2959,
    label: 'Окрасчик изделий из стеклопластиков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2960,
    label: 'Окрасчик картона и фибры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2961,
    label: 'Окрасчик микросрезов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2962,
    label: 'Окрасчик приборов и деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2963,
    label: 'Окрасчик резиновых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2964,
    label: 'Окрасчик сиропа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2965,
    label: 'Оксидировщик-вакуумщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2966,
    label: 'Оленевод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от биологических факторов (кровососущих насекомых)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2967,
    label: 'Омеднильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2968,
    label: 'Оператор автомата по производству вареных колбас',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2969,
    label: 'Оператор автомата по производству полуфабрикатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2970,
    label: 'Оператор автомата по розливу молочной продукции в пакеты и пленку',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2971,
    label: 'Оператор автоматизированного лесотранспортера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2972,
    label: 'Оператор автоматизированного процесса производства алюминия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2973,
    label: 'Оператор автоматизированной линии теплоизоляционных труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2974,
    label:
      'Оператор автоматических и полуавтоматических линий станков и установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2975,
    label:
      'Оператор автоматических и полуавтоматических линий холодноштамповочного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 2976,
    label: 'Оператор автоматической газовой защиты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания) и от воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2977,
    label: 'Оператор автоматической линии изготовления белого карандаша',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2978,
    label: 'Оператор автоматической линии изготовления ТВЭЛ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2979,
    label: 'Оператор автоматической линии изготовления спичек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2980,
    label:
      'Оператор автоматической линии подготовки и пайки электрорадиоэлементов на печатных платах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2981,
    label: 'Оператор автоматической линии по изготовлению изолированных жил',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2982,
    label: 'Оператор автоматической линии по производству пеностекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2983,
    label: 'Оператор автоматической линии производства молочных продуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2984,
    label: 'Оператор автоматической линии производства сосисок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2985,
    label: 'Оператор автоматической регулирующей станции температуры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2986,
    label: 'Оператор автоматов сборки карандашей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2987,
    label: 'Оператор агрегата обработки отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2988,
    label: 'Оператор агрегатных линий сортировки и переработки бревен',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2989,
    label: 'Оператор акустических испытаний',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2990,
    label: 'Оператор аппаратов микрофильмирования и копирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2991,
    label: 'Оператор бутылоразгрузочного и бутылоукладочного автомата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2992,
    label: 'Оператор вакуумно-напылительных процессов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2993,
    label:
      'Оператор вакуумных установок по нанесению покрытий на оптические детали',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2994,
    label: 'Оператор вакуумприсосных механизмов и приспособлений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2995,
    label:
      'Оператор вальцово-каландровой линии производства поливинилхлоридной пленки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 2996,
    label: 'Оператор взвешивания изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 2997,
    label: 'Оператор вальцовочной линии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 2998,
    label: 'Оператор вибростенда',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 2999,
    label: 'Оператор видеозаписи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3000,
    label: 'Оператор водозапорных сооружений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3001,
    label: 'Оператор водомаслостанции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов, кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3002,
    label: 'Оператор волнировочно-стопирующего агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3003,
    label: 'Оператор ворсовального оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3004,
    label: 'Оператор в производстве заквасок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3005,
    label: 'Оператор в производстве кисломолочных и детских молочных продуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3006,
    label: 'Оператор в производстве раствора казеинатов и казецитов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3007,
    label: 'Оператор выводных устройств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3008,
    label: 'Оператор выдувного полуавтомата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3009,
    label: 'Оператор выпарной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3010,
    label: 'Оператор выращивания дрожжей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3011,
    label: 'Оператор выращивания чистой культуры дрожжей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3012,
    label: 'Оператор высокочастотной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3013,
    label: 'Оператор вязально-прошивного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 3014,
    label: 'Оператор газгольдерной станции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3015,
    label: 'Оператор газораспределительной станции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени и механических воздействий (истирания) и воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3016,
    label: 'Оператор-гальваник на автоматических и полуавтоматических линиях',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3017,
    label: 'Оператор геотехнологических скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3018,
    label: 'Оператор герметизации блочков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3019,
    label: 'Оператор гладильно-сушильного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3020,
    label: 'Оператор горячей камеры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3021,
    label: 'Оператор гранулирования минеральной ваты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3022,
    label: 'Оператор гребнечесального оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3023,
    label: 'Оператор дезинсекционных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3024,
    label: 'Оператор дефектоскопной тележки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3025,
    label: 'Оператор джинного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3026,
    label:
      'Оператор дисперсионных смесителей по приготовлению стержневой массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3027,
    label:
      'Оператор дистанционного пульта управления в водопроводно-канализационном хозяйстве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 3028,
    label:
      'Оператор дистанционного пульта управления в химическом производстве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3029,
    label:
      'Оператор дистанционного управления транспортными средствами внутришахтного транспорта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3030,
    label: 'Оператор диффузионных процессов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3031,
    label: 'Оператор жгутоперемоточной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3032,
    label: 'Оператор животноводческих комплексов и механизированных ферм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3033,
    label: 'Оператор заготовительного отделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3034,
    label: 'Оператор загрузки конвертера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3035,
    label: 'Оператор загрузочной и разгрузочной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3036,
    label: 'Оператор заправочных станций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3037,
    label: 'Оператор зародышеотделительной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3038,
    label: 'Оператор иглопробивного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3039,
    label: 'Оператор иглопробивного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3040,
    label: 'Оператор конвейера обкатки труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3041,
    label: 'Оператор по изготовлению асбостального полотна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3042,
    label: 'Оператор изготовления ровинга',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3043,
    label: 'Оператор изготовления рулонно-конструкционных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3044,
    label: 'Оператор инерционных сепараторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3045,
    label: 'Оператор ионитовой установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3046,
    label: 'Оператор ионообмена',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3047,
    label: 'Оператор исследовательской горячей камеры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3048,
    label: 'Оператор камеры окрашивания акустических плит',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3049,
    label: 'Оператор канатной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3050,
    label: 'Оператор канатовьющих и веревочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3051,
    label: 'Оператор клеевого оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3052,
    label: 'Оператор клепальных автоматов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3053,
    label: 'Оператор ковочно-прессовых агрегатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3054,
    label: 'Оператор коксосортировки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3055,
    label: 'Оператор-комплектовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3056,
    label: 'Оператор конвейера твердения асбестоцементных труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3057,
    label: 'Оператор конвейерной линии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3058,
    label: 'Оператор конвейерной линии оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3059,
    label: 'Оператор конвейерных печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3060,
    label: 'Оператор контактно-стыковой сварки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3061,
    label: 'Оператор копировальных и множительных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3062,
    label: 'Оператор коптильной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3063,
    label: 'Оператор коробконабивочного станка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3064,
    label: 'Оператор котельной',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3065,
    label: 'Оператор красочной станции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3066,
    label: 'Оператор круглочесальной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3067,
    label: 'Оператор крутильного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3068,
    label: 'Оператор кручения и вытяжки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3069,
    label: 'Оператор кручения и намотки химических волокон',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3070,
    label: 'Оператор-кузнец на автоматических и полуавтоматических линиях',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3071,
    label: 'Оператор лазерной голографической установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3072,
    label:
      'Оператор лазерных гравировальных автоматов по изготовлению форм офсетной печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3073,
    label: 'Оператор ленточного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3074,
    label: 'Оператор линии в производстве пищевой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3075,
    label: 'Оператор линии замачивания семян',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3076,
    label: 'Оператор линии окраски изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3077,
    label: 'Оператор линии окраски кирпича',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3078,
    label: 'Оператор линии отделки рельсов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3079,
    label: 'Оператор линии по обработке перопухового сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3080,
    label: 'Оператор линии по обработке цветных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3081,
    label: 'Оператор линии по производству жевательной резинки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3082,
    label: 'Оператор линии по производству муки и гранул',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3083,
    label: 'Оператор линии приготовления фарша',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3084,
    label: 'Оператор линии приготовления шоколадной массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3085,
    label: 'Оператор линии производства маргарина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3086,
    label: 'Оператор линии производства мороженого',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3087,
    label: 'Оператор линии производства мыла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3088,
    label: 'Оператор линии протравливания семян',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3089,
    label: 'Оператор линии розлива молока и молочной продукции в бутылки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3090,
    label: 'Оператор линии фильтрации в пивоваренном производстве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3091,
    label: 'Оператор линтерного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3092,
    label: 'Оператор-литейщик на автоматах и автоматических линиях',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3093,
    label: 'Оператор логоскопа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3094,
    label: 'Оператор льночесальной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3095,
    label: 'Оператор магистральных газопроводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3096,
    label: 'Оператор магнитной записи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3097,
    label: 'Оператор манипулятора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3098,
    label: 'Оператор машинного доения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3099,
    label: 'Оператор машины непрерывного литья заготовок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3100,
    label: 'Оператор механизированной подачи смеси',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3101,
    label: 'Оператор механизированных и автоматизированных складов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3102,
    label: 'Оператор микросварки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3103,
    label: 'Оператор моечной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3104,
    label: 'Оператор моечно-очистительного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3105,
    label: 'Оператор мойно-отжимного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3106,
    label: 'Оператор молокохранилища',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3107,
    label: 'Оператор мотального оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3108,
    label: 'Оператор-моторист станции контроля цемента',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3109,
    label: 'Оператор мяльно-чесальной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3110,
    label:
      'Оператор на автоматических и полуавтоматических линиях в деревообработке',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3111,
    label:
      'Оператор на агрегатах непрерывного обезжиривания, травления, оксидирования и нанесения гальванических покрытий травления, оксидирования и нанесения гальванических покрытий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3112,
    label: 'Оператор на аэротенках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3113,
    label: 'Оператор на биофильтрах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3114,
    label: 'Оператор на бормашине по предварительной обработке литых магнитов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3115,
    label: 'Оператор на вварочных машинах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3116,
    label: 'Оператор на иловых площадках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3117,
    label: 'Оператор-намазчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3118,
    label: 'Оператор намагничивания ферритовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3119,
    label: 'Оператор нормализации деталей из ВМ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3120,
    label: 'Оператор на метантенках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3121,
    label: 'Оператор на отстойниках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3122,
    label: 'Оператор на песколовках и жироловках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3123,
    label: 'Оператор на подогреве мазута',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3124,
    label: 'Оператор наполнения шприц-тюбиков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3125,
    label: 'Оператор-нарезчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3126,
    label: 'Оператор на решетке',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3127,
    label: 'Оператор на филаментмашине',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3128,
    label: 'Оператор на фильтрах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3129,
    label: 'Оператор на эмшерах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3130,
    label: 'Оператор нефтепродукто-перекачивающей станции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3131,
    label: 'Оператор обдирочных станков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средство защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 3132,
    label: 'Оператор обдувочной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3133,
    label: 'Оператор обезвоживающей и обессоливающей установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3134,
    label: 'Оператор обжарочного аппарата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3135,
    label: 'Оператор обрубного отделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3136,
    label: 'Оператор овцеводческих комплексов и механизированных ферм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3137,
    label: 'Оператор окрасочно-сушильной линии и агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3138,
    label: 'Оператор окруточного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3139,
    label: 'Оператор опаливающего оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3140,
    label: 'Оператор осциллографирования и тензометрирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3141,
    label: 'Оператор отстаивания и теплообмена',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3142,
    label: 'Оператор очистного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3143,
    label: 'Оператор очистных сооружений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3144,
    label: 'Оператор пакетоформирующих машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3145,
    label: 'Оператор пароэжекторной установки вакуумирования металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3146,
    label: 'Оператор переборщика-увлажнителя асбестцементных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3147,
    label: 'Оператор перевивочной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3148,
    label: 'Оператор передвижной сейсмической установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3149,
    label: 'Оператор печатного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3150,
    label: 'Оператор плазмохимических процессов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3151,
    label: 'Оператор плетельного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3152,
    label: 'Оператор плетельной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3153,
    label: 'Оператор пневмогидроподачи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3154,
    label: 'Оператор по ветеринарной обработке животных',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3155,
    label: 'Оператор по выращиванию кристаллов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3156,
    label: 'Оператор по выращиванию кристаллов пьезокварца',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3157,
    label: 'Оператор по вытяжке световодов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3158,
    label: 'Оператор по геофизическому опробованию полезного ископаемого',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3159,
    label: 'Оператор по гидравлическому разрыву пластов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3160,
    label: 'Оператор подачи технического углерода',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3161,
    label: 'Оператор по поддержанию пластового давления и химической',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3162,
    label: 'Оператор подземных газогенераторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3163,
    label: 'Оператор по добыче нефти и газа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3164,
    label:
      'Оператор по защитным покрытиям в производстве масок цветных кинескопов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3165,
    label: 'Оператор по изготовлению резиновых смесей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3166,
    label: 'Оператор по искусственному осеменению животных и птицы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3167,
    label: 'Оператор по исследованию скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3168,
    label: 'Оператор полей орошения и фильтрации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3169,
    label: 'Оператор полуавтоматической линии изготовления спичечных коробок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3170,
    label: 'Оператор получения кварцевых стекловолокон',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3171,
    label: 'Оператор получения непрерывного стекловолокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3172,
    label: 'Оператор получения оптического стекловолокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3173,
    label: 'Оператор получения поливинилхлоридных композиций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3174,
    label: 'Оператор получения стекловолокна каолинового состава',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3175,
    label: 'Оператор получения стеклохолста одностадийным методом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3176,
    label: 'Оператор получения штапельного стекловолокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3177,
    label: 'Оператор по намагничиванию и контролю высококоэрцитивных магнитов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3178,
    label: 'Оператор по нанесению газопоглотителя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3179,
    label: 'Оператор по нанесению просветляющих и защитных покрытий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3180,
    label: 'Оператор по наращиванию эпитаксиальных слоев',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3181,
    label: 'Оператор по обслуживанию и ремонту вагонов и контейнеров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3182,
    label: 'Оператор по обслуживанию пылегазоулавливающих установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от воздействия статического электричества, кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3183,
    label: 'Оператор по опробованию (испытанию) скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3184,
    label: 'Оператор по перезарядке транспортных систем',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3185,
    label:
      'Оператор по подготовке скважин к капитальному и подземному ремонтам',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3186,
    label: 'Оператор по поддержанию пластового давления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3187,
    label: 'Оператор по подземному ремонту скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3188,
    label:
      'Оператор по производству отделочных материалов на поливинилхлоридной основе',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3189,
    label: 'Оператор по путевым измерениям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3190,
    label: 'Оператор по сбору газа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3191,
    label: 'Оператор по сбору и очистке конденсата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3192,
    label: 'Оператор поста управления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3193,
    label:
      'Оператор поста управления агрегатами непрерывного травления, обезжиривания, лужения, оцинкования, лакирования и отжига',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3194,
    label: 'Оператор поста управления агрегатами объемной закалки рельсов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3195,
    label: 'Оператор поста управления системы шихтоподачи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3196,
    label: 'Оператор поста управления стана горячего проката труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3197,
    label: 'Оператор поста управления стана горячей прокатки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3198,
    label: 'Оператор поста управления стана холодной прокатки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3199,
    label: 'Оператор поста централизации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3200,
    label: 'Оператор по термообработке корда',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3201,
    label: 'Оператор поточно-автоматизированных линий производства сигарет',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3202,
    label: 'Оператор поточно-автоматической линии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3203,
    label: 'Оператор поточной линии по выработке волокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3204,
    label: 'Оператор поточной линии подготовки основы искусственной кожи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3205,
    label: 'Оператор поточной линии полиэтиленирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3206,
    label: 'Оператор поточных линий нанесения световозвращающих составов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3207,
    label: 'Оператор по химической обработке скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3208,
    label: 'Оператор по цементажу скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3209,
    label: 'Оператор прачечной самообслуживания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3210,
    label: 'Оператор прецизионной резки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3211,
    label: 'Оператор прецизионной фотолитографии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3212,
    label:
      'Оператор при дежурном по станции; оператор при маневровом диспетчере железнодорожной станции;\nоператор по обработке перевозочных документов;\nстарший оператор по обработке перевозочных документов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3213,
    label: 'Оператор приготовления затора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3214,
    label: 'Оператор приготовления растворов питательной среды и солей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3215,
    label: 'Оператор пробоотборной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3216,
    label: 'Оператор проволочного прокатного стана',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3217,
    label: 'Оператор проекционной аппаратуры и газорезательных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3218,
    label: 'Оператор производства древесной массы из щепы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3219,
    label: 'Оператор производства кремнеземных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3220,
    label: 'Оператор производства формованного полиуретана и пенополиуретана',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3221,
    label:
      'Оператор производственного водоснабжения вентиляционных и газовых систем',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от воздействия статического электричества, кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3222,
    label: 'Оператор промывочного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3223,
    label: 'Оператор пропиточного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3224,
    label: 'Оператор профилегибочного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3225,
    label: 'Оператор проходных сушил',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3226,
    label: 'Оператор прядевьющей машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3227,
    label: 'Оператор птицефабрик и механизированных ферм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3228,
    label: 'Оператор пульта технических средств охраны и пожарной сигнализации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3229,
    label: 'Оператор пульта управления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3230,
    label: 'Оператор пульта управления в добыче нефти и газа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3231,
    label: 'Оператор пульта управления в производстве стеновых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3232,
    label: 'Оператор пульта управления в сахарном производстве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3233,
    label:
      'Оператор пульта управления оборудованием в производстве строительных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3234,
    label:
      'Оператор пульта управления оборудованием железобетонного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3235,
    label:
      'Оператор пульта управления оборудованием жилых и общественных зданий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3236,
    label: 'Оператор пульта управления поверхностным шахтным комплексом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3237,
    label: 'Оператор пульта управления приготовлением закладочной смеси',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3238,
    label:
      'Оператор пульта управления трансбордерным и горизонтально-замкнутым конвейерами',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3239,
    label: 'Оператор пульта управления элеватором',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3240,
    label: 'Оператор пульта управления электропечей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3241,
    label: 'Оператор пульта электромагнитной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3242,
    label: 'Оператор пушильного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3243,
    label: 'Оператор радиохимического производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3244,
    label: 'Оператор разменных автоматов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3245,
    label: 'Оператор размоточной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3246,
    label: 'Оператор размоточно-склеивающего станка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3247,
    label: 'Оператор разрыхлительно-трепального агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3248,
    label: 'Оператор разрыхлительно-трепальной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3249,
    label: 'Оператор раскладочной машины (льняное производство)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3250,
    label: 'Оператор раскладочной машины (шелковое производство)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3251,
    label: 'Оператор раскройного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3252,
    label: 'Оператор раскряжевочной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3253,
    label: 'Оператор распределения пряжи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3254,
    label: 'Оператор распределения химических материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3255,
    label: 'Оператор рассолодобычных скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3256,
    label: 'Оператор расфасовочно-упаковочного автомата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3257,
    label: 'Оператор реактора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3258,
    label: 'Оператор расчесывающего оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3259,
    label: 'Оператор реакторного отделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3260,
    label: 'Оператор релаксационно-мотального агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3261,
    label: 'Оператор ровничного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3262,
    label:
      'Оператор роторной линии по производству изделий из пластических масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3263,
    label: 'Оператор рыбокоптильной механизированной линии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3264,
    label: 'Оператор рыхлительно-щипательных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3265,
    label: 'Оператор сверлильного агрегата и пресса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3266,
    label: 'Оператор свиноводческих комплексов и механизированных ферм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3267,
    label: 'Оператор связи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3268,
    label: 'Оператор сейсмопрогноза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3269,
    label: 'Оператор серодобычных и водоотливных скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3270,
    label:
      'Оператор систем гидравлики и охлаждения машины непрерывного литья заготовок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3271,
    label: 'Оператор скороморозильных аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3272,
    label: 'Оператор снаряжения ТВЭЛ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3273,
    label: 'Оператор сновального оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3274,
    label: 'Оператор сновальной пропиточно-вытяжной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3275,
    label: 'Оператор сооружений по удалению осадка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3276,
    label: 'Оператор сортировочной горки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3277,
    label: 'Оператор спецводоочистки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3278,
    label: 'Оператор специального перфоратора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3279,
    label: 'Оператор специальных устройств земснарядов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3280,
    label: 'Оператор спичечных автоматов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3281,
    label: 'Оператор стана по прокатке гипсобетонных панелей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3282,
    label: 'Оператор станка для укладки рассыпных спичек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3283,
    label: 'Оператор станков с программным управлением',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3284,
    label: 'Оператор стеклоформующих машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3285,
    label: 'Оператор стенда по обыгрыванию клавишных инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3286,
    label: 'Оператор стендов и установок радиационной техники',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3287,
    label: 'Оператор стиральных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3288,
    label: 'Оператор стригального оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3289,
    label: 'Оператор сучкорезной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3290,
    label: 'Оператор сушильного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3291,
    label: 'Оператор сушильных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3292,
    label: 'Оператор сушки синтетического каучука',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3293,
    label: 'Оператор теплового пункта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3294,
    label: 'Оператор тепловых сетей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3295,
    label: 'Оператор-термист на автоматических линиях',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3296,
    label: 'Оператор-термист на передвижных термических установках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3297,
    label: 'Оператор термоскрепляющего оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3298,
    label: 'Оператор термосоединений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3299,
    label: 'Оператор тестера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3300,
    label: 'Оператор технологических установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3301,
    label:
      'Оператор технологического оборудования в сооружениях защищенного грунта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3302,
    label: 'Оператор товарный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3303,
    label:
      'Оператор транспортно-технологического оборудования реакторного отделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3304,
    label: 'Оператор тростильного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3305,
    label: 'Оператор трубообжимных станков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3306,
    label: 'Оператор трубчатой печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3307,
    label: 'Оператор трясильной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3308,
    label: 'Оператор турбосмесителя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3309,
    label: 'Оператор тянульной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3310,
    label: 'Оператор узла посыпки и охлаждения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3311,
    label: 'Оператор узловязальной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3312,
    label: 'Оператор укладчика-разборщика асбестоцементных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3313,
    label: 'Оператор ультразвуковых установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3314,
    label: 'Оператор ускорительной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3315,
    label: 'Оператор установки бестарного хранения сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3316,
    label: 'Оператор установки витаминизации дрожжей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3317,
    label: 'Оператор установки волокнообразования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3318,
    label: 'Оператор установки вымерения объема',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3319,
    label:
      'Оператор установки изготовления гофрированных листовых стеклопластиков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3320,
    label: 'Оператор установки минераловатных цилиндров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3321,
    label: 'Оператор установки по сушке осадка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3322,
    label: 'Оператор установок по тепловой обработке бетона',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3323,
    label: 'Оператор установки ТВЧ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания) кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3324,
    label: 'Оператор установки теплоизоляционного шнура',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3325,
    label: 'Оператор установок изготовления сотовых пакетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3326,
    label: 'Оператор установок изготовления стеклопластиковых конструкций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3327,
    label: 'Оператор установок и линий обработки пиломатериалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3328,
    label: 'Оператор установок пескоструйной очистки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3329,
    label: 'Оператор установок по нанесению покрытий в вакууме',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3330,
    label: 'Оператор установок по обезвоживанию осадка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3331,
    label: 'Оператор установок синтеза алмазов и сверхтвердых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3332,
    label: 'Оператор установок спецпоезда',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3333,
    label: 'Оператор фацетного станка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3334,
    label: 'Оператор формования ленты стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3335,
    label: 'Оператор формирующей машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3336,
    label: 'Оператор фотоавтоматов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3337,
    label: 'Оператор фотонаборных автоматов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3338,
    label: 'Оператор хлораторной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3339,
    label: 'Оператор холстовытяжной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3340,
    label: 'Оператор централизованной мойки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3341,
    label:
      'Оператор центрального пульта управления в производстве древесных и костровых плит',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3342,
    label: 'Оператор центрального щита управления сублиматного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3343,
    label: 'Оператор цехов по приготовлению кормов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3344,
    label: 'Оператор чаеперерабатывающей линии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3345,
    label: 'Оператор чесально-вязального оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3346,
    label: 'Оператор чесального оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3347,
    label: 'Оператор чесально-дублировочного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3348,
    label: 'Оператор чесально-ленточного агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3349,
    label: 'Оператор чистильной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3350,
    label: 'Оператор швейного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3351,
    label: 'Оператор ширильного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3352,
    label: 'Оператор шлихтовального оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3353,
    label: 'Оператор щита КИПиА',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3354,
    label: 'Оператор щита парогенераторной',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3355,
    label: 'Оператор щита технологического контроля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3356,
    label: 'Оператор щита (пульта) управления преобразовательной подстанции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3357,
    label: 'Оператор экспериментальных стендов и установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3358,
    label: 'Оператор электроакустических измерений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3359,
    label: 'Оператор электрогидравлической очистки отливок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3360,
    label: 'Оператор электронного набора и верстки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3361,
    label: 'Оператор электронных автоматов по изготовлению форм высокой печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3362,
    label: 'Оператор электронного цветоделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3363,
    label:
      'Оператор электронных гравировальных автоматов по изготовлению форм глубокой печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3364,
    label: 'Оператор электростатической обработки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3365,
    label: 'Оператор электрохимической очистки заготовок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3366,
    label: 'Оператор элионных процессов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3367,
    label: 'Оператор этикетировочного станка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3368,
    label: 'Опиловщик камня',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3369,
    label: 'Опиловщик папье-маше',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3370,
    label: 'Опиловщик фасонных отливок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3371,
    label: 'Опиловщик фибры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3372,
    label: 'Опиловщик черни',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3373,
    label: 'Оплавщик изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3374,
    label: 'Оплавщик стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3375,
    label: 'Оплавщик полотна и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3376,
    label: 'Оплетчик деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3377,
    label: 'Оплетчик проводов и кабелей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3378,
    label: 'Оплетчик стекложгутов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3379,
    label: 'Оправщик огнеупорных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3380,
    label: 'Оправщик-чистильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3381,
    label: 'Оправщик электрокерамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3382,
    label: 'Опрессовщик кабелей и проводов пластикатами и резиной',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3383,
    label: 'Опрессовщик кабелей свинцом или алюминием',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3384,
    label: 'Опрессовщик труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3385,
    label: 'Опрокидчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов) и от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ, от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 3386,
    label: 'Оптик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3387,
    label: 'Оптик медицинский',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3388,
    label: 'Оптик-механик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3389,
    label: 'Оптик элементов квантовых приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3390,
    label: 'Опылитель форм и металла серным порошком',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3391,
    label: 'Осветитель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3392,
    label: 'Освинцевальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3393,
    label: 'Осмольщик бочек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3394,
    label: 'Осмотрщик вагонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3395,
    label: 'Осмотрщик гидротехнических сооружений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3396,
    label: 'Осмотрщик нефтеналивных емкостей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания неавтономные с подачей чистого воздуха по шлангу/магистрали (дыхательные аппараты)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3397,
    label: 'Осмотрщик-ремонтник вагонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 36 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3398,
    label: 'Остекловщик резисторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3399,
    label: 'Отбельщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3400,
    label: 'Отбельщик агарового студня',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3401,
    label: 'Отбельщик коагулята',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3402,
    label: 'Отбивщик ртути',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3403,
    label: 'Отборщик анатомического материала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3404,
    label: 'Отборщик геологических проб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3405,
    label: 'Отварщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3406,
    label: 'Отварщик коконных отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3407,
    label: 'Отводчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3408,
    label: 'Отгазовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от воздействия статического электричества, кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3409,
    label: 'Отделочник валяльно-войлочных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3410,
    label: 'Отделочник волокна (льняное производство)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3411,
    label: 'Отделочник волокна (производство текстильной галантереи)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3412,
    label: 'Отделочник ворса',
    SIZ: [
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3413,
    label: 'Отделочник головных уборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3414,
    label: 'Отделочник деталей игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3415,
    label: 'Отделочник железобетонных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3416,
    label: 'Отделочник изделий из древесины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3417,
    label:
      'Отделочник изделий (общие профессии производств легкой промышленности)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3418,
    label: 'Отделочник изделий (шорно-седельное производство)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3419,
    label: 'Отделочник изделий, полуфабрикатов, материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3420,
    label: 'Отделочник искусственного каракуля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3421,
    label: 'Отделочник карандашей на автомате',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3422,
    label: 'Отделочник кинофотоматериалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3423,
    label: 'Отделочник литых бумажных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3424,
    label: 'Отделочник материалов и готовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3425,
    label: 'Отделочник меховых шкурок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3426,
    label: 'Отделочник резиновых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3427,
    label: 'Отделочник ткани',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3428,
    label: 'Отделочник химических волокон',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3429,
    label: 'Отделочник художественных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3430,
    label: 'Отделочник щетиноволосяных материалов и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3431,
    label: 'Отделывальщик клише',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3432,
    label: 'Отделывальщик фотолитер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3433,
    label: 'Отделывальщик шрифтовой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3434,
    label: 'Отдельщик выдувных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3435,
    label: 'Отжигальщик-вакуумщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3436,
    label: 'Отжигальщик изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3437,
    label: 'Отжигальщик кабельных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3438,
    label: 'Отжигальщик прецизионной стали и сплавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3439,
    label: 'Отжигальщик стеклоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3440,
    label: 'Отжигальщик цветных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от выплесков расплавленного металла, механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3441,
    label: 'Отжимщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3442,
    label: 'Отжимщик белья на центрифугах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3443,
    label: 'Отжимщик воздуха и влаги из камер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3444,
    label: 'Отжимщик массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3445,
    label: 'Отжимщик холстов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3446,
    label: 'Откачник-вакуумщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3447,
    label: 'Откладчик изделий в опечки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3448,
    label: 'Отливщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3449,
    label: 'Отливщик валиков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3450,
    label: 'Отливщик литых бумажных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3451,
    label: 'Отливщик натриевых болванок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3452,
    label: 'Отливщик фарфоровых и фаянсовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3453,
    label: 'Отломщик стекла от машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3454,
    label: 'Отметчик ареометров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3455,
    label: 'Отметчик термометров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3456,
    label: 'Отопщик на карусельной машине',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3457,
    label: 'Отпарщик-прессовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3458,
    label: 'Отрезчик ленты стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3459,
    label: 'Оформитель готовой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3460,
    label: 'Оформитель диапозитивных фильмов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3461,
    label: 'Оформитель игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3462,
    label: 'Оформитель коллекций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3463,
    label: 'Оформитель табло, виньеток и альбомов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3464,
    label: 'Оформитель технической документации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3465,
    label: 'Охлаждальщик ткани',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3466,
    label: 'Охотник промысловый',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от биологических факторов (кровососущих насекомых)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3467,
    label: 'Оцинковщик горячим способом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от защиты от искр и брызг расплавленного металла, металлической окалины, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью, искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3468,
    label: 'Оцинковщик-хромировщик диффузионным способом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3469,
    label: 'Очесывальщик барабанов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3470,
    label: 'Пантографист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3471,
    label: 'Парафинировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3472,
    label: 'Парафинировщик изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3473,
    label: 'Парафинировщик керамических и фарфоровых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3474,
    label: 'Парафинировщик художественных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3475,
    label: 'Парашютист (десантник)-пожарный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3476,
    label: 'Паркетчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3477,
    label: 'Парусник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3478,
    label: 'Пастижер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3479,
    label: 'Патинировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3480,
    label: 'Патронист рисунков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3481,
    label: 'Паяльщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3482,
    label: 'Паяльщик пакетов конденсаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3483,
    label: 'Паяльщик по винипласту',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3484,
    label: 'Паяльщик по свинцу (свинцовопаяльщик)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3485,
    label: 'Паяльщик радиодеталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3486,
    label: 'Паяльщик сеток и шинок на стекле',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3487,
    label: 'Паяльщик труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3488,
    label: 'Пекарь',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3489,
    label: 'Пекарь комплексно-механизированной линии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3490,
    label: 'Пекарь-мастер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3491,
    label: 'Пекоплавщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3492,
    label: 'Переводчик обойных рисунков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3493,
    label: 'Переводчик печати и рисунка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3494,
    label: 'Переводчик рисунков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3495,
    label: 'Переводчик форм глубокой печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3496,
    label: 'Перегонщик печей и трансбордерных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3497,
    label: 'Перезарядчик контактных аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3498,
    label: 'Перезарядчик сборочных станков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3499,
    label: 'Перезарядчик установки декристаллизации каучука',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3500,
    label: 'Перезарядчик фильтр-прессов и диализаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3501,
    label: 'Перекатчик ткани и прокладки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3502,
    label: 'Перекристаллизаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3503,
    label: 'Перемотчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3504,
    label: 'Перемотчик бумаги и бумажной пряжи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3505,
    label: 'Перемотчик ленты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3506,
    label: 'Перемотчик нити',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3507,
    label: 'Перемотчик основ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3508,
    label: 'Перемотчик рулонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3509,
    label: 'Перемотчик-сортировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3510,
    label: 'Перемотчик стеклоткани',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3511,
    label: 'Перемотчик электроизоляционных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3512,
    label: 'Переплетчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3513,
    label: 'Переплетчик документов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3514,
    label: 'Переработчик радиоактивных отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм изолирующий для защиты кожи и органов дыхания от радиоактивных веществ',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания неавтономные с подачей чистого воздуха по шлангу/магистрали (дыхательные аппараты)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3515,
    label: 'Пересадчик сетей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3516,
    label: 'Перетяжчик перчаточной кожи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3517,
    label: 'Перфораторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3518,
    label: 'Перфораторщик магнитных лент',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3519,
    label: 'Перфораторщик пленок из пластических масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3520,
    label: 'Перфораторщик фольги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3521,
    label: 'Пескослепщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3522,
    label: 'Пескоструйщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с принудительной подачей воздуха',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3523,
    label: 'Пескоструйщик по стеклу',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3524,
    label: 'Печатник брайлевской печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3525,
    label: 'Печатник высокой печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3526,
    label: 'Печатник глубокой печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3527,
    label: 'Печатник диаграммной продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3528,
    label: 'Печатник металлографских оттисков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3529,
    label: 'Печатник металлографской печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3530,
    label: 'Печатник миллиметровки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3531,
    label: 'Печатник на линолеуме',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3532,
    label: 'Печатник орловской печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3533,
    label: 'Печатник печатно-высекального автомата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3534,
    label: 'Печатник плоской печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3535,
    label: 'Печатник по жести',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3536,
    label: 'Печатник субтитрования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3537,
    label: 'Печатник-тиснильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3538,
    label: 'Печатник трафаретной печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3539,
    label: 'Печатник флексографской печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3540,
    label: 'Печатник циферблатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3541,
    label: 'Печатник эстампа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3542,
    label: 'Печевой восстановления железа и отжига железных порошков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени, искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3543,
    label: 'Печевой в производстве цинковой пыли',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3544,
    label: 'Печевой иодиодного рафинирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3545,
    label: 'Печевой на вельцпечах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени, искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3546,
    label: 'Печевой на восстановлении и дистилляции титана и редких металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени, искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3547,
    label: 'Печевой на дистилляции металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения, кратковременного воздействия открытого пламени и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3548,
    label: 'Печевой на получении цинкового купороса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3549,
    label: 'Печевой по восстановлению никелевого порошка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3550,
    label: 'Печевой по восстановлению термическим способом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3551,
    label: 'Печевой по переработке отходов и оборотов производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3552,
    label:
      'Печевой по переработке титаносодержащих и редкоземельных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени, искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения, контакта с нагретой поверхностью и выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3553,
    label: 'Печевой по производству трехокиси сурьмы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3554,
    label: 'Печник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3555,
    label: 'Пилоправ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3556,
    label: 'Пилот',
    SIZ: [
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3557,
    label: 'Пилот-инструктор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 3558,
    label: 'Пильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 3559,
    label: 'Пирометрист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3560,
    label: 'Пиротехник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3561,
    label: 'Плавильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от кратковременного воздействия открытого пламени',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3562,
    label: 'Плавильщик абразивных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3563,
    label: 'Плавильщик бариевого электролита',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3564,
    label: 'Плавильщик вторичного олова',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3565,
    label: 'Плавильщик изделий из кварцевого непрозрачного стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3566,
    label: 'Плавильщик-литейщик прецизионных сплавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3567,
    label: 'Плавильщик металла и сплавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3568,
    label: 'Плавильщик металла на вакуумных печах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3569,
    label: 'Плавильщик нафталина и фенолов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3570,
    label: 'Плавильщик обезвоженного кварцевого стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3571,
    label: 'Плавильщик огнеупорного сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3572,
    label: 'Плавильщик пищевого жира',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3573,
    label: 'Плавильщик раскислителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3574,
    label: 'Плавильщик свинцовых сплавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3575,
    label: 'Плавильщик синтетических шлаков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения, искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3576,
    label: 'Плавильщик стекловолокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3577,
    label: 'Плавильщик ферросплавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3578,
    label: 'Плавильщик циклонной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3579,
    label: 'Плавильщик шоопсплава и висмута',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3580,
    label: 'Плавильщик электронно-лучевой плавки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от конвективной теплоты и теплового излучения',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3581,
    label: 'Плавильщик эмали',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3582,
    label:
      'Плакировщик алмазных порошков, кристаллов и сверхтвердых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3583,
    label: 'Плакировщик изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3584,
    label: 'Плакировщик полимерных материалов на металл',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3585,
    label: 'Планиметрист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3586,
    label: 'Пластикаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3587,
    label: 'Плетельщик мебели',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3588,
    label: 'Плиссировщик-гофрировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3589,
    label: 'Плодоовощевод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3590,
    label: 'Пломбировщик вагонов и контейнеров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3591,
    label: 'Плотник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (порезов, проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3592,
    label: 'Плотник судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (порезов, проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3593,
    label: 'Повар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3594,
    label: 'Повар детского питания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3595,
    label: 'Повар судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3596,
    label: 'Повертальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3597,
    label: 'Поверяльщик веретен',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3598,
    label: 'Поверяльщик разводок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3599,
    label: 'Подборщик авровых основ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3600,
    label: 'Подборщик деталей и изделий на поток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3601,
    label: 'Подборщик камней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3602,
    label: 'Подборщик пресс-материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3603,
    label: 'Подборщик расцветок искусственных зубов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3604,
    label: 'Подборщик ставок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3605,
    label: 'Подборщик стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3606,
    label: 'Подборщик-учетчик абразивных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3607,
    label: 'Подгонщик катушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3608,
    label: 'Подгонщик шунтов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3609,
    label: 'Подготовитель бассейнов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3610,
    label: 'Подготовитель белья для глажения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3611,
    label: 'Подготовитель пищевого сырья и материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3612,
    label: 'Подготовитель прокатного инструмента',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3613,
    label: 'Подготовитель составов к разливке плавок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени, искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения, контакта с нагретой поверхностью, искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3614,
    label: 'Подготовитель сталеразливочных канав',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени, искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения, контакта с нагретой поверхностью, искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3615,
    label: 'Подготовщик изделий к спеканию',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3616,
    label: 'Подготовщик исходного материала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3617,
    label: 'Подготовщик камер и рукавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3618,
    label: 'Подготовщик набивочных и настилочных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3619,
    label: 'Подготовщик основы для мультипликационных рисунков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3620,
    label: 'Подготовщик паковок и целлюлозы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3621,
    label: 'Подготовщик сетематериалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3622,
    label: 'Подготовщик сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3623,
    label: 'Подготовщик тары',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3624,
    label: 'Поддувщик изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3625,
    label: 'Подинщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3626,
    label: 'Подклейщик стекловолокнистых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3627,
    label:
      'Подносчик сырья, полуфабрикатов, химических материалов и отходов производства в отмочнозольных, дубильно-красильных и жировальных цехах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3628,
    label:
      'Подносчик сырья, полуфабрикатов, химических материалов и отходов производства в сырейных, дубильно-красильных и формалиновых цехах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3629,
    label: 'Подручный вальцовщика стана горячего проката труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3630,
    label: 'Подручный вальцовщика стана горячей прокатки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр, брызг расплавленного металла, металлической окалины, контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени и конвективной теплоты',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3631,
    label: 'Подручный вальцовщика стана холодного проката труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3632,
    label: 'Подручный вальцовщика стана холодной прокатки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3633,
    label: 'Подручный сталевара вакуумной печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3634,
    label: 'Подручный сталевара конвертера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени, контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения, кратковременного воздействия открытого пламени, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3635,
    label: 'Подручный сталевара мартеновской печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени, выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени, контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения, кратковременного воздействия открытого пламени, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3636,
    label: 'Подручный сталевара печи прямого восстановления железа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3637,
    label: 'Подручный сталевара установки внепечной обработки стали',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3638,
    label: 'Подручный сталевара установки электрошлакового переплава',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3639,
    label: 'Подручный сталевара электропечи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения, кратковременного воздействия открытого пламени и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени, контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения, кратковременного воздействия открытого пламени, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3640,
    label: 'Подсобный рабочий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3641,
    label: 'Подсобный рабочий на лесозаготовках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3642,
    label: 'Подсобный рабочий на лесосплаве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3643,
    label: 'Подсобный рабочий на подсочке леса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3644,
    label: 'Подшкипер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3645,
    label: 'Пожарный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3646,
    label: 'Поездной электромеханик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3647,
    label: 'Позолотчик художественных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3648,
    label: 'Полевой (путевой) рабочий изыскательской русловой партии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3649,
    label: 'Поливщик-лакировщик фотоматериалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3650,
    label: 'Поливщик магнитных дорожек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3651,
    label: 'Поливщик светофильтров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3652,
    label: 'Полимеризаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3653,
    label: 'Полимеризаторщик металлических форм и листов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3654,
    label: 'Полировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3655,
    label: 'Полировщик водородным пламенем',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3656,
    label: 'Полировщик волок из алмазов и сверхтвердых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3657,
    label: 'Полировщик игл',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3658,
    label: 'Полировщик изделий из бумаги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3659,
    label: 'Полировщик листов и лент',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3660,
    label: 'Полировщик лопаток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3661,
    label: 'Полировщик музыкальных инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3662,
    label: 'Полировщик оптических деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3663,
    label: 'Полировщик стекла и стеклоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3664,
    label: 'Полировщик стеклоизделий кислотой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3665,
    label: 'Полировщик технических камней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3666,
    label: 'Полировщик формных цилиндров глубокой печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3667,
    label: 'Полировщик хирургических инструментов и аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3668,
    label: 'Полировщик художественных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3669,
    label: 'Полировщик шрота',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3670,
    label: 'Полотер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3671,
    label: 'Поляризатор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3672,
    label: 'Помощник бурильщика капитального ремонта скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов, кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от нефти и/или нефтепродуктов',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 3673,
    label: 'Помощник бурильщика плавучего бурильного агрегата в море',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов, кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от нефти и/или нефтепродуктов',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 3674,
    label:
      'Помощник бурильщика эксплуатационного и разведочного бурения скважин на нефть и газ (второй)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов, кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от нефти и/или нефтепродуктов',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 3675,
    label:
      'Помощник бурильщика эксплуатационного и разведочного бурения скважин на нефть и газ (первый)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов, кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от нефти и/или нефтепродуктов',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 3676,
    label:
      'Помощник бурильщика эксплуатационного и разведочного бурения скважин при электробурении',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов, кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от нефти и/или нефтепродуктов',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 3677,
    label: 'Помощник воспитателя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3678,
    label: 'Помощник врача-эпидемиолога',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Одежда специальная для ограниченной защиты от токсичных веществ',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3679,
    label: 'Помощник мастера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3680,
    label: 'Помощник машиниста автомотрисы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 3681,
    label: 'Помощник машиниста дизельпоезда',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3682,
    label: 'Помощник машиниста (обжигальщика) вращающихся печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3683,
    label: 'Помощник машиниста (обжигальщика) шахтных печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3684,
    label: 'Помощник машиниста паровоза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3685,
    label: 'Помощник машиниста реактивной установки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3686,
    label: 'Помощник машиниста сырьевых мельниц',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3687,
    label: 'Помощник машиниста тепловоза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3688,
    label: 'Помощник машиниста тягового агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3689,
    label: 'Помощник машиниста угольных мельниц',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3690,
    label: 'Помощник машиниста цементных мельниц',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3691,
    label: 'Помощник машиниста электровоза',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3692,
    label: 'Помощник машиниста электропоезда',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3693,
    label: 'Помощник механика',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3694,
    label: 'Помощник шкипера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3695,
    label: 'Портной',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 3696,
    label: 'Портной по пошиву мягкой тары',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 3697,
    label: 'Порционист лао-ча',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3698,
    label: 'Посадчик металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3699,
    label: 'Постановщик-выгрузчик абразивных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3700,
    label: 'Постовой (разъездной) рабочий судоходной обстановки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3701,
    label: 'Посыпщик слюдой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3702,
    label: 'Починщик шпона и фанеры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3703,
    label: 'Почтальон',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3704,
    label: 'Пошивщик изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3705,
    label: 'Пошивщик кожгалантерейных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3706,
    label: 'Пошивщик шорно-седельных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3707,
    label: 'Правильщик вручную',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3708,
    label: 'Правильщик на машинах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3709,
    label: 'Правильщик при стеклоформующей и отопочной машине',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3710,
    label: 'Правильщик проката и труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от конвективной теплоты и теплового излучения',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3711,
    label: 'Правильщик роговых пластин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3712,
    label: 'Правщик абразивных кругов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3713,
    label: 'Правщик меховых шкурок и скроев изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3714,
    label: 'Правщик проволоки и плетенки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3715,
    label: 'Правщик технологической оснастки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3716,
    label: 'Препаратор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3717,
    label: 'Препаратор биологических объектов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3718,
    label: 'Препаратор ветеринарный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3719,
    label: 'Препаратор по анатомии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3720,
    label: 'Препаратор по микрозоологии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3721,
    label: 'Препаратор производства биосинтетических лечебных средств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3722,
    label: 'Препаратор производства стекловидного тела',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3723,
    label: 'Препаратор скелетов мелких животных',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3724,
    label: 'Препаратор срезов по анатомии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3725,
    label: 'Препараторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3726,
    label: 'Прессовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3727,
    label: 'Прессовщик агломератов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3728,
    label: 'Прессовщик асбестоцементных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3729,
    label: 'Прессовщик асфальтовых плиток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3730,
    label: 'Прессовщик блоков целлулоида',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3731,
    label: 'Прессовщик брикетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3732,
    label: 'Прессовщик бумагоделательной (картоно-делательной) машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3733,
    label: 'Прессовщик валяльно-войлочных изделий и шкурок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3734,
    label: 'Прессовщик ВМ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3735,
    label: 'Прессовщик волокна (льняное производство)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3736,
    label: 'Прессовщик волокна (пенькоджутовое производство)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3737,
    label: 'Прессовщик-вулканизаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3738,
    label: 'Прессовщик-выдувщик целлулоидных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3739,
    label: 'Прессовщик горячего стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3740,
    label: 'Прессовщик горячего формования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3741,
    label: 'Прессовщик горячих труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени, искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3742,
    label: 'Прессовщик готовой продукции и отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3743,
    label: 'Прессовщик деталей для игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3744,
    label: 'Прессовщик древесных и костровых плит',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3745,
    label: 'Прессовщик заготовок для шпальтовых сит',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3746,
    label: 'Прессовщик изделий из древесины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3747,
    label: 'Прессовщик изделий из металлических порошков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3748,
    label: 'Прессовщик изделий из оптического стекла и кристаллов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3749,
    label: 'Прессовщик изделий из пластмасс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3750,
    label: 'Прессовщик изделий из рогового порошка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3751,
    label: 'Прессовщик изделий из стеклопорошка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3752,
    label: 'Прессовщик изделий строительной керамики',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3753,
    label: 'Прессовщик изделий электронной техники',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3754,
    label: 'Прессовщик изоляционных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3755,
    label:
      'Прессовщик инструментов из алмазных порошков и сверхтвердых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3756,
    label: 'Прессовщик карандашных блоков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3757,
    label: 'Прессовщик картонажных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3758,
    label: 'Прессовщик картона и фибры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3759,
    label: 'Прессовщик керамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3760,
    label: 'Прессовщик кирпичного чая',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3761,
    label: 'Прессовщик клеильно-сушильной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3762,
    label: 'Прессовщик кож',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3763,
    label: 'Прессовщик колес и бандажей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, контакта, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3764,
    label: 'Прессовщик колесных пар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3765,
    label: 'Прессовщик коллагенового жгута',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3766,
    label: 'Прессовщик коры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3767,
    label: 'Прессовщик листовых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3768,
    label: 'Прессовщик литых бумажных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3769,
    label: 'Прессовщик лома и отходов металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3770,
    label: 'Прессовщик махорочной пыли',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3771,
    label: 'Прессовщик миканита и микалекса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3772,
    label: 'Прессовщик молетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3773,
    label: 'Прессовщик на гидропрессах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3774,
    label: 'Прессовщик на горячей штамповке',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3775,
    label: 'Прессовщик на испытании труб и баллонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3776,
    label: 'Прессовщик на печах горячего прессования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 3777,
    label: 'Прессовщик нафталина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3778,
    label: 'Прессовщик обмазочного пресса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3779,
    label: 'Прессовщик огнеупорных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3780,
    label: 'Прессовщик оптической керамики',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3781,
    label: 'Прессовщик-освинцовщик рукавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3782,
    label: 'Прессовщик отжимной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3783,
    label: 'Прессовщик-отжимщик пищевой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3784,
    label: 'Прессовщик отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3785,
    label: 'Прессовщик пергаментной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3786,
    label: 'Прессовщик перевязочных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3787,
    label: 'Прессовщик пленочных материалов прессрулонным методом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3788,
    label: 'Прессовщик плит из тростника',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3789,
    label: 'Прессовщик плиточного чая',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3790,
    label: 'Прессовщик полуфабриката макаронных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3791,
    label: 'Прессовщик порошковых смесей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3792,
    label: 'Прессовщик пресспата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3793,
    label: 'Прессовщик-прошивщик рельсовых скреплений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3794,
    label: 'Прессовщик растительного войлока',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3795,
    label: 'Прессовщик ровничной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3796,
    label: 'Прессовщик рыбной муки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3797,
    label: 'Прессовщик рядна из-под табака',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3798,
    label:
      'Прессовщик секций, катушек и изоляционных деталей электрических машин и аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3799,
    label: 'Прессовщик слюдопластов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3800,
    label: 'Прессовщик стальных профилей на установке гидроэкструзии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3801,
    label: 'Прессовщик стеклопакетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3802,
    label: 'Прессовщик стекол',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3803,
    label: 'Прессовщик стеновых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3804,
    label: 'Прессовщик стержней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3805,
    label: 'Прессовщик сыра',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3806,
    label: 'Прессовщик сырья и волокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3807,
    label: 'Прессовщик твердых сплавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3808,
    label: 'Прессовщик теплоизоляционных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3809,
    label: 'Прессовщик ткани',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3810,
    label: 'Прессовщик торфоплит',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3811,
    label: 'Прессовщик труб и профилей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3812,
    label: 'Прессовщик туб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3813,
    label: 'Прессовщик фарфоровых труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3814,
    label: 'Прессовщик ферритовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3815,
    label: 'Прессовщик-формовщик пищевой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3816,
    label: 'Прессовщик химического волокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3817,
    label: 'Прессовщик хлопковой целлюлозы и отходов целлулоида',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3818,
    label: 'Прессовщик электродной продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3819,
    label: 'Прессовщик электродов и элементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3820,
    label: 'Прессовщик электрокерамических изделий в резиновых формах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3821,
    label: 'Прессовщик электрокерамических изделий из пластических масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3822,
    label: 'Прессовщик электрокерамических изделий из порошковых масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3823,
    label: 'Прессовщик электротехнических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3824,
    label: 'Прессовщик электроугольных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3825,
    label: 'Приборист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3826,
    label: 'Приборист газодинамической лаборатории',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3827,
    label: 'Привязывальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3828,
    label: 'Приготовитель абразивных порошков, паст и мастик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3829,
    label: 'Приготовитель активных масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3830,
    label: 'Приготовитель ангоба и глазури',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3831,
    label: 'Приготовитель белковых масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3832,
    label: 'Приготовитель бурового раствора',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3833,
    label: 'Приготовитель водорослевого порошка и крупки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3834,
    label: 'Приготовитель волокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3835,
    label: 'Приготовитель грунтовых составов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3836,
    label: 'Приготовитель дражировочной массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3837,
    label:
      'Приготовитель заправочных, огнеупорных материалов и термических смесей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3838,
    label: 'Приготовитель композиционных блоков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3839,
    label: 'Приготовитель кормов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3840,
    label: 'Приготовитель крахмального молока',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3841,
    label: 'Приготовитель крупки органического стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3842,
    label: 'Приготовитель кулинарных изделий из мяса птицы и кроликов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3843,
    label: 'Приготовитель лаков, красок и левкаса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3844,
    label: 'Приготовитель масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3845,
    label: 'Приготовитель мелассного сусла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3846,
    label: 'Приготовитель молочных коктейлей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3847,
    label: 'Приготовитель морса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3848,
    label: 'Приготовитель напитков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3849,
    label: 'Приготовитель нюхательной махорки и табака',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3850,
    label: 'Приготовитель оптических клеев',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3851,
    label: 'Приготовитель паст и пленок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3852,
    label: 'Приготовитель питательных растворов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3853,
    label: 'Приготовитель пробковой крупы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3854,
    label: 'Приготовитель пропиточного состава',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3855,
    label: 'Приготовитель пульпы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3856,
    label: 'Приготовитель разделительной пасты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3857,
    label: 'Приготовитель растворов и масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3858,
    label: 'Приготовитель растворов и смесей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3859,
    label: 'Приготовитель растворов и электролитов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3860,
    label: 'Приготовитель растворов красителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3861,
    label: 'Приготовитель реактивной воды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Фильтрующий самоспасатель',
        norm: 'одноразовое применение',
      },
    ],
  },
  {
    profId: 3862,
    label: 'Приготовитель склеивающего состава',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3863,
    label: 'Приготовитель смесей и масс медицинского назначения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3864,
    label: 'Приготовитель спичечных масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Фильтрующий самоспасатель',
        norm: 'одноразовое применение',
      },
    ],
  },
  {
    profId: 3865,
    label: 'Приготовитель стиральных растворов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3866,
    label: 'Приготовитель сухих пивных дрожжей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3867,
    label: 'Приготовитель технических жиров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3868,
    label: 'Приготовитель тресты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3869,
    label: 'Приготовитель уплотняющих растворов и паст',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3870,
    label: 'Приготовитель шампанского',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3871,
    label: 'Приготовитель шихты полупроводниковых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3872,
    label: 'Приготовитель электролита и флюса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3873,
    label: 'Приготовитель электропроводного слоя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3874,
    label: 'Приготовитель эмалевых порошков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3875,
    label: 'Приготовитель эмульсий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3876,
    label: 'Приемосдатчик груза и багажа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 36 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3877,
    label: 'Приемосдатчик груза и багажа в поездах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3878,
    label: 'Приемщик баллонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3879,
    label: 'Приемщик биологических материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3880,
    label: 'Приемщик драгоценных металлов и сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3881,
    label: 'Приемщик заказов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3882,
    label: 'Приемщик золота стоматологических учреждений (подразделений)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3883,
    label: 'Приемщик материалов, полуфабрикатов и готовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3884,
    label: 'Приемщик материалов, полуфабрикатов и готовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3885,
    label: 'Приемщик молочной продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3886,
    label: 'Приемщик на машинах и агрегатах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3887,
    label: 'Приемщик-отправитель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3888,
    label: 'Приемщик перопухового сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3889,
    label: 'Приемщик плавсредств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3890,
    label: 'Приемщик поездов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 36 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3891,
    label: 'Приемщик покрышек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3892,
    label: 'Приемщик пункта проката',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3893,
    label: 'Приемщик-раздатчик золотосодержащих препаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3894,
    label: 'Приемщик руды и асбеста',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3895,
    label: 'Приемщик-сдатчик пищевой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3896,
    label: 'Приемщик сельскохозяйственных продуктов и сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3897,
    label: 'Приемщик скота',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3898,
    label: 'Приемщик солодкового корня',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3899,
    label: 'Приемщик-сортировщик живой птицы и кроликов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3900,
    label: 'Приемщик сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3901,
    label: 'Приемщик сырья для клея',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3902,
    label: 'Приемщик сырья, полуфабрикатов и готовой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3903,
    label: 'Приемщик товаров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3904,
    label: 'Приемщик трамваев и троллейбусов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3905,
    label: 'Приемщик яиц',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3906,
    label: 'Прикатчик напыленных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3907,
    label: 'Присучальщик основ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3908,
    label: 'Притирщик стеклоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3909,
    label: 'Пробивальщик-продувальщик труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3910,
    label: 'Пробист высокой печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3911,
    label: 'Пробист плоской печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3912,
    label: 'Пробоотборщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3913,
    label: 'Проборщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3914,
    label: 'Пробуторщик малолитражной драги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3915,
    label: 'Проверщик судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3916,
    label: 'Проводник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3917,
    label: 'Проводник (вожатый) служебных собак',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3918,
    label: 'Проводник на геологических поисках и съемке',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), вредных биологических факторов (клещей и кровососущих насекомых), в том числе в комплекте с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), вредных биологических факторов (клещей и кровососущих насекомых)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3919,
    label: 'Проводник пассажирского вагона',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Фильтрующий самоспасатель',
        norm: 'одноразовое применение',
      },
    ],
  },
  {
    profId: 3920,
    label: 'Проводник по сопровождению грузов и спецвагонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Фильтрующий самоспасатель',
        norm: 'одноразовое применение',
      },
    ],
  },
  {
    profId: 3921,
    label: 'Проводник по сопровождению животных',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3922,
    label:
      'Проводник по сопровождению локомотивов и пассажирских вагонов в нерабочем состоянии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Фильтрующий самоспасатель',
        norm: 'одноразовое применение',
      },
    ],
  },
  {
    profId: 3923,
    label: 'Проводник-электромонтер почтовых вагонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Фильтрующий самоспасатель',
        norm: 'одноразовое применение',
      },
    ],
  },
  {
    profId: 3924,
    label: 'Провязывальщик мотков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3925,
    label: 'Продавец непродовольственных товаров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3926,
    label: 'Продавец продовольственных товаров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3927,
    label: 'Прожекторист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3928,
    label: 'Прожигальщик медицинских изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3929,
    label: 'Прокальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3930,
    label: 'Прокальщик зерна и шлифпорошков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3931,
    label: 'Прокальщик металлических порошков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3932,
    label: 'Прокальщик на печах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3933,
    label: 'Прокальщик порошка для кабеля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3934,
    label: 'Прокальщик электроугольного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3935,
    label: 'Прокатчик горячего металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3936,
    label: 'Прокатчик кож',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3937,
    label: 'Прокатчик ленты из порошка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3938,
    label: 'Прокатчик пленки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3939,
    label: 'Прокатчик слюды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3940,
    label: 'Прокатчик фарфоровых труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3941,
    label: 'Прокатчик шаров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3942,
    label: 'Проклеивальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3943,
    label: 'Проклеивальщик ватилина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3944,
    label: 'Проклейщик массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3945,
    label: 'Промазчик форм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3946,
    label: 'Промывальщик волокнистых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3947,
    label: 'Промывальщик геологических проб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3948,
    label: 'Промывальщик котлов паровозов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: 'до износа',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3949,
    label: 'Промывальщик медно-никелевого порошка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3950,
    label: 'Промывальщик-пропарщик цистерн',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3951,
    label: 'Промывальщик сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3952,
    label: 'Промывальщик технических сукон',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3953,
    label: 'Промывщик бриллиантов и алмазов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3954,
    label: 'Промывщик гидроксала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3955,
    label: 'Промывщик деталей и узлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3956,
    label: 'Промывщик камней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3957,
    label: 'Промывщик оптических деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды и растворов нетоксичных веществ, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3958,
    label: 'Промывщик целлюлозы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды и растворов нетоксичных веществ и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3959,
    label: 'Промышленный альпинист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3960,
    label: 'Пропарщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3961,
    label: 'Пропарщик асбестоцементных и асбесто-силитовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 3962,
    label: 'Пропарщик лао-ча',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3963,
    label: 'Пропарщик-проварщик древесины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3964,
    label: 'Пропарщик стеновых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3965,
    label: 'Пропитчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3966,
    label: 'Пропитчик бумаги и бумажных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3967,
    label: 'Пропитчик бумаги и тканей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3968,
    label: 'Пропитчик кабелей и проводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3969,
    label: 'Пропитчик карандашных дощечек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3970,
    label: 'Пропитчик пиломатериалов и изделий из древесины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3971,
    label: 'Пропитчик по огнезащитной пропитке',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3972,
    label: 'Пропитчик слюдопластовых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3973,
    label: 'Пропитчик стержней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3974,
    label: 'Пропитчик шпона',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3975,
    label: 'Пропитчик электротехнических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3976,
    label: 'Пропитывальщик пожарных рукавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3977,
    label: 'Просевальщик материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3978,
    label: 'Просевальщик порошков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3979,
    label: 'Просевальщик (рассевальщик)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3980,
    label: 'Просевальщик сыпучих материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3981,
    label: 'Просевальщик технической продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3982,
    label: 'Просевальщик фарматуры и отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3983,
    label: 'Просевальщик фтористого натрия и извести-пушонки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нетоксичной пыли, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3984,
    label: 'Просевщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3985,
    label: 'Просевщик бисера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3986,
    label: 'Просевщик порошков на механических ситах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нетоксичной пыли, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3987,
    label: 'Просеивальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3988,
    label: 'Просмотрщик ампул с инъекционными растворами',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3989,
    label: 'Просмотрщик продукции медицинского назначения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3990,
    label: 'Протирщик изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3991,
    label: 'Протирщик стеарата кальция',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3992,
    label: 'Протирщик часовых стекол',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3993,
    label: 'Протирщик электровакуумных приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3994,
    label: 'Протравщик хлопковых семян',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3995,
    label: 'Протравщик шкурок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3996,
    label: 'Протяжчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3997,
    label: 'Протяжчик штурвалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 3998,
    label: 'Профилировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 3999,
    label: 'Проходчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов) и от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'Определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 4000,
    label: 'Проходчик горных склонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов) и от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4001,
    label: 'Проходчик на поверхностных работах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов) и от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4002,
    label: 'Проявщик кинопленки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4003,
    label: 'Пружинщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4004,
    label: 'Прядильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4005,
    label: 'Птицевод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4006,
    label: 'Пудровщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4007,
    label: 'Пудровщик оттисков деколи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4008,
    label: 'Пультовщик конвертера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4009,
    label: 'Пультовщик электроплавильной печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4010,
    label: 'Пульфонщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4011,
    label: 'Путевой рабочий на озере',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4012,
    label: 'Путевой рабочий тральной бригады',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4013,
    label: 'Пчеловод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от биологических факторов (кровососущих насекомых)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4014,
    label: 'Пятновыводчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4015,
    label: 'Рабочий береговой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4016,
    label: 'Рабочий бюро бытовых услуг',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4017,
    label: 'Рабочий зеленого строительства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4018,
    label: 'Рабочий зеленого хозяйства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4019,
    label: 'Рабочий карты намыва',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4020,
    label: 'Рабочий на геологосъемочных и поисковых работах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и вредных биологических факторов (клещей и кровососущих насекомых), в том числе в комплекте с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4021,
    label: 'Рабочий на геофизических работах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и вредных биологических факторов (клещей и кровососущих насекомых), в том числе в комплекте с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4022,
    label: 'Рабочий по изготовлению опытных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4023,
    label: 'Рабочий полигона',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4024,
    label: 'Рабочий по подаче химикатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4025,
    label: 'Рабочий по подготовке спецпродукта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4026,
    label: 'Рабочий по обслуживанию бани',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4027,
    label: 'Рабочий плодоовощного хранилища',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4028,
    label:
      'Рабочий производства по изготовлению детонирующего шнура, зарядов и других комплектующих, содержащих взрывчатые вещества',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4029,
    label: 'Рабочий по благоустройству населенных пунктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4030,
    label: 'Рабочий по комплексному обслуживанию и ремонту зданий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 4031,
    label: 'Машинист по стирке и ремонту спецодежды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4032,
    label: 'Рабочий по уходу за животными',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4033,
    label: 'Рабочий производственных бань',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4034,
    label: 'Рабочий противолавинной защиты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4035,
    label: 'Рабочий ритуальных услуг',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4036,
    label: 'Радиометрист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4037,
    label:
      'Радиомеханик по обслуживанию и ремонту радиотелевизионной аппаратуры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4038,
    label: 'Радиомеханик по ремонту радиоэлектронного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4039,
    label: 'Радиомонтажник судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 4040,
    label: 'Радиомонтер приемных телевизионных антенн',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4041,
    label: 'Радиооператор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4042,
    label: 'Радиотелеграфист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4043,
    label: 'Радиотехник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4044,
    label: 'Радист-радиолокаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4045,
    label: 'Разбивщик отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4046,
    label: 'Разбивщик сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4047,
    label: 'Разбивщик ферросплавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4048,
    label: 'Разбортовщик винипластовых и полиэтиленовых труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4049,
    label: 'Разборщик асбестоцементных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4050,
    label: 'Разборщик оптического стекла и кристаллов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4051,
    label: 'Разборщик пакетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4052,
    label: 'Разборщик печей сопротивления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4053,
    label: 'Разборщик субпродуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4054,
    label: 'Развальцовщик стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4055,
    label: 'Разварщик саломаса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4056,
    label: 'Разварщик силикатной глыбы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4057,
    label: 'Разведчик объектов природы для коллекций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4058,
    label: 'Развесчик химического сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4059,
    label: 'Разводчик кож',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4060,
    label: 'Разводчик (распусчик) холяв',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4061,
    label: 'Развязывальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4062,
    label: 'Разгрузчик диффузоров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4063,
    label: 'Раздатчик взрывчатых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воздействия статического электричества и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Фильтрующий самоспасатель',
        norm: 'одноразовое применение',
      },
    ],
  },
  {
    profId: 4064,
    label: 'Раздатчик нефтепродуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от нефти и/или нефтепродуктов',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Фильтрующий самоспасатель',
        norm: 'одноразовое применение',
      },
    ],
  },
  {
    profId: 4065,
    label: 'Раздельщик жгутов стекловолокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4066,
    label: 'Раздельщик лома и отходов металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4067,
    label: 'Раздельщик лома и специзделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4068,
    label: 'Раздельщик титановой губки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4069,
    label: 'Раздирщик пакетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 4070,
    label: 'Развивальщик-загладчик пеномассы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4071,
    label: 'Разливщик ртути',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 4072,
    label: 'Разливщик стали',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4073,
    label: 'Разливщик стерильных растворов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4074,
    label: 'Разливщик химической продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4075,
    label: 'Разливщик цветных металлов и сплавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4076,
    label: 'Разметчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Наколенники',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4077,
    label: 'Разметчик алмазов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4078,
    label: 'Разметчик деталей и материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4079,
    label: 'Разметчик плазовый',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4080,
    label: 'Разметчик по дереву',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4081,
    label: 'Разметчик проката',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4082,
    label: 'Разметчик пьезокварцевого сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4083,
    label: 'Разметчик стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4084,
    label: 'Разметчик судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4085,
    label: 'Разметчик хлыстов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4086,
    label: 'Размольщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4087,
    label: 'Размольщик вирусной ткани и бактерийной массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4088,
    label: 'Размольщик-дозировщик угольных масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4089,
    label: 'Размольщик древесины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4090,
    label: 'Размольщик карандашной массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4091,
    label: 'Размольщик (мельник) кости-паренки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4092,
    label: 'Размольщик роговой стружки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4093,
    label: 'Размотчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4094,
    label: 'Размотчик лент',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4095,
    label: 'Размотчик стеклонити',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4096,
    label: 'Разрабатывальщик отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4097,
    label: 'Разрабатывальщик сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4098,
    label: 'Разрисовщик игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4099,
    label: 'Разрисовщик изделий из кожи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4100,
    label: 'Разрисовщик кожгалантерейных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4101,
    label: 'Разрисовщик моделей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4102,
    label: 'Разрисовщик обоев',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4103,
    label: 'Разрисовщик по стеклу',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4104,
    label: 'Разрисовщик ткани',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4105,
    label: 'Разрубщик аккумуляторных пластин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4106,
    label: 'Разрубщик мяса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки и защитные приспособления кольчужные',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4107,
    label: 'Разрывщик оттисков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4108,
    label: 'Разрыхлитель табака',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4109,
    label: 'Раймовщик дистилляционных печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4110,
    label: 'Раклист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4111,
    label: 'Рамповщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания) и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4112,
    label: 'Рамщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4113,
    label: 'Раскатчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4114,
    label: 'Раскатчик-сортировщик бумаги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4115,
    label: 'Раскатчик стержней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4116,
    label: 'Раскатчик ткани',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4117,
    label: 'Раскладчик лекал',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4118,
    label: 'Раскладчик листового табака',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4119,
    label: 'Раскладчик стекловолокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4120,
    label: 'Раскладчик сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4121,
    label: 'Раскольщик алмазов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4122,
    label: 'Раскольщик блоков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4123,
    label: 'Раскрасчик диапозитивов и фотоотпечатков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4124,
    label: 'Раскрасчик законтурованных рисунков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4125,
    label: 'Раскрасчик изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4126,
    label: 'Раскройщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4127,
    label: 'Раскройщик бересты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4128,
    label: 'Раскройщик кожевенного сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4129,
    label: 'Раскройщик кожи и меха',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4130,
    label: 'Раскройщик листового материала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4131,
    label: 'Раскройщик материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4132,
    label: 'Раскройщик пленки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4133,
    label: 'Раскройщик стекловолокнистых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4134,
    label: 'Раскройщик шлифовального полотна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4135,
    label: 'Раскряжевщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Защитные приспособления для защиты от режущего воздействия ручной цепной пилой',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от режущего воздействия ручной цепной пилой',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), режущего воздействия ручной цепной пилой',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от режущего воздействия ручной цепной пилой',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от механических воздействий (ударов твердых частиц), в том числе из металлической сетки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4136,
    label: 'Распаковщик сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4137,
    label: 'Распалубщик теплоизоляционных и акустических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4138,
    label: 'Распарщик целлулоидных пластин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4139,
    label: 'Распиловщик алмазов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4140,
    label: 'Распиловщик водорастворимых кристаллов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4141,
    label: 'Распиловщик войлока',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4142,
    label: 'Распиловщик камня',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4143,
    label: 'Распиловщик кости и рога',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Перчатки и защитные приспособления кольчужные',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4144,
    label: 'Распиловщик меха и войлока',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4145,
    label: 'Распиловщик мясопродуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки и защитные приспособления кольчужные',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4146,
    label: 'Распиловщик необожженных кругов и брусков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4147,
    label: 'Распиловщик оптического стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4148,
    label: 'Расправщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4149,
    label: 'Расправщик войлочных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4150,
    label: 'Расправщик основ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4151,
    label: 'Распределитель работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4152,
    label: 'Распределитель силикатной массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4153,
    label: 'Распылитель газопоглотителя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4154,
    label: 'Рассевальщик шлифзерна и шлифпорошков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4155,
    label: 'Рассевщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4156,
    label: 'Растворщик реагентов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4157,
    label: 'Растильщик грибницы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4158,
    label: 'Растяжчик кожаных полос',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4159,
    label: 'Растяжчик кож и овчин на рамы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4160,
    label: 'Растяжчик металлосеток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4161,
    label: 'Растяжчик секций и катушек электрических машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4162,
    label: 'Расфасовщик алмазов и алмазных порошков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4163,
    label: 'Расфасовщик ваты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4164,
    label: 'Расфасовщик мясопродуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4165,
    label: 'Расфасовщик нюхательной махорки и табака',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4166,
    label: 'Расфасовщик табака',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4167,
    label: 'Расформовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4168,
    label: 'Расчесывальщик меховых шкурок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4169,
    label: 'Расчехловщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4170,
    label: 'Расшлифовщик фильеров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4171,
    label: 'Расщепляльщик синтетических нитей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4172,
    label: 'Рафинировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4173,
    label: 'Рафинировщик ртути',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 4174,
    label: 'Реактивщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4175,
    label: 'Реакторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4176,
    label: 'Реакторщик химочистки рассола',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4177,
    label: 'Регенераторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4178,
    label: 'Регенераторщик абразивов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4179,
    label: 'Регенераторщик драгоценных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4180,
    label: 'Регенераторщик отработанного масла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от нефти и/или нефтепродуктов',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4181,
    label: 'Регенераторщик сернистой кислоты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4182,
    label: 'Регенераторщик слюды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4183,
    label: 'Регенераторщик спецсмазок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4184,
    label: 'Регулировщик асбестообогатительного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4185,
    label: 'Регулировщик-градуировщик электроизмерительных приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4186,
    label: 'Регулировщик композиции и концентрации массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4187,
    label: 'Регулировщик-настройщик тренажеров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4188,
    label: 'Регулировщик пианино и роялей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4189,
    label: 'Регулировщик подачи воды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4190,
    label: 'Регулировщик полей фильтрации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4191,
    label: 'Регулировщик работы скважин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4192,
    label: 'Регулировщик радиоэлектронной аппаратуры и приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4193,
    label: 'Регулировщик скорости движения вагонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 36 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4194,
    label: 'Регулировщик хвостового хозяйства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4195,
    label: 'Регулировщик электродов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4196,
    label: 'Регулировщик язычковых инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4197,
    label: 'Редуцировщик игл',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4198,
    label: 'Редуцировщик трубчатых электронагревателей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4199,
    label: 'Резчик алмазов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4200,
    label: 'Резчик ампул и трубок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4201,
    label: 'Резчик асбестоцементных и асбестосилитовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4202,
    label: 'Резчик бетонных и железобетонных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4203,
    label: 'Резчик брикета и заготовок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4204,
    label: 'Резчик бумаги, картона и целлюлозы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4205,
    label: 'Резчик гипсокартонных листов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4206,
    label: 'Резчик горячего металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4207,
    label: 'Резчик декалькоманий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4208,
    label: 'Резчик деревянного шрифта',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4209,
    label: 'Резчик заготовок и изделий из пластических масс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4210,
    label: 'Резчик керамических и фарфоровых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4211,
    label: 'Резчик кирпича и черепицы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4212,
    label: 'Резчик конвертов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4213,
    label: 'Резчик ленты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4214,
    label: 'Резчик-лудильщик фольги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4215,
    label: 'Резчик магнитных лент',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4216,
    label: 'Резчик магнитопроводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4217,
    label: 'Резчик материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 4218,
    label: 'Резчик материалов и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 4219,
    label: 'Резчик материалов кабельного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4220,
    label: 'Резчик металла на ножницах и прессах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4221,
    label: 'Резчик металлического натрия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4222,
    label: 'Резчик минералов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4223,
    label: 'Резчик мясопродуктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки и защитные приспособления кольчужные',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4224,
    label: 'Резчик на микротоме',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4225,
    label: 'Резчик на огне',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4226,
    label: 'Резчик на отжимной машине',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4227,
    label: 'Резчик на пилах, ножовках и станках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4228,
    label: 'Резчик неэмульсированных пленок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4229,
    label: 'Резчик нитей стержней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4230,
    label: 'Резчик пеноблоков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4231,
    label: 'Резчик пищевой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4232,
    label: 'Резчик по дереву и бересте',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4233,
    label: 'Резчик по камню',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки и защитные приспособления кольчужные',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4234,
    label: 'Резчик по кости и рогу',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки и защитные приспособления кольчужные',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4235,
    label: 'Резчик полуфабрикатов изделий медицинского назначения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4236,
    label: 'Резчик пробковых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4237,
    label: 'Резчик пряжи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4238,
    label: 'Резчик радиокерамики и ферритов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4239,
    label: 'Резчик свеклы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4240,
    label: 'Резчик слюды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4241,
    label: 'Резчик стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов, истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4242,
    label: 'Резчик стекловолокнистых и стеклопластиковых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4243,
    label: 'Резчик стеклоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4244,
    label: 'Резчик сусальных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов, истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4245,
    label: 'Резчик сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4246,
    label: 'Резчик теплоизоляционных и акустических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4247,
    label: 'Резчик траншей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4248,
    label: 'Резчик труб и заготовок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4249,
    label: 'Резчик фольги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4250,
    label: 'Резчик химического волокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4251,
    label: 'Резчик холодного металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов, истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4252,
    label: 'Резчик шлифовальной шкурки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов, истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4253,
    label: 'Резчик шпона и облицовочных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4254,
    label: 'Резчик эластомеров и резины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4255,
    label: 'Резчик электроизоляционных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4256,
    label: 'Резьбонарезчик деталей часов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4257,
    label: 'Резьбонарезчик на специальных станках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4258,
    label: 'Резьбофрезеровщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 4259,
    label: 'Резьбошлифовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4260,
    label: 'Реквизитор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4261,
    label: 'Рекуператорщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4262,
    label: 'Рекуператорщик алмазов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4263,
    label: 'Ремонтировщик кожгалантерейных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4264,
    label: 'Ремонтировщик обувных колодок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4265,
    label: 'Ремонтировщик плоскостных спортивных сооружений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4266,
    label: 'Ремонтировщик полимеризационного инвентаря',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4267,
    label: 'Ремонтировщик резиновых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4268,
    label: 'Ремонтировщик респираторов и противогазов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4269,
    label: 'Ремонтировщик сетеизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4270,
    label: 'Ремонтировщик шпуль',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4271,
    label: 'Ремонтник искусственных сооружений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4272,
    label: 'Ремонтник технологической оснастки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4273,
    label: 'Ремюер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4274,
    label: 'Рентгенолаборант',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: ' ',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4275,
    label: 'Рентгенгониометрист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Одежда специальная для защиты от радиоактивных веществ и ионизирующих излучений',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от радиоактивных веществ и ионизирующих излучений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты рук от радиоактивных веществ и ионизирующих излучений в том числе перчатки камерные',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от ионизирующих излучений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4276,
    label: 'Рентгеномеханик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4277,
    label: 'Реперщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4278,
    label: 'Репульпаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4279,
    label: 'Рессорщик на обработке горячего металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4280,
    label: 'Реставратор архивных и библиотечных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4281,
    label: 'Реставратор готовой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4282,
    label: 'Реставратор декоративно-художественных покрасок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4283,
    label: 'Реставратор декоративных штукатурок и лепных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4284,
    label: 'Реставратор духовых инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4285,
    label: 'Реставратор клавишных инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4286,
    label: 'Реставратор кровельных покрытий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4287,
    label: 'Реставратор металлических конструкций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4288,
    label: 'Реставратор памятников деревянного зодчества',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4289,
    label: 'Реставратор памятников каменного зодчества',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4290,
    label: 'Реставратор произведений из дерева',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4291,
    label: 'Реставратор смычковых и щипковых инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4292,
    label: 'Реставратор тканей, гобеленов и ковров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4293,
    label: 'Реставратор ударных инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4294,
    label: 'Реставратор фильмовых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4295,
    label: 'Реставратор фильмокопий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4296,
    label: 'Реставратор художественных изделий и декоративных предметов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4297,
    label: 'Реставратор язычковых инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4298,
    label: 'Ретушер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4299,
    label: 'Ретушер прецизионной фотолитографии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4300,
    label: 'Ретушер субтитров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4301,
    label: 'Рецептурщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4302,
    label:
      'Речной рабочий на подводно-технических, габионных и фашинных работах, выполняемых с поверхности',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4303,
    label:
      'Речной рабочий на эксплуатации и обслуживании несамоходных плавучих снарядов и других плавучих средств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4304,
    label: 'Рисовальщик светящимися красками',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4305,
    label: 'Рисовальщик эмалями',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4306,
    label: 'Рисовод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4307,
    label: 'Рифлевщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4308,
    label: 'Рихтовщик игольно-платинных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4309,
    label: 'Рихтовщик кузовов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4310,
    label: 'Рубщик проволоки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4311,
    label: 'Рубщик судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4312,
    label: 'Рулевой (кормщик)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4313,
    label: 'Рыбак кефального хозяйства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4314,
    label: 'Рыбак прибрежного лова',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: ' ',
    label: '',
    SIZ: [],
  },
  {
    profId: 4315,
    label: 'Рыбовод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4316,
    label: 'Садовник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4317,
    label: 'Садовод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4318,
    label: 'Садчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4319,
    label: 'Садчик в печи и на туннельные вагоны',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4320,
    label: 'Садчик камня в обжигательные печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4321,
    label: 'Санитар ветеринарный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4322,
    label: 'Санитарка (мойщица)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4323,
    label: 'Сатураторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4324,
    label: 'Сборщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4325,
    label: 'Сборщик алмазных инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4326,
    label: 'Сборщик асбестоцементных плит',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4327,
    label: 'Сборщик асбометаллических листов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4328,
    label: 'Сборщик баллонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4329,
    label: 'Сборщик безбандажных шин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4330,
    label: 'Сборщик браслетов и брекеров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4331,
    label: 'Сборщик бумажных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4332,
    label: 'Сборщик верха обуви',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4333,
    label: 'Сборщик влагопоглотителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4334,
    label: 'Сборщик восстанавливаемых покрышек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4335,
    label: 'Сборщик выпрямителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4336,
    label: 'Сборщик гальванических элементов и батарей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4337,
    label: 'Сборщик деревянных судов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4338,
    label: 'Сборщик деталей и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4339,
    label: 'Сборщик-достройщик судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4340,
    label: 'Сборщик духовых инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4341,
    label: 'Сборщик железобетонных конструкций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4342,
    label: 'Сборщик железобетонных судов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4343,
    label: 'Сборщик игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4344,
    label: 'Сборщик изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4345,
    label: 'Сборщик изделий из дерева и папье-маше',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4346,
    label: 'Сборщик изделий из древесины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4347,
    label: 'Сборщик изделий из кожи и меха',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4348,
    label: 'Сборщик изделий из пластмасс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4349,
    label: 'Сборщик изделий из стеклопластиков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4350,
    label: 'Сборщик изделий из янтаря',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4351,
    label: 'Сборщик изделий электронной техники',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4352,
    label: 'Сборщик индикаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4353,
    label: 'Сборщик инъекционных игл',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4354,
    label: 'Сборщик каркасов в производстве гипсобетонных панелей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4355,
    label: 'Сборщик кассет для малогабаритных магнитофонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4356,
    label: 'Сборщик квантовых приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4357,
    label: 'Сборщик кварцевых держателей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4358,
    label: 'Сборщик-клейщик конструкций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4359,
    label: 'Сборщик-клепальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4360,
    label: 'Сборщик кожгалантерейных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4361,
    label: 'Сборщик корпусов металлических судов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4362,
    label: 'Сборщик лент',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4363,
    label: 'Сборщик микросхем',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4364,
    label: 'Сборщик мокрых отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4365,
    label: 'Сборщик-монтажник в производстве цветных кинескопов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4366,
    label: 'Сборщик-монтажник клавишных инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4367,
    label: 'Сборщик-монтажник смычковых инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4368,
    label: 'Сборщик-монтажник щипковых инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4369,
    label: 'Сборщик музыкальных и озвученных игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4370,
    label: 'Сборщик-настройщик магнитных систем',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4371,
    label: 'Сборщик натуральных объектов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4372,
    label: 'Сборщик низа обуви',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4373,
    label: 'Сборщик обмоток трансформаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4374,
    label: 'Сборщик обуви',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4375,
    label: 'Сборщик-отдельщик катушек трансформаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4376,
    label: 'Сборщик очков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4377,
    label: 'Сборщик пакетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4378,
    label: 'Сборщик пакетов конденсаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4379,
    label: 'Сборщик перевязочных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4380,
    label: 'Сборщик пластин искусственной кожи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4381,
    label: 'Сборщик пластмассовых судов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4382,
    label: 'Сборщик плетеной мебели',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4383,
    label: 'Сборщик покрышек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4384,
    label: 'Сборщик полимеризационного инвентаря',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4385,
    label: 'Сборщик полупроводниковых приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4386,
    label: 'Сборщик по обрамлению стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4387,
    label: 'Сборщик приборов из стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4388,
    label: 'Сборщик пробковой пыли',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4389,
    label: 'Сборщик пробковых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4390,
    label: 'Сборщик продукции в аэрозольной упаковке',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4391,
    label: 'Сборщик прядильных блоков и насосов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4392,
    label: 'Сборщик пьезорезонаторов и изделий на основе пьезоэлементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4393,
    label: 'Сборщик радиодеталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4394,
    label: 'Сборщик резиновых технических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4395,
    label: 'Сборщик ртути',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 4396,
    label: 'Сборщик ртутно-цинковых, магниевых и других источников тока',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4397,
    label: 'Сборщик ртутных выпрямителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 4398,
    label: 'Сборщик сборочных единиц часов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4399,
    label: 'Сборщик свинцовых аккумуляторов и батарей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4400,
    label: 'Сборщик сердечников трансформаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4401,
    label: 'Сборщик сильноточных конденсаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4402,
    label: 'Сборщик-снаряжальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4403,
    label: 'Сборщик стеклоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4404,
    label: 'Сборщик стеклопакетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4405,
    label: 'Сборщик теплоизоляционных конструкций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4406,
    label: 'Сборщик термосов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки - для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4407,
    label: 'Сборщик технологических каналов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4408,
    label: 'Сборщик тиглей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4409,
    label: 'Сборщик токоограничивающих реакторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4410,
    label: 'Сборщик трансформаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4411,
    label: 'Сборщик ударных инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4412,
    label: 'Сборщик фанерных труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4413,
    label: 'Сборщик фарфоровых и фаянсовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4414,
    label: 'Сборщик ферритовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4415,
    label: 'Сборщик форм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4416,
    label: 'Сборщик форм для флексографской печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4417,
    label: 'Сборщик химаппаратуры и химоборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4418,
    label: 'Сборщик хирургических инструментов и аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4419,
    label: 'Сборщик цельнометаллических растворов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4420,
    label: 'Сборщик часов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4421,
    label: 'Сборщик чемоданов из фанеры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4422,
    label: 'Сборщик шайб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4423,
    label: 'Сборщик шинно-пневматических муфт',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4424,
    label: 'Сборщик шорно-седельных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4425,
    label: 'Сборщик шприцев',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4426,
    label: 'Сборщик штемпелей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4427,
    label: 'Сборщик щелевидных сит и металлических щеток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4428,
    label: 'Сборщик щелочных аккумуляторов и батарей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4429,
    label: 'Сборщик электрических машин и аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4430,
    label: 'Сборщик электроигр',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4431,
    label: 'Сборщик электроизмерительных приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4432,
    label: 'Сборщик электрокерамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4433,
    label: 'Сборщик электроугольного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4434,
    label: 'Сборщик эндокринно-ферментного сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4435,
    label: 'Сборщик эпителия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4436,
    label: 'Сборщик этажерочных вагонеток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4437,
    label: 'Сборщик язычковых инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4438,
    label: 'Сварщик арматурных сеток и каркасов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4439,
    label: 'Сварщик выпрямителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4440,
    label: 'Сварщик изделий из тугоплавких металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от искр и брызг расплавленного металла и металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4441,
    label: 'Сварщик на диффузионно-сварочных установках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4442,
    label: 'Сварщик на лазерных установках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от лазерных излучений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4443,
    label: 'Сварщик на машинах контактной (прессовой) сварки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4444,
    label: 'Сварщик на установках ТВЧ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4445,
    label: 'Сварщик на электронно-лучевых сварочных установках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4446,
    label: 'Сварщик-оператор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4447,
    label: 'Сварщик-перемотчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4448,
    label: 'Сварщик печной сварки труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4449,
    label: 'Сварщик пластмасс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4450,
    label: 'Сварщик спеченных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4451,
    label: 'Сварщик стеклянных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4452,
    label: 'Сварщик термитной сварки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4453,
    label: 'Сварщик швейных изделий на установках ТВЧ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4454,
    label: 'Сварщик электровакуумных приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4455,
    label: 'Сверловщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4456,
    label: 'Сверловщик абразивных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4457,
    label: 'Сверловщик затравочных пластин кварца',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4458,
    label: 'Сверловщик камней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4459,
    label: 'Сверловщик оптических деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4460,
    label: 'Сверловщик-пневматик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4461,
    label: 'Сверловщик стеклоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4462,
    label: 'Сверловщик электрокерамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4463,
    label: 'Светокопировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4464,
    label: 'Свиновод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4465,
    label: 'Свойлачивальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4466,
    label: 'Связывальщик пачек волокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4467,
    label: 'Сгонщик-смывщик краски и лаков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4468,
    label: 'Сгустительщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4469,
    label: 'Сгустительщик кожволокнистой массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4470,
    label: 'Сдатчик готовой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4471,
    label: 'Сдатчик экспортных лесоматериалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4472,
    label: 'Сепараторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4473,
    label: 'Сепараторщик биомассы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4474,
    label: 'Сепараторщик шлифзерна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4475,
    label: 'Серебрильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4476,
    label: 'Серебрильщик пьезотехнических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4477,
    label: 'Сестра-хозяйка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4478,
    label: 'Сетевязальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4479,
    label: 'Сигналист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 36 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4480,
    label: 'Сигнальщик боновый',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4481,
    label: 'Силосник',
    SIZ: [
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4482,
    label: 'Синильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4483,
    label: 'Скиповой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4484,
    label: 'Скирдовальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4485,
    label: 'Складывальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4486,
    label: 'Склеивальщик нитей и текстильно-галантерейных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4487,
    label: 'Склейщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4488,
    label: 'Склейщик блоков, заготовок и строительных конструкций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4489,
    label: 'Склейщик керамических, фарфоровых и фаянсовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4490,
    label: 'Склейщик-окрасчик очковых оправ из пластмасс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4491,
    label: 'Склейщик оптических деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4492,
    label: 'Склейщик пьезоэлементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4493,
    label: 'Склейщик технических камней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4494,
    label: 'Склейщик фанерных труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4495,
    label: 'Склейщик электрокерамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4496,
    label: 'Скорняк-наборщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4497,
    label: 'Скорняк-раскройщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4498,
    label: 'Скрайбировщик пластин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4499,
    label: 'Скрубберщик-насосчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4500,
    label: 'Скрутчик изделий кабельного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4501,
    label: 'Скрутчик-изолировщик жил и кабеля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4502,
    label: 'Скрутчик-изолировщик элементов кабелей связи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4503,
    label: 'Слесарь аварийно-восстановительных работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), воды и растворов нетоксичных веществ',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4504,
    label: 'Слесарь аварийно-восстановительных работ в газовом хозяйстве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4505,
    label: 'Слесарь-вакуумщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4506,
    label: 'Слесарь-доводчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4507,
    label: 'Слесарь-доводчик специзделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4508,
    label: 'Слесарь зумпфового агрегата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4509,
    label: 'Слесарь-инструментальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4510,
    label: 'Слесарь-испытатель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4511,
    label: 'Слесарь-испытатель транспортных агрегатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4512,
    label: 'Слесарь-механик по испытанию установок и аппаратуры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4513,
    label: 'Слесарь-механик по радиоэлектронной аппаратуре',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4514,
    label: 'Слесарь-механик по ремонту авиационных приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), воздействия статического электричества',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), воздействия статического электричества',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4515,
    label: 'Слесарь-механик электромеханических приборов и систем',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4516,
    label: 'Слесарь механосборочных работ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4517,
    label: 'Слесарь-монтажник приборного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 4518,
    label: 'Слесарь-монтажник судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 4519,
    label: 'Слесарь-опрессовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4520,
    label: 'Слесарь по аэрогидродинамическим испытаниями',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4521,
    label: 'Слесарь по выводам и обмоткам электрических машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4522,
    label:
      'Слесарь по изготовлению деталей и узлов систем вентиляции, кондиционирования воздуха, пневмотранспорта и аспирации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4523,
    label: 'Слесарь по изготовлению и доводке деталей летательных аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4524,
    label: 'Слесарь по изготовлению и ремонту трубопроводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4525,
    label:
      'Слесарь по изготовлению узлов и деталей санитарно-технических систем',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4526,
    label:
      'Слесарь по изготовлению узлов и деталей технологических трубопроводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4527,
    label: 'Слесарь по контрольно-измерительным приборам и автоматике',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4528,
    label: 'Слесарь по монтажу и ремонту оснований морских буровых и эстакад',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (проколов), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4529,
    label: 'Слесарь по обслуживанию буровых',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (проколов), нефти и/или нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4530,
    label:
      'Слесарь по обслуживанию оборудования технологического регулирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4531,
    label:
      'Слесарь по техническому обслуживанию оборудования газоразделительного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воздействия статического электричества, механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4532,
    label: 'Слесарь по обслуживанию оборудования электростанций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Комплект для защиты от воздействия электрических полей промышленной частоты в составе:',
        norm: '1 комп. на 18 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки утепленные',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки от пониженных температур',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 4533,
    label: 'Слесарь по обслуживанию тепловых пунктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 4534,
    label: 'Слесарь по обслуживанию тепловых сетей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 4535,
    label:
      'Слесарь по осмотру и ремонту локомотивов на пунктах технического обслуживания',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
    ],
  },
  {
    profId: 4536,
    label: 'Слесарь по ремонту авиадвигателей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4537,
    label: 'Слесарь по ремонту автомобилей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от нефти и/или нефтепродуктов',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4538,
    label: 'Слесарь по ремонту агрегатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от нефти и/или нефтепродуктов',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4539,
    label: 'Слесарь по ремонту гидротурбинного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4540,
    label: 'Слесарь по ремонту дорожно-строительных машин и тракторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4541,
    label: 'Слесарь по ремонту и обслуживанию перегрузочных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4542,
    label:
      'Слесарь по ремонту и обслуживанию систем вентиляции и кондиционирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: ' ',
      },
      {
        type: 'Средства защиты головы',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4543,
    label: 'Слесарь по ремонту лесозаготовительного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 4544,
    label: 'Слесарь по ремонту летательных аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4545,
    label:
      'Слесарь по ремонту оборудования котельных и пылеприготовительных цехов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания неавтономные с подачей чистого воздуха по шлангу/магистрали (дыхательные аппараты)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4546,
    label: 'Слесарь по ремонту оборудования тепловых сетей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каскетка защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания неавтономные с подачей чистого воздуха по шлангу/магистрали (дыхательные аппараты)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4547,
    label: 'Слесарь по ремонту оборудования топливоподачи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от нефти и/или нефтепродуктов',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4548,
    label: 'Слесарь по ремонту парогазотурбинного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4549,
    label: 'Слесарь по ремонту подвижного состава',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4550,
    label: 'Слесарь по ремонту путевых машин и механизмов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4551,
    label: 'Слесарь по ремонту реакторно-турбинного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4552,
    label: 'Слесарь по ремонту сельскохозяйственных машин и оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4553,
    label: 'Слесарь по ремонту технологических установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4554,
    label: 'Слесарь по сборке металлоконструкций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4555,
    label: 'Слесарь по такелажу и грузозахватным приспособлениями',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, ударов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4556,
    label: 'Слесарь по топливной аппаратуре',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4557,
    label: 'Слесарь по эксплуатации и ремонту газового оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4558,
    label: 'Слесарь по эксплуатации и ремонту подземных газопроводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 4559,
    label: 'Слесарь-проводчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4560,
    label: 'Слесарь-ремонтник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4561,
    label: 'Слесарь-сантехник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4562,
    label: 'Слесарь-сборщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4563,
    label: 'Слесарь-сборщик авиационных приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4564,
    label: 'Слесарь-сборщик двигателей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4565,
    label: 'Слесарь-сборщик изделий из органического стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4566,
    label: 'Слесарь-сборщик летательных аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4567,
    label: 'Слесарь-сборщик радиоэлектронной аппаратуры и приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4568,
    label: 'Слесарь-сборщик универсальных сборных приспособлений (УСП)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4569,
    label: 'Слесарь-сборщик, разборщик ГК, ЦЧ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4570,
    label: 'Слесарь-сборщик, разборщик специзделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4571,
    label: 'Слесарь-сборщик, разборщик ядерных боеприпасов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4572,
    label: 'Слесарь системы испарительного охлаждения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Рубашка для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4573,
    label: 'Слесарь-склейщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4574,
    label: 'Слесарь строительный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4575,
    label: 'Слесарь-судоремонтник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4576,
    label: 'Слесарь централизованной смазочной станции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4577,
    label:
      'Слесарь-электрик по обслуживанию и ремонту металлоконструкций метрополитена',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4578,
    label:
      'Слесарь-электрик по обслуживанию и ремонту оборудования метрополитена',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой -',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Фильтрующий самоспасатель',
        norm: 'одноразовое применение',
      },
    ],
  },
  {
    profId: 4579,
    label:
      'Слесарь-электрик по обслуживанию и ремонту станционного и тоннельного оборудования метрополитена',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 4580,
    label: 'Слесарь-электрик по обслуживанию и ремонту эскалаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4581,
    label: 'Слесарь-электрик по ремонту электрооборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 4582,
    label: 'Слесарь электродной продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4583,
    label: 'Слесарь-электромонтажник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 4584,
    label: 'Сливщик-разливщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от нефти и/или нефтепродуктов, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от нефти и/или нефтепродуктов',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4585,
    label: 'Сливщик стекломассы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4586,
    label: 'Смазчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от нефти и/или нефтепродуктов',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от нефти и/или нефтепродуктов',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4587,
    label: 'Смесильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4588,
    label: 'Смесительщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4589,
    label: 'Смесительщик муки на силосах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4590,
    label: 'Смешивальщик волокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4591,
    label: 'Смольщик берд',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4592,
    label: 'Смольщик пакли',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4593,
    label: 'Смотритель маяка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4594,
    label: 'Смотритель огней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4595,
    label: 'Снаряжальщик детонаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4596,
    label: 'Снаряжальщик-доводчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4597,
    label: 'Сновальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4598,
    label: 'Собаковод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4599,
    label: 'Содовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4600,
    label: 'Солодовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4601,
    label: 'Сортировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4602,
    label: 'Сортировщик абразивных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4603,
    label: 'Сортировщик алмазов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4604,
    label: 'Сортировщик бриллиантов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4605,
    label: 'Сортировщик бумажного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4606,
    label: 'Сортировщик в производстве карандашей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4607,
    label: 'Сортировщик в производстве пищевой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4608,
    label: 'Сортировщик декоративных пород дерева',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4609,
    label: 'Сортировщик деталей подшипников',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4610,
    label: 'Сортировщик деталей часов и камней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4611,
    label: 'Сортировщик древесины на воде',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4612,
    label: 'Сортировщик жести и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4613,
    label: 'Сортировщик игольно-платинных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4614,
    label: 'Сортировщик извести',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4615,
    label: 'Сортировщик изделий, полуфабрикатов и материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4616,
    label: 'Сортировщик изделий, сырья и материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4617,
    label: 'Сортировщик керамзита',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4618,
    label: 'Сортировщик кожевенно-мехового сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4619,
    label: 'Сортировщик кокса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4620,
    label: 'Сортировщик контейнеров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4621,
    label: 'Сортировщик кости',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4622,
    label: 'Сортировщик куска на печах сопротивления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4623,
    label: 'Сортировщик материалов и изделий из древесины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4624,
    label: 'Сортировщик немытой шерсти',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4625,
    label: 'Сортировщик отливок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4626,
    label: 'Сортировщик полуфабриката и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4627,
    label: 'Сортировщик почтовых отправлений и произведений печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4628,
    label: 'Сортировщик-разборщик чая',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4629,
    label: 'Сортировщик рога и кости',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4630,
    label: 'Сортировщик-сборщик лома и отходов металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4631,
    label: 'Сортировщик-сдатчик металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4632,
    label: 'Сортировщик сигарного листа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4633,
    label: 'Сортировщик сырья и волокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4634,
    label: 'Сортировщик сырья и изделий из слюды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4635,
    label: 'Сортировщик сырья, материалов и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4636,
    label: 'Сортировщик сырья, фарфоровых, фаянсовых и керамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4637,
    label: 'Сортировщик табака',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4638,
    label: 'Сортировщик табака в ферментационном производстве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4639,
    label: 'Сортировщик табачных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4640,
    label: 'Сортировщик тушек птицы и кроликов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4641,
    label: 'Сортировщик (упаковщик) теплоизоляционных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4642,
    label: 'Сортировщик шкур',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4643,
    label: 'Сортировщик шкурок кроликов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4644,
    label: 'Сортировщик шпона и фанеры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4645,
    label: 'Сортировщик электродов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4646,
    label: 'Сортировщик электроизоляционных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4647,
    label: 'Сортировщик электроугольных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4648,
    label: 'Составитель аппретур, эмульсий и лаков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4649,
    label: 'Составитель вагонных партий табака',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4650,
    label: 'Составитель коллагеновой массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4651,
    label: 'Составитель массы на мешалках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4652,
    label: 'Составитель навесок ингредиентов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4653,
    label: 'Составитель обмазки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4654,
    label: 'Составитель описи объектов населенных пунктов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4655,
    label: 'Составитель пасты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4656,
    label: 'Составитель перопуховой смеси',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4657,
    label: 'Составитель поездов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 36 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и растворов нетоксичных веществ',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4658,
    label: 'Составитель смесей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4659,
    label: 'Составитель смеси моющих средств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4660,
    label: 'Составитель фарша',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4661,
    label: 'Составитель фтористых присадок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4662,
    label: 'Составитель химических растворов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4663,
    label: 'Составщик шихты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4664,
    label: 'Спайщик стеклоизделий в электрических печах с газовым наполнением',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4665,
    label: 'Спекальщик инструментов из алмазов и сверхтвердых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4666,
    label: 'Спекальщик кювет',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4667,
    label: 'Спекальщик ленточных сердечников',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4668,
    label: 'Спекальщик стекловолокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4669,
    label: 'Спекальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4670,
    label: 'Спекальщик ферритовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты, теплового излучения, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4671,
    label: 'Спекальщик шихты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от кратковременного воздействия открытого пламени, искр и брызг расплавленного металла и металлической окалины',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты, теплового излучения, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4672,
    label:
      'Специалист отдела материально-технического снабжения (ответственный за работы с подъемными сооружениями)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 4673,
    label: 'Сплавщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4674,
    label: 'Сплавщик отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4675,
    label: 'Сплотчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4676,
    label: 'Средовар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4677,
    label: 'Стабилизаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), кратковременного воздействия открытого пламени, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4678,
    label: 'Стабилизаторщик-дефибринировщик крови',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4679,
    label: 'Стабилизаторщик изделий холодом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4680,
    label: 'Стабилизировщик кабелей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4681,
    label: 'Ставильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4682,
    label: 'Ставильщик-выборщик изделий из печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4683,
    label:
      'Ставильщик-выборщик фарфоровых, фаянсовых и керамических изделий на вагонетках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4684,
    label: 'Ставильщик стеклоизделий в автоклавы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4685,
    label: 'Сталевар вакуумной печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4686,
    label: 'Сталевар конвертера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения, кратковременного воздействия открытого пламени и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени, контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4687,
    label: 'Сталевар мартеновской печи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения, кратковременного воздействия открытого пламени и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени, контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4688,
    label: 'Сталевар установки внепечной обработки стали',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения, кратковременного воздействия открытого пламени и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени, контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4689,
    label: 'Сталевар установки электрошлакового переплава',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4690,
    label: 'Сталевар электропечи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения, кратковременного воздействия открытого пламени и выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени, контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения, кратковременного воздействия открытого пламени и выплесков расплавленного металла',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от выплесков расплавленного металла',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4691,
    label: 'Станочник деревообрабатывающих станков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4692,
    label: 'Станочник жестяно-баночного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4693,
    label: 'Станочник клеенаносящего станка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4694,
    label: 'Станочник кромкофуговального станка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4695,
    label: 'Станочник навивочного станка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4696,
    label: 'Станочник на механической обработке электродной продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4697,
    label: 'Станочник на обработке твердосплавной продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4698,
    label: 'Станочник по изготовлению гнутой мебели',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4699,
    label: 'Станочник по обработке деталей из взрывчатых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4700,
    label: 'Станочник по обработке керамики',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4701,
    label: 'Станочник-распиловщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4702,
    label: 'Станочник ребросклеивающего станка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4703,
    label: 'Станочник специальных деревообрабатывающих станков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 4704,
    label: 'Станочник специальных металлообрабатывающих станков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 4705,
    label: 'Станочник усовочного станка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4706,
    label: 'Станочник широкого профиля',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4707,
    label: 'Станочник шпалорезного станка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4708,
    label: 'Станционный рабочий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4709,
    label: 'Старший врач станции (отделения) скорой медицинской помощи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные и противоаэрозольные с дополнительной защитой от газов и паров средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4710,
    label: 'Старший машинист котельного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 4711,
    label: 'Старший машинист котлотурбинного цеха',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4712,
    label: 'Старший машинист турбинного отделения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от вибрации',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 4713,
    label: 'Старший машинист энергоблоков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4714,
    label: 'Стволовой (подземный)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 4715,
    label: 'Стекловар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4716,
    label: 'Стеклографист (ротаторщик)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4717,
    label: 'Стеклодув',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от конвективной теплоты и теплового излучения',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4718,
    label: 'Стеклопротирщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4719,
    label: 'Стекольщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4720,
    label: 'Стендовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4721,
    label: 'Стереотипер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4722,
    label: 'Стерженщик машинной формовки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4723,
    label: 'Стерженщик ручной формовки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4724,
    label: 'Стерилизаторщик ваты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4725,
    label: 'Стерилизаторщик материалов и препаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4726,
    label: 'Стерилизаторщик питательных сред',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4727,
    label: 'Столяр',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4728,
    label: 'Столяр по изготовлению декораций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4729,
    label:
      'Столяр по изготовлению и ремонту деталей и узлов музыкальных инструментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4730,
    label: 'Столяр строительный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4731,
    label: 'Столяр судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4732,
    label: 'Сторож (вахтер)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4733,
    label: 'Стрелок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4734,
    label: 'Стригальщик ворса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4735,
    label: 'Строгаль кожевенно-мехового сырья и полуфабрикатов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4736,
    label: 'Строгальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4737,
    label: 'Строгальщик пластмасс',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4738,
    label: 'Стропальщик целлулоидных блоков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 4739,
    label: 'Стропальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4740,
    label: 'Струнонавивальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4741,
    label: 'Струнщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4742,
    label: 'Стыковщик полос',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4743,
    label: 'Стыковщик резиновых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4744,
    label: 'Стыковщик текстиля на прессе',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4745,
    label:
      'Судебный эксперт (эксперт-биохимик, эксперт-генетик, эксперт-химик)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '2 пары на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные средства индивидуальной защиты органов дыхания с лицевой частью из изолирующих материалов',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4746,
    label: 'Судоводитель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 4747,
    label: 'Судокорпусник-ремонтник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4748,
    label: 'Судопропускник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4749,
    label: 'Сукновал',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4750,
    label: 'Сульфатчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4751,
    label: 'Сушильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4752,
    label: 'Сушильщик абразивных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4753,
    label: 'Сушильщик асбестоцементных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4754,
    label: 'Сушильщик бумаги, картона, фибры и изделий из них',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4755,
    label: 'Сушильщик бумагоделательной (картоно-делательной) машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4756,
    label: 'Сушильщик вакуум-формующей машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4757,
    label: 'Сушильщик ванилина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4758,
    label: 'Сушильщик девулканизата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4759,
    label: 'Сушильщик деталей и игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4760,
    label: 'Сушильщик деталей и приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4761,
    label: 'Сушильщик длиннотрубчатых макарон',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4762,
    label: 'Сушильщик дощечек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4763,
    label: 'Сушильщик дрожжей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4764,
    label: 'Сушильщик жести',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4765,
    label: 'Сушильщик заготовок и художественных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4766,
    label: 'Сушильщик заполнителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4767,
    label: 'Сушильщик (заправщик)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4768,
    label: 'Сушильщик изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4769,
    label: 'Сушильщик клеильно-сушильной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4770,
    label: 'Сушильщик компонентов обмазки и флюсов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4771,
    label: 'Сушильщик линолеума',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4772,
    label: 'Сушильщик лубяного сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4773,
    label: 'Сушильщик махорочной крошки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4774,
    label: 'Сушильщик машины длинноволокнистой бумаги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4775,
    label: 'Сушильщик отходов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4776,
    label: 'Сушильщик пакетов конденсаторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4777,
    label: 'Сушильщик пергаментной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4778,
    label: 'Сушильщик перопухового сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4779,
    label: 'Сушильщик пищевой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4780,
    label: 'Сушильщик пленки бутафоль',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4781,
    label: 'Сушильщик посыпочных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4782,
    label: 'Сушильщик пресспата',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4783,
    label: 'Сушильщик растений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4784,
    label: 'Сушильщик ровничной машины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4785,
    label: 'Сушильщик стеклоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4786,
    label: 'Сушильщик стержней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4787,
    label: 'Сушильщик стержней, форм и формовочных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4788,
    label: 'Сушильщик сырья и материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4789,
    label: 'Сушильщик сырья, полуфабрикатов и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4790,
    label: 'Сушильщик теплоизоляционных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4791,
    label: 'Сушильщик фарфоровых, фаянсовых, керамических изделий и сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4792,
    label: 'Сушильщик шкурок кроликов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4793,
    label: 'Сушильщик шлифзерна, шлифпорошков и шихтовых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4794,
    label: 'Сушильщик шпона и фанеры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4795,
    label: 'Сушильщик электродов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4796,
    label: 'Сушильщик элементного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4797,
    label: 'Счетчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4798,
    label: 'Счетчик меры и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4799,
    label: 'Сшивальщик металлосеток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4800,
    label: 'Сшивальщик-проклейщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4801,
    label: 'Съемщик байки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4802,
    label: 'Съемщик брикетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4803,
    label: 'Съемщик волокна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4804,
    label: 'Съемщик горячих изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4805,
    label: 'Съемщик диапозитивных фильмов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4806,
    label: 'Съемщик изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4807,
    label: 'Съемщик клея',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4808,
    label: 'Съемщик лент скоростемеров локомотивов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4809,
    label: 'Съемщик мультипликационных проб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4810,
    label: 'Съемщик оболочек с кабельных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4811,
    label: 'Съемщик обуви с колодок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4812,
    label: 'Съемщик оптических характеристик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4813,
    label: 'Съемщик политого стекла и фотопластинок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4814,
    label: 'Съемщик-раскройщик металлосеток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4815,
    label: 'Съемщик резиновых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4816,
    label: 'Съемщик свинцовой оболочки с рукавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4817,
    label: 'Съемщик стекла и стеклоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4818,
    label: 'Съемщик стеклопластиковых и стекловолокнистых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4819,
    label: 'Съемщик суровья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4820,
    label: 'Съемщик теплоизоляционных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4821,
    label: 'Съемщик-укладчик асбестоцементных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4822,
    label: 'Съемщик-укладчик асфальтовых плиток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4823,
    label: 'Съемщик-укладчик в производстве стеновых и вяжущих материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4824,
    label: 'Съемщик-укладчик заготовок, массы и готовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4825,
    label: 'Съемщик-укладчик фарфоровых, фаянсовых и керамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4826,
    label: 'Съемщик целлюлозы, бумаги, картона и изделий из них',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4827,
    label: 'Съемщик шелка-сырца',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4828,
    label: 'Сыродел',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4829,
    label: 'Сыродел-мастер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4830,
    label: 'Сыродел-мастер по созреванию сыров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4831,
    label: 'Сыродел по созреванию сыров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4832,
    label: 'Сыросол',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4833,
    label: 'Табаковод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4834,
    label: 'Таблетировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4835,
    label: 'Такелажник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 4836,
    label: 'Такелажник на монтаже',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 4837,
    label: 'Такелажник судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 4838,
    label: 'Таксидермист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4839,
    label: 'Талькировщик листов и лент',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4840,
    label: 'Тальман',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4841,
    label: 'Телеграфист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4842,
    label: 'Телефонист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4843,
    label: 'Темперировщик жировой основы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4844,
    label: 'Термист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4845,
    label: 'Термист купроксных и селеновых выпрямителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4846,
    label: 'Термист на установках ТВЧ',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4847,
    label: 'Термист нафталиновых печей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4848,
    label: 'Термист по обработке слюды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4849,
    label: 'Термист проката и труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4850,
    label: 'Термист холодом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4851,
    label: 'Термообработчик древесно-волокнистых плит',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4852,
    label: 'Термообработчик проводов и кабелей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4853,
    label: 'Термоотделочник меховых шкурок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4854,
    label: 'Термоотделочник пряжи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4855,
    label: 'Термоотделочник текстильно-галантерейных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4856,
    label: 'Термоотделочник швейных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4857,
    label: 'Термоотделочник щетины и волоса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4858,
    label: 'Термопечатник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4859,
    label: 'Термопластикаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4860,
    label: 'Терморезчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4861,
    label: 'Термостатчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4862,
    label: 'Термоусадчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4863,
    label: 'Тесемщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4864,
    label: 'Тестовод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4865,
    label: 'Тесчик спецсортиментов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4866,
    label: 'Техник по добыче нефти и газа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от воздействия статического электричества и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4867,
    label: 'Техник по подготовке и транспортировке нефти и газа',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от воздействия статического электричества и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4868,
    label: 'Техник по эксплуатации нефтепроводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от воздействия статического электричества и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4869,
    label: 'Техник по эксплуатации оборудования газовых объектов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от воздействия статического электричества и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4870,
    label: 'Тиснильщик рисунка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4871,
    label: 'Ткач',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4872,
    label: 'Токарь',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4873,
    label: 'Токарь-затыловщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4874,
    label: 'Токарь-карусельщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4875,
    label: 'Токарь по камню',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4876,
    label: 'Токарь по обработке радиоактивных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4877,
    label: 'Токарь-полуавтоматчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4878,
    label: 'Токарь по обработке абразивных изделий',
    SIZ: [
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нетоксичной пыли',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4879,
    label: 'Токарь по обработке асбестоцементных труб и муфт',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4880,
    label: 'Токарь-расточник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4881,
    label: 'Токарь-револьверщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: ':',
        norm: ' ',
      },
    ],
  },
  {
    profId: 4882,
    label: 'Тоннельный рабочий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 4883,
    label: 'Тоннельщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 4884,
    label: 'Тоннельщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4885,
    label: 'Тоннельщик-моторист скипового подъемника',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4886,
    label: 'Торфорабочий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4887,
    label: 'Точильщик кардной гарнитуры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4888,
    label: 'Точильщик стригальных ножей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4889,
    label: 'Точильщик шляпок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4890,
    label: 'Травильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4891,
    label: 'Травильщик валов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4892,
    label: 'Травильщик клише',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4893,
    label: 'Травильщик купроксных выпрямительных элементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4894,
    label: 'Травильщик прецизионного травления',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4895,
    label: 'Травильщик радиокерамики',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4896,
    label: 'Травильщик синтетических материалов на тканевой основе',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4897,
    label: 'Травильщик стекла плавиковой кислотой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4898,
    label: 'Травильщик фарфоровых и фаянсовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4899,
    label: 'Травильщик фольги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4900,
    label: 'Травильщик фольги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4901,
    label: 'Травильщик форм глубокой печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4902,
    label: 'Тракторист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нефти и нефтепродуктов',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4903,
    label: 'Тракторист по подготовке лесосек, трелевке и вывозке леса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4904,
    label: 'Тракторист-машинист сельскохозяйственного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4905,
    label: 'Трамбовщик изоляционного материала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4906,
    label: 'Трамбовщик огнеприпасов из карборунда',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4907,
    label: 'Транспортерщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 4908,
    label: 'Транспортерщик горячего клинкера',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4909,
    label: 'Транспортировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4910,
    label: 'Транспортировщик в литейном производстве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4911,
    label: 'Транспортировщик шихты',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4912,
    label: 'Трафаретчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4913,
    label: 'Трелевщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4914,
    label: 'Тренер лошадей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4915,
    label: 'Тренировщик радиодеталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4916,
    label: 'Трубник на солекомбайне',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4917,
    label: 'Трубогибщик судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4918,
    label: 'Трубоклад промышленных железобетонных труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4919,
    label: 'Трубоклад промышленных кирпичных труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4920,
    label: 'Труболитейщик-формовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 4921,
    label: 'Трубопроводчик линейный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4922,
    label: 'Трубопроводчик судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 4923,
    label: 'Трубопрокатчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4924,
    label: 'Тузлуковщик шкур',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4925,
    label: 'Туннелепрокладчик в бунтах хлопка-сырца',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4926,
    label: 'Тянульщик кож',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4927,
    label: 'Тянульщик по выработке стеклянных труб и дрота',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4928,
    label: 'Уборщик в литейных цехах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4929,
    label: 'Уборщик горячего металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины, контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4930,
    label: 'Уборщик мусоропроводов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от воды и растворов нетоксичных веществ',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4931,
    label: 'Уборщик отходов металлургического производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4932,
    label: 'Уборщик производственных и служебных помещений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и от скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4933,
    label: 'Уборщик территорий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4934,
    label: 'Уборщик шлака и оборотных материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от кратковременного воздействия открытого пламени',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4935,
    label: 'Увлажнитель кожаных, меховых деталей и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4936,
    label: 'Увлажнительщик бумаги и картона',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4937,
    label: 'Увлажняльщик кожевенных картонов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4938,
    label: 'Увлажняльщик махорочного сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4939,
    label: 'Увлажняльщик сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4940,
    label: 'Увлажняльщик табачного сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4941,
    label: 'Укладчик-выбиральщик мокрого товара и пряжи вручную',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4942,
    label: 'Укладчик деталей и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4943,
    label: 'Укладчик диапозитивных фильмов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4944,
    label: 'Укладчик-заливщик продуктов консервирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4945,
    label: 'Укладчик изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4946,
    label: 'Укладчик пиломатериалов, деталей и изделий из древесины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4947,
    label: 'Укладчик продукции медицинского назначения',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4948,
    label: 'Укладчик проката',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4949,
    label: 'Укладчик синели',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4950,
    label: 'Укладчик стеклонити в изделия',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4951,
    label: 'Укладчик сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4952,
    label: 'Укладчик-упаковщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4953,
    label: 'Укладчик хлебобулочных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4954,
    label: 'Униформист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4955,
    label: 'Упаковщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4956,
    label: 'Упаковщик в производстве лития',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4957,
    label: 'Упаковщик кип',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4958,
    label: 'Упаковщик цемента',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4959,
    label: 'Упаковщик-цементировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4960,
    label: 'Упаковщик электродов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4961,
    label: 'Уплотнитель припоя',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4962,
    label: 'Упрочнитель деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4963,
    label: 'Усреднильщик сырья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4964,
    label: 'Установщик алмазов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4965,
    label: 'Установщик бурильных замков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4966,
    label: 'Установщик декораций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4967,
    label: 'Установщик изделий в эмалировании',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4968,
    label: 'Установщик катализаторных сеток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4969,
    label: 'Установщик ладовых пластин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4970,
    label: 'Установщик прядильных блоков и гарнитуры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4971,
    label: 'Установщик стеклоплавильных сосудов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4972,
    label: 'Установщик фильерных пластин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4973,
    label: 'Установщик художественных произведений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4974,
    label: 'Установщик цвета и света',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4975,
    label:
      'Учетчик, техник (выполняющие работы по учету товарно-материальных ценностей)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4976,
    label: 'Фанеровщик художественных изделий из дерева',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4977,
    label: 'Фаршировщик овощей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4978,
    label: 'Фасовщик пучков щетины и волоса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4979,
    label: 'Фацетчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4980,
    label: 'Фельдшер скорой медицинской помощи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4981,
    label: 'Фельдшер-водитель скорой медицинской помощи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4982,
    label: 'Фельдшер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Халат для защиты от общих производственных загрязнений',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений, механических воздействий (истирания) и скольжения',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4983,
    label: 'Фельдъегерь',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4984,
    label: 'Фенольщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4985,
    label: 'Ферментаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4986,
    label: 'Ферментировщик чая',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4987,
    label: 'Фибровщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4988,
    label: 'Фидерщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4989,
    label: 'Фиксаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4990,
    label: 'Филигранщик бумаги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4991,
    label: 'Фильерщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4992,
    label: 'Фильмопроверщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4993,
    label: 'Фильмотекарь',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4994,
    label: 'Фильтровальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4995,
    label: 'Фильтрпрессовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4996,
    label: 'Флипперовщик бортовых колец',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 4997,
    label: 'Флотатор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4998,
    label: 'Флотаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 4999,
    label: 'Флюсовар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5000,
    label: 'Флюсовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5001,
    label: 'Фонотекарь',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5002,
    label: 'Форматор скульптурного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5003,
    label: 'Формировщик плотов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5004,
    label: 'Формировщик трикотажных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5005,
    label: 'Формовщик абразивных изделий на керамической связке',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5006,
    label:
      'Формовщик абразивных изделий на бакелитовой, вулканитовой и эпоксидной связках',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5007,
    label: 'Формовщик анодов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5008,
    label: 'Формовщик асбестоцементных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5009,
    label: 'Формовщик асфальтовых плиток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5010,
    label: 'Формовщик блоков мипоры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5011,
    label: 'Формовщик брикетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5012,
    label: 'Формовщик буртов фаолитовых труб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5013,
    label: 'Формовщик на производстве стеновых и вяжущих материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5014,
    label: 'Формовщик вспененного полистирола',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5015,
    label: 'Формовщик головных уборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5016,
    label: 'Формовщик деталей из стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от механических воздействий (порезов)',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (порезов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5017,
    label: 'Формовщик деталей и игрушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5018,
    label: 'Формовщик деталей и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5019,
    label: 'Формовщик железобетонных изделий и конструкций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5020,
    label: 'Формовщик изделий из вспенивающихся материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5021,
    label: 'Формовщик изделий из поропластов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5022,
    label: 'Формовщик изделий строительной керамики',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5023,
    label: 'Формовщик искусственных зубов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5024,
    label: 'Формовщик камнелитейного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5025,
    label: 'Формовщик капселей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5026,
    label: 'Формовщик колбасных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5027,
    label: 'Формовщик машинной формовки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5028,
    label: 'Формовщик медицинских препаратов, полуфабрикатов и изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5029,
    label: 'Формовщик огнеупорных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 5030,
    label: 'Формовщик пакетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5031,
    label: 'Формовщик пенальной косметики',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5032,
    label: 'Формовщик по выплавляемым моделям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5033,
    label: 'Формовщик покрышек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5034,
    label: 'Формовщик радиокерамики',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5035,
    label: 'Формовщик разделительных и декоративных слоев',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5036,
    label: 'Формовщик ртутных выпрямителей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5037,
    label: 'Формовщик ручной формовки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5038,
    label: 'Формовщик селеновых элементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5039,
    label: 'Формовщик стеклопластиковых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5040,
    label: 'Формовщик сыра',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5041,
    label: 'Формовщик текстильных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5042,
    label: 'Формовщик теплоизоляционных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5043,
    label: 'Формовщик теста',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5044,
    label: 'Формовщик фарфоровых и фаянсовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5045,
    label: 'Формовщик фильтр-пластин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5046,
    label: 'Формовщик фольги',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5047,
    label: 'Формовщик форм для наглядных пособий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5048,
    label: 'Формовщик художественного литья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5049,
    label: 'Формовщик электродной массы',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5050,
    label: 'Формовщик электрокерамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5051,
    label: 'Формодержатель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5052,
    label: 'Форсунщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5053,
    label: 'Фосфатировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5054,
    label: 'Фотограф прецизионной фотолитографии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5055,
    label: 'Фотограф фотомеханического гравирования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5056,
    label: 'Фотокерамик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5057,
    label: 'Фотокинолаборант газодинамической лаборатории',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5058,
    label: 'Фотолаборант',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5059,
    label: 'Фотолаборант-расчетчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5060,
    label: 'Фотооператор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5061,
    label: 'Фотопечатник на стекле',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5062,
    label: 'Фотопечатник по эмали',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5063,
    label: 'Фотоплазокопировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5064,
    label: 'Фототекарь',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5065,
    label: 'Фотоцинкограф',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5066,
    label: 'Фрезеровщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5067,
    label: 'Фрезеровщик асбестоцементных плит',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5068,
    label: 'Фрезеровщик камня',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5069,
    label: 'Фрезеровщик обуви',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5070,
    label: 'Фрезеровщик оптических деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5071,
    label: 'Фрезеровщик слитков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5072,
    label: 'Фрезеровщик специзделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5073,
    label: 'Фризерщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5074,
    label: 'Фриттовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5075,
    label: 'Фурнитурщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5076,
    label: 'Футеровщик-каменщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5077,
    label: 'Футеровщик (кислотоупорщик)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5078,
    label: 'Футеровщик-шамотчик на ремонте ванн',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5079,
    label: 'Футлярщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5080,
    label: 'Халвомес',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5081,
    label: 'Хальмовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5082,
    label: 'Хлораторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5083,
    label: 'Хлораторщик по приготовлению двухлористого олова',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5084,
    label: 'Хлораторщик электродной продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5085,
    label: 'Хлоропроводчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5086,
    label: 'Хлорщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5087,
    label: 'Хмелевод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5088,
    label: 'Холодильщик пищевой продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5089,
    label: 'Холодильщик резиновых смесей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5090,
    label: 'Хромолитограф',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5091,
    label: 'Художник декоративной росписи по металлу',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5092,
    label: 'Художник миниатюрной живописи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5093,
    label: 'Художник росписи по дереву',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5094,
    label: 'Художник росписи по ткани',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5095,
    label: 'Художник росписи по эмали',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5096,
    label: 'Художник по костюму',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5097,
    label: 'Цветовод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5098,
    label: 'Цветочница',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5099,
    label: 'Цементатор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5100,
    label: 'Цементаторщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5101,
    label: 'Цементаторщик гидромедьустановки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5102,
    label: 'Центрировщик оптических деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5103,
    label: 'Центрифуговщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5104,
    label: 'Центровщик стеклянных колб',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5105,
    label: 'Цепеизготовитель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от теплового излучения',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5106,
    label: 'Цоколевщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5107,
    label: 'Чабан',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5108,
    label: 'Чаевод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5109,
    label: 'Часовщик по ремонту механических часов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5110,
    label: 'Часовщик по ремонту электронных и кварцевых часов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5111,
    label: 'Чеканщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5112,
    label: 'Чеканщик скульптурного производства',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5113,
    label: 'Чеканщик художественных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5114,
    label: 'Чернильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5115,
    label: 'Чернильщик ювелирных и художественных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5116,
    label: 'Чехловщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5117,
    label: 'Чистильщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5118,
    label: 'Чистильщик абразивных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5119,
    label: 'Чистильщик вентиляционных установок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5120,
    label: 'Чистильщик выпарных аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5121,
    label: 'Чистильщик дымоходов, боровов и топок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5122,
    label: 'Чистильщик изделий, полуфабрикатов и материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5123,
    label: 'Чистильщик канализационных тоннелей и каналов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и раствора нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 5124,
    label: 'Чистильщик коконного сдира',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5125,
    label: 'Чистильщик лица голья',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5126,
    label: 'Чистильщик металла, отливок, изделий и деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5127,
    label: 'Чистильщик меховых шкурок бензином',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5128,
    label: 'Чистильщик на очистке шламовых бассейнов и болтушек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5129,
    label: 'Чистильщик оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нетоксичной пыли',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5130,
    label: 'Чистильщик оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от нетоксичной пыли',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5131,
    label: 'Чистильщик обуви',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5132,
    label: 'Чистильщик оптики',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5133,
    label: 'Чистильщик оснастки и приспособлений',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5134,
    label: 'Чистильщик полимеризационных стаканов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5135,
    label: 'Чистильщик по очистке пылевых камер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5136,
    label: 'Чистильщик продукции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5137,
    label: 'Чистильщик ткани, изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5138,
    label: 'Чистильщик-точильщик чесальных аппаратов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5139,
    label: 'Чистильщик ферросплавов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5140,
    label: 'Чистильщик шпуль',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5141,
    label: 'Чистильщик электроугольных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5142,
    label: 'Чокеровщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5143,
    label: 'Шаблонщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5144,
    label: 'Шабровщик цветных металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5145,
    label: 'Швея',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5146,
    label: 'Швея (в сырейно-красильных и скорняжных цехах)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5147,
    label: 'Шевинговальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5148,
    label: 'Шелковод',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5149,
    label: 'Шелкограф',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5150,
    label: 'Шероховщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5151,
    label: 'Шихтовар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты, теплового излучения, искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины, контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5152,
    label: 'Шихтовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), кратковременного воздействия открытого пламени',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5153,
    label: 'Шихтовщик в алмазном производстве',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5154,
    label: 'Шихтовщик в производстве абразивов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания) и нетоксичной пыли',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5155,
    label: 'Шкипер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5156,
    label: 'Шлаковщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), конвективной теплоты и теплового излучения',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины, контакта с нагретыми поверхностями',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины, контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5157,
    label: 'Шламовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5158,
    label: 'Шламовщик-бассейнщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от грубодисперсных аэрозолей (пыли)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5159,
    label: 'Шламовщик электролитных ванн',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5160,
    label: 'Шлифовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5161,
    label: 'Шлифовщик алмазов и сверхтвердых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5162,
    label: 'Шлифовщик асбестоцементных и асбестосилитовых плит',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5163,
    label: 'Шлифовщик брикетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5164,
    label: 'Шлифовщик бумаги, картона и фибры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5165,
    label: 'Шлифовщик водорастворимых кристаллов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5166,
    label: 'Шлифовщик горных пород',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5167,
    label: 'Шлифовщик изделий из твердых сплавов и тугоплавких металлов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5168,
    label: 'Шлифовщик изделий, полуфабрикатов и материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5169,
    label: 'Шлифовщик изделий строительной керамики',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5170,
    label: 'Шлифовщик изделий электронной техники',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5171,
    label: 'Шлифовщик искусственной кожи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5172,
    label: 'Шлифовщик камней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5173,
    label: 'Шлифовщик керамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5174,
    label: 'Шлифовщик литоофсетных форм',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5175,
    label: 'Шлифовщик медицинских изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5176,
    label: 'Шлифовщик металлооптических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5177,
    label: 'Шлифовщик микалекса',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5178,
    label: 'Шлифовщик оптических деталей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5179,
    label: 'Шлифовщик печатных валов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5180,
    label: 'Шлифовщик по дереву',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5181,
    label: 'Шлифовщик подносов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5182,
    label: 'Шлифовщик-полировщик изделий из камня',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5183,
    label:
      'Шлифовщик-полировщик по прецизионной обработке полупроводниковых материалов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5184,
    label: 'Шлифовщик полотна',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5185,
    label: 'Шлифовщик пьезокварцевых пластин и кристаллов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5186,
    label: 'Шлифовщик-резчик огнеупорных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5187,
    label: 'Шлифовщик стекла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5188,
    label: 'Шлифовщик стеклоизделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5189,
    label: 'Шлифовщик стержней',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5190,
    label: 'Шлифовщик фарфоровых и фаянсовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5191,
    label: 'Шлифовщик ферритовых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5192,
    label: 'Шлифовщик электрокерамических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5193,
    label: 'Шлифовщик электроугольных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5194,
    label: 'Шлифовщик янтаря',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5195,
    label: 'Шлиховщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5196,
    label: 'Шлихтовар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5197,
    label: 'Шлюзовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5198,
    label: 'Шоопировщик элементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5199,
    label: 'Шорник по изготовлению и отделке протезно-ортопедических изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5200,
    label: 'Шпаклевщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5201,
    label: 'Шпредингист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5202,
    label: 'Шприцовщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5203,
    label: 'Штабелевщик древесины',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5204,
    label: 'Штабелевщик электродов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от контакта с нагретой поверхностью',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5205,
    label: 'Штабелировщик металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, проколов, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5206,
    label: 'Штамповщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от вибрации',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, ударов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5207,
    label: 'Штамповщик ватных фильтров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5208,
    label: 'Штамповщик жидкого металла',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5209,
    label: 'Штамповщик изделий из слюды',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5210,
    label: 'Штамповщик корректирующих светофильтров',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5211,
    label: 'Штамповщик методом взрыва',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм от кратковременного воздействия открытого пламени и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5212,
    label: 'Штамповщик на падающих молотах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5213,
    label: 'Штамповщик ножек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5214,
    label: 'Штамповщик оболочек',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5215,
    label: 'Штамповщик пробковых изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5216,
    label: 'Штамповщик резиновой обуви',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5217,
    label: 'Штамповщик (текстильная галантерея)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5218,
    label: 'Штамповщик слюдяных изделий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
    ],
  },
  {
    profId: 5219,
    label: 'Штамповщик электроимпульсным методом',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5220,
    label: 'Штапелировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5221,
    label: 'Штемпелевщик карандашей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5222,
    label: 'Штемпелевщик этикеток',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5223,
    label: 'Штенгелевщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5224,
    label: 'Штукатур',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5225,
    label: 'Штурман-испытатель',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Куртка летная кожаная',
        norm: '1 шт. на 60 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Полукомбинезон летный кожаный',
        norm: '1 шт. на 60 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5226,
    label: 'Шуровщик топлива',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания со сжатым воздухом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 5227,
    label: 'Экипировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5228,
    label: 'Экранировщик жил, проводов и кабелей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5229,
    label: 'Эксгаустировщик банок с консервами',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5230,
    label: 'Экспедитор печати',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5231,
    label: 'Экстрагировщик пектина',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5232,
    label: 'Электрик-испытатель бортовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Куртка летная кожаная',
        norm: '1 шт. на 60 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Полукомбинезон летный кожаный',
        norm: '1 шт. на 60 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от общих производственных загрязнений и механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от общих производственных загрязнений',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от слепящей яркости',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5233,
    label: 'Электрик судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: ' ',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5234,
    label: 'Электрик участка',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5235,
    label: 'Электрик цеха',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5236,
    label: 'Электровакуумщик по напылению и насыщению',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5237,
    label: 'Электроверетенщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5238,
    label: 'Электровибронаплавщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5239,
    label: 'Электроворсовальщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5240,
    label: 'Электрогазосварщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5241,
    label: 'Электрогазосварщик-врезчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5242,
    label: 'Электродчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органа слуха',
        vid: 'Противошумные вкладыши (беруши) или противошумные наушники, включая активные, и их комплектующие',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5243,
    label: 'Электродчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5244,
    label: 'Электродчик безламельных аккумуляторов и элементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5245,
    label: 'Электродчик ламельных аккумуляторов и элементов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5246,
    label: 'Электрозаточник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5247,
    label: 'Электрокопировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5248,
    label: 'Электролизерщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5249,
    label: 'Электролизник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5250,
    label: 'Электролизник водных растворов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5251,
    label: 'Электролизник по снятию олова с жести',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5252,
    label: 'Электролизник расплавленных солей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от кратковременного воздействия открытого пламени, конвективной теплоты, теплового излучения, искр и брызг расплавленного металла и металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла и металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла и металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от конвективной теплоты, теплового излучения и кратковременного воздействия открытого пламени',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5253,
    label: 'Электролизник хлористого лития',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от растворов кислот и щелочей',
        norm: '12 шт. на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания), растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5254,
    label: 'Электромеханик по испытанию и ремонту электрооборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5255,
    label: 'Электромеханик по лифтам',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5256,
    label:
      'Электромеханик по обслуживанию светотехнического оборудования систем обеспечения полетов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5257,
    label:
      'Электромеханик по ремонту и обслуживанию медицинских оптических приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5258,
    label: 'Электромеханик по ремонту и обслуживанию медицинского оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5259,
    label:
      'Электромеханик по ремонту и обслуживанию медицинского рентгеновского оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5260,
    label:
      'Электромеханик по ремонту и обслуживанию наркозно-дыхательной аппаратуры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5261,
    label:
      'Электромеханик по ремонту и обслуживанию счетно-вычислительных машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5262,
    label:
      'Электромеханик по ремонту и обслуживанию электронной медицинской аппаратуры',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5263,
    label:
      'Электромеханик по средствам автоматики и приборам технологического оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5264,
    label: 'Электромеханик по торговому и холодильному оборудованию',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5265,
    label: 'Электромеханик почтового оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5266,
    label: 'Электромеханик судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5267,
    label: 'Электромонтажник блоков электронно-механических часов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5268,
    label: 'Электромонтажник-наладчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5269,
    label: 'Электромонтажник по аккумуляторным батареям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5270,
    label: 'Электромонтажник по вторичным цепям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5271,
    label: 'Электромонтажник по кабельным сетям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5272,
    label: 'Электромонтажник по освещению и осветительным сетям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5273,
    label: 'Электромонтажник по распределительным устройствами',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5274,
    label:
      'Электромонтажник по сигнализации, централизации и блокировке на железнодорожном транспорте и наземных линиях метрополитена',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5275,
    label: 'Электромонтажник по силовым сетям и электрооборудованию',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5276,
    label: 'Электромонтажник по электрическим машинам',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5277,
    label: 'Электромонтажник судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5278,
    label: 'Электромонтажник-схемщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5279,
    label: 'Электромонтер главного щита управления электростанции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5280,
    label: 'Электромонтер диспетчерского оборудования и телеавтоматики',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5281,
    label: 'Электромонтер канализационных сооружений связи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 5282,
    label: 'Электромонтер контактной сети',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Комплект для защиты от воздействия электрических полей промышленной частоты и поражения электрическим током наведенного напряжения',
        norm: '1 комп. на 12 мес',
      },
    ],
  },
  {
    profId: 5283,
    label: 'Электромонтер линейных сооружений телефонной связи и радиофикации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5284,
    label:
      'Электромонтер-линейщик по монтажу воздушных линий высокого напряжения и контактной сети',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Комплект для защиты от воздействия электрических полей промышленной частоты и поражения электрическим током наведенного напряжения',
        norm: '1 комп. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Комплект для защиты от воздействия электрических полей промышленной частоты в составе:',
        norm: '1 комп. на 18 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки утепленные',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки от пониженных температур',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 5285,
    label: 'Электромонтер оперативно-выездной бригады',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Комплект для защиты от воздействия электрических полей промышленной частоты в составе:',
        norm: '1 комп. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки утепленные',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки от пониженных температур',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 5286,
    label: 'Электромонтер охранно-пожарной сигнализации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5287,
    label: 'Электромонтер по испытаниям и измерениям',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Комплект для защиты от воздействия электрических полей промышленной частоты в составе:',
        norm: '1 комп. на 18 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки утепленные',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки от пониженных температур',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 5288,
    label: 'Электромонтер по надзору за трассами кабельных сетей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5289,
    label: 'Электромонтер по обслуживанию буровых',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5290,
    label: 'Электромонтер по обслуживанию гидроагрегатов машинного зала',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: ' ',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5291,
    label: 'Электромонтер по обслуживанию подстанции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Комплект для защиты от воздействия электрических полей промышленной частоты в составе:',
        norm: '1 комп. на 18 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки утепленные',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки от пониженных температур',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 5292,
    label: 'Электромонтер по обслуживанию преобразовательных устройств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5293,
    label: 'Электромонтер по обслуживанию электрооборудования электростанций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5294,
    label: 'Электромонтер по обслуживанию электроустановок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5295,
    label:
      'Электромонтер по оперативным переключениям в распределительных сетях',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой -',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Комплект для защиты от воздействия электрических полей промышленной частоты в составе:',
        norm: '1 комп. на 18 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки утепленные',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки от пониженных температур',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 5296,
    label: 'Электромонтер по ремонту аппаратуры, релейной защиты и автоматики',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5297,
    label: 'Электромонтер по ремонту воздушных линий электропередачи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Комплект для защиты от воздействия электрических полей промышленной частоты в составе:',
        norm: '1 комп. на 18 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки утепленные',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки от пониженных температур',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 5298,
    label: 'Электромонтер по ремонту вторичной коммутации и связи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5299,
    label: 'Электромонтер по ремонту и монтажу кабельных линий',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5300,
    label: 'Электромонтер по ремонту и обслуживанию электрооборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Комплект для защиты от воздействия электрических полей промышленной частоты в составе:',
        norm: '1 комп. на 18 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки утепленные',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки от пониженных температур',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 5301,
    label: 'Электромонтер по ремонту обмоток и изоляции электрооборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов) и термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5302,
    label: 'Электромонтер по эксплуатации распределительных сетей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5303,
    label: 'Электромонтер по эксплуатации электросчетчиков',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5304,
    label: 'Электромонтер по эскизированию трасс линий электропередачи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5305,
    label: 'Электромонтер приемопередающей станции спутниковой связи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5306,
    label: 'Электромонтер-релейщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5307,
    label:
      'Электромонтер по ремонту и обслуживанию аппаратуры и устройств связи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5308,
    label: 'Электромонтер станционного оборудования радиорелейных линий связи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5309,
    label: 'Электромонтер станционного оборудования радиофикации',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5310,
    label: 'Электромонтер станционного оборудования телеграфной связи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5311,
    label: 'Электромонтер станционного оборудования телефонной связи',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5312,
    label: 'Электромонтер станционного радиооборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5313,
    label: 'Электромонтер станционного телевизионного оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5314,
    label: 'Электромонтер тяговой подстанции',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Комплект для защиты от воздействия электрических полей промышленной частоты и поражения электрическим током наведенного напряжения',
        norm: '1 комп. на 12 мес',
      },
    ],
  },
  {
    profId: 5315,
    label:
      'Электромонтер по обслуживанию и ремонту устройств сигнализации, централизации и блокировки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от механических воздействий',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5316,
    label: 'Электрополировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от растворов кислот и щелочей',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5317,
    label: 'Электрорадиомонтажник судовой',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5318,
    label: 'Электрорадионавигатор',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5319,
    label: 'Электросварщик листов и лент',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5320,
    label: 'Электросварщик на автоматических и полуавтоматических машинах',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5321,
    label: 'Электросварщик ручной сварки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Фартук для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '2 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5322,
    label: 'Электросварщик труб на стане',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), искр и брызг расплавленного металла, металлической окалины',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от искр и брызг расплавленного металла, металлической окалины',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой от брызг расплавленного металла и горячих частиц',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5323,
    label: 'Электрослесарь-контактчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5324,
    label:
      'Электрослесарь-монтажник подземного горнопроходческого оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды и механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5325,
    label: 'Электрослесарь на проходке',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Жилет сигнальный повышенной видимости',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 5326,
    label: 'Электрослесарь подземный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм шахтерский',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от воды, механических воздействий (ударов, проколов, порезов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания, порезов, проколов)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор (подшлемник) для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска шахтерская (с креплением для головного светильника)',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Средства индивидуальной защиты органов дыхания с химически связанным кислородом (изолирующие дыхательные аппараты, изолирующие самоспасатели)',
        norm: 'до износа - дыхательные аппараты, одноразовое применение - самоспасатели',
      },
    ],
  },
  {
    profId: 5327,
    label:
      'Электрослесарь по обслуживанию автоматики и средств измерений электростанций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5328,
    label:
      'Электрослесарь по ремонту и обслуживанию автоматики и средств измерений электростанций и электросетей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5329,
    label: 'Электрослесарь по ремонту оборудования нефтебаз',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от воздействия статического электричества, механических воздействий (истирания), кратковременного воздействия открытого пламени, нефти и/или нефтепродуктов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), нефти и/или нефтепродуктов',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5330,
    label: 'Электрослесарь по ремонту оборудования распределительных устройств',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'При работах в зоне влияния электрического поля с напряженностью более 5 кВ/м дополнительно:',
        vid: ' ',
        norm: ' ',
      },
      {
        type: 'Комплект защитный',
        vid: 'Комплект для защиты от воздействия электрических полей промышленной частоты в составе:',
        norm: '1 комп. на 18 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующая куртка с накасником и капюшоном от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующий полукомбинезон/брюки от пониженных температур',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие перчатки утепленные',
        norm: '4 пары на 12 мес',
      },
      {
        type: 'Комплект защитный',
        vid: 'Экранирующие ботинки от пониженных температур',
        norm: '1 пара на 12 мес',
      },
    ],
  },
  {
    profId: 5331,
    label: 'Электрослесарь по ремонту электрических машин',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5332,
    label: 'Электрослесарь по ремонту электрооборудования электростанций',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от термических рисков электрической дуги',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное термостойкое',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'или',
        norm: ' ',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Белье специальное хлопчатобумажное',
        norm: '4 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов), термических рисков электрической дуги',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки термостойкие',
        norm: '6 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Подшлемник термостойкий',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Каска защитная от повышенных температур',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты лица',
        vid: 'Щиток защитный лицевой с термостойкой окантовкой',
        norm: '1 шт. на 24 мес',
      },
    ],
  },
  {
    profId: 5333,
    label: 'Электрослесарь (слесарь) дежурный и по ремонту оборудования',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воздействия статического электричества и механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5334,
    label: 'Электрослесарь строительный',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная диэлектрическая',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки специальные диэлектрические',
        norm: 'определяется документами изготовителя',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5335,
    label: 'Электросушильщик кабелей',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5336,
    label: 'Электрофотограф',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5337,
    label: 'Электрохимобработчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5338,
    label: 'Электроэрозионист',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5339,
    label: 'Эмалировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5340,
    label: 'Эмалировщик проволоки',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5341,
    label: 'Эмалировщик резисторов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5342,
    label: 'Эмальер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5343,
    label: 'Эмульсировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от растворов кислот и щелочей, в том числе с головным убором',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от растворов кислот и щелочей',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от химических факторов',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5344,
    label: 'Эмульсировщик прецизионной фотолитографии',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5345,
    label: 'Эмульсовар',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от капель и брызг жидкостей',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противогазовые, противогазоаэрозольные (комбинированные) средства индивидуальной защиты органов дыхания с изолирующей лицевой частью (полумаской, маской, четвертьмаской)',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5346,
    label: 'Эпилировщик меховых шкурок',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты органов дыхания',
        vid: 'Противоаэрозольные, противоаэрозольные с дополнительной защитой от паров и газов средства индивидуальной защиты органов дыхания с фильтрующей лицевой частью - фильтрующие полумаски',
        norm: 'до износа',
      },
    ],
  },
  {
    profId: 5347,
    label: 'Эфиромасличник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5348,
    label: 'Ювелир (ювелир-модельер)',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5349,
    label: 'Ювелир-браслетчик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5350,
    label: 'Ювелир-гравер',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5351,
    label: 'Ювелир-закрепщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5352,
    label: 'Ювелир-монтировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5353,
    label: 'Ювелир-филигранщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5354,
    label: 'Ювелир-цепочник',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5355,
    label: 'Юстировщик',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм сигнальный повышенной видимости для защиты от механических воздействий (истирания, порезов)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Одежда специальная защитная',
        vid: 'Пальто, полупальто, плащ для защиты от воды',
        norm: '1 шт. на 24 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (проколов, порезов, ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Нарукавники для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от воды и растворов нетоксичных веществ',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты глаз',
        vid: 'Очки защитные от механических воздействий, в том числе с покрытием от запотевания',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5356,
    label: 'Юстировщик деталей и приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (ударов)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
  {
    profId: 5357,
    label: 'Юстировщик оптических приборов',
    SIZ: [
      {
        type: 'Одежда специальная защитная',
        vid: 'Костюм для защиты от механических воздействий (истирания)',
        norm: '1 шт. на 12 мес',
      },
      {
        type: 'Средства защиты ног',
        vid: 'Обувь специальная для защиты от механических воздействий (истирания)',
        norm: '1 пара на 12 мес',
      },
      {
        type: 'Средства защиты рук',
        vid: 'Перчатки для защиты от механических воздействий (истирания)',
        norm: '12 пар на 12 мес',
      },
      {
        type: 'Средства защиты головы',
        vid: 'Головной убор для защиты от общих производственных загрязнений',
        norm: '1 шт. на 12 мес',
      },
    ],
  },
];

export default prof;
