const danget776 = [
  {
    ID: 1,
    label:
      'Наличие микроорганизмов-продуцентов, препаратов, содержащих живые клетки и споры микроорганизмов в окружающей среде: воздухе, воде, на поверхностях',
  },
  { ID: 1, label: 'Патогенные микроорганизмы' },
  {
    ID: '2',
    label:
      'Неприменение СИЗ или применение поврежденных СИЗ, не сертифицированных СИЗ, не соответствующих размерам СИЗ, СИЗ, не соответствующих выявленным опасностям, составу или уровню воздействия вредных факторов',
  },
  {
    ID: 3,
    label: 'Скользкие, обледенелые, зажиренные, мокрые опорные поверхности',
  },
  {
    ID: 3,
    label: 'Перепад высот, отсутствие ограждения на высоте свыше 5 м',
  },
  { ID: 4, label: 'Выполнение работ вблизи водоемов' },
  {
    ID: 4,
    label: 'Деятельность на палубе и за бортом судов, нефтяных платформ',
  },
  { ID: 4, label: 'Спасательные операции на воде и/или на льду.' },
  {
    ID: 4,
    label:
      'Выполнение работ вблизи технологических емкостей, наполненных водой или иными технологическими жидкостями',
  },
  {
    ID: 4,
    label:
      'Выполнение работ в момент естественного (природного) затопления шахты',
  },
  {
    ID: 4,
    label:
      'Выполнение работ в момент технологического (вынужденного) затопления шахты',
  },
  {
    ID: 4,
    label:
      'Выполнение работ в момент аварии, повлекшей за собой затопление шахты',
  },
  { ID: 5, label: 'Обрушение подземных конструкций при монтаже' },
  { ID: 5, label: 'Обрушение подземных конструкций при эксплуатации' },
  {
    ID: 5,
    label:
      'Естественные природные подземные толчки и колебания земной поверхности, наводнения, пожары',
  },
  { ID: 6, label: 'Обрушение наземных конструкций' },
  {
    ID: 6,
    label:
      'Естественные природные подземные толчки и колебания земной поверхности, наводнения, пожары',
  },
  { ID: '7', label: 'Транспортное средство, в том числе погрузчик' },
  { ID: '8', label: 'Подвижные части машин и механизмов' },
  { ID: 9, label: 'Вредные химические вещества в воздухе рабочей зоны' },
  { ID: 9, label: 'Воздействие на кожные покровы смазочных масел' },
  {
    ID: 9,
    label: 'Воздействие на кожные покровы обезжиривающих и чистящих веществ',
  },
  { ID: 9, label: 'Контакт с высокоопасными веществами' },
  { ID: 9, label: 'Образование токсичных паров при нагревании' },
  { ID: 9, label: 'Воздействие химических веществ на кожу' },
  { ID: 9, label: 'Воздействие химических веществ на глаза' },
  {
    ID: '10',
    label: 'Химические реакции веществ, приводящие к пожару и взрыву',
  },
  {
    ID: '11',
    label:
      'Недостаток кислорода в воздухе рабочей зоны в замкнутых технологических емкостях, из-за вытеснения его другими газами или жидкостями',
  },
  {
    ID: '12',
    label: 'Аэрозоли преимущественно фиброгенного действия (АПФД)',
  },
  {
    ID: 13,
    label: 'Материал, жидкость или газ, имеющие высокую температуру',
  },
  {
    ID: 13,
    label:
      'Энергия открытого пламени, выплесков металлов, искр и брызг расплавленного металла и металлической окалины',
  },
  {
    ID: 13,
    label:
      'Поверхности, имеющие высокую температуру (воздействие конвективной теплоты)',
  },
  { ID: 13, label: 'Прямое воздействие солнечных лучей' },
  {
    ID: '14',
    label: 'Охлажденная поверхность, охлажденная жидкость или газ',
  },
  {
    ID: '15',
    label:
      'Высокая влажность окружающей среды, в рабочей зоне, в том числе, связанная с климатом (воздействие влажности в виде тумана, росы, атмосферных осадков, конденсата, струй и капель жидкости)',
  },
  {
    ID: '16',
    label:
      'Высокая или низкая скорость движения воздуха, в том числе, связанная с климатом',
  },
  {
    ID: '17',
    label:
      'Повышенное барометрическое давление (при выполнении водолазных спусков и кессонных работ, при подводном плавании в аквалангах, при лечении сжатым воздухом или кислородом в камерах повышенного давления и барокамерах, предназначенных для проведения хирургич',
  },
  {
    ID: '18',
    label:
      'Пониженное барометрическое давление (пребывание на высоте в условиях пониженного барометрического давления и обусловленного этим уменьшения парциального давления газов, входящих в состав воздуха, в том числе кислорода)',
  },
  { ID: '19', label: 'Резкое изменение барометрического давления' },
  {
    ID: 20,
    label:
      'Повышенный уровень шума и другие неблагоприятные характеристики шума',
  },
  {
    ID: 20,
    label:
      'Повышенный уровень ультразвуковых колебаний (воздушный и контактный ультразвук)',
  },
  {
    ID: 21,
    label:
      'Воздействие локальной вибрации при использовании ручных механизмов и инструментов',
  },
  {
    ID: 21,
    label:
      'Воздействие общей вибрации (колебания всего тела, передающиеся с рабочего места).',
  },
  {
    ID: '22',
    label:
      'Груз, инструмент или предмет, перемещаемый или поднимаемый, в том числе на высоту',
  },
  {
    ID: '23',
    label:
      'Физические перегрузки при чрезмерных физических усилиях при подъеме предметов и деталей, при перемещении предметов и деталей, при стереотипных рабочих движениях и при статических нагрузках, при неудобной рабочей позе, в том числе при наклонах корпуса тела',
  },
  {
    ID: 24,
    label:
      'Монотонность труда при выполнении однообразных действий или непрерывной и устойчивой концентрации внимания в условиях дефицита сенсорных нагрузок',
  },
  {
    ID: 24,
    label:
      'Новые, непривычные виды труда, связанные с отсутствием информации, умений для выполнения новым видам работы',
  },
  {
    ID: 24,
    label:
      'Напряженный психологический климат в коллективе, стрессовые ситуации, в том числе вследствие выполнения работ вне места постоянного проживания и отсутствия иных внешних контактов',
  },
  {
    ID: 24,
    label:
      'Диспетчеризация процессов, связанная с длительной концентрацией внимания',
  },
  { ID: '25', label: 'Дикие или домашние животные' },
  {
    ID: '26',
    label:
      'Наличие на рабочем месте паукообразных и насекомых, включая кровососущих',
  },
  { ID: 27, label: 'Электрический ток' },
  { ID: 27, label: 'Шаговое напряжение' },
  {
    ID: 27,
    label:
      'Искры, возникающие вследствие накопления статического электричества, в том числе при работе во взрыво-пожароопасной среде',
  },
  {
    ID: 27,
    label:
      'Наведенное напряжение в отключенной электрической цепи (электромагнитное воздействие параллельной воздушной электрической линии или электричества, циркулирующего в контактной сети)',
  },
  {
    ID: '28',
    label: 'Насилие от враждебно-настроенных работников/третьих лиц',
  },
];
export default danget776;
