const conversion = [
  {
    danger776:
      'Наличие микроорганизмов-продуцентов, препаратов, содержащих живые клетки и споры микроорганизмов в окружающей среде: воздухе, воде, на поверхностях',
    IdDangerEvent776: '1.1',
    IdDanger767: '8.1',
    danger767:
      'Наличие микроорганизмов-продуцентов, препаратов, содержащих живые клетки и споры микроорганизмов в окружающей среде: воздухе, воде, на поверхностях',
    IdDangerEvent767: '8.1.1',
    dangerEvent767:
      'Заражение работника вследствие воздействия микроорганизмов-продуцентов, препаратов, содержащих живые клетки и споры микроорганизмов в воздухе, воде, на поверхностях',
  },
  {
    danger776: 'Патогенные микроорганизмы',
    IdDangerEvent776: '1.2',
    IdDanger767: '8.1',
    danger767: 'Патогенные микроорганизмы',
    IdDangerEvent767: '8.2.1',
    dangerEvent767:
      'Заболевание работника, связанное с воздействием патогенных микроорганизмов',
  },
  {
    danger776: 'Патогенные микроорганизмы',
    IdDangerEvent776: '1.2',
    IdDanger767: '6.2',
    danger767: 'Высокоопасные вещества',
    IdDangerEvent767: '6.2.1',
    dangerEvent767:
      'Химические ожоги кожи при контакте с опасными веществами и/или поражение слизистых при контакте с опасными веществами и/или отравление организма при контакте с высокоопасными веществами и/или вдыхание высокоопасных веществ и/или контакт с патогенными микроорганизмами',
  },
  {
    danger776: 'Скользкие, обледенелые, зажиренные, мокрые опорные поверхности',
    IdDangerEvent776: '3.1',
    IdDanger767: '1.1',
    danger767: 'Скользкие, обледенелые, зажиренные, мокрые поверхности',
    IdDangerEvent767: '1.1.1',
    dangerEvent767:
      'Падение работника из-за потери равновесия при поскальзывании при передвижении',
  },
  {
    danger776: 'Скользкие, обледенелые, зажиренные, мокрые опорные поверхности',
    IdDangerEvent776: '3.1',
    IdDanger767: '1.8',
    danger767:
      'Предметы и элементы конструкции, расположенные на путях следования, в том числе из-за неправильной организации рабочего места',
    IdDangerEvent767: '1.8.1',
    dangerEvent767:
      'Столкновение работника с неподвижным предметом или элементом конструкции, оказавшимся на пути следования',
  },
  {
    danger776: 'Перепад высот, отсутствие ограждения на высоте свыше 5 м',
    IdDangerEvent776: '3.2',
    IdDanger767: '1.2',
    danger767: 'Перепад высот, отсутствие ограждения на высоте',
    IdDangerEvent767: '1.2.1',
    dangerEvent767:
      'Падение работника с высоты, в том числе при выполнении альпинистских работ',
  },
  {
    danger776: 'Перепад высот, отсутствие ограждения на высоте свыше 5 м',
    IdDangerEvent776: '3.3',
    IdDanger767: '1.2',
    danger767: 'Перепад высот, отсутствие ограждения на высоте',
    IdDangerEvent767: '1.2.1',
    dangerEvent767:
      'Падение работника с высоты, в том числе при выполнении альпинистских работ',
  },
  {
    danger776: 'Транспортное средство, в том числе погрузчик',
    IdDangerEvent776: '7.1',
    IdDanger767: '18.1',
    danger767: 'Транспортное средство, в том числе погрузчик',
    IdDangerEvent767: '18.1.1',
    dangerEvent767:
      'Механические травмы работника вследствие наезда транспортного средства',
  },
  {
    danger776: 'Транспортное средство, в том числе погрузчик',
    IdDangerEvent776: '7.2',
    IdDanger767: '18.1',
    danger767: 'Транспортное средство, в том числе погрузчик',
    IdDangerEvent767: '18.1.1',
    dangerEvent767:
      'Механические травмы работника вследствие наезда транспортного средства',
  },
  {
    danger776: 'Транспортное средство, в том числе погрузчик',
    IdDangerEvent776: '7.3',
    IdDanger767: '18.1',
    danger767: 'Транспортное средство, в том числе погрузчик',
    IdDangerEvent767: '18.1.2',
    dangerEvent767:
      'Механические травмы работника вследствие, раздавливания между двумя сближающимися транспортными средствами',
  },
  {
    danger776: 'Подвижные части машин и механизмов',
    IdDangerEvent776: '8.1',
    IdDanger767: '1.10',
    danger767:
      'Неподвижная или подвижная, в том числе вращающаяся колющая поверхность (острие)',
    IdDangerEvent767: '1.10.1',
    dangerEvent767:
      'Укол или прокол мягких тканей работника, из-за натыкания на неподвижную колющую поверхность (острие), в том числе штыри, арматуру, углы, анкерные устройства и другие, а также в результате воздействия движущихся колющих частей механизмов и машин',
  },
  {
    danger776: 'Подвижные части машин и механизмов',
    IdDangerEvent776: '8.1',
    IdDanger767: '1.12',
    danger767: 'Подвижные части машин и механизмов',
    IdDangerEvent767: '1.12.1',
    dangerEvent767:
      'Затягивание в подвижные части машин и механизмов одежды, волос и отдельных частей тела работника',
  },
  {
    danger776: 'Подвижные части машин и механизмов',
    IdDangerEvent776: '8.1',
    IdDanger767: '1.14',
    danger767:
      'Абразивные материалы, в том числе необработанная древесина (в том числе вертикальные и горизонтальные поверхности)',
    IdDangerEvent767: '1.14.1',
    dangerEvent767:
      'Воздействие движущегося и неподвижного абразивного элемента, необработанной древесины на кожу работника, проникновение заноз под кожу',
  },
  {
    danger776: 'Подвижные части машин и механизмов',
    IdDangerEvent776: '8.1',
    IdDanger767: '1.16',
    danger767: 'Движущиеся режущие части механизмов, машин',
    IdDangerEvent767: '1.16.1',
    dangerEvent767:
      'Порез мягких тканей или ампутация отдельных частей тела работника в результате воздействия движущихся режущих частей механизмов, машин',
  },
  {
    danger776: 'Подвижные части машин и механизмов',
    IdDangerEvent776: '8.1',
    IdDanger767: '1.18',
    danger767: 'Дисковые ножи, дисковые пилы и другое (кроме ножей поварских)',
    IdDangerEvent767: '1.18.1',
    dangerEvent767:
      'Порез мягких тканей или ампутация отдельных частей тела работника в результате воздействия острого режущего инструмента (дисковые ножи, дисковые пилы и другое (кроме ножей поварских)',
  },
  {
    danger776: 'Подвижные части машин и механизмов',
    IdDangerEvent776: '8.1',
    IdDanger767: '1.5',
    danger767: 'Вращающиеся или движущие детали оборудования или инструменты',
    IdDangerEvent767: '1.5.1',
    dangerEvent767:
      'Удар работника инструментом при неправильной эксплуатации, удар вращающимися или движущимися частями оборудования',
  },
  {
    danger776: 'Вредные химические вещества в воздухе рабочей зоны',
    IdDangerEvent776: '9.1',
    IdDanger767: '6.2',
    danger767: 'Высокоопасные вещества',
    IdDangerEvent767: '6.2.1',
    dangerEvent767:
      'Химические ожоги кожи при контакте с опасными веществами и/или поражение слизистых при контакте с опасными веществами и/или отравление организма при контакте с высокоопасными веществами и/или вдыхание высокоопасных веществ и/или контакт с патогенными микроорганизмами',
  },
  {
    danger776: 'Вредные химические вещества в воздухе рабочей зоны',
    IdDangerEvent776: '9.1',
    IdDanger767: '6.4',
    danger767:
      'Повышенная концентрация паров вредных жидкостей, газов в воздухе рабочей зоны (а также пыль, туман, дым)',
    IdDangerEvent767: '6.4.1',
    dangerEvent767:
      'Вдыхание работником паров вредных жидкостей, газов (а также пыли, тумана, дыма); поражение легких от вдыхания вредных паров или газов; поражение слизистых оболочек дыхательных путей от вдыхания вредных паров или газов',
  },
  {
    danger776: 'Вредные химические вещества в воздухе рабочей зоны',
    IdDangerEvent776: '9.1',
    IdDanger767: '7.2',
    danger767: 'Взвеси вредных химических веществ в воздухе рабочей зоны',
    IdDangerEvent767: '7.2.1',
    dangerEvent767:
      'Негативное воздействие воздушных взвесей вредных химических веществ на организм работника',
  },
  {
    danger776: 'Вредные химические вещества в воздухе рабочей зоны',
    IdDangerEvent776: '9.1',
    IdDanger767: '7.3',
    danger767:
      'Аэрозоли, воздушные взвеси, содержащие смазочные масла, чистящие и обезжиривающие вещества в воздухе рабочей зоны',
    IdDangerEvent767: '7.3.1',
    dangerEvent767:
      'Воздействия на органы дыхания работников воздушных взвесей, содержащих смазочные масла, чистящие и обезжиривающие вещества',
  },
  {
    danger776: 'Воздействие на кожные покровы смазочных масел',
    IdDangerEvent776: '9.2',
    IdDanger767: '6.3',
    danger767:
      'Растворы кислот, щелочей, смазочно-охлаждающих жидкостей на водной основе, щелочемасляных эмульсий',
    IdDangerEvent767: '6.3.1',
    dangerEvent767:
      'Дерматиты, химические ожоги и другие воздействия на кожные покровы работника кислот, щелочей, смазочно-охлаждающих жидкостей на водной основе, щелочемасляных эмульсий',
  },
  {
    danger776: 'Воздействие на кожные покровы смазочных масел',
    IdDangerEvent776: '9.2',
    IdDanger767: '6.7',
    danger767:
      'Нефть, нефтепродукты, смазочные масла, воздействующие на кожные покровы',
    IdDangerEvent767: '6.7.1',
    dangerEvent767:
      'Дерматиты вследствие воздействия на кожные покровы работника технических (смазочных) масел, нефти и/или нефтепродуктов',
  },
  {
    danger776:
      'Воздействие на кожные покровы обезжиривающих и чистящих веществ',
    IdDangerEvent776: '9.3',
    IdDanger767: '6.8',
    danger767: 'Чистящие и обеззараживающие, дезинфицирующие вещества',
    IdDangerEvent767: '6.8.1',
    dangerEvent767:
      'Дерматиты, вследствие воздействия на кожные покровы чистящих и обеззараживающих, дезинфицирующих веществ',
  },
  {
    danger776: 'Контакт с высокоопасными веществами',
    IdDangerEvent776: '9.4',
    IdDanger767: '6.2',
    danger767: 'Высокоопасные вещества',
    IdDangerEvent767: '6.2.1',
    dangerEvent767:
      'Химические ожоги кожи при контакте с опасными веществами и/или поражение слизистых при контакте с опасными веществами и/или отравление организма при контакте с высокоопасными веществами и/или вдыхание высокоопасных веществ и/или контакт с патогенными микроорганизмами',
  },
  {
    danger776: 'Образование токсичных паров при нагревании',
    IdDangerEvent776: '9.5',
    IdDanger767: '6.4',
    danger767:
      'Повышенная концентрация паров вредных жидкостей, газов в воздухе рабочей зоны (а также пыль, туман, дым)',
    IdDangerEvent767: '6.4.1',
    dangerEvent767:
      'Вдыхание работником паров вредных жидкостей, газов (а также пыли, тумана, дыма); поражение легких от вдыхания вредных паров или газов; поражение слизистых оболочек дыхательных путей от вдыхания вредных паров или газов',
  },
  {
    danger776: 'Образование токсичных паров при нагревании',
    IdDangerEvent776: '9.5',
    IdDanger767: '6.6',
    danger767:
      'Токсичные пары, газы, аэрозоли, выделяемые при нагревании или горении, не связанном с технологическим процессом производства, в том числе при пожаре',
    IdDangerEvent767: '6.6.1',
    dangerEvent767:
      'Вдыхание работником токсичных паров, газов, аэрозолей, образовавшихся при нагревании веществ, при их горении, в том числе при пожаре',
  },
  {
    danger776: 'Воздействие химических веществ на кожу',
    IdDangerEvent776: '9.6',
    IdDanger767: '6.3',
    danger767:
      'Растворы кислот, щелочей, смазочно-охлаждающих жидкостей на водной основе, щелочемасляных эмульсий',
    IdDangerEvent767: '6.3.1',
    dangerEvent767:
      'Дерматиты, химические ожоги и другие воздействия на кожные покровы работника кислот, щелочей, смазочно-охлаждающих жидкостей на водной основе, щелочемасляных эмульсий',
  },
  {
    danger776: 'Воздействие химических веществ на глаза',
    IdDangerEvent776: '9.7',
    IdDanger767: '6.5',
    danger767: 'Вещества, способные вызвать химический ожог роговицы глаза',
    IdDangerEvent767: '6.5.1',
    dangerEvent767:
      'Химический ожог роговицы глаза работника из-за попадания опасных веществ в глаза',
  },
  {
    danger776:
      'Недостаток кислорода в воздухе рабочей зоны в замкнутых технологических емкостях, из-за вытеснения его другими газами или жидкостями',
    IdDangerEvent776: '11.1',
    IdDanger767: '5.1',
    danger767:
      'Недостаток кислорода в воздухе рабочей зоны в замкнутых технологических емкостях, из-за вытеснения его другими газами или жидкостями',
    IdDangerEvent767: '5.1.1',
    dangerEvent767:
      'Развитие гипоксии или удушья из-за недостатка кислорода 5.1.1',
  },
  {
    danger776:
      'Недостаток кислорода в воздухе рабочей зоны в замкнутых технологических емкостях, из-за вытеснения его другими газами или жидкостями',
    IdDangerEvent776: '11.2',
    IdDanger767: '5.1',
    danger767:
      'Недостаток кислорода в воздухе рабочей зоны в замкнутых технологических емкостях, из-за вытеснения его другими газами или жидкостями',
    IdDangerEvent767: '5.1.1',
    dangerEvent767:
      'Развитие гипоксии или удушья из-за недостатка кислорода 5.1.1',
  },
  {
    danger776:
      'Недостаток кислорода в воздухе рабочей зоны в замкнутых технологических емкостях, из-за вытеснения его другими газами или жидкостями',
    IdDangerEvent776: '11.3',
    IdDanger767: '5.2',
    danger767:
      'Недостаток кислорода в воздухе рабочей зоны при работе в подземных сооружениях',
    IdDangerEvent767: '5.2.1',
    dangerEvent767:
      'Развитие гипоксии или удушья из-за недостатка кислорода 5.2.1',
  },
  {
    danger776: 'Аэрозоли преимущественно фиброгенного действия (АПФД)',
    IdDangerEvent776: '12.1',
    IdDanger767: '7.1',
    danger767: 'Пыль в воздухе рабочей зоны',
    IdDangerEvent767: '7.1.2',
    dangerEvent767: 'Негативное воздействия пыли на органы дыхания',
  },
  {
    danger776: 'Аэрозоли преимущественно фиброгенного действия (АПФД)',
    IdDangerEvent776: '12.2',
    IdDanger767: '7.1',
    danger767: 'Пыль в воздухе рабочей зоны',
    IdDangerEvent767: '7.1.1',
    dangerEvent767: 'Негативное воздействия пыли на глаза работника',
  },
  {
    danger776: 'Аэрозоли преимущественно фиброгенного действия (АПФД)',
    IdDangerEvent776: '12.2',
    IdDanger767: '7.1',
    danger767: 'Пыль в воздухе рабочей зоны',
    IdDangerEvent767: '7.1.3',
    dangerEvent767: 'Негативное воздействия пыли на кожу (дерматиты)',
  },
  {
    danger776: 'Аэрозоли преимущественно фиброгенного действия (АПФД)',
    IdDangerEvent776: '12.2',
    IdDanger767: '1.24',
    danger767: 'Мелкие стружки, мелкие осколки, крупнодисперсная пыль',
    IdDangerEvent767: '1.24.1',
    dangerEvent767:
      'Попадание в глаза работника стружки, мелких осколков, крупнодисперсной пыли',
  },
  {
    danger776: 'Аэрозоли преимущественно фиброгенного действия (АПФД)',
    IdDangerEvent776: '12.3',
    IdDanger767: '7.2',
    danger767: 'Взвеси вредных химических веществ в воздухе рабочей зоны',
    IdDangerEvent767: '7.2.1',
    dangerEvent767:
      'Негативное воздействие воздушных взвесей вредных химических веществ на организм работника',
  },
  {
    danger776: 'Аэрозоли преимущественно фиброгенного действия (АПФД)',
    IdDangerEvent776: '12.4',
    IdDanger767: '7.3',
    danger767:
      'Аэрозоли, воздушные взвеси, содержащие смазочные масла, чистящие и обезжиривающие вещества в воздухе рабочей зоны',
    IdDangerEvent767: '7.3.1',
    dangerEvent767:
      'Воздействия на органы дыхания работников воздушных взвесей, содержащих смазочные масла, чистящие и обезжиривающие вещества',
  },
  {
    danger776: 'Аэрозоли преимущественно фиброгенного действия (АПФД)',
    IdDangerEvent776: '12.5',
    IdDanger767: '7.3',
    danger767:
      'Аэрозоли, воздушные взвеси, содержащие смазочные масла, чистящие и обезжиривающие вещества в воздухе рабочей зоны',
    IdDangerEvent767: '7.3.1',
    dangerEvent767:
      'Воздействия на органы дыхания работников воздушных взвесей, содержащих смазочные масла, чистящие и обезжиривающие вещества',
  },
  {
    danger776: 'Материал, жидкость или газ, имеющие высокую температуру',
    IdDangerEvent776: '13.1',
    IdDanger767: '4.1',
    danger767:
      'Поверхности, имеющие высокую температуру (воздействие конвективной теплоты)',
    IdDangerEvent767: '4.1.1',
    dangerEvent767:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
  },
  {
    danger776: 'Материал, жидкость или газ, имеющие высокую температуру',
    IdDangerEvent776: '13.2',
    IdDanger767: '4.2',
    danger767: 'Материал, жидкость или газ, имеющие высокую температуру',
    IdDangerEvent767: '4.2.1',
    dangerEvent767:
      'Ожог кожных покровов и слизистых работника в следствие контакта с материалом, жидкостью или газом, имеющим высокую температуру',
  },
  {
    danger776: 'Материал, жидкость или газ, имеющие высокую температуру',
    IdDangerEvent776: '13.3',
    IdDanger767: '4.8',
    danger767:
      'Высокая температура окружающей среды, в рабочей зоне, в том числе связанная с климатом',
    IdDangerEvent767: '4.8.2',
    dangerEvent767:
      'Тепловой удар при длительном нахождении в помещении с высокой температурой воздуха',
  },
  {
    danger776:
      'Энергия открытого пламени, выплесков металлов, искр и брызг расплавленного металла и металлической окалины',
    IdDangerEvent776: '13.4',
    IdDanger767: '4.8',
    danger767:
      'Высокая температура окружающей среды, в рабочей зоне, в том числе связанная с климатом',
    IdDangerEvent767: '4.8.2',
    dangerEvent767:
      'Тепловой удар при длительном нахождении в помещении с высокой температурой воздуха',
  },
  {
    danger776:
      'Энергия открытого пламени, выплесков металлов, искр и брызг расплавленного металла и металлической окалины',
    IdDangerEvent776: '13.5',
    IdDanger767: '4.4',
    danger767:
      'Энергия открытого пламени, выплесков металлов, искр и брызг расплавленного металла и металлической окалины',
    IdDangerEvent767: '4.4.1',
    dangerEvent767:
      'Ожог кожных покровов и слизистых работника воздействия открытого пламени',
  },
  {
    danger776:
      'Энергия открытого пламени, выплесков металлов, искр и брызг расплавленного металла и металлической окалины',
    IdDangerEvent776: '13.5',
    IdDanger767: '13.4',
    danger767: 'Тепловое излучение',
    IdDangerEvent767: '13.4.1',
    dangerEvent767:
      'Ожоги кожных покровов и слизистых оболочек работника вследствие воздействия тепловое излучения',
  },
  {
    danger776:
      'Энергия открытого пламени, выплесков металлов, искр и брызг расплавленного металла и металлической окалины',
    IdDangerEvent776: '13.6',
    IdDanger767: '4.4',
    danger767:
      'Энергия открытого пламени, выплесков металлов, искр и брызг расплавленного металла и металлической окалины',
    IdDangerEvent767: '4.4.2',
    dangerEvent767:
      'Ожог роговицы глаза работника горячими жидкостями и газами, а также тепловым излучением и воздействием открытого пламени',
  },
  {
    danger776:
      'Энергия открытого пламени, выплесков металлов, искр и брызг расплавленного металла и металлической окалины',
    IdDangerEvent776: '13.6',
    IdDanger767: '13.4',
    danger767: 'Тепловое излучение',
    IdDangerEvent767: '13.4.1',
    dangerEvent767:
      'Ожоги кожных покровов и слизистых оболочек работника вследствие воздействия тепловое излучения',
  },
  {
    danger776:
      'Энергия открытого пламени, выплесков металлов, искр и брызг расплавленного металла и металлической окалины',
    IdDangerEvent776: '13.7',
    IdDanger767: '4.2',
    danger767: 'Материал, жидкость или газ, имеющие высокую температуру',
    IdDangerEvent767: '4.2.1',
    dangerEvent767:
      'Ожог кожных покровов и слизистых работника в следствие контакта с материалом, жидкостью или газом, имеющим высокую температуру',
  },
  {
    danger776:
      'Поверхности, имеющие высокую температуру (воздействие конвективной теплоты)',
    IdDangerEvent776: '13.8',
    IdDanger767: '4.8',
    danger767:
      'Высокая температура окружающей среды, в рабочей зоне, в том числе связанная с климатом',
    IdDangerEvent767: '4.8.2',
    dangerEvent767:
      'Тепловой удар при длительном нахождении в помещении с высокой температурой воздуха',
  },
  {
    danger776:
      'Поверхности, имеющие высокую температуру (воздействие конвективной теплоты)',
    IdDangerEvent776: '13.9',
    IdDanger767: '4.1',
    danger767:
      'Поверхности, имеющие высокую температуру (воздействие конвективной теплоты)',
    IdDangerEvent767: '4.1.1',
    dangerEvent767:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
  },
  {
    danger776: 'Прямое воздействие солнечных лучей',
    IdDangerEvent776: '13.10',
    IdDanger767: '4.8',
    danger767:
      'Высокая температура окружающей среды, в рабочей зоне, в том числе связанная с климатом',
    IdDangerEvent767: '4.8.1',
    dangerEvent767:
      'Тепловой удар при длительном нахождении на открытом воздухе при прямом воздействии лучей солнца на незащищенную поверхность головы',
  },
  {
    danger776: 'Охлажденная поверхность, охлажденная жидкость или газ',
    IdDangerEvent776: '14.1',
    IdDanger767: '4.5',
    danger767: 'Охлажденная поверхность, охлажденная жидкость или газ',
    IdDangerEvent767: '4.5.1',
    dangerEvent767:
      'Обморожение мягких тканей работника из-за контакта с поверхностью имеющую низкую температуру, с охлажденной жидкостью или газом',
  },
  {
    danger776:
      'Высокая влажность окружающей среды, в рабочей зоне, в том числе, связанная с климатом (воздействие влажности в виде тумана, росы, атмосферных осадков, конденсата, струй и капель жидкости)',
    IdDangerEvent776: '15.1',
    IdDanger767: '4.9',
    danger767:
      'Высокая влажность окружающей среды в рабочей зоне, в том числе, связанная с климатом',
    IdDangerEvent767: '4.9.1',
    dangerEvent767:
      'Воздействие влажности в виде тумана, росы, атмосферных осадков, конденсата, струй и капель жидкости',
  },
  {
    danger776:
      'Высокая или низкая скорость движения воздуха, в том числе, связанная с климатом',
    IdDangerEvent776: '16.1',
    IdDanger767: '4.7',
    danger767:
      'Низкая температура окружающей среды в рабочей зоне, в том числе связанная с климатом',
    IdDangerEvent767: '4.7.1',
    dangerEvent767:
      'Простудное заболевание работника из-за воздействия пониженной температуры воздуха, обморожения мягких тканей, в том числе мягких тканей конечностей',
  },
  {
    danger776:
      'Высокая или низкая скорость движения воздуха, в том числе, связанная с климатом',
    IdDangerEvent776: '16.1',
    IdDanger767: '4.10',
    danger767: 'Низкая температура окружающей среды и ветер на рабочем месте',
    IdDangerEvent767: '4.10.1',
    dangerEvent767:
      'Заболевание работника из-за воздействия движения воздуха и (или) пониженной температуры',
  },
  {
    danger776:
      'Высокая или низкая скорость движения воздуха, в том числе, связанная с климатом',
    IdDangerEvent776: '16.1',
    IdDanger767: '4.8',
    danger767:
      'Высокая температура окружающей среды, в рабочей зоне, в том числе связанная с климатом',
    IdDangerEvent767: '4.8.1',
    dangerEvent767:
      'Тепловой удар при длительном нахождении на открытом воздухе при прямом воздействии лучей солнца на незащищенную поверхность головы',
  },
  {
    danger776:
      'Высокая или низкая скорость движения воздуха, в том числе, связанная с климатом',
    IdDangerEvent776: '16.2',
    IdDanger767: '19.3',
    danger767: 'Ударная волна от взрыва',
    IdDangerEvent767: '19.3.1',
    dangerEvent767: 'Повреждения работника вследствие действия ударной волны',
  },
  {
    danger776:
      'Повышенный уровень шума и другие неблагоприятные характеристики шума',
    IdDangerEvent776: '20.1',
    IdDanger767: '10.1',
    danger767:
      'Повышенный уровня шума и другие неблагоприятные характеристики шума',
    IdDangerEvent767: '10.1.1',
    dangerEvent767:
      'Снижение остроты слуха, тугоухость, глухота, вследствие воздействия повышенного уровня шума и других неблагоприятных характеристик ш',
  },
  {
    danger776:
      'Повышенный уровень шума и другие неблагоприятные характеристики шума',
    IdDangerEvent776: '20.2',
    IdDanger767: '10.1',
    danger767:
      'Повышенный уровня шума и другие неблагоприятные характеристики шума',
    IdDangerEvent767: '10.1.1',
    dangerEvent767:
      'Снижение остроты слуха, тугоухость, глухота, вследствие воздействия повышенного уровня шума и других неблагоприятных характеристик ш',
  },
  {
    danger776:
      'Повышенный уровень ультразвуковых колебаний (воздушный и контактный ультразвук)',
    IdDangerEvent776: '20.3',
    IdDanger767: '10.2',
    danger767:
      'Повышенный (низкочастотный) уровень ультразвуковых колебаний (воздушный и контактный ультразвук)',
    IdDangerEvent767: '10.2.1',
    dangerEvent767:
      'Негативное воздействие повышенного (низкочастотный) уровень ультразвуковых колебаний (воздушный и контактный ультразвук) на работника',
  },
  {
    danger776:
      'Воздействие локальной вибрации при использовании ручных механизмов и инструментов',
    IdDangerEvent776: '21.1',
    IdDanger767: '11.1',
    danger767: 'Повышенная вибрация при использовании ручных механизмов',
    IdDangerEvent767: '11.1.1',
    dangerEvent767:
      'Воздействие локальной вибрации на руки работника при использовании ручных механизмов (сужение сосудов, болезнь белых пальцев)',
  },
  {
    danger776:
      'Груз, инструмент или предмет, перемещаемый или поднимаемый, в том числе на высоту',
    IdDangerEvent776: '22.1',
    IdDanger767: '1.3',
    danger767:
      'Груз, инструмент или предмет, перемещаемый или поднимаемый, в том числе на высоту',
    IdDangerEvent767: '1.3.1',
    dangerEvent767:
      'Удар работника или падение на работника предмета, тяжелого инструмента или груза, упавшего при перемещении или подъеме',
  },
  {
    danger776: 'Дикие или домашние животные',
    IdDangerEvent776: '25.1',
    IdDanger767: '15.1',
    danger767: 'Дикие или домашние животные',
    IdDangerEvent767: '15.1.1',
    dangerEvent767: 'Последствия укуса работника животным',
  },
  {
    danger776: 'Дикие или домашние животные',
    IdDangerEvent776: '25.2',
    IdDanger767: '15.1',
    danger767: 'Дикие или домашние животные',
    IdDangerEvent767: '15.1.1',
    dangerEvent767: 'Последствия укуса работника животным',
  },
  {
    danger776: 'Дикие или домашние животные',
    IdDangerEvent776: '25.4',
    IdDanger767: '15.1',
    danger767: 'Дикие или домашние животные',
    IdDangerEvent767: '15.1.1',
    dangerEvent767: 'Последствия укуса работника животным',
  },
  {
    danger776: 'Дикие или домашние животные',
    IdDangerEvent776: '25.4',
    IdDanger767: '15.1',
    danger767: 'Дикие или домашние животные',
    IdDangerEvent767: '15.1.2',
    dangerEvent767:
      'Заражение работника вследствие прямого контакта с животными',
  },
  {
    danger776: 'Дикие или домашние животные',
    IdDangerEvent776: '25.5',
    IdDanger767: '15.1',
    danger767: 'Дикие или домашние животные',
    IdDangerEvent767: '15.1.1',
    dangerEvent767: 'Последствия укуса работника животным',
  },
  {
    danger776: 'Дикие или домашние животные',
    IdDangerEvent776: '25.6',
    IdDanger767: '15.1',
    danger767: 'Дикие или домашние животные',
    IdDangerEvent767: '15.1.1',
    dangerEvent767: 'Последствия укуса работника животным',
  },
  {
    danger776: 'Дикие или домашние животные',
    IdDangerEvent776: '25.7',
    IdDanger767: '15.1',
    danger767: 'Дикие или домашние животные',
    IdDangerEvent767: '15.1.2',
    dangerEvent767:
      'Заражение работника вследствие прямого контакта с животными',
  },
  {
    danger776:
      'Наличие на рабочем месте паукообразных и насекомых, включая кровососущих',
    IdDangerEvent776: '26.1',
    IdDanger767: '8.3',
    danger767:
      'Наличие на рабочем месте паукообразных и кровососущих насекомых, способных являться переносчиками тяжелых инфекций',
    IdDangerEvent767: '8.3.1',
    dangerEvent767:
      'Заражение работника вследствие инфекции от укуса паукообразных и насекомых, микроорганизмами, переносчиками которых они являются',
  },
  {
    danger776:
      'Наличие на рабочем месте паукообразных и насекомых, включая кровососущих',
    IdDangerEvent776: '26.2',
    IdDanger767: '8.3',
    danger767:
      'Наличие на рабочем месте паукообразных и кровососущих насекомых, способных являться переносчиками тяжелых инфекций',
    IdDangerEvent767: '8.3.1',
    dangerEvent767:
      'Заражение работника вследствие инфекции от укуса паукообразных и насекомых, микроорганизмами, переносчиками которых они являются',
  },
  {
    danger776:
      'Наличие на рабочем месте паукообразных и насекомых, включая кровососущих',
    IdDangerEvent776: '26.3',
    IdDanger767: '8.3',
    danger767:
      'Наличие на рабочем месте паукообразных и кровососущих насекомых, способных являться переносчиками тяжелых инфекций',
    IdDangerEvent767: '8.3.1',
    dangerEvent767:
      'Заражение работника вследствие инфекции от укуса паукообразных и насекомых, микроорганизмами, переносчиками которых они являются',
  },
  {
    danger776: 'Электрический ток',
    IdDangerEvent776: '27.1',
    IdDanger767: '3.1',
    danger767: 'Электрический ток',
    IdDangerEvent767: '3.1.1',
    dangerEvent767:
      'Удар током и другие травмы, полученные в результате контакта с токоведущими частями, которые находятся под напряжением до 1000 В',
  },
  {
    danger776: 'Электрический ток',
    IdDangerEvent776: '27.1',
    IdDanger767: '3.1',
    danger767: 'Электрический ток',
    IdDangerEvent767: '3.1.2',
    dangerEvent767:
      'Травмы при контакте с токоведущими частями, которые находятся под напряжением выше 1000 В',
  },
  {
    danger776: 'Электрический ток',
    IdDangerEvent776: '27.4',
    IdDanger767: '3.5',
    danger767: 'Энергия, выделяемая при возникновении электрической дуги',
    IdDangerEvent767: '3.5.1',
    dangerEvent767:
      'Ожоги кожных покровов работника, вследствие термического воздействия электрической дуги',
  },
  {
    danger776: 'Шаговое напряжение',
    IdDangerEvent776: '27.5',
    IdDanger767: '3.2',
    danger767: 'Шаговое напряжение',
    IdDangerEvent767: '3.2.1',
    dangerEvent767:
      'Воздействие электрического тока на работника, вследствие его прохождения через ткани и органы',
  },
  {
    danger776:
      'Искры, возникающие вследствие накопления статического электричества, в том числе при работе во взрыво-пожароопасной среде',
    IdDangerEvent776: '27.6',
    IdDanger767: '3.3',
    danger767:
      'Искры, возникающие вследствие накопления статического электричества, в том числе при работе во взрывопожарноопасной среде',
    IdDangerEvent767: '3.3.1',
    dangerEvent767:
      'Возникновение ожога вследствие поджигания взрывопожарноопасной среды',
  },
  {
    danger776:
      'Наведенное напряжение в отключенной электрической цепи (электромагнитное воздействие параллельной воздушной электрической линии или электричества, циркулирующего в контактной сети)',
    IdDangerEvent776: '27.7',
    IdDanger767: '3.4',
    danger767:
      'Наведенное напряжение в отключенной электрической цепи (электромагнитное воздействие параллельной воздушной электрической линии или электричества, циркулирующего в контактной сети)',
    IdDangerEvent767: '3.4.1',
    dangerEvent767: 'Поражение током от наведенного напряжения',
  },
];
export default conversion;
