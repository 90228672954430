const code = [
  { ID: 10003, label: 'Авербандщик' },
  {
    ID: 10005,
    label: 'Авиационный механик (техник) по планеру и двигателям',
  },
  {
    ID: 10007,
    label: 'Авиационный механик (техник) по приборам и электрооборудованию',
  },
  {
    ID: 10008,
    label: 'Авиационный механик (техник) по радиооборудованию',
  },
  {
    ID: 10010,
    label:
      'Авиационный техник (механик) по парашютным и аварийно-спасательным средствам',
  },
  {
    ID: 10012,
    label: 'Авиационный техник по горюче-смазочным материалам',
  },
  { ID: 10013, label: 'Автоклавщик' },
  { ID: 10014, label: 'Автоклавщик' },
  { ID: 10015, label: 'Автоклавщик' },
  { ID: 10016, label: 'Автоклавщик литья под давлением' },
  { ID: 10017, label: 'Автоклавщик на запарке брикетов' },
  {
    ID: 10019,
    label:
      'Автоклавщик-сушильщик аккумуляторных пластин в производстве свинцовых аккумуляторов',
  },
  { ID: 10021, label: 'Автоматчик' },
  { ID: 10023, label: 'Автоматчик вязальных автоматов' },
  {
    ID: 10025,
    label: 'Автоматчик игольно-платинных изделий',
  },
  {
    ID: 10027,
    label: 'Автоматчик картонажного производства',
  },
  {
    ID: 10028,
    label: 'Автоматчик клеильных полуавтоматов',
  },
  {
    ID: 10030,
    label: 'Автоматчик на узловязальных и навивочных автоматах и станках',
  },
  {
    ID: 10032,
    label: 'Автоматчик по изготовлению деталей клавишных инструментов',
  },
  { ID: 10036, label: 'Автоматчик ремизных автоматов' },
  {
    ID: 10038,
    label: 'Автоматчик холодновысадочных автоматов',
  },
  {
    ID: 10039,
    label: 'Автоматчик элементного производства',
  },
  { ID: 10041, label: 'Агломератчик' },
  { ID: 10043, label: 'Аквадировщик' },
  { ID: 10045, label: 'Акклиматизатор' },
  { ID: 10047, label: 'Аккумуляторщик' },
  { ID: 10049, label: 'Алундировщик' },
  { ID: 10051, label: 'Алюминировщик' },
  {
    ID: 10053,
    label: 'Алюминировщик электротехнических изделий',
  },
  { ID: 10055, label: 'Ангобировщик' },
  {
    ID: 10056,
    label: 'Ангобировщик санитарно-строительных изделий',
  },
  { ID: 10058, label: 'Анодчик в производстве алюминия' },
  { ID: 10060, label: 'Антенщик-мачтовик' },
  { ID: 10062, label: 'Антикоррозийщик' },
  { ID: 10065, label: 'Аппаратчик' },
  { ID: 10067, label: 'Аппаратчик абсолютирования' },
  { ID: 10069, label: 'Аппаратчик абсорбции' },
  { ID: 10071, label: 'Аппаратчик адсорбции' },
  { ID: 10073, label: 'Аппаратчик азотирования' },
  { ID: 10075, label: 'Аппаратчик активации' },
  { ID: 10080, label: 'Аппаратчик аммонолиза' },
  { ID: 10083, label: 'Аппаратчик аппретирования' },
  {
    ID: 10084,
    label: 'Аппаратчик асептического консервирования',
  },
  { ID: 10085, label: 'Аппаратчик ацеталирования' },
  { ID: 10088, label: 'Аппаратчик ацилирования' },
  { ID: 10090, label: 'Аппаратчик балансовых установок' },
  {
    ID: 10092,
    label: 'Аппаратчик бельевых сушильных установок',
  },
  {
    ID: 10094,
    label: 'Аппаратчик бисульфитирования ванилина',
  },
  { ID: 10097, label: 'Аппаратчик бучения' },
  { ID: 10101, label: 'Аппаратчик вакуумирования' },
  { ID: 10103, label: 'Аппаратчик вакуум-приемников' },
  {
    ID: 10105,
    label: 'Аппаратчик вакуум-сушильной установки',
  },
  {
    ID: 10107,
    label: 'Аппаратчик вакуум-термической печи',
  },
  { ID: 10109, label: 'Аппаратчик валки изделий' },
  { ID: 10111, label: 'Аппаратчик-вальцевар' },
  { ID: 10114, label: 'Аппаратчик варки утфеля' },
  { ID: 10116, label: 'Аппаратчик витаминизации жира' },
  {
    ID: 10118,
    label: 'Аппаратчик водно-химической обработки',
  },
  { ID: 10120, label: 'Аппаратчик возгонки' },
  { ID: 10122, label: 'Аппаратчик воздухоразделения' },
  { ID: 10124, label: 'Аппаратчик восстановления' },
  {
    ID: 10125,
    label: 'Аппаратчик восстановления молока',
  },
  {
    ID: 10127,
    label: 'Аппаратчик восстановления полупроводниковых материалов',
  },
  {
    ID: 10129,
    label: 'Аппаратчик в производстве драгоценных металлов',
  },
  {
    ID: 10131,
    label: 'Аппаратчик в производстве металлических порошков',
  },
  { ID: 10133, label: 'Аппаратчик в производстве солей' },
  {
    ID: 10135,
    label: 'Аппаратчик в производстве твердых сплавов и тугоплавких металлов',
  },
  {
    ID: 10137,
    label: 'Аппаратчик в производстве титана и редких металлов',
  },
  { ID: 10138, label: 'Аппаратчик вспенивания' },
  { ID: 10142, label: 'Аппаратчик вулканизации' },
  {
    ID: 10144,
    label: 'Аппаратчик выделения ацетофенона',
  },
  { ID: 10146, label: 'Аппаратчик выделения карбинола' },
  {
    ID: 10148,
    label: 'Аппаратчик выделения псевдобутилена',
  },
  { ID: 10150, label: 'Аппаратчик выделения серы' },
  {
    ID: 10151,
    label: 'Аппаратчик выделения фтористого бора',
  },
  { ID: 10153, label: 'Аппаратчик выпаривания' },
  {
    ID: 10155,
    label: 'Аппаратчик выпаривания и гранулирования',
  },
  {
    ID: 10157,
    label: 'Аппаратчик выпарных аппаратов для получения жидкого продукта',
  },
  {
    ID: 10158,
    label: 'Аппаратчик выпарных аппаратов для получения твердого продукта',
  },
  { ID: 10160, label: 'Аппаратчик выращивания дрожжей' },
  { ID: 10162, label: 'Аппаратчик высаждения' },
  { ID: 10164, label: 'Аппаратчик вытопки' },
  { ID: 10166, label: 'Аппаратчик вытяжки' },
  { ID: 10168, label: 'Аппаратчик выщелачивания' },
  {
    ID: 10170,
    label: 'Аппаратчик газового консервирования',
  },
  { ID: 10172, label: 'Аппаратчик газогенерации' },
  { ID: 10174, label: 'Аппаратчик газоразделения' },
  { ID: 10176, label: 'Аппаратчик гашения извести' },
  { ID: 10177, label: 'Аппаратчик гидратации' },
  { ID: 10178, label: 'Аппаратчик гидратации' },
  { ID: 10179, label: 'Аппаратчик гидрирования' },
  { ID: 10181, label: 'Аппаратчик гидролиза' },
  {
    ID: 10183,
    label: 'аппаратчик гидролиза жира и печени',
  },
  {
    ID: 10185,
    label: 'Аппаратчик гидролиза крахмального молока',
  },
  { ID: 10187, label: 'Аппаратчик-гидрометаллург' },
  { ID: 10189, label: 'Аппаратчик гидрохлорирования' },
  { ID: 10191, label: 'Аппаратчик гомогенизации' },
  {
    ID: 10195,
    label: 'Аппаратчик гомогенизации пластических масс',
  },
  { ID: 10197, label: 'Аппаратчик гранулирования' },
  { ID: 10198, label: 'Аппаратчик гранулирования' },
  { ID: 10199, label: 'Аппаратчик деаэрации' },
  { ID: 10201, label: 'Аппаратчик дегидратации' },
  { ID: 10202, label: 'Аппаратчик дегидрирования' },
  { ID: 10204, label: 'Аппаратчик дезодорации' },
  { ID: 10206, label: 'Аппаратчик декарбоксилирования' },
  { ID: 10208, label: 'Аппаратчик деполимеризации' },
  { ID: 10210, label: 'Аппаратчик десорбции' },
  { ID: 10212, label: 'Аппаратчик деструкции' },
  { ID: 10213, label: 'Аппаратчик десублимации' },
  {
    ID: 10215,
    label: 'Аппаратчик дефекосатурации диффузионного сока',
  },
  { ID: 10219, label: 'Аппаратчик димеризации' },
  {
    ID: 10221,
    label: 'Аппаратчик диспергирования пигментов и красителей',
  },
  {
    ID: 10222,
    label: 'Аппаратчик диспергирования щелочных металлов',
  },
  {
    ID: 10224,
    label: 'Аппаратчик диспропорционирования',
  },
  { ID: 10226, label: 'Аппаратчик диффузии' },
  { ID: 10228, label: 'Аппаратчик дозирования' },
  {
    ID: 10230,
    label: 'Аппаратчик-дозировщик на изготовлении и поливе фотоэмульсий',
  },
  { ID: 10232, label: 'Аппаратчик дозревания' },
  {
    ID: 10234,
    label: 'Аппаратчик дубления и сушки белковой оболочки',
  },
  {
    ID: 10236,
    label: 'Аппаратчик дубления (кожевенное и кожсырьевое производство)',
  },
  {
    ID: 10238,
    label: 'Аппаратчик дубления (меховое производство)',
  },
  { ID: 10240, label: 'Аппаратчик енолизации' },
  { ID: 10242, label: 'Аппаратчик желатинизации' },
  { ID: 10245, label: 'Аппаратчик жироочистки' },
  { ID: 10247, label: 'Аппаратчик-заварщик' },
  {
    ID: 10248,
    label: 'Аппаратчик замораживания пищевого сырья и продуктов',
  },
  { ID: 10251, label: 'Аппаратчик запаривания' },
  { ID: 10253, label: 'Аппаратчик запаривания одонков' },
  { ID: 10255, label: 'Аппаратчик золения' },
  { ID: 10257, label: 'Аппаратчик золки' },
  {
    ID: 10259,
    label: 'Аппаратчик извлечения побочных продуктов',
  },
  {
    ID: 10261,
    label: 'Аппаратчик изготовления армированных прессовочных материалов',
  },
  {
    ID: 10263,
    label: 'Аппаратчик изготовления баритмассы',
  },
  {
    ID: 10265,
    label: 'Аппаратчик изготовления искусственного шеелита',
  },
  {
    ID: 10267,
    label: 'Аппаратчик изготовления нетканых стекловолокнистых материалов',
  },
  {
    ID: 10270,
    label: 'Аппаратчик изготовления пленочных материалов',
  },
  {
    ID: 10273,
    label: 'Аппаратчик изготовления резиновых нитей',
  },
  {
    ID: 10275,
    label: 'Аппаратчик изготовления рентгеновских экранов',
  },
  {
    ID: 10277,
    label: 'Аппаратчик изготовления термопасты',
  },
  {
    ID: 10279,
    label: 'Аппаратчик измельчения и предсозревания',
  },
  { ID: 10281, label: 'Аппаратчик изомеризации' },
  { ID: 10283, label: 'Аппаратчик имидирования' },
  { ID: 10285, label: 'Аппаратчик-индулиновар' },
  { ID: 10287, label: 'Аппаратчик йодирования' },
  { ID: 10289, label: 'Аппаратчик ионного обмена' },
  {
    ID: 10291,
    label: 'Аппаратчик ионообменной очистки глицерина',
  },
  {
    ID: 10293,
    label: 'Аппаратчик ионообменной очистки химико-фармацевтических препаратов',
  },
  { ID: 10295, label: 'Аппаратчик испарения' },
  {
    ID: 10296,
    label: 'Аппаратчик испарительной установки',
  },
  { ID: 10298, label: 'Аппаратчик калибровки' },
  { ID: 10300, label: 'Аппаратчик карбидизации' },
  { ID: 10304, label: 'Аппаратчик карбонизации' },
  { ID: 10306, label: 'Аппаратчик каустификации' },
  {
    ID: 10308,
    label: 'Аппаратчик кислотной обработки спилка',
  },
  { ID: 10309, label: 'Аппаратчик коагулирования шляма' },
  { ID: 10310, label: 'Аппаратчик коагуляции' },
  {
    ID: 10312,
    label: 'Аппаратчик коконозапарочной машины',
  },
  {
    ID: 10314,
    label: 'Аппаратчик комбикормового производства',
  },
  { ID: 10316, label: 'Аппаратчик-комплекторщик' },
  { ID: 10318, label: 'Аппаратчик конверсии' },
  { ID: 10320, label: 'Аппаратчик-конденсаторщик' },
  { ID: 10321, label: 'Аппаратчик конденсации' },
  { ID: 10323, label: 'Аппаратчик контактирования' },
  { ID: 10324, label: 'Аппаратчик контактной выпарки' },
  {
    ID: 10327,
    label: 'Аппаратчик концентрирования кислот',
  },
  {
    ID: 10329,
    label: 'Аппаратчик коньячного производства',
  },
  { ID: 10331, label: 'Аппаратчик-краскотер' },
  {
    ID: 10332,
    label: 'Аппаратчик крахмального агрегата',
  },
  {
    ID: 10334,
    label: 'Аппаратчик крашения и жирования кож',
  },
  { ID: 10336, label: 'Аппаратчик кристаллизации' },
  {
    ID: 10338,
    label: 'Аппаратчик кристаллизации и центрифугирования',
  },
  {
    ID: 10340,
    label: 'Аппаратчик крупяного производства',
  },
  { ID: 10342, label: 'Аппаратчик ксантогенирования' },
  { ID: 10344, label: 'Аппаратчик-лаковар' },
  {
    ID: 10346,
    label: 'Аппаратчик лесохимической установки',
  },
  { ID: 10348, label: 'Аппаратчик литья и рубки' },
  { ID: 10350, label: 'Аппаратчик матирования смолы' },
  { ID: 10352, label: 'Аппаратчик мерсеризации' },
  { ID: 10353, label: 'Аппаратчик мерсеризации' },
  { ID: 10358, label: 'Аппаратчик мокрой классификации' },
  {
    ID: 10360,
    label: 'Аппаратчик мукомольного производства',
  },
  { ID: 10362, label: 'Аппаратчик мыловарения' },
  {
    ID: 10363,
    label: 'Аппаратчик мягчения кожевенных полуфабрикатов и меховых шкурок',
  },
  {
    ID: 10365,
    label: 'Аппаратчик нагрева теплоносителей',
  },
  {
    ID: 10367,
    label: 'Аппаратчик на изготовлении микропроводов в стеклянной изоляции',
  },
  {
    ID: 10369,
    label: 'Аппаратчик нанесения полимерных и парафиновых покрытий на сыры',
  },
  {
    ID: 10371,
    label: 'Аппаратчик на плазменных установках',
  },
  {
    ID: 10373,
    label: 'Аппаратчик на приготовлении смесей и растворов',
  },
  {
    ID: 10375,
    label: 'Аппаратчик на пропиточных агрегатах',
  },
  { ID: 10377, label: 'Аппаратчик напыления металлом' },
  {
    ID: 10379,
    label: 'Аппаратчик напыления стекловолокнистых материалов',
  },
  { ID: 10381, label: 'Аппаратчик насыщения' },
  {
    ID: 10383,
    label: 'Аппаратчик на установках деионизации',
  },
  { ID: 10385, label: 'Аппаратчик-нейтрализаторщик' },
  { ID: 10386, label: 'Аппаратчик нейтрализации' },
  {
    ID: 10388,
    label: 'Аппаратчик никелирования стеклоткани',
  },
  { ID: 10394, label: 'Аппаратчик нитрозного процесса' },
  { ID: 10396, label: 'Аппаратчик обезвоживания' },
  { ID: 10398, label: 'Аппаратчик обезвоживания битума' },
  {
    ID: 10400,
    label: 'Аппаратчик обезвоздушивания и фильтрации',
  },
  { ID: 10402, label: 'Аппаратчик обезжиривания' },
  {
    ID: 10404,
    label: 'Аппаратчик обезжиривания сиропов',
  },
  {
    ID: 10407,
    label: 'Аппаратчик обеззоливания, мягчения',
  },
  { ID: 10409, label: 'Аппаратчик обессоливания воды' },
  {
    ID: 10410,
    label: 'Аппаратчик обесфеноливания и обеспиридинивания масел',
  },
  { ID: 10412, label: 'Аппаратчик обжига' },
  {
    ID: 10414,
    label: 'Аппаратчик облагораживания гексола',
  },
  { ID: 10416, label: 'Аппаратчик облучения жира' },
  {
    ID: 10418,
    label: 'Аппаратчик обогащения золотосодержащих руд',
  },
  { ID: 10420, label: 'Аппаратчик обработки' },
  { ID: 10422, label: 'Аппаратчик обработки зерна' },
  { ID: 10424, label: 'Аппаратчик обработки крови' },
  {
    ID: 10429,
    label: 'Аппаратчик обработки эфирных масел',
  },
  { ID: 10431, label: 'Аппаратчик окисления' },
  { ID: 10433, label: 'Аппаратчик окисления битума' },
  {
    ID: 10435,
    label: 'Аппаратчик окисления молибденовых отходов',
  },
  { ID: 10437, label: 'Аппаратчик окраски квасцов' },
  { ID: 10439, label: 'Аппаратчик оксимирования' },
  { ID: 10441, label: 'Аппаратчик оксихлорирования' },
  { ID: 10443, label: 'Аппаратчик-олифовар' },
  { ID: 10445, label: 'Аппаратчик омыления' },
  { ID: 10449, label: 'Аппаратчик осаждения' },
  { ID: 10451, label: 'Аппаратчик осаждения глютена' },
  { ID: 10453, label: 'Аппаратчик осушки газа' },
  {
    ID: 10455,
    label: 'Аппаратчик ориентации органического стекла',
  },
  { ID: 10457, label: 'Аппаратчик отбеливания' },
  { ID: 10461, label: 'Аппаратчик отверждения' },
  {
    ID: 10463,
    label: 'Аппаратчик отделки и сушки химической нити',
  },
  {
    ID: 10465,
    label: 'Аппаратчик отжига кристаллов корунда',
  },
  { ID: 10467, label: 'Аппаратчик отжига хрома' },
  { ID: 10469, label: 'Аппаратчик отжима' },
  { ID: 10471, label: 'Аппаратчик откатки' },
  {
    ID: 10473,
    label: 'Аппаратчик-отливщик кинофотоосновы и техпленок',
  },
  {
    ID: 10475,
    label: 'Аппаратчик-отливщик магнитных лент',
  },
  {
    ID: 10477,
    label: 'Аппаратчик-отливщик пленки бутафоль',
  },
  { ID: 10479, label: 'Аппаратчик отстаивания' },
  { ID: 10481, label: 'Аппаратчик охлаждения' },
  {
    ID: 10482,
    label: 'Аппаратчик охлаждения молочных продуктов',
  },
  {
    ID: 10484,
    label: 'Аппаратчик очистки алмазного концентрата',
  },
  { ID: 10486, label: 'Аппаратчик очистки газа' },
  { ID: 10488, label: 'Аппаратчик очистки жидкости' },
  { ID: 10490, label: 'Аппаратчик очистки сточных вод' },
  {
    ID: 10492,
    label: 'Аппаратчик парафинирования ткани',
  },
  {
    ID: 10494,
    label: 'Аппаратчик пароводотермического агрегата',
  },
  { ID: 10496, label: 'Аппаратчик пастеризации' },
  { ID: 10501, label: 'Аппаратчик перегонки' },
  {
    ID: 10503,
    label: 'Аппаратчик перегонки и ректификации спирта',
  },
  { ID: 10505, label: 'Аппаратчик перегревания' },
  {
    ID: 10507,
    label: 'Аппаратчик переработки отходов химического производства',
  },
  { ID: 10509, label: 'Аппаратчик переэтерификации' },
  { ID: 10511, label: 'Аппаратчик перколяции' },
  { ID: 10513, label: 'Аппаратчик печей восстановления' },
  { ID: 10515, label: 'Аппаратчик пиролиза' },
  { ID: 10517, label: 'Аппаратчик плавления' },
  { ID: 10519, label: 'Аппаратчик плюсования' },
  { ID: 10521, label: 'Аппаратчик по выработке уксуса' },
  {
    ID: 10523,
    label: 'Аппаратчик по выращиванию монокристаллов и лент',
  },
  {
    ID: 10525,
    label: 'Аппаратчик по выщелачиванию фибры',
  },
  {
    ID: 10527,
    label: 'Аппаратчик подготовки сырья и отпуска полуфабрикатов и продукции',
  },
  {
    ID: 10529,
    label: 'Аппаратчик-подслойщик фотостекла',
  },
  { ID: 10531, label: 'Аппаратчик по загрузке пека' },
  {
    ID: 10532,
    label: 'Аппаратчик по изготовлению клеевой нити',
  },
  {
    ID: 10534,
    label: 'Аппаратчик по изготовлению шлифовальной шкурки',
  },
  {
    ID: 10538,
    label: 'Аппаратчик-поливщик магнитны лент',
  },
  {
    ID: 10540,
    label: 'Аппаратчик-поливщик фотоэмульсий',
  },
  { ID: 10542, label: 'Аппаратчик поликонденсации' },
  { ID: 10544, label: 'Аппаратчик полимеризации' },
  {
    ID: 10546,
    label: 'Аппаратчик получения высокотемпературного пека',
  },
  {
    ID: 10548,
    label: 'Аппаратчик получения высокочистых соединений щелочных металлов',
  },
  {
    ID: 10550,
    label: 'Аппаратчик получения гексахлорбензола',
  },
  {
    ID: 10552,
    label: 'Аппаратчик получения гидроокисных соединений щелочных металлов',
  },
  { ID: 10554, label: 'Аппаратчик получения декстрина' },
  {
    ID: 10556,
    label: 'Аппаратчик получения закиси азота',
  },
  {
    ID: 10558,
    label: 'Аппаратчик получения зародышей двуокиси титана',
  },
  {
    ID: 10566,
    label: 'Аппаратчик получения комплексных соединений',
  },
  {
    ID: 10568,
    label: 'Аппаратчик получения кукурузного масла',
  },
  {
    ID: 10569,
    label: 'Аппаратчик получения кумароновой смолы',
  },
  {
    ID: 10571,
    label: 'Аппаратчик получения лаков и эмалей на полимеризационных смолах',
  },
  {
    ID: 10573,
    label: 'Аппаратчик получения метатитановой кислоты',
  },
  {
    ID: 10575,
    label: 'Аппаратчик получения микронизированных материалов',
  },
  {
    ID: 10577,
    label: 'Аппаратчик получения нитролигнина',
  },
  {
    ID: 10579,
    label: 'Аппаратчик получения окислов металлов',
  },
  { ID: 10581, label: 'Аппаратчик получения пата' },
  {
    ID: 10583,
    label: 'Аппаратчик получения полых микросфер',
  },
  {
    ID: 10587,
    label: 'Аппаратчик получения сернистой кислоты',
  },
  {
    ID: 10589,
    label: 'Аппаратчик получения сероуглерода-сырца',
  },
  {
    ID: 10591,
    label:
      'Аппаратчик получения сильнодействующих алкалоидов и кристаллических гликозидов',
  },
  {
    ID: 10593,
    label: 'Аппаратчик получения синтетических гормонов',
  },
  { ID: 10595, label: 'Аппаратчик получения сиропов' },
  {
    ID: 10598,
    label: 'Аппаратчик получения составов для рентгеновских экранов',
  },
  {
    ID: 10600,
    label: 'Аппаратчик получения сульфата аммония',
  },
  {
    ID: 10602,
    label: 'Аппаратчик получения сухих кормов',
  },
  {
    ID: 10603,
    label: 'Аппаратчик получения сухого крахмала',
  },
  {
    ID: 10605,
    label: 'Аппаратчик получения сырого бензола',
  },
  {
    ID: 10607,
    label: 'Аппаратчик получения сырого крахмала',
  },
  {
    ID: 10609,
    label: 'Аппаратчик получения технического углерода',
  },
  {
    ID: 10611,
    label: 'Аппаратчик получения трихлорпропана и дихлоргидрина',
  },
  {
    ID: 10613,
    label: 'Аппаратчик получения углекислоты',
  },
  { ID: 10615, label: 'Аппаратчик получения фосфатидов' },
  {
    ID: 10616,
    label: 'Аппаратчик получения фотогипосульфита',
  },
  {
    ID: 10618,
    label: 'Аппаратчик получения фурановых соединений',
  },
  {
    ID: 10620,
    label: 'Аппаратчик получения хлорного железа',
  },
  {
    ID: 10622,
    label: 'Аппаратчик получения чистого антрацена',
  },
  {
    ID: 10624,
    label: 'Аппаратчик получения экструзионных продуктов',
  },
  {
    ID: 10625,
    label: 'Аппаратчик по насасыванию диафрагм',
  },
  {
    ID: 10627,
    label: 'Аппаратчик по обработке и купажированию уксуса',
  },
  {
    ID: 10629,
    label: 'Аппаратчик по обработке сырого пектина',
  },
  {
    ID: 10631,
    label: 'Аппаратчик по обслуживанию рекуператоров и системы охлаждения',
  },
  { ID: 10633, label: 'Аппаратчик по окислению кадмия' },
  {
    ID: 10635,
    label:
      'Аппаратчик по получению высокочистых материалов для полупроводникового производства',
  },
  {
    ID: 10637,
    label: 'Аппаратчик по приготовлению глинозема',
  },
  {
    ID: 10639,
    label: 'Аппаратчик по приготовлению майонеза',
  },
  {
    ID: 10641,
    label: 'Аппаратчик по приготовлению химреагентов',
  },
  {
    ID: 10643,
    label: 'Аппаратчик по производству ванадия',
  },
  {
    ID: 10645,
    label:
      'Аппаратчик по производству и химической очистке полупроводниковых материалов',
  },
  {
    ID: 10647,
    label: 'Аппаратчик по производству синтетических клеящих смол',
  },
  {
    ID: 10649,
    label: 'Аппаратчик по производству сухой спичечной соломки',
  },
  {
    ID: 10651,
    label: 'Аппаратчик по разделению редкоземельных элементов',
  },
  {
    ID: 10653,
    label: 'Аппаратчик по регенерации селена',
  },
  { ID: 10655, label: 'Аппаратчик по регенерации серы' },
  {
    ID: 10657,
    label: 'Аппаратчик по сбору и обогащению шлама',
  },
  { ID: 10659, label: 'Аппаратчик по сушке торфа' },
  {
    ID: 10661,
    label: 'Аппаратчик по химической обработке полупроводниковых материалов',
  },
  {
    ID: 10663,
    label: 'Аппаратчик приготовления амальгамы натрия',
  },
  {
    ID: 10665,
    label: 'Аппаратчик приготовления брикетной смеси',
  },
  {
    ID: 10669,
    label: 'Аппаратчик приготовления высокожирных эмульсий',
  },
  {
    ID: 10671,
    label: 'Аппаратчик приготовления дубильных экстрактов',
  },
  {
    ID: 10673,
    label: 'Аппаратчик приготовления замесов',
  },
  {
    ID: 10675,
    label: 'Аппаратчик приготовления зубоврачебных материалов',
  },
  {
    ID: 10677,
    label: 'Аппаратчик приготовления инвертного сиропа',
  },
  {
    ID: 10679,
    label: 'Аппаратчик приготовления каменноугольного лака',
  },
  {
    ID: 10681,
    label: 'Аппаратчик приготовления катализатора',
  },
  {
    ID: 10683,
    label: 'Аппаратчик приготовления компаундов',
  },
  {
    ID: 10685,
    label: 'Аппаратчик приготовления косметических средств',
  },
  {
    ID: 10687,
    label: 'Аппаратчик приготовления кулинарных и кондитерских жиров',
  },
  { ID: 10689, label: 'Аппаратчик приготовления лака' },
  {
    ID: 10693,
    label: 'Аппаратчик приготовления латексной смеси',
  },
  {
    ID: 10695,
    label: 'Аппаратчик приготовления медицинских масс и мазей',
  },
  {
    ID: 10697,
    label: 'Аппаратчик приготовления мездрового клея',
  },
  {
    ID: 10698,
    label: 'Аппаратчик приготовления мыльного клея',
  },
  {
    ID: 10700,
    label: 'Аппаратчик приготовления окисленного крахмала',
  },
  {
    ID: 10702,
    label: 'Аппаратчик приготовления парфюмерных композиций и жидкостей',
  },
  { ID: 10704, label: 'Аппаратчик приготовления пасты' },
  {
    ID: 10706,
    label: 'Аппаратчик приготовления питательных сред',
  },
  {
    ID: 10708,
    label: 'Аппаратчик приготовления полимеризационной смеси',
  },
  {
    ID: 10710,
    label: 'Аппаратчик приготовления препарированной смолы',
  },
  {
    ID: 10712,
    label: 'Аппаратчик приготовления проявляющей пасты',
  },
  {
    ID: 10714,
    label: 'Аппаратчик приготовления прядильных растворов',
  },
  {
    ID: 10716,
    label: 'Аппаратчик приготовления резиновых клеев и покрытий',
  },
  {
    ID: 10718,
    label: 'Аппаратчик приготовления связующих',
  },
  {
    ID: 10720,
    label: 'Аппаратчик приготовления сернокислого глинозема',
  },
  {
    ID: 10722,
    label: 'Аппаратчик приготовления стерильных растворов',
  },
  {
    ID: 10724,
    label: 'Аппаратчик приготовления сырой смеси',
  },
  { ID: 10726, label: 'Аппаратчик приготовления тресты' },
  {
    ID: 10727,
    label: 'Аппаратчик приготовления ферментного препарата',
  },
  {
    ID: 10729,
    label: 'Аппаратчик приготовления химических растворов',
  },
  {
    ID: 10731,
    label: 'Аппаратчик приготовления электролита',
  },
  {
    ID: 10733,
    label: 'Аппаратчик приготовления эмульгатора',
  },
  {
    ID: 10735,
    label: 'Аппаратчик приготовления эмульсий',
  },
  { ID: 10737, label: 'Аппаратчик производства АГ-соли' },
  {
    ID: 10739,
    label: 'Аппаратчик производства адипиновой кислоты',
  },
  {
    ID: 10741,
    label: 'Аппаратчик производства адипонитрила',
  },
  {
    ID: 10743,
    label: 'Аппаратчик производства азокрасителей',
  },
  {
    ID: 10745,
    label: 'Аппаратчик производства альбумина',
  },
  {
    ID: 10747,
    label: 'Аппаратчик производства аммиачной селитры',
  },
  {
    ID: 10749,
    label: 'Аппаратчик производства аэросилы',
  },
  {
    ID: 10751,
    label: 'Аппаратчик производства бакелитовой пленки',
  },
  {
    ID: 10753,
    label: 'Аппаратчик производства бактерийных препаратов',
  },
  {
    ID: 10755,
    label: 'Аппаратчик производства бертолетовой соли',
  },
  {
    ID: 10757,
    label: 'Аппаратчик производства борной кислоты',
  },
  {
    ID: 10758,
    label: 'Аппаратчик производства бульонных кубиков',
  },
  {
    ID: 10761,
    label: 'Аппаратчик производства гематогена и медицинской желчи',
  },
  {
    ID: 10763,
    label: 'Аппаратчик производства гидросульфита натрия',
  },
  {
    ID: 10765,
    label: 'Аппаратчик производства горного воска',
  },
  {
    ID: 10767,
    label: 'Аппаратчик производства двуокиси хлора',
  },
  {
    ID: 10769,
    label: 'Аппаратчик производства диметилтерефталата',
  },
  {
    ID: 10771,
    label: 'Аппаратчик производства дициандиамида',
  },
  {
    ID: 10773,
    label: 'Аппаратчик производства дициклопентадиена',
  },
  {
    ID: 10775,
    label: 'Аппаратчик производства желтого фосфора',
  },
  {
    ID: 10777,
    label: 'Аппаратчик производства жидкого заменителя цельного молока',
  },
  {
    ID: 10779,
    label: 'Аппаратчик производства заквасок',
  },
  { ID: 10780, label: 'Аппаратчик производства индола' },
  {
    ID: 10782,
    label: 'Аппаратчик производства казеинового клея',
  },
  {
    ID: 10784,
    label: 'Аппаратчик производства калиевой селитры',
  },
  {
    ID: 10786,
    label: 'Аппаратчик производства кисломолочных и детских молочных продуктов',
  },
  {
    ID: 10788,
    label: 'Аппаратчик производства контактной массы',
  },
  {
    ID: 10790,
    label: 'Аппаратчик производства контактной серной кислоты',
  },
  { ID: 10792, label: 'Аппаратчик производства корунда' },
  {
    ID: 10794,
    label: 'Аппаратчик производства костного клея',
  },
  {
    ID: 10796,
    label: 'Аппаратчик производства красителей для меха',
  },
  {
    ID: 10798,
    label: 'Аппаратчик производства красного фосфора',
  },
  {
    ID: 10800,
    label: 'Аппаратчик производства кремнийорганических лаков',
  },
  {
    ID: 10802,
    label: 'Аппаратчик производства креолина и лизола',
  },
  {
    ID: 10804,
    label: 'Аппаратчик производства криолита',
  },
  {
    ID: 10806,
    label: 'Аппаратчик производства литопона',
  },
  {
    ID: 10808,
    label: 'Аппаратчик производства малотоннажных продуктов',
  },
  {
    ID: 10810,
    label: 'Аппаратчик производства мездрового клея',
  },
  {
    ID: 10812,
    label: 'Аппаратчик производства металлического натрия',
  },
  {
    ID: 10814,
    label: 'Аппаратчик производства молочного сахара',
  },
  {
    ID: 10816,
    label: 'Аппаратчик производства мочевины',
  },
  {
    ID: 10817,
    label: 'Аппаратчик производства мышьяковистых солей',
  },
  {
    ID: 10819,
    label: 'Аппаратчик производства надперекиси калия',
  },
  {
    ID: 10821,
    label: 'Аппаратчик производства нейтрального кремнегеля',
  },
  {
    ID: 10823,
    label: 'Аппаратчик производства нитрата и нитрита натрия',
  },
  {
    ID: 10824,
    label: 'Аппаратчик производства нитрофоски',
  },
  {
    ID: 10826,
    label: 'Аппаратчик производства озокерита и озокеритовой продукции',
  },
  {
    ID: 10828,
    label: 'Аппаратчик производства пиридиновых оснований',
  },
  {
    ID: 10830,
    label: 'Аппаратчик производства пищевых жиров',
  },
  {
    ID: 10832,
    label: 'Аппаратчик производства плавленого сыра',
  },
  {
    ID: 10835,
    label:
      'Аппаратчик производства продукции из кожевенных и коллагенсодержащих отходов',
  },
  {
    ID: 10836,
    label: 'Аппаратчик производства реагентов',
  },
  {
    ID: 10838,
    label: 'Аппаратчик производства регенеративных веществ',
  },
  {
    ID: 10840,
    label: 'Аппаратчик производства светосоставов',
  },
  {
    ID: 10842,
    label: 'Аппаратчик производства силикагелей',
  },
  {
    ID: 10844,
    label: 'Аппаратчик производства силикатного клея',
  },
  {
    ID: 10846,
    label: 'Аппаратчик производства синтетических красителей',
  },
  {
    ID: 10848,
    label: 'Аппаратчик производства смазочного масла',
  },
  {
    ID: 10850,
    label: 'Аппаратчик производства спектрально-чистых газов',
  },
  {
    ID: 10852,
    label: 'Аппаратчик производства сульфата аммония',
  },
  {
    ID: 10854,
    label: 'Аппаратчик производства сульфитных солей',
  },
  {
    ID: 10855,
    label: 'Аппаратчик производства сульфомасел и ядохимикатов',
  },
  {
    ID: 10857,
    label: 'Аппаратчик производства сухих молочных продуктов',
  },
  {
    ID: 10859,
    label: 'Аппаратчик производства термической фосфорной кислоты',
  },
  {
    ID: 10861,
    label: 'Аппаратчик производства технической продукции',
  },
  {
    ID: 10863,
    label: 'Аппаратчик производства топленого масла',
  },
  {
    ID: 10865,
    label: 'Аппаратчик производства тормозной жидкости и антифризов',
  },
  {
    ID: 10867,
    label: 'Аппаратчик производства фенилметилуретилана',
  },
  {
    ID: 10869,
    label: 'Аппаратчик производства ферментов и плазмозаменяющих препаратов',
  },
  {
    ID: 10871,
    label: 'Аппаратчик производства формованного кокса',
  },
  { ID: 10872, label: 'Аппаратчик производства фосфора' },
  {
    ID: 10874,
    label: 'Аппаратчик производства фосфорных соединений',
  },
  {
    ID: 10876,
    label: 'Аппаратчик производства фталоцианиновых красителей',
  },
  {
    ID: 10878,
    label: 'Аппаратчик производства фтористого натрия',
  },
  {
    ID: 10880,
    label: 'Аппаратчик производства химических реактивов',
  },
  {
    ID: 10882,
    label: 'Аппаратчик производства хромовых соединений',
  },
  {
    ID: 10884,
    label: 'Аппаратчик производства цианистых металлов',
  },
  {
    ID: 10886,
    label: 'Аппаратчик производства цинкового купороса',
  },
  {
    ID: 10887,
    label: 'Аппаратчик производства цинковой пыли',
  },
  {
    ID: 10889,
    label: 'Аппаратчик производства шампанского',
  },
  { ID: 10891, label: 'Аппаратчик прокаливания' },
  { ID: 10893, label: 'Аппаратчик промывки' },
  {
    ID: 10895,
    label: 'Аппаратчик промывки мездры, шерсти, щетины и волоса',
  },
  { ID: 10897, label: 'Аппаратчик пропитки' },
  { ID: 10898, label: 'Аппаратчик пропитки' },
  {
    ID: 10899,
    label: 'Аппаратчик пропитки и сушки асбостальных листов',
  },
  {
    ID: 10901,
    label: 'Аппаратчик пропитки облицовочных материалов',
  },
  { ID: 10903, label: 'Аппаратчик процесса брожения' },
  { ID: 10905, label: 'Аппаратчик разложения' },
  { ID: 10907, label: 'Аппаратчик рассева' },
  { ID: 10909, label: 'Аппаратчик растворения' },
  {
    ID: 10912,
    label: 'Аппаратчик растворения лаковых основ',
  },
  { ID: 10914, label: 'Аппаратчик расщепления жиров' },
  {
    ID: 10916,
    label: 'Аппаратчик рафинации жиров и масел',
  },
  {
    ID: 10918,
    label: 'Аппаратчик рафинирования крахмала',
  },
  {
    ID: 10919,
    label: 'Аппаратчик рафинирования щелочных металлов',
  },
  { ID: 10921, label: 'Аппаратчик регенерации' },
  {
    ID: 10923,
    label: 'Аппаратчик регенерации воскомассы',
  },
  { ID: 10925, label: 'Аппаратчик рекристаллизации' },
  { ID: 10927, label: 'Аппаратчик рекуперации' },
  { ID: 10931, label: 'Аппаратчик сгустителей' },
  { ID: 10935, label: 'Аппаратчик сепарирования' },
  {
    ID: 10937,
    label: 'Аппаратчик сепарирования и флотации',
  },
  { ID: 10939, label: 'Аппаратчик-сернильщик' },
  { ID: 10941, label: 'Аппаратчик сжигания' },
  {
    ID: 10943,
    label: 'Аппаратчик сжигания сероводорода',
  },
  { ID: 10945, label: 'Аппаратчик-сиккативовар' },
  { ID: 10947, label: 'Аппаратчик силиконирования' },
  { ID: 10949, label: 'Аппаратчик синтеза' },
  {
    ID: 10953,
    label: 'Аппаратчик скипидарной установки',
  },
  { ID: 10955, label: 'Аппаратчик смесителей' },
  { ID: 10957, label: 'Аппаратчик смешивания' },
  {
    ID: 10959,
    label: 'Аппаратчик смешивания красителей',
  },
  { ID: 10961, label: 'Аппаратчик созревания оболочки' },
  {
    ID: 10963,
    label: 'Аппаратчик солеобогатительной установки',
  },
  { ID: 10965, label: 'Аппаратчик солеобразования' },
  { ID: 10967, label: 'Аппаратчик составления эмалей' },
  { ID: 10969, label: 'Аппаратчик спекания' },
  { ID: 10971, label: 'Аппаратчик сплавления' },
  { ID: 10972, label: 'Аппаратчик средоварения' },
  { ID: 10973, label: 'Аппаратчик стандартизации' },
  {
    ID: 10974,
    label: 'Аппаратчик стандартизации в производстве пластических масс',
  },
  { ID: 10976, label: 'Аппаратчик стерилизации' },
  {
    ID: 10978,
    label: 'Аппаратчик стерилизации консервов',
  },
  {
    ID: 10981,
    label: 'Аппаратчик сублимационной установки',
  },
  { ID: 10984, label: 'Аппаратчик-сульфировщик' },
  {
    ID: 10986,
    label: 'Аппаратчик сульфитации овощей и фруктов',
  },
  { ID: 10988, label: 'Аппаратчик-сульфитировщик' },
  { ID: 10990, label: 'Аппаратчик сушильной установки' },
  { ID: 10992, label: 'Аппаратчик-сушильщик' },
  { ID: 10994, label: 'Аппаратчик сушки' },
  {
    ID: 10996,
    label: 'Аппаратчик сушки и карбонизации лигнина и целлолигнина',
  },
  {
    ID: 11000,
    label: 'Аппаратчик сушки клея и желатина',
  },
  { ID: 11002, label: 'Аппаратчик сушки кости-паренки' },
  {
    ID: 11004,
    label: 'Аппаратчик сушки спичечных коробок',
  },
  { ID: 11007, label: 'Аппаратчик талловой установки' },
  { ID: 11009, label: 'Аппаратчик теплоутилизации' },
  {
    ID: 11011,
    label: 'Аппаратчик термической активации углей',
  },
  {
    ID: 11013,
    label: 'Аппаратчик термической коагуляции белковых веществ',
  },
  {
    ID: 11017,
    label: 'Аппаратчик термической обработки мясопродуктов',
  },
  {
    ID: 11019,
    label: 'Аппаратчик термической обработки субпродуктов',
  },
  {
    ID: 11021,
    label: 'Аппаратчик термовлажностной обработки',
  },
  {
    ID: 11023,
    label: 'Аппаратчик термообработки коксуемой шихты',
  },
  {
    ID: 11025,
    label: 'Аппаратчик термообработки пластмассовых изделий',
  },
  { ID: 11027, label: 'Аппаратчик термообработки ткани' },
  {
    ID: 11029,
    label: 'Аппаратчик терморелаксации пряжи',
  },
  { ID: 11030, label: 'Аппаратчик термостабилизации' },
  {
    ID: 11032,
    label: 'Аппаратчик томатосокового агрегата',
  },
  { ID: 11034, label: 'Аппаратчик увлажнения' },
  { ID: 11036, label: 'Аппаратчик углеобогащения' },
  { ID: 11038, label: 'Аппаратчик улавливания жиров' },
  {
    ID: 11040,
    label: 'Аппаратчик упаривания и сгущения продуктов',
  },
  {
    ID: 11041,
    label: 'Аппаратчик уплотнения технического углерода',
  },
  {
    ID: 11043,
    label: 'Аппаратчик установки для отделения мяса от кости',
  },
  {
    ID: 11045,
    label: 'Аппаратчик установки нейтрального газа',
  },
  {
    ID: 11047,
    label: 'Аппаратчик установки опытного производства',
  },
  {
    ID: 11049,
    label: 'Аппаратчик установки производства отбельной земли',
  },
  {
    ID: 11050,
    label: 'Аппаратчик утилизации конфискатов',
  },
  { ID: 11052, label: 'Аппаратчик фенилирования' },
  { ID: 11055, label: 'Аппаратчик ферментации затора' },
  {
    ID: 11056,
    label: 'Аппаратчик ферментации препаратов биосинтеза',
  },
  {
    ID: 11057,
    label: 'Аппаратчик ферментации эфиромасличного сырья',
  },
  { ID: 11059, label: 'Аппаратчик фиксации' },
  { ID: 11061, label: 'Аппаратчик фильтрации' },
  { ID: 11063, label: 'Аппаратчик-фирнисовар' },
  {
    ID: 11065,
    label: 'Аппаратчик формования синтетического каучука',
  },
  {
    ID: 11067,
    label: 'Аппаратчик формования химического волокна',
  },
  {
    ID: 11068,
    label: 'Аппаратчик формования целлофановой пленки',
  },
  { ID: 11070, label: 'Аппаратчик форполимеризации' },
  {
    ID: 11074,
    label: 'Аппаратчик фотохимического синтеза',
  },
  { ID: 11076, label: 'Аппаратчик хемосорбции' },
  { ID: 11078, label: 'Аппаратчик химводоочистки' },
  {
    ID: 11079,
    label: 'Аппаратчик химводоочистки электростанции',
  },
  {
    ID: 11081,
    label: 'Аппаратчик химической обработки технического сырья',
  },
  {
    ID: 11083,
    label: 'Аппаратчик химической очистки препаратов биосинтеза',
  },
  { ID: 11085, label: 'Аппаратчик химической чистки' },
  {
    ID: 11088,
    label: 'Аппаратчик хлорирования и станнирования',
  },
  { ID: 11090, label: 'Аппаратчик центрифугирования' },
  { ID: 11094, label: 'Аппаратчик циклизации' },
  { ID: 11095, label: 'Аппаратчик чешуирования' },
  {
    ID: 11097,
    label: 'Аппаратчик чистки ковровых изделий',
  },
  {
    ID: 11099,
    label: 'Аппаратчик чистки пухоперовых изделий',
  },
  { ID: 11100, label: 'Аппаратчик шаровых мельниц' },
  { ID: 11102, label: 'Аппаратчик экстрагирования' },
  {
    ID: 11104,
    label: 'Аппаратчик экстрагирования таннидов',
  },
  { ID: 11106, label: 'Аппаратчик-экстракторщик' },
  { ID: 11108, label: 'Аппаратчик электролиза' },
  {
    ID: 11110,
    label: 'Аппаратчик электролитического обезжиривания',
  },
  {
    ID: 11112,
    label: 'Аппаратчик электрохимического производства тантала',
  },
  {
    ID: 11114,
    label: 'Аппаратчик этаноламиновой установки',
  },
  { ID: 11116, label: 'Аппаратчик этерифакации' },
  {
    ID: 11117,
    label: 'Аппаратчик этиленгликолевой установки',
  },
  { ID: 11119, label: 'Аппретурщик' },
  { ID: 11121, label: 'Арматурщик' },
  { ID: 11122, label: 'Арматурщик' },
  { ID: 11123, label: 'Арматурщик железобетонных судов' },
  {
    ID: 11125,
    label: 'Арматурщик язычковых инструментов',
  },
  { ID: 11127, label: 'Армировщик' },
  { ID: 11129, label: 'Армировщик кабельных изделий' },
  {
    ID: 11131,
    label: 'Армировщик санитарно-строительных изделий',
  },
  {
    ID: 11132,
    label: 'Армировщик электрокерамических изделий',
  },
  { ID: 11134, label: 'Ароматизаторщик' },
  { ID: 11136, label: 'Аспираторщик' },
  { ID: 11138, label: 'Асфальтировщик труб' },
  { ID: 11140, label: 'Асфальтобетонщик' },
  { ID: 11141, label: 'Асфальтобетонщикварильщик' },
  { ID: 11143, label: 'Аэрографист' },
  { ID: 11144, label: 'Аэрографист' },
  {
    ID: 11145,
    label: 'Аэрографист щипковых инструментов',
  },
  { ID: 11147, label: 'Аэрографщик' },
  { ID: 11149, label: 'Аэродромный рабочий' },
  { ID: 11151, label: 'Аэрозольщик' },
  { ID: 11157, label: 'Бакелизаторщик' },
  { ID: 11159, label: 'Бакелитчик (пропитчик)' },
  { ID: 11161, label: 'Балансировщик деталей и узлов' },
  {
    ID: 11163,
    label: 'Балансировщик-заливщик абразивных кругов',
  },
  {
    ID: 11165,
    label: 'Балансировщик инструментов из алмазов и сверхтвердых материалов',
  },
  { ID: 11166, label: 'Балансировщик шин' },
  { ID: 11168, label: 'Бандажировщик' },
  { ID: 11170, label: 'Бандажник' },
  { ID: 11174, label: 'Барильетчик' },
  { ID: 11176, label: 'Бармен' },
  { ID: 11178, label: 'Бассейнщик' },
  { ID: 11180, label: 'Батанщик' },
  { ID: 11182, label: 'Бахромщик' },
  { ID: 11184, label: 'Бегунщик' },
  { ID: 11186, label: 'Бегунщик смесительных бегунов' },
  { ID: 11188, label: 'Беконщик' },
  { ID: 11196, label: 'Бетонщик' },
  { ID: 11200, label: 'Битумщик' },
  { ID: 11202, label: 'Бланшировщик' },
  { ID: 11204, label: 'Блокировщик стеклоизделий' },
  { ID: 11206, label: 'Боец скота' },
  { ID: 11208, label: 'Бондарь' },
  { ID: 11209, label: 'Бондарь-укупорщик' },
  { ID: 11210, label: 'Бондарь-укупорщик' },
  { ID: 11212, label: 'Бортмеханик' },
  {
    ID: 11213,
    label: 'Бортоператор по проверке магистральных трубопроводов',
  },
  { ID: 11214, label: 'Бортоператор грузовых самолетов' },
  { ID: 11215, label: 'Бортоператор (первый, второй' },
  { ID: 11217, label: 'Бортпроводник' },
  { ID: 11218, label: 'Бортрадист' },
  { ID: 11219, label: 'Бортштурман' },
  { ID: 11220, label: 'Боцман' },
  { ID: 11221, label: 'Боцман береговой' },
  { ID: 11222, label: 'Бригадир бункеров' },
  { ID: 11223, label: 'Бригадир бурильного отделения' },
  { ID: 11224, label: 'Бригадир двора изложниц' },
  {
    ID: 11225,
    label: 'Бригадир заготовительного отделения',
  },
  {
    ID: 11226,
    label: 'Бригадир изыскательской русловой партии',
  },
  {
    ID: 11228,
    label: 'Бригадир колодцев замедленного охлаждения металла',
  },
  { ID: 11230, label: 'Бригадир молотового отделения' },
  {
    ID: 11231,
    label: 'Бригадир муфтосварочного отделения',
  },
  {
    ID: 11233,
    label:
      'Бригадир на отделке, сортировке, приемке, сдаче, пакетировке и упаковке металла и готовой продукции',
  },
  {
    ID: 11235,
    label: 'Бригадир-настройщик трубоэлектросварочных станов',
  },
  {
    ID: 11237,
    label: 'Бригадир на участках основного производства',
  },
  {
    ID: 11239,
    label: 'Бригадир обдирочно-зачистного отделения',
  },
  {
    ID: 11241,
    label:
      'Бригадир (освобожденный) по текущему содержанию и ремонту пути и искусственных сооружений',
  },
  {
    ID: 11242,
    label:
      'Бригадир (освобожденный) предприятий железнодорожного транспорта и метрополитенов',
  },
  {
    ID: 11244,
    label: 'Бригадир осмотра и механообработки колес',
  },
  {
    ID: 11246,
    label: 'Бригадир отделения калибровки, волочения и шлифовки металла',
  },
  {
    ID: 11248,
    label: 'Бригадир отделения центробежных машин',
  },
  {
    ID: 11250,
    label: 'Бригадир подготовительного отделения',
  },
  {
    ID: 11251,
    label:
      'Бригадир по перемещению сырья, полуфабрикатов и готовой продукции в процессе производства',
  },
  {
    ID: 11253,
    label: 'Бригадир поста судоходной обстановки',
  },
  { ID: 11255, label: 'Бригадир разливочных машин' },
  {
    ID: 11257,
    label: 'Бригадир разработки шлакового отвала',
  },
  { ID: 11259, label: 'Бригадир рудного двора' },
  {
    ID: 11263,
    label: 'Бригадир трубоволочильного отделения',
  },
  {
    ID: 11265,
    label: 'Бригадир участка прессов-расширителей',
  },
  {
    ID: 11267,
    label: 'Бригадир участка приготовления формовочной смеси',
  },
  {
    ID: 11269,
    label: 'Бригадир участка станов холодной прокатки труб',
  },
  {
    ID: 11270,
    label:
      'Бригадир шихтового двора в сталеплавильном и ферросплавном производствах',
  },
  { ID: 11272, label: 'Брикетировщик' },
  { ID: 11274, label: 'Брикетировщик формовочной массы' },
  { ID: 11276, label: 'Бронеобмотчик проводов' },
  { ID: 11278, label: 'Бронзировщик' },
  {
    ID: 11280,
    label: 'Бронзировщик рам клавишных инструментов',
  },
  { ID: 11282, label: 'Бронировщик кабелей' },
  { ID: 11284, label: 'Брошюровщик' },
  { ID: 11286, label: 'Брынзодел' },
  { ID: 11287, label: 'Брынзодел-мастер' },
  { ID: 11289, label: 'Бункеровщик' },
  { ID: 11290, label: 'Бункеровщик доменных печей' },
  {
    ID: 11292,
    label: 'Бурильщик капитального ремонта скважин',
  },
  {
    ID: 11294,
    label: 'Бурильщик плавучего бурильного агрегата в море',
  },
  { ID: 11295, label: 'Бурильщик шпуров' },
  {
    ID: 11297,
    label:
      'Бурильщик эксплуатационного и разведочного бурения скважин на нефть и газ',
  },
  { ID: 11298, label: 'Буртоукладчик' },
  { ID: 11300, label: 'Бутафор' },
  { ID: 11301, label: 'Буфетчик' },
  { ID: 11305, label: 'Вагонетчик' },
  {
    ID: 11307,
    label: 'Вагонетчик воздушно-канатной дороги',
  },
  { ID: 11308, label: 'Вагранщик' },
  { ID: 11309, label: 'Вагранщик' },
  {
    ID: 11311,
    label: 'Вакуум-прессовщик керамической массы и заготовок',
  },
  { ID: 11313, label: 'Вакуумщик' },
  { ID: 11315, label: 'Вакуумщик термосных колб' },
  { ID: 11316, label: 'Вакуумщик триплекса и блоков' },
  { ID: 11317, label: 'Вальцовщик' },
  { ID: 11318, label: 'Вальцовщик' },
  { ID: 11319, label: 'Вальцовщик' },
  {
    ID: 11320,
    label: 'Вальцовщик гидроизоляционных материалов',
  },
  { ID: 11322, label: 'Вальцовщик игл' },
  {
    ID: 11324,
    label: 'Вальцовщик игольчатых роликов и шариков',
  },
  { ID: 11326, label: 'Вальцовщик калибровочного стана' },
  { ID: 11328, label: 'Вальцовщик керамической пленки' },
  {
    ID: 11329,
    label: 'Вальцовщик кожевенных материалов',
  },
  { ID: 11331, label: 'Вальцовщик косметической массы' },
  {
    ID: 11333,
    label: 'Вальцовщик массы на вулканитовой связке',
  },
  { ID: 11335, label: 'Вальцовщик обкатной машины' },
  {
    ID: 11337,
    label: 'Вальцовщик по сборке и перевалке клетей',
  },
  {
    ID: 11339,
    label: 'Вальцовщик проволоки для спиралей',
  },
  {
    ID: 11340,
    label: 'Вальцовщик профилегибочного агрегата',
  },
  { ID: 11342, label: 'Вальцовщик резиновых смесей' },
  {
    ID: 11344,
    label: 'Вальцовщик стана горячего проката труб',
  },
  {
    ID: 11345,
    label: 'Вальцовщик стана горячей прокатки',
  },
  {
    ID: 11347,
    label: 'Вальцовщик стана печной сварки труб',
  },
  {
    ID: 11349,
    label: 'Вальцовщик стана холодного проката труб',
  },
  {
    ID: 11350,
    label: 'Вальцовщик стана холодной прокатки',
  },
  {
    ID: 11352,
    label: 'Вальцовщик сырья и полуфабрикатов',
  },
  {
    ID: 11354,
    label: 'Вальцовщик трубоформовочного стана',
  },
  { ID: 11355, label: 'Вальцовщик фибровых трубок' },
  { ID: 11357, label: 'Вальцовщик холодного металла' },
  { ID: 11359, label: 'Вальщик леса' },
  { ID: 11361, label: 'Валяльщик' },
  {
    ID: 11363,
    label: 'Варильщик химических составов для варки отходов',
  },
  { ID: 11364, label: 'Варщик' },
  { ID: 11365, label: 'Варщик' },
  { ID: 11366, label: 'Варщик аппрета' },
  { ID: 11368, label: 'Варщик асфальтовой массы' },
  { ID: 11370, label: 'Варщик битума' },
  { ID: 11372, label: 'Варщик волокнистого сырья' },
  {
    ID: 11373,
    label: 'Варщик восковой, клеевой массы и пропиточной смеси',
  },
  { ID: 11375, label: 'Варщик гипса' },
  { ID: 11379, label: 'Варщик жидкого стекла' },
  { ID: 11381, label: 'Варщик кабельной массы' },
  { ID: 11382, label: 'Варщик косметической массы' },
  { ID: 11384, label: 'Варщик литейных смазок' },
  { ID: 11386, label: 'Варщик пека' },
  {
    ID: 11388,
    label: 'Варщик пищевого сырья и продуктов',
  },
  { ID: 11390, label: 'Варщик селена' },
  {
    ID: 11392,
    label: 'Варщик сиропов, соков, экстрактов',
  },
  { ID: 11394, label: 'Варщик смолки' },
  { ID: 11395, label: 'Варщик суспензий' },
  { ID: 11397, label: 'Варщик торфомассы' },
  { ID: 11398, label: 'Варщик тряпья' },
  {
    ID: 11400,
    label: 'Варщик химической древесной массы',
  },
  { ID: 11402, label: 'Варщик хлопка' },
  { ID: 11404, label: 'Варщик целлюлозы' },
  { ID: 11406, label: 'Варщик шубного лоскута' },
  { ID: 11407, label: 'Варщик щетины и волоса' },
  {
    ID: 11410,
    label: 'Варщик электроизоляционных лаков, смол и мастик',
  },
  { ID: 11412, label: 'Ватинщик' },
  { ID: 11414, label: 'Вафельщик' },
  { ID: 11416, label: 'Велюрщик' },
  {
    ID: 11418,
    label: 'Вентилевой гидравлического пресса',
  },
  { ID: 11420, label: 'Верховой доменной печи' },
  { ID: 11422, label: 'Весовщик' },
  { ID: 11423, label: 'Весовщик-счетчик' },
  { ID: 11425, label: 'Веяльщик чая' },
  { ID: 11427, label: 'Вздымщик' },
  { ID: 11429, label: 'Взрывник' },
  { ID: 11431, label: 'Взрывник на лесных работах' },
  { ID: 11433, label: 'Виварщик' },
  { ID: 11435, label: 'Вивщик колец' },
  { ID: 11436, label: 'Видеотекарь' },
  { ID: 11438, label: 'Винипластчик' },
  { ID: 11439, label: 'Виноградарь' },
  { ID: 11440, label: 'Витражист' },
  { ID: 11442, label: 'Водитель автомобиля' },
  { ID: 11444, label: 'Водитель аэросаней' },
  { ID: 11447, label: 'Водитель вездехода' },
  { ID: 11448, label: 'Водитель дрезины' },
  { ID: 11449, label: 'Водитель-испытатель' },
  {
    ID: 11450,
    label: 'Водитель-испытатель боевых и специальных машин',
  },
  {
    ID: 11451,
    label: 'Водитель мототранспортных средств',
  },
  { ID: 11453, label: 'Водитель погрузчика' },
  { ID: 11457, label: 'Водитель трамвая' },
  {
    ID: 11460,
    label: 'Водитель транспортно-уборочной машины',
  },
  { ID: 11462, label: 'Водитель троллейбуса' },
  { ID: 11463, label: 'Водитель электро- и автотележки' },
  { ID: 11465, label: 'Водолаз' },
  { ID: 11467, label: 'Водопроводчик доменной печи' },
  { ID: 11469, label: 'Водопроводчик шахтной печи' },
  { ID: 11471, label: 'Водораздатчик' },
  { ID: 11472, label: 'Водораздатчик порта' },
  { ID: 11474, label: 'Водородчик' },
  { ID: 11476, label: 'Возчик' },
  { ID: 11478, label: 'Возчик леса' },
  {
    ID: 11480,
    label: 'Волнировщик асбестоцементных листов',
  },
  { ID: 11482, label: 'Волочильщик' },
  {
    ID: 11484,
    label: 'Волочильщик материала для спиралей',
  },
  { ID: 11486, label: 'Волочильщик проволоки' },
  { ID: 11487, label: 'Волочильщик цветных металлов' },
  { ID: 11489, label: 'Волочильщик труб' },
  { ID: 11490, label: 'Воронильщик' },
  {
    ID: 11492,
    label: 'Вставщик деталей, изделий и фурнитуры',
  },
  { ID: 11493, label: 'Вставщик камер' },
  { ID: 11495, label: 'Вулканизаторщик' },
  {
    ID: 11497,
    label: 'Вулканизаторщик кабельных изделий',
  },
  {
    ID: 11498,
    label: 'Вулканизаторщик кругов на вулканитовой связке',
  },
  { ID: 11500, label: 'Вулканизаторщик печатных форм' },
  { ID: 11502, label: 'Выбивальщик мягкой тары' },
  { ID: 11504, label: 'Выбивальщик отливок' },
  { ID: 11505, label: 'Выбивщик блоков мипоры' },
  {
    ID: 11507,
    label: 'Выбивщик изделий из гипсовых форм',
  },
  { ID: 11511, label: 'Выбивщик титановой губки' },
  { ID: 11513, label: 'Выборщик' },
  { ID: 11515, label: 'Выборщик камней' },
  { ID: 11517, label: 'Выборщик металлического натрия' },
  { ID: 11519, label: 'Выборщик солодкового корня' },
  {
    ID: 11521,
    label: 'Выборщик-сортировщик огнеупорного лома',
  },
  { ID: 11522, label: 'Выборщик-укладчик камня' },
  {
    ID: 11524,
    label: 'Выборщик фарфоровых, фаянсовых и керамических изделий',
  },
  { ID: 11526, label: 'Выгребальщик костры' },
  { ID: 11528, label: 'Выгребальщик очеса' },
  { ID: 11529, label: 'Выгребальщик пуха и отходов' },
  { ID: 11531, label: 'Выгружальщик хлопка' },
  { ID: 11532, label: 'Выгрузчик блоков полимера' },
  { ID: 11534, label: 'Выгрузчик горячего агломерата' },
  { ID: 11536, label: 'Выгрузчик древесины из воды' },
  { ID: 11538, label: 'Выгрузчик извести из печей' },
  { ID: 11540, label: 'Выгрузчик мышьяка' },
  {
    ID: 11541,
    label: 'Выгрузчик огнеупорных материалов из печей',
  },
  { ID: 11543, label: 'Выгрузчик на отвалах' },
  { ID: 11545, label: 'Выгрузчик пыли' },
  { ID: 11546, label: 'Выгрузчик шахтных печей' },
  { ID: 11548, label: 'Выдувальщик стеклоизделий' },
  { ID: 11550, label: 'Выемщик варочных камер' },
  { ID: 11552, label: 'Выжигальщик древесного угля' },
  { ID: 11554, label: 'Выжигальщик по дереву' },
  { ID: 11555, label: 'Выжигальщик рисунков' },
  {
    ID: 11557,
    label: 'Вызывальщик локомотивных и поездных бригад',
  },
  { ID: 11559, label: 'Выливщик-заливщик металла' },
  { ID: 11561, label: 'Выпарщик капо-корня' },
  { ID: 11563, label: 'Выпарщик соли' },
  { ID: 11564, label: 'Выпарщик щелоков' },
  { ID: 11566, label: 'Выравнивальщик кожаных деталей' },
  {
    ID: 11568,
    label: 'Выравнивальщик толщины деталей и полуфабрикатов',
  },
  { ID: 11570, label: 'Вырезальщик фестонов' },
  { ID: 11572, label: 'Вырубщик деталей' },
  { ID: 11574, label: 'Вырубщик заготовок и изделий' },
  { ID: 11576, label: 'Вытягивальщик ремней' },
  { ID: 11578, label: 'Выставщик' },
  {
    ID: 11579,
    label: 'Выстилальщик кожевенно-мехового сырья и голья',
  },
  { ID: 11581, label: 'Вышивальщик по коже и меху' },
  { ID: 11583, label: 'Вышивальщица' },
  {
    ID: 11585,
    label: 'Вышивальщица текстильно-галантерейных изделий',
  },
  { ID: 11587, label: 'Вышкомонтажник' },
  { ID: 11588, label: 'Вышкомонтажник-сварщик' },
  { ID: 11590, label: 'Вышкомонтажник-электромонтер' },
  { ID: 11592, label: 'Вязальщик' },
  { ID: 11594, label: 'Вязальщик польстерных щеток' },
  { ID: 11596, label: 'Вязальщик прутков и проволоки' },
  {
    ID: 11598,
    label: 'Вязальщик схемных жгутов, кабелей и шнуров',
  },
  {
    ID: 11600,
    label: 'Вязальщица текстильно-галантерейных изделий',
  },
  {
    ID: 11602,
    label: 'Вязальщица трикотажных изделий, полотна',
  },
  { ID: 11607, label: 'Газовщик' },
  { ID: 11609, label: 'Газовщик доменной печи' },
  { ID: 11611, label: 'Газовщик коксовых печей' },
  { ID: 11612, label: 'Газовщик шахтной печи' },
  { ID: 11614, label: 'Газогенераторщик' },
  { ID: 11616, label: 'Газомерщик' },
  { ID: 11618, label: 'Газорезчик' },
  { ID: 11620, label: 'Газосварщик' },
  { ID: 11622, label: 'Газоспасатель' },
  {
    ID: 11624,
    label: 'Галтовщик деталей часов и камней',
  },
  {
    ID: 11625,
    label: 'Галтовщик игольно-платинных изделий',
  },
  { ID: 11627, label: 'Галтовщик радиодеталей' },
  { ID: 11629, label: 'Гальваник' },
  { ID: 11631, label: 'Гальванотипист' },
  { ID: 11633, label: 'Гардеробщик' },
  {
    ID: 11635,
    label: 'Гарнировщик музыкальных инструментов',
  },
  {
    ID: 11636,
    label: 'Гарнитурщик химического прядения',
  },
  { ID: 11640, label: 'Гасильщик извести' },
  { ID: 11642, label: 'Гелиотропист' },
  { ID: 11644, label: 'Генераторщик' },
  {
    ID: 11646,
    label: 'Генераторщик ацетиленовой установки',
  },
  { ID: 11648, label: 'Герметизаторщик' },
  { ID: 11652, label: 'Гибщик судовой' },
  { ID: 11654, label: 'Гибщик труб' },
  { ID: 11655, label: 'Гибщик фибры' },
  { ID: 11659, label: 'Гидрогенизаторщик' },
  { ID: 11663, label: 'Гидромониторщик' },
  { ID: 11664, label: 'Гидропескоструйщик' },
  { ID: 11666, label: 'Гидротермист' },
  { ID: 11668, label: 'Гидроциклонщик' },
  { ID: 11670, label: 'Гидрочистильщик' },
  { ID: 11672, label: 'Гильошир' },
  { ID: 11674, label: 'Гильоширщик' },
  { ID: 11676, label: 'Гладильщик' },
  { ID: 11678, label: 'Глазировщик' },
  { ID: 11680, label: 'Глазировщик мороженого и сырков' },
  {
    ID: 11682,
    label: 'Глазуровщик изделий строительной керамики',
  },
  {
    ID: 11684,
    label: 'Глазуровщик фарфоровых и фаянсовых изделий',
  },
  {
    ID: 11686,
    label: 'Глазуровщик электрокерамических изделий',
  },
  { ID: 11690, label: 'Гнутарь по дереву' },
  { ID: 11691, label: 'Голлендорщик' },
  { ID: 11693, label: 'Гончар' },
  { ID: 11695, label: 'Горничная' },
  { ID: 11697, label: 'Горновой десульфурации чугуна' },
  { ID: 11699, label: 'Горновой доменной печи' },
  {
    ID: 11700,
    label: 'Горновой на агломерации и обжиге',
  },
  { ID: 11702, label: 'Горновой ферросплавных печей' },
  { ID: 11704, label: 'Горновой шахтной печи' },
  { ID: 11706, label: 'Горномонтажник подземный' },
  { ID: 11708, label: 'Горнорабочий' },
  {
    ID: 11710,
    label: 'Горнорабочий на геологических работах',
  },
  {
    ID: 11711,
    label: 'Горнорабочий на маркшейдерских работах',
  },
  { ID: 11715, label: 'Горнорабочий очистного забоя' },
  { ID: 11717, label: 'Горнорабочий подземный' },
  {
    ID: 11719,
    label: 'Горнорабочий по предупреждению и тушению пожаров',
  },
  {
    ID: 11721,
    label: 'Горнорабочий по ремонту горных выработок',
  },
  { ID: 11723, label: 'Горнорабочий разреза' },
  {
    ID: 11725,
    label: 'Горнорабочий россыпных месторождений',
  },
  {
    ID: 11726,
    label:
      'Горнорабочий у экскаваторов, отвальных мостов и отвалообразователей',
  },
  { ID: 11728, label: 'Гофрировщик винипласта' },
  { ID: 11730, label: 'Гофрировщик меховых камер' },
  {
    ID: 11731,
    label: 'Гофрировщик протезов кровеносных сосудов',
  },
  { ID: 11733, label: 'Гофрировщик трубок' },
  { ID: 11735, label: 'Гравер' },
  { ID: 11736, label: 'Гравер' },
  { ID: 11737, label: 'Гравер валов' },
  {
    ID: 11739,
    label: 'Гравер оригиналов топографических карт',
  },
  { ID: 11741, label: 'Гравер печатных форм' },
  { ID: 11743, label: 'Гравер стекла' },
  { ID: 11745, label: 'Гравер шрифта' },
  { ID: 11747, label: 'Градуировщик' },
  { ID: 11748, label: 'Градуировщик оптических деталей' },
  { ID: 11750, label: 'Градуировщик радиоаппаратуры' },
  { ID: 11752, label: 'Гранулировщик' },
  { ID: 11753, label: 'Гранулировщик' },
  { ID: 11754, label: 'Грануляторщик' },
  { ID: 11756, label: 'Грануляторщик доменного шлака' },
  { ID: 11758, label: 'Гратосъемщик' },
  { ID: 11760, label: 'Графитировщик' },
  {
    ID: 11761,
    label: 'Графитировщик асбестовых технических изделий',
  },
  { ID: 11763, label: 'Гример-пастижер' },
  { ID: 11765, label: 'Грохотовщик' },
  { ID: 11766, label: 'Грохотчик-шуровщик' },
  { ID: 11768, label: 'Грузчик' },
  { ID: 11770, label: 'Грунтовальщик' },
  {
    ID: 11772,
    label: 'Грунтовщик асбестоцементных и асбесто-силитовых изделий',
  },
  {
    ID: 11774,
    label: 'Грунтовщик холста и картона художественных полотен',
  },
  {
    ID: 11776,
    label: 'Грунтовщик художественных изделий из дерева',
  },
  { ID: 11777, label: 'Грунтовщик щеточных изделий' },
  { ID: 11779, label: 'Гуммировщик металлоизделий' },
  { ID: 11781, label: 'Гуртовщик' },
  { ID: 11783, label: 'Давильщик' },
  { ID: 11784, label: 'Дверевой' },
  { ID: 11786, label: 'Дворник' },
  { ID: 11790, label: 'Девулканизаторщик' },
  { ID: 11792, label: 'Дегоржер' },
  {
    ID: 11794,
    label: 'Дежурный зала игральных автоматов, аттракционов и тиров',
  },
  { ID: 11796, label: 'Дежурный по переезду' },
  { ID: 11800, label: 'Дежурный стрелочного поста' },
  { ID: 11802, label: 'Дежурный у эскалатора' },
  { ID: 11804, label: 'Дезактиваторщик' },
  { ID: 11806, label: 'Дезинфектор' },
  { ID: 11807, label: 'Дезодораторщик сои' },
  { ID: 11809, label: 'Декатировщик' },
  { ID: 11811, label: 'Декоратор витрин' },
  { ID: 11813, label: 'Делильщик кружев' },
  { ID: 11815, label: 'Демонстратор одежды' },
  { ID: 11816, label: 'Демонстратор пластических поз' },
  { ID: 11817, label: 'Демонстратор причесок' },
  { ID: 11818, label: 'Денатураторщик спирта' },
  { ID: 11822, label: 'Дефектовщик авиационной техники' },
  {
    ID: 11827,
    label: 'Дефектоскопист по газовому и жидкостному контролю',
  },
  {
    ID: 11830,
    label: 'Дефектоскопист по магнитному и ультразвуковому контролю',
  },
  {
    ID: 11833,
    label: 'Дефектоскопист рентгено-, гамма-графирования',
  },
  { ID: 11835, label: 'Дефибрерщик' },
  { ID: 11837, label: 'Дефростатчик пищевых продуктов' },
  {
    ID: 11838,
    label: 'Дизелист плавучего бурильного агрегата в море',
  },
  { ID: 11840, label: 'Дистиллировщик ртути' },
  { ID: 11842, label: 'Дистилляторщик ртути' },
  { ID: 11843, label: 'Диффузорщик целлюлозы' },
  { ID: 11845, label: 'Дневальный' },
  { ID: 11847, label: 'Доводчик' },
  {
    ID: 11849,
    label: 'Доводчик алмазо-содержащих концентратов',
  },
  { ID: 11851, label: 'Доводчик деталей' },
  { ID: 11853, label: 'Доводчик-притирщик' },
  { ID: 11854, label: 'Доводчик угольных шайб' },
  { ID: 11856, label: 'Дозиметрист' },
  { ID: 11858, label: 'Дозировщик' },
  { ID: 11860, label: 'Дозировщик асбеста' },
  { ID: 11862, label: 'Дозировщик волокна и пленки' },
  { ID: 11863, label: 'Дозировщик горячего возврата' },
  {
    ID: 11865,
    label: 'Дозировщик дробленого камня и битума',
  },
  {
    ID: 11867,
    label: 'Дозировщик керамических материалов',
  },
  {
    ID: 11869,
    label: 'Дозировщик компонентов бетонных смесей',
  },
  {
    ID: 11871,
    label: 'Дозировщик медицинских препаратов',
  },
  { ID: 11872, label: 'Дозировщик минерализатора' },
  { ID: 11874, label: 'Дозировщик пищевой продукции' },
  { ID: 11876, label: 'Дозировщик реагентов' },
  { ID: 11878, label: 'Дозировщик ртути' },
  {
    ID: 11880,
    label: 'Дозировщик-смесительщик на шнеках',
  },
  { ID: 11881, label: 'Дозировщик сырья' },
  { ID: 11883, label: 'Долбежник' },
  { ID: 11885, label: 'Долотозаправщик' },
  { ID: 11887, label: 'Дорожно-путевой рабочий' },
  { ID: 11889, label: 'Дорожный рабочий' },
  {
    ID: 11891,
    label: 'Доставщик крепежных материалов в шахту',
  },
  { ID: 11893, label: 'Доставщик поездных документов' },
  { ID: 11895, label: 'Дояр' },
  { ID: 11897, label: 'Драгер' },
  { ID: 11899, label: 'Дражировщик' },
  { ID: 11901, label: 'Драпировщик' },
  { ID: 11902, label: 'Древопар' },
  { ID: 11904, label: 'Дренажист по добыче мирабилита' },
  { ID: 11906, label: 'Дренажник' },
  { ID: 11907, label: 'Дробильщик' },
  { ID: 11908, label: 'Дробильщик' },
  { ID: 11909, label: 'Дробильщик' },
  {
    ID: 11910,
    label: 'Дробильщик алмазов и сверхтвердых материалов',
  },
  { ID: 11912, label: 'Дробильщик извести' },
  { ID: 11914, label: 'Дробильщик колчедана' },
  { ID: 11916, label: 'Дробильщик компонентов обмазки' },
  { ID: 11917, label: 'Дробильщик-размольщик' },
  { ID: 11918, label: 'Дробильщик-размольщик' },
  { ID: 11919, label: 'Дробильщик-размольщик' },
  { ID: 11920, label: 'Дробильщик слюды' },
  {
    ID: 11922,
    label: 'Дробильщик теплоизоляционного сырья',
  },
  { ID: 11924, label: 'Дробильщик целлюлозы' },
  {
    ID: 11925,
    label: 'Дробильщик шлифзерна, шлифпорошков и шихтовых материалов',
  },
  {
    ID: 11927,
    label: 'Дробильщик электро-угольного производства',
  },
  { ID: 11929, label: 'Дровокол' },
  { ID: 11930, label: 'Дрожжевод' },
  { ID: 11932, label: 'Дублировщик' },
  {
    ID: 11934,
    label: 'Дублировщик деталей и материалов',
  },
  { ID: 11936, label: 'Дублировщик листового материала' },
  { ID: 11939, label: 'Егерь' },
  { ID: 11943, label: 'Жаровщик' },
  { ID: 11945, label: 'Жестянщик' },
  { ID: 11947, label: 'Живописец' },
  { ID: 11949, label: 'Животновод' },
  {
    ID: 11951,
    label: 'Животновод по уходу за рабочими животными',
  },
  { ID: 11953, label: 'Жиловщик мяса и субпродуктов' },
  { ID: 11955, label: 'Жировальщик кож' },
  { ID: 11957, label: 'Жокей' },
  { ID: 11961, label: 'Забойщик' },
  { ID: 11963, label: 'Забойщик на отбойных молотках' },
  {
    ID: 11965,
    label: 'Заборщик фарфоровых, фаянсовых и керамических изделий',
  },
  { ID: 11967, label: 'Завальцовщик' },
  { ID: 11969, label: 'Завальцовщик радиодеталей' },
  {
    ID: 11971,
    label: 'Завальщик шихты в вагранки и печи',
  },
  { ID: 11973, label: 'Заварщик' },
  { ID: 11974, label: 'Заварщик изоляторов' },
  {
    ID: 11976,
    label: 'Заварщик на высокочастотном индукторе',
  },
  { ID: 11978, label: 'Заварщик отливок' },
  { ID: 11980, label: 'Заварщик пасты' },
  {
    ID: 11982,
    label: 'Заварщик полупроводниковых приборов',
  },
  { ID: 11984, label: 'Заварщик труб и баллонов' },
  {
    ID: 11985,
    label: 'Заварщик электровакуумных приборов',
  },
  { ID: 11989, label: 'Завивальщик спиралей' },
  { ID: 11990, label: 'Заготовитель блоков по ботанике' },
  {
    ID: 11993,
    label: 'Заготовитель блоков по гистологии, цитологии, эмбриологии',
  },
  {
    ID: 11995,
    label: 'Заготовитель геологических образцов',
  },
  { ID: 11997, label: 'Заготовитель продуктов и сырья' },
  {
    ID: 11999,
    label: 'Заготовитель смеси для цементации',
  },
  { ID: 12001, label: 'Заготовщик' },
  { ID: 12002, label: 'Заготовщик' },
  { ID: 12003, label: 'Заготовщик абразивной массы' },
  { ID: 12005, label: 'Заготовщик асбестовой смески' },
  {
    ID: 12006,
    label: 'Заготовщик асбестовых технических изделий',
  },
  {
    ID: 12008,
    label: 'Заготовщик бакелитовой, вулканитовой и эпоксидной массы',
  },
  { ID: 12010, label: 'Заготовщик бинта' },
  { ID: 12012, label: 'Заготовщик битума' },
  { ID: 12013, label: 'Заготовщик бумажных пакетов' },
  { ID: 12015, label: 'Заготовщик водорослей' },
  { ID: 12017, label: 'Заготовщик газопоглотителя' },
  {
    ID: 12019,
    label:
      'Заготовщик деталей и материалов к ювелирным и художественным изделиям',
  },
  {
    ID: 12021,
    label: 'Заготовщик деталей электровакуумных приборов',
  },
  { ID: 12023, label: 'Заготовщик изоляционных деталей' },
  {
    ID: 12025,
    label: 'Заготовщик каркаса спортивных велошин',
  },
  {
    ID: 12028,
    label: 'Заготовщик карт и паковочных материалов',
  },
  { ID: 12030, label: 'Заготовщик клапанов' },
  { ID: 12032, label: 'Заготовщик льда' },
  {
    ID: 12034,
    label: 'Заготовщик материалов для прессования и отделки игрушек',
  },
  {
    ID: 12035,
    label:
      'Заготовщик материалов для художественных изделий из дерева, бересты, капо-корня',
  },
  { ID: 12037, label: 'Заготовщик материалов и деталей' },
  {
    ID: 12039,
    label: 'Заготовщик материалов и деталей игрушек',
  },
  { ID: 12041, label: 'Заготовщик микалексовой массы' },
  { ID: 12042, label: 'Заготовщик мягкой тары' },
  {
    ID: 12044,
    label: 'Заготовщик образцов для испытания шин',
  },
  {
    ID: 12046,
    label: 'Заготовщик основы для моющих средств',
  },
  { ID: 12048, label: 'Заготовщик пленки' },
  {
    ID: 12050,
    label: 'Заготовщик полуфабрикатов для игрушек',
  },
  {
    ID: 12051,
    label: 'Заготовщик полуфабрикатов перевязочных материалов',
  },
  {
    ID: 12053,
    label: 'Заготовщик радиотакелажа и электрорадиоэлементов',
  },
  {
    ID: 12055,
    label: 'Заготовщик резиновых изделий и деталей',
  },
  { ID: 12057, label: 'Заготовщик слюды' },
  {
    ID: 12058,
    label: 'Заготовщик смеси для строительных плит из костры',
  },
  {
    ID: 12060,
    label: 'Заготовщик такелажа инженерного имущества',
  },
  { ID: 12062, label: 'Заготовщик тростника' },
  {
    ID: 12064,
    label: 'Заготовщик химических полуфабрикатов',
  },
  {
    ID: 12066,
    label: 'Заготовщик химических полуфабрикатов тугоплавких металлов',
  },
  {
    ID: 12068,
    label: 'Заготовщик химических растворов и красок',
  },
  { ID: 12070, label: 'Заготовщик чайных ящиков' },
  { ID: 12071, label: 'Заготовщик черни' },
  {
    ID: 12073,
    label: 'Заготовщик шиноремонтных материалов',
  },
  {
    ID: 12075,
    label: 'Заготовщик шорно-седельных изделий',
  },
  {
    ID: 12076,
    label: 'Заготовщик шприцованных деталей для шин',
  },
  { ID: 12078, label: 'Заготовщик янтаря' },
  { ID: 12080, label: 'Загрузчик' },
  { ID: 12082, label: 'Загрузчик балансов в дефибреры' },
  { ID: 12084, label: 'Загрузчик варочных котлов' },
  { ID: 12086, label: 'Загрузчик-выгрузчик' },
  {
    ID: 12088,
    label:
      'Загрузчик-выгрузчик абразивных изделий в периодические обжигательные печи',
  },
  {
    ID: 12090,
    label: 'Загрузчик-выгрузчик вагранок и печей',
  },
  {
    ID: 12091,
    label: 'Загрузчик (выгрузчик) диффузоров',
  },
  {
    ID: 12093,
    label: 'Загрузчик-выгрузчик обжигательных печей',
  },
  {
    ID: 12095,
    label: 'Загрузчик-выгрузчик обжиговых и графитировочных печей',
  },
  { ID: 12097, label: 'Загрузчик-выгрузчик печей' },
  {
    ID: 12098,
    label: 'Загрузчик-выгрузчик печей обжига и графитации',
  },
  {
    ID: 12100,
    label: 'Загрузчик-выгрузчик пищевой продукции',
  },
  { ID: 12102, label: 'Загрузчик-выгрузчик сушил' },
  {
    ID: 12104,
    label: 'Загрузчик-выгрузчик сырья, топлива и стеновых изделий',
  },
  {
    ID: 12106,
    label: 'Загрузчик-выгрузчик термоантрацитовых печей',
  },
  {
    ID: 12108,
    label: 'Загрузчик-выгрузчик ферментационных камер',
  },
  {
    ID: 12110,
    label: 'Загрузчик древесных и костровых плит',
  },
  {
    ID: 12112,
    label: 'Загрузчик дробильно-помольного оборудования',
  },
  { ID: 12114, label: 'Загрузчик кассет' },
  {
    ID: 12116,
    label: 'Загрузчик колчеданных, серных печей и турм',
  },
  { ID: 12117, label: 'Загрузчик мелющих тел' },
  { ID: 12119, label: 'Загрузчик печей' },
  { ID: 12121, label: 'Загрузчик печей сопротивления' },
  {
    ID: 12123,
    label: 'Загрузчик-разгрузчик сушильных печей',
  },
  { ID: 12125, label: 'Загрузчик сульфата' },
  { ID: 12127, label: 'Загрузчик сушильных барабанов' },
  { ID: 12129, label: 'Загрузчик сырья' },
  { ID: 12130, label: 'Загрузчик сырья и полуфабриката' },
  { ID: 12132, label: 'Загрузчик термических печей' },
  { ID: 12134, label: 'Загрузчик туннельных печей' },
  {
    ID: 12136,
    label: 'Загрузчик химического сырья в аппараты',
  },
  { ID: 12138, label: 'Загрузчик шихты' },
  { ID: 12139, label: 'Загрузчик щелочи' },
  {
    ID: 12141,
    label: 'Закальщик изделий с хохломской росписью',
  },
  { ID: 12143, label: 'Закальщик мороженого' },
  { ID: 12145, label: 'Закальщик стекла' },
  { ID: 12147, label: 'Закатчик бумажных изделий' },
  { ID: 12149, label: 'Закатчик маканых изделий' },
  { ID: 12150, label: 'Закатчик медицинской продукции' },
  { ID: 12151, label: 'Заквасчик' },
  {
    ID: 12152,
    label: 'Закладчик хирургического шовного материала',
  },
  {
    ID: 12154,
    label: 'Закрепщик алмазов и сверхтвердых материалов',
  },
  { ID: 12156, label: 'Закройщик' },
  {
    ID: 12158,
    label: 'Закройщик картона, фибры и других материалов',
  },
  { ID: 12160, label: 'Закройщик-резак' },
  {
    ID: 12161,
    label: 'Закройщик резиновых изделий и деталей',
  },
  { ID: 12163, label: 'Заливщик анодов' },
  { ID: 12165, label: 'Заливщик голосовых планок' },
  {
    ID: 12166,
    label: 'Заливщик игольно-платинных изделий',
  },
  { ID: 12168, label: 'Заливщик камнелитейных изделий' },
  { ID: 12170, label: 'Заливщик кокса' },
  { ID: 12172, label: 'Заливщик компаундами' },
  {
    ID: 12174,
    label: 'Заливщик магнитных сплавов на печах-кристаллизаторах',
  },
  { ID: 12176, label: 'Заливщик металла' },
  {
    ID: 12180,
    label: 'Заливщик свинцово-оловянистых сплавов',
  },
  { ID: 12182, label: 'Заливщик смолкой' },
  { ID: 12183, label: 'Заливщик-труболитейщик' },
  {
    ID: 12185,
    label: 'Заливщик форм полимеризационной смесью',
  },
  { ID: 12187, label: 'Заливщик цоколей' },
  { ID: 12189, label: 'Замачивальщик шелка-сырца' },
  { ID: 12191, label: 'Замерщик дебитов скважин' },
  {
    ID: 12192,
    label: 'Замерщик на топографогеодезических и маркшейдерских работах',
  },
  { ID: 12194, label: 'Замыливальщик ткани' },
  { ID: 12196, label: 'Запайщик ампул' },
  { ID: 12198, label: 'Запайщик колб и сосудов' },
  { ID: 12199, label: 'Запайщик фарфоровых труб' },
  { ID: 12203, label: 'Запарщик коконов' },
  { ID: 12205, label: 'Запарщик крученого шелка' },
  { ID: 12207, label: 'Запарщик ткани' },
  { ID: 12208, label: 'Запарщик шерстяных изделий' },
  {
    ID: 12210,
    label: 'Заправщик иглопробивного агрегата',
  },
  { ID: 12212, label: 'Заправщик поливомоечных машин' },
  {
    ID: 12214,
    label: 'Заправщик рулонов картона и бумаги',
  },
  {
    ID: 12216,
    label: 'Заправщик-съемщик тормозной ленты',
  },
  {
    ID: 12218,
    label: 'Заправщик текстильного оборудования',
  },
  { ID: 12219, label: 'Заправщик эмалевых шликеров' },
  { ID: 12221, label: 'Запрессовщик игл' },
  { ID: 12223, label: 'Запрессовщик фитилей' },
  { ID: 12225, label: 'Зарядчик автоклавов' },
  { ID: 12227, label: 'Зарядчик ингаляторов' },
  { ID: 12229, label: 'Зарядчик огнетушителей' },
  { ID: 12231, label: 'Зарядчик противогазовых коробок' },
  { ID: 12233, label: 'Зарядчик холодильных аппаратов' },
  { ID: 12235, label: 'Засольщик мяса и мясопродуктов' },
  { ID: 12237, label: 'Засольщик овощей' },
  { ID: 12238, label: 'Засольщик шкур' },
  { ID: 12240, label: 'Засыпщик шихты' },
  { ID: 12242, label: 'Заточник' },
  {
    ID: 12244,
    label: 'Заточник деревообрабатывающего инструмента',
  },
  {
    ID: 12245,
    label: 'Заточник игольно-платинных изделий',
  },
  {
    ID: 12247,
    label: 'Заточник карандашей, стержней и палочек',
  },
  { ID: 12249, label: 'Заточник контактной пружины' },
  {
    ID: 12251,
    label: 'Заточник медицинского инструмента',
  },
  { ID: 12253, label: 'Затяжчик обуви' },
  { ID: 12255, label: 'Зачистчик' },
  {
    ID: 12257,
    label: 'Зачистчик электроизоляционных материалов',
  },
  { ID: 12259, label: 'Зашивальщик мягкой тары' },
  { ID: 12261, label: 'Зверовод' },
  { ID: 12262, label: 'Зверовод зоологических баз' },
  { ID: 12264, label: 'Земледел' },
  { ID: 12266, label: 'Землекоп' },
  {
    ID: 12268,
    label: 'Зоолаборант питомника лабораторных животных',
  },
  {
    ID: 12269,
    label: 'Зоолаборант серпентария (питомника)',
  },
  { ID: 12271, label: 'Зубополировщик деталей часов' },
  { ID: 12273, label: 'Зуборезчик' },
  { ID: 12275, label: 'Зубофрезеровщик деталей часов' },
  { ID: 12277, label: 'Зубошлифовщик' },
  { ID: 12282, label: 'Известегасильщик' },
  {
    ID: 12284,
    label: 'Изготовитель абразивных дисков и полировальных изделий',
  },
  { ID: 12286, label: 'Изготовитель абразивных пилок' },
  {
    ID: 12288,
    label: 'Изготовитель асбометаллических изделий',
  },
  { ID: 12290, label: 'Изготовитель берд' },
  { ID: 12291, label: 'Изготовитель бисквита' },
  {
    ID: 12292,
    label: 'Изготовитель блоков и панелей из кирпича',
  },
  {
    ID: 12294,
    label: 'Изготовитель блоков пенополиуретана',
  },
  { ID: 12295, label: 'Изготовитель бужей и катетеров' },
  { ID: 12296, label: 'Изготовитель вафель' },
  { ID: 12297, label: 'Изготовитель веревочных изделий' },
  { ID: 12299, label: 'Изготовитель витаминных соков' },
  { ID: 12301, label: 'Изготовитель гипсовых бинтов' },
  {
    ID: 12303,
    label: 'Изготовитель глазированных фруктов',
  },
  { ID: 12304, label: 'Изготовитель голосовых планок' },
  {
    ID: 12306,
    label: 'Изготовитель декоративных элементов мебели',
  },
  {
    ID: 12308,
    label: 'Изготовитель деталей для духовых инструментов',
  },
  {
    ID: 12310,
    label: 'Изготовитель деталей и узлов трубопроводов из пластмасс',
  },
  { ID: 12312, label: 'Изготовитель жгутов' },
  {
    ID: 12314,
    label: 'Изготовитель звукопоглощающих клиньев',
  },
  { ID: 12315, label: 'Изготовитель зубочисток' },
  { ID: 12317, label: 'Изготовитель игровых кукол' },
  {
    ID: 12319,
    label: 'Изготовитель изделий из вспенивающихся материалов',
  },
  {
    ID: 12320,
    label: 'Изготовитель изделий из органического стекла',
  },
  {
    ID: 12321,
    label: 'Изготовитель изделий из тканей с художественной росписью',
  },
  {
    ID: 12322,
    label: 'Изготовитель изделий из фторопласта',
  },
  {
    ID: 12323,
    label: 'Изготовитель изделий из шлифовальной шкурки',
  },
  {
    ID: 12325,
    label: 'Изготовитель изделий методом намотки',
  },
  {
    ID: 12327,
    label: 'Изготовитель искусственной колбасной оболочки',
  },
  { ID: 12329, label: 'Изготовитель казеина' },
  {
    ID: 12330,
    label: 'Изготовитель камышовых и соломенных матов',
  },
  { ID: 12332, label: 'Изготовитель капов' },
  { ID: 12333, label: 'Изготовитель карамели' },
  { ID: 12334, label: 'Изготовитель каркасов' },
  { ID: 12336, label: 'Изготовитель кетгута' },
  { ID: 12338, label: 'Изготовитель кожмягчителя' },
  { ID: 12340, label: 'Изготовитель колец' },
  { ID: 12341, label: 'Изготовитель конфет' },
  { ID: 12342, label: 'Изготовитель костяного угля' },
  { ID: 12345, label: 'Изготовитель лайки' },
  { ID: 12346, label: 'Изготовитель лекал' },
  { ID: 12347, label: 'Изготовитель лекал' },
  {
    ID: 12349,
    label: 'Изготовитель лент и металлосеток',
  },
  {
    ID: 12351,
    label: 'Изготовитель ленточных сердечников',
  },
  {
    ID: 12353,
    label: 'Изготовитель ленты из фторопласта',
  },
  { ID: 12355, label: 'Изготовитель маканых изделий' },
  { ID: 12356, label: 'Изготовитель макетов матриц' },
  {
    ID: 12357,
    label: 'Изготовитель мармеладо-пастильных изделий',
  },
  {
    ID: 12358,
    label: 'Изготовитель металлорукавов, гибких валов и бронеспиралей',
  },
  {
    ID: 12360,
    label: 'Изготовитель микрофонных порошков',
  },
  { ID: 12361, label: 'Изготовитель мишурной нити' },
  {
    ID: 12363,
    label: 'Изготовитель многослойных панелей',
  },
  { ID: 12365, label: 'Изготовитель молдингов' },
  {
    ID: 12367,
    label: 'Изготовитель молоточков для клавишных инструментов',
  },
  { ID: 12369, label: 'Изготовитель мороженого' },
  {
    ID: 12371,
    label: 'Изготовитель музыкальных инструментов по индивидуальным заказам',
  },
  {
    ID: 12372,
    label: 'Изготовитель мясных полуфабрикатов',
  },
  { ID: 12374, label: 'Изготовитель набивок' },
  {
    ID: 12376,
    label: 'Изготовитель-наладчик пресс-проводок',
  },
  {
    ID: 12378,
    label: 'Изготовитель натуральной колбасной оболочки',
  },
  { ID: 12380, label: 'Изготовитель орудий лова' },
  {
    ID: 12382,
    label: 'Изготовитель оснастки для жаккардовых машин',
  },
  {
    ID: 12383,
    label: 'Изготовитель основы валяльно-войлочных изделий',
  },
  { ID: 12385, label: 'Изготовитель очковых оправ' },
  { ID: 12387, label: 'Изготовитель парафиновых колец' },
  {
    ID: 12389,
    label: 'Изготовитель перопуховых изделий',
  },
  {
    ID: 12391,
    label: 'Изготовитель пищевых полуфабрикатов',
  },
  {
    ID: 12393,
    label: 'Изготовитель пластмассовой аппаратуры',
  },
  { ID: 12395, label: 'Изготовитель подбор и гужиков' },
  {
    ID: 12397,
    label: 'Изготовитель полуфабрикатов из мяса птицы',
  },
  {
    ID: 12399,
    label: 'Изготовитель препаратов драгоценных металлов и люстров',
  },
  {
    ID: 12401,
    label: 'Изготовитель прессовочных материалов',
  },
  {
    ID: 12403,
    label: 'Изготовитель приспособлений для выращивания монокристаллов',
  },
  { ID: 12405, label: 'Изготовитель пробок' },
  {
    ID: 12407,
    label: 'Изготовитель профильных заготовок',
  },
  { ID: 12408, label: 'Изготовитель пульпоэкстракторов' },
  { ID: 12410, label: 'Изготовитель ранта' },
  { ID: 12412, label: 'Изготовитель ремиз' },
  { ID: 12414, label: 'Изготовитель саговой крупки' },
  {
    ID: 12416,
    label: 'Изготовитель светофильтров и поляроидов',
  },
  { ID: 12418, label: 'Изготовитель свечей' },
  { ID: 12420, label: 'Изготовитель сепараторов' },
  { ID: 12424, label: 'Изготовитель сигар' },
  {
    ID: 12426,
    label: 'Изготовитель сильфонных компенсаторов и шлангов',
  },
  { ID: 12428, label: 'Изготовитель синели' },
  { ID: 12429, label: 'Изготовитель синельки' },
  { ID: 12431, label: 'Изготовитель сметаны' },
  { ID: 12433, label: 'Изготовитель специальных спичек' },
  { ID: 12435, label: 'Изготовитель стеклоблоков' },
  {
    ID: 12437,
    label: 'Изготовитель стекловаренных керамических емкостей',
  },
  {
    ID: 12439,
    label: 'Изготовитель стеклопластиковых гребных винтов',
  },
  {
    ID: 12441,
    label: 'Изготовитель стеклопластиковых изделий намоткой',
  },
  { ID: 12443, label: 'Изготовитель стеклянной пленки' },
  { ID: 12445, label: 'Изготовитель стержней' },
  { ID: 12447, label: 'Изготовитель струн' },
  { ID: 12449, label: 'Изготовитель субтитров' },
  {
    ID: 12450,
    label: 'Изготовитель сычужного порошка и пищевого пепсина',
  },
  { ID: 12452, label: 'Изготовитель таблеток' },
  { ID: 12454, label: 'Изготовитель творога' },
  { ID: 12458, label: 'Изготовитель тензорезисторов' },
  {
    ID: 12460,
    label: 'Изготовитель трафаретов, шкал и плат',
  },
  {
    ID: 12462,
    label: 'Изготовитель труб из органического стекла',
  },
  {
    ID: 12463,
    label: 'Изготовитель труб из фторопласта',
  },
  { ID: 12465, label: 'Изготовитель филигранных основ' },
  { ID: 12467, label: 'Изготовитель фильер' },
  { ID: 12468, label: 'Изготовитель фитилей' },
  {
    ID: 12470,
    label: 'Изготовитель фольгированных стеклоплит',
  },
  { ID: 12471, label: 'Изготовитель форм' },
  { ID: 12472, label: 'Изготовитель форм' },
  { ID: 12474, label: 'Изготовитель формодержателей' },
  {
    ID: 12476,
    label: 'Изготовитель художественных изделий из бересты',
  },
  {
    ID: 12478,
    label: 'Изготовитель художественных изделий из дерева',
  },
  {
    ID: 12480,
    label: 'Изготовитель художественных изделий из керамики',
  },
  {
    ID: 12482,
    label: 'Изготовитель художественных изделий из кожи',
  },
  {
    ID: 12483,
    label: 'Изготовитель художественных изделий из лозы',
  },
  {
    ID: 12484,
    label: 'Изготовитель художественных изделий из льноволокна',
  },
  {
    ID: 12485,
    label: 'Изготовитель художественных изделий из металла',
  },
  {
    ID: 12487,
    label: 'Изготовитель художественных изделий из пластмасс',
  },
  {
    ID: 12488,
    label: 'Изготовитель художественных изделий из соломки',
  },
  {
    ID: 12489,
    label: 'Изготовитель художественных изделий из янтаря',
  },
  { ID: 12491, label: 'Изготовитель целлулоидных колец' },
  { ID: 12493, label: 'Изготовитель шаблонов' },
  {
    ID: 12496,
    label: 'Изготовитель шкал и сеток фотоспособом',
  },
  { ID: 12497, label: 'Изготовитель шоколада' },
  { ID: 12498, label: 'Изготовитель штампов' },
  {
    ID: 12499,
    label: 'Изготовитель щетино-щеточных изделий',
  },
  {
    ID: 12501,
    label: 'Изготовитель электроизоляционных трубок',
  },
  { ID: 12503, label: 'Измеритель белковой оболочки' },
  {
    ID: 12505,
    label: 'Измеритель выпрямителей и элементов',
  },
  { ID: 12507, label: 'Измеритель емкостей' },
  {
    ID: 12509,
    label: 'Измеритель кожевенно-мехового сырья и материалов',
  },
  { ID: 12511, label: 'Измеритель магнитных свойств' },
  {
    ID: 12515,
    label: 'Измеритель электрических параметров микромодулей',
  },
  {
    ID: 12516,
    label: 'Измеритель электрических параметров радиодеталей',
  },
  {
    ID: 12518,
    label: 'Измеритель электрофизических параметров',
  },
  { ID: 12519, label: 'Изолировщик' },
  { ID: 12520, label: 'Изолировщик' },
  { ID: 12521, label: 'Изолировщик' },
  { ID: 12522, label: 'Изолировщик в термообработке' },
  { ID: 12523, label: 'Изолировщик жил кабеля' },
  { ID: 12525, label: 'Изолировщик колец' },
  {
    ID: 12527,
    label: 'Изолировщик кромок обрезиненного корда',
  },
  { ID: 12529, label: 'Изолировщик на гидроизоляции' },
  { ID: 12531, label: 'Изолировщик на термоизоляции' },
  { ID: 12533, label: 'Изолировщик-пленочник' },
  { ID: 12535, label: 'Изолировщик проводов' },
  { ID: 12537, label: 'Изолировщик световодов' },
  { ID: 12539, label: 'Изолировщик судовой' },
  { ID: 12541, label: 'Изолировщик труб на линии' },
  {
    ID: 12542,
    label: 'Изолировщик элементного производства',
  },
  { ID: 12544, label: 'Индикаторщик' },
  { ID: 12546, label: 'Инкрустатор' },
  { ID: 12548, label: 'Инокуляторщик' },
  { ID: 12557, label: 'Интонировщик' },
  {
    ID: 12559,
    label: 'Информатор судоходной обстановки',
  },
  { ID: 12561, label: 'Иризаторщик' },
  {
    ID: 12565,
    label: 'Исполнитель художественно-оформительских работ',
  },
  { ID: 12567, label: 'Испытатель абразивов' },
  {
    ID: 12569,
    label: 'Испытатель агрегатов, приборов и чувствительных элементов',
  },
  { ID: 12571, label: 'Испытатель баллонов' },
  { ID: 12574, label: 'Испытатель бумажных мешков' },
  {
    ID: 12576,
    label: 'Испытатель вакуумных покрытий (тонких пленок)',
  },
  {
    ID: 12577,
    label: 'Испытатель вооружения боевых и специальных машин',
  },
  { ID: 12580, label: 'Испытатель двигателей' },
  { ID: 12582, label: 'Испытатель деталей и приборов' },
  {
    ID: 12584,
    label: 'Испытатель инструментов из алмазов и сверхтвердых материалов',
  },
  { ID: 12585, label: 'Испытатель источников тока' },
  {
    ID: 12587,
    label: 'Испытатель карборундовых стержней',
  },
  { ID: 12589, label: 'Испытатель колец' },
  { ID: 12591, label: 'Испытатель металла' },
  {
    ID: 12593,
    label: 'Испытатель металлических канатов и цепей',
  },
  { ID: 12595, label: 'Испытатель-механик двигателей' },
  { ID: 12597, label: 'Испытатель на герметичность' },
  { ID: 12599, label: 'Испытатель проводов и кабелей' },
  {
    ID: 12600,
    label: 'Испытатель протезно-ортопедических изделий',
  },
  { ID: 12601, label: 'Испытатель резиновых изделий' },
  {
    ID: 12603,
    label: 'Испытатель селенового производства',
  },
  {
    ID: 12605,
    label: 'Испытатель стекловолокнистых материалов и стеклопластиков',
  },
  { ID: 12607, label: 'Испытатель стеклоизделий' },
  { ID: 12610, label: 'Испытатель-формировщик' },
  {
    ID: 12612,
    label: 'Испытатель электрических машин, аппаратов и приборов',
  },
  {
    ID: 12614,
    label: 'Испытатель электрокерамических изделий',
  },
  {
    ID: 12616,
    label: 'Испытатель электро-угольных изделий',
  },
  { ID: 12618, label: 'Истопник' },
  { ID: 12624, label: 'Кабельщик-спайщик' },
  { ID: 12626, label: 'Кабестанщик' },
  { ID: 12628, label: 'Кабинщик-кантовщик' },
  { ID: 12629, label: 'Каландровщик' },
  { ID: 12630, label: 'Каландровщик' },
  { ID: 12631, label: 'Каландровщик' },
  {
    ID: 12632,
    label: 'Каландровщик на обрезинке металлокордного полотна',
  },
  { ID: 12634, label: 'Каландровщик резиновых смесей' },
  {
    ID: 12636,
    label: 'Каландровщик целлюлозно-бумажного производства',
  },
  { ID: 12638, label: 'Калибровщик гипсовых форм' },
  {
    ID: 12640,
    label: 'Калибровщик изделий лечебного назначения',
  },
  { ID: 12642, label: 'Калибровщик карандашных дощечек' },
  {
    ID: 12644,
    label: 'Калибровщик катушек электроприборов',
  },
  { ID: 12646, label: 'Калибровщик коконов' },
  { ID: 12648, label: 'Калибровщик магнитопроводов' },
  { ID: 12650, label: 'Калибровщик набивок' },
  { ID: 12652, label: 'Калибровщик нитеобразователей' },
  { ID: 12654, label: 'Калибровщик пищевых продуктов' },
  {
    ID: 12656,
    label: 'Калибровщик-прецизионист в производстве масок цветных кинескопов',
  },
  { ID: 12658, label: 'Калибровщик-раскройщик' },
  { ID: 12660, label: 'Калибровщик слюды' },
  { ID: 12661, label: 'Калибровщик слюды' },
  { ID: 12662, label: 'Калибровщик стеклоизделий' },
  { ID: 12664, label: 'Калибровщик труб на прессе' },
  {
    ID: 12666,
    label: 'Калибровщик трубок из пластических масс',
  },
  { ID: 12668, label: 'Калибровщик форм' },
  {
    ID: 12671,
    label: 'Калибровщик электро-угольных изделий',
  },
  { ID: 12673, label: 'Калильщик' },
  {
    ID: 12675,
    label: 'Калильщик чепрака и технической кожи',
  },
  { ID: 12676, label: 'Калильщик янтаря' },
  { ID: 12678, label: 'Камбузник' },
  { ID: 12680, label: 'Каменщик' },
  {
    ID: 12682,
    label: 'Каменщик (печник) дежурный у печей',
  },
  { ID: 12684, label: 'Камеронщик' },
  { ID: 12686, label: 'Камневар' },
  { ID: 12689, label: 'Камнетес' },
  { ID: 12690, label: 'Камнетес' },
  { ID: 12691, label: 'Канавщик' },
  { ID: 12692, label: 'Канавщик' },
  { ID: 12694, label: 'Канилировщик проволоки' },
  { ID: 12696, label: 'Кантователь керамических труб' },
  { ID: 12698, label: 'Кантовщик-укладчик' },
  { ID: 12700, label: 'Капиллярщик' },
  { ID: 12702, label: 'Каптажист' },
  { ID: 12706, label: 'Карбидировщик' },
  { ID: 12708, label: 'Карбонизатор' },
  { ID: 12709, label: 'Карбонизаторщик' },
  { ID: 12710, label: 'Карбонизаторщик' },
  { ID: 12712, label: 'Кардовщик' },
  { ID: 12714, label: 'Каротажник' },
  { ID: 12716, label: 'Картонажник' },
  { ID: 12718, label: 'Карьерщик' },
  { ID: 12719, label: 'Кассир билетный' },
  { ID: 12720, label: 'Кастелянша' },
  { ID: 12721, label: 'Кассир торгового зала' },
  { ID: 12722, label: 'Катализаторщик' },
  { ID: 12723, label: 'Катализаторщик' },
  { ID: 12726, label: 'Катодчик' },
  { ID: 12728, label: 'Кашировальщик-красильщик фольги' },
  { ID: 12730, label: 'Каюр' },
  { ID: 12732, label: 'Кварцедув' },
  { ID: 12734, label: 'Кварцеплавильщик' },
  { ID: 12736, label: 'Кессонщик-аппаратчик' },
  { ID: 12738, label: 'Кессонщик-проходчик' },
  { ID: 12740, label: 'Кессонщик-слесарь' },
  { ID: 12741, label: 'Кессонщик-электромонтажник' },
  { ID: 12743, label: 'Кеттельщик' },
  { ID: 12745, label: 'Киномеханик' },
  { ID: 12747, label: 'Киоскер' },
  { ID: 12749, label: 'Кисловщик' },
  { ID: 12751, label: 'Кислотоупорщик-винипластчик' },
  { ID: 12753, label: 'Кислотоупорщик-гуммировщик' },
  { ID: 12754, label: 'Кислотчик' },
  { ID: 12755, label: 'Кислотчик' },
  { ID: 12757, label: 'Клавиатурщик' },
  { ID: 12759, label: 'Кладовщик' },
  {
    ID: 12761,
    label: 'Кладчик стекла на поливную машину',
  },
  { ID: 12763, label: 'Классировщик шерсти и пуха' },
  {
    ID: 12765,
    label: 'Классификаторщик дробленого камня',
  },
  {
    ID: 12767,
    label: 'Классификаторщик крокуса и наждака',
  },
  { ID: 12769, label: 'Классификаторщик песка и пемзы' },
  {
    ID: 12771,
    label: 'Классификаторщик порошков из алмазов и сверхтвердых материалов',
  },
  { ID: 12773, label: 'Классификаторщик слюды' },
  { ID: 12774, label: 'Классификаторщик шлифпорошков' },
  { ID: 12776, label: 'Клеевар' },
  { ID: 12777, label: 'Клеевар' },
  { ID: 12778, label: 'Клеильщик' },
  { ID: 12780, label: 'Клеймовщик горячего металла' },
  { ID: 12782, label: 'Клейщик' },
  {
    ID: 12784,
    label: 'Клейщик бумаги, картона и изделий из них',
  },
  { ID: 12786, label: 'Клейщик изделий из металла' },
  { ID: 12788, label: 'Клейщик миканитов' },
  { ID: 12790, label: 'Клейщик-опрессовщик' },
  { ID: 12792, label: 'Клейщик пленкоэлектрокартона' },
  {
    ID: 12794,
    label: 'Клейщик резиновых, полимерных деталей и изделий',
  },
  {
    ID: 12796,
    label: 'Клейщик силовой арматуры и мягких баков',
  },
  {
    ID: 12797,
    label: 'Клейщик фибры и литых бумажных изделий',
  },
  { ID: 12799, label: 'Клейщик эбонитовых изделий' },
  { ID: 12801, label: 'Клепальщик' },
  { ID: 12803, label: 'Клеровщик сахара' },
  { ID: 12805, label: 'Клишист' },
  { ID: 12807, label: 'Коагулировщик пектина' },
  { ID: 12809, label: 'Коагулянщик' },
  { ID: 12811, label: 'Ковровщик' },
  { ID: 12813, label: 'Ковровщица' },
  { ID: 12815, label: 'Ковшевой' },
  { ID: 12817, label: 'Кокильщик-сборщик' },
  { ID: 12819, label: 'Кокономотальщик' },
  { ID: 12821, label: 'Коконосмесчик' },
  { ID: 12823, label: 'Коксоочиститель' },
  { ID: 12825, label: 'Коксоразгрузчик' },
  { ID: 12827, label: 'Коллекционер образцов тканей' },
  { ID: 12828, label: 'Колорист' },
  { ID: 12829, label: 'Колорист' },
  { ID: 12830, label: 'Колорист' },
  { ID: 12831, label: 'Колорист' },
  { ID: 12832, label: 'Колорист' },
  { ID: 12833, label: 'Кольщик плит и блоков' },
  { ID: 12834, label: 'Кольщик слюды' },
  { ID: 12837, label: 'Комплектовщик' },
  {
    ID: 12839,
    label: 'Комплектовщик авиационной техники',
  },
  { ID: 12841, label: 'Комплектовщик белья' },
  { ID: 12843, label: 'Комплектовщик гипсовых форм' },
  { ID: 12845, label: 'Комплектовщик деталей игрушек' },
  {
    ID: 12847,
    label: 'Комплектовщик деталей и изделий из бумаги',
  },
  {
    ID: 12849,
    label: 'Комплектовщик деталей музыкальных инструментов',
  },
  { ID: 12851, label: 'Комплектовщик изделий' },
  {
    ID: 12853,
    label: 'Комплектовщик изделий и инструмента',
  },
  {
    ID: 12855,
    label: 'Комплектовщик изделий, полуфабрикатов и материалов',
  },
  {
    ID: 12857,
    label: 'Комплектовщик карандашей и стержней',
  },
  { ID: 12859, label: 'Комплектовщик костей' },
  {
    ID: 12861,
    label: 'Комплектовщик материалов, кроя и изделий',
  },
  { ID: 12863, label: 'Комплектовщик мебели' },
  { ID: 12865, label: 'Комплектовщик микромодулей' },
  { ID: 12867, label: 'Комплектовщик моделей' },
  { ID: 12869, label: 'Комплектовщик панелей' },
  {
    ID: 12871,
    label: 'Комплектовщик полуфабрикатов медицинских изделий',
  },
  { ID: 12873, label: 'Комплектовщик проводов' },
  {
    ID: 12875,
    label: 'Комплектовщик пряжи, ткани и изделий',
  },
  {
    ID: 12876,
    label: 'Комплектовщик пьезотехнических изделий',
  },
  {
    ID: 12878,
    label: 'Комплектовщик стекла и стеклоизделий',
  },
  {
    ID: 12880,
    label: 'Комплектовщик сухих зоопрепаратов',
  },
  { ID: 12882, label: 'Комплектовщик товаров' },
  { ID: 12884, label: 'Комплектовщик туб' },
  {
    ID: 12886,
    label: 'Комплектовщик фарфоровых и фаянсовых изделий',
  },
  { ID: 12887, label: 'Комплектовщик фильмокопий' },
  { ID: 12889, label: 'Комплектовщик форм' },
  {
    ID: 12891,
    label: 'Комплектовщик форм сусальных металлов',
  },
  {
    ID: 12893,
    label: 'Комплектовщик шрифтовой продукции',
  },
  { ID: 12895, label: 'Конвертерщик' },
  { ID: 12897, label: 'Конвертчик' },
  { ID: 12899, label: 'Конденсаторщик' },
  { ID: 12901, label: 'Кондитер' },
  { ID: 12903, label: 'Кондиционерщик зерна и семян' },
  { ID: 12904, label: 'Кондуктор' },
  {
    ID: 12905,
    label: 'Кондуктор главный грузовых поездов',
  },
  { ID: 12907, label: 'Кондуктор грузовых поездов' },
  { ID: 12909, label: 'Коневод' },
  {
    ID: 12911,
    label: 'Конопатчик электрощеточного производства',
  },
  {
    ID: 12913,
    label: 'Консервировщик кожевенного и пушно-мехового сырья',
  },
  {
    ID: 12914,
    label: 'Консервировщик кожевенно-мехового сырья',
  },
  {
    ID: 12916,
    label: 'Консервировщик оборудования и металлоизделий',
  },
  { ID: 12918, label: 'Консервировщик пантов' },
  {
    ID: 12922,
    label: 'Контролер абразивных материалов и изделий',
  },
  {
    ID: 12924,
    label: 'Контролер автоматических пропускных пунктов метрополитена',
  },
  {
    ID: 12926,
    label: 'Контролер асбестоцементных изделий',
  },
  {
    ID: 12928,
    label: 'Контролер бетонных и железнобетонных изделий и конструкций',
  },
  {
    ID: 12930,
    label: 'Контролер в аккумуляторном и элементном производстве',
  },
  { ID: 12934, label: 'Контролер-визитажник' },
  {
    ID: 12936,
    label: 'Контролер в литейном производстве',
  },
  {
    ID: 12938,
    label: 'Контролер водопроводного хозяйства',
  },
  {
    ID: 12940,
    label:
      'Контролер в производстве алмазов, сверхтвердых материалов и изделий из них',
  },
  {
    ID: 12942,
    label: 'Контролер в производстве черных металлов',
  },
  {
    ID: 12944,
    label: 'Контролер в производстве электроизоляционных материалов',
  },
  { ID: 12946, label: 'Контролер газового хозяйства' },
  {
    ID: 12948,
    label: 'Контролер деревообрабатывающего производства',
  },
  { ID: 12950, label: 'Контролер деталей и приборов' },
  {
    ID: 12951,
    label: 'Контролер игольно-платиновых изделий',
  },
  { ID: 12953, label: 'Контролер изделий из камня' },
  { ID: 12955, label: 'Контролер изделий из слюды' },
  {
    ID: 12956,
    label:
      'Контролер-испытатель боевых и специальных машин (в дорожных условиях)',
  },
  { ID: 12957, label: 'Контролер измерения продукции' },
  {
    ID: 12958,
    label: 'Контролер изделий, полуфабрикатов и материалов',
  },
  {
    ID: 12959,
    label: 'Контролер измерительных приборов и специального инструмента',
  },
  {
    ID: 12960,
    label:
      'Контролер-испытатель боевых и специальных машин (в стационарных условиях)',
  },
  { ID: 12961, label: 'Контролер кабельных изделий' },
  { ID: 12965, label: 'Контролер-кассир' },
  { ID: 12968, label: 'Контролер качества' },
  {
    ID: 12970,
    label: 'Контролер качества обработки изделий',
  },
  {
    ID: 12972,
    label: 'Контролер качества обработки одежды и белья',
  },
  {
    ID: 12974,
    label: 'Контролер качества продукции и технологического процесса',
  },
  { ID: 12976, label: 'Контролер кип' },
  {
    ID: 12977,
    label: 'Контролер контрольно-пропускного пункта',
  },
  {
    ID: 12978,
    label: 'Контролер котельных, холодноштамповочных и давильных работ',
  },
  {
    ID: 12980,
    label: 'Контролер кровельных и гидроизоляционных материалов',
  },
  {
    ID: 12981,
    label: 'Контролер кузнечно-прессовых работ',
  },
  {
    ID: 12982,
    label: 'Контролер лесозаготовительного производства и лесосплава',
  },
  {
    ID: 12983,
    label: 'Контролер лома и отходов металла',
  },
  { ID: 12985, label: 'Контролер малярных работ' },
  {
    ID: 12987,
    label: 'Контролер материалов, изделий и лекал',
  },
  { ID: 12989, label: 'Контролер материалов и изделий' },
  {
    ID: 12991,
    label: 'Контролер материалов, металлов, полуфабрикатов и изделий',
  },
  {
    ID: 12993,
    label: 'Контролер медицинского оборудования и изделий',
  },
  {
    ID: 12995,
    label:
      'Контролер мехового сырья и полуфабрикатов в скорняжном производстве',
  },
  {
    ID: 12997,
    label:
      'Контролер мехового сырья и полуфабрикатов в сырейно-красильном производстве',
  },
  {
    ID: 12999,
    label: 'Контролер монетно-орденского производства',
  },
  {
    ID: 13001,
    label: 'Контролер музыкальных инструментов',
  },
  { ID: 13003, label: 'Контролер наглядных пособий' },
  {
    ID: 13005,
    label: 'Контролер контрольно-пропускного пункта',
  },
  {
    ID: 13007,
    label: 'Контролер оптических деталей и приборов',
  },
  { ID: 13009, label: 'Контролер печного хозяйства' },
  { ID: 13011, label: 'Контролер пищевой продукции' },
  {
    ID: 13013,
    label: 'Контролер пленки, растворов и фильмовых материалов',
  },
  {
    ID: 13015,
    label: 'Контролер по драгоценной продукции',
  },
  { ID: 13016, label: 'Контролер по звучанию' },
  {
    ID: 13018,
    label: 'Контролер по качеству нефти и нефтепродуктов',
  },
  {
    ID: 13022,
    label: 'Контролер полимерных строительных материалов',
  },
  {
    ID: 13024,
    label: 'Контролер полуфабрикатов и готовой продукции',
  },
  { ID: 13026, label: 'Контролер по термообработке' },
  { ID: 13028, label: 'Контролер-приемщик' },
  {
    ID: 13034,
    label: 'Контролер-приемщик микроэлементов',
  },
  {
    ID: 13036,
    label: 'Контролер-приемщик фарфоровых, фаянсовых и керамических изделий',
  },
  {
    ID: 13038,
    label: 'Контролер продукции медицинского назначения',
  },
  { ID: 13040, label: 'Контролер продукции обогащения' },
  {
    ID: 13041,
    label: 'Контролер продукции цветной металлургии',
  },
  {
    ID: 13043,
    label: 'Контролер производства стекловолокна и стеклопластиков',
  },
  {
    ID: 13045,
    label: 'Контролер работ по металлопокрытиям',
  },
  {
    ID: 13047,
    label: 'Контролер радиоэлектронной аппаратуры и приборов',
  },
  {
    ID: 13049,
    label: 'Контролер режимов работы технологического оборудования',
  },
  {
    ID: 13051,
    label: 'Контролер ремизобердочного производства',
  },
  {
    ID: 13053,
    label: 'Контролер сборки электрических машин, аппаратов и приборов',
  },
  {
    ID: 13055,
    label: 'Контролер сборочно-монтажных и ремонтных работ',
  },
  { ID: 13057, label: 'Контролер сварочных работ' },
  {
    ID: 13059,
    label: 'Контролер светочувствительных изделий',
  },
  { ID: 13061, label: 'Контролер сетеизделий' },
  {
    ID: 13063,
    label: 'Контролер станочных и слесарных работ',
  },
  {
    ID: 13065,
    label: 'Контролер стекольного производства',
  },
  {
    ID: 13066,
    label: 'Контролер стеновых и вяжущих материалов',
  },
  { ID: 13068, label: 'Контролер струн и сшивок' },
  {
    ID: 13070,
    label: 'Контролер судокорпусных, судомонтажных и трубопроводных работ',
  },
  { ID: 13072, label: 'Контролер суспензии' },
  {
    ID: 13074,
    label: 'Контролер сырья и полуфабрикатов',
  },
  {
    ID: 13076,
    label: 'Контролер теплоизоляционных изделий',
  },
  {
    ID: 13078,
    label: 'Контролер технического состояния автомототранспортных средств',
  },
  {
    ID: 13079,
    label: 'Контролер технологического процесса',
  },
  {
    ID: 13080,
    label: 'Контролер технологического процесса',
  },
  { ID: 13081, label: 'Контролер углеприема' },
  {
    ID: 13083,
    label: 'Контролер художественных изделий',
  },
  {
    ID: 13085,
    label: 'Контролер целлюлозно-бумажного производства',
  },
  {
    ID: 13087,
    label: 'Контролер цехов плавки, дробления, регенерации и рассева',
  },
  {
    ID: 13089,
    label: 'Контролер часового и камневого производства',
  },
  { ID: 13091, label: 'Контролер шинного производства' },
  {
    ID: 13093,
    label: 'Контролер электродного производства',
  },
  {
    ID: 13095,
    label: 'Контролер электромонтажных работ',
  },
  { ID: 13097, label: 'Контролер эмалевого покрытия' },
  { ID: 13098, label: 'Контролер энергонадзора' },
  { ID: 13100, label: 'Контуровщик' },
  {
    ID: 13102,
    label: 'Контуровщик стеклопластиковых изделий',
  },
  { ID: 13106, label: 'Концентраторщик' },
  { ID: 13108, label: 'Конюх' },
  {
    ID: 13110,
    label: 'Координатографист прецизионной фотолитографии',
  },
  { ID: 13113, label: 'Копировщик' },
  { ID: 13115, label: 'Копировщик печатных форм' },
  { ID: 13117, label: 'Копировщик рисунков и карт' },
  { ID: 13119, label: 'Копировщик фильмовых материалов' },
  { ID: 13121, label: 'Копровщик' },
  {
    ID: 13123,
    label: 'Копровщик по разделке лома и отходов металла',
  },
  { ID: 13125, label: 'Коптильщик колбасного сыра' },
  { ID: 13127, label: 'Кормач' },
  { ID: 13131, label: 'Корректировщик ванн' },
  { ID: 13132, label: 'Корректировщик шлама' },
  { ID: 13134, label: 'Корректор' },
  { ID: 13136, label: 'Корчевщик' },
  { ID: 13138, label: 'Косметик' },
  { ID: 13140, label: 'Костюмер' },
  { ID: 13144, label: 'Котельщик' },
  { ID: 13146, label: 'Котельщик судовой' },
  { ID: 13148, label: 'Котлочист' },
  { ID: 13150, label: 'Кочегар-обжигальщик' },
  { ID: 13152, label: 'Кочегар паровозов в депо' },
  { ID: 13154, label: 'Кочегар производственных печей' },
  { ID: 13156, label: 'Кочегар судна' },
  { ID: 13158, label: 'Кочегар сушильных барабанов' },
  {
    ID: 13160,
    label: 'Кочегар сушильных печей и барабанов',
  },
  { ID: 13162, label: 'Кочегар технологических печей' },
  { ID: 13168, label: 'Крановый электрик' },
  { ID: 13169, label: 'Красильщик' },
  { ID: 13170, label: 'Красильщик' },
  { ID: 13171, label: 'Красильщик' },
  { ID: 13172, label: 'Красильщик волоса' },
  {
    ID: 13174,
    label: 'Красильщик в пастижерском производстве',
  },
  { ID: 13176, label: 'Красильщик зеркал' },
  { ID: 13178, label: 'Красильщик карандашей' },
  { ID: 13180, label: 'Красильщик кож' },
  {
    ID: 13182,
    label: 'Красильщик материалов для игрушек',
  },
  { ID: 13184, label: 'Красильщик меха и шубной овчины' },
  { ID: 13186, label: 'Красковар' },
  { ID: 13188, label: 'Краскосоставитель' },
  { ID: 13191, label: 'Краскотер' },
  { ID: 13193, label: 'Крепильщик' },
  { ID: 13195, label: 'Крепильщик деталей' },
  { ID: 13197, label: 'Крепильщик мандрен' },
  {
    ID: 13201,
    label: 'Кровельщик по рулонным кровлям и по кровлям из штучных материалов',
  },
  { ID: 13203, label: 'Кровельщик по стальным кровлям' },
  { ID: 13205, label: 'Кроликовод' },
  { ID: 13209, label: 'Кружевница' },
  { ID: 13211, label: 'Крутильщик' },
  { ID: 13213, label: 'Крутильщик жгутов' },
  { ID: 13215, label: 'Крутильщик сеточника' },
  { ID: 13217, label: 'Крутильщик шнуров' },
  { ID: 13219, label: 'Кубовщик' },
  { ID: 13221, label: 'Кузнец-бурозаправщик' },
  { ID: 13223, label: 'Кузнец драгоценных металлов' },
  { ID: 13225, label: 'Кузнец на молотах и прессах' },
  { ID: 13227, label: 'Кузнец ручной ковки' },
  { ID: 13229, label: 'Кузнец-штамповщик' },
  {
    ID: 13231,
    label: 'Кузнец-штамповщик на ротационных машинах',
  },
  {
    ID: 13233,
    label: 'Кулинар изделий из рыбы и морепродуктов',
  },
  { ID: 13235, label: 'Кулинар мучных изделий' },
  { ID: 13237, label: 'Кумысодел' },
  { ID: 13239, label: 'Купажист' },
  { ID: 13241, label: 'Купажист пектинового экстракта' },
  { ID: 13243, label: 'Купажист по табакам' },
  { ID: 13245, label: 'Купажист по чаю' },
  { ID: 13247, label: 'Курьер' },
  { ID: 13249, label: 'Кухонный рабочий' },
  { ID: 13251, label: 'Кучер' },
  {
    ID: 13255,
    label: 'Лаборант асбестообогатительного производства',
  },
  { ID: 13257, label: 'Лаборант-коллектор' },
  { ID: 13259, label: 'Лаборант-кристаллооптик' },
  {
    ID: 13261,
    label: 'Лаборант лаборатории искусственного старения стеклоизделий',
  },
  { ID: 13263, label: 'Лаборант-металлограф' },
  { ID: 13265, label: 'Лаборант-микробиолог' },
  {
    ID: 13267,
    label: 'Лаборант минералогического анализа',
  },
  {
    ID: 13269,
    label: 'Лаборант по анализу газов в металлах',
  },
  {
    ID: 13271,
    label: 'Лаборант по анализу газов и пыли',
  },
  { ID: 13273, label: 'Лаборант по анализу коконов' },
  { ID: 13275, label: 'Лаборант по анализу люминофоров' },
  {
    ID: 13276,
    label: 'Лаборант по анализу формовочных и шихтовых смесей',
  },
  {
    ID: 13278,
    label: 'Лаборант по выращиванию медицинских пиявок',
  },
  { ID: 13280, label: 'Лаборант по гренажу' },
  { ID: 13289, label: 'Лаборант-полярографист' },
  {
    ID: 13293,
    label: 'Лаборант по обработке аэрофотопленок',
  },
  {
    ID: 13301,
    label: 'Лаборант по ультразвуковой технике',
  },
  {
    ID: 13302,
    label: 'Лаборант по физико-механическим испытаниям',
  },
  {
    ID: 13304,
    label: 'Лаборант по электроизоляционным материалам',
  },
  { ID: 13306, label: 'Лаборант пробирного анализа' },
  {
    ID: 13308,
    label: 'Лаборант производства бактерийных препаратов',
  },
  { ID: 13310, label: 'Лаборант-радиометрист' },
  {
    ID: 13312,
    label: 'Лаборант рентгеноспектрального анализа',
  },
  { ID: 13313, label: 'Лаборант-рентгеноструктурщик' },
  { ID: 13315, label: 'Лаборант-сенситометрист' },
  { ID: 13317, label: 'Лаборант спектрального анализа' },
  {
    ID: 13319,
    label: 'Лаборант химико-бактериологического анализа',
  },
  { ID: 13321, label: 'Лаборант химического анализа' },
  { ID: 13322, label: 'Лаборант-электроакустик' },
  {
    ID: 13324,
    label: 'Лаборант электромеханических испытаний и измерений',
  },
  { ID: 13326, label: 'Лаглинщик' },
  { ID: 13328, label: 'Лакировщик' },
  { ID: 13330, label: 'Лакировщик глобусов' },
  { ID: 13332, label: 'Лакировщик деталей часов' },
  { ID: 13334, label: 'Лакировщик жести и труб' },
  { ID: 13336, label: 'Лакировщик кож' },
  { ID: 13338, label: 'Лакировщик оптических деталей' },
  { ID: 13341, label: 'Лакировщик подносов' },
  { ID: 13343, label: 'Лакировщик проводов и кабелей' },
  { ID: 13345, label: 'Лакировщик туб' },
  { ID: 13346, label: 'Лакировщик фибровых изделий' },
  { ID: 13348, label: 'Лакировщик форм' },
  {
    ID: 13350,
    label: 'Лакировщик художественных изделий',
  },
  {
    ID: 13351,
    label: 'Лакировщик электроизоляционных изделий и материалов',
  },
  { ID: 13353, label: 'Лаковар' },
  { ID: 13355, label: 'Лакоразводчик' },
  { ID: 13357, label: 'Ламповщик' },
  { ID: 13359, label: 'Ланолинщик' },
  { ID: 13361, label: 'Лебедчик' },
  { ID: 13363, label: 'Лебедчик на лесосплаве' },
  { ID: 13365, label: 'Лебедчик на трелевке леса' },
  {
    ID: 13366,
    label: 'Лебедчик на штабелевке и погрузке леса',
  },
  { ID: 13368, label: 'Лентовой уборщик' },
  { ID: 13370, label: 'Лепщик архитектурных деталей' },
  {
    ID: 13372,
    label: 'Лепщик скульптурного производства',
  },
  {
    ID: 13374,
    label: 'Лепщик электрокерамических изделий',
  },
  { ID: 13376, label: 'Лесовод' },
  { ID: 13378, label: 'Лесоруб' },
  { ID: 13382, label: 'Листобойщик' },
  {
    ID: 13384,
    label: 'Литейщик вакуумного, центробежно-вакуумного и центробежного литья',
  },
  { ID: 13386, label: 'Литейщик гипсовых форм' },
  {
    ID: 13388,
    label: 'Литейщик изделий из свинцовых сплавов',
  },
  { ID: 13390, label: 'Литейщик катализатора' },
  { ID: 13392, label: 'Литейщик металлов и сплавов' },
  {
    ID: 13394,
    label: 'Литейщик методом направленной кристаллизации',
  },
  {
    ID: 13395,
    label: 'Литейщик на машинах для литья под давлением',
  },
  { ID: 13397, label: 'Литейщик облицовочных плиток' },
  { ID: 13399, label: 'Литейщик пластмасс' },
  {
    ID: 13401,
    label: 'Литейщик радиокерамики и ферритов',
  },
  {
    ID: 13403,
    label: 'Литейщик санитарно-строительных изделий на конвейере',
  },
  {
    ID: 13404,
    label: 'Литейщик санитарно-строительных изделий на стенде',
  },
  { ID: 13406, label: 'Литейщик стекла' },
  { ID: 13408, label: 'Литейщик художественных изделий' },
  { ID: 13410, label: 'Литейщик цветных металлов' },
  {
    ID: 13411,
    label: 'Литейщик электрокерамических изделий',
  },
  { ID: 13413, label: 'Лифтер' },
  { ID: 13414, label: 'Ловец безнадзорных животных' },
  { ID: 13415, label: 'Ломщик пода' },
  { ID: 13417, label: 'Лудильщик горячим способом' },
  {
    ID: 13419,
    label: 'Лудильщик деталей и приборов горячим способом',
  },
  {
    ID: 13422,
    label: 'Лудильщик (оцинковщик) электролитическим методом',
  },
  { ID: 13424, label: 'Лудильщик проволоки' },
  { ID: 13426, label: 'Лучевальщик' },
  { ID: 13428, label: 'Лущильщик пленки' },
  { ID: 13430, label: 'Лущильщик шпона' },
  { ID: 13432, label: 'Люковой' },
  { ID: 13433, label: 'Люковой' },
  { ID: 13434, label: 'Люминофорщик-экранировщик' },
  { ID: 13440, label: 'Магнезировщик-вакуумщик' },
  { ID: 13442, label: 'Макаль' },
  {
    ID: 13444,
    label: 'Макетчик макетно-модельного проектирования',
  },
  {
    ID: 13446,
    label: 'Макетчик театрально-постановочных макетов',
  },
  { ID: 13448, label: 'Макетчик художественных макетов' },
  { ID: 13450, label: 'Маляр' },
  { ID: 13452, label: 'Маляр по отделке декораций' },
  { ID: 13456, label: 'Маникюрша' },
  { ID: 13458, label: 'Маривод' },
  { ID: 13460, label: 'Маркировщик' },
  { ID: 13462, label: 'Маркировщик деталей и приборов' },
  { ID: 13466, label: 'Маслодел' },
  { ID: 13467, label: 'Маслодел-мастер' },
  { ID: 13469, label: 'Мастер-взрывник' },
  {
    ID: 13471,
    label:
      'Мастер-наладчик по техническому обслуживанию машинно-тракторного парка',
  },
  {
    ID: 13475,
    label: 'Мастер производства молочного сахара',
  },
  {
    ID: 13477,
    label:
      'Мастер производства сухого обезжиренного молока и заменителя цельного молока',
  },
  {
    ID: 13478,
    label: 'Мастер производства цельномолочной и кисломолочной продукции',
  },
  { ID: 13480, label: 'Матировщик-вакуумщик' },
  { ID: 13482, label: 'Матрос' },
  { ID: 13483, label: 'Матрос береговой' },
  { ID: 13484, label: 'Матрос-водолаз' },
  { ID: 13486, label: 'Матрос драги' },
  { ID: 13493, label: 'Матрос пожарный' },
  { ID: 13495, label: 'Матрос-спасатель' },
  { ID: 13497, label: 'Мацератор' },
  { ID: 13499, label: 'Мачтовик-антенщик судовой' },
  { ID: 13503, label: 'Машинист автобетонолома' },
  { ID: 13505, label: 'Машинист автобетононасоса' },
  {
    ID: 13507,
    label: 'Машинист автовышки и автогидроподъемника',
  },
  { ID: 13509, label: 'Машинист автогрейдера' },
  { ID: 13511, label: 'Машинист автогудронатора' },
  { ID: 13513, label: 'Машинист автокамерного агрегата' },
  { ID: 13515, label: 'Машинист автокомпрессора' },
  {
    ID: 13517,
    label: 'Машинист автомата для завертывания книг в суперобложку',
  },
  {
    ID: 13519,
    label: 'Машинист автомата по изготовлению обрезных обложек',
  },
  {
    ID: 13521,
    label: 'Машинист автоматической линии по изготовлению книг',
  },
  {
    ID: 13523,
    label:
      'Машинист автоматической линии по изготовлению контейнеров из полимеров',
  },
  {
    ID: 13525,
    label:
      'Машинист автоматической линии по изготовлению тетрадей для нот и альбомов для рисования',
  },
  { ID: 13527, label: 'Машинист автомобилеразгрузчика' },
  { ID: 13529, label: 'Машинист автомотрисы' },
  { ID: 13532, label: 'Машинист автоямобура' },
  {
    ID: 13534,
    label: 'Машинист агрегата безвоздушного распыления высокого давления',
  },
  {
    ID: 13536,
    label: 'Машинист агрегата бесшвейного скрепления',
  },
  {
    ID: 13538,
    label: 'Машинист агрегата изготовления искусственной кожи',
  },
  {
    ID: 13540,
    label: 'Машинист агрегата изготовления стирательной резинки',
  },
  {
    ID: 13542,
    label: 'Машинист агрегата искусственного бархата',
  },
  {
    ID: 13544,
    label: 'Машинист агрегата по изготовлению навивочных рукавов',
  },
  {
    ID: 13546,
    label: 'Машинист агрегатов по добыче соли в озере',
  },
  {
    ID: 13548,
    label:
      'Машинист агрегатов по обслуживанию нефтегазопромыслового оборудования',
  },
  { ID: 13550, label: 'Машинист адресовальной машины' },
  { ID: 13552, label: 'Машинист алфавитной машины' },
  { ID: 13556, label: 'Машинист барабанного охладителя' },
  { ID: 13558, label: 'Машинист баровой установки' },
  {
    ID: 13560,
    label: 'Машинист береговых насосных станций',
  },
  {
    ID: 13562,
    label: 'Машинист бетононасосной установки',
  },
  {
    ID: 13564,
    label: 'Машинист бетоносмесителя передвижного',
  },
  { ID: 13565, label: 'Машинист бетоноукладчика' },
  { ID: 13567, label: 'Машинист битумировочной машины' },
  {
    ID: 13569,
    label: 'Машинист битумоплавильной передвижной установки',
  },
  {
    ID: 13571,
    label: 'Машинист блокообрабатывающего агрегата',
  },
  { ID: 13573, label: 'Машинист блондочной машины' },
  {
    ID: 13575,
    label:
      'Машинист блочного щита управления агрегатами (парогенератор-турбина)',
  },
  {
    ID: 13577,
    label: 'Машинист блочной системы управления агрегатами (котел-турбина)',
  },
  { ID: 13579, label: 'Машинист брикетного пресса' },
  { ID: 13581, label: 'Машинист-бронзировщик' },
  { ID: 13583, label: 'Машинист бульдозера' },
  { ID: 13584, label: 'Машинист бульдозера' },
  {
    ID: 13585,
    label: 'Машинист бумагоделательной (картоноделательной) машины (сеточник)',
  },
  {
    ID: 13587,
    label: 'Машинист бумагокрасильной машины (красильщик)',
  },
  {
    ID: 13589,
    label: 'Машинист бурильнокрановой самоходной машины',
  },
  { ID: 13590, label: 'Машинист буровой установки' },
  {
    ID: 13592,
    label: 'Машинист буровых установок на нефть и газ',
  },
  { ID: 13594, label: 'Машинист буртоукладочной машины' },
  { ID: 13596, label: 'Машинист вагон-весов' },
  { ID: 13598, label: 'Машинист вагоноопрокидывателя' },
  { ID: 13600, label: 'Машинист вакуумной установки' },
  {
    ID: 13602,
    label: 'Машинист вакуум-упаковочной машины',
  },
  {
    ID: 13604,
    label: 'Машинист вакуум-формовочной машины',
  },
  {
    ID: 13606,
    label: 'Машинист вакуум-формующей машины',
  },
  { ID: 13608, label: 'Машинист валькосъемной машины' },
  { ID: 13610, label: 'Машинист вальцовых станков' },
  {
    ID: 13612,
    label: 'Машинист ведущего мотора прокатного стана',
  },
  {
    ID: 13616,
    label: 'Машинист вентиляционной и аспирационной установок',
  },
  {
    ID: 13620,
    label: 'Машинист вибровдавливающего погружателя свай самоходного',
  },
  {
    ID: 13622,
    label: 'Машинист вибропогружателя бескопрового',
  },
  {
    ID: 13623,
    label: 'Машинист вибропогрузочной установки',
  },
  {
    ID: 13625,
    label: 'Машинист вибросит резальных машин',
  },
  {
    ID: 13627,
    label: 'Машинист винтовых насосов (фуллеровщик)',
  },
  {
    ID: 13629,
    label: 'Машинист вкладочно-швейно-резального агрегата',
  },
  {
    ID: 13631,
    label: 'Машинист воздухоразделительных установок',
  },
  {
    ID: 13633,
    label: 'Машинист воздушно-канатной дороги',
  },
  { ID: 13635, label: 'Машинист выборочной машины' },
  { ID: 13637, label: 'Машинист выдувных машин' },
  {
    ID: 13642,
    label: 'Машинист вырубально-упаковочного агрегата',
  },
  {
    ID: 13644,
    label: 'Машинист высекально-штамповочной машины',
  },
  {
    ID: 13646,
    label: 'Машинист высотно-компрессорной установки',
  },
  {
    ID: 13648,
    label: 'Машинист вышивальной машины "Пантограф"',
  },
  {
    ID: 13650,
    label: 'Машинист газогенераторной станции',
  },
  { ID: 13652, label: 'Машинист газодувных машин' },
  { ID: 13654, label: 'Машинист газокаротажной станции' },
  {
    ID: 13656,
    label: 'Машинист газораздаточной станции',
  },
  {
    ID: 13658,
    label: 'Машинист газотурбинных установок',
  },
  { ID: 13660, label: 'Машинист гидроагрегатов' },
  {
    ID: 13662,
    label:
      'Машинист гидромониторно-эжекторного плавучего несамоходного снаряда',
  },
  {
    ID: 13664,
    label: 'Машинист гидроочистки и смазки изложниц',
  },
  {
    ID: 13666,
    label: 'Машинист гидропневматической установки',
  },
  { ID: 13668, label: 'Машинист гидросеялки самоходной' },
  {
    ID: 13669,
    label: 'Машинист гидротипного производства',
  },
  { ID: 13671, label: 'Машинист глинорезной машины' },
  { ID: 13673, label: 'Машинист горных выемочных машин' },
  {
    ID: 13675,
    label: 'Машинист гофрировального агрегата',
  },
  {
    ID: 13677,
    label: 'Машинист гранулирования пластических масс',
  },
  { ID: 13679, label: 'Машинист гранулятора' },
  { ID: 13681, label: 'Машинист грейдера прицепного' },
  { ID: 13683, label: 'Машинист грейдер-элеватора' },
  { ID: 13685, label: 'Машинист гренировальной машины' },
  { ID: 13686, label: 'Машинист грузового причала' },
  {
    ID: 13687,
    label: 'Машинист гуммировального агрегата',
  },
  {
    ID: 13689,
    label: 'Машинист двигателей внутреннего сгорания',
  },
  { ID: 13691, label: 'Машинист двоильной машины' },
  { ID: 13692, label: 'Машинист двоильной машины' },
  {
    ID: 13694,
    label: 'Машинист дизельмолота бескопрового',
  },
  { ID: 13696, label: 'Машинист дизельпоезда' },
  { ID: 13698, label: 'Машинист дистрибутора' },
  { ID: 13700, label: 'Машинист дноклеильной машины' },
  {
    ID: 13702,
    label: 'Машинист дорожно-транспортных машин',
  },
  { ID: 13704, label: 'Машинист драги' },
  { ID: 13705, label: 'Машинист дражировочных машин' },
  { ID: 13706, label: 'Машинист дренажной машины' },
  {
    ID: 13710,
    label: 'Машинист дробильно-погрузочного агрегата',
  },
  {
    ID: 13711,
    label: 'Машинист дробильно-помольно-сортировочных механизмов',
  },
  { ID: 13713, label: 'Машинист дробильных машин' },
  { ID: 13715, label: 'Машинист дробильных установок' },
  { ID: 13717, label: 'Машинист дублирующего агрегата' },
  {
    ID: 13719,
    label: 'Машинист железнодорожного водоснабжения',
  },
  {
    ID: 13720,
    label: 'Машинист железнодорожно-строительных машин',
  },
  { ID: 13721, label: 'Машинист завалочной машины' },
  { ID: 13723, label: 'Машинист загрузочных механизмов' },
  { ID: 13725, label: 'Машинист закаточных машин' },
  { ID: 13729, label: 'Машинист заправочной машины' },
  {
    ID: 13734,
    label: 'Машинист землеройно-фрезерной самоходной машины',
  },
  {
    ID: 13736,
    label: 'Машинист землесосного плавучего несамоходного снаряда',
  },
  { ID: 13737, label: 'Машинист землесосной установки' },
  {
    ID: 13739,
    label: 'Машинист зерновых погрузочно разгрузочных машин',
  },
  { ID: 13741, label: 'Машинист зумпфового агрегата' },
  { ID: 13743, label: 'Машинист ионизационной машины' },
  { ID: 13744, label: 'Машинист каландра' },
  { ID: 13745, label: 'Машинист каландра' },
  { ID: 13746, label: 'Машинист калибровочных машин' },
  { ID: 13747, label: 'Машинист кальцинаторов' },
  { ID: 13749, label: 'Машинист камнерезной машины' },
  { ID: 13751, label: 'Машинист катальной машины' },
  {
    ID: 13753,
    label:
      'Машинист катка самоходного и полуприцепного на пневматических шинах',
  },
  {
    ID: 13755,
    label: 'Машинист катка самоходного с гладкими вальцами',
  },
  { ID: 13757, label: 'Машинист клеевого агрегата' },
  {
    ID: 13759,
    label: 'Машинист клеильно-загибочной машины',
  },
  {
    ID: 13761,
    label: 'Машинист клеильно-сушильной машины (клейщик)',
  },
  { ID: 13763, label: 'Машинист книговставочной машины' },
  { ID: 13765, label: 'Машинист коксовых машин' },
  {
    ID: 13767,
    label: 'Машинист коксопогрузочной машины',
  },
  {
    ID: 13769,
    label: 'Машинист компрессора для подачи воздуха водолазам',
  },
  {
    ID: 13771,
    label:
      'Машинист компрессора передвижного с двигателем внутреннего сгорания',
  },
  {
    ID: 13773,
    label: 'Машинист компрессора передвижного с электродвигателем',
  },
  {
    ID: 13775,
    label: 'Машинист компрессорных установок',
  },
  { ID: 13777, label: 'Машинист конвейера' },
  { ID: 13779, label: 'Машинист конвертной машины' },
  {
    ID: 13781,
    label:
      'Машинист контактно-сварочной установки передвижной для сварки магистральных газонефтепродуктопроводов',
  },
  { ID: 13783, label: 'Машинист копра' },
  { ID: 13784, label: 'Машинист котельной установки' },
  { ID: 13785, label: 'Машинист котлов' },
  { ID: 13786, label: 'Машинист (кочегар) котельной' },
  { ID: 13788, label: 'Машинист крана автомобильного' },
  { ID: 13790, label: 'Машинист крана (крановщик)' },
  {
    ID: 13792,
    label: 'Машинист крана металлургического производства',
  },
  { ID: 13794, label: 'Машинист крана склада сульфата' },
  { ID: 13796, label: 'Машинист-крановщик' },
  {
    ID: 13798,
    label: 'Машинист красильного и вулканизационного оборудования',
  },
  { ID: 13800, label: 'Машинист кратцера' },
  { ID: 13804, label: 'Машинист креповой машины' },
  { ID: 13806, label: 'Машинист крытвенной машины' },
  {
    ID: 13807,
    label: 'Машинист крышкоделательной машины',
  },
  {
    ID: 13809,
    label: 'Машинист лакировально-гуммировальной машины',
  },
  { ID: 13811, label: 'Машинист лакировальных машин' },
  { ID: 13813, label: 'Машинист лакировочных машин' },
  { ID: 13815, label: 'Машинист лаконаносящей машины' },
  { ID: 13817, label: 'Машинист ледорезной машины' },
  {
    ID: 13819,
    label: 'Машинист линии нанесения точечного полимерного покрытия',
  },
  {
    ID: 13821,
    label: 'Машинист линии непрерывной ферментации табака',
  },
  {
    ID: 13823,
    label: 'Машинист линии подготовки табака к ферментации',
  },
  {
    ID: 13824,
    label: 'Машинист линий аэрозоль-транспорта',
  },
  { ID: 13825, label: 'Машинист линовальной машины' },
  {
    ID: 13826,
    label: 'Машинист листоформовочной машины',
  },
  { ID: 13827, label: 'Машинист локомотива на паромах' },
  { ID: 13828, label: 'Машинист льдопогрузочной машины' },
  {
    ID: 13830,
    label: 'Машинист малярной станции передвижной',
  },
  {
    ID: 13832,
    label: 'Машинист маркировочной машины для разметки автомобильных дорог',
  },
  {
    ID: 13834,
    label: 'Машинист махорочно-набивных машин',
  },
  {
    ID: 13836,
    label: 'Машинист машин вытягивания стекла',
  },
  {
    ID: 13838,
    label: 'Машинист машин и механизмов внутренних водоемов',
  },
  {
    ID: 13840,
    label: 'Машинист машин по добыче и переработке кускового торфа',
  },
  {
    ID: 13842,
    label: 'Машинист машин по добыче и переработке фрезерного торфа',
  },
  {
    ID: 13844,
    label: 'Машинист машин по заготовке тростника',
  },
  {
    ID: 13846,
    label: 'Машинист машин по подготовке торфяных месторождений к эксплуатации',
  },
  {
    ID: 13847,
    label: 'Машинист машин по чистке и смазке листов',
  },
  {
    ID: 13849,
    label: 'Машинист машины длинноволокнистых бумаг',
  },
  {
    ID: 13851,
    label: 'Машинист машины для вкладки книг в футляры',
  },
  {
    ID: 13853,
    label: 'Машинист машины для изоляции газонефтепродуктопроводов',
  },
  {
    ID: 13854,
    label: 'Машинист машины для ломки футеровки конвертеров и ковшей',
  },
  {
    ID: 13856,
    label: 'Машинист машины для нанесения пленкообразующей жидкости',
  },
  {
    ID: 13858,
    label: 'Машинист машины для покрытия бумаги полиэтиленовой пленкой',
  },
  {
    ID: 13860,
    label: 'Машинист машины для устройства укрепительных полос',
  },
  {
    ID: 13862,
    label: 'Машинист машины для устройства швов при выполнении дорожных работ',
  },
  {
    ID: 13864,
    label: 'Машинист машины огневой зачистки',
  },
  {
    ID: 13866,
    label: 'Машинист машины по изготовлению бумаги для табачных фильтров',
  },
  {
    ID: 13868,
    label: 'Машинист машины по изготовлению гильз',
  },
  {
    ID: 13870,
    label: 'Машинист машины по производству изделий из бумаги',
  },
  { ID: 13872, label: 'Машинист мельниц' },
  { ID: 13873, label: 'Машинист мельниц' },
  { ID: 13874, label: 'Машинист мельницы' },
  {
    ID: 13876,
    label:
      'Машинист механизированного натяжного устройства для изготовления напряженно-армированных конструкций',
  },
  {
    ID: 13878,
    label:
      'Машинист механизированного оборудования по подъему подвижной (скользящей) опалубки',
  },
  {
    ID: 13880,
    label: 'Машинист механического или флотационного обогащения руды',
  },
  { ID: 13882, label: 'Машинист механического катка' },
  {
    ID: 13884,
    label:
      'Машинист механического оборудования землесосных плавучих несамоходных снарядов и грунтонасосных установок',
  },
  { ID: 13886, label: 'Машинист мешалок' },
  { ID: 13888, label: 'Машинист микструдера' },
  { ID: 13890, label: 'Машинист моечной установки' },
  { ID: 13891, label: 'Машинист моечных машин' },
  {
    ID: 13893,
    label: 'Машинист мостового перегружателя',
  },
  { ID: 13895, label: 'Машинист мотовоза' },
  { ID: 13897, label: 'Машинист мягчильной машины' },
  {
    ID: 13899,
    label: 'Машинист навивочных и намоточных машин',
  },
  {
    ID: 13901,
    label: 'Машинист на молотах, прессах и манипуляторах',
  },
  { ID: 13903, label: 'Машинист по припрессовке пленки' },
  {
    ID: 13904,
    label: 'Машинист нарезчика швов самоходного',
  },
  { ID: 13906, label: 'Машинист наслаивающей машины' },
  {
    ID: 13908,
    label: 'Машинист насосной станции по закачке рабочего агента в пласт',
  },
  { ID: 13910, label: 'Машинист насосных установок' },
  { ID: 13912, label: 'Машинист низальных машин' },
  {
    ID: 13914,
    label: 'Машинист (обжигальщик) вращающихся печей',
  },
  {
    ID: 13915,
    label: 'Машинист (обжигальщик) шахтных печей',
  },
  { ID: 13917, label: 'Машинист обкаточной машины' },
  { ID: 13919, label: 'Машинист обойно-печатной машины' },
  {
    ID: 13921,
    label: 'Машинист оборудования конвейерных и поточных линий',
  },
  {
    ID: 13923,
    label: 'Машинист оборудования распределительных нефтебаз',
  },
  { ID: 13925, label: 'Машинист обсадочной машины' },
  {
    ID: 13929,
    label: 'Машинист-обходчик по котельному оборудованию',
  },
  {
    ID: 13931,
    label: 'Машинист-обходчик по турбинному оборудованию',
  },
  {
    ID: 13933,
    label: 'Машинист обклеечно-каптальной машины',
  },
  { ID: 13935, label: 'Машинист окомкователя' },
  { ID: 13937, label: 'Машинист оплеточной машины' },
  { ID: 13939, label: 'Машинист опудривателя окатышей' },
  { ID: 13941, label: 'Машинист отвалообразователя' },
  { ID: 13943, label: 'Машинист отвального моста' },
  { ID: 13944, label: 'Машинист отвального плуга' },
  {
    ID: 13946,
    label:
      'Машинист отжимного оборудования (дубильно-экстрактовое производство)',
  },
  {
    ID: 13948,
    label:
      'Машинист отжимного оборудования (кожевенное и кожсырьевое производство)',
  },
  { ID: 13950, label: 'Машинист отжимной машины' },
  {
    ID: 13952,
    label: 'Машинист отжимно-промывного оборудования',
  },
  { ID: 13954, label: 'Машинист отливной машины' },
  { ID: 13956, label: 'Машинист охладителей' },
  {
    ID: 13958,
    label: 'Машинист очистительного оборудования',
  },
  { ID: 13959, label: 'Машинист очистительных машин' },
  { ID: 13961, label: 'Машинист ошпарочного агрегата' },
  { ID: 13963, label: 'Машинист папочной машины' },
  { ID: 13965, label: 'Машинист паровоза' },
  {
    ID: 13967,
    label: 'Машинист паровой машины и локомобиля',
  },
  {
    ID: 13969,
    label: 'Машинист паровой передвижной депарафинизационной установки',
  },
  { ID: 13971, label: 'Машинист паровых турбин' },
  {
    ID: 13973,
    label:
      'Машинист парогенераторной установки по закачке пара в нефтяные пласты',
  },
  { ID: 13975, label: 'Машинист пергаментной машины' },
  { ID: 13977, label: 'Машинист перегружателей' },
  {
    ID: 13979,
    label: 'Машинист передвижного компрессора',
  },
  { ID: 13981, label: 'Машинист перекидного желоба' },
  {
    ID: 13982,
    label: 'Машинист перопухообрабатывающих машин',
  },
  {
    ID: 13984,
    label: 'Машинист пескоподающей установки',
  },
  {
    ID: 13986,
    label: 'Машинист печатно-высекательного агрегата',
  },
  { ID: 13987, label: 'Машинист печатной машины' },
  { ID: 13988, label: 'Машинист печатной машины' },
  { ID: 13990, label: 'Машинист питателя' },
  { ID: 13992, label: 'Машинист плунжерной машины' },
  { ID: 13993, label: 'Машинист пневматических насосов' },
  {
    ID: 13994,
    label: 'Машинист пневматической установки',
  },
  { ID: 13996, label: 'Машинист пневмотранспорта' },
  {
    ID: 13998,
    label: 'Машинист поворотной и подъемной машин моста',
  },
  {
    ID: 14000,
    label: 'Машинист погрузочно-доставочной машины',
  },
  { ID: 14002, label: 'Машинист погрузочной машины' },
  {
    ID: 14006,
    label: 'Машинист подборочно-швейной машины',
  },
  {
    ID: 14008,
    label: 'Машинист подземных самоходных машин',
  },
  { ID: 14010, label: 'Машинист подземных установок' },
  { ID: 14012, label: 'Машинист подъемника' },
  {
    ID: 14014,
    label: 'Машинист подъемника грузопассажирского строительного',
  },
  {
    ID: 14016,
    label: 'Машинист подъемника каротажной станции',
  },
  {
    ID: 14017,
    label: 'Машинист подъемника мачтового, стоечного и шахтного',
  },
  {
    ID: 14019,
    label: 'Машинист подъемника по опробованию скважин',
  },
  { ID: 14021, label: 'Машинист подъемной машины' },
  {
    ID: 14023,
    label: 'Машинист подъемно-передвижных подмостей',
  },
  {
    ID: 14029,
    label: 'Машинист полуавтоматической линии двоения овчины',
  },
  {
    ID: 14031,
    label: 'Машинист по моторным испытаниям топлива',
  },
  { ID: 14033, label: 'Машинист помповой (донкерман' },
  { ID: 14035, label: 'Машинист по навивке канатов' },
  {
    ID: 14037,
    label: 'Машинист по обслуживанию силосов и угольной башни',
  },
  {
    ID: 14039,
    label: 'Машинист по очистке кукурузных початков',
  },
  {
    ID: 14040,
    label: 'Машинист по разматыванию щетинных лент',
  },
  {
    ID: 14044,
    label: 'Машинист поточно-автоматизированных линий переработки табака',
  },
  {
    ID: 14046,
    label: 'Машинист поточной линии формования хлебных изделий',
  },
  {
    ID: 14048,
    label:
      'Машинист поточно-механизированных папиросо-сигаретных линий и машин',
  },
  { ID: 14050, label: 'Машинист по цементажу скважин' },
  { ID: 14052, label: 'Машинист предформователя' },
  { ID: 14053, label: 'Машинист пресса' },
  { ID: 14054, label: 'Машинист пресс-гранулятора' },
  { ID: 14055, label: 'Машинист пресспата (сеточник)' },
  { ID: 14057, label: 'Машинист прикатной машины' },
  { ID: 14059, label: 'Машинист приклеечной машины' },
  { ID: 14061, label: 'Машинист прокатной машины' },
  {
    ID: 14063,
    label: 'Машинист прокатной машины термостойкого стекла и стеклопрофилита',
  },
  { ID: 14065, label: 'Машинист промывочного агрегата' },
  { ID: 14067, label: 'Машинист промывочной машины' },
  {
    ID: 14070,
    label: 'Машинист промывочно-сушильно-ширильно-стабилизационной линии',
  },
  { ID: 14072, label: 'Машинист промывочных машин' },
  { ID: 14074, label: 'Машинист пропиточного агрегата' },
  { ID: 14076, label: 'Машинист просеивающих установок' },
  { ID: 14078, label: 'Машинист протекторного агрегата' },
  { ID: 14080, label: 'Машинист протирочных машин' },
  { ID: 14082, label: 'Машинист профилировщика' },
  {
    ID: 14084,
    label: 'Машинист проходческого комплекса',
  },
  { ID: 14085, label: 'Машинист пруфера' },
  { ID: 14089, label: 'Машинист путепередвигателя' },
  { ID: 14098, label: 'Машинист пылевых насосов' },
  { ID: 14100, label: 'Машинист разводной машины' },
  {
    ID: 14102,
    label: 'Машинист разволакнивающей машины',
  },
  { ID: 14103, label: 'Машинист разливочной машины' },
  { ID: 14104, label: 'Машинист разливочной машины' },
  {
    ID: 14106,
    label: 'Машинист разливочно-наполнительных автоматов',
  },
  {
    ID: 14108,
    label: 'Машинист размольного оборудования',
  },
  {
    ID: 14110,
    label: 'Машинист размораживающей установки',
  },
  { ID: 14112, label: 'Машинист расплеточной машины' },
  {
    ID: 14114,
    label: 'Машинист распределителя цемента аэрационного самоходного',
  },
  {
    ID: 14115,
    label: 'Машинист распределителя цемента гравитационного прицепного',
  },
  { ID: 14117, label: 'Машинист растворонасоса' },
  {
    ID: 14119,
    label: 'Машинист растворосмесителя передвижного',
  },
  {
    ID: 14121,
    label: 'Машинист расфасовочно-упаковочных машин',
  },
  { ID: 14123, label: 'Машинист ратинирующей машины' },
  { ID: 14125, label: 'Машинист реактивной установки' },
  {
    ID: 14127,
    label: 'Машинист реверсивной паровой машины прокатного стана',
  },
  {
    ID: 14129,
    label: 'Машинист регенерационной установки',
  },
  { ID: 14131, label: 'Машинист-регулировщик' },
  { ID: 14133, label: 'Машинист резальных машин' },
  { ID: 14135, label: 'Машинист резиносмесителя' },
  { ID: 14137, label: 'Машинист рекордерной машины' },
  { ID: 14139, label: 'Машинист релевочной машины' },
  {
    ID: 14144,
    label: 'Машинист ритуального оборудования',
  },
  { ID: 14146, label: 'Машинист рифлевальной машины' },
  { ID: 14151, label: 'Машинист ровничной машины' },
  { ID: 14153, label: 'Машинист рубильной машины' },
  { ID: 14155, label: 'Машинист рубительной машины' },
  {
    ID: 14157,
    label: 'Машинист рудоусреднительной машины',
  },
  { ID: 14158, label: 'Машинист рушальных установок' },
  { ID: 14160, label: 'Машинист рыбомучной установки' },
  { ID: 14162, label: 'Машинист рыбоподъемника' },
  {
    ID: 14164,
    label: 'Машинист рыбопромысловых машин и механизмов',
  },
  { ID: 14165, label: 'Машинист рыхлительных машин' },
  {
    ID: 14167,
    label: 'Машинист самоходного весоповерочного вагона',
  },
  {
    ID: 14169,
    label: 'Машинист самоходного кабелепередвижчика',
  },
  {
    ID: 14171,
    label: 'Машинист самоходной газорастворомешалки',
  },
  { ID: 14173, label: 'Машинист сбивальных машин' },
  {
    ID: 14174,
    label: 'Машинист семеочистительных машин',
  },
  { ID: 14175, label: 'Машинист семешлифовальных машин' },
  { ID: 14177, label: 'Машинист силиконовой машины' },
  { ID: 14179, label: 'Машинист сквиджевого станка' },
  { ID: 14181, label: 'Машинист скипового подъемника' },
  { ID: 14183, label: 'Машинист скрепера' },
  { ID: 14184, label: 'Машинист скрепера' },
  { ID: 14185, label: 'Машинист скрепера (скреперист)' },
  { ID: 14187, label: 'Машинист скреперной лебедки' },
  { ID: 14189, label: 'Машинист слитколомателя' },
  {
    ID: 14191,
    label: 'Машинист слюдовыборочной установки',
  },
  {
    ID: 14192,
    label: 'Машинист слюдопластоделательной машины',
  },
  { ID: 14194, label: 'Машинист смесительного агрегата' },
  {
    ID: 14196,
    label: 'Машинист смесительной установки гидрозакладки',
  },
  { ID: 14198, label: 'Машинист смесительных барабанов' },
  {
    ID: 14199,
    label: 'Машинист смесителя асфальтобетона передвижного',
  },
  { ID: 14203, label: 'Машинист солекомбайна' },
  {
    ID: 14205,
    label: 'Машинист солеобогатительной установки',
  },
  {
    ID: 14206,
    label: 'Машинист солеуборочного комбайна',
  },
  { ID: 14208, label: 'Машинист сортировки' },
  {
    ID: 14210,
    label: 'Машинист сортировочного автомата',
  },
  {
    ID: 14214,
    label: 'Машинист сплоточной (сортировочной) машины',
  },
  { ID: 14216, label: 'Машинист сталеструйной машины' },
  { ID: 14218, label: 'Машинист стрейнера' },
  {
    ID: 14220,
    label: 'Машинист сублимационных установок',
  },
  {
    ID: 14222,
    label: 'Машинист сухих доковых установок',
  },
  { ID: 14224, label: 'Машинист сушильной установки' },
  { ID: 14225, label: 'Машинист сушильных агрегатов' },
  { ID: 14227, label: 'Машинист сцены' },
  { ID: 14229, label: 'Машинист сшивальной машины' },
  { ID: 14232, label: 'Машинист сырьевых мельниц' },
  { ID: 14234, label: 'Машинист табакорезальных машин' },
  { ID: 14236, label: 'Машинист-таблетировщик' },
  { ID: 14238, label: 'Машинист телескопических трапов' },
  { ID: 14239, label: 'Машинист тельфера' },
  { ID: 14241, label: 'Машинист тепловоза' },
  {
    ID: 14243,
    label: 'Машинист термодисперсионной установки',
  },
  {
    ID: 14245,
    label: 'Машинист термостабилизационной машины',
  },
  { ID: 14249, label: 'Машинист терочных машин' },
  { ID: 14251, label: 'Машинист тестомесильных машин' },
  { ID: 14253, label: 'Машинист тесторазделочных машин' },
  { ID: 14255, label: 'Машинист тетрадных агрегатов' },
  {
    ID: 14257,
    label: 'Машинист технологических компрессоров',
  },
  {
    ID: 14259,
    label: 'Машинист технологических насосов',
  },
  { ID: 14261, label: 'Машинист топливоподачи' },
  {
    ID: 14263,
    label: 'Машинист торфодобывающего экскаватора',
  },
  { ID: 14265, label: 'Машинист трамбовки' },
  {
    ID: 14267,
    label: 'Машинист-транспортировщик горячего металла',
  },
  { ID: 14269, label: 'Машинист трелевочной машины' },
  { ID: 14271, label: 'Машинист трубной машины' },
  {
    ID: 14273,
    label: 'Машинист трубогибочной установки передвижной',
  },
  {
    ID: 14275,
    label: 'Машинист трубоочистительной машины',
  },
  { ID: 14277, label: 'Машинист трубоукладчика' },
  {
    ID: 14279,
    label: 'Машинист трубочной машины (трубочник)',
  },
  { ID: 14282, label: 'Машинист тягового агрегата' },
  { ID: 14284, label: 'Машинист уборочных машин' },
  { ID: 14286, label: 'Машинист угольных мельниц' },
  {
    ID: 14288,
    label: 'Машинист укладчика асфальтобетона',
  },
  { ID: 14290, label: 'Машинист упаковочной машины' },
  {
    ID: 14291,
    label: 'Машинист уплотняющей и планировочно-уплотняющей машины',
  },
  {
    ID: 14293,
    label:
      'Машинист установки передвижной автоматизированной непрерывного действия для приготовления бетонных смесей',
  },
  {
    ID: 14295,
    label: 'Машинист установки по бурению стволов шахт полным сечением',
  },
  {
    ID: 14297,
    label:
      'Машинист установки по испытанию железобетонных изделий и конструкций',
  },
  {
    ID: 14299,
    label: 'Машинист установки по обработке транспортных средств',
  },
  {
    ID: 14301,
    label: 'Машинист установки по приготовлению пульпы',
  },
  {
    ID: 14303,
    label:
      'Машинист установки по продавливанию и горизонтальному бурению грунта',
  },
  {
    ID: 14305,
    label: 'Машинист установки по разрушению негабаритов горной массы',
  },
  {
    ID: 14307,
    label: 'Машинист установки по расщеплению слюды',
  },
  {
    ID: 14311,
    label: 'Машинист установки самоклеящихся пленок',
  },
  {
    ID: 14313,
    label: 'Машинист установки сухого тушения кокса',
  },
  {
    ID: 14315,
    label: 'Машинист установок обогащения и брикетирования',
  },
  {
    ID: 14316,
    label: 'Машинист установок по обслуживанию подвижного состава',
  },
  { ID: 14317, label: 'Машинист фальцевальных машин' },
  { ID: 14319, label: 'Машинист фиксационных машин' },
  {
    ID: 14321,
    label: 'Машинист фильтроделательных машин',
  },
  { ID: 14322, label: 'Машинист фильтр-пресса' },
  { ID: 14324, label: 'Машинист финишера' },
  { ID: 14326, label: 'Машинист формовочного агрегата' },
  { ID: 14328, label: 'Машинист формовочной машины' },
  {
    ID: 14330,
    label: 'Машинист формующе-завертывающего полуавтомата',
  },
  { ID: 14331, label: 'Машинист формующих машин' },
  { ID: 14333, label: 'Машинист фрезагрегата' },
  {
    ID: 14335,
    label: 'Машинист фрезерно-зачистной машины',
  },
  { ID: 14337, label: 'Машинист холодильника' },
  {
    ID: 14339,
    label: 'Машинист холодильной установки по замораживанию грунтов',
  },
  { ID: 14341, label: 'Машинист холодильных установок' },
  { ID: 14345, label: 'Машинист цементных мельниц' },
  {
    ID: 14347,
    label: 'Машинист центрального теплового щита управления котлами',
  },
  {
    ID: 14349,
    label: 'Машинист центрального теплового щита управления паровыми турбинами',
  },
  {
    ID: 14351,
    label: 'Машинист чаезавялочно-фиксационных машин',
  },
  { ID: 14352, label: 'Машинист чаезавялочных машин' },
  { ID: 14354, label: 'Машинист чаескручивающих машин' },
  { ID: 14356, label: 'Машинист чаесушильных машин' },
  {
    ID: 14358,
    label: 'Машинист чесальных и мешальных машин',
  },
  {
    ID: 14360,
    label: 'Машинист шахтных холодильных установок',
  },
  {
    ID: 14362,
    label: 'Машинист швейных машин и автоматов',
  },
  { ID: 14364, label: 'Машинист шихтоподачи' },
  { ID: 14366, label: 'Машинист шламовых насосов' },
  {
    ID: 14368,
    label: 'Машинист шоколадо-отделочных машин',
  },
  { ID: 14371, label: 'Машинист шпрединг-машины' },
  { ID: 14373, label: 'Машинист шприц-машины' },
  {
    ID: 14375,
    label: 'Машинист шпулезаверточных и конусных машин',
  },
  {
    ID: 14377,
    label: 'Машинист штабелеформирующей машины',
  },
  {
    ID: 14379,
    label: 'Машинист штукатурной станции передвижной',
  },
  { ID: 14381, label: 'Машинист штыревого крана' },
  {
    ID: 14382,
    label: 'Машинист шурфопроходческого агрегата',
  },
  { ID: 14386, label: 'Машинист эксгаустера' },
  { ID: 14388, label: 'Машинист экскаватора' },
  {
    ID: 14390,
    label: 'Машинист экскаватора одноковшового',
  },
  { ID: 14392, label: 'Машинист экскаватора роторного' },
  { ID: 14393, label: 'Машинист экструдера' },
  { ID: 14395, label: 'Машинист экструзионного пресса' },
  {
    ID: 14397,
    label:
      'Машинист электрического оборудования землесосных плавучих несамоходных снарядов и грунтонасосных установок',
  },
  { ID: 14399, label: 'Машинист электровоза' },
  {
    ID: 14401,
    label: 'Машинист электровоза металлургического цеха',
  },
  {
    ID: 14403,
    label: 'Машинист электровоза тушильного вагона',
  },
  { ID: 14405, label: 'Машинист электролафета' },
  { ID: 14407, label: 'Машинист электролебедки' },
  { ID: 14409, label: 'Машинист электропоезда' },
  {
    ID: 14411,
    label:
      'Машинист электросварочного передвижного агрегата с двигателем внутреннего сгорания',
  },
  {
    ID: 14413,
    label: 'Машинист электростанции передвижной',
  },
  { ID: 14415, label: 'Машинист энергоблока' },
  { ID: 14416, label: 'Машинист энсонитной машины' },
  { ID: 14418, label: 'Машинист эскалатора' },
  { ID: 14420, label: 'Медник' },
  {
    ID: 14422,
    label: 'Медник по изготовлению судовых изделий',
  },
  { ID: 14424, label: 'Мездрильщик' },
  { ID: 14426, label: 'Мездрильщик шкур' },
  { ID: 14427, label: 'Мездрильщик шкурок кроликов' },
  { ID: 14429, label: 'Меланжист' },
  { ID: 14431, label: 'Мельник' },
  {
    ID: 14433,
    label: 'Мельник деревообрабатывающего производства',
  },
  { ID: 14435, label: 'Мельник извести' },
  { ID: 14437, label: 'Мельник минерального сырья' },
  { ID: 14438, label: 'Мельник эмалевых материалов' },
  { ID: 14440, label: 'Металлизатор' },
  {
    ID: 14442,
    label: 'Металлизатор электрокерамических изделий',
  },
  {
    ID: 14444,
    label:
      'Механизатор (докермеханизатор) комплексной бригады на погрузочно-разгрузочных работах',
  },
  {
    ID: 14448,
    label: 'Механик по обслуживанию ветроустановок',
  },
  {
    ID: 14450,
    label: 'Механик по обслуживанию звуковой техники',
  },
  {
    ID: 14452,
    label: 'Механик по обслуживанию кинотелевизионного оборудования',
  },
  {
    ID: 14454,
    label: 'Механик по обслуживанию съемочной техники',
  },
  {
    ID: 14455,
    label: 'Механик по техническим видам спорта',
  },
  {
    ID: 14456,
    label: 'Механик по обслуживанию телевизионного оборудования',
  },
  {
    ID: 14457,
    label: 'Механик протезно-ортопедических изделий',
  },
  { ID: 14458, label: 'Мешальщик керамического шликера' },
  {
    ID: 14460,
    label: 'Мешальщик сухой массы (для свинцовых аккумуляторов)',
  },
  { ID: 14461, label: 'Мешальщик угольных масс' },
  { ID: 14463, label: 'Миксеровой' },
  { ID: 14465, label: 'Миксовщик' },
  {
    ID: 14472,
    label: 'Моделировщик искусственных зубов',
  },
  { ID: 14474, label: 'Модельер колодок' },
  { ID: 14476, label: 'Модельер коробок' },
  { ID: 14478, label: 'Модельер ортопедической обуви' },
  { ID: 14480, label: 'Модельщик архитектурных деталей' },
  {
    ID: 14482,
    label: 'Модельщик аэрогидродинамических моделей из металла',
  },
  {
    ID: 14483,
    label: 'Модельщик аэрогидродинамических моделей из неметалла',
  },
  { ID: 14485, label: 'Модельщик выплавляемых моделей' },
  { ID: 14487, label: 'Модельщик гипсовых моделей' },
  {
    ID: 14489,
    label: 'Модельщик керамического производства',
  },
  { ID: 14491, label: 'Модельщик наглядных пособий' },
  { ID: 14493, label: 'Модельщик по деревянным моделям' },
  {
    ID: 14495,
    label: 'Модельщик по металлическим моделям',
  },
  {
    ID: 14497,
    label: 'Модельщик по моделям из эпоксидных смол',
  },
  { ID: 14499, label: 'Модельщик резиновой обуви' },
  {
    ID: 14501,
    label: 'Модельщик скульптурного производства',
  },
  { ID: 14502, label: 'Модельщик стеклопластиков' },
  { ID: 14504, label: 'Модистка головных уборов' },
  { ID: 14506, label: 'Мозаичник' },
  {
    ID: 14508,
    label: 'Мозаичник монументально-декоративной живописи',
  },
  { ID: 14510, label: 'Мойщик ковров' },
  {
    ID: 14512,
    label: 'Мойщик колб с применением кислотных растворов',
  },
  { ID: 14514, label: 'Мойщик летательных аппаратов' },
  { ID: 14516, label: 'Мойщик мездры и волоса' },
  { ID: 14518, label: 'Мойщик мокрых отходов' },
  { ID: 14520, label: 'Мойщик покрышек' },
  { ID: 14522, label: 'Мойщик посуды' },
  { ID: 14523, label: 'Мойщик посуды и ампул' },
  { ID: 14525, label: 'Мойщик-сушильщик металла' },
  { ID: 14527, label: 'Мойщик сыра' },
  {
    ID: 14528,
    label: 'Мойщик-уборщик подвижного состава',
  },
  { ID: 14530, label: 'Мойщик фибры' },
  { ID: 14532, label: 'Мойщик холстов' },
  {
    ID: 14534,
    label: 'Мойщик шерсти (валяльно-войлочное производство)',
  },
  {
    ID: 14536,
    label: 'Мойщик шерсти (шерстяное производство)',
  },
  { ID: 14537, label: 'Мойщик щетины и волоса' },
  { ID: 14539, label: 'Моллировщик стекла' },
  { ID: 14541, label: 'Монтажист' },
  { ID: 14544, label: 'Монтажник' },
  {
    ID: 14546,
    label: 'Монтажник аппаратуры специальных головных уборов',
  },
  { ID: 14548, label: 'Монтажник-вакуумщик' },
  { ID: 14552, label: 'Монтажник геодезических знаков' },
  { ID: 14554, label: 'Монтажник гидроагрегатов' },
  { ID: 14556, label: 'Монтажник горного оборудования' },
  {
    ID: 14558,
    label:
      'Монтажник дробильно-размольного оборудования и оборудования для сортировки и обогащения',
  },
  {
    ID: 14560,
    label: 'Монтажник компрессоров, насосов и вентиляторов',
  },
  {
    ID: 14562,
    label: 'Монтажник металлорежущего и кузнечно-прессового оборудования',
  },
  {
    ID: 14564,
    label: 'Монтажник механического оборудования гидротехнических сооружений',
  },
  { ID: 14566, label: 'Монтажник микромодулей' },
  { ID: 14568, label: 'Монтажник на ремонте ванн' },
  { ID: 14569, label: 'Монтажник на ремонте печей' },
  {
    ID: 14571,
    label: 'Монтажник наружных трубопроводов',
  },
  { ID: 14573, label: 'Монтажник негатива' },
  {
    ID: 14575,
    label: 'Монтажник оборудования атомных электрических станций',
  },
  {
    ID: 14577,
    label:
      'Монтажник оборудования блокировки и централизации на железнодорожном транспорте',
  },
  {
    ID: 14579,
    label: 'Монтажник оборудования деревообрабатывающих предприятий',
  },
  {
    ID: 14581,
    label:
      'Монтажник оборудования зернохранилищ и предприятий по промышленной переработке зерна',
  },
  {
    ID: 14582,
    label: 'Монтажник оборудования коксохимических производств',
  },
  {
    ID: 14585,
    label: 'Монтажник оборудования котельных установок',
  },
  {
    ID: 14587,
    label: 'Монтажник оборудования металлургических заводов',
  },
  {
    ID: 14589,
    label: 'Монтажник оборудования предприятий пищевой промышленности',
  },
  {
    ID: 14591,
    label: 'Монтажник оборудования предприятий полиграфической промышленности',
  },
  {
    ID: 14593,
    label: 'Монтажник оборудования предприятий строительных материалов',
  },
  {
    ID: 14595,
    label: 'Монтажник оборудования предприятий текстильной промышленности',
  },
  {
    ID: 14597,
    label:
      'Монтажник оборудования предприятий химической и нефтяной промышленности',
  },
  {
    ID: 14599,
    label:
      'Монтажник оборудования предприятий целлюлозно-бумажной промышленности',
  },
  { ID: 14601, label: 'Монтажник оборудования связи' },
  {
    ID: 14603,
    label: 'Монтажник оборудования сортировочных горок',
  },
  {
    ID: 14605,
    label: 'Монтажник оборудования холодильных установок',
  },
  {
    ID: 14607,
    label:
      'Монтажник подъемно-транспортного оборудования непрерывного действия',
  },
  {
    ID: 14608,
    label: 'Монтажник подъемно-транспортного оборудования прерывного действия',
  },
  { ID: 14610, label: 'Монтажник позитива' },
  {
    ID: 14612,
    label: 'Монтажник по монтажу стальных и железобетонных конструкций',
  },
  {
    ID: 14614,
    label:
      'Монтажник приборов и аппаратуры автоматического контроля, регулирования и управления',
  },
  {
    ID: 14616,
    label: 'Монтажник радио- и специального оборудования летательных аппаратов',
  },
  {
    ID: 14618,
    label: 'Монтажник радиоэлектронной аппаратуры и приборов',
  },
  { ID: 14620, label: 'Монтажник реакционных аппаратов' },
  {
    ID: 14621,
    label: 'Монтажник санитарно-технических систем и оборудования',
  },
  {
    ID: 14622,
    label: 'Монтажник санитарно-технического оборудования',
  },
  { ID: 14624, label: 'Монтажник связи - антенщик' },
  { ID: 14626, label: 'Монтажник связи - кабельщик' },
  { ID: 14627, label: 'Монтажник связи - линейщик' },
  { ID: 14629, label: 'Монтажник связи - спайщик' },
  {
    ID: 14631,
    label: 'Монтажник селеновых выпрямителей',
  },
  {
    ID: 14633,
    label: 'Монтажник сельскохозяйственного оборудования',
  },
  {
    ID: 14635,
    label:
      'Монтажник систем вентиляции, кондиционирования воздуха, пневмотранспорта и аспирации',
  },
  {
    ID: 14637,
    label: 'Монтажник строительных машин и механизмов',
  },
  { ID: 14639, label: 'Монтажник тензорезисторов' },
  {
    ID: 14641,
    label: 'Монтажник технологических трубопроводов',
  },
  {
    ID: 14642,
    label:
      'Монтажник технологического оборудования и связанных с ним конструкций',
  },
  {
    ID: 14643,
    label: 'Монтажник трелевочного и погрузочного оборудования',
  },
  {
    ID: 14645,
    label: 'Монтажник турбоагрегатов и синхронных компенсаторов',
  },
  {
    ID: 14646,
    label: 'Монтажник-установщик внешней арматуры',
  },
  {
    ID: 14650,
    label: 'Монтажник фотокомплекта "Момент"',
  },
  {
    ID: 14652,
    label: 'Монтажник шахтного оборудования на поверхности',
  },
  {
    ID: 14654,
    label: 'Монтажник экспозиции и художественно-оформительских работ',
  },
  {
    ID: 14656,
    label: 'Монтажник электрических подъемников (лифтов)',
  },
  {
    ID: 14658,
    label: 'Монтажник электрооборудования летательных аппаратов',
  },
  {
    ID: 14660,
    label: 'Монтажник элементов памяти на ферритах',
  },
  { ID: 14662, label: 'Монтер кабельного производства' },
  {
    ID: 14666,
    label: 'Монтер по защите подземных трубопроводов от коррозии',
  },
  { ID: 14668, label: 'Монтер пути' },
  {
    ID: 14670,
    label: 'Монтер судовых средств безопасности',
  },
  { ID: 14671, label: 'Монтер судоходной обстановки' },
  { ID: 14673, label: 'Монтировщик вентилей' },
  { ID: 14675, label: 'Монтировщик влажных препаратов' },
  {
    ID: 14677,
    label: 'Монтировщик изделий из драгоценных металлов',
  },
  { ID: 14679, label: 'Монтировщик изделий из камня' },
  { ID: 14681, label: 'Монтировщик искусственных зубов' },
  { ID: 14683, label: 'Монтировщик костных препаратов' },
  { ID: 14685, label: 'Монтировщик микросрезов' },
  { ID: 14686, label: 'Монтировщик муляжей и моделей' },
  {
    ID: 14688,
    label: 'Монтировщик остеологических коллекций',
  },
  {
    ID: 14690,
    label: 'Монтировщик скелетов мелких животных',
  },
  {
    ID: 14692,
    label: 'Монтировщик стеклометаллизированной нити',
  },
  {
    ID: 14694,
    label: 'Монтировщик сухих биологических объектов',
  },
  { ID: 14696, label: 'Монтировщик сцены' },
  {
    ID: 14698,
    label: 'Монтировщик технологических коллекций',
  },
  { ID: 14700, label: 'Монтировщик шин' },
  {
    ID: 14701,
    label: 'Монтировщик щинопневматических муфт',
  },
  { ID: 14703, label: 'Мостовщик' },
  { ID: 14704, label: 'Мотальщик' },
  {
    ID: 14708,
    label: 'Моторист автоматизированной топливоподачи',
  },
  {
    ID: 14710,
    label: 'Моторист багерной (шламовой) насосной',
  },
  {
    ID: 14712,
    label: 'Моторист бетоносмесительных установок',
  },
  { ID: 14713, label: 'Моторист буровой установки' },
  {
    ID: 14715,
    label: 'Моторист вентиляционной установки',
  },
  { ID: 14717, label: 'Моторист водосброса' },
  { ID: 14718, label: 'Моторист (машинист)' },
  {
    ID: 14719,
    label: 'Моторист (машинист) рефрижераторных установок',
  },
  { ID: 14720, label: 'Моторист механической лопаты' },
  {
    ID: 14721,
    label: 'Моторист на подаче крокусной суспензии',
  },
  { ID: 14723, label: 'Моторист передаточной тележки' },
  { ID: 14725, label: 'Моторист поворотного круга' },
  {
    ID: 14728,
    label: 'Моторист по уборке оборудования электростанций',
  },
  {
    ID: 14730,
    label: 'Моторист промывочного прибора по извлечению металла',
  },
  { ID: 14732, label: 'Моторист рапокачки' },
  {
    ID: 14736,
    label: 'Моторист самостоятельного управления судовым двигателем',
  },
  {
    ID: 14737,
    label: 'Моторист самоходной каротажной станции',
  },
  { ID: 14739, label: 'Моторист-смазчик' },
  { ID: 14741, label: 'Моторист смесителя и мешалки' },
  {
    ID: 14743,
    label: 'Моторист теплой промывки котлов паровозов',
  },
  {
    ID: 14745,
    label: 'Моторист транспортирующих механизмов',
  },
  { ID: 14747, label: 'Моторист триера' },
  { ID: 14748, label: 'Моторист трюмный' },
  {
    ID: 14750,
    label: 'Моторист установки по перекачиванию битума',
  },
  { ID: 14752, label: 'Моторист холодильного барабана' },
  {
    ID: 14754,
    label: 'Моторист цементировочного агрегата',
  },
  {
    ID: 14755,
    label: 'Моторист цементопескосмесительного агрегата',
  },
  { ID: 14757, label: 'Моторист электродвигателей' },
  {
    ID: 14759,
    label: 'Моторист электроразведочной станции',
  },
  { ID: 14761, label: 'Муляжист' },
  { ID: 14762, label: 'Мягчильщик' },
  { ID: 14767, label: 'Набивальщик валиков и фильтров' },
  {
    ID: 14769,
    label: 'Набивальщик наконечников на шнур',
  },
  {
    ID: 14771,
    label: 'Набивальщик трубчатых электронагревателей',
  },
  { ID: 14773, label: 'Набивщик блоков' },
  { ID: 14775, label: 'Набивщик изделий' },
  { ID: 14777, label: 'Набивщик одонков' },
  { ID: 14781, label: 'Набойщик рисунков' },
  { ID: 14783, label: 'Набойщик рисунков манерами' },
  { ID: 14784, label: 'Набойщик рисунков на обои' },
  {
    ID: 14786,
    label: 'Наборщик блока из остеклованных стержней',
  },
  { ID: 14788, label: 'Наборщик бумажных валов' },
  { ID: 14790, label: 'Наборщик ворсовальных рамок' },
  { ID: 14792, label: 'Наборщик вручную' },
  { ID: 14794, label: 'Наборщик деталей часов и камней' },
  { ID: 14796, label: 'Наборщик зубцов и петель' },
  {
    ID: 14797,
    label: 'Наборщик игольно-платинных изделий',
  },
  {
    ID: 14799,
    label: 'Наборщик керамических плиток в ковры',
  },
  {
    ID: 14801,
    label: 'Наборщик ковриков из мозаичной плитки',
  },
  { ID: 14803, label: 'Наборщик на машинах' },
  {
    ID: 14805,
    label: 'Наборщик на наборно-печатающих машинах',
  },
  {
    ID: 14807,
    label: 'Наборщик на наборно-строкоотливных машинах',
  },
  {
    ID: 14809,
    label: 'Наборщик облицовочных материалов для мебели',
  },
  { ID: 14810, label: 'Наборщик пакетов листов и труб' },
  { ID: 14811, label: 'Наборщик проб в шахте' },
  { ID: 14814, label: 'Наборщик ремиз' },
  { ID: 14816, label: 'Наборщик рисунка' },
  { ID: 14818, label: 'Наборщик рисунчатых валов' },
  { ID: 14820, label: 'Наборщик стекломассы' },
  { ID: 14822, label: 'Наборщик стопоров' },
  { ID: 14824, label: 'Наборщик текстолитовых ободов' },
  { ID: 14826, label: 'Наборщик цен' },
  { ID: 14829, label: 'Навалоотбойщик' },
  {
    ID: 14831,
    label: 'Навальщик-свальщик лесоматериалов',
  },
  { ID: 14833, label: 'Навальщик соли в бассейнах' },
  { ID: 14835, label: 'Навесчик заготовок' },
  { ID: 14839, label: 'Навесчик стекла' },
  { ID: 14841, label: 'Навивальщик основ' },
  { ID: 14843, label: 'Навивщик картона' },
  { ID: 14845, label: 'Навивщик магнитопроводов' },
  {
    ID: 14847,
    label: 'Навивщик металлокордного полотна',
  },
  { ID: 14849, label: 'Навивщик пружин' },
  {
    ID: 14851,
    label: 'Навойщик-проборщик основы металлосеток',
  },
  { ID: 14852, label: 'Нагревальщик металла' },
  { ID: 14854, label: 'Нагревальщик (сварщик) металла' },
  { ID: 14856, label: 'Нагревальщик цветных металлов' },
  { ID: 14860, label: 'Наездник' },
  { ID: 14862, label: 'Наждачник' },
  {
    ID: 14864,
    label: 'Накалывальщик растительного войлока',
  },
  { ID: 14866, label: 'Накатчик' },
  {
    ID: 14868,
    label: 'Накатчик бумагоделательной (картоноделательной) машины',
  },
  { ID: 14870, label: 'Накатчик изделий' },
  {
    ID: 14872,
    label: 'Накатчик клеильно-сушильной машины',
  },
  {
    ID: 14874,
    label: 'Накатчик машины для покрытия бумаги полиэтиленовой пленкой',
  },
  { ID: 14875, label: 'Накатчик-обкатчик' },
  { ID: 14877, label: 'Накатчик пергаментной машины' },
  { ID: 14879, label: 'Накатчик полировальных кругов' },
  { ID: 14881, label: 'Накатчик пресспата' },
  { ID: 14883, label: 'Накатчик резьбы цоколей' },
  { ID: 14885, label: 'Накатчик рисунков' },
  { ID: 14887, label: 'Накатчик ткани, полотна' },
  { ID: 14889, label: 'Накладчик на печатных машинах' },
  {
    ID: 14891,
    label: 'Наклейщик вставок для ювелирных и художественных изделий',
  },
  { ID: 14893, label: 'Наклейщик заготовок' },
  { ID: 14895, label: 'Наклейщик орнамента на багет' },
  {
    ID: 14897,
    label: 'Наладчик автоматизированных вагон-весов',
  },
  {
    ID: 14899,
    label: 'Наладчик автоматических линий и агрегатных станков',
  },
  {
    ID: 14901,
    label: 'Наладчик автоматов и полуавтоматов',
  },
  {
    ID: 14903,
    label: 'Наладчик автоматов сварки выводов',
  },
  {
    ID: 14905,
    label: 'Наладчик автоматов элементного производства',
  },
  {
    ID: 14907,
    label: 'Наладчик асбестоцементного оборудования',
  },
  {
    ID: 14910,
    label: 'Наладчик геофизической аппаратуры',
  },
  {
    ID: 14912,
    label: 'Наладчик деревообрабатывающего оборудования',
  },
  {
    ID: 14914,
    label: 'Наладчик зуборезных и резьбофрезерных станков',
  },
  {
    ID: 14915,
    label: 'Наладчик зубофрезерных автоматов и полуавтоматов',
  },
  {
    ID: 14917,
    label: 'Наладчик контрольно-измерительных вагонов',
  },
  {
    ID: 14919,
    label: 'Наладчик контрольно-измерительных приборов и автоматики',
  },
  {
    ID: 14921,
    label: 'Наладчик кузнечно-прессового оборудования',
  },
  { ID: 14923, label: 'Наладчик литейных машин' },
  {
    ID: 14925,
    label:
      'Наладчик машин и автоматических линий по производству изделий из пластмасс',
  },
  { ID: 14926, label: 'Наладчик моечных машин' },
  {
    ID: 14928,
    label: 'Наладчик-монтажник испытательного оборудования',
  },
  {
    ID: 14932,
    label: 'Наладчик настольных станков и прессов',
  },
  {
    ID: 14934,
    label: 'Наладчик оборудования в бумажном производстве',
  },
  {
    ID: 14936,
    label:
      'Наладчик оборудования в производстве асбестовых технических изделий',
  },
  {
    ID: 14938,
    label: 'Наладчик оборудования в производстве аэрозольных упаковок',
  },
  {
    ID: 14940,
    label: 'Наладчик оборудования в производстве драгоценных металлов',
  },
  {
    ID: 14942,
    label:
      'Наладчик оборудования в производстве металлических канатов, сеток, пружин, щеток и цепей',
  },
  {
    ID: 14944,
    label: 'Наладчик оборудования в производстве пищевой продукции',
  },
  {
    ID: 14946,
    label:
      'Наладчик оборудования в производстве стекловолокна и стеклопластиков',
  },
  {
    ID: 14948,
    label: 'Наладчик оборудования в производстве стеновых и вяжущих материалов',
  },
  {
    ID: 14950,
    label: 'Наладчик оборудования в производстве теплоизоляционных материалов',
  },
  {
    ID: 14952,
    label: 'Наладчик оборудования железобетонного производства',
  },
  {
    ID: 14954,
    label: 'Наладчик оборудования жестяно-баночного производства',
  },
  {
    ID: 14956,
    label: 'Наладчик оборудования и агрегатов в термообработке',
  },
  {
    ID: 14958,
    label: 'Наладчик оборудования игольного производства',
  },
  {
    ID: 14960,
    label: 'Наладчик оборудования керамического производства',
  },
  {
    ID: 14962,
    label: 'Наладчик оборудования лакокрасочных покрытий',
  },
  {
    ID: 14964,
    label: 'Наладчик оборудования металлопокрытия и окраски',
  },
  {
    ID: 14966,
    label: 'Наладчик оборудования оптического производства',
  },
  {
    ID: 14968,
    label: 'Наладчик оборудования по обработке камня',
  },
  {
    ID: 14970,
    label: 'Наладчик оборудования по производству резиновых изделий и обуви',
  },
  { ID: 14972, label: 'Наладчик окомковательных машин' },
  {
    ID: 14974,
    label: 'Наладчик полиграфического оборудования',
  },
  {
    ID: 14975,
    label: 'Наладчик полуавтоматических установок аккумуляторного производства',
  },
  {
    ID: 14976,
    label: 'Наладчик поточно-автоматизированных линий производства сигарет',
  },
  {
    ID: 14977,
    label:
      'Наладчик приборов, аппаратуры и систем автоматического контроля, регулирования и управления (наладчик КИП и автоматики)',
  },
  {
    ID: 14979,
    label: 'Наладчик железнодорожно-строительных машин и механизмов',
  },
  {
    ID: 14981,
    label: 'Наладчик ремизобердочного оборудования',
  },
  {
    ID: 14983,
    label: 'Наладчик сборочных автоматов, полуавтоматов и автоматических линий',
  },
  {
    ID: 14985,
    label: 'Наладчик сварочного и газоплазморезательного оборудования',
  },
  {
    ID: 14986,
    label: 'Наладчик сельскохозяйственных машин и тракторов',
  },
  {
    ID: 14987,
    label: 'Наладчик сортировочных автоматов',
  },
  {
    ID: 14989,
    label: 'Наладчик станков и манипуляторов с программным управлением',
  },
  {
    ID: 14991,
    label: 'Наладчик стекольных автоматов и полуавтоматов',
  },
  { ID: 14993, label: 'Наладчик строительных машин' },
  {
    ID: 14995,
    label: 'Наладчик технологического оборудования',
  },
  {
    ID: 14996,
    label: 'Наладчик технологического оборудования',
  },
  {
    ID: 14997,
    label: 'Наладчик ускорителей заряженных частиц',
  },
  {
    ID: 14999,
    label: 'Наладчик установок для синтеза алмазов и сверхтвердых материалов',
  },
  {
    ID: 15000,
    label: 'Наладчик формовочных и стержневых машин',
  },
  {
    ID: 15002,
    label: 'Наладчик холодноштамповочного оборудования',
  },
  { ID: 15004, label: 'Наладчик шлифовальных станков' },
  { ID: 15006, label: 'Намазчик аккумуляторных пластин' },
  { ID: 15008, label: 'Намазчик деталей' },
  { ID: 15010, label: 'Намазчик пасты' },
  { ID: 15012, label: 'Намазчик спичечных коробок' },
  { ID: 15013, label: 'Намазчик целлулоида' },
  { ID: 15015, label: 'Намазчик электропроводного слоя' },
  { ID: 15017, label: 'Намотчик бумажных цилиндров' },
  { ID: 15019, label: 'Намотчик иглиц' },
  {
    ID: 15021,
    label: 'Намотчик изоляционных остовов вводов',
  },
  { ID: 15023, label: 'Намотчик катушек' },
  {
    ID: 15025,
    label: 'Намотчик катушек для электроприборов и аппаратов',
  },
  {
    ID: 15027,
    label: 'Намотчик катушек и секций электромашин',
  },
  {
    ID: 15029,
    label: 'Намотчик катушек трансформаторов',
  },
  {
    ID: 15031,
    label: 'Намотчик материалов и полуфабрикатов',
  },
  { ID: 15034, label: 'Намотчик проволоки и тросов' },
  { ID: 15036, label: 'Намотчик пряжи' },
  { ID: 15038, label: 'Намотчик резисторов' },
  { ID: 15040, label: 'Намотчик ровничной машины' },
  { ID: 15043, label: 'Намотчик рулонов' },
  { ID: 15045, label: 'Намотчик секций конденсаторов' },
  {
    ID: 15047,
    label: 'Намотчик секций силовых конденсаторов',
  },
  { ID: 15049, label: 'Намотчик серпантина' },
  { ID: 15051, label: 'Намотчик сеток' },
  { ID: 15053, label: 'Намотчик спиралей и сеток' },
  { ID: 15055, label: 'Намотчик утка' },
  { ID: 15056, label: 'Намотчик фильтров' },
  {
    ID: 15058,
    label: 'Намотчик электроизоляционных изделий',
  },
  {
    ID: 15060,
    label: 'Намотчик электромагнитных сердечников',
  },
  { ID: 15062, label: 'Напайщик' },
  { ID: 15064, label: 'Наплавщик пластмассы' },
  { ID: 15066, label: 'Наполнитель ампул' },
  { ID: 15068, label: 'Наполнитель баллонов' },
  { ID: 15070, label: 'Наполнитель контейнеров' },
  { ID: 15072, label: 'Наполнитель приборов газами' },
  { ID: 15073, label: 'Наполнитель приборов жидкостями' },
  {
    ID: 15075,
    label: 'Нарезчик изделий из кости и рога',
  },
  { ID: 15077, label: 'Насадчик бобин' },
  { ID: 15079, label: 'Насадчик валов' },
  { ID: 15080, label: 'Насадчик дефибрерных камней' },
  { ID: 15082, label: 'Насадчик манжет' },
  { ID: 15084, label: 'Насадчик обуви' },
  { ID: 15086, label: 'Насекальщик карт' },
  { ID: 15088, label: 'Насекальщик мелющих камней' },
  {
    ID: 15090,
    label: 'Насекальщик напильников, рашпилей и пил',
  },
  { ID: 15091, label: 'Настильщик' },
  { ID: 15092, label: 'Настильщик' },
  { ID: 15094, label: 'Настильщик материалов' },
  { ID: 15096, label: 'Настильщик стекла' },
  { ID: 15097, label: 'Настильщик фильтр-полотен' },
  { ID: 15099, label: 'Настройщик духовых инструментов' },
  { ID: 15101, label: 'Настройщик музыкальных игрушек' },
  { ID: 15103, label: 'Настройщик пианино и роялей' },
  {
    ID: 15105,
    label: 'Настройщик полупроводниковых приборов',
  },
  { ID: 15106, label: 'Настройщик пьезорезонаторов' },
  {
    ID: 15108,
    label: 'Настройщик-регулировщик смычковых инструментов',
  },
  {
    ID: 15110,
    label: 'Настройщик щипковых инструментов',
  },
  {
    ID: 15111,
    label: 'Настройщик язычковых инструментов',
  },
  { ID: 15113, label: 'Насыпщик цемента' },
  { ID: 15115, label: 'Натяжчик сеток' },
  { ID: 15117, label: 'Нейтрализаторщик' },
  {
    ID: 15119,
    label: 'Нейтрализаторщик хромовой стружки',
  },
  {
    ID: 15121,
    label: 'Нейтрализаторщик цианистых растворов',
  },
  { ID: 15124, label: 'Нормализаторщик' },
  { ID: 15126, label: 'Носильщик' },
  { ID: 15129, label: 'Нотогравер' },
  { ID: 15131, label: 'Нотографик' },
  { ID: 15133, label: 'Нумеровщик' },
  { ID: 15135, label: 'Няня' },
  { ID: 15141, label: 'Обвальщик мяса' },
  { ID: 15143, label: 'Обвальщик тушек птицы' },
  { ID: 15145, label: 'Обвязчик агломератов' },
  {
    ID: 15147,
    label: 'Обвязчик электро-угольных изделий',
  },
  { ID: 15149, label: 'Обдирщик алмазов' },
  { ID: 15151, label: 'Обдувщик абразивных изделий' },
  {
    ID: 15153,
    label: 'Обезвоживатель шлифзерна и шлифпорошков',
  },
  { ID: 15155, label: 'Обжарщик пищевых продуктов' },
  { ID: 15156, label: 'Обжигальщик' },
  { ID: 15157, label: 'Обжигальщик' },
  {
    ID: 15159,
    label: 'Обжигальщик в производстве стекла',
  },
  {
    ID: 15161,
    label: 'Обжигальщик в производстве теплоизоляционных материалов',
  },
  { ID: 15163, label: 'Обжигальщик графитовых стержней' },
  { ID: 15165, label: 'Обжигальщик игрушек' },
  { ID: 15167, label: 'Обжигальщик извести' },
  {
    ID: 15169,
    label: 'Обжигальщик изделий строительной керамики',
  },
  {
    ID: 15171,
    label: 'Обжигальщик керамических пигментов',
  },
  { ID: 15173, label: 'Обжигальщик ламп' },
  { ID: 15175, label: 'Обжигальщик массивных шин' },
  { ID: 15177, label: 'Обжигальщик материалов' },
  { ID: 15179, label: 'Обжигальщик металлической тары' },
  { ID: 15181, label: 'Обжигальщик на печах' },
  { ID: 15183, label: 'Обжигальщик отходов металла' },
  { ID: 15185, label: 'Обжигальщик прядильных деталей' },
  {
    ID: 15187,
    label: 'Обжигальщик радиокерамики, пьезокерамики и ферритов',
  },
  { ID: 15189, label: 'Обжигальщик рисовального угля' },
  { ID: 15191, label: 'Обжигальщик слюды' },
  {
    ID: 15193,
    label: 'Обжигальщик стеновых и вяжущих материалов',
  },
  {
    ID: 15195,
    label: 'Обжигальщик фарфоровых и фаянсовых изделий',
  },
  {
    ID: 15197,
    label: 'Обжигальщик электрокерамических изделий',
  },
  {
    ID: 15199,
    label: 'Обжигальщик электро-угольных изделий',
  },
  { ID: 15201, label: 'Обжигальщик эмали' },
  { ID: 15204, label: 'Обжимщик электросоединителей' },
  { ID: 15206, label: 'Обкатчик клюквы' },
  { ID: 15208, label: 'Обкатчик подшипников' },
  { ID: 15210, label: 'Обкатчик труб' },
  { ID: 15212, label: 'Облицовщик деталей мебели' },
  { ID: 15214, label: 'Облицовщик-мозаичник' },
  { ID: 15216, label: 'Облицовщик-мраморщик' },
  {
    ID: 15218,
    label: 'Облицовщик музыкальных инструментов',
  },
  { ID: 15220, label: 'Облицовщик-плиточник' },
  { ID: 15222, label: 'Облицовщик-полировщик' },
  {
    ID: 15224,
    label: 'Облицовщик синтетическими материалами',
  },
  { ID: 15226, label: 'Обмазчик заслонов' },
  { ID: 15228, label: 'Обмазчик ковшей' },
  { ID: 15230, label: 'Обмазчик листов и труб' },
  { ID: 15234, label: 'Обмолотчик' },
  {
    ID: 15236,
    label: 'Обмотчик элементов электрических машин',
  },
  {
    ID: 15238,
    label: 'Обмуровщик кислотных резервуаров',
  },
  { ID: 15240, label: 'Обогатитель графита' },
  { ID: 15242, label: 'Обогатитель микропорошков' },
  { ID: 15244, label: 'Обогатитель отходов' },
  {
    ID: 15246,
    label: 'Обогатитель шлифзерна и шлифпорошков',
  },
  { ID: 15248, label: 'Обогатитель шлихов' },
  { ID: 15250, label: 'Обойщик' },
  { ID: 15252, label: 'Обойщик мебели' },
  { ID: 15254, label: 'Оборщик горных выработок' },
  { ID: 15257, label: 'Обработчик вентилей' },
  { ID: 15259, label: 'Обработчик ветсанбрака' },
  { ID: 15261, label: 'Обработчик винного сырья' },
  {
    ID: 15263,
    label: 'Обработчик виноматериалов и вина',
  },
  { ID: 15265, label: 'Обработчик воды' },
  { ID: 15267, label: 'Обработчик волокна и ткани' },
  {
    ID: 15269,
    label: 'Обработчик волоса, шерсти и щетины',
  },
  { ID: 15271, label: 'Обработчик вторичных шламов' },
  {
    ID: 15273,
    label: 'Обработчик деталей, полуфабрикатов и изделий',
  },
  { ID: 15275, label: 'Обработчик дрота' },
  { ID: 15277, label: 'Обработчик заготовок для туб' },
  {
    ID: 15279,
    label: 'Обработчик заготовок из стекловолокна',
  },
  { ID: 15281, label: 'Обработчик зуба берда' },
  {
    ID: 15285,
    label: 'Обработчик изделий из кости и рога',
  },
  { ID: 15287, label: 'Обработчик изделий из пластмасс' },
  { ID: 15289, label: 'Обработчик изделий из янтаря' },
  { ID: 15291, label: 'Обработчик икры' },
  {
    ID: 15295,
    label: 'Обработчик кожевенно-мехового сырья',
  },
  { ID: 15297, label: 'Обработчик колбасных изделий' },
  {
    ID: 15299,
    label: 'Обработчик коньячных спиртов и коньяков',
  },
  { ID: 15301, label: 'Обработчик крабов' },
  { ID: 15303, label: 'Обработчик кроликов' },
  { ID: 15305, label: 'Обработчик материалов латексом' },
  { ID: 15307, label: 'Обработчик матричных листов' },
  { ID: 15309, label: 'Обработчик меховых шкурок' },
  { ID: 15311, label: 'Обработчик морепродуктов' },
  { ID: 15313, label: 'Обработчик морского зверя' },
  { ID: 15315, label: 'Обработчик мясных туш' },
  { ID: 15317, label: 'Обработчик натриевых болванок' },
  { ID: 15319, label: 'Обработчик основы металлосеток' },
  { ID: 15321, label: 'Обработчик отходов виноделия' },
  { ID: 15323, label: 'Обработчик перламутра' },
  {
    ID: 15325,
    label: 'Обработчик пищевых продуктов и тары',
  },
  {
    ID: 15327,
    label: 'Обработчик поверхностных пороков металла',
  },
  { ID: 15329, label: 'Обработчик подошв' },
  { ID: 15331, label: 'Обработчик пробковых изделий' },
  { ID: 15333, label: 'Обработчик прутков сормайта' },
  { ID: 15335, label: 'Обработчик птицы' },
  { ID: 15337, label: 'Обработчик резиновых изделий' },
  { ID: 15339, label: 'Обработчик рогов' },
  { ID: 15341, label: 'Обработчик рыбы' },
  { ID: 15343, label: 'Обработчик сепараторов' },
  {
    ID: 15345,
    label: 'Обработчик синтетического каучука',
  },
  { ID: 15347, label: 'Обработчик соапстока' },
  {
    ID: 15349,
    label: 'Обработчик справочного и информационного материала',
  },
  { ID: 15351, label: 'Обработчик стеклоблоков' },
  { ID: 15353, label: 'Обработчик сусла и соков' },
  {
    ID: 15355,
    label: 'Обработчик твердосплавных изделий',
  },
  {
    ID: 15359,
    label: 'Обработчик технологических емкостей и тары',
  },
  {
    ID: 15363,
    label: 'Обработчик триплекса и стеклопакетов',
  },
  {
    ID: 15365,
    label: 'Обработчик художественных изделий из дерева и папье-маше',
  },
  { ID: 15366, label: 'Обработчик шкур' },
  { ID: 15367, label: 'Обработчик шкур' },
  {
    ID: 15369,
    label: 'Обработчик шкур волососгонной смесью',
  },
  {
    ID: 15371,
    label: 'Обработчик эмалированных изделий',
  },
  {
    ID: 15373,
    label: 'Обрезчик анатомического материала',
  },
  { ID: 15375, label: 'Обрезчик материалов' },
  { ID: 15377, label: 'Обрезчик резиновых изделий' },
  { ID: 15379, label: 'Обрубщик' },
  { ID: 15381, label: 'Обрубщик ватников' },
  { ID: 15383, label: 'Обрубщик облоя' },
  { ID: 15385, label: 'Обрубщик сучьев' },
  { ID: 15387, label: 'Обрывщик волокна' },
  { ID: 15389, label: 'Обрядчик сырья' },
  { ID: 15391, label: 'Обсыпщик кондитерских изделий' },
  {
    ID: 15393,
    label: 'Обувщик по индивидуальному пошиву обуви',
  },
  {
    ID: 15396,
    label: 'Обувщик по пошиву ортопедической обуви',
  },
  { ID: 15398, label: 'Обувщик по ремонту обуви' },
  {
    ID: 15400,
    label: 'Обходчик водопроводно-канализационной сети',
  },
  { ID: 15402, label: 'Обходчик гидросооружений' },
  { ID: 15404, label: 'Обходчик линейный' },
  {
    ID: 15406,
    label: 'Обходчик пути и искусственных сооружений',
  },
  {
    ID: 15408,
    label: 'Обходчик трассы гидрозолоудаления и золоотвалов',
  },
  { ID: 15409, label: 'Обшивщик медицинских изделий' },
  { ID: 15410, label: 'Обшивщик цилиндров' },
  { ID: 15412, label: 'Объездчик' },
  { ID: 15415, label: 'Овощевод' },
  { ID: 15416, label: 'Огнеупорщик' },
  { ID: 15418, label: 'Огранщик алмазов в бриллианты' },
  {
    ID: 15420,
    label: 'Огранщик вставок для ювелирных и художественных изделий',
  },
  { ID: 15424, label: 'Озонаторщик' },
  { ID: 15426, label: 'Окантовщик киноэкранов' },
  { ID: 15428, label: 'Окантовщик сепараторных пластин' },
  { ID: 15430, label: 'Окантовщик фотоотпечатков' },
  { ID: 15432, label: 'Оклейщик батарей' },
  { ID: 15434, label: 'Оклейщик глобусов' },
  { ID: 15436, label: 'Оклейщик изделий из бересты' },
  { ID: 15438, label: 'Оклейщик органического стекла' },
  { ID: 15440, label: 'Оконтуровщик оригиналов' },
  { ID: 15442, label: 'Окорщик' },
  { ID: 15444, label: 'Окрасчик игрушек' },
  {
    ID: 15446,
    label: 'Окрасчик изделий из стеклопластиков',
  },
  { ID: 15448, label: 'Окрасчик картона и фибры' },
  { ID: 15450, label: 'Окрасчик микросрезов' },
  { ID: 15452, label: 'Окрасчик приборов и деталей' },
  { ID: 15454, label: 'Окрасчик резиновых изделий' },
  { ID: 15456, label: 'Окрасчик сиропа' },
  { ID: 15458, label: 'Оксидировщик-вакуумщик' },
  { ID: 15460, label: 'Оленевод' },
  { ID: 15462, label: 'Омеднильщик' },
  {
    ID: 15464,
    label: 'Оператор автомата по производству вареных',
  },
  {
    ID: 15465,
    label: 'Оператор автомата по производству полуфабрикатов',
  },
  {
    ID: 15466,
    label: 'Оператор автомата по розливу молочной продукции в пакеты и пленку',
  },
  {
    ID: 15468,
    label: 'Оператор автоматизированного лесотранспортера',
  },
  {
    ID: 15469,
    label: 'Оператор автоматизированного процесса производства алюминия',
  },
  {
    ID: 15472,
    label: 'Оператор автоматизированной линии теплоизоляции труб',
  },
  {
    ID: 15474,
    label:
      'Оператор автоматических и полуавтоматических линий станков и установок',
  },
  {
    ID: 15477,
    label:
      'Оператор автоматических и полуавтоматических линий холодноштамповочного оборудования',
  },
  {
    ID: 15478,
    label: 'Оператор автоматической газовой защиты',
  },
  {
    ID: 15479,
    label: 'Оператор автоматической линии изготовления белого карандаша',
  },
  {
    ID: 15481,
    label: 'Оператор автоматической линии изготовления спичек',
  },
  {
    ID: 15483,
    label:
      'Оператор автоматической линии подготовки и пайки электрорадиоэлементов на печатных платах',
  },
  {
    ID: 15485,
    label: 'Оператор автоматической линии по изготовлению изолированных жил',
  },
  {
    ID: 15487,
    label: 'Оператор автоматической линии по производству пеностекла',
  },
  {
    ID: 15491,
    label: 'Оператор автоматической линии производства молочных продуктов',
  },
  {
    ID: 15493,
    label: 'Оператор автоматической линии производства сосисок',
  },
  {
    ID: 15497,
    label: 'Оператор автоматов сборки карандашей',
  },
  {
    ID: 15499,
    label: 'Оператор агрегата обработки отходов',
  },
  {
    ID: 15501,
    label: 'Оператор агрегатных линий сортировки и переработки бревен',
  },
  { ID: 15503, label: 'Оператор акустических испытаний' },
  {
    ID: 15505,
    label: 'Оператор аппаратов микрофильмирования и копирования',
  },
  {
    ID: 15509,
    label: 'Оператор бутылоразгрузочного и бутылоукладочного автомата',
  },
  {
    ID: 15511,
    label: 'Оператор вакуумно-напылительных процессов',
  },
  {
    ID: 15513,
    label:
      'Оператор вакуумных установок по нанесению покрытий на оптические детали',
  },
  {
    ID: 15515,
    label: 'Оператор вакуум-присосных механизмов и приспособлений',
  },
  {
    ID: 15517,
    label:
      'Оператор вальцово-каландровой линии производства поливинилхлоридной пленки',
  },
  { ID: 15519, label: 'Оператор вальцовочной линии' },
  { ID: 15521, label: 'Оператор видеозаписи' },
  {
    ID: 15523,
    label: 'Оператор водозапорных сооружений',
  },
  { ID: 15525, label: 'Оператор водомаслостанции' },
  {
    ID: 15527,
    label: 'Оператор волнировочно-стопирующего агрегата',
  },
  {
    ID: 15529,
    label: 'Оператор ворсовального оборудования',
  },
  {
    ID: 15531,
    label: 'Оператор в производстве заквасок',
  },
  {
    ID: 15533,
    label: 'Оператор в производстве кисломолочных и детских молочных продуктов',
  },
  {
    ID: 15535,
    label: 'Оператор в производстве раствора казеинатов и казецитов',
  },
  { ID: 15536, label: 'Оператор выводных устройств' },
  { ID: 15537, label: 'Оператор выдувного полуавтомата' },
  { ID: 15539, label: 'Оператор выпарной установки' },
  { ID: 15541, label: 'Оператор выращивания дрожжей' },
  {
    ID: 15543,
    label: 'Оператор выращивания чистой культуры дрожжей',
  },
  {
    ID: 15545,
    label: 'Оператор высокочастотной установки',
  },
  {
    ID: 15549,
    label: 'Оператор вязально-прошивного оборудования',
  },
  { ID: 15551, label: 'Оператор газгольдерной станции' },
  {
    ID: 15553,
    label: 'Оператор газораспределительной станции',
  },
  {
    ID: 15555,
    label: 'Оператор-гальваник на автоматических и полуавтоматических линиях',
  },
  {
    ID: 15560,
    label: 'Оператор гладильно-сушильного агрегата',
  },
  { ID: 15562, label: 'Оператор "горячей" камеры' },
  {
    ID: 15564,
    label: 'Оператор гранулирования минеральной ваты',
  },
  {
    ID: 15566,
    label: 'Оператор гребнечесального оборудования',
  },
  {
    ID: 15570,
    label: 'Оператор дезинсекционных установок',
  },
  { ID: 15572, label: 'Оператор дефектоскопной тележки' },
  { ID: 15574, label: 'Оператор джинного оборудования' },
  {
    ID: 15576,
    label:
      'Оператор дисперсионных смесителей по приготовлению стержневой массы',
  },
  {
    ID: 15578,
    label:
      'Оператор дистанционного пульта управления в водопроводно-канализационном хозяйстве',
  },
  {
    ID: 15580,
    label:
      'Оператор дистанционного пульта управления в химическом производстве',
  },
  { ID: 15582, label: 'Оператор диффузионных процессов' },
  {
    ID: 15584,
    label: 'Оператор жгутоперемоточной машины',
  },
  {
    ID: 15586,
    label: 'Оператор животноводческих комплексов и механизированных ферм',
  },
  {
    ID: 15588,
    label: 'Оператор заготовительного отделения',
  },
  { ID: 15590, label: 'Оператор загрузки конвертера' },
  {
    ID: 15592,
    label: 'Оператор загрузочной и разгрузочной установки',
  },
  { ID: 15594, label: 'Оператор заправочных станций' },
  {
    ID: 15596,
    label: 'Оператор зародышеотделительной машины',
  },
  {
    ID: 15600,
    label: 'Оператор иглопробивного агрегата',
  },
  {
    ID: 15602,
    label: 'Оператор иглопробивного оборудования',
  },
  {
    ID: 15604,
    label: 'Оператор по изготовлению асбостального полотна',
  },
  { ID: 15606, label: 'Оператор изготовления ровинга' },
  {
    ID: 15608,
    label: 'Оператор изготовления рулонно-конструкционных материалов',
  },
  {
    ID: 15610,
    label: 'Оператор инерционных сепараторов',
  },
  { ID: 15612, label: 'Оператор ионитовой установки' },
  { ID: 15614, label: 'Оператор ионообмена' },
  {
    ID: 15616,
    label: 'Оператор камеры окрашивания акустических плит',
  },
  { ID: 15618, label: 'Оператор канатной машины' },
  {
    ID: 15620,
    label: 'Оператор канатовьющих и веревочных машин',
  },
  { ID: 15622, label: 'Оператор клеевого оборудования' },
  { ID: 15624, label: 'Оператор клепальных автоматов' },
  { ID: 15626, label: 'Оператор коксосортировки' },
  {
    ID: 15628,
    label: 'Оператор конвейера твердения асбестоцементных труб',
  },
  { ID: 15630, label: 'Оператор конвейерной линии' },
  {
    ID: 15632,
    label: 'Оператор конвейерной линии оборудования',
  },
  { ID: 15634, label: 'Оператор конвейерных печей' },
  {
    ID: 15636,
    label: 'Оператор копировальных и множительных машин',
  },
  { ID: 15639, label: 'Оператор коптильной установки' },
  {
    ID: 15641,
    label: 'Оператор коробконабивочного станка',
  },
  { ID: 15643, label: 'Оператор котельной' },
  { ID: 15645, label: 'Оператор красочной станции' },
  { ID: 15647, label: 'Оператор круглочесальной машины' },
  {
    ID: 15649,
    label: 'Оператор крутильного оборудования',
  },
  { ID: 15651, label: 'Оператор кручения и вытяжки' },
  {
    ID: 15653,
    label: 'Оператор кручения и намотки химических волокон',
  },
  {
    ID: 15655,
    label: 'Оператор-кузнец на автоматических и полуавтоматических линиях',
  },
  {
    ID: 15657,
    label: 'Оператор лазерной голографической установки',
  },
  {
    ID: 15658,
    label:
      'Оператор лазерных гравировальных автоматов по изготовлению форм офсетной печати',
  },
  {
    ID: 15659,
    label: 'Оператор ленточного оборудования',
  },
  {
    ID: 15661,
    label: 'Оператор линии в производстве пищевой продукции',
  },
  {
    ID: 15662,
    label: 'Оператор линии замачивания семян',
  },
  { ID: 15663, label: 'Оператор линии окраски кирпича' },
  { ID: 15665, label: 'Оператор линии отделки рельсов' },
  {
    ID: 15667,
    label: 'Оператор линии по обработке перопухового сырья',
  },
  {
    ID: 15669,
    label: 'Оператор линии по обработке цветных металлов',
  },
  {
    ID: 15671,
    label: 'Оператор линии по производству жевательной резинки',
  },
  {
    ID: 15673,
    label: 'Оператор линии по производству муки и гранул',
  },
  {
    ID: 15675,
    label: 'Оператор линии приготовления фарша',
  },
  {
    ID: 15677,
    label: 'Оператор линии приготовления шоколадной массы',
  },
  {
    ID: 15679,
    label: 'Оператор линии производства маргарина',
  },
  {
    ID: 15680,
    label: 'Оператор линии производства мороженого',
  },
  {
    ID: 15681,
    label: 'Оператор линии производства мыла',
  },
  {
    ID: 15682,
    label: 'Оператор линии протравливания семян',
  },
  {
    ID: 15683,
    label: 'Оператор линии розлива молока и молочной продукции в бутылки',
  },
  {
    ID: 15684,
    label: 'Оператор линии фильтрации в пивоваренном производстве',
  },
  {
    ID: 15685,
    label: 'Оператор линтерного оборудования',
  },
  {
    ID: 15687,
    label: 'Оператор-литейщик на автоматах и автоматических линиях',
  },
  { ID: 15689, label: 'Оператор логоскопа' },
  { ID: 15691, label: 'Оператор льночесальной машины' },
  {
    ID: 15693,
    label: 'Оператор магистральных газопроводов',
  },
  { ID: 15695, label: 'Оператор магнитной записи' },
  { ID: 15697, label: 'Оператор манипулятора' },
  { ID: 15699, label: 'Оператор машинного доения' },
  {
    ID: 15701,
    label: 'Оператор машины непрерывного литья заготовок',
  },
  {
    ID: 15703,
    label: 'Оператор механизированной подачи смеси',
  },
  {
    ID: 15705,
    label: 'Оператор механизированных и автоматизированных складов',
  },
  { ID: 15707, label: 'Оператор микросварки' },
  { ID: 15709, label: 'Оператор моечной установки' },
  {
    ID: 15711,
    label: 'Оператор моечно-очистительного агрегата',
  },
  {
    ID: 15713,
    label: 'Оператор мойно-отжимного агрегата',
  },
  { ID: 15715, label: 'Оператор молокохранилища' },
  {
    ID: 15719,
    label: 'Оператор мотального оборудования',
  },
  {
    ID: 15722,
    label: 'Оператор-моторист станции контроля цементажа',
  },
  {
    ID: 15724,
    label: 'Оператор мяльно-чесальной машины',
  },
  {
    ID: 15726,
    label:
      'Оператор на автоматических и полуавтоматических линиях в деревообработке',
  },
  { ID: 15728, label: 'Оператор на аэротенках' },
  { ID: 15730, label: 'Оператор на биофильтрах' },
  {
    ID: 15732,
    label: 'Оператор на бормашине по предварительной обработке литых магнитов',
  },
  { ID: 15734, label: 'Оператор на вварочных машинах' },
  { ID: 15736, label: 'Оператор на иловых площадках' },
  { ID: 15738, label: 'Оператор-намазчик' },
  { ID: 15740, label: 'Оператор на метантенках' },
  { ID: 15742, label: 'Оператор на отстойниках' },
  {
    ID: 15744,
    label: 'Оператор на песколовках и жироловках',
  },
  { ID: 15746, label: 'Оператор на подогреве мазута' },
  {
    ID: 15748,
    label: 'Оператор наполнения шприц-тюбиков',
  },
  { ID: 15750, label: 'Оператор-нарезчик' },
  { ID: 15752, label: 'Оператор на решетке' },
  { ID: 15754, label: 'Оператор на филаментмашине' },
  { ID: 15756, label: 'Оператор на фильтрах' },
  { ID: 15758, label: 'Оператор на эмшерах' },
  {
    ID: 15759,
    label: 'Оператор нефтепродуктоперекачивающей станции',
  },
  { ID: 15760, label: 'Оператор обдирочных станков' },
  { ID: 15762, label: 'Оператор обдувочной установки' },
  {
    ID: 15764,
    label: 'Оператор обезвоживающей и обессоливающей установки',
  },
  { ID: 15766, label: 'Оператор обжарочного аппарата' },
  { ID: 15768, label: 'Оператор обрубного отделения' },
  {
    ID: 15770,
    label: 'Оператор овцеводческих комплексов и механизированных ферм',
  },
  {
    ID: 15772,
    label: 'Оператор окрасочно-сушильной линии и агрегата',
  },
  {
    ID: 15774,
    label: 'Оператор окруточного оборудования',
  },
  {
    ID: 15776,
    label: 'Оператор опаливающего оборудования',
  },
  {
    ID: 15778,
    label: 'Оператор осциллографирования и тензометрирования',
  },
  {
    ID: 15780,
    label: 'Оператор отстаивания и теплообмена',
  },
  { ID: 15782, label: 'Оператор очистного оборудования' },
  { ID: 15784, label: 'Оператор очистных сооружений' },
  {
    ID: 15786,
    label: 'Оператор пакетоформирующих машин',
  },
  {
    ID: 15788,
    label: 'Оператор пароэжекторной установки вакуумирования металла',
  },
  {
    ID: 15792,
    label: 'Оператор переборщика-увлажнителя асбестоцементных изделий',
  },
  { ID: 15794, label: 'Оператор перевивочной машины' },
  {
    ID: 15796,
    label: 'Оператор передвижной сейсмической установки',
  },
  { ID: 15798, label: 'Оператор печатного оборудования' },
  {
    ID: 15800,
    label: 'Оператор плазмохимических процессов',
  },
  {
    ID: 15802,
    label: 'Оператор плетельного оборудования',
  },
  { ID: 15804, label: 'Оператор плетельной машины' },
  { ID: 15806, label: 'Оператор пневмогидроподачи' },
  {
    ID: 15808,
    label: 'Оператор по ветеринарной обработке животных',
  },
  {
    ID: 15810,
    label: 'Оператор по выращиванию кристаллов',
  },
  {
    ID: 15812,
    label: 'Оператор по выращиванию кристаллов пьезокварца',
  },
  { ID: 15814, label: 'Оператор по вытяжке световодов' },
  {
    ID: 15816,
    label: 'Оператор по геофизическому опробованию полезного ископаемого',
  },
  {
    ID: 15818,
    label: 'Оператор по гидравлическому разрыву пластов',
  },
  {
    ID: 15820,
    label: 'Оператор подачи технического углерода',
  },
  {
    ID: 15822,
    label: 'Оператор подземных газогенераторов',
  },
  { ID: 15824, label: 'Оператор по добыче нефти и газа' },
  {
    ID: 15826,
    label:
      'Оператор по защитным покрытиям в производстве масок цветных кинескопов',
  },
  {
    ID: 15828,
    label: 'Оператор по изготовлению резиновых смесей',
  },
  {
    ID: 15830,
    label: 'Оператор по искусственному осеменению животных и птицы',
  },
  {
    ID: 15832,
    label: 'Оператор по исследованию скважин',
  },
  {
    ID: 15836,
    label: 'Оператор полей орошения и фильтрации',
  },
  {
    ID: 15838,
    label: 'Оператор полуавтоматической линии изготовления спичечных коробок',
  },
  {
    ID: 15840,
    label: 'Оператор получения кварцевых стекловолокон',
  },
  {
    ID: 15842,
    label: 'Оператор получения непрерывного стекловолокна',
  },
  {
    ID: 15844,
    label: 'Оператор получения оптического стекловолокна',
  },
  {
    ID: 15846,
    label: 'Оператор получения поливинилхлоридных композиций',
  },
  {
    ID: 15848,
    label: 'Оператор получения стекловолокна каолинового состава',
  },
  {
    ID: 15850,
    label: 'Оператор получения стеклохолста одностадийным методом',
  },
  {
    ID: 15852,
    label: 'Оператор получения штапельного стекловолокна',
  },
  {
    ID: 15853,
    label: 'Оператор по намагничиванию и контролю высококоэрцитивных магнитов',
  },
  {
    ID: 15854,
    label: 'Оператор по нанесению газопоглотителя',
  },
  {
    ID: 15856,
    label: 'Оператор по нанесению просветляющих и защитных покрытий',
  },
  {
    ID: 15858,
    label: 'Оператор по наращиванию эпитаксиальных слоев',
  },
  {
    ID: 15859,
    label: 'Оператор по обслуживанию и ремонту вагонов и контейнеров',
  },
  {
    ID: 15860,
    label: 'Оператор по обслуживанию пылегазоулавливающих установок',
  },
  {
    ID: 15862,
    label: 'Оператор по опробованию (испытанию) скважин',
  },
  {
    ID: 15864,
    label: 'Оператор по перезарядке транспортных систем',
  },
  {
    ID: 15866,
    label:
      'Оператор по подготовке скважин к капитальному и подземному ремонтам',
  },
  {
    ID: 15868,
    label: 'Оператор по поддержанию пластового давления',
  },
  {
    ID: 15870,
    label: 'Оператор по подземному ремонту скважин',
  },
  {
    ID: 15872,
    label:
      'Оператор по производству отделочных материалов на полвинилхлоридной основе',
  },
  { ID: 15874, label: 'Оператор по путевым измерениям' },
  { ID: 15876, label: 'Оператор по сбору газа' },
  {
    ID: 15878,
    label: 'Оператор по сбору и очистке конденсата',
  },
  { ID: 15880, label: 'Оператор поста управления' },
  {
    ID: 15882,
    label:
      'Оператор поста управления агрегатами непрерывного травления, обезжиривания, лужения, оцинкования, лакирования и отжига',
  },
  {
    ID: 15884,
    label: 'Оператор поста управления агрегатами объемной закалки рельсов',
  },
  {
    ID: 15886,
    label: 'Оператор поста управления системы шихтоподачи',
  },
  {
    ID: 15888,
    label: 'Оператор поста управления стана горячего проката труб',
  },
  {
    ID: 15890,
    label: 'Оператор поста управления стана горячей прокатки',
  },
  {
    ID: 15892,
    label: 'Оператор поста управления стана холодной прокатки',
  },
  { ID: 15894, label: 'Оператор поста централизации' },
  {
    ID: 15896,
    label: 'Оператор по термообработке корда',
  },
  {
    ID: 15897,
    label: 'Оператор поточно-автоматизированных линий производства сигарет',
  },
  {
    ID: 15898,
    label: 'Оператор поточно-автоматической линии',
  },
  {
    ID: 15900,
    label: 'Оператор поточной линии по выработке волокна',
  },
  {
    ID: 15902,
    label: 'Оператор поточной линии подготовки основы искусственной кожи',
  },
  {
    ID: 15904,
    label: 'Оператор поточной линии полиэтиленирования',
  },
  {
    ID: 15906,
    label: 'Оператор поточных линий нанесения световозвращающих составов',
  },
  {
    ID: 15908,
    label: 'Оператор по химической обработке скважин',
  },
  { ID: 15910, label: 'Оператор по цементажу скважин' },
  {
    ID: 15912,
    label: 'Оператор прачечной самообслуживания',
  },
  { ID: 15914, label: 'Оператор прецизионной резки' },
  {
    ID: 15916,
    label: 'Оператор прецизионной фотолитографии',
  },
  { ID: 15918, label: 'Оператор приготовления затора' },
  {
    ID: 15920,
    label: 'Оператор приготовления растворов питательной среды и солей',
  },
  {
    ID: 15922,
    label: 'Оператор пробоотборной установки',
  },
  {
    ID: 15924,
    label: 'Оператор проволочного прокатного стана',
  },
  {
    ID: 15926,
    label: 'Оператор проекционной аппаратуры и газорезательных машин',
  },
  {
    ID: 15928,
    label: 'Оператор производства древесной массы из щепы',
  },
  {
    ID: 15930,
    label: 'Оператор производства кремнеземных материалов',
  },
  {
    ID: 15932,
    label: 'Оператор производства формованного полиуретана и пенополиуретана',
  },
  {
    ID: 15936,
    label: 'Оператор промывочного оборудования',
  },
  {
    ID: 15938,
    label: 'Оператор пропиточного оборудования',
  },
  {
    ID: 15940,
    label: 'Оператор профилегибочного агрегата',
  },
  { ID: 15942, label: 'Оператор проходных сушил' },
  { ID: 15944, label: 'Оператор прядевьющей машины' },
  {
    ID: 15946,
    label: 'Оператор птицефабрик и механизированных ферм',
  },
  { ID: 15948, label: 'Оператор пульта управления' },
  {
    ID: 15950,
    label: 'Оператор пульта управления в добыче нефти и газа',
  },
  {
    ID: 15952,
    label: 'Оператор пульта управления в производстве стеновых изделий',
  },
  {
    ID: 15954,
    label: 'Оператор пульта управления в сахарном производстве',
  },
  {
    ID: 15956,
    label:
      'Оператор пульта управления оборудованием железобетонного производства',
  },
  {
    ID: 15957,
    label:
      'Оператор пульта управления оборудованием жилых и общественных зданий',
  },
  {
    ID: 15960,
    label:
      'Оператор пульта управления трансбордерным и горизонтально-замкнутым конвейерами',
  },
  {
    ID: 15961,
    label: 'Оператор пульта управления элеватором',
  },
  {
    ID: 15962,
    label: 'Оператор пульта управления электропечей',
  },
  {
    ID: 15966,
    label: 'Оператор пушильного оборудования',
  },
  { ID: 15972, label: 'Оператор разменных автоматов' },
  { ID: 15974, label: 'Оператор размоточной машины' },
  {
    ID: 15976,
    label: 'Оператор размоточно-склеивающего станка',
  },
  {
    ID: 15978,
    label: 'Оператор разрыхлительно-трепального агрегата',
  },
  {
    ID: 15980,
    label: 'Оператор разрыхлительно-трепальной машины',
  },
  {
    ID: 15984,
    label: 'Оператор раскладочной машины (льняное производство)',
  },
  {
    ID: 15986,
    label: 'Оператор раскладочной машины (шелковое производство)',
  },
  {
    ID: 15988,
    label: 'Оператор раскройного оборудования',
  },
  {
    ID: 15990,
    label: 'Оператор раскряжевочной установки',
  },
  { ID: 15992, label: 'Оператор распределения пряжи' },
  {
    ID: 15994,
    label: 'Оператор распределения химических материалов',
  },
  {
    ID: 15997,
    label: 'Оператор рассолодобычных скважин',
  },
  {
    ID: 15998,
    label: 'Оператор расфасовочно-упаковочного автомата',
  },
  {
    ID: 16000,
    label: 'Оператор расчесывающего оборудования',
  },
  { ID: 16002, label: 'Оператор реакторного отделения' },
  {
    ID: 16004,
    label: 'Оператор релаксационно-мотального агрегата',
  },
  {
    ID: 16006,
    label: 'Оператор ровничного оборудования',
  },
  {
    ID: 16008,
    label:
      'Оператор роторной линии по производству изделий из пластических масс',
  },
  {
    ID: 16010,
    label: 'Оператор рыбокоптильной механизированной линии',
  },
  {
    ID: 16012,
    label: 'Оператор рыхлительно-щипательных машин',
  },
  {
    ID: 16015,
    label: 'Оператор сверлильного агрегата и пресса',
  },
  {
    ID: 16017,
    label: 'Оператор свиноводческих комплексов и механизированных ферм',
  },
  { ID: 16019, label: 'Оператор связи' },
  { ID: 16020, label: 'Оператор сейсмопрогноза' },
  {
    ID: 16021,
    label: 'Оператор серодобычных и водоотливных скважин',
  },
  {
    ID: 16023,
    label:
      'Оператор систем гидравлики и охлаждения машины непрерывного литья заготовок',
  },
  {
    ID: 16025,
    label: 'Оператор скороморозильных аппаратов',
  },
  {
    ID: 16027,
    label: 'Оператор сновального оборудования',
  },
  {
    ID: 16029,
    label: 'Оператор сновальной пропиточно-вытяжной машины',
  },
  {
    ID: 16031,
    label: 'Оператор сооружений по удалению осадка',
  },
  { ID: 16033, label: 'Оператор сортировочной горки' },
  { ID: 16035, label: 'Оператор спецводоочистки' },
  {
    ID: 16037,
    label: 'Оператор специальных устройств земснарядов',
  },
  { ID: 16039, label: 'Оператор спичечных автоматов' },
  {
    ID: 16041,
    label: 'Оператор стана по прокатке гипсобетонных панелей',
  },
  {
    ID: 16043,
    label: 'Оператор станка для укладки рассыпных спичек',
  },
  {
    ID: 16045,
    label: 'Оператор станков с программным управлением',
  },
  { ID: 16049, label: 'Оператор стеклоформующих машин' },
  {
    ID: 16051,
    label: 'Оператор стенда по обыгрыванию клавишных инструментов',
  },
  { ID: 16053, label: 'Оператор стиральных машин' },
  {
    ID: 16055,
    label: 'Оператор стригального оборудования',
  },
  { ID: 16059, label: 'Оператор сучкорезной установки' },
  {
    ID: 16061,
    label: 'Оператор сушильного оборудования',
  },
  { ID: 16063, label: 'Оператор сушильных установок' },
  {
    ID: 16065,
    label: 'Оператор сушки синтетического каучука',
  },
  { ID: 16067, label: 'Оператор теплового пункта' },
  { ID: 16068, label: 'Оператор тепловых сетей' },
  {
    ID: 16069,
    label: 'Оператор-термист на автоматических линиях',
  },
  {
    ID: 16071,
    label: 'Оператор-термист на передвижных термических установках',
  },
  {
    ID: 16075,
    label: 'Оператор термоскрепляющего оборудования',
  },
  { ID: 16077, label: 'Оператор термосоединений' },
  { ID: 16079, label: 'Оператор тестера' },
  {
    ID: 16081,
    label: 'Оператор технологических установок',
  },
  {
    ID: 16083,
    label:
      'Оператор технологического оборудования в сооружениях защищенного грунта',
  },
  { ID: 16085, label: 'Оператор товарный' },
  {
    ID: 16087,
    label:
      'Оператор транспортно-технологического оборудования реакторного отделения',
  },
  {
    ID: 16089,
    label: 'Оператор тростильного оборудования',
  },
  { ID: 16091, label: 'Оператор трубообжимных станков' },
  { ID: 16093, label: 'Оператор трубчатой печи' },
  { ID: 16095, label: 'Оператор трясильной машины' },
  { ID: 16097, label: 'Оператор турбосмесителя' },
  { ID: 16099, label: 'Оператор тянульной машины' },
  {
    ID: 16101,
    label: 'Оператор узла посыпки и охлаждения',
  },
  { ID: 16103, label: 'Оператор узловязальной машины' },
  {
    ID: 16105,
    label: 'Оператор укладчика-разборщика асбестоцементных изделий',
  },
  {
    ID: 16107,
    label: 'Оператор ультразвуковых установок',
  },
  {
    ID: 16109,
    label: 'Оператор ускорительной установки',
  },
  {
    ID: 16111,
    label: 'Оператор установки бестарного хранения сырья',
  },
  {
    ID: 16113,
    label: 'Оператор установки витаминизации дрожжей',
  },
  {
    ID: 16115,
    label: 'Оператор установки волокнообразования',
  },
  {
    ID: 16117,
    label: 'Оператор установки вымерения объема',
  },
  {
    ID: 16119,
    label:
      'Оператор установки изготовления гофрированных листовых стеклопластиков',
  },
  {
    ID: 16121,
    label: 'Оператор установки минераловатных цилиндров',
  },
  {
    ID: 16123,
    label: 'Оператор установки по сушке осадка',
  },
  {
    ID: 16125,
    label: 'Оператор установок по тепловой обработке бетона',
  },
  { ID: 16127, label: 'Оператор установки ТВЧ' },
  {
    ID: 16129,
    label: 'Оператор установки теплоизоляционного шнура',
  },
  {
    ID: 16131,
    label: 'Оператор установок изготовления сотовых пакетов',
  },
  {
    ID: 16133,
    label: 'Оператор установок изготовления стеклопластиковых конструкций',
  },
  {
    ID: 16135,
    label: 'Оператор установок и линий обработки пиломатериалов',
  },
  {
    ID: 16137,
    label: 'Оператор установок пескоструйной очистки',
  },
  {
    ID: 16139,
    label: 'Оператор установок по нанесению покрытий в вакууме',
  },
  {
    ID: 16141,
    label: 'Оператор установок по обезвоживанию осадка',
  },
  {
    ID: 16143,
    label: 'Оператор установок синтеза алмазов и сверхтвердых материалов',
  },
  { ID: 16145, label: 'Оператор фацетного станка' },
  {
    ID: 16147,
    label: 'Оператор формования ленты стекла',
  },
  { ID: 16149, label: 'Оператор формирующей машины' },
  { ID: 16151, label: 'Оператор фотоавтоматов' },
  { ID: 16153, label: 'Оператор фотонаборных автоматов' },
  { ID: 16155, label: 'Оператор хлораторной установки' },
  { ID: 16157, label: 'Оператор холстовытяжной машины' },
  { ID: 16159, label: 'Оператор централизованной мойки' },
  {
    ID: 16161,
    label:
      'Оператор центрального пульта управления в производстве древесных и костровых плит',
  },
  {
    ID: 16163,
    label: 'Оператор цехов по приготовлению кормов',
  },
  {
    ID: 16165,
    label: 'Оператор чаеперерабатывающей линии',
  },
  {
    ID: 16167,
    label: 'Оператор чесально-вязального оборудования',
  },
  {
    ID: 16170,
    label: 'Оператор чесального оборудования',
  },
  {
    ID: 16171,
    label: 'Оператор чесально-дублировочного агрегата',
  },
  {
    ID: 16179,
    label: 'Оператор чесально-ленточного агрегата',
  },
  { ID: 16183, label: 'Оператор чистильной машины' },
  { ID: 16185, label: 'Оператор швейного оборудования' },
  {
    ID: 16187,
    label: 'Оператор ширильного оборудования',
  },
  {
    ID: 16189,
    label: 'Оператор шлихтовального оборудования',
  },
  {
    ID: 16191,
    label: 'Оператор щита (пульта) управления преобразовательной подстанции',
  },
  {
    ID: 16195,
    label: 'Оператор электроакустических измерений',
  },
  {
    ID: 16197,
    label: 'Оператор электрогидравлической очистки отливок',
  },
  {
    ID: 16199,
    label: 'Оператор электронно-вычислительных и вычислительных машин',
  },
  {
    ID: 16200,
    label: 'Оператор электронного набора и верстки',
  },
  {
    ID: 16201,
    label: 'Оператор электронных автоматов по изготовлению форм высокой печати',
  },
  {
    ID: 16202,
    label: 'Оператор электронного цветоделения',
  },
  {
    ID: 16203,
    label:
      'Оператор электронных гравировальных автоматов по изготовлению форм глубокой печати',
  },
  {
    ID: 16207,
    label: 'Оператор электростатической обработки',
  },
  {
    ID: 16209,
    label: 'Оператор электрохимической очистки заготовок',
  },
  { ID: 16211, label: 'Оператор элионных процессов' },
  {
    ID: 16213,
    label: 'Оператор этикетировочного станка',
  },
  { ID: 16215, label: 'Опиловщик камня' },
  { ID: 16217, label: 'Опиловщик папье-маше' },
  { ID: 16219, label: 'Опиловщик фасонных отливок' },
  { ID: 16221, label: 'Опиловщик фибры' },
  { ID: 16223, label: 'Опиловщик черни' },
  { ID: 16225, label: 'Оплавщик изделий' },
  { ID: 16227, label: 'Оплавщик стекла' },
  { ID: 16229, label: 'Оплавщик полотна и изделий' },
  { ID: 16231, label: 'Оплетчик деталей' },
  { ID: 16233, label: 'Оплетчик проводов и кабелей' },
  { ID: 16235, label: 'Оплетчик стекложгутов' },
  { ID: 16237, label: 'Оправщик огнеупорных изделий' },
  { ID: 16239, label: 'Оправщик-чистильщик' },
  {
    ID: 16241,
    label: 'Оправщик электрокерамических изделий',
  },
  {
    ID: 16243,
    label: 'Опрессовщик кабелей и проводов пластикатами и резиной',
  },
  {
    ID: 16245,
    label: 'Опрессовщик кабелей свинцом или алюминием',
  },
  { ID: 16247, label: 'Опрессовщик труб' },
  { ID: 16249, label: 'Опрокидчик' },
  { ID: 16251, label: 'Оптик' },
  { ID: 16253, label: 'Оптик медицинский' },
  { ID: 16255, label: 'Оптик-механик' },
  {
    ID: 16257,
    label: 'Оптик элементов квантовых приборов',
  },
  {
    ID: 16259,
    label: 'Опылитель форм и металла серным порошком',
  },
  { ID: 16263, label: 'Осветитель' },
  { ID: 16265, label: 'Освинцевальщик' },
  { ID: 16267, label: 'Осмольщик бочек' },
  { ID: 16269, label: 'Осмотрщик вагонов' },
  {
    ID: 16271,
    label: 'Осмотрщик гидротехнических сооружений',
  },
  {
    ID: 16273,
    label: 'Осмотрщик нефтеналивных емкостей',
  },
  { ID: 16275, label: 'Осмотрщик-ремонтник вагонов' },
  { ID: 16277, label: 'Остекловщик резисторов' },
  { ID: 16278, label: 'Отбельщик' },
  { ID: 16279, label: 'Отбельщик' },
  { ID: 16281, label: 'Отбельщик агарового студня' },
  { ID: 16283, label: 'Отбельщик коагулята' },
  { ID: 16288, label: 'Отбивщик ртути' },
  {
    ID: 16290,
    label: 'Отборщик анатомического материала',
  },
  { ID: 16292, label: 'Отборщик геологических проб' },
  { ID: 16294, label: 'Отварщик' },
  { ID: 16296, label: 'Отварщик коконных отходов' },
  { ID: 16298, label: 'Отводчик' },
  {
    ID: 16300,
    label: 'Отделочник валяльно-войлочных изделий',
  },
  {
    ID: 16302,
    label: 'Отделочник волокна (льняное производство)',
  },
  {
    ID: 16304,
    label: 'Отделочник волокна (производство текстильной галантереи)',
  },
  { ID: 16306, label: 'Отделочник ворса' },
  { ID: 16308, label: 'Отделочник головных уборов' },
  { ID: 16310, label: 'Отделочник деталей игрушек' },
  {
    ID: 16312,
    label: 'Отделочник железобетонных изделий',
  },
  { ID: 16314, label: 'Отделочник изделий из древесины' },
  {
    ID: 16319,
    label: 'Отделочник изделий, полуфабрикатов, материалов',
  },
  {
    ID: 16320,
    label: 'Отделочник искусственного каракуля',
  },
  {
    ID: 16322,
    label: 'Отделочник карандашей на автомате',
  },
  { ID: 16324, label: 'Отделочник кинофотоматериалов' },
  {
    ID: 16326,
    label: 'Отделочник литых бумажных изделий',
  },
  {
    ID: 16328,
    label: 'Отделочник материалов и готовых изделий',
  },
  { ID: 16330, label: 'Отделочник меховых шкурок' },
  { ID: 16332, label: 'Отделочник резиновых изделий' },
  { ID: 16334, label: 'Отделочник ткани' },
  { ID: 16336, label: 'Отделочник химических волокон' },
  {
    ID: 16338,
    label: 'Отделочник художественных изделий',
  },
  { ID: 16342, label: 'Отделывальщик клише' },
  { ID: 16344, label: 'Отделывальщик фотолитер' },
  {
    ID: 16346,
    label: 'Отделывальщик шрифтовой продукции',
  },
  { ID: 16348, label: 'Отдельщик выдувных изделий' },
  { ID: 16350, label: 'Отжигальщик-вакуумщик' },
  { ID: 16352, label: 'Отжигальщик изделий' },
  { ID: 16354, label: 'Отжигальщик кабельных изделий' },
  {
    ID: 16356,
    label: 'Отжигальщик прецизионной стали и сплавов',
  },
  { ID: 16358, label: 'Отжигальщик стеклоизделий' },
  { ID: 16360, label: 'Отжигальщик цветных металлов' },
  { ID: 16362, label: 'Отжимщик' },
  { ID: 16363, label: 'Отжимщик' },
  { ID: 16364, label: 'Отжимщик белья на центрифугах' },
  {
    ID: 16367,
    label: 'Отжимщик воздуха и влаги из камер',
  },
  { ID: 16369, label: 'Отжимщик массы' },
  { ID: 16371, label: 'Отжимщик холстов' },
  { ID: 16373, label: 'Откачник-вакуумщик' },
  { ID: 16375, label: 'Откладчик изделий в опечки' },
  { ID: 16377, label: 'Отливщик' },
  { ID: 16379, label: 'Отливщик валиков' },
  { ID: 16381, label: 'Отливщик литых бумажных изделий' },
  { ID: 16383, label: 'Отливщик натриевых болванок' },
  {
    ID: 16385,
    label: 'Отливщик фарфоровых и фаянсовых изделий',
  },
  { ID: 16387, label: 'Отломщик стекла от машин' },
  { ID: 16389, label: 'Отметчик ареометров' },
  { ID: 16391, label: 'Отметчик термометров' },
  { ID: 16393, label: 'Отопщик на карусельной машине' },
  { ID: 16395, label: 'Отпарщик-прессовщик' },
  { ID: 16397, label: 'Отрезчик ленты стекла' },
  { ID: 16399, label: 'Официант' },
  { ID: 16401, label: 'Оформитель готовой продукции' },
  {
    ID: 16403,
    label: 'Оформитель диапозитивных фильмов',
  },
  { ID: 16405, label: 'Оформитель игрушек' },
  { ID: 16407, label: 'Оформитель коллекций' },
  {
    ID: 16409,
    label: 'Оформитель табло, виньеток и альбомов',
  },
  { ID: 16411, label: 'Охлаждальщик ткани' },
  { ID: 16413, label: 'Охотник промысловый' },
  { ID: 16415, label: 'Оцинковщик горячим способом' },
  {
    ID: 16417,
    label: 'Оцинковщик-хромировщик диффузионным способом',
  },
  { ID: 16419, label: 'Очесывальщик барабанов' },
  { ID: 16425, label: 'Пантографист' },
  { ID: 16426, label: 'Парафинировщик' },
  { ID: 16427, label: 'Парафинировщик' },
  { ID: 16429, label: 'Парафинировщик изделий' },
  {
    ID: 16431,
    label: 'Парафинировщик керамических и фарфоровых изделий',
  },
  {
    ID: 16433,
    label: 'Парафинировщик художественных изделий',
  },
  { ID: 16435, label: 'Парашютист (десантник)-пожарный' },
  { ID: 16437, label: 'Парикмахер' },
  { ID: 16445, label: 'Паркетчик' },
  { ID: 16448, label: 'Парусник' },
  { ID: 16450, label: 'Пастижер' },
  { ID: 16452, label: 'Патинировщик' },
  { ID: 16454, label: 'Патронист рисунков' },
  { ID: 16456, label: 'Паяльщик' },
  { ID: 16458, label: 'Паяльщик пакетов конденсаторов' },
  { ID: 16460, label: 'Паяльщик по винипласту' },
  {
    ID: 16462,
    label: 'Паяльщик по свинцу (свинцовопаяльщик)',
  },
  { ID: 16464, label: 'Паяльщик радиодеталей' },
  {
    ID: 16466,
    label: 'Паяльщик сеток и шинок на стекле',
  },
  { ID: 16468, label: 'Паяльщик труб' },
  { ID: 16470, label: 'Педикюрша' },
  { ID: 16472, label: 'Пекарь' },
  { ID: 16476, label: 'Пекарь-мастер' },
  { ID: 16478, label: 'Пекоплавщик' },
  { ID: 16480, label: 'Переводчик обойных рисунков' },
  { ID: 16482, label: 'Переводчик печати и рисунка' },
  { ID: 16484, label: 'Переводчик рисунков' },
  { ID: 16486, label: 'Переводчик форм глубокой печати' },
  {
    ID: 16488,
    label: 'Перегонщик печей и трансбордерных установок',
  },
  {
    ID: 16490,
    label: 'Перезарядчик контактных аппаратов',
  },
  { ID: 16492, label: 'Перезарядчик сборочных станков' },
  {
    ID: 16494,
    label: 'Перезарядчик установки декристаллизации каучука',
  },
  {
    ID: 16495,
    label: 'Перезарядчик фильтр-прессов и диализаторов',
  },
  { ID: 16497, label: 'Перекатчик ткани и прокладки' },
  { ID: 16499, label: 'Перекристаллизаторщик' },
  { ID: 16501, label: 'Перемотчик' },
  {
    ID: 16503,
    label: 'Перемотчик бумаги и бумажной пряжи',
  },
  { ID: 16504, label: 'Перемотчик ленты' },
  { ID: 16505, label: 'Перемотчик ленты' },
  { ID: 16507, label: 'Перемотчик нити' },
  { ID: 16509, label: 'Перемотчик основ' },
  { ID: 16511, label: 'Перемотчик рулонов' },
  { ID: 16513, label: 'Перемотчик-сортировщик' },
  { ID: 16515, label: 'Перемотчик стеклоткани' },
  {
    ID: 16517,
    label: 'Перемотчик электроизоляционных материалов',
  },
  { ID: 16519, label: 'Переплетчик' },
  { ID: 16521, label: 'Переплетчик документов' },
  {
    ID: 16523,
    label: 'Переработчик радиоактивных отходов',
  },
  { ID: 16525, label: 'Пересадчик сетей' },
  { ID: 16527, label: 'Перетяжчик перчаточной кожи' },
  { ID: 16529, label: 'Перфораторщик' },
  { ID: 16530, label: 'Перфораторщик' },
  { ID: 16531, label: 'Перфораторщик' },
  { ID: 16532, label: 'Перфораторщик магнитных лент' },
  {
    ID: 16534,
    label: 'Перфораторщик пленок из пластических масс',
  },
  { ID: 16536, label: 'Перфораторщик фольги' },
  { ID: 16538, label: 'Пескослепщик' },
  { ID: 16540, label: 'Пескоструйщик' },
  { ID: 16542, label: 'Пескоструйщик по стеклу' },
  { ID: 16544, label: 'Печатник брайлевской печати' },
  { ID: 16546, label: 'Печатник высокой печати' },
  { ID: 16548, label: 'Печатник глубокой печати' },
  { ID: 16550, label: 'Печатник диаграммной продукции' },
  {
    ID: 16552,
    label: 'Печатник металлографских оттисков',
  },
  { ID: 16554, label: 'Печатник металлографской печати' },
  { ID: 16556, label: 'Печатник миллиметровки' },
  { ID: 16558, label: 'Печатник на линолеуме' },
  { ID: 16560, label: 'Печатник орловской печати' },
  {
    ID: 16562,
    label: 'Печатник печатно-высекального автомата',
  },
  { ID: 16564, label: 'Печатник плоской печати' },
  { ID: 16566, label: 'Печатник по жести' },
  { ID: 16568, label: 'Печатник субтитрования' },
  { ID: 16570, label: 'Печатник-тиснильщик' },
  { ID: 16572, label: 'Печатник трафаретной печати' },
  { ID: 16573, label: 'Печатник флексографской печати' },
  { ID: 16575, label: 'Печатник циферблатов' },
  { ID: 16577, label: 'Печатник эстампа' },
  {
    ID: 16580,
    label: 'Печевой восстановления железа и отжига железных порошков',
  },
  {
    ID: 16581,
    label: 'Печевой в производстве цинковой пыли',
  },
  {
    ID: 16583,
    label: 'Печевой иодиодного рафинирования',
  },
  { ID: 16585, label: 'Печевой на вельцпечах' },
  {
    ID: 16587,
    label: 'Печевой на восстановлении и дистилляции титана и редких металлов',
  },
  {
    ID: 16589,
    label: 'Печевой на получении цинкового купороса',
  },
  {
    ID: 16593,
    label: 'Печевой по восстановлению никелевого порошка',
  },
  {
    ID: 16594,
    label: 'Печевой по восстановлению термическим способом',
  },
  {
    ID: 16596,
    label:
      'Печевой по переработке титаносодержащих и редкоземельных материалов',
  },
  {
    ID: 16598,
    label: 'Печевой по производству трехокиси сурьмы',
  },
  { ID: 16600, label: 'Печник' },
  { ID: 16602, label: 'Пилоправ' },
  { ID: 16606, label: 'Пильщик' },
  { ID: 16608, label: 'Пирометрист' },
  { ID: 16611, label: 'Пиротехник' },
  { ID: 16613, label: 'Плавильщик' },
  { ID: 16614, label: 'Плавильщик' },
  {
    ID: 16615,
    label: 'Плавильщик абразивных материалов',
  },
  {
    ID: 16617,
    label: 'Плавильщик бариевого электролита',
  },
  { ID: 16619, label: 'Плавильщик вторичного олова' },
  {
    ID: 16621,
    label: 'Плавильщик изделий из кварцевого непрозрачного стекла',
  },
  {
    ID: 16624,
    label: 'Плавильщик-литейщик прецизионных сплавов',
  },
  { ID: 16626, label: 'Плавильщик металла и сплавов' },
  {
    ID: 16628,
    label: 'Плавильщик металла на вакуумных печах',
  },
  { ID: 16630, label: 'Плавильщик нафталина и фенолов' },
  {
    ID: 16632,
    label: 'Плавильщик обезвоженного кварцевого стекла',
  },
  { ID: 16634, label: 'Плавильщик огнеупорного сырья' },
  { ID: 16636, label: 'Плавильщик пищевого жира' },
  { ID: 16638, label: 'Плавильщик раскислителей' },
  { ID: 16640, label: 'Плавильщик свинцовых сплавов' },
  { ID: 16642, label: 'Плавильщик синтетических шлаков' },
  { ID: 16644, label: 'Плавильщик стекловолокна' },
  { ID: 16645, label: 'Плавильщик ферросплавов' },
  { ID: 16647, label: 'Плавильщик циклонной установки' },
  { ID: 16649, label: 'Плавильщик шоопсплава и висмута' },
  {
    ID: 16651,
    label: 'Плавильщик электронно-лучевой плавки',
  },
  { ID: 16653, label: 'Плавильщик эмали' },
  {
    ID: 16655,
    label:
      'Плакировщик алмазных порошков, кристаллов и сверхтвердых материалов',
  },
  { ID: 16657, label: 'Плакировщик изделий' },
  {
    ID: 16659,
    label: 'Плакировщик полимерных материалов на металл',
  },
  { ID: 16661, label: 'Планиметрист' },
  { ID: 16663, label: 'Пластикаторщик' },
  { ID: 16665, label: 'Плетельщик мебели' },
  { ID: 16667, label: 'Плиссировщик-гофрировщик' },
  { ID: 16668, label: 'Плодоовощевод' },
  {
    ID: 16669,
    label: 'Пломбировщик вагонов и контейнеров',
  },
  { ID: 16671, label: 'Плотник' },
  { ID: 16673, label: 'Плотник судовой' },
  { ID: 16675, label: 'Повар' },
  { ID: 16676, label: 'Повар судовой' },
  { ID: 16680, label: 'Повертальщик' },
  { ID: 16682, label: 'Поверяльщик веретен' },
  { ID: 16684, label: 'Поверяльщик разводок' },
  { ID: 16686, label: 'Подборщик авровых основ' },
  { ID: 16690, label: 'Подборщик камней' },
  { ID: 16692, label: 'Подборщик пресс-материалов' },
  {
    ID: 16694,
    label: 'Подборщик расцветок искусственных зубов',
  },
  { ID: 16698, label: 'Подборщик ставок' },
  { ID: 16700, label: 'Подборщик стекла' },
  {
    ID: 16701,
    label: 'Подборщик-учетчик абразивных изделий',
  },
  { ID: 16705, label: 'Подгонщик катушек' },
  { ID: 16707, label: 'Подгонщик шунтов' },
  { ID: 16709, label: 'Подготовитель бассейнов' },
  {
    ID: 16711,
    label: 'Подготовитель белья для глажения',
  },
  {
    ID: 16715,
    label: 'Подготовитель пищевого сырья и материалов',
  },
  {
    ID: 16717,
    label: 'Подготовитель прокатного инструмента',
  },
  {
    ID: 16719,
    label: 'Подготовитель составов к разливке плавок',
  },
  {
    ID: 16721,
    label: 'Подготовитель сталеразливочных канав',
  },
  { ID: 16723, label: 'Подготовщик исходного материала' },
  { ID: 16725, label: 'Подготовщик камер и рукавов' },
  {
    ID: 16727,
    label: 'Подготовщик набивочных и настилочных материалов',
  },
  {
    ID: 16729,
    label: 'Подготовщик основы для мультипликационных рисунков',
  },
  { ID: 16731, label: 'Подготовщик паковок и целлюлозы' },
  { ID: 16733, label: 'Подготовщик раскройных карт' },
  { ID: 16735, label: 'Подготовщик сетематериалов' },
  { ID: 16736, label: 'Подготовщик сырья' },
  { ID: 16738, label: 'Поддувщик изделий' },
  { ID: 16740, label: 'Подинщик' },
  {
    ID: 16742,
    label: 'Подклейщик стекловолокнистых материалов',
  },
  {
    ID: 16744,
    label:
      'Подносчик сырья, полуфабрикатов, химических материалов и отходов производства в отмочно-зольных, дубильно-красильных и жировальных цехах',
  },
  {
    ID: 16746,
    label:
      'Подносчик сырья, полуфабрикатов, химических материалов и отходов производства в сырейных, дубильно-красильных и формалиновых цехах',
  },
  {
    ID: 16748,
    label: 'Подручный вальцовщика стана горячего проката труб',
  },
  {
    ID: 16750,
    label: 'Подручный вальцовщика стана горячей прокатки',
  },
  {
    ID: 16752,
    label: 'Подручный вальцовщика стана холодного проката труб',
  },
  {
    ID: 16754,
    label: 'Подручный вальцовщика стана холодной прокатки',
  },
  {
    ID: 16756,
    label: 'Подручный сталевара вакуумной печи',
  },
  { ID: 16758, label: 'Подручный сталевара конвертера' },
  {
    ID: 16760,
    label: 'Подручный сталевара мартеновской печи',
  },
  {
    ID: 16762,
    label: 'Подручный сталевара печи прямого восстановления железа',
  },
  {
    ID: 16764,
    label: 'Подручный сталевара установки внепечной обработки стали',
  },
  {
    ID: 16765,
    label: 'Подручный сталевара установки электрошлакового переплава',
  },
  { ID: 16767, label: 'Подручный сталевара электропечи' },
  { ID: 16771, label: 'Подсобный рабочий' },
  {
    ID: 16773,
    label: 'Подсобный рабочий на лесозаготовках',
  },
  { ID: 16775, label: 'Подсобный рабочий на лесосплаве' },
  {
    ID: 16777,
    label: 'Подсобный рабочий на подсочке леса',
  },
  { ID: 16779, label: 'Подшкипер' },
  { ID: 16781, label: 'Пожарный' },
  { ID: 16783, label: 'Поездной электромеханик' },
  {
    ID: 16785,
    label: 'Позолотчик художественных изделий',
  },
  {
    ID: 16786,
    label: 'Полевой (путевой) рабочий изыскательской русловой партии',
  },
  {
    ID: 16787,
    label: 'Поливщик-лакировщик фотоматериалов',
  },
  { ID: 16789, label: 'Поливщик магнитных дорожек' },
  { ID: 16791, label: 'Поливщик светофильтров' },
  { ID: 16795, label: 'Полимеризаторщик' },
  {
    ID: 16797,
    label: 'Полимеризаторщик металлических форм и листов',
  },
  { ID: 16799, label: 'Полировщик' },
  { ID: 16800, label: 'Полировщик' },
  { ID: 16801, label: 'Полировщик водородным пламенем' },
  {
    ID: 16803,
    label: 'Полировщик волок из алмазов и сверхтвердых материалов',
  },
  { ID: 16804, label: 'Полировщик игл' },
  { ID: 16806, label: 'Полировщик изделий из бумаги' },
  { ID: 16810, label: 'Полировщик листов и лент' },
  { ID: 16812, label: 'Полировщик 6лопаток' },
  {
    ID: 16814,
    label: 'Полировщик музыкальных инструментов',
  },
  { ID: 16816, label: 'Полировщик оптических деталей' },
  {
    ID: 16818,
    label: 'Полировщик стекла и стеклоизделий',
  },
  {
    ID: 16819,
    label: 'Полировщик стеклоизделий кислотой',
  },
  { ID: 16821, label: 'Полировщик технических камней' },
  {
    ID: 16823,
    label: 'Полировщик формных цилиндров глубокой печати',
  },
  {
    ID: 16825,
    label: 'Полировщик хирургических инструментов и аппаратов',
  },
  {
    ID: 16827,
    label: 'Полировщик художественных изделий',
  },
  { ID: 16829, label: 'Полировщик шрота' },
  { ID: 16831, label: 'Полотер' },
  { ID: 16833, label: 'Поляризатор' },
  {
    ID: 16835,
    label: 'Помощник бурильщика капитального ремонта скважин',
  },
  {
    ID: 16837,
    label: 'Помощник бурильщика плавучего бурильного агрегата в море',
  },
  {
    ID: 16839,
    label:
      'Помощник бурильщика эксплуатационного и разведочного бурения скважин на нефть и газ (второй)',
  },
  {
    ID: 16840,
    label:
      'Помощник бурильщика эксплуатационного и разведочного бурения скважин на нефть и газ (первый)',
  },
  {
    ID: 16842,
    label:
      'Помощник бурильщика эксплуатационного и разведочного бурения скважин при электробурении',
  },
  { ID: 16844, label: 'Помощник воспитателя' },
  { ID: 16848, label: 'Помощник мастера' },
  { ID: 16849, label: 'Помощник мастера' },
  { ID: 16856, label: 'Помощник машиниста дизельпоезда' },
  {
    ID: 16860,
    label: 'Помощник машиниста (обжигальщика) вращающихся печей',
  },
  {
    ID: 16861,
    label: 'Помощник машиниста (обжигальщика) шахтных печей',
  },
  { ID: 16863, label: 'Помощник машиниста паровоза' },
  {
    ID: 16876,
    label: 'Помощник машиниста сырьевых мельниц',
  },
  { ID: 16878, label: 'Помощник машиниста тепловоза' },
  {
    ID: 16880,
    label: 'Помощник машиниста тягового агрегата',
  },
  {
    ID: 16882,
    label: 'Помощник машиниста угольных мельниц',
  },
  {
    ID: 16883,
    label: 'Помощник машиниста цементных мельниц',
  },
  { ID: 16885, label: 'Помощник машиниста электровоза' },
  {
    ID: 16887,
    label: 'Помощник машиниста электропоезда',
  },
  { ID: 16891, label: 'Помощник механика' },
  { ID: 16907, label: 'Помощник шкипера' },
  { ID: 16909, label: 'Портной' },
  { ID: 16911, label: 'Порционист лао-ча' },
  { ID: 16913, label: 'Посадчик металла' },
  {
    ID: 16917,
    label: 'Постановщик-выгрузчик абразивных изделий',
  },
  {
    ID: 16919,
    label: 'Постовой(разъездной) рабочий судоходной обстановки',
  },
  { ID: 16921, label: 'Посыпщик слюдой' },
  { ID: 16923, label: 'Починщик шпона и фанеры' },
  { ID: 16925, label: 'Почтальон' },
  { ID: 16926, label: 'Пошивщик изделий' },
  { ID: 16932, label: 'Правильщик вручную' },
  { ID: 16934, label: 'Правильщик на машинах' },
  {
    ID: 16936,
    label: 'Правильщик при стеклоформующей и отопочной машине',
  },
  { ID: 16938, label: 'Правильщик проката и труб' },
  { ID: 16940, label: 'Правильщик роговых пластин' },
  { ID: 16942, label: 'Правщик абразивных кругов' },
  {
    ID: 16944,
    label: 'Правщик меховых шкурок и скроев изделий',
  },
  { ID: 16946, label: 'Правщик проволоки и плетенки' },
  {
    ID: 16948,
    label: 'Правщик технологической оснастки',
  },
  { ID: 16950, label: 'Препаратор' },
  {
    ID: 16953,
    label: 'Препаратор биологических объектов',
  },
  { ID: 16955, label: 'Препаратор ветеринарный' },
  { ID: 16957, label: 'Препаратор по анатомии' },
  { ID: 16959, label: 'Препаратор по микрозоологии' },
  {
    ID: 16961,
    label: 'Препаратор производства биосинтетических лечебных средств',
  },
  {
    ID: 16963,
    label: 'Препаратор производства стекловидного тела',
  },
  {
    ID: 16965,
    label: 'Препаратор скелетов мелких животных',
  },
  { ID: 16966, label: 'Препаратор срезов по анатомии' },
  { ID: 16968, label: 'Препараторщик' },
  { ID: 16969, label: 'Прессовщик' },
  { ID: 16970, label: 'Прессовщик' },
  { ID: 16971, label: 'Прессовщик' },
  { ID: 16972, label: 'Прессовщик агломератов' },
  {
    ID: 16974,
    label: 'Прессовщик асбестоцементных изделий',
  },
  { ID: 16975, label: 'Прессовщик асфальтовых плиток' },
  { ID: 16977, label: 'Прессовщик блоков целлулоида' },
  {
    ID: 16979,
    label: 'Прессовщик бумагоделательной (картоноделательной) машины',
  },
  {
    ID: 16981,
    label: 'Прессовщик валяльно-войлочных изделий и шкурок',
  },
  {
    ID: 16983,
    label: 'Прессовщик волокна (льняное производство)',
  },
  {
    ID: 16985,
    label: 'Прессовщик волокна (пенько-джутовое производство)',
  },
  { ID: 16987, label: 'Прессовщик-вулканизаторщик' },
  {
    ID: 16989,
    label: 'Прессовщик-выдувщик целлулоидных изделий',
  },
  { ID: 16991, label: 'Прессовщик горячего стекла' },
  { ID: 16993, label: 'Прессовщик горячего формования' },
  { ID: 16995, label: 'Прессовщик горячих труб' },
  {
    ID: 16997,
    label: 'Прессовщик готовой продукции и отходов',
  },
  { ID: 16999, label: 'Прессовщик деталей для игрушек' },
  {
    ID: 17001,
    label: 'Прессовщик древесных и костровых плит',
  },
  {
    ID: 17002,
    label: 'Прессовщик заготовок для шпальтовых сит',
  },
  { ID: 17004, label: 'Прессовщик изделий из древесины' },
  {
    ID: 17005,
    label: 'Прессовщик изделий из металлических порошков',
  },
  {
    ID: 17006,
    label: 'Прессовщик изделий из оптического стекла и кристаллов',
  },
  { ID: 17008, label: 'Прессовщик изделий из пластмасс' },
  {
    ID: 17010,
    label: 'Прессовщик изделий из рогового порошка',
  },
  {
    ID: 17012,
    label: 'Прессовщик изделий из стеклопорошка',
  },
  {
    ID: 17014,
    label: 'Прессовщик изделий строительной керамики',
  },
  {
    ID: 17016,
    label: 'Прессовщик изделий электронной техники',
  },
  {
    ID: 17018,
    label: 'Прессовщик изоляционных материалов',
  },
  {
    ID: 17020,
    label:
      'Прессовщик инструментов из алмазных порошков и сверхтвердых материалов',
  },
  { ID: 17022, label: 'Прессовщик карандашных блоков' },
  { ID: 17024, label: 'Прессовщик картонажных изделий' },
  { ID: 17026, label: 'Прессовщик картона и фибры' },
  { ID: 17028, label: 'Прессовщик кирпичного чая' },
  {
    ID: 17030,
    label: 'Прессовщик клеильно-сушильной машины',
  },
  { ID: 17032, label: 'Прессовщик кож' },
  { ID: 17034, label: 'Прессовщик колес и бандажей' },
  { ID: 17035, label: 'Прессовщик колесных пар' },
  { ID: 17037, label: 'Прессовщик коллагенового жгута' },
  { ID: 17039, label: 'Прессовщик коры' },
  { ID: 17042, label: 'Прессовщик листовых материалов' },
  {
    ID: 17044,
    label: 'Прессовщик литых бумажных изделий',
  },
  {
    ID: 17046,
    label: 'Прессовщик лома и отходов металла',
  },
  { ID: 17048, label: 'Прессовщик махорочной пыли' },
  { ID: 17050, label: 'Прессовщик миканита и микалекса' },
  { ID: 17052, label: 'Прессовщик молетов' },
  { ID: 17054, label: 'Прессовщик на гидропрессах' },
  { ID: 17056, label: 'Прессовщик на горячей штамповке' },
  {
    ID: 17058,
    label: 'Прессовщик на испытании труб и баллонов',
  },
  { ID: 17060, label: 'Прессовщик нафталина' },
  { ID: 17062, label: 'Прессовщик обмазочного пресса' },
  { ID: 17064, label: 'Прессовщик огнеупорных изделий' },
  { ID: 17065, label: 'Прессовщик оптической керамики' },
  { ID: 17067, label: 'Прессовщик-освинцовщик рукавов' },
  { ID: 17069, label: 'Прессовщик отжимной машины' },
  {
    ID: 17071,
    label: 'Прессовщик-отжимщик пищевой продукции',
  },
  { ID: 17072, label: 'Прессовщик отходов' },
  { ID: 17073, label: 'Прессовщик отходов' },
  { ID: 17075, label: 'Прессовщик пергаментной машины' },
  {
    ID: 17077,
    label: 'Прессовщик перевязочных материалов',
  },
  {
    ID: 17079,
    label: 'Прессовщик пленочных материалов прессрулонным методом',
  },
  { ID: 17081, label: 'Прессовщик плит из тростника' },
  { ID: 17083, label: 'Прессовщик плиточного чая' },
  {
    ID: 17085,
    label: 'Прессовщик полуфабриката макаронных изделий',
  },
  { ID: 17087, label: 'Прессовщик пресспата' },
  {
    ID: 17089,
    label: 'Прессовщик-прошивщик рельсовых скреплений',
  },
  {
    ID: 17091,
    label: 'Прессовщик растительного войлока',
  },
  { ID: 17093, label: 'Прессовщик ровничной машины' },
  { ID: 17096, label: 'Прессовщик рыбной муки' },
  { ID: 17098, label: 'Прессовщик рядна из-под табака' },
  {
    ID: 17100,
    label:
      'Прессовщик секций, катушек и изоляционных деталей электрических машин и аппаратов',
  },
  { ID: 17102, label: 'Прессовщик слюдопластов' },
  {
    ID: 17104,
    label: 'Прессовщик стальных профилей на установке гидроэкструзии',
  },
  { ID: 17106, label: 'Прессовщик стеклопакетов' },
  { ID: 17108, label: 'Прессовщик стекол' },
  { ID: 17110, label: 'Прессовщик стеновых изделий' },
  { ID: 17112, label: 'Прессовщик стержней' },
  { ID: 17114, label: 'Прессовщик сыра' },
  { ID: 17115, label: 'Прессовщик сырья и волокна' },
  { ID: 17117, label: 'Прессовщик твердых сплавов' },
  {
    ID: 17119,
    label: 'Прессовщик теплоизоляционных изделий',
  },
  { ID: 17121, label: 'Прессовщик ткани' },
  { ID: 17123, label: 'Прессовщик торфоплит' },
  { ID: 17125, label: 'Прессовщик труб и профилей' },
  { ID: 17127, label: 'Прессовщик туб' },
  { ID: 17129, label: 'Прессовщик фарфоровых труб' },
  {
    ID: 17130,
    label: 'Прессовщик-формовщик пищевой продукции',
  },
  { ID: 17132, label: 'Прессовщик химического волокна' },
  {
    ID: 17134,
    label: 'Прессовщик хлопковой целлюлозы и отходов целлулоида',
  },
  {
    ID: 17136,
    label: 'Прессовщик электродной продукции',
  },
  {
    ID: 17138,
    label: 'Прессовщик электродов и элементов',
  },
  {
    ID: 17140,
    label: 'Прессовщик электрокерамических изделий в резиновых формах',
  },
  {
    ID: 17142,
    label: 'Прессовщик электрокерамических изделий из пластических масс',
  },
  {
    ID: 17144,
    label: 'Прессовщик электрокерамических изделий из порошковых масс',
  },
  {
    ID: 17146,
    label: 'Прессовщик электротехнических изделий',
  },
  {
    ID: 17148,
    label: 'Прессовщик электро-угольных изделий',
  },
  { ID: 17149, label: 'Приборист' },
  { ID: 17150, label: 'Приборист' },
  { ID: 17152, label: 'Привязывальщик' },
  {
    ID: 17154,
    label: 'Приготовитель абразивных порошков, паст и мастик',
  },
  { ID: 17156, label: 'Приготовитель активных масс' },
  { ID: 17158, label: 'Приготовитель ангоба и глазури' },
  { ID: 17160, label: 'Приготовитель белковых масс' },
  { ID: 17162, label: 'Приготовитель бурового раствора' },
  {
    ID: 17164,
    label: 'Приготовитель водорослевого порошка и крупки',
  },
  { ID: 17166, label: 'Приготовитель волокна' },
  {
    ID: 17168,
    label: 'Приготовитель грунтовых составов',
  },
  {
    ID: 17169,
    label: 'Приготовитель дражировочной массы',
  },
  {
    ID: 17170,
    label:
      'Приготовитель заправочных, огнеупорных материалов и термических смесей',
  },
  {
    ID: 17172,
    label: 'Приготовитель композиционных блоков',
  },
  { ID: 17174, label: 'Приготовитель кормов' },
  {
    ID: 17177,
    label: 'Приготовитель крахмального молока',
  },
  {
    ID: 17178,
    label: 'Приготовитель крупки органического стекла',
  },
  {
    ID: 17180,
    label: 'Приготовитель кулинарных изделий из мяса птицы и кроликов',
  },
  {
    ID: 17182,
    label: 'Приготовитель лаков, красок и левкаса',
  },
  { ID: 17184, label: 'Приготовитель масс' },
  { ID: 17186, label: 'Приготовитель мелассного сусла' },
  {
    ID: 17188,
    label: 'Приготовитель молочных коктейлей',
  },
  { ID: 17190, label: 'Приготовитель морса' },
  { ID: 17192, label: 'Приготовитель напитков' },
  {
    ID: 17194,
    label: 'Приготовитель нюхательной махорки и табака',
  },
  { ID: 17196, label: 'Приготовитель оптических клеев' },
  {
    ID: 17198,
    label: 'Приготовитель питательных растворов',
  },
  { ID: 17200, label: 'Приготовитель пробковой крупы' },
  {
    ID: 17202,
    label: 'Приготовитель пропиточного состава',
  },
  { ID: 17203, label: 'Приготовитель пульпы' },
  {
    ID: 17205,
    label: 'Приготовитель разделительной пасты',
  },
  { ID: 17207, label: 'Приготовитель растворов и масс' },
  {
    ID: 17209,
    label: 'Приготовитель растворов и смесей',
  },
  {
    ID: 17211,
    label: 'Приготовитель растворов и электролитов',
  },
  {
    ID: 17213,
    label: 'Приготовитель растворов красителей',
  },
  { ID: 17215, label: 'Приготовитель реактивной воды' },
  {
    ID: 17217,
    label: 'Приготовитель склеивающего состава',
  },
  {
    ID: 17219,
    label: 'Приготовитель смесей и масс медицинского назначения',
  },
  { ID: 17221, label: 'Приготовитель спичечных масс' },
  {
    ID: 17223,
    label: 'Приготовитель стиральных растворов',
  },
  {
    ID: 17225,
    label: 'Приготовитель сухих пивных дрожжей',
  },
  { ID: 17227, label: 'Приготовитель технических жиров' },
  { ID: 17229, label: 'Приготовитель тресты' },
  {
    ID: 17231,
    label: 'Приготовитель уплотняющих растворов и паст',
  },
  { ID: 17233, label: 'Приготовитель шампанского' },
  {
    ID: 17234,
    label: 'Приготовитель шихты полупроводниковых материалов',
  },
  {
    ID: 17236,
    label: 'Приготовитель электролита и флюса',
  },
  {
    ID: 17238,
    label: 'Приготовитель электропроводного слоя',
  },
  { ID: 17240, label: 'Приготовитель эмалевых порошков' },
  { ID: 17242, label: 'Приготовитель эмульсий' },
  { ID: 17244, label: 'Приемосдатчик груза и багажа' },
  {
    ID: 17246,
    label: 'Приемосдатчик груза и багажа в поездах',
  },
  { ID: 17248, label: 'Приемщик баллонов' },
  {
    ID: 17250,
    label: 'Приемщик биологических материалов',
  },
  {
    ID: 17252,
    label: 'Приемщик драгоценных металлов и сырья',
  },
  { ID: 17254, label: 'Приемщик заказов' },
  {
    ID: 17256,
    label: 'Приемщик золота стоматологических учреждений (подразделений)',
  },
  {
    ID: 17258,
    label: 'Приемщик материалов, полуфабрикатов и готовых изделий',
  },
  {
    ID: 17259,
    label: 'Приемщик материалов, полуфабрикатов и готовых изделий',
  },
  { ID: 17261, label: 'Приемщик молочной продукции' },
  { ID: 17263, label: 'Приемщик на машинах и агрегатах' },
  { ID: 17265, label: 'Приемщик-отправитель' },
  { ID: 17266, label: 'Приемщик перопухового сырья' },
  { ID: 17268, label: 'Приемщик плавсредств' },
  { ID: 17270, label: 'Приемщик поездов' },
  { ID: 17272, label: 'Приемщик покрышек' },
  { ID: 17274, label: 'Приемщик пункта проката' },
  {
    ID: 17276,
    label: 'Приемщик-раздатчик золотосодержащих препаратов',
  },
  { ID: 17278, label: 'Приемщик руды и асбеста' },
  {
    ID: 17280,
    label: 'Приемщик-сдатчик пищевой продукции',
  },
  {
    ID: 17282,
    label: 'Приемщик сельскохозяйственных продуктов и сырья',
  },
  { ID: 17284, label: 'Приемщик скота' },
  { ID: 17286, label: 'Приемщик солодкового корня' },
  {
    ID: 17288,
    label: 'Приемщик-сортировщик живой птицы и кроликов',
  },
  { ID: 17290, label: 'Приемщик сырья' },
  { ID: 17292, label: 'Приемщик сырья для клея' },
  {
    ID: 17294,
    label: 'Приемщик сырья, полуфабрикатов и готовой продукции',
  },
  { ID: 17296, label: 'Приемщик товаров' },
  {
    ID: 17298,
    label: 'Приемщик трамваев и троллейбусов',
  },
  { ID: 17300, label: 'Приемщик яиц' },
  { ID: 17302, label: 'Прикатчик напыленных изделий' },
  { ID: 17304, label: 'Присучальщик основ' },
  { ID: 17306, label: 'Притирщик стеклоизделий' },
  { ID: 17308, label: 'Пробивальщик-продувальщик труб' },
  { ID: 17310, label: 'Пробист высокой печати' },
  { ID: 17312, label: 'Пробист плоской печати' },
  { ID: 17314, label: 'Пробоотборщик' },
  { ID: 17316, label: 'Проборщик' },
  { ID: 17320, label: 'Пробуторщик малолитражной драги' },
  { ID: 17322, label: 'Проверщик судовой' },
  { ID: 17324, label: 'Проводник' },
  {
    ID: 17328,
    label: 'Проводник (вожатый) служебных собак',
  },
  {
    ID: 17330,
    label: 'Проводник на геологических поисках и съемке',
  },
  { ID: 17334, label: 'Проводник пассажирского вагона' },
  {
    ID: 17336,
    label: 'Проводник по сопровождению грузов и спецвагонов',
  },
  {
    ID: 17339,
    label: 'Проводник по сопровождению животных',
  },
  {
    ID: 17341,
    label:
      'Проводник по сопровождению локомотивов и пассажирских вагонов в нерабочем состоянии',
  },
  {
    ID: 17347,
    label: 'Проводник-электромонтер почтовых вагонов',
  },
  { ID: 17349, label: 'Провязывальщик мотков' },
  {
    ID: 17351,
    label: 'Продавец непродовольственных товаров',
  },
  {
    ID: 17353,
    label: 'Продавец продовольственных товаров',
  },
  { ID: 17355, label: 'Прожекторист' },
  {
    ID: 17357,
    label: 'Прожигальщик медицинских изделий',
  },
  { ID: 17359, label: 'Прокальщик' },
  { ID: 17361, label: 'Прокальщик зерна и шлифпорошков' },
  { ID: 17363, label: 'Прокальщик на печах' },
  { ID: 17365, label: 'Прокальщик порошка для кабеля' },
  {
    ID: 17367,
    label: 'Прокальщик электро-угольного производства',
  },
  { ID: 17369, label: 'Прокатчик горячего металла' },
  { ID: 17371, label: 'Прокатчик кож' },
  { ID: 17373, label: 'Прокатчик пленки' },
  { ID: 17375, label: 'Прокатчик слюды' },
  { ID: 17377, label: 'Прокатчик фарфоровых труб' },
  { ID: 17379, label: 'Прокатчик шаров' },
  { ID: 17381, label: 'Проклеивальщик' },
  { ID: 17383, label: 'Проклеивальщик ватилина' },
  { ID: 17385, label: 'Проклейщик массы' },
  { ID: 17387, label: 'Промазчик форм' },
  {
    ID: 17389,
    label: 'Промывальщик волокнистых материалов',
  },
  { ID: 17391, label: 'Промывальщик геологических проб' },
  { ID: 17393, label: 'Промывальщик котлов паровозов' },
  { ID: 17395, label: 'Промывальщик-пропарщик цистерн' },
  { ID: 17397, label: 'Промывальщик сырья' },
  { ID: 17399, label: 'Промывальщик технических сукон' },
  { ID: 17401, label: 'Промывщик бриллиантов и алмазов' },
  { ID: 17403, label: 'Промывщик гидроксала' },
  { ID: 17405, label: 'Промывщик деталей и узлов' },
  { ID: 17407, label: 'Промывщик камней' },
  { ID: 17409, label: 'Промывщик оптических деталей' },
  { ID: 17411, label: 'Промывщик целлюлозы' },
  { ID: 17412, label: 'Промышленный альпинист' },
  { ID: 17413, label: 'Пропарщик' },
  {
    ID: 17415,
    label: 'Пропарщик асбестоцементных и асбесто-силитовых изделий',
  },
  { ID: 17417, label: 'Пропарщик лао-ча' },
  { ID: 17420, label: 'Пропарщик-проварщик древесины' },
  { ID: 17422, label: 'Пропарщик стеновых материалов' },
  { ID: 17423, label: 'Пропитчик' },
  { ID: 17424, label: 'Пропитчик' },
  { ID: 17425, label: 'Пропитчик' },
  {
    ID: 17426,
    label: 'Пропитчик бумаги и бумажных изделий',
  },
  { ID: 17428, label: 'Пропитчик бумаги и тканей' },
  { ID: 17430, label: 'Пропитчик кабелей и проводов' },
  { ID: 17432, label: 'Пропитчик карандашных дощечек' },
  {
    ID: 17434,
    label: 'Пропитчик пиломатериалов и изделий из древесины',
  },
  {
    ID: 17436,
    label: 'Пропитчик по огнезащитной пропитке',
  },
  {
    ID: 17438,
    label: 'Пропитчик слюдопластовых материалов',
  },
  { ID: 17440, label: 'Пропитчик стержней' },
  { ID: 17442, label: 'Пропитчик шпона' },
  {
    ID: 17444,
    label: 'Пропитчик электротехнических изделий',
  },
  { ID: 17446, label: 'Пропитывальщик пожарных рукавов' },
  { ID: 17448, label: 'Просевальщик материалов' },
  { ID: 17450, label: 'Просевальщик порошков' },
  { ID: 17451, label: 'Просевальщик (рассевальщик)' },
  { ID: 17453, label: 'Просевальщик сыпучих материалов' },
  {
    ID: 17455,
    label: 'Просевальщик технической продукции',
  },
  {
    ID: 17457,
    label: 'Просевальщик фарматуры и отходов',
  },
  {
    ID: 17459,
    label: 'Просевальщик фтористого натрия и извести-пушонки',
  },
  { ID: 17461, label: 'Просевщик' },
  { ID: 17463, label: 'Просевщик бисера' },
  {
    ID: 17465,
    label: 'Просевщик порошков на механических ситах',
  },
  { ID: 17467, label: 'Просеивальщик' },
  {
    ID: 17469,
    label: 'Просмотрщик ампул с инъекционными растворами',
  },
  {
    ID: 17471,
    label: 'Просмотрщик продукции медицинского назначения',
  },
  { ID: 17473, label: 'Протирщик изделий' },
  { ID: 17475, label: 'Протирщик стеарата кальция' },
  { ID: 17477, label: 'Протирщик часовых стекол' },
  {
    ID: 17479,
    label: 'Протирщик электровакуумных приборов',
  },
  { ID: 17481, label: 'Протравщик хлопковых семян' },
  { ID: 17483, label: 'Протравщик шкурок' },
  { ID: 17485, label: 'Протяжчик' },
  { ID: 17487, label: 'Протяжчик штурвалов' },
  { ID: 17489, label: 'Профилировщик' },
  { ID: 17491, label: 'Проходчик' },
  { ID: 17493, label: 'Проходчик горных склонов' },
  {
    ID: 17495,
    label: 'Проходчик на поверхностных работах',
  },
  { ID: 17497, label: 'Проявщик кинопленки' },
  { ID: 17499, label: 'Пружинщик' },
  { ID: 17501, label: 'Прядильщик' },
  { ID: 17503, label: 'Птицевод' },
  { ID: 17505, label: 'Пудровщик' },
  { ID: 17507, label: 'Пудровщик оттисков деколи' },
  { ID: 17509, label: 'Пультовщик конвертера' },
  {
    ID: 17511,
    label: 'Пультовщик электроплавильной печи',
  },
  { ID: 17513, label: 'Пульфонщик' },
  { ID: 17517, label: 'Путевой рабочий на озере' },
  {
    ID: 17519,
    label: 'Путевой рабочий тральной бригады',
  },
  { ID: 17521, label: 'Пчеловод' },
  { ID: 17523, label: 'Пятновыводчик' },
  { ID: 17524, label: 'Рабочий береговой' },
  { ID: 17525, label: 'Рабочий бюро бытовых услуг' },
  { ID: 17530, label: 'Рабочий зеленого строительства' },
  { ID: 17531, label: 'Рабочий зеленого хозяйства' },
  { ID: 17532, label: 'Рабочий карты намыва' },
  {
    ID: 17534,
    label: 'Рабочий на геологосъемочных и поисковых работах',
  },
  {
    ID: 17536,
    label: 'Рабочий на геофизических работах',
  },
  { ID: 17540, label: 'Рабочий по подаче химикатов' },
  { ID: 17541, label: 'Рабочий по обслуживанию бани' },
  { ID: 17542, label: 'Рабочий плодоовощного хранилища' },
  {
    ID: 17543,
    label: 'Рабочий по благоустройству населенных пунктов',
  },
  {
    ID: 17544,
    label: 'Рабочий по комплексному обслуживанию и ремонту зданий',
  },
  {
    ID: 17545,
    label: 'Машинист по стирке и ремонту спецодежды',
  },
  { ID: 17546, label: 'Рабочий по уходу за животными' },
  { ID: 17547, label: 'Рабочий производственных бань' },
  { ID: 17549, label: 'Рабочий противолавинной защиты' },
  { ID: 17550, label: 'Рабочий ритуальных услуг' },
  { ID: 17551, label: 'Радиометрист' },
  {
    ID: 17553,
    label:
      'Радиомеханик по обслуживанию и ремонту радиотелевизионной аппаратуры',
  },
  {
    ID: 17556,
    label: 'Радиомеханик по ремонту радиоэлектронного оборудования',
  },
  { ID: 17560, label: 'Радиомонтажник судовой' },
  {
    ID: 17562,
    label: 'Радиомонтер приемных телевизионных антенн',
  },
  { ID: 17564, label: 'Радиооператор' },
  { ID: 17566, label: 'Радиотелеграфист' },
  { ID: 17568, label: 'Радиотехник' },
  { ID: 17572, label: 'Радист-радиолокаторщик' },
  { ID: 17574, label: 'Разбивщик отходов' },
  { ID: 17576, label: 'Разбивщик сырья' },
  { ID: 17577, label: 'Разбивщик ферросплавов' },
  {
    ID: 17579,
    label: 'Разбортовщик винипластовых и полиэтиленовых труб',
  },
  {
    ID: 17581,
    label: 'Разборщик асбестоцементных изделий',
  },
  {
    ID: 17583,
    label: 'Разборщик оптического стекла и кристаллов',
  },
  { ID: 17585, label: 'Разборщик пакетов' },
  { ID: 17586, label: 'Разборщик печей сопротивления' },
  { ID: 17588, label: 'Разборщик субпродуктов' },
  { ID: 17590, label: 'Развальцовщик стекла' },
  { ID: 17592, label: 'Разварщик саломаса' },
  { ID: 17594, label: 'Разварщик силикатной глыбы' },
  {
    ID: 17596,
    label: 'Разведчик объектов природы для коллекций',
  },
  { ID: 17598, label: 'Развесчик химического сырья' },
  { ID: 17600, label: 'Разводчик кож' },
  { ID: 17602, label: 'Разводчик (распусчик) холяв' },
  { ID: 17604, label: 'Развязывальщик' },
  { ID: 17606, label: 'Разгрузчик диффузоров' },
  { ID: 17608, label: 'Раздатчик взрывчатых материалов' },
  { ID: 17610, label: 'Раздатчик нефтепродуктов' },
  { ID: 17614, label: 'Раздельщик жгутов стекловолокна' },
  {
    ID: 17616,
    label: 'Раздельщик лома и отходов металла',
  },
  { ID: 17619, label: 'Раздельщик титановой губки' },
  { ID: 17621, label: 'Раздирщик пакетов' },
  {
    ID: 17623,
    label: 'Развивальщик - загладчик пеномассы',
  },
  { ID: 17625, label: 'Разливщик ртути' },
  { ID: 17627, label: 'Разливщик стали' },
  { ID: 17629, label: 'Разливщик стерильных растворов' },
  { ID: 17632, label: 'Разливщик химической продукции' },
  {
    ID: 17634,
    label: 'Разливщик цветных металлов и сплавов',
  },
  { ID: 17636, label: 'Разметчик' },
  { ID: 17638, label: 'Разметчик алмазов' },
  { ID: 17640, label: 'Разметчик деталей и материалов' },
  { ID: 17642, label: 'Разметчик плазовый' },
  { ID: 17644, label: 'Разметчик по дереву' },
  { ID: 17646, label: 'Разметчик поката' },
  { ID: 17647, label: 'Разметчик пьезокварцевого сырья' },
  { ID: 17649, label: 'Разметчик стекла' },
  { ID: 17651, label: 'Разметчик судовой' },
  { ID: 17653, label: 'Разметчик хлыстов' },
  { ID: 17655, label: 'Размольщик' },
  { ID: 17656, label: 'Размольщик' },
  {
    ID: 17657,
    label: 'Размольщик вирусной ткани и бактерийной массы',
  },
  {
    ID: 17658,
    label: 'Размольщик - дозировщик угольных масс',
  },
  { ID: 17660, label: 'Размольщик древесины' },
  { ID: 17662, label: 'Размольщик карандашной массы' },
  {
    ID: 17664,
    label: 'Размольщик (мельник) кости - паренки',
  },
  { ID: 17665, label: 'Размольщик роговой стружки' },
  { ID: 17667, label: 'Размотчик' },
  { ID: 17669, label: 'Размотчик лент' },
  { ID: 17671, label: 'Размотчик стеклонити' },
  { ID: 17676, label: 'Разрабатывальщик отходов' },
  { ID: 17678, label: 'Разрабатывальщик сырья' },
  { ID: 17681, label: 'Разрисовщик игрушек' },
  { ID: 17683, label: 'Разрисовщик изделий из кожи' },
  {
    ID: 17685,
    label: 'Разрисовщик кожгалантерейных изделий',
  },
  { ID: 17687, label: 'Разрисовщик моделей' },
  { ID: 17689, label: 'Разрисовщик обоев' },
  { ID: 17691, label: 'Разрисовщик по стеклу' },
  { ID: 17693, label: 'Разрисовщик ткани' },
  {
    ID: 17695,
    label: 'Разрубщик аккумуляторных пластин',
  },
  { ID: 17697, label: 'Разрубщик мяса' },
  { ID: 17701, label: 'Разрыхлитель табака' },
  { ID: 17704, label: 'Раймовщик дистилляционных печей' },
  { ID: 17705, label: 'Раклист' },
  { ID: 17706, label: 'Раклист' },
  { ID: 17708, label: 'Рамповщик' },
  { ID: 17710, label: 'Рамщик' },
  { ID: 17712, label: 'Раскатчик' },
  { ID: 17714, label: 'Раскатчик - сортировщик бумаги' },
  { ID: 17716, label: 'Раскатчик стержней' },
  { ID: 17717, label: 'Раскатчик ткани' },
  { ID: 17719, label: 'Раскладчик лекал' },
  { ID: 17721, label: 'Раскладчик листового табака' },
  { ID: 17723, label: 'Раскладчик стекловолокна' },
  { ID: 17725, label: 'Раскладчик сырья' },
  { ID: 17729, label: 'Раскольщик алмазов' },
  { ID: 17731, label: 'Раскольщик блоков' },
  {
    ID: 17733,
    label: 'Раскрасчик диапозитивов и фотоотпечатков',
  },
  {
    ID: 17734,
    label: 'Раскрасчик законтурованных рисунков',
  },
  { ID: 17736, label: 'Раскрасчик изделий' },
  { ID: 17738, label: 'Раскройщик' },
  { ID: 17740, label: 'Раскройщик бересты' },
  { ID: 17742, label: 'Раскройщик кожевенного сырья' },
  { ID: 17744, label: 'Раскройщик кожи и меха' },
  { ID: 17746, label: 'Раскройщик листового материала' },
  { ID: 17748, label: 'Раскройщик материалов' },
  { ID: 17750, label: 'Раскройщик пленки' },
  {
    ID: 17752,
    label: 'Раскройщик стекловолокнистых материалов',
  },
  {
    ID: 17753,
    label: 'Раскройщик шлифовального полотна',
  },
  { ID: 17755, label: 'Раскряжевщик' },
  { ID: 17757, label: 'Распаковщик сырья' },
  {
    ID: 17759,
    label: 'Распалубщик теплоизоляционных и акустических изделий',
  },
  { ID: 17762, label: 'Распарщик целлулоидных пластин' },
  { ID: 17764, label: 'Распиловщик алмазов' },
  {
    ID: 17766,
    label: 'Распиловщик водорастворных кристаллов',
  },
  { ID: 17768, label: 'Распиловщик войлока' },
  { ID: 17769, label: 'Распиловщик камня' },
  { ID: 17770, label: 'Распиловщик камня' },
  { ID: 17772, label: 'Распиловщик кости и рога' },
  { ID: 17774, label: 'Распиловщик меха и войлока' },
  { ID: 17776, label: 'Распиловщик мясопродуктов' },
  {
    ID: 17777,
    label: 'Распиловщик необожженных кругов и брусков',
  },
  { ID: 17779, label: 'Распиловщик оптического стекла' },
  { ID: 17781, label: 'Расправщик' },
  { ID: 17783, label: 'Расправщик войлочных изделий' },
  { ID: 17785, label: 'Расправщик основ' },
  { ID: 17787, label: 'Распределитель работ' },
  { ID: 17789, label: 'Распределитель силикатной массы' },
  { ID: 17791, label: 'Распылитель газопоглотителя' },
  {
    ID: 17792,
    label: 'Рассевальщик шлифзерна и шлифпорошков',
  },
  { ID: 17794, label: 'Рассевщик' },
  { ID: 17796, label: 'Растворщик реагентов' },
  { ID: 17798, label: 'Растильщик грибницы' },
  { ID: 17800, label: 'Растяжчик кожаных полос' },
  { ID: 17802, label: 'Растяжчик кож и овчин на рамы' },
  { ID: 17803, label: 'Растяжчик металлосеток' },
  {
    ID: 17805,
    label: 'Растяжчик секций и катушек электрических машин',
  },
  {
    ID: 17807,
    label: 'Расфасовщик алмазов и алмазных порошков',
  },
  { ID: 17809, label: 'Расфасовщик ваты' },
  { ID: 17811, label: 'Расфасовщик мясопродуктов' },
  {
    ID: 17813,
    label: 'Расфасовщик нюхательной махорки и табака',
  },
  { ID: 17814, label: 'Расфасовщик табака' },
  { ID: 17816, label: 'Расформовщик' },
  { ID: 17818, label: 'Расчесывальщик меховых шкурок' },
  { ID: 17820, label: 'Расшлифовщик фильеров' },
  {
    ID: 17822,
    label: 'Расщепляльщик синтетических нитей',
  },
  { ID: 17824, label: 'Рафинировщик' },
  { ID: 17826, label: 'Рафинировщик ртути' },
  { ID: 17828, label: 'Реактивщик' },
  { ID: 17830, label: 'Реакторщик' },
  { ID: 17832, label: 'Реакторщик химочистки рассола' },
  { ID: 17834, label: 'Регенераторщик' },
  { ID: 17836, label: 'Регенераторщик абразивов' },
  {
    ID: 17838,
    label: 'Регенераторщик драгоценных металлов',
  },
  {
    ID: 17840,
    label: 'Регенераторщик отработанного масла',
  },
  {
    ID: 17841,
    label: 'Регенераторщик сернистой кислоты',
  },
  { ID: 17844, label: 'Регенераторщик слюды' },
  {
    ID: 17846,
    label: 'Регулировщик асбестообогатительного оборудования',
  },
  {
    ID: 17848,
    label: 'Регулировщик-градуировщик электроизмерительных приборов',
  },
  {
    ID: 17850,
    label: 'Регулировщик композиции и концентрации массы',
  },
  {
    ID: 17852,
    label: 'Регулировщик-настройщик тренажеров',
  },
  { ID: 17854, label: 'Регулировщик пианино и роялей' },
  { ID: 17856, label: 'Регулировщик подачи воды' },
  { ID: 17857, label: 'Регулировщик полей фильтрации' },
  { ID: 17859, label: 'Регулировщик работы скважин' },
  {
    ID: 17861,
    label: 'Регулировщик радиоэлектронной аппаратуры и приборов',
  },
  {
    ID: 17863,
    label: 'Регулировщик скорости движения вагонов',
  },
  {
    ID: 17865,
    label: 'Регулировщик хвостового хозяйства',
  },
  { ID: 17867, label: 'Регулировщик электродов' },
  {
    ID: 17869,
    label: 'Регулировщик язычковых инструментов',
  },
  { ID: 17871, label: 'Редуцировщик игл' },
  {
    ID: 17872,
    label: 'Редуцировщик трубчатых электронагревателей',
  },
  { ID: 17874, label: 'Резчик алмазов' },
  { ID: 17876, label: 'Резчик ампул и трубок' },
  {
    ID: 17878,
    label: 'Резчик асбестоцементных и асбесто-силитовых изделий',
  },
  {
    ID: 17880,
    label: 'Резчик бетонных и железобетонных изделий',
  },
  { ID: 17882, label: 'Резчик брикета и заготовок' },
  {
    ID: 17883,
    label: 'Резчик бумаги, картона и целлюлозы',
  },
  { ID: 17887, label: 'Резчик гипсокартонных листов' },
  { ID: 17889, label: 'Резчик горячего металла' },
  { ID: 17891, label: 'Резчик декалькоманий' },
  { ID: 17893, label: 'Резчик деревянного шрифта' },
  {
    ID: 17895,
    label: 'Резчик заготовок и изделий из пластических масс',
  },
  {
    ID: 17897,
    label: 'Резчик керамических и фарфоровых изделий',
  },
  { ID: 17899, label: 'Резчик кирпича и черепицы' },
  { ID: 17901, label: 'Резчик конвертов' },
  { ID: 17902, label: 'Резчик-лудильщик фольги' },
  { ID: 17904, label: 'Резчик магнитных лент' },
  { ID: 17906, label: 'Резчик магнитопроводов' },
  { ID: 17908, label: 'Резчик материалов' },
  { ID: 17909, label: 'Резчик материалов и изделий' },
  { ID: 17910, label: 'Резчик материалов и изделий' },
  {
    ID: 17912,
    label: 'Резчик материалов кабельного производства',
  },
  {
    ID: 17914,
    label: 'Резчик металла на ножницах и прессах',
  },
  { ID: 17916, label: 'Резчик металлического натрия' },
  { ID: 17918, label: 'Резчик минералов' },
  { ID: 17920, label: 'Резчик мясопродуктов' },
  { ID: 17922, label: 'Резчик на микротоме' },
  { ID: 17925, label: 'Резчик на огне' },
  { ID: 17926, label: 'Резчик на отжимной машине' },
  {
    ID: 17928,
    label: 'Резчик на пилах, ножовках и станках',
  },
  { ID: 17930, label: 'Резчик неэмульсированных пленок' },
  { ID: 17932, label: 'Резчик нитей стержней' },
  { ID: 17934, label: 'Резчик пеноблоков' },
  { ID: 17936, label: 'Резчик пищевой продукции' },
  { ID: 17938, label: 'Резчик по дереву и бересте' },
  { ID: 17940, label: 'Резчик по камню' },
  { ID: 17942, label: 'Резчик по кости и рогу' },
  {
    ID: 17943,
    label: 'Резчик полуфабрикатов изделий медицинского назначения',
  },
  { ID: 17945, label: 'Резчик пробковых изделий' },
  { ID: 17947, label: 'Резчик пряжи' },
  { ID: 17949, label: 'Резчик радиокерамики и ферритов' },
  { ID: 17951, label: 'Резчик свеклы' },
  { ID: 17952, label: 'Резчик слюды' },
  { ID: 17954, label: 'Резчик стекла' },
  {
    ID: 17956,
    label: 'Резчик стекловолокнистых и стеклопластиковых материалов',
  },
  { ID: 17958, label: 'Резчик стеклоизделий' },
  { ID: 17960, label: 'Резчик сусальных металлов' },
  { ID: 17962, label: 'Резчик сырья' },
  {
    ID: 17964,
    label: 'Резчик теплоизоляционных и акустических изделий',
  },
  { ID: 17966, label: 'Резчик траншей' },
  { ID: 17968, label: 'Резчик труб и заготовок' },
  { ID: 17970, label: 'Резчик химического волокна' },
  { ID: 17972, label: 'Резчик холодного металла' },
  { ID: 17974, label: 'Резчик шлифовальной шкурки' },
  {
    ID: 17975,
    label: 'Резчик шпона и облицовочных материалов',
  },
  { ID: 17977, label: 'Резчик эластомеров и резины' },
  {
    ID: 17979,
    label: 'Резчик электроизоляционных материалов',
  },
  { ID: 17981, label: 'Резьбонарезчик деталей часов' },
  {
    ID: 17983,
    label: 'Резьбонарезчик на специальных станках',
  },
  { ID: 17985, label: 'Резьбофрезеровщик' },
  { ID: 17986, label: 'Резьбошлифовщик' },
  { ID: 17988, label: 'Реквизитор' },
  { ID: 17990, label: 'Рекуператорщик' },
  { ID: 17992, label: 'Рекуператорщик алмазов' },
  {
    ID: 17996,
    label: 'Ремонтировщик кожгалантерейных изделий',
  },
  { ID: 17998, label: 'Ремонтировщик обувных колодок' },
  {
    ID: 18000,
    label: 'Ремонтировщик плоскостных спортивных сооружений',
  },
  {
    ID: 18001,
    label: 'Ремонтировщик полимеризационного инвентаря',
  },
  { ID: 18003, label: 'Ремонтировщик резиновых изделий' },
  {
    ID: 18005,
    label: 'Ремонтировщик респираторов и противогазов',
  },
  { ID: 18007, label: 'Ремонтировщик сетеизделий' },
  { ID: 18012, label: 'Ремонтировщик шпуль' },
  {
    ID: 18013,
    label: 'Ремонтник искусственных сооружений',
  },
  {
    ID: 18014,
    label: 'Ремонтник технологической оснастки',
  },
  { ID: 18019, label: 'Ремюер' },
  { ID: 18021, label: 'Рентгенгониометрист' },
  { ID: 18023, label: 'Рентгеномеханик' },
  { ID: 18025, label: 'Реперщик' },
  { ID: 18027, label: 'Репульпаторщик' },
  {
    ID: 18031,
    label: 'Рессорщик на обработке горячего металла',
  },
  {
    ID: 18032,
    label: 'Реставратор архивных и библиотечных материалов',
  },
  { ID: 18033, label: 'Реставратор готовой продукции' },
  {
    ID: 18035,
    label: 'Реставратор декоративно-художественных покрасок',
  },
  {
    ID: 18036,
    label: 'Реставратор декоративных штукатурок и лепных изделий',
  },
  {
    ID: 18038,
    label: 'Реставратор духовых инструментов',
  },
  {
    ID: 18040,
    label: 'Реставратор клавишных инструментов',
  },
  { ID: 18042, label: 'Реставратор кровельных покрытий' },
  {
    ID: 18044,
    label: 'Реставратор металлических конструкций',
  },
  {
    ID: 18046,
    label: 'Реставратор памятников деревянного зодчества',
  },
  {
    ID: 18048,
    label: 'Реставратор памятников каменного зодчества',
  },
  {
    ID: 18050,
    label: 'Реставратор произведений из дерева',
  },
  {
    ID: 18052,
    label: 'Реставратор смычковых и щипковых инструментов',
  },
  {
    ID: 18054,
    label: 'Реставратор тканей, гобеленов и ковров',
  },
  {
    ID: 18056,
    label: 'Реставратор ударных инструментов',
  },
  {
    ID: 18058,
    label: 'Реставратор фильмовых материалов',
  },
  { ID: 18059, label: 'Реставратор фильмокопий' },
  {
    ID: 18061,
    label: 'Реставратор художественных изделий и декоративных предметов',
  },
  {
    ID: 18063,
    label: 'Реставратор язычковых инструментов',
  },
  { ID: 18065, label: 'Ретушер' },
  { ID: 18066, label: 'Ретушер' },
  {
    ID: 18068,
    label: 'Ретушер прецизионной фотолитографии',
  },
  { ID: 18070, label: 'Ретушер субтитров' },
  { ID: 18072, label: 'Рецептурщик' },
  {
    ID: 18074,
    label:
      'Речной рабочий на подводно-технических, габионных и фашинных работах, выполняемых с поверхности',
  },
  {
    ID: 18075,
    label:
      'Речной рабочий на эксплуатации и обслуживании несамоходных плавучих снарядов и других плавучих средств',
  },
  {
    ID: 18077,
    label: 'Рисовальщик светящимися красками',
  },
  { ID: 18079, label: 'Рисовальщик эмалями' },
  { ID: 18080, label: 'Рисовод' },
  { ID: 18081, label: 'Рифлевщик' },
  {
    ID: 18083,
    label: 'Рихтовщик игольно-платинных изделий',
  },
  { ID: 18085, label: 'Рихтовщик кузовов' },
  { ID: 18087, label: 'Рубщик проволоки' },
  { ID: 18089, label: 'Рубщик судовой' },
  { ID: 18091, label: 'Рулевой (кормщик)' },
  { ID: 18093, label: 'Рыбак кефального хозяйства' },
  { ID: 18095, label: 'Рыбак прибрежного лова' },
  { ID: 18097, label: 'Рыбовод' },
  { ID: 18103, label: 'Садовник' },
  { ID: 18104, label: 'Садовод' },
  { ID: 18105, label: 'Садчик' },
  {
    ID: 18107,
    label: 'Садчик в печи и на туннельные вагоны',
  },
  {
    ID: 18109,
    label: 'Садчик камня в обжигательные печи',
  },
  { ID: 18111, label: 'Санитар ветеринарный' },
  { ID: 18112, label: 'Санитарка (мойщица)' },
  { ID: 18114, label: 'Сатураторщик' },
  { ID: 18116, label: 'Сборщик' },
  { ID: 18118, label: 'Сборщик алмазных инструментов' },
  { ID: 18120, label: 'Сборщик асбестоцементных плит' },
  {
    ID: 18121,
    label: 'Сборщик асбометаллических листов',
  },
  { ID: 18123, label: 'Сборщик баллонов' },
  { ID: 18125, label: 'Сборщик безбандажных шин' },
  { ID: 18129, label: 'Сборщик браслетов и брекеров' },
  { ID: 18130, label: 'Сборщик бумажных изделий' },
  { ID: 18134, label: 'Сборщик влагопоглотителей' },
  {
    ID: 18136,
    label: 'Сборщик восстанавливаемых покрышек',
  },
  { ID: 18138, label: 'Сборщик выпрямителей' },
  {
    ID: 18140,
    label: 'Сборщик гальванических элементов и батарей',
  },
  { ID: 18142, label: 'Сборщик деревянных судов' },
  { ID: 18144, label: 'Сборщик деталей и изделий' },
  { ID: 18145, label: 'Сборщик-достройщик судовой' },
  { ID: 18147, label: 'Сборщик духовых инструментов' },
  {
    ID: 18150,
    label: 'Сборщик железобетонных конструкций',
  },
  { ID: 18152, label: 'Сборщик железобетонных судов' },
  { ID: 18155, label: 'Сборщик игрушек' },
  { ID: 18157, label: 'Сборщик изделий' },
  {
    ID: 18159,
    label: 'Сборщик изделий из дерева и папье-маше',
  },
  { ID: 18161, label: 'Сборщик изделий из древесины' },
  { ID: 18163, label: 'Сборщик изделий из кожи и меха' },
  { ID: 18165, label: 'Сборщик изделий из пластмасс' },
  {
    ID: 18167,
    label: 'Сборщик изделий из стеклопластиков',
  },
  { ID: 18169, label: 'Сборщик изделий из янтаря' },
  {
    ID: 18170,
    label: 'Сборщик изделий электронной техники',
  },
  { ID: 18171, label: 'Сборщик индикаторов' },
  { ID: 18172, label: 'Сборщик инъекционных игл' },
  {
    ID: 18174,
    label: 'Сборщик каркасов в производстве гипсобетонных панелей',
  },
  {
    ID: 18176,
    label: 'Сборщик кассет для малогабаритных магнитофонов',
  },
  { ID: 18178, label: 'Сборщик квантовых приборов' },
  { ID: 18180, label: 'Сборщик кварцевых держателей' },
  { ID: 18181, label: 'Сборщик-клейщик конструкций' },
  { ID: 18183, label: 'Сборщик-клепальщик' },
  {
    ID: 18185,
    label: 'Сборщик кожгалантерейных изделий',
  },
  {
    ID: 18187,
    label: 'Сборщик корпусов металлических судов',
  },
  { ID: 18189, label: 'Сборщик лент' },
  { ID: 18193, label: 'Сборщик микросхем' },
  { ID: 18194, label: 'Сборщик мокрых отходов' },
  {
    ID: 18196,
    label: 'Сборщик-монтажник в производстве цветных кинескопов',
  },
  {
    ID: 18198,
    label: 'Сборщик-монтажник клавишных инструментов',
  },
  {
    ID: 18200,
    label: 'Сборщик-монтажник смычковых инструментов',
  },
  {
    ID: 18201,
    label: 'Сборщик-монтажник щипковых инструментов',
  },
  {
    ID: 18203,
    label: 'Сборщик музыкальных и озвученных игрушек',
  },
  {
    ID: 18205,
    label: 'Сборщик-настройщик магнитных систем',
  },
  { ID: 18207, label: 'Сборщик натуральных объектов' },
  { ID: 18211, label: 'Сборщик обмоток трансформаторов' },
  { ID: 18213, label: 'Сборщик обуви' },
  {
    ID: 18214,
    label: 'Сборщик-отдельщик катушек трансформаторов',
  },
  { ID: 18216, label: 'Сборщик очков' },
  { ID: 18218, label: 'Сборщик пакетов' },
  { ID: 18220, label: 'Сборщик пакетов конденсаторов' },
  { ID: 18222, label: 'Сборщик перевязочных материалов' },
  {
    ID: 18224,
    label: 'Сборщик пластин искусственной кожи',
  },
  { ID: 18226, label: 'Сборщик пластмассовых судов' },
  { ID: 18228, label: 'Сборщик плетеной мебели' },
  { ID: 18230, label: 'Сборщик покрышек' },
  {
    ID: 18232,
    label: 'Сборщик полимеризационного инвентаря',
  },
  {
    ID: 18233,
    label: 'Сборщик полупроводниковых приборов',
  },
  { ID: 18235, label: 'Сборщик по обрамлению стекла' },
  { ID: 18237, label: 'Сборщик приборов из стекла' },
  { ID: 18239, label: 'Сборщик пробковой пыли' },
  { ID: 18241, label: 'Сборщик пробковых изделий' },
  {
    ID: 18243,
    label: 'Сборщик продукции в аэрозольной упаковке',
  },
  {
    ID: 18245,
    label: 'Сборщик прядильных блоков и насосов',
  },
  {
    ID: 18247,
    label: 'Сборщик пьезорезонаторов и изделий на основе пьезоэлементов',
  },
  { ID: 18249, label: 'Сборщик радиодеталей' },
  {
    ID: 18253,
    label: 'Сборщик резиновых технических изделий',
  },
  { ID: 18255, label: 'Сборщик ртути' },
  {
    ID: 18256,
    label: 'Сборщик ртутно-цинковых, магниевых и других источников тока',
  },
  { ID: 18258, label: 'Сборщик ртутных выпрямителей' },
  { ID: 18260, label: 'Сборщик сборочных единиц часов' },
  {
    ID: 18262,
    label: 'Сборщик свинцовых аккумуляторов и батарей',
  },
  {
    ID: 18264,
    label: 'Сборщик сердечников трансформаторов',
  },
  {
    ID: 18265,
    label: 'Сборщик сильноточных конденсаторов',
  },
  { ID: 18267, label: 'Сборщик стеклоизделий' },
  { ID: 18269, label: 'Сборщик стеклопакетов' },
  {
    ID: 18271,
    label: 'Сборщик теплоизоляционных конструкций',
  },
  { ID: 18273, label: 'Сборщик термосов' },
  { ID: 18275, label: 'Сборщик тиглей' },
  {
    ID: 18277,
    label: 'Сборщик токоограничивающих реакторов',
  },
  { ID: 18279, label: 'Сборщик трансформаторов' },
  { ID: 18281, label: 'Сборщик ударных инструментов' },
  { ID: 18282, label: 'Сборщик фанерных труб' },
  {
    ID: 18284,
    label: 'Сборщик фарфоровых и фаянсовых изделий',
  },
  { ID: 18286, label: 'Сборщик форм' },
  {
    ID: 18288,
    label: 'Сборщик форм для флексографской печати',
  },
  {
    ID: 18290,
    label: 'Сборщик химаппаратуры и химоборудования',
  },
  {
    ID: 18292,
    label: 'Сборщик хирургических инструментов и аппаратов',
  },
  {
    ID: 18294,
    label: 'Сборщик цельнометаллических растров',
  },
  { ID: 18296, label: 'Сборщик часов' },
  { ID: 18298, label: 'Сборщик чемоданов из фанеры' },
  { ID: 18299, label: 'Сборщик шайб' },
  {
    ID: 18301,
    label: 'Сборщик шинно-пневматических муфт',
  },
  { ID: 18303, label: 'Сборщик шорно-седельных изделий' },
  { ID: 18305, label: 'Сборщик шприцев' },
  { ID: 18307, label: 'Сборщик штемпелей' },
  {
    ID: 18309,
    label: 'Сборщик щелевидных сит и металлических щеток',
  },
  {
    ID: 18310,
    label: 'Сборщик щелочных аккумуляторов и батарей',
  },
  {
    ID: 18312,
    label: 'Сборщик электрических машин и аппаратов',
  },
  { ID: 18314, label: 'Сборщик электроигр' },
  {
    ID: 18316,
    label: 'Сборщик электроизмерительных приборов',
  },
  {
    ID: 18318,
    label: 'Сборщик электрокерамических изделий',
  },
  {
    ID: 18320,
    label: 'Сборщик электро-угольного производства',
  },
  {
    ID: 18322,
    label: 'Сборщик эндокринно-ферментного сырья',
  },
  { ID: 18323, label: 'Сборщик эпителия' },
  { ID: 18325, label: 'Сборщик этажерочных вагонеток' },
  { ID: 18327, label: 'Сборщик язычковых инструментов' },
  {
    ID: 18329,
    label: 'Сварщик арматурных сеток и каркасов',
  },
  { ID: 18331, label: 'Сварщик выпрямителей' },
  {
    ID: 18333,
    label: 'Сварщик изделий из тугоплавких металлов',
  },
  {
    ID: 18334,
    label: 'Сварщик на диффузионно-сварочных установках',
  },
  { ID: 18336, label: 'Сварщик на лазерных установках' },
  {
    ID: 18338,
    label: 'Сварщик на машинах контактной (прессовой) сварки',
  },
  { ID: 18340, label: 'Сварщик на установках ТВЧ' },
  {
    ID: 18342,
    label: 'Сварщик на электронно-лучевых сварочных установках',
  },
  { ID: 18344, label: 'Сварщик печной сварки труб' },
  { ID: 18346, label: 'Сварщик пластмасс' },
  { ID: 18348, label: 'Сварщик стеклянных изделий' },
  { ID: 18350, label: 'Сварщик термитной сварки' },
  {
    ID: 18352,
    label: 'Сварщик швейных изделий на установках ТВЧ',
  },
  {
    ID: 18353,
    label: 'Сварщик электровакуумных приборов',
  },
  { ID: 18355, label: 'Сверловщик' },
  { ID: 18357, label: 'Сверловщик абразивных изделий' },
  {
    ID: 18359,
    label: 'Сверловщик затравочных пластин кварца',
  },
  { ID: 18361, label: 'Сверловщик камней' },
  { ID: 18363, label: 'Сверловщик оптических деталей' },
  { ID: 18365, label: 'Сверловщик-пневматик' },
  { ID: 18366, label: 'Сверловщик стеклоизделий' },
  {
    ID: 18368,
    label: 'Сверловщик электрокерамических изделий',
  },
  { ID: 18370, label: 'Светокопировщик' },
  { ID: 18372, label: 'Свиновод' },
  { ID: 18373, label: 'Свойлачивальщик' },
  { ID: 18375, label: 'Связывальщик пачек волокна' },
  { ID: 18377, label: 'Сгонщик-смывщик краски и лаков' },
  { ID: 18379, label: 'Сгустительщик' },
  {
    ID: 18380,
    label: 'Сгустительщик кожволокнистой массы',
  },
  { ID: 18382, label: 'Сдатчик готовой продукции' },
  {
    ID: 18384,
    label: 'Сдатчик экспортных лесоматериалов',
  },
  { ID: 18385, label: 'Сепараторщик' },
  { ID: 18386, label: 'Сепараторщик' },
  { ID: 18387, label: 'Сепараторщик' },
  { ID: 18388, label: 'Сепараторщик биомассы' },
  { ID: 18391, label: 'Сепараторщик шлифзерна' },
  { ID: 18393, label: 'Серебрильщик' },
  {
    ID: 18395,
    label: 'Серебрильщик пьезотехнических изделий',
  },
  { ID: 18399, label: 'Сетевязальщик' },
  { ID: 18401, label: 'Сигналист' },
  { ID: 18403, label: 'Сигнальщик боновый' },
  { ID: 18405, label: 'Силосник' },
  { ID: 18407, label: 'Синильщик' },
  { ID: 18409, label: 'Скиповой' },
  { ID: 18411, label: 'Скирдовальщик' },
  { ID: 18412, label: 'Складывальщик' },
  {
    ID: 18414,
    label: 'Склеивальщик нитей и текстильно-галантерейных изделий',
  },
  { ID: 18416, label: 'Склейщик' },
  {
    ID: 18418,
    label: 'Склейщик блоков, заготовок и строительных конструкций',
  },
  {
    ID: 18420,
    label: 'Склейщик керамических, фарфоровых и фаянсовых изделий',
  },
  {
    ID: 18422,
    label: 'Склейщик-окрасчик очковых оправ из пластмасс',
  },
  { ID: 18424, label: 'Склейщик оптических деталей' },
  { ID: 18425, label: 'Склейщик пьезоэлементов' },
  { ID: 18427, label: 'Склейщик технических камней' },
  { ID: 18429, label: 'Склейщик фанерных труб' },
  {
    ID: 18431,
    label: 'Склейщик электрокерамических изделий',
  },
  { ID: 18433, label: 'Скорняк-наборщик' },
  { ID: 18437, label: 'Скорняк-раскройщик' },
  { ID: 18439, label: 'Скрайбировщик пластин' },
  { ID: 18440, label: 'Скрубберщик-насосчик' },
  {
    ID: 18442,
    label: 'Скрутчик изделий кабельного производства',
  },
  {
    ID: 18444,
    label: 'Скрутчик-изолировщик жил и кабеля',
  },
  {
    ID: 18445,
    label: 'Скрутчик-изолировщик элементов кабелей связи',
  },
  {
    ID: 18447,
    label: 'Слесарь аварийно-восстановительных работ',
  },
  {
    ID: 18449,
    label: 'Слесарь аварийно-восстановительных работ в газовом хозяйстве',
  },
  { ID: 18450, label: 'Слесарь зумпфового агрегата' },
  { ID: 18452, label: 'Слесарь-инструментальщик' },
  { ID: 18454, label: 'Слесарь-испытатель' },
  {
    ID: 18458,
    label: 'Слесарь-механик по испытанию установок и аппаратуры',
  },
  {
    ID: 18460,
    label: 'Слесарь-механик по радиоэлектронной аппаратуре',
  },
  {
    ID: 18462,
    label: 'Слесарь-механик по ремонту авиационных приборов',
  },
  {
    ID: 18464,
    label: 'Слесарь-механик электромеханических приборов и систем',
  },
  { ID: 18466, label: 'Слесарь механосборочных работ' },
  {
    ID: 18468,
    label: 'Слесарь-монтажник приборного оборудования',
  },
  { ID: 18470, label: 'Слесарь-монтажник судовой' },
  { ID: 18476, label: 'Слесарь-опрессовщик' },
  {
    ID: 18477,
    label: 'Слесарь по аэрогидродинамическим испытаниям',
  },
  {
    ID: 18481,
    label: 'Слесарь по выводам и обмоткам электрических машин',
  },
  {
    ID: 18483,
    label:
      'Слесарь по изготовлению деталей и узлов систем вентиляции, кондиционирования воздуха, пневмотранспорта и аспирации',
  },
  {
    ID: 18485,
    label: 'Слесарь по изготовлению и доводке деталей летательных аппаратов',
  },
  {
    ID: 18487,
    label: 'Слесарь по изготовлению и ремонту трубопроводов',
  },
  {
    ID: 18489,
    label:
      'Слесарь по изготовлению узлов и деталей санитарно-технических систем',
  },
  {
    ID: 18492,
    label:
      'Слесарь по изготовлению узлов и деталей технологических трубопроводов',
  },
  {
    ID: 18494,
    label: 'Слесарь по контрольно-измерительным приборам и автоматике',
  },
  {
    ID: 18496,
    label: 'Слесарь по монтажу и ремонту оснований морских буровых и эстакад',
  },
  { ID: 18497, label: 'Слесарь по обслуживанию буровых' },
  {
    ID: 18499,
    label: 'Слесарь по обслуживанию оборудования электростанций',
  },
  {
    ID: 18503,
    label: 'Слесарь по обслуживанию тепловых пунктов',
  },
  {
    ID: 18505,
    label: 'Слесарь по обслуживанию тепловых сетей',
  },
  {
    ID: 18507,
    label:
      'Слесарь по осмотру и ремонту локомотивов на пунктах технического обслуживания',
  },
  {
    ID: 18509,
    label: 'Слесарь по ремонту авиадвигателей',
  },
  { ID: 18511, label: 'Слесарь по ремонту автомобилей' },
  { ID: 18513, label: 'Слесарь по ремонту агрегатов' },
  {
    ID: 18520,
    label: 'Слесарь по ремонту гидротурбинного оборудования',
  },
  {
    ID: 18522,
    label: 'Слесарь по ремонту дорожно-строительных машин и тракторов',
  },
  {
    ID: 18524,
    label: 'Слесарь по ремонту и обслуживанию перегрузочных машин',
  },
  {
    ID: 18526,
    label:
      'Слесарь по ремонту и обслуживанию систем вентиляции и кондиционирования',
  },
  {
    ID: 18528,
    label: 'Слесарь по ремонту лесозаготовительного оборудования',
  },
  {
    ID: 18529,
    label: 'Слесарь по ремонту летательных аппаратов',
  },
  {
    ID: 18531,
    label:
      'Слесарь по ремонту оборудования котельных и пылеприготовительных цехов',
  },
  {
    ID: 18535,
    label: 'Слесарь по ремонту оборудования тепловых сетей',
  },
  {
    ID: 18537,
    label: 'Слесарь по ремонту оборудования топливоподачи',
  },
  {
    ID: 18538,
    label: 'Слесарь по ремонту парогазотурбинного оборудования',
  },
  {
    ID: 18540,
    label: 'Слесарь по ремонту подвижного состава',
  },
  {
    ID: 18542,
    label: 'Слесарь по ремонту путевых машин и механизмов',
  },
  {
    ID: 18544,
    label: 'Слесарь по ремонту реакторно-турбинного оборудования',
  },
  {
    ID: 18545,
    label: 'Слесарь по ремонту сельскохозяйственных машин и оборудования',
  },
  {
    ID: 18547,
    label: 'Слесарь по ремонту технологических установок',
  },
  {
    ID: 18549,
    label: 'Слесарь по сборке металлоконструкций',
  },
  {
    ID: 18551,
    label: 'Слесарь по такелажу и грузозахватным приспособлениям',
  },
  { ID: 18552, label: 'Слесарь по топливной аппаратуре' },
  {
    ID: 18554,
    label: 'Слесарь по эксплуатации и ремонту газового оборудования',
  },
  {
    ID: 18556,
    label: 'Слесарь по эксплуатации и ремонту подземных газопроводов',
  },
  { ID: 18557, label: 'Слесарь-проводчик' },
  { ID: 18559, label: 'Слесарь-ремонтник' },
  { ID: 18560, label: 'Слесарь-сантехник' },
  {
    ID: 18561,
    label: 'Слесарь-сборщик авиационных приборов',
  },
  { ID: 18563, label: 'Слесарь-сборщик двигателей' },
  {
    ID: 18565,
    label: 'Слесарь-сборщик изделий из органического стекла',
  },
  {
    ID: 18567,
    label: 'Слесарь-сборщик летательных аппаратов',
  },
  {
    ID: 18569,
    label: 'Слесарь-сборщик радиоэлектронной аппаратуры и приборов',
  },
  {
    ID: 18572,
    label: 'Слесарь системы испарительного охлаждения',
  },
  { ID: 18576, label: 'Слесарь строительный' },
  { ID: 18577, label: 'Слесарь-судоремонтник' },
  {
    ID: 18581,
    label: 'Слесарь централизованной смазочной станции',
  },
  {
    ID: 18583,
    label:
      'Слесарь-электрик по обслуживанию и ремонту металлоконструкций метрополитена',
  },
  {
    ID: 18585,
    label:
      'Слесарь-электрик по обслуживанию и ремонту оборудования метрополитена',
  },
  {
    ID: 18587,
    label:
      'Слесарь-электрик по обслуживанию и ремонту станционного и тоннельного оборудования метрополитена',
  },
  {
    ID: 18589,
    label: 'Слесарь-электрик по обслуживанию и ремонту эскалаторов',
  },
  {
    ID: 18590,
    label: 'Слесарь-электрик по ремонту электрооборудования',
  },
  { ID: 18594, label: 'Слесарь электродной продукции' },
  { ID: 18596, label: 'Слесарь-электромонтажник' },
  { ID: 18598, label: 'Сливщик-разливщик' },
  { ID: 18600, label: 'Сливщик стекломассы' },
  { ID: 18602, label: 'Смазчик' },
  { ID: 18604, label: 'Смесильщик' },
  { ID: 18605, label: 'Смесительщик' },
  { ID: 18606, label: 'Смесительщик' },
  { ID: 18607, label: 'Смесительщик муки на силосах' },
  { ID: 18609, label: 'Смешивальщик волокна' },
  { ID: 18611, label: 'Смольщик берд' },
  { ID: 18612, label: 'Смольщик пакли' },
  { ID: 18614, label: 'Смотритель маяка' },
  { ID: 18616, label: 'Смотритель огней' },
  { ID: 18619, label: 'Сновальщик' },
  { ID: 18620, label: 'Сновальщик' },
  { ID: 18621, label: 'Собаковод' },
  { ID: 18623, label: 'Содовщик' },
  { ID: 18625, label: 'Солодовщик' },
  { ID: 18626, label: 'Сортировщик' },
  { ID: 18627, label: 'Сортировщик' },
  {
    ID: 18628,
    label: 'Сортировщик абразивных материалов',
  },
  { ID: 18629, label: 'Сортировщик алмазов' },
  { ID: 18630, label: 'Сортировщик алмазов' },
  { ID: 18632, label: 'Сортировщик бриллиантов' },
  {
    ID: 18634,
    label: 'Сортировщик бумажного производства',
  },
  {
    ID: 18636,
    label: 'Сортировщик в производстве карандашей',
  },
  {
    ID: 18637,
    label: 'Сортировщик в производстве пищевой продукции',
  },
  {
    ID: 18639,
    label: 'Сортировщик декоративных пород дерева',
  },
  { ID: 18641, label: 'Сортировщик деталей подшипников' },
  {
    ID: 18643,
    label: 'Сортировщик деталей часов и камней',
  },
  { ID: 18644, label: 'Сортировщик древесины на воде' },
  { ID: 18646, label: 'Сортировщик жести и изделий' },
  {
    ID: 18648,
    label: 'Сортировщик игольно-платинных изделий',
  },
  { ID: 18649, label: 'Сортировщик извести' },
  {
    ID: 18651,
    label: 'Сортировщик изделий, полуфабрикатов и материалов',
  },
  {
    ID: 18654,
    label: 'Сортировщик изделий, сырья и материалов',
  },
  { ID: 18656, label: 'Сортировщик керамзита' },
  {
    ID: 18658,
    label: 'Сортировщик кожевенно-мехового сырья',
  },
  { ID: 18660, label: 'Сортировщик кокса' },
  { ID: 18662, label: 'Сортировщик контейнеров' },
  { ID: 18664, label: 'Сортировщик кости' },
  {
    ID: 18665,
    label: 'Сортировщик куска на печах сопротивления',
  },
  {
    ID: 18667,
    label: 'Сортировщик материалов и изделий из древесины',
  },
  { ID: 18669, label: 'Сортировщик немытой шерсти' },
  { ID: 18671, label: 'Сортировщик отливок' },
  {
    ID: 18673,
    label: 'Сортировщик полуфабриката и изделий',
  },
  {
    ID: 18674,
    label: 'Сортировщик почтовых отправлений и произведений печати',
  },
  { ID: 18676, label: 'Сортировщик-разборщик чая' },
  { ID: 18677, label: 'Сортировщик рога и кости' },
  {
    ID: 18679,
    label: 'Сортировщик-сборщик лома и отходов металла',
  },
  { ID: 18681, label: 'Сортировщик-сдатчик металла' },
  { ID: 18683, label: 'Сортировщик сигарного листа' },
  { ID: 18685, label: 'Сортировщик сырья и волокна' },
  {
    ID: 18686,
    label: 'Сортировщик сырья и изделий из слюды',
  },
  {
    ID: 18688,
    label: 'Сортировщик сырья, материалов и изделий',
  },
  {
    ID: 18690,
    label: 'Сортировщик сырья, фарфоровых, фаянсовых и керамических изделий',
  },
  { ID: 18692, label: 'Сортировщик табака' },
  {
    ID: 18693,
    label: 'Сортировщик табака в ферментационном производстве',
  },
  { ID: 18695, label: 'Сортировщик табачных изделий' },
  {
    ID: 18697,
    label: 'Сортировщик тушек птицы и кроликов',
  },
  {
    ID: 18698,
    label: 'Сортировщик (упаковщик) теплоизоляционных изделий',
  },
  { ID: 18700, label: 'Сортировщик шкур' },
  { ID: 18702, label: 'Сортировщик шкурок кроликов' },
  { ID: 18703, label: 'Сортировщик шпона и фанеры' },
  { ID: 18705, label: 'Сортировщик электродов' },
  {
    ID: 18707,
    label: 'Сортировщик электроизоляционных материалов',
  },
  {
    ID: 18709,
    label: 'Сортировщик электро-угольных изделий',
  },
  {
    ID: 18711,
    label: 'Составитель аппретур, эмульсий и лаков',
  },
  {
    ID: 18713,
    label: 'Составитель вагонных партий табака',
  },
  { ID: 18715, label: 'Составитель коллагеновой массы' },
  { ID: 18717, label: 'Составитель массы на мешалках' },
  {
    ID: 18719,
    label: 'Составитель навесок ингредиентов',
  },
  { ID: 18721, label: 'Составитель обмазки' },
  {
    ID: 18722,
    label: 'Составитель описи объектов населенных пунктов',
  },
  { ID: 18723, label: 'Составитель пасты' },
  { ID: 18724, label: 'Составитель перопуховой смеси' },
  { ID: 18726, label: 'Составитель поездов' },
  { ID: 18728, label: 'Составитель смесей' },
  {
    ID: 18730,
    label: 'Составитель смеси моющих средств',
  },
  { ID: 18735, label: 'Составитель фарша' },
  { ID: 18736, label: 'Составитель фтористых присадок' },
  {
    ID: 18738,
    label: 'Составитель химических растворов',
  },
  { ID: 18740, label: 'Составщик шихты' },
  {
    ID: 18742,
    label: 'Спекальщик инструментов из алмазов и сверхтвердых материалов',
  },
  { ID: 18743, label: 'Спекальщик кювет' },
  {
    ID: 18745,
    label: 'Спекальщик ленточных сердечников',
  },
  { ID: 18747, label: 'Спекальщик стекловолокна' },
  { ID: 18749, label: 'Спекальщик' },
  { ID: 18751, label: 'Сплавщик' },
  { ID: 18753, label: 'Сплавщик отходов' },
  { ID: 18755, label: 'Сплотчик' },
  { ID: 18756, label: 'Средовар' },
  {
    ID: 18758,
    label: 'Стабилизаторщик-дефибринировщик крови',
  },
  { ID: 18760, label: 'Стабилизировщик кабелей' },
  { ID: 18762, label: 'Ставильщик' },
  { ID: 18763, label: 'Ставильщик' },
  {
    ID: 18764,
    label: 'Ставильщик-выборщик изделий из печей',
  },
  {
    ID: 18766,
    label:
      'Ставильщик-выборщик фарфоровых, фаянсовых и керамических изделий на вагонетках',
  },
  {
    ID: 18767,
    label: 'Ставильщик стеклоизделий в автоклавы',
  },
  { ID: 18769, label: 'Сталевар вакуумной печи' },
  { ID: 18771, label: 'Сталевар конвертера' },
  { ID: 18773, label: 'Сталевар мартеновской печи' },
  {
    ID: 18777,
    label: 'Сталевар установки внепечной обработки стали',
  },
  {
    ID: 18779,
    label: 'Сталевар установки электрошлакового переплава',
  },
  { ID: 18781, label: 'Сталевар электропечи' },
  {
    ID: 18783,
    label: 'Станочник деревообрабатывающих станков',
  },
  {
    ID: 18784,
    label: 'Станочник жестяно-баночного оборудования',
  },
  { ID: 18786, label: 'Станочник клеенаносящего станка' },
  {
    ID: 18788,
    label: 'Станочник кромкофуговального станка',
  },
  { ID: 18790, label: 'Станочник навивочного станка' },
  {
    ID: 18792,
    label: 'Станочник на механической обработке электродной продукции',
  },
  {
    ID: 18794,
    label: 'Станочник на обработке твердосплавной продукции',
  },
  {
    ID: 18796,
    label: 'Станочник по изготовлению гнутой мебели',
  },
  { ID: 18798, label: 'Станочник по обработке керамики' },
  { ID: 18800, label: 'Станочник-распиловшик' },
  {
    ID: 18801,
    label: 'Станочник ребросклеивающего станка',
  },
  {
    ID: 18803,
    label: 'Станочник специальных деревообрабатывающих станков',
  },
  {
    ID: 18805,
    label: 'Станочник специальных металлообрабатывающих станков',
  },
  { ID: 18807, label: 'Станочник усовочного станка' },
  { ID: 18809, label: 'Станочник широкого профиля' },
  { ID: 18810, label: 'Станочник шпалорезного станка' },
  { ID: 18812, label: 'Станционный рабочий' },
  {
    ID: 18826,
    label: 'Старший машинист котельного оборудования',
  },
  {
    ID: 18828,
    label: 'Старший машинист котлотурбинного цеха',
  },
  {
    ID: 18830,
    label: 'Старший машинист турбинного отделения',
  },
  { ID: 18832, label: 'Старший машинист энергоблоков' },
  { ID: 18850, label: 'Стволовой (подземный)' },
  { ID: 18852, label: 'Стекловар' },
  { ID: 18854, label: 'Стеклографист (ротаторщик)' },
  { ID: 18856, label: 'Стеклодув' },
  { ID: 18858, label: 'Стеклопротирщик' },
  { ID: 18859, label: 'Стекольщик' },
  { ID: 18861, label: 'Стендовщик' },
  { ID: 18863, label: 'Стереотипер' },
  { ID: 18865, label: 'Стерженщик машинной формовки' },
  { ID: 18867, label: 'Стерженщик ручной формовки' },
  { ID: 18869, label: 'Стерилизаторщик ваты' },
  {
    ID: 18871,
    label: 'Стерилизаторщик материалов и препаратов',
  },
  {
    ID: 18872,
    label: 'Стерилизаторщик питательных сред',
  },
  { ID: 18874, label: 'Столяр' },
  {
    ID: 18876,
    label: 'Столяр по изготовлению декораций',
  },
  {
    ID: 18878,
    label:
      'Столяр по изготовлению и ремонту деталей и узлов музыкальных инструментов',
  },
  { ID: 18880, label: 'Столяр строительный' },
  { ID: 18881, label: 'Столяр судовой' },
  { ID: 18883, label: 'Сторож (вахтер)' },
  { ID: 18885, label: 'Стрелок' },
  { ID: 18887, label: 'Стригальщик ворса' },
  {
    ID: 18889,
    label: 'Строгаль кожевенно-мехового сырья и полуфабрикатов',
  },
  { ID: 18891, label: 'Строгальщик' },
  { ID: 18894, label: 'Строгальщик пластмасс' },
  { ID: 18897, label: 'Стропальшик' },
  { ID: 18899, label: 'Струнонавивальщик' },
  { ID: 18901, label: 'Струнщик' },
  { ID: 18903, label: 'Стыковщик полос' },
  { ID: 18905, label: 'Стыковщик резиновых изделий' },
  { ID: 18906, label: 'Стыковщик текстиля на прессе' },
  { ID: 18908, label: 'Судокорпусник-ремонтник' },
  { ID: 18910, label: 'Судопропускник' },
  { ID: 18912, label: 'Сукновал' },
  { ID: 18913, label: 'Сульфатчик' },
  { ID: 18914, label: 'Сушильщик' },
  { ID: 18915, label: 'Сушильщик' },
  { ID: 18916, label: 'Сушильщик' },
  { ID: 18917, label: 'Сушильщик' },
  { ID: 18918, label: 'Сушильщик абразивных изделий' },
  {
    ID: 18920,
    label: 'Сушильщик асбестоцементных изделий',
  },
  {
    ID: 18922,
    label: 'Сушильщик бумаги, картона; фибры и изделий из них',
  },
  {
    ID: 18924,
    label: 'Сушильщик бумагоделательной (картоноделательной) машины',
  },
  {
    ID: 18926,
    label: 'Сушильщик вакуум-формующей машины',
  },
  { ID: 18928, label: 'Сушильщик ванилина' },
  { ID: 18929, label: 'Сушильщик девулканизата' },
  { ID: 18931, label: 'Сушильщик деталей и игрушек' },
  { ID: 18933, label: 'Сушильщик деталей и приборов' },
  {
    ID: 18935,
    label: 'Сушильшик длиннотрубчатых макарон',
  },
  { ID: 18937, label: 'Сушильщик дощечек' },
  { ID: 18938, label: 'Сушильщик дрожжей' },
  { ID: 18940, label: 'Сушильщик жести' },
  {
    ID: 18942,
    label: 'Сушильщик заготовок и художественных изделий',
  },
  { ID: 18944, label: 'Сушильщик заполнителей' },
  { ID: 18945, label: 'Сушильщик (заправщик)' },
  { ID: 18946, label: 'Сушильщик изделий' },
  { ID: 18947, label: 'Сушильщик изделий' },
  { ID: 18948, label: 'Сушильщик изделий' },
  {
    ID: 18949,
    label: 'Сушильщик клеильно-сушильной машины',
  },
  {
    ID: 18951,
    label: 'Сушильщик компонентов обмазки и флюсов',
  },
  { ID: 18953, label: 'Сушильщик линолеума' },
  { ID: 18954, label: 'Сушильщик лубяного сырья' },
  { ID: 18956, label: 'Сушильщик махорочной крошки' },
  {
    ID: 18958,
    label: 'Сушильщик машины длинноволокнистой бумаги',
  },
  { ID: 18960, label: 'Сушильщик молочного сахара' },
  { ID: 18962, label: 'Сушильщик отходов' },
  { ID: 18964, label: 'Сушильщик пакетов конденсаторов' },
  { ID: 18966, label: 'Сушильщик пергаментной машины' },
  { ID: 18967, label: 'Сушильщик перопухового сырья' },
  { ID: 18969, label: 'Сушильщик пищевой продукции' },
  { ID: 18971, label: 'Сушильщик пленки бутафоль' },
  { ID: 18973, label: 'Сушильщик посыпочных материалов' },
  { ID: 18975, label: 'Сушильщик пресспата' },
  { ID: 18977, label: 'Сушильщик растений' },
  { ID: 18979, label: 'Сушильщик ровничной машины' },
  { ID: 18981, label: 'Сушильщик стеклоизделий' },
  { ID: 18983, label: 'Сушильщик стержней' },
  {
    ID: 18984,
    label: 'Сушильщик стержней, форм и формовочных материалов',
  },
  { ID: 18986, label: 'Сушильщик сырья и материалов' },
  {
    ID: 18988,
    label: 'Сушильщик сырья, полуфабрикатов и изделий',
  },
  {
    ID: 18992,
    label: 'Сушильщик теплоизоляционных изделий',
  },
  {
    ID: 18994,
    label: 'Сушильщик фарфоровых, фаянсовых, керамических изделий и сырья',
  },
  { ID: 18996, label: 'Сушильщик шкурок кроликов' },
  {
    ID: 18998,
    label: 'Сушильщик шлифзерна, шлифпорошков и шихтовых материалов',
  },
  { ID: 18999, label: 'Сушильщик шпона и фанеры' },
  { ID: 19001, label: 'Сушильщик электродов' },
  {
    ID: 19003,
    label: 'Сушильщик элементного производства',
  },
  { ID: 19005, label: 'Счетчик' },
  { ID: 19007, label: 'Счетчик меры и изделий' },
  { ID: 19009, label: 'Сшивальщик металлосеток' },
  { ID: 19010, label: 'Сшивальщик-проклейщик' },
  { ID: 19012, label: 'Съемщик байки' },
  { ID: 19014, label: 'Съемщик брикетов' },
  { ID: 19015, label: 'Съемщик волокна' },
  { ID: 19016, label: 'Съемщик волокна' },
  { ID: 19017, label: 'Съемщик горячих изделий' },
  { ID: 19019, label: 'Съемщик диапозитивных фильмов' },
  { ID: 19021, label: 'Съемщик изделий' },
  { ID: 19022, label: 'Съемщик изделий' },
  { ID: 19023, label: 'Съемщик клея' },
  {
    ID: 19025,
    label: 'Съемщик лент скоростемеров локомотивов',
  },
  { ID: 19027, label: 'Съемщик мультипликационных проб' },
  {
    ID: 19029,
    label: 'Съемщик оболочек с кабельных изделий',
  },
  { ID: 19030, label: 'Съемщик обуви с колодок' },
  { ID: 19031, label: 'Съемщик обуви с колодок' },
  {
    ID: 19032,
    label: 'Съемщик оптических характеристик',
  },
  {
    ID: 19034,
    label: 'Съемщик политого стекла и фотопластинок',
  },
  { ID: 19036, label: 'Съемщик-раскройщик металлосеток' },
  { ID: 19038, label: 'Съемщик резиновых изделий' },
  {
    ID: 19041,
    label: 'Съемщик свинцовой оболочки с рукавов',
  },
  { ID: 19043, label: 'Съемщик стекла и стеклоизделий' },
  {
    ID: 19045,
    label: 'Съемщик стеклопластиковых и стекловолокнистых изделий',
  },
  { ID: 19046, label: 'Съемщик суровья' },
  {
    ID: 19050,
    label: 'Съемщик теплоизоляционных изделий',
  },
  {
    ID: 19052,
    label: 'Съемщик-укладчик асбестоцементных изделий',
  },
  {
    ID: 19054,
    label: 'Съемщик-укладчик асфальтовых плиток',
  },
  {
    ID: 19056,
    label: 'Съемщик-укладчик в производстве стеновых и вяжущих материалов',
  },
  {
    ID: 19058,
    label: 'Съемщик-укладчик заготовок, массы и готовых изделий',
  },
  {
    ID: 19060,
    label: 'Съемщик-укладчик фарфоровых, фаянсовых и керамических изделий',
  },
  {
    ID: 19062,
    label: 'Съемщик целлюлозы, бумаги, картона и изделий из них',
  },
  { ID: 19064, label: 'Съемщик шелка-сырца' },
  { ID: 19067, label: 'Сыродел' },
  { ID: 19068, label: 'Сыродел-мастер' },
  {
    ID: 19070,
    label: 'Сыродел-мастер по созреванию сыров',
  },
  { ID: 19072, label: 'Сыродел по созреванию сыров' },
  { ID: 19074, label: 'Сыросол' },
  { ID: 19077, label: 'Табаковод' },
  { ID: 19079, label: 'Таблетировщик' },
  { ID: 19081, label: 'Такелажник' },
  { ID: 19083, label: 'Такелажник на монтаже' },
  { ID: 19085, label: 'Такелажник судовой' },
  { ID: 19087, label: 'Таксидермист' },
  { ID: 19089, label: 'Талькировщик листов и лент' },
  { ID: 19090, label: 'Тальман' },
  { ID: 19091, label: 'Телеграфист' },
  { ID: 19093, label: 'Телефонист' },
  { ID: 19098, label: 'Темперировщик жировой основы' },
  { ID: 19100, label: 'Термист' },
  {
    ID: 19102,
    label: 'Термист купроксных и селеновых выпрямителей',
  },
  { ID: 19104, label: 'Термист на установках ТВЧ' },
  { ID: 19106, label: 'Термист нафталиновых печей' },
  { ID: 19108, label: 'Термист по обработке слюды' },
  { ID: 19110, label: 'Термист проката и труб' },
  { ID: 19111, label: 'Термист холодом' },
  {
    ID: 19113,
    label: 'Термообработчик древесно-волокнистых плит',
  },
  {
    ID: 19115,
    label: 'Термообработчик проводов и кабелей',
  },
  { ID: 19117, label: 'Термоотделочник меховых шкурок' },
  { ID: 19119, label: 'Термоотделочник пряжи' },
  {
    ID: 19121,
    label: 'Термоотделочник текстильно-галантерейных изделий',
  },
  { ID: 19123, label: 'Термоотделочник швейных изделий' },
  { ID: 19124, label: 'Термоотделочник щетины и волоса' },
  { ID: 19126, label: 'Термопечатник' },
  { ID: 19128, label: 'Термопластикаторщик' },
  { ID: 19130, label: 'Терморезчик' },
  { ID: 19131, label: 'Термостатчик' },
  { ID: 19132, label: 'Термостатчик' },
  { ID: 19134, label: 'Термоусадчик' },
  { ID: 19136, label: 'Тесемщик' },
  { ID: 19137, label: 'Тестовод' },
  { ID: 19139, label: 'Тесчик спецсортиментов' },
  { ID: 19141, label: 'Тиснильщик рисунка' },
  { ID: 19143, label: 'Ткач' },
  { ID: 19149, label: 'Токарь' },
  { ID: 19151, label: 'Токарь-затыловщик' },
  { ID: 19153, label: 'Токарь-карусельщик' },
  { ID: 19156, label: 'Токарь по камню' },
  { ID: 19158, label: 'Токарь-полуавтоматчик' },
  {
    ID: 19160,
    label: 'Токарь по обработке абразивных изделий',
  },
  {
    ID: 19161,
    label: 'Токарь по обработке асбестоцементных труб и муфт',
  },
  { ID: 19163, label: 'Токарь-расточник' },
  { ID: 19165, label: 'Токарь-револьверщик' },
  { ID: 19167, label: 'Тоннельный рабочий' },
  { ID: 19169, label: 'Тоннельщик' },
  { ID: 19170, label: 'Тоннельщик' },
  {
    ID: 19171,
    label: 'Тоннельщик-моторист скипового подъемника',
  },
  { ID: 19173, label: 'Торфорабочий' },
  { ID: 19177, label: 'Точильщик кардной гарнитуры' },
  { ID: 19179, label: 'Точильщик стригальных ножей' },
  { ID: 19180, label: 'Точильщик шляпок' },
  { ID: 19182, label: 'Травильщик' },
  { ID: 19184, label: 'Травильщик валов' },
  { ID: 19186, label: 'Травильщик клише' },
  {
    ID: 19188,
    label: 'Травильщик купроксных выпрямительных элементов',
  },
  {
    ID: 19190,
    label: 'Травильщик прецизионного травления',
  },
  { ID: 19192, label: 'Травильщик радиокерамики' },
  {
    ID: 19194,
    label: 'Травильщик синтетических материалов на тканевой основе',
  },
  {
    ID: 19196,
    label: 'Травильщик стекла плавиковой кислотой',
  },
  {
    ID: 19198,
    label: 'Травильщик фарфоровых и фаянсовых изделий',
  },
  { ID: 19199, label: 'Травильщик фольги' },
  { ID: 19200, label: 'Травильщик фольги' },
  { ID: 19201, label: 'Травильщик форм глубокой печати' },
  { ID: 19203, label: 'Тракторист' },
  {
    ID: 19204,
    label: 'Тракторист по подготовке лесосек, трелевке и вывозке леса',
  },
  {
    ID: 19205,
    label: 'Тракторист-машинист сельскохозяйственного производства',
  },
  {
    ID: 19209,
    label: 'Трамбовщик изоляционного материала',
  },
  {
    ID: 19211,
    label: 'Трамбовщик огнеприпасов из карборунда',
  },
  { ID: 19213, label: 'Транспортерщик' },
  {
    ID: 19215,
    label: 'Транспортерщик горячего клинкера',
  },
  { ID: 19217, label: 'Транспортировщик' },
  {
    ID: 19219,
    label: 'Транспортировщик в литейном производстве',
  },
  { ID: 19220, label: 'Транспортировщик шихты' },
  { ID: 19222, label: 'Трафаретчик' },
  { ID: 19223, label: 'Трафаретчик' },
  { ID: 19224, label: 'Трелевщик' },
  { ID: 19226, label: 'Тренер лошадей' },
  { ID: 19228, label: 'Тренировщик радиодеталей' },
  { ID: 19230, label: 'Трубник на солекомбайне' },
  { ID: 19231, label: 'Трубогибщик судовой' },
  {
    ID: 19233,
    label: 'Трубоклад промышленных железобетонных труб',
  },
  {
    ID: 19234,
    label: 'Трубоклад промышленных кирпичных труб',
  },
  { ID: 19236, label: 'Труболитейщик-формовщик' },
  { ID: 19238, label: 'Трубопроводчик линейный' },
  { ID: 19240, label: 'Трубопроводчик судовой' },
  { ID: 19242, label: 'Трубопрокатчик' },
  { ID: 19243, label: 'Тузлуковщик шкур' },
  {
    ID: 19245,
    label: 'Туннелепрокладчик в бунтах хлопка-сырца',
  },
  { ID: 19247, label: 'Тянульщик кож' },
  {
    ID: 19249,
    label: 'Тянульщик по выработке стеклянных труб и дрота',
  },
  { ID: 19252, label: 'Уборщик в литейных цехах' },
  { ID: 19254, label: 'Уборщик горячего металла' },
  { ID: 19255, label: 'Уборщик мусоропроводов' },
  {
    ID: 19257,
    label: 'Уборщик отходов металлургического производства',
  },
  {
    ID: 19258,
    label: 'Уборщик производственных и служебных помещений',
  },
  { ID: 19262, label: 'Уборщик территорий' },
  {
    ID: 19263,
    label: 'Уборщик шлака и оборотных материалов',
  },
  {
    ID: 19265,
    label: 'Увлажнитель кожаных, меховых деталей и изделий',
  },
  { ID: 19267, label: 'Увлажнительщик бумаги и картона' },
  {
    ID: 19268,
    label: 'Увлажняльщик кожевенных картонов',
  },
  { ID: 19270, label: 'Увлажняльщик махорочного сырья' },
  { ID: 19272, label: 'Увлажняльщик сырья' },
  { ID: 19273, label: 'Увлажняльщик табачного сырья' },
  {
    ID: 19275,
    label: 'Укладчик-выбиральщик мокрого товара и пряжи вручную',
  },
  { ID: 19277, label: 'Укладчик деталей и изделий' },
  { ID: 19278, label: 'Укладчик диапозитивных фильмов' },
  {
    ID: 19279,
    label: 'Укладчик-заливщик продуктов консервирования',
  },
  { ID: 19280, label: 'Укладчик изделий' },
  {
    ID: 19281,
    label: 'Укладчик пиломатериалов, деталей и изделий из древесины',
  },
  {
    ID: 19285,
    label: 'Укладчик продукции медицинского назначения',
  },
  { ID: 19287, label: 'Укладчик проката' },
  { ID: 19288, label: 'Укладчик синели' },
  { ID: 19290, label: 'Укладчик стеклонити в изделия' },
  { ID: 19291, label: 'Укладчик сырья' },
  { ID: 19293, label: 'Укладчик-упаковщик' },
  { ID: 19294, label: 'Укладчик хлебобулочных изделий' },
  { ID: 19296, label: 'Униформист' },
  { ID: 19298, label: 'Упаковщик кип' },
  { ID: 19300, label: 'Упаковщик цемента' },
  { ID: 19302, label: 'Упаковщик-цементировщик' },
  { ID: 19304, label: 'Упаковщик электродов' },
  { ID: 19306, label: 'Уплотнитель припоя' },
  { ID: 19307, label: 'Упрочнитель деталей' },
  { ID: 19309, label: 'Усреднильщик сырья' },
  { ID: 19310, label: 'Установщик алмазов' },
  { ID: 19312, label: 'Установщик бурильных замков' },
  { ID: 19313, label: 'Установщик декораций' },
  {
    ID: 19315,
    label: 'Установщик изделий в эмалировании',
  },
  { ID: 19317, label: 'Установщик катализаторных сеток' },
  { ID: 19319, label: 'Установщик ладовых пластин' },
  {
    ID: 19321,
    label: 'Установщик прядильных блоков и гарнитуры',
  },
  {
    ID: 19323,
    label: 'Установщик стеклоплавильных сосудов',
  },
  { ID: 19324, label: 'Установщик фильерных пластин' },
  {
    ID: 19326,
    label: 'Установщик художественных произведений',
  },
  { ID: 19328, label: 'Установщик цвета и света' },
  {
    ID: 19331,
    label: 'Фанеровщик художественных изделий из дерева',
  },
  { ID: 19335, label: 'Фаршировщик овощей' },
  { ID: 19336, label: 'Фасовщик пучков щетины и волоса' },
  { ID: 19337, label: 'Фасовщица' },
  { ID: 19338, label: 'Фацетчик' },
  { ID: 19339, label: 'Фельдъегерь' },
  { ID: 19340, label: 'Фенольщик' },
  { ID: 19342, label: 'Ферментаторщик' },
  { ID: 19343, label: 'Ферментировщик чая' },
  { ID: 19345, label: 'Фибровщик' },
  { ID: 19346, label: 'Фидерщик' },
  { ID: 19348, label: 'Фиксаторщик' },
  { ID: 19350, label: 'Филигранщик бумаги' },
  { ID: 19351, label: 'Фильерщик' },
  { ID: 19353, label: 'Фильмопроверщик' },
  { ID: 19355, label: 'Фильмотекарь' },
  { ID: 19356, label: 'Фильтровальщик' },
  { ID: 19357, label: 'Фильтрпрессовщик' },
  { ID: 19358, label: 'Фильтрпрессовщик' },
  { ID: 19360, label: 'Флипперовщик бортовых колец' },
  { ID: 19362, label: 'Флотатор' },
  { ID: 19363, label: 'Флотаторщик' },
  { ID: 19364, label: 'Флотаторщик' },
  { ID: 19365, label: 'Флюсовар' },
  { ID: 19366, label: 'Флюсовщик' },
  { ID: 19368, label: 'Фонотекарь' },
  {
    ID: 19369,
    label: 'Форматор скульптурного производства',
  },
  { ID: 19371, label: 'Формировщик плотов' },
  { ID: 19373, label: 'Формировщик трикотажных изделий' },
  {
    ID: 19375,
    label: 'Формовщик абразивных изделий на керамической связке',
  },
  {
    ID: 19376,
    label:
      'Формовщик абразивных изделий на бакелитовой, вулканитовой и эпоксидной связках',
  },
  { ID: 19378, label: 'Формовщик анодов' },
  {
    ID: 19379,
    label: 'Формовщик асбестоцементных изделий',
  },
  { ID: 19382, label: 'Формовщик асфальтовых плиток' },
  { ID: 19386, label: 'Формоащик брикетов' },
  {
    ID: 19387,
    label: 'Формовщик буртов фаолитовых труб',
  },
  {
    ID: 19389,
    label: 'Формовщик на производстве стеновых и вяжущих материалов',
  },
  { ID: 19392, label: 'Формовщик головных уборов' },
  { ID: 19393, label: 'Формовщик головных уборов' },
  { ID: 19394, label: 'Формовщик деталей из стекла' },
  { ID: 19395, label: 'Формовщик деталей и игрушек' },
  { ID: 19397, label: 'Формовщик деталей и изделий' },
  {
    ID: 19399,
    label: 'Формовщик железобетонных изделий и конструкций',
  },
  {
    ID: 19400,
    label: 'Формовщик изделий из вспенивающихся материалов',
  },
  {
    ID: 19402,
    label: 'Формовщик изделий строительной керамики',
  },
  { ID: 19404, label: 'Формовщик искусственных зубов' },
  {
    ID: 19406,
    label: 'Формовщик камнелитейного производства',
  },
  { ID: 19407, label: 'Формовщик капселей' },
  { ID: 19409, label: 'Формовщик колбасных изделий' },
  { ID: 19411, label: 'Формовщик машинной формовки' },
  {
    ID: 19413,
    label: 'Формовщик медицинских препаратов, полуфабрикатов и изделий',
  },
  { ID: 19416, label: 'Формовщик огнеупорных изделий' },
  { ID: 19418, label: 'Формовщик пакетов' },
  { ID: 19419, label: 'Формовщик пенальной косметики' },
  {
    ID: 19421,
    label: 'Формовщик по выплавляемым моделям',
  },
  { ID: 19423, label: 'Формовщик покрышек' },
  { ID: 19425, label: 'Формовщик радиокерамики' },
  {
    ID: 19426,
    label: 'Формовщик разделительных и декоративных слоев',
  },
  { ID: 19428, label: 'Формовщик ртутных выпрямителей' },
  { ID: 19430, label: 'Формовщик ручной формовки' },
  { ID: 19432, label: 'Формовщик селеновых элементов' },
  {
    ID: 19433,
    label: 'Формовщик стеклопластиковых изделий',
  },
  { ID: 19435, label: 'Формовщик сыра' },
  { ID: 19437, label: 'Формовщик текстильных изделий' },
  {
    ID: 19439,
    label: 'Формовщик теплоизоляционных изделий',
  },
  { ID: 19441, label: 'Формовщик теста' },
  {
    ID: 19442,
    label: 'Формовщик фарфоровых и фаянсовых изделий',
  },
  { ID: 19444, label: 'Формовщик фильтр-пластин' },
  { ID: 19445, label: 'Формовщик фольги' },
  {
    ID: 19447,
    label: 'Формовщик форм для наглядных пособий',
  },
  { ID: 19449, label: 'Формовщик художественного питья' },
  { ID: 19451, label: 'Формовщик электродной массы' },
  {
    ID: 19452,
    label: 'Формовщик электрокерамических изделий',
  },
  { ID: 19454, label: 'Формодержатель' },
  { ID: 19455, label: 'Форсунщик' },
  { ID: 19457, label: 'Фосфатировщик' },
  { ID: 19459, label: 'Фотограф' },
  { ID: 19460, label: 'Фотограф' },
  {
    ID: 19461,
    label: 'Фотограф прецизионной фотолитографии',
  },
  {
    ID: 19464,
    label: 'Фотограф фотомеханического гравирования',
  },
  { ID: 19465, label: 'Фотокерамик' },
  { ID: 19467, label: 'Фотолаборант' },
  { ID: 19469, label: 'Фотооператор' },
  { ID: 19471, label: 'Фотопечатник на стекле' },
  { ID: 19472, label: 'Фотопечатник по эмали' },
  { ID: 19474, label: 'Фотоплазокопировщик' },
  { ID: 19476, label: 'Фототекарь' },
  { ID: 19477, label: 'Фотоцинкограф' },
  { ID: 19479, label: 'Фрезеровщик' },
  {
    ID: 19481,
    label: 'Фрезеровщик асбестоцементных плит',
  },
  { ID: 19483, label: 'Фрезеровщик камня' },
  { ID: 19485, label: 'Фрезеровщик обуви' },
  { ID: 19486, label: 'Фрезеровщик оптических деталей' },
  { ID: 19488, label: 'Фрезеровщик слитков' },
  { ID: 19489, label: 'Фризерщик' },
  { ID: 19490, label: 'Фризерщик' },
  { ID: 19491, label: 'Фриттовщик' },
  { ID: 19492, label: 'Фриттовщик' },
  { ID: 19493, label: 'Фурнитурщик' },
  { ID: 19494, label: 'Футеровщик-каменщик' },
  { ID: 19496, label: 'Футеровщик (кислотоупорщик)' },
  {
    ID: 19497,
    label: 'Футеровщик-шамотчик на ремонте ванн',
  },
  { ID: 19499, label: 'Футлярщик' },
  { ID: 19502, label: 'Халвомес' },
  { ID: 19503, label: 'Хальмовщик' },
  { ID: 19505, label: 'Хлораторщик' },
  {
    ID: 19506,
    label: 'Хлораторщик по приготовлению двухпористого олова',
  },
  {
    ID: 19508,
    label: 'Хлораторщик электродной продукции',
  },
  { ID: 19509, label: 'Хлоропроводчик' },
  { ID: 19511, label: 'Хлорщик' },
  { ID: 19512, label: 'Хмелевод' },
  { ID: 19513, label: 'Холодильщик пищевой продукции' },
  { ID: 19514, label: 'Холодильщик резиновых смесей' },
  { ID: 19515, label: 'Хромолитограф' },
  {
    ID: 19517,
    label: 'Художник декоративной росписи по металлу',
  },
  { ID: 19519, label: 'Художник миниатюрной живописи' },
  { ID: 19520, label: 'Художник росписи по дереву' },
  { ID: 19521, label: 'Художник росписи по ткани' },
  { ID: 19522, label: 'Художник росписи по эмали' },
  { ID: 19523, label: 'Художник по костюму' },
  { ID: 19524, label: 'Цветовод' },
  { ID: 19525, label: 'Цветочница' },
  { ID: 19526, label: 'Цементатор' },
  { ID: 19528, label: 'Цементаторщик' },
  {
    ID: 19529,
    label: 'Цементаторщик гидромедьустановки',
  },
  { ID: 19531, label: 'Центрировщик оптических деталей' },
  { ID: 19532, label: 'Центрифуговщик' },
  { ID: 19533, label: 'Центрифуговщик' },
  { ID: 19534, label: 'Центрифуговщик' },
  { ID: 19535, label: 'Центровщик стеклянных колб' },
  { ID: 19537, label: 'Цепеизготовитель' },
  { ID: 19539, label: 'Цоколевщик' },
  { ID: 19542, label: 'Чабан' },
  { ID: 19544, label: 'Чаевод' },
  {
    ID: 19545,
    label: 'Часовщик по ремонту механических часов',
  },
  {
    ID: 19546,
    label: 'Часовщик по ремонту электронных и кварцевых часов',
  },
  { ID: 19547, label: 'Чеканщик' },
  {
    ID: 19549,
    label: 'Чеканщик скульптурного производства',
  },
  { ID: 19550, label: 'Чеканщик художественных изделий' },
  { ID: 19552, label: 'Чернильщик' },
  {
    ID: 19553,
    label: 'Чернильщик ювелирных и художественных изделий',
  },
  { ID: 19555, label: 'Чистильщик' },
  { ID: 19557, label: 'Чистильщик абразивных изделий' },
  {
    ID: 19558,
    label: 'Чистильщик вентиляционных установок',
  },
  { ID: 19560, label: 'Чистильщик выпарных аппаратов' },
  {
    ID: 19561,
    label: 'Чистильщик дымоходов, боровов и топок',
  },
  {
    ID: 19563,
    label: 'Чистильщик изделий, полуфабрикатов и материалов',
  },
  {
    ID: 19565,
    label: 'Чистильщик канализационных тоннелей и каналов',
  },
  { ID: 19566, label: 'Чистильщик коконного сдира' },
  { ID: 19567, label: 'Чистильщик лица голья' },
  {
    ID: 19568,
    label: 'Чистильщик металла, отливок, изделий и деталей',
  },
  {
    ID: 19570,
    label: 'Чистильщик меховых шкурок бензином',
  },
  {
    ID: 19571,
    label: 'Чистильщик на очистке шламовых бассейнов и болтушек',
  },
  { ID: 19572, label: 'Чистильщик оборудования' },
  { ID: 19573, label: 'Чистильщик оборудования' },
  { ID: 19574, label: 'Чистильщик оборудования' },
  { ID: 19575, label: 'Чистильщик обуви' },
  { ID: 19576, label: 'Чистильщик оптики' },
  {
    ID: 19577,
    label: 'Чистильщик оснастки и приспособлений',
  },
  {
    ID: 19579,
    label: 'Чистильщик полимеризационных стаканов',
  },
  {
    ID: 19580,
    label: 'Чистильщик по очистке пылевых камер',
  },
  { ID: 19582, label: 'Чистильщик продукции' },
  { ID: 19585, label: 'Чистильщик ткани, изделий' },
  {
    ID: 19586,
    label: 'Чистильщик-точильщик чесальных аппаратов',
  },
  { ID: 19588, label: 'Чистильщик ферросплавов' },
  { ID: 19589, label: 'Чистильщик шпуль' },
  {
    ID: 19591,
    label: 'Чистильщик электро-угольных изделий',
  },
  { ID: 19593, label: 'Чокеровщик' },
  { ID: 19596, label: 'Шаблонщик' },
  { ID: 19598, label: 'Шабровщик цветных металлов' },
  { ID: 19599, label: 'Швейцар' },
  { ID: 19601, label: 'Швея' },
  {
    ID: 19603,
    label: 'Швея (в сырейно-красильных и скорняжных цехах)',
  },
  { ID: 19606, label: 'Шевинговальщик' },
  { ID: 19608, label: 'Шелковод' },
  { ID: 19609, label: 'Шелкограф' },
  { ID: 19611, label: 'Шероховщик' },
  { ID: 19612, label: 'Шихтовар' },
  { ID: 19613, label: 'Шихтовщик' },
  { ID: 19614, label: 'Шихтовщик' },
  { ID: 19615, label: 'Шихтовщик' },
  { ID: 19616, label: 'Шихтовщик' },
  { ID: 19617, label: 'Шихтовщик' },
  { ID: 19618, label: 'Шихтовщик' },
  {
    ID: 19619,
    label: 'Шихтовщик в алмазном производстве',
  },
  {
    ID: 19620,
    label: 'Шихтовщик в производстве абразивов',
  },
  { ID: 19621, label: 'Шкипер' },
  { ID: 19622, label: 'Шлаковщик' },
  { ID: 19625, label: 'Шламовщик' },
  { ID: 19627, label: 'Шламовщик-бассейнщик' },
  { ID: 19628, label: 'Шламовщик электролитных ванн' },
  { ID: 19630, label: 'Шлифовщик' },
  {
    ID: 19632,
    label: 'Шлифовщик алмазов и сверхтвердых материалов',
  },
  {
    ID: 19633,
    label: 'Шлифовщик асбестоцементных и асбесто-силитовых плит',
  },
  {
    ID: 19635,
    label: 'Шлифовщик бумаги, картона и фибры',
  },
  {
    ID: 19636,
    label: 'Шлифовщик водорастворимых кристаллов',
  },
  { ID: 19638, label: 'Шлифовщик горных пород' },
  {
    ID: 19640,
    label: 'Шлифовщик изделий из твердых сплавов и тугоплавких металлов',
  },
  {
    ID: 19641,
    label: 'Шлифовщик изделий, полуфабрикатов и материалов',
  },
  {
    ID: 19643,
    label: 'Шлифовщик изделий строительной керамики',
  },
  {
    ID: 19645,
    label: 'Шлифовщик изделий электронной техники',
  },
  { ID: 19646, label: 'Шлифовщик искусственной кожи' },
  { ID: 19648, label: 'Шлифовщик камней' },
  { ID: 19649, label: 'Шлифовщик литоофсетных форм' },
  { ID: 19651, label: 'Шлифовщик медицинских изделий' },
  { ID: 19652, label: 'Шлифовщик микалекса' },
  { ID: 19654, label: 'Шлифовщик оптических деталей' },
  { ID: 19655, label: 'Шлифовщик печатных валов' },
  { ID: 19657, label: 'Шлифовщик по дереву' },
  { ID: 19658, label: 'Шлифовщик подносов' },
  {
    ID: 19660,
    label: 'Шлифовщик-полировщик изделий из камня',
  },
  {
    ID: 19661,
    label:
      'Шлифовщик-полировщик по прецизионной обработке полупроводниковых материалов',
  },
  { ID: 19663, label: 'Шлифовщик полотна' },
  {
    ID: 19664,
    label: 'Шлифовщик пьезокварцевых пластин и кристаллов',
  },
  {
    ID: 19666,
    label: 'Шлифовщик-резчик огнеупорных изделий',
  },
  { ID: 19668, label: 'Шлифовщик стекла' },
  { ID: 19669, label: 'Шлифовщик стеклоизделий' },
  { ID: 19671, label: 'Шлифовщик стержней' },
  {
    ID: 19672,
    label: 'Шлифовщик фарфоровых и фаянсовых изделий',
  },
  {
    ID: 19674,
    label: 'Шлифовщик электрокерамических изделий',
  },
  {
    ID: 19676,
    label: 'Шлифовщик электро-угольных изделий',
  },
  { ID: 19677, label: 'Шлифовщик янтаря' },
  { ID: 19679, label: 'Шлиховщик' },
  { ID: 19680, label: 'Шлихтовар' },
  { ID: 19682, label: 'Шлюзовщик' },
  { ID: 19686, label: 'Шоопировщик элементов' },
  {
    ID: 19687,
    label: 'Шорник по изготовлению и отделке протезно-ортопедических изделий',
  },
  { ID: 19690, label: 'Шпаклевщик' },
  { ID: 19691, label: 'Шпредингист' },
  { ID: 19693, label: 'Шприцовщик' },
  { ID: 19695, label: 'Штабелевщик древесины' },
  { ID: 19696, label: 'Штабелевщик электродов' },
  { ID: 19698, label: 'Штабелировщик металла' },
  { ID: 19700, label: 'Штамповщик' },
  { ID: 19701, label: 'Штамповщик' },
  { ID: 19702, label: 'Штамповщик ватных фильтров' },
  { ID: 19703, label: 'Штамповщик жидкого металла' },
  { ID: 19706, label: 'Штамповщик изделий из слюды' },
  {
    ID: 19707,
    label: 'Штамповщик корректирующих светофильтров',
  },
  { ID: 19709, label: 'Штамповщик методом взрыва' },
  { ID: 19710, label: 'Штамповщик на падающих молотах' },
  { ID: 19712, label: 'Штамповщик ножек' },
  { ID: 19713, label: 'Штамповщик пробковых изделий' },
  { ID: 19715, label: 'Штамповщик резиновой обуви' },
  {
    ID: 19716,
    label: 'Штамповщик (текстильная галантерея)',
  },
  {
    ID: 19718,
    label: 'Штамповщик электроимпульсным методом',
  },
  { ID: 19720, label: 'Штапелировщик' },
  { ID: 19721, label: 'Штемпелевщик карандашей' },
  { ID: 19723, label: 'Штемпелевщик этикеток' },
  { ID: 19725, label: 'Штенгелевщик' },
  { ID: 19727, label: 'Штукатур' },
  { ID: 19735, label: 'Шуровщик топлива' },
  { ID: 19740, label: 'Экипировщик' },
  {
    ID: 19742,
    label: 'Экранировщик жил, проводов и кабелей',
  },
  {
    ID: 19744,
    label: 'Эксгаустировщик банок с консервами',
  },
  { ID: 19745, label: 'Экспедитор печати' },
  { ID: 19747, label: 'Экстрагировщик пектина' },
  { ID: 19749, label: 'Электрик судовой' },
  { ID: 19751, label: 'Электроверетенщик' },
  { ID: 19752, label: 'Электровибронаплавщик' },
  { ID: 19754, label: 'Электроворсовальщик' },
  { ID: 19756, label: 'Электрогазосварщик' },
  { ID: 19758, label: 'Электрогазосварщик-врезчик' },
  { ID: 19760, label: 'Электродчик' },
  { ID: 19761, label: 'Электродчик' },
  {
    ID: 19762,
    label: 'Электродчик безламельных аккумуляторов и элементов',
  },
  {
    ID: 19764,
    label: 'Электродчик ламельных аккумуляторов и элементов',
  },
  { ID: 19765, label: 'Электрозаточник' },
  { ID: 19767, label: 'Электрокопировщик' },
  { ID: 19769, label: 'Электролизерщик' },
  { ID: 19771, label: 'Электролизник водных растворов' },
  {
    ID: 19773,
    label: 'Электролизник по снятию олова с жести',
  },
  {
    ID: 19774,
    label: 'Электролизник расплавленных солей',
  },
  {
    ID: 19776,
    label: 'Электромеханик по испытанию и ремонту электрооборудования',
  },
  { ID: 19778, label: 'Электромеханик по лифтам' },
  {
    ID: 19780,
    label:
      'Электромеханик по обслуживанию светотехнического оборудования систем обеспечения полетов',
  },
  {
    ID: 19782,
    label:
      'Электромеханик по ремонту и обслуживанию медицинских оптических приборов',
  },
  {
    ID: 19784,
    label: 'Электромеханик по ремонту и обслуживанию медицинского оборудования',
  },
  {
    ID: 19786,
    label:
      'Электромеханик по ремонту и обслуживанию медицинского рентгеновского оборудования',
  },
  {
    ID: 19787,
    label:
      'Электромеханик по ремонту и обслуживанию наркозно-дыхательной аппаратуры',
  },
  {
    ID: 19789,
    label:
      'Электромеханик по ремонту и обслуживанию счетно-вычислительных машин',
  },
  {
    ID: 19791,
    label:
      'Электромеханик по ремонту и обслуживанию электронной медицинской аппаратуры',
  },
  {
    ID: 19792,
    label:
      'Электромеханик по средствам автоматики и приборам технологического оборудования',
  },
  {
    ID: 19793,
    label: 'Электромеханик по торговому и холодильному оборудованию',
  },
  {
    ID: 19794,
    label: 'Электромеханик почтового оборудования',
  },
  {
    ID: 19796,
    label: 'Злектромонтажник блоков электронно-механических часов',
  },
  { ID: 19798, label: 'Электромонтажник-наладчик' },
  {
    ID: 19800,
    label: 'Электромонтажник по аккумуляторным батареям',
  },
  {
    ID: 19802,
    label: 'Электромонтажник по вторичным цепям',
  },
  {
    ID: 19804,
    label: 'Электромонтажник по кабельным сетям',
  },
  {
    ID: 19806,
    label: 'Электромонтажник по освещению и осветительным сетям',
  },
  {
    ID: 19808,
    label: 'Электромонтажник по распределительным устройствам',
  },
  {
    ID: 19810,
    label:
      'Электромонтажник по сигнализации, централизации и блокировке на железнодорожном транспорте и наземных линиях метрополитена',
  },
  {
    ID: 19812,
    label: 'Электромонтажник по силовым сетям и электрооборудованию',
  },
  {
    ID: 19814,
    label: 'Электромонтажник по электрическим машинам',
  },
  { ID: 19816, label: 'Электромонтажник судовой' },
  { ID: 19817, label: 'Электромонтажник-схемщик' },
  {
    ID: 19819,
    label: 'Электромонтер главного щита управления электростанции',
  },
  {
    ID: 19821,
    label: 'Электромонтер диспетчерского оборудования и телеавтоматики',
  },
  {
    ID: 19823,
    label: 'Электромонтер канализационных сооружений связи',
  },
  { ID: 19825, label: 'Электромонтер контактной сети' },
  {
    ID: 19827,
    label: 'Электромонтер линейных сооружений телефонной связи и радиофикации',
  },
  {
    ID: 19829,
    label:
      'Электромонтер-линейщик по монтажу воздушных линий высокого напряжения и контактной сети',
  },
  {
    ID: 19831,
    label: 'Электромонтер оперативно-выездной бригады',
  },
  {
    ID: 19832,
    label: 'Электромонтер охранно-пожарной сигнализации',
  },
  {
    ID: 19834,
    label: 'Электромонтер по испытаниям и измерениям',
  },
  {
    ID: 19836,
    label: 'Электромонтер по надзору за трассами кабельных сетей',
  },
  {
    ID: 19838,
    label: 'Электромонтер по обслуживанию буровых',
  },
  {
    ID: 19840,
    label: 'Электромонтер по обслуживанию гидроагрегатов машинного зала',
  },
  {
    ID: 19842,
    label: 'Электромонтер по обслуживанию подстанции',
  },
  {
    ID: 19844,
    label: 'Электромонтер по обслуживанию преобразовательных устройств',
  },
  {
    ID: 19848,
    label: 'Электромонтер по обслуживанию электрооборудования электростанций',
  },
  {
    ID: 19850,
    label: 'Электромонтер по обслуживанию электроустановок',
  },
  {
    ID: 19852,
    label:
      'Электромонтер по оперативным переключениям в распределительных сетях',
  },
  {
    ID: 19854,
    label: 'Электромонтер по ремонту аппаратуры, релейной защиты и автоматики',
  },
  {
    ID: 19855,
    label: 'Электромонтер по ремонту воздушных линий электропередачи',
  },
  {
    ID: 19857,
    label: 'Электромонтер по ремонту вторичной коммутации и связи',
  },
  {
    ID: 19859,
    label: 'Электромонтер по ремонту и монтажу кабельных линий',
  },
  {
    ID: 19861,
    label: 'Электромонтер по ремонту и обслуживанию электрооборудования',
  },
  {
    ID: 19863,
    label: 'Электромонтер по ремонту обмоток и изоляции электрооборудования',
  },
  {
    ID: 19867,
    label: 'Электромонтер по эксплуатации распределительных сетей',
  },
  {
    ID: 19869,
    label: 'Электромонтер по эксплуатации электросчетчиков',
  },
  {
    ID: 19870,
    label: 'Электромонтер по эскизированию трасс линий электропередачи',
  },
  {
    ID: 19872,
    label: 'Электромонтер приемопередающей станции спутниковой связи',
  },
  { ID: 19874, label: 'Электромонтер-релейщик' },
  {
    ID: 19876,
    label:
      'Электромонтер по ремонту и обслуживанию аппаратуры и устройств связи',
  },
  {
    ID: 19878,
    label: 'Электромонтер станционного оборудования радиорелейных линий связи',
  },
  {
    ID: 19880,
    label: 'Электромонтер станционного оборудования радиофикации',
  },
  {
    ID: 19881,
    label: 'Электромонтер станционного оборудования телеграфной связи',
  },
  {
    ID: 19883,
    label: 'Электромонтер станционного оборудования телефонной связи',
  },
  {
    ID: 19885,
    label: 'Электромонтер станционного радиооборудования',
  },
  {
    ID: 19887,
    label: 'Электромонтер станционного телевизионного оборудования',
  },
  {
    ID: 19888,
    label: 'Электромонтер тяговой подстанции',
  },
  {
    ID: 19890,
    label:
      'Электромонтер по обслуживанию и ремонту устройств сигнализации, централизации и блокировки',
  },
  { ID: 19894, label: 'Электрополировщик' },
  { ID: 19899, label: 'Электрорадиомонтажник судовой' },
  { ID: 19901, label: 'Электрорадионавигатор' },
  { ID: 19903, label: 'Электросварщик листов и лент' },
  {
    ID: 19905,
    label: 'Электросварщик на автоматических и полуавтоматических машинах',
  },
  { ID: 19906, label: 'Электросварщик ручной сварки' },
  { ID: 19908, label: 'Электросварщик труб на стане' },
  { ID: 19910, label: 'Электрослесарь-контактчик' },
  {
    ID: 19912,
    label:
      'Электрослесарь-монтажник подземного горнопроходческого оборудования',
  },
  { ID: 19914, label: 'Электрослесарь на проходке' },
  { ID: 19915, label: 'Электрослесарь подземный' },
  {
    ID: 19917,
    label:
      'Электрослесарь по обслуживанию автоматики и средств измерений электростанций',
  },
  {
    ID: 19919,
    label:
      'Электрослесарь по ремонту и обслуживанию автоматики и средств измерений электростанций',
  },
  {
    ID: 19921,
    label: 'Электрослесарь по ремонту оборудования нефтебаз',
  },
  {
    ID: 19923,
    label: 'Электрослесарь по ремонту оборудования распределительных устройств',
  },
  {
    ID: 19927,
    label: 'Электрослесарь по ремонту электрических машин',
  },
  {
    ID: 19929,
    label: 'Электрослесарь по ремонту электрооборудования электростанций',
  },
  {
    ID: 19931,
    label: 'Электрослесарь (слесарь) дежурный и по ремонту оборудования',
  },
  { ID: 19933, label: 'Электрослесарь строительный' },
  { ID: 19935, label: 'Электросушильщик кабелей' },
  { ID: 19937, label: 'Электрофотограф' },
  { ID: 19939, label: 'Электрохимобработчик' },
  { ID: 19940, label: 'Электроэрозионист' },
  { ID: 19942, label: 'Эмалировщик' },
  { ID: 19944, label: 'Эмалировщик проволоки' },
  { ID: 19946, label: 'Эмалировщик резисторов' },
  { ID: 19948, label: 'Эмальер' },
  { ID: 19950, label: 'Эмульсировщик' },
  {
    ID: 19952,
    label: 'Эмульсировщик прецизионной фотолитографии',
  },
  { ID: 19954, label: 'Эмульсовар' },
  { ID: 19956, label: 'Эпилировщик меховых шкурок' },
  { ID: 19957, label: 'Эфиромасличник' },
  { ID: 19959, label: 'Ювелир (ювелир-модельер)' },
  { ID: 19960, label: 'Ювелир-браслетчик' },
  { ID: 19962, label: 'Ювелир-гравер' },
  { ID: 19964, label: 'Ювелир-закрепщик' },
  { ID: 19966, label: 'Ювелир-монтировщик' },
  { ID: 19968, label: 'Ювелир-филигранщик' },
  { ID: 19969, label: 'Ювелир-цепочник' },
  { ID: 19971, label: 'Юстировщик' },
  { ID: 19973, label: 'Юстировщик деталей и приборов' },
  { ID: 19975, label: 'Юстировщик оптических приборов' },
  { ID: 20001, label: 'Агент' },
  { ID: 20002, label: 'Агент банка' },
  { ID: 20004, label: 'Агент коммерческий' },
  { ID: 20007, label: 'Агент морской' },
  {
    ID: 20010,
    label: 'Агент по доставке заказанных билетов',
  },
  {
    ID: 20014,
    label: 'Агент по заказам населения на перевозку',
  },
  { ID: 20015, label: 'Агент по закупкам' },
  {
    ID: 20019,
    label: 'Агент по организации обслуживания пассажирских авиаперевозок',
  },
  {
    ID: 20020,
    label: 'Агент по организации обслуживания почтово-грузовых авиаперевозок',
  },
  {
    ID: 20022,
    label: 'Агент по передаче грузов на пограничной станции (пункте)',
  },
  {
    ID: 20025,
    label: 'Агент по приему заказов на билеты',
  },
  { ID: 20026, label: 'Агент по продаже недвижимости' },
  {
    ID: 20028,
    label: 'Агент по розыску грузов и багажа',
  },
  { ID: 20031, label: 'Агент по снабжению' },
  { ID: 20032, label: 'Агент рекламный' },
  { ID: 20034, label: 'Агент страховой' },
  { ID: 20035, label: 'Агент торговый' },
  { ID: 20036, label: 'Агролесомелиоратор' },
  {
    ID: 20037,
    label: 'Агролесомелиоратор (средней квалификации)',
  },
  { ID: 20038, label: 'Агрометеоролог' },
  { ID: 20040, label: 'Агроном' },
  { ID: 20041, label: 'Агроном (средней квалификации)' },
  {
    ID: 20047,
    label:
      'Агроном отделения (бригады, сельскохозяйственного участка, фермы, цеха)',
  },
  {
    ID: 20048,
    label:
      'Агроном отделения (бригады, сельскохозяйственного участка, фермы, цеха) (средней квалификации)',
  },
  { ID: 20050, label: 'Агроном по защите растений' },
  {
    ID: 20051,
    label: 'Агроном по защите растений (средней квалификации)',
  },
  { ID: 20053, label: 'Агроном по семеноводству' },
  {
    ID: 20054,
    label: 'Агроном по семеноводству (средней квалификации)',
  },
  { ID: 20056, label: 'Агрохимик' },
  {
    ID: 20057,
    label: 'Агрохимик (средней квалификации)',
  },
  { ID: 20059, label: 'Адвокат' },
  { ID: 20061, label: 'Административный секретарь' },
  { ID: 20062, label: 'Администратор' },
  {
    ID: 20063,
    label: 'Администратор гостиницы (дома отдыха)',
  },
  { ID: 20065, label: 'Администратор дежурный' },
  { ID: 20068, label: 'Администратор зала' },
  { ID: 20071, label: 'Администратор кино' },
  { ID: 20073, label: 'Администратор суда' },
  { ID: 20074, label: 'Администратор съемочной группы' },
  { ID: 20077, label: 'Администратор телевидения' },
  { ID: 20080, label: 'Академик-секретарь академии' },
  { ID: 20083, label: 'Аккомпаниатор' },
  { ID: 20086, label: 'Акушерка' },
  { ID: 20089, label: 'Арбитр' },
  { ID: 20095, label: 'Артист' },
  { ID: 20098, label: 'Артист ансамбля песни и танца' },
  { ID: 20101, label: 'Артист балета' },
  { ID: 20102, label: 'Артист балета (солист)' },
  { ID: 20103, label: 'Артист - буффонадный клоун' },
  { ID: 20104, label: 'Артист, ведущий концерт' },
  { ID: 20105, label: 'Артист - воздушный гимнаст' },
  {
    ID: 20107,
    label: 'Артист-вокалист музыкальной комедии и эстрады',
  },
  {
    ID: 20108,
    label: 'Артист-вокалист (оперный и камерный)',
  },
  { ID: 20110, label: 'Артист-вокалист (солист)' },
  {
    ID: 20113,
    label: 'Артист вокально-инструментального ансамбля',
  },
  { ID: 20119, label: 'Артист вспомогательного состава' },
  { ID: 20122, label: 'Артист драмы' },
  {
    ID: 20133,
    label:
      'Артист жанра циркового искусства (дрессуры животных, жонглирования, "Иллюзион", "Эквилибр" и др.)',
  },
  {
    ID: 20135,
    label: 'Артист камерно-инструментального и вокального ансамбля',
  },
  { ID: 20138, label: 'Артист кино' },
  { ID: 20139, label: 'Артист коверный' },
  { ID: 20140, label: 'Артист-конферансье' },
  { ID: 20144, label: 'Артист мимического ансамбля' },
  { ID: 20145, label: 'Артист - музыкальный зксцентрик' },
  { ID: 20147, label: 'Артист оркестра' },
  {
    ID: 20148,
    label:
      'Артист оркестра духового, народных инструментов, эстрадно-симфонического',
  },
  { ID: 20156, label: 'Артист разговорного жанра' },
  { ID: 20158, label: 'Артист-сатирик' },
  {
    ID: 20159,
    label: 'Артист симфонического (камерного) оркестра',
  },
  { ID: 20162, label: 'Артист-солист-инструменталист' },
  {
    ID: 20163,
    label: 'Артист спортивно-акробатического жанра',
  },
  {
    ID: 20166,
    label: 'Артист танцевального и хорового коллектива',
  },
  { ID: 20167, label: 'Артист (кукловод) театра кукол' },
  { ID: 20168, label: 'Артист хора' },
  { ID: 20171, label: 'Артист цирка' },
  {
    ID: 20173,
    label: 'Артист эстрадного оркестра и ансамбля',
  },
  {
    ID: 20174,
    label: 'Артист эстрадно-инструментального ансамбля',
  },
  {
    ID: 20177,
    label:
      'Артист эстрадно-спортивного, иллюзионного и других оригинальных эстрадных жанров',
  },
  { ID: 20184, label: 'Археограф' },
  { ID: 20187, label: 'Археолог' },
  { ID: 20190, label: 'Архивариус' },
  { ID: 20193, label: 'Архивист' },
  { ID: 20196, label: 'Архитектор' },
  { ID: 20199, label: 'Ассистент' },
  { ID: 20200, label: 'Ассистент балетмейстера' },
  { ID: 20201, label: 'Ассистент дирижера' },
  { ID: 20202, label: 'Ассистент звукооператора' },
  { ID: 20205, label: 'Ассистент звукооформителя' },
  { ID: 20209, label: 'Ассистент звукорежиссера' },
  { ID: 20212, label: 'Ассистент кинооператора' },
  { ID: 20215, label: 'Ассистент кинорежиссера' },
  {
    ID: 20222,
    label: 'Ассистент оператора тележурналистского комплекта',
  },
  { ID: 20223, label: 'Ассистент режиссера' },
  {
    ID: 20224,
    label: 'Ассистент режиссера-постановщика',
  },
  {
    ID: 20225,
    label: 'Ассистент режиссера радиовещания',
  },
  { ID: 20226, label: 'Ассистент режиссера телевидения' },
  { ID: 20227, label: 'Ассистент телеоператора' },
  { ID: 20229, label: 'Ассистент хормейстера' },
  {
    ID: 20230,
    label: 'Ассистент художника-мультипликатора',
  },
  {
    ID: 20233,
    label: 'Ассистент художника по комбинированным съемкам',
  },
  {
    ID: 20236,
    label: 'Ассистент художника-постановщика',
  },
  { ID: 20239, label: 'Атташе' },
  { ID: 20241, label: 'Аудитор' },
  {
    ID: 20243,
    label: 'Аудитор Счетной палаты Российской Федерации',
  },
  { ID: 20244, label: 'Аукционист' },
  { ID: 20246, label: 'Аэролог' },
  { ID: 20249, label: 'Аэрофотогеодезист' },
  { ID: 20252, label: 'Аэрохимик' },
  { ID: 20301, label: 'Багермейстер' },
  { ID: 20304, label: 'Багермейстер-капитан' },
  { ID: 20306, label: 'Багермейстер-наставник' },
  { ID: 20307, label: 'Бактериолог' },
  { ID: 20310, label: 'Балетмейстер' },
  { ID: 20312, label: 'Балетмейстер-постановщик' },
  { ID: 20313, label: 'Библиограф' },
  { ID: 20316, label: 'Библиотекарь' },
  {
    ID: 20318,
    label: 'Библиотекарь (средней квалификации)',
  },
  { ID: 20321, label: 'Биолог' },
  { ID: 20324, label: 'Биофизик' },
  { ID: 20327, label: 'Биохимик' },
  { ID: 20328, label: 'Биржевой маклер' },
  { ID: 20330, label: 'Брокер (торговый)' },
  { ID: 20331, label: 'Брокер (финансовый)' },
  { ID: 20334, label: 'Букмекер' },
  { ID: 20336, label: 'Бухгалтер' },
  {
    ID: 20337,
    label: 'Бухгалтер (средней квалификации)',
  },
  { ID: 20339, label: 'Бухгалтер-ревизор' },
  { ID: 20342, label: 'Бухгалтер-эксперт' },
  { ID: 20387, label: 'Ведущий дискотеки' },
  {
    ID: 20390,
    label: 'Ведущий инженер по летным испытаниям воздушных судов',
  },
  {
    ID: 20393,
    label: 'Ведущий инженер по объективному контролю',
  },
  {
    ID: 20396,
    label:
      'Ведущий инженер по экспериментальным работам и летным испытаниям систем воздушных судов',
  },
  {
    ID: 20400,
    label: 'Ведущий инженер по эксплуатации воздушных судов',
  },
  {
    ID: 20402,
    label: 'Ведущий инспектор в аппарате Счетной палаты Российской Федерации',
  },
  { ID: 20404, label: 'Ведущий программы' },
  {
    ID: 20406,
    label: 'Ведущий специалист в Администрации Президента Российской Федерации',
  },
  {
    ID: 20407,
    label: 'Ведущий специалист в аппарате Верховного Суда Российской Федерации',
  },
  {
    ID: 20409,
    label:
      'Ведущий специалист в аппарате Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 20410,
    label:
      'Ведущий специалист в аппарате Генеральной прокуратуры Российской Федерации',
  },
  {
    ID: 20412,
    label:
      'Ведущий специалист в Аппарате Государственной Думы Федерального Собрания',
  },
  {
    ID: 20413,
    label:
      'Ведущий специалист в аппарате Конституционного Суда Российской Федерации',
  },
  {
    ID: 20415,
    label: 'Ведущий специалист в Аппарате Правительства Российской Федерации',
  },
  {
    ID: 20416,
    label:
      'Ведущий специалист в Аппарате Совета Федерации Федерального Собрания',
  },
  {
    ID: 20418,
    label:
      'Ведущий специалист в аппарате Центральной избирательной комиссии Российской Федерации',
  },
  {
    ID: 20419,
    label:
      'Ведущий специалист в центральном аппарате федерального органа исполнительной власти',
  },
  { ID: 20425, label: 'Ветеринарный врач' },
  { ID: 20427, label: 'Ветеринарный фельдшер' },
  { ID: 20430, label: 'Водолазный специалист' },
  {
    ID: 20431,
    label: 'Водолазный специалист подводного аппарата',
  },
  { ID: 20434, label: 'Вожатый' },
  { ID: 20436, label: 'Воспитатель' },
  {
    ID: 20437,
    label: 'Воспитатель детского сада (яслей-сада)',
  },
  { ID: 20439, label: 'Воспитатель общежития' },
  {
    ID: 20442,
    label: 'Воспитатель профессионально-технического учебного заведения',
  },
  { ID: 20448, label: 'Врач' },
  { ID: 20449, label: 'Врач здравпункта' },
  { ID: 20450, label: 'Врач-кардиоревматолог' },
  { ID: 20451, label: 'Врач-лаборант' },
  { ID: 20452, label: 'Врач-интерн' },
  {
    ID: 20453,
    label: 'Врач-интерн учреждения госсанэпидслужбы',
  },
  { ID: 20454, label: 'Врач-методист' },
  { ID: 20455, label: 'Врач общей практики (семейный)' },
  { ID: 20457, label: 'Врач подводного аппарата' },
  { ID: 20458, label: 'Врач-педиатр участковый' },
  {
    ID: 20459,
    label: 'Врач-педиатр городской (районный)',
  },
  {
    ID: 20460,
    label: 'Врач по контролю за занимающимися физкультурой и спортом',
  },
  { ID: 20461, label: 'Врач приемного отделения' },
  {
    ID: 20462,
    label: 'Врач-протезист по глазному протезированию',
  },
  { ID: 20463, label: 'Врач-специалист' },
  {
    ID: 20464,
    label: 'Врач-протезист по ушному протезированию',
  },
  { ID: 20465, label: 'Врач-психиатр участковый' },
  { ID: 20466, label: 'Врач-психиатр детский' },
  {
    ID: 20467,
    label: 'Врач-психиатр-нарколог участковый',
  },
  { ID: 20468, label: 'Врач-психиатр подростковый' },
  { ID: 20469, label: 'Врач-статистик' },
  { ID: 20470, label: 'Врач судовой' },
  { ID: 20471, label: 'Врач-терапевт подростковый' },
  {
    ID: 20472,
    label: 'Врач - специалист по медико-социальной экспертизе',
  },
  {
    ID: 20474,
    label: 'Врач-специалист учреждения госсанэпидслужбы',
  },
  { ID: 20475, label: 'Врач-терапевт участковый' },
  {
    ID: 20476,
    label: 'Врач-терапевт участковый цехового врачебного участка',
  },
  { ID: 20478, label: 'Врач-стажер' },
  {
    ID: 20480,
    label: 'Врач - судебно-медицинский эксперт',
  },
  {
    ID: 20482,
    label: 'Врач - судебно-психиатрический эксперт',
  },
  { ID: 20483, label: 'Врач-фтизиатр участковый' },
  { ID: 20485, label: 'Выпускающий' },
  { ID: 20487, label: 'Выпускающий ответственный' },
  {
    ID: 20539,
    label: 'Генеральный директор акционерного общества',
  },
  {
    ID: 20541,
    label: 'Генеральный директор генеральной дирекции',
  },
  {
    ID: 20543,
    label: 'Генеральный директор российского агентства',
  },
  { ID: 20547, label: 'Генеральный директор киностудии' },
  { ID: 20550, label: 'Генеральный директор комбината' },
  {
    ID: 20553,
    label: 'Генеральный директор межотраслевого научно-технического комплекса',
  },
  {
    ID: 20556,
    label: 'Генеральный директор научно-производственного объединения',
  },
  {
    ID: 20557,
    label: 'Генеральный директор объединения',
  },
  {
    ID: 20559,
    label: 'Генеральный директор производственного объединения',
  },
  {
    ID: 20560,
    label: 'Генеральный директор предприятия',
  },
  { ID: 20561, label: 'Генеральный директор программ' },
  {
    ID: 20563,
    label: 'Генеральный директор Российского гуманитарного научного фонда',
  },
  {
    ID: 20565,
    label: 'Генеральный директор Судебного департамента',
  },
  {
    ID: 20568,
    label: 'Генеральный директор управления гражданской авиации',
  },
  { ID: 20571, label: 'Генеральный конструктор' },
  {
    ID: 20574,
    label: 'Генеральный консул Российской Федерации',
  },
  { ID: 20577, label: 'Генеральный прокурор' },
  {
    ID: 20578,
    label: 'Генеральный прокурор Российской Федерации',
  },
  { ID: 20580, label: 'Генеральный секретарь' },
  { ID: 20583, label: 'Геоботаник' },
  { ID: 20586, label: 'Геодезист' },
  { ID: 20589, label: 'Геолог' },
  { ID: 20590, label: 'Геолог карьера, рудника, шахты' },
  {
    ID: 20592,
    label: 'Геолог нефтегазоразведки (партии) структурно-поискового бурения',
  },
  { ID: 20601, label: 'Геофизик' },
  {
    ID: 20602,
    label: 'Геофизик карьера, рудника, шахты',
  },
  { ID: 20603, label: 'Геохимик' },
  { ID: 20605, label: 'Герпетолог' },
  { ID: 20606, label: 'Гид-переводчик' },
  { ID: 20607, label: 'Гидроакустик' },
  { ID: 20608, label: 'Гидробиолог' },
  { ID: 20610, label: 'Гидрогеолог' },
  {
    ID: 20613,
    label: 'Гидрогеолог карьера, рудника, шахты',
  },
  { ID: 20614, label: 'Гидролог' },
  { ID: 20615, label: 'Гидрометнаблюдатель' },
  { ID: 20616, label: 'Гидротехник' },
  { ID: 20618, label: 'Гидрохимик' },
  {
    ID: 20620,
    label: 'Глава администрации (края, области)',
  },
  {
    ID: 20621,
    label: 'Глава администрации (города, района)',
  },
  {
    ID: 20622,
    label: 'Глава администрации (сельской, поселковой)',
  },
  { ID: 20626, label: 'Главная медицинская сестра' },
  { ID: 20629, label: 'Главный агроном' },
  {
    ID: 20631,
    label: 'Главный агроном (государственный инспектор по карантину растений)',
  },
  { ID: 20632, label: 'Главный агрохимик' },
  { ID: 20633, label: 'Главный администратор' },
  { ID: 20636, label: 'Главный археограф' },
  { ID: 20637, label: 'Главный архивист' },
  { ID: 20638, label: 'Главный архитектор' },
  { ID: 20641, label: 'Главный архитектор проекта' },
  { ID: 20644, label: 'Главный балетмейстер' },
  { ID: 20647, label: 'Главный библиограф' },
  { ID: 20650, label: 'Главный библиотекарь' },
  { ID: 20656, label: 'Главный бухгалтер' },
  { ID: 20659, label: 'Главный ветеринарный врач' },
  {
    ID: 20660,
    label: 'Главный военный судебный пристав',
  },
  {
    ID: 20666,
    label:
      'Главный врач (начальник) учреждения госсанэпидслужбы (главный государственный санитарный врач)',
  },
  {
    ID: 20668,
    label:
      'Главный врач (директор, заведующий, начальник) учреждения здравоохранения',
  },
  { ID: 20671, label: 'Главный выпускающий' },
  {
    ID: 20672,
    label: 'Главный геодезист (в сельском и лесном хозяйстве)',
  },
  {
    ID: 20673,
    label: 'Главный геодезист (в промышленности)',
  },
  {
    ID: 20674,
    label: 'Главный геодезист (в строительстве)',
  },
  {
    ID: 20675,
    label: 'Главный геодезист (на транспорте)',
  },
  { ID: 20677, label: 'Главный геолог' },
  { ID: 20680, label: 'Главный геофизик' },
  { ID: 20681, label: 'Главный геохимик' },
  {
    ID: 20685,
    label: 'Главный гидрогеолог (в сельском хозяйстве)',
  },
  {
    ID: 20686,
    label: 'Главный гидрогеолог (в промышленности)',
  },
  {
    ID: 20687,
    label: 'Главный гидрогеолог (в строительстве)',
  },
  { ID: 20689, label: 'Главный гидрограф' },
  { ID: 20692, label: 'Главный гидролог' },
  {
    ID: 20697,
    label: 'Главный гидротехник (в сельском хозяйстве)',
  },
  {
    ID: 20698,
    label: 'Главный гидротехник (в промышленности)',
  },
  {
    ID: 20699,
    label: 'Главный гидротехник (на транспорте)',
  },
  { ID: 20701, label: 'Главный горняк' },
  {
    ID: 20704,
    label: 'Главный государственный инженер-инспектор',
  },
  {
    ID: 20707,
    label: 'Главный государственный инспектор',
  },
  {
    ID: 20708,
    label: 'Главный государственный инспектор бассейна',
  },
  {
    ID: 20709,
    label: 'Главный государственный инспектор по геодезическому надзору',
  },
  {
    ID: 20712,
    label:
      'Главный государственный инспектор по качеству сельскохозяйственной продукции и продуктов ее переработки',
  },
  {
    ID: 20713,
    label:
      'Главный государственный инспектор по контролю за производством и качеством спиртов и алкогольных напитков',
  },
  {
    ID: 20716,
    label:
      'Главный государственный инспектор Российской Федерации по охране труда',
  },
  {
    ID: 20718,
    label:
      'Главный государственный инспектор по судоходству на пограничных реках',
  },
  {
    ID: 20721,
    label: 'Главный государственный инспектор труда',
  },
  {
    ID: 20722,
    label: 'Главный государственный инспектор труда Российской Федерации',
  },
  {
    ID: 20726,
    label: 'Главный государственный налоговый инспектор',
  },
  {
    ID: 20734,
    label: 'Главный государственный патентный эксперт',
  },
  {
    ID: 20736,
    label:
      'Главный государственный правовой инспектор труда Российской Федерации',
  },
  { ID: 20741, label: 'Главный диктор' },
  { ID: 20742, label: 'Главный дессинатор' },
  { ID: 20743, label: 'Главный дизайнер проекта' },
  { ID: 20744, label: 'Главный дирижер' },
  { ID: 20745, label: 'Главный директор программ' },
  {
    ID: 20746,
    label: 'Главный диспетчер (в промышленности)',
  },
  {
    ID: 20748,
    label: 'Главный диспетчер (на транспорте и в связи)',
  },
  {
    ID: 20749,
    label: 'Главный диспетчер (в прочих отраслях)',
  },
  { ID: 20750, label: 'Главный зоотехник' },
  { ID: 20751, label: 'Главный звукорежиссер' },
  {
    ID: 20753,
    label: 'Главный инспектор в аппарате Счетной палаты Российской Федерации',
  },
  {
    ID: 20754,
    label:
      'Главный инженер (в сельском, охотничьем, лесном и рыбном хозяйстве)',
  },
  {
    ID: 20755,
    label: 'Главный инженер (в промышленности)',
  },
  {
    ID: 20757,
    label:
      'Главный инженер (на транспорте, в связи, материально-техническом снабжении и сбыте)',
  },
  {
    ID: 20758,
    label: 'Главный инженер (в прочих отраслях)',
  },
  { ID: 20760, label: 'Главный инженер проекта' },
  { ID: 20761, label: 'Главный искусствовед' },
  { ID: 20762, label: 'Главный ихтиопатолог' },
  { ID: 20763, label: 'Главный калибровщик' },
  { ID: 20764, label: 'Главный кассир' },
  { ID: 20765, label: 'Главный казначей' },
  { ID: 20767, label: 'Главный кинооператор' },
  { ID: 20771, label: 'Главный колорист' },
  { ID: 20780, label: 'Главный конструктор' },
  { ID: 20783, label: 'Главный конструктор проекта' },
  {
    ID: 20784,
    label:
      'Главный консультант в аппарате Верховного Суда Российской Федерации',
  },
  {
    ID: 20785,
    label:
      'Главный консультант в аппарате Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 20787,
    label:
      'Главный консультант в аппарате Конституционного Суда Российской Федерации',
  },
  { ID: 20788, label: 'Главный контролер-ревизор' },
  { ID: 20795, label: 'Главный крупчатник' },
  { ID: 20796, label: 'Главный крупянщик' },
  {
    ID: 20798,
    label: 'Главный ландшафтный архитектор проекта',
  },
  {
    ID: 20800,
    label: 'Главный лесничий (главный государственный инспектор по лесу)',
  },
  {
    ID: 20802,
    label: 'Главный маркшейдер (в промышленности)',
  },
  {
    ID: 20803,
    label: 'Главный маркшейдер (в строительстве)',
  },
  { ID: 20806, label: 'Главный мелиоратор' },
  { ID: 20808, label: 'Главный металлург' },
  { ID: 20811, label: 'Главный метролог' },
  {
    ID: 20814,
    label:
      'Главный механик (в сельском, охотничьем, лесном и рыбном хозяйстве)',
  },
  {
    ID: 20815,
    label: 'Главный механик (в промышленности)',
  },
  {
    ID: 20817,
    label:
      'Главный механик (на транспорте, в связи, материально-техническом снабжении и сбыте)',
  },
  {
    ID: 20818,
    label: 'Главный механик (в прочих отраслях)',
  },
  {
    ID: 20820,
    label: 'Главный механик подводного аппарата',
  },
  { ID: 20824, label: 'Главный модельер-конструктор' },
  { ID: 20829, label: 'Главный обогатитель' },
  { ID: 20830, label: 'Главный телеоператор' },
  { ID: 20833, label: 'Главный охотовед' },
  { ID: 20836, label: 'Главный палеограф' },
  { ID: 20837, label: 'Главный почвовед' },
  { ID: 20838, label: 'Главный приборист' },
  { ID: 20841, label: 'Главный прокатчик' },
  { ID: 20845, label: 'Главный радиолог' },
  {
    ID: 20848,
    label: 'Главный ревизор по безопасности движения',
  },
  {
    ID: 20850,
    label: 'Главный редактор (издательства, редакции газет и журналов)',
  },
  { ID: 20854, label: 'Главный редактор карт и лоций' },
  { ID: 20860, label: 'Главный редактор программ' },
  {
    ID: 20868,
    label: 'Главный редактор студии (киностудии)',
  },
  {
    ID: 20872,
    label: 'Главный редактор творческого объединения',
  },
  {
    ID: 20873,
    label: 'Главный редактор телевидения и радиовещания',
  },
  { ID: 20875, label: 'Главный режиссер' },
  { ID: 20881, label: 'Главный релейщик' },
  { ID: 20883, label: 'Главный рыбовод' },
  { ID: 20886, label: 'Главный сварщик' },
  { ID: 20887, label: 'Главный синоптик' },
  { ID: 20888, label: 'Главный советник' },
  { ID: 20889, label: 'Главный специалист' },
  {
    ID: 20898,
    label: 'Главный специалист в аппарате Верховного Суда Российской Федерации',
  },
  {
    ID: 20899,
    label:
      'Главный специалист в аппарате Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 20901,
    label:
      'Главный специалист в аппарате Генеральной прокуратуры Российской Федерации',
  },
  {
    ID: 20902,
    label:
      'Главный специалист в аппарате Конституционного Суда Российской Федерации',
  },
  {
    ID: 20904,
    label:
      'Главный специалист в центральном аппарате федерального органа исполнительной власти',
  },
  {
    ID: 20908,
    label: 'Главный специалист по автоматике',
  },
  {
    ID: 20910,
    label: 'Главный специалист по антенным сооружениям',
  },
  {
    ID: 20911,
    label: 'Главный специалист по защите информации',
  },
  {
    ID: 20913,
    label: 'Главный специалист по монтажу и наладке систем автоматизации',
  },
  {
    ID: 20920,
    label: 'Главный специалист по программному обеспечению',
  },
  {
    ID: 20931,
    label: 'Главный специалист по радиовещанию',
  },
  {
    ID: 20937,
    label: 'Главный специалист по радиопередающим устройствам',
  },
  {
    ID: 20946,
    label: 'Главный специалист по сантехническому оборудованию',
  },
  {
    ID: 20953,
    label: 'Главный специалист по системам создания специального климата',
  },
  {
    ID: 20962,
    label:
      'Главный специалист по слаботочным системам и контрольно-измерительным приборам и автоматике',
  },
  {
    ID: 20970,
    label: 'Главный специалист по телевизионному вещанию',
  },
  {
    ID: 20977,
    label: 'Главный специалист по тропосферным радиорелейным линиям',
  },
  {
    ID: 20982,
    label: 'Главный специалист по цветному телевидению',
  },
  {
    ID: 20987,
    label: 'Главный специалист по электросвязи',
  },
  { ID: 20996, label: 'Главный сталеплавильщик' },
  { ID: 20998, label: 'Главный строитель' },
  {
    ID: 20999,
    label: 'Главный судебный пристав субъекта Российской Федерации',
  },
  { ID: 21000, label: 'Главный судебный пристав' },
  {
    ID: 21001,
    label: 'Главный судья по испытанию племенных лошадей',
  },
  {
    ID: 21009,
    label: 'Главный технолог (в промышленности)',
  },
  {
    ID: 21010,
    label: 'Главный технолог (в прочих отраслях)',
  },
  { ID: 21011, label: 'Главный технолог проекта' },
  { ID: 21012, label: 'Главный товаровед' },
  { ID: 21014, label: 'Главный тренер' },
  { ID: 21015, label: 'Главный тренер сборной команды' },
  {
    ID: 21016,
    label:
      'Главный турбинист производственного объединения по ремонту и наладке оборудования атомных станций',
  },
  {
    ID: 21018,
    label: 'Главный ученый секретарь президиума академии',
  },
  { ID: 21019, label: 'Главный хормейстер' },
  { ID: 21020, label: 'Главный хранитель фондов' },
  {
    ID: 21021,
    label: 'Главный ученый секретарь научного центра',
  },
  {
    ID: 21023,
    label: 'Главный художественный руководитель',
  },
  { ID: 21024, label: 'Главный художник' },
  {
    ID: 21025,
    label: 'Главный ученый секретарь президиума регионального отделения',
  },
  { ID: 21026, label: 'Главный художник-модельер' },
  { ID: 21029, label: 'Главный штурман' },
  { ID: 21032, label: 'Главный экономист' },
  {
    ID: 21033,
    label: 'Главный эксперт по врачебно-трудовой экспертизе',
  },
  { ID: 21034, label: 'Главный электрик' },
  { ID: 21035, label: 'Главный электроник' },
  {
    ID: 21039,
    label:
      'Главный энергетик (в сельском, охотничьем, лесном и рыбном хозяйстве)',
  },
  {
    ID: 21041,
    label: 'Главный энергетик (в промышленности)',
  },
  {
    ID: 21042,
    label:
      'Главный энергетик (на транспорте, в связи, материально-техническом снабжении и сбыте)',
  },
  {
    ID: 21044,
    label: 'Главный энергетик (в прочих отраслях)',
  },
  { ID: 21045, label: 'Главный энергодиспетчер' },
  { ID: 21047, label: 'Главный юрист' },
  { ID: 21050, label: 'Государственный герольдмейстер' },
  { ID: 21051, label: 'Государственный инспектор' },
  {
    ID: 21053,
    label:
      'Государственный инспектор по безопасности плавания судов (мореплавания и портового надзора) рыбного хозяйства',
  },
  {
    ID: 21054,
    label: 'Государственный инспектор по геодезическому надзору',
  },
  {
    ID: 21056,
    label: 'Государственный инспектор по карантину растений',
  },
  {
    ID: 21057,
    label:
      'Государственный инспектор по качеству сельскохозяйственной продукции и продуктов ее переработки',
  },
  {
    ID: 21059,
    label: 'Государственный инспектор по качеству товаров и торговле',
  },
  {
    ID: 21060,
    label:
      'Государственный инспектор по контролю за производством и качеством спиртов и алкогольных напитков',
  },
  {
    ID: 21062,
    label: 'Государственный инспектор по маломерным судам',
  },
  {
    ID: 21063,
    label:
      'Государственный инспектор по охране территории государственного природного заповедника',
  },
  {
    ID: 21065,
    label: 'Государственный инспектор по охране леса',
  },
  {
    ID: 21066,
    label: 'Государственный инспектор по охране труда',
  },
  {
    ID: 21068,
    label: 'Государственный инспектор по судоходству на пограничных реках',
  },
  {
    ID: 21071,
    label: 'Государственный инспектор рыбоохраны',
  },
  { ID: 21072, label: 'Государственный инспектор труда' },
  {
    ID: 21080,
    label: 'Государственный налоговый инспектор',
  },
  { ID: 21084, label: 'Государственный нотариус' },
  {
    ID: 21087,
    label: 'Государственный патентный эксперт',
  },
  {
    ID: 21089,
    label: 'Государственный патентный эксперт-стажер',
  },
  {
    ID: 21094,
    label: 'Государственный хлебный инспектор',
  },
  { ID: 21101, label: 'Губернатор' },
  { ID: 21201, label: 'Девиатор' },
  { ID: 21204, label: 'Дежурный бюро пропусков' },
  {
    ID: 21207,
    label: 'Дежурный оборотного локомотивного депо',
  },
  {
    ID: 21209,
    label: 'Дежурный основного локомотивного депо',
  },
  {
    ID: 21211,
    label:
      'Дежурный оперативный (отряда пожарной охраны, по контролю полетов, по связи и радионавигации, пункта управления, по перелетам, поисково-спасательной службы)',
  },
  {
    ID: 21213,
    label: 'Дежурный ответственный по обмену почты',
  },
  { ID: 21218, label: 'Дежурный по вокзалу' },
  {
    ID: 21221,
    label: 'Дежурный по выдаче справок (бюро справок)',
  },
  {
    ID: 21224,
    label: 'Дежурный по депо подвижного состава',
  },
  {
    ID: 21225,
    label: 'Дежурный по дому отдыха локомотивных (поездных) бригад',
  },
  {
    ID: 21227,
    label: 'Дежурный по железнодорожной станции',
  },
  {
    ID: 21228,
    label: 'Дежурный по залу (бильярдному, вокзала, спортивному и др.)',
  },
  {
    ID: 21230,
    label: 'Дежурный по комнате матери и ребенка',
  },
  {
    ID: 21231,
    label:
      'Дежурный по комнате отдыха (водителей автомобилей, пассажиров с детьми)',
  },
  { ID: 21233, label: 'Дежурный по метрополитену' },
  {
    ID: 21234,
    label:
      'Дежурный по механизированной дистанции погрузочно-разгрузочных работ',
  },
  {
    ID: 21236,
    label: 'Дежурный по обеспечению питания пассажиров',
  },
  {
    ID: 21237,
    label: 'Дежурный по обслуживанию пассажиров',
  },
  { ID: 21239, label: 'Дежурный по общежитию' },
  {
    ID: 21240,
    label:
      'Дежурный по объединению (промышленного железнодорожного транспорта)',
  },
  {
    ID: 21242,
    label: 'Дежурный по отделению железной дороги',
  },
  {
    ID: 21243,
    label: 'Дежурный по парку (на железнодорожном транспорте)',
  },
  {
    ID: 21245,
    label: 'Дежурный по перевозочной документации и матрицам',
  },
  { ID: 21246, label: 'Дежурный по переправе' },
  {
    ID: 21248,
    label: 'Дежурный по приему и выдаче оружия',
  },
  {
    ID: 21249,
    label: 'Дежурный по приему и отправлению поездов метрополитена',
  },
  {
    ID: 21251,
    label:
      'Дежурный по пункту транспортно-экспедиционного агентства на вокзале',
  },
  { ID: 21252, label: 'Дежурный по разъезду' },
  {
    ID: 21255,
    label: 'Дежурный по режиму специального учебно-воспитательного учреждения',
  },
  {
    ID: 21257,
    label: 'Дежурный по сопровождению воздушных судов',
  },
  { ID: 21258, label: 'Дежурный по сортировочной горке' },
  {
    ID: 21260,
    label: 'Дежурный по станции метрополитена',
  },
  { ID: 21261, label: 'Дежурный по стоянке, ангару' },
  {
    ID: 21263,
    label: 'Дежурный по тарифной документации',
  },
  { ID: 21264, label: 'Дежурный по товарной конторе' },
  {
    ID: 21266,
    label: 'Дежурный по транспортно-экспедиционному агентству (филиалу)',
  },
  { ID: 21267, label: 'Дежурный по электродепо' },
  {
    ID: 21269,
    label: 'Дежурный по этажу (гостиницы, кемпинга, пансионата)',
  },
  { ID: 21270, label: 'Дежурный предприятия связи' },
  { ID: 21271, label: 'Дежурный пульта управления' },
  {
    ID: 21274,
    label: 'Дежурный пункта оборота локомотивов',
  },
  {
    ID: 21277,
    label: 'Дежурный пункта подмены локомотивных бригад',
  },
  {
    ID: 21286,
    label: 'Дежурный станционного поста телеуправления',
  },
  {
    ID: 21290,
    label: 'Дежурный станционного поста централизации',
  },
  { ID: 21296, label: 'Декан факультета' },
  { ID: 21297, label: 'Декларант' },
  { ID: 21299, label: 'Делопроизводитель' },
  { ID: 21300, label: 'Дельтапланерист-испытатель' },
  { ID: 21302, label: 'Дессинатор' },
  { ID: 21305, label: 'Диктор' },
  { ID: 21306, label: 'Диктор (комментатор)' },
  { ID: 21307, label: 'Диктор редакции радиовещания' },
  { ID: 21308, label: 'Дилер по валютным операциям' },
  { ID: 21309, label: 'Дилер' },
  { ID: 21310, label: 'Дилер по ценным бумагам' },
  { ID: 21311, label: 'Консульский агент' },
  { ID: 21314, label: 'Дипломатический курьер' },
  { ID: 21315, label: 'Дилер фондовой биржи' },
  { ID: 21319, label: 'Директор акционерного общества' },
  {
    ID: 21320,
    label: 'Директор автотранспортного предприятия',
  },
  {
    ID: 21321,
    label: 'Директор архива Президента Российской Федерации',
  },
  {
    ID: 21322,
    label: 'Директор (заведующий) архивного учреждения',
  },
  { ID: 21323, label: 'Директор атомной станции' },
  { ID: 21326, label: 'Директор аукциона' },
  {
    ID: 21329,
    label: 'Директор базы (кинобазы, продовольственных товаров и др.)',
  },
  {
    ID: 21331,
    label: 'Директор Библиотеки Администрации Президента Российской Федерации',
  },
  {
    ID: 21332,
    label:
      'Директор (заведующий) библиотеки (централизованной библиотечной системы)',
  },
  {
    ID: 21333,
    label: 'Директор больницы (дома) сестринского ухода, хосписа',
  },
  { ID: 21335, label: 'Директор ботанического сада' },
  {
    ID: 21341,
    label: 'Директор (заведующий) внешкольного учреждения',
  },
  { ID: 21344, label: 'Директор (заведующий) выставки' },
  {
    ID: 21345,
    label:
      'Директор (начальник) вычислительного (информационно-вычислительного) центра',
  },
  {
    ID: 21347,
    label: 'Директор (начальник) гидроэлектростанции (каскада)',
  },
  {
    ID: 21350,
    label: 'Директор главного морского агентства',
  },
  {
    ID: 21353,
    label: 'Директор (заведующий) гостиницы (кемпинга, пансионата)',
  },
  {
    ID: 21359,
    label: 'Директор государственной заводской конюшни',
  },
  {
    ID: 21362,
    label: 'Директор дворца (бракосочетания, культуры, спорта и др.)',
  },
  { ID: 21363, label: 'Директор департамента' },
  {
    ID: 21365,
    label: 'Директор (начальник, уполномоченный) дирекции',
  },
  {
    ID: 21368,
    label: 'Директор (заведующий) дома (детского, отдыха, творчества и др.)',
  },
  {
    ID: 21370,
    label: 'Директор дома-интерната для престарелых и инвалидов',
  },
  { ID: 21374, label: 'Директор завода' },
  {
    ID: 21375,
    label: 'Директор (заведующий) зала (выставочного, демонстрационного и др.)',
  },
  {
    ID: 21378,
    label: 'Директор государственного природного заповедника',
  },
  { ID: 21381, label: 'Директор зоопарка' },
  { ID: 21386, label: 'Директор (заведующий) интерната' },
  { ID: 21389, label: 'Директор ипподрома' },
  {
    ID: 21395,
    label: 'Директор кабинета (методического, учебно-методического)',
  },
  {
    ID: 21396,
    label: 'Директор касс (театрально-концертных и спортивно-зрелищных)',
  },
  { ID: 21398, label: 'Директор киностудии' },
  { ID: 21401, label: 'Директор кинотеатра' },
  { ID: 21404, label: 'Директор (начальник) комбината' },
  { ID: 21407, label: 'Директор коммерческий' },
  {
    ID: 21410,
    label: 'Директор комплекса (оздоровительного, спортивного, туристского)',
  },
  { ID: 21413, label: 'Директор конторы' },
  { ID: 21416, label: 'Директор концертной организации' },
  { ID: 21419, label: 'Директор (начальник) котельной' },
  { ID: 21422, label: 'Директор (начальник) курсов' },
  {
    ID: 21425,
    label: 'Директор (заведующий, начальник) лаборатории',
  },
  { ID: 21431, label: 'Директор морского агентства' },
  {
    ID: 21433,
    label: 'Директор музыкального (танцевального) коллектива',
  },
  { ID: 21434, label: 'Директор (заведующий) музея' },
  {
    ID: 21435,
    label: 'Директор научно-исследовательского института',
  },
  { ID: 21441, label: 'Директор обсерватории' },
  { ID: 21444, label: 'Директор объединения' },
  {
    ID: 21447,
    label:
      'Директор (начальник) организации (изыскательской, конструкторской, проектной)',
  },
  {
    ID: 21465,
    label: 'Директор парка культуры и отдыха',
  },
  {
    ID: 21467,
    label: 'Директор Парламентской библиотеки',
  },
  { ID: 21474, label: 'Директор планетария' },
  { ID: 21477, label: 'Директор по кадрам и быту' },
  {
    ID: 21480,
    label: 'Директор по капитальному строительству',
  },
  { ID: 21486, label: 'Директор по производству' },
  { ID: 21489, label: 'Директор по экономике' },
  {
    ID: 21495,
    label: 'Директор (начальник, управляющий) предприятия',
  },
  {
    ID: 21498,
    label: 'Директор (заведующий) предприятия общественного питания',
  },
  {
    ID: 21501,
    label: 'Директор (заведующий) предприятия розничной торговли',
  },
  {
    ID: 21504,
    label: 'Директор предприятия сетей (тепловых, электрических)',
  },
  { ID: 21506, label: 'Директор представительства' },
  {
    ID: 21507,
    label: 'Директор (начальник) преобразовательного комплекса',
  },
  {
    ID: 21508,
    label: 'Директор (заведующий) приюта для молодежи',
  },
  {
    ID: 21510,
    label: 'Директор программ (радиотелевизионных)',
  },
  {
    ID: 21511,
    label: 'Директор (управляющий) производственного объединения',
  },
  { ID: 21513, label: 'Директор промысла' },
  {
    ID: 21516,
    label: 'Директор разреза, разрезоуправления (сланцевого, угольного)',
  },
  { ID: 21525, label: 'Директор Регистра' },
  { ID: 21529, label: 'Директор речного Регистра' },
  {
    ID: 21532,
    label: 'Директор рудника (рудоуправления)',
  },
  { ID: 21538, label: 'Директор совхоза' },
  {
    ID: 21541,
    label: 'Директор (заведующий) спортивного сооружения',
  },
  { ID: 21542, label: 'Директор спортивной школы' },
  {
    ID: 21544,
    label:
      'Директор станции (госсортостанции, лесосеменной, машиноиспытательной и др.)',
  },
  {
    ID: 21546,
    label: 'Директор строящегося предприятия',
  },
  {
    ID: 21548,
    label: 'Директор (главный редактор) структурного агентства',
  },
  { ID: 21550, label: 'Директор студии' },
  { ID: 21553, label: 'Директор съемочной группы' },
  {
    ID: 21555,
    label: 'Директор творческого объединения (коллектива)',
  },
  { ID: 21556, label: 'Директор театра' },
  {
    ID: 21565,
    label: 'Директор (начальник) тепловой электростанции',
  },
  {
    ID: 21566,
    label:
      'Директор территориального центра профессиональной ориентации молодежи',
  },
  { ID: 21571, label: 'Директор технический' },
  { ID: 21572, label: 'Директор типографии' },
  { ID: 21574, label: 'Директор (начальник) треста' },
  { ID: 21579, label: 'Директор управления Сбербанка' },
  { ID: 21580, label: 'Директор учебного пункта' },
  {
    ID: 21581,
    label: 'Директор (начальник) учебного (учебно-тренировочного) центра',
  },
  {
    ID: 21583,
    label: 'Директор учебно-курсового комбината',
  },
  {
    ID: 21586,
    label: 'Директор учебно-производственного комбината',
  },
  { ID: 21587, label: 'Директор училища (колледжа)' },
  {
    ID: 21588,
    label:
      'Директор учреждения социального обслуживания (центра социального обслуживания (пожилых и инвалидов), центра социальной помощи семье и детям, социально-реабилитационного центра для несовершеннолетних, социального приюта для детей и подростков и др.)',
  },
  { ID: 21589, label: 'Директор фабрики' },
  { ID: 21592, label: 'Директор (заведующий) филиала' },
  { ID: 21593, label: 'Директор фирмы' },
  { ID: 21595, label: 'Директор фонда' },
  {
    ID: 21598,
    label:
      'Директор (заведующий) хозяйства (лесоохотничьего, охотничьего и др.)',
  },
  {
    ID: 21603,
    label: 'Директор центра занятости населения',
  },
  {
    ID: 21604,
    label: 'Директор центра спортивной подготовки сборных команд',
  },
  {
    ID: 21605,
    label:
      'Директор центра (инженерного, музейно-выставочного, научно-методического, научно-технического творчества, по оценке качества сельскохозяйственных культур, стандартизации, метрологии и сертификации и др.)',
  },
  {
    ID: 21607,
    label:
      'Директор (заведующий) центра (информации для молодежи, консультативных услуг молодежи, ресоциализации молодежи, социально-психологической помощи молодежи)',
  },
  { ID: 21608, label: 'Директор цирка' },
  {
    ID: 21611,
    label: 'Директор шахты (шахтоуправления)',
  },
  {
    ID: 21614,
    label: 'Директор школы (гимназии, лицея)',
  },
  { ID: 21623, label: 'Дирижер' },
  { ID: 21629, label: 'Диспетчер' },
  {
    ID: 21635,
    label: 'Диспетчер автомобильного транспорта',
  },
  {
    ID: 21638,
    label:
      'Диспетчер аэродромного диспетчерского (авиационно-диспетчерского) пункта',
  },
  { ID: 21641, label: 'Диспетчер вагонного депо' },
  {
    ID: 21650,
    label: 'Диспетчер внутришахтного (шахтного) транспорта',
  },
  { ID: 21665, label: 'Диспетчер дорожный' },
  {
    ID: 21671,
    label: 'Диспетчер-инструктор службы движения',
  },
  { ID: 21674, label: 'Диспетчер локомотивного депо' },
  { ID: 21677, label: 'Диспетчер локомотивный дорожный' },
  {
    ID: 21680,
    label: 'Диспетчер локомотивный отделения железной дороги',
  },
  {
    ID: 21683,
    label: 'Диспетчер маневровый железнодорожной станции',
  },
  {
    ID: 21686,
    label: 'Диспетчер объединенного диспетчерского управления энергосистемы',
  },
  { ID: 21701, label: 'Диспетчер поездной' },
  { ID: 21704, label: 'Диспетчер пожарной связи' },
  {
    ID: 21706,
    label: 'Диспетчер по обеспечению питания пассажиров',
  },
  {
    ID: 21708,
    label:
      'Диспетчер по организации авиационных перевозок (пассажирских, почтово-грузовых, международных)',
  },
  {
    ID: 21710,
    label: 'Диспетчер по организации перевозок в приписных аэропортах',
  },
  {
    ID: 21716,
    label: 'Диспетчер по отпуску готовой продукции',
  },
  { ID: 21719, label: 'Диспетчер по перелетам' },
  {
    ID: 21725,
    label: 'Диспетчер по регулированию вагонного парка',
  },
  { ID: 21728, label: 'Диспетчер порта' },
  { ID: 21734, label: 'Диспетчер по флоту' },
  { ID: 21737, label: 'Диспетчер по центровке' },
  {
    ID: 21740,
    label: 'Диспетчер предприятия (района) сетей',
  },
  {
    ID: 21743,
    label: 'Диспетчер преобразовательного комплекса',
  },
  {
    ID: 21745,
    label:
      'Диспетчер производственно-диспетчерской службы (по контролю за подготовкой воздушных судов к вылету, по обеспечению суточного плана полетов, по организации информационно-справочной работы)',
  },
  {
    ID: 21748,
    label: 'Диспетчер регионального координационного центра поиска и спасания',
  },
  { ID: 21753, label: 'Диспетчер редакционный' },
  { ID: 21761, label: 'Диспетчер связи' },
  { ID: 21764, label: 'Диспетчер службы движения' },
  { ID: 21768, label: 'Диспетчер станционный' },
  { ID: 21776, label: 'Диспетчер факультета' },
  {
    ID: 21782,
    label: 'Диспетчер электромеханической службы',
  },
  { ID: 21785, label: 'Диспетчер электроподстанции' },
  { ID: 21788, label: 'Диспетчер эскалаторной службы' },
  { ID: 21790, label: 'Доверенный врач' },
  { ID: 21792, label: 'Документовед' },
  { ID: 21795, label: 'Доцент' },
  { ID: 21895, label: 'Заведующая машинописным бюро' },
  { ID: 21898, label: 'Заведующий аптекой' },
  { ID: 21899, label: 'Заведующий аптечным учреждением' },
  { ID: 21902, label: 'Заведующий архивом' },
  {
    ID: 21903,
    label:
      'Заведующий архивом (в аппарате Совета Федерации, Государственной Думы Федерального Собрания Российской Федерации)',
  },
  { ID: 21904, label: 'Заведующий архивохранилищем' },
  {
    ID: 21907,
    label: 'Заведующий аспирантурой (интернатурой, ординатурой)',
  },
  { ID: 21910, label: 'Заведующий ателье' },
  {
    ID: 21913,
    label: 'Заведующий аттракционным комплексом',
  },
  { ID: 21914, label: 'Заведующий аттракционом' },
  {
    ID: 21917,
    label: 'Заведующий базой (перевалочной, спортивной и др.)',
  },
  { ID: 21919, label: 'Заведующий баней' },
  { ID: 21921, label: 'Заведующий билетными кассами' },
  {
    ID: 21924,
    label:
      'Заведующий бюро (справочным, чертежно-копировальным, чертежным и др.)',
  },
  { ID: 21925, label: 'Заведующий бюро пропусков' },
  { ID: 21929, label: 'Заведующий ветеринарной аптекой' },
  {
    ID: 21931,
    label: 'Заведующий ветеринарной клиникой (лечебницей, поликлиникой)',
  },
  {
    ID: 21932,
    label: 'Заведующий ветеринарной лабораторией',
  },
  {
    ID: 21934,
    label: 'Заведующий ветеринарной станцией',
  },
  { ID: 21937, label: 'Заведующий ветеринарным пунктом' },
  {
    ID: 21938,
    label: 'Заведующий ветеринарным участком',
  },
  { ID: 21940, label: 'Заведующий виварием' },
  { ID: 21941, label: 'Заведующий видеотекой' },
  { ID: 21944, label: 'Заведующий геокамерой' },
  { ID: 21946, label: 'Заведующий глинохозяйством' },
  { ID: 21949, label: 'Заведующий горными работами' },
  { ID: 21950, label: 'Заведующий госсортоучастком' },
  { ID: 21952, label: 'Заведующий грузовым двором' },
  { ID: 21953, label: 'Заведующий группой' },
  {
    ID: 21959,
    label: 'Заведующий детским садом (детскими яслями, яслями-садом)',
  },
  { ID: 21961, label: 'Заведующий докторантурой' },
  { ID: 21963, label: 'Заведующий домом быта (моды)' },
  {
    ID: 21964,
    label: 'Заведующий домом отдыха локомотивных (поездных) бригад',
  },
  { ID: 21966, label: 'Заведующий душевой' },
  {
    ID: 21968,
    label:
      'Заведующий кабинетом (по технике безопасности, техническим, учебно-методическим, учебным и др.)',
  },
  { ID: 21970, label: 'Заведующий камерой хранения' },
  {
    ID: 21972,
    label: 'Заведующий камерой хранения (ручного багажа)',
  },
  { ID: 21975, label: 'Заведующий канцелярией' },
  {
    ID: 21977,
    label: 'Заведующий Канцелярией Президента Российской Федерации',
  },
  { ID: 21980, label: 'Заведующий кафедрой' },
  { ID: 21983, label: 'Заведующий кладбищем' },
  {
    ID: 21986,
    label: 'Заведующий кладовой (ломбарда, ценностей)',
  },
  { ID: 21987, label: 'Директор (заведующий) клуба' },
  { ID: 21990, label: 'Заведующий колумбарием' },
  {
    ID: 21992,
    label: 'Заведующий комнатой (матери и ребенка, отдыха)',
  },
  { ID: 21996, label: 'Заведующий конным двором' },
  {
    ID: 22001,
    label: 'Заведующий конторой (товарной и др.)',
  },
  {
    ID: 22003,
    label: 'Заведующий копировально-множительным бюро',
  },
  { ID: 22005, label: 'Заведующий корректорской' },
  {
    ID: 22006,
    label: 'Заведующий корреспондентским бюро (пунктом)',
  },
  { ID: 22007, label: 'Заведующий костюмерной' },
  { ID: 22010, label: 'Заведующий крематорием' },
  { ID: 22013, label: 'Заведующий кухней для животных' },
  {
    ID: 22014,
    label:
      'Заведующий лабораторией (в сельском, охотничьем, лесном и рыбном хозяйстве)',
  },
  {
    ID: 22015,
    label: 'Заведующий лабораторией (в промышленности)',
  },
  {
    ID: 22016,
    label: 'Заведующий лабораторией (научно-исследовательской)',
  },
  {
    ID: 22017,
    label: 'Заведующий лабораторией (в прочих отраслях)',
  },
  { ID: 22019, label: 'Заведующий летним театром' },
  { ID: 22022, label: 'Заведующий ломбардом' },
  { ID: 22023, label: 'Заведующий машинным двором' },
  { ID: 22024, label: 'Заведующий молочной кухней' },
  {
    ID: 22026,
    label: 'Заведующий научно-технической библиотекой',
  },
  { ID: 22028, label: 'Заведующий обстановкой' },
  { ID: 22031, label: 'Заведующий общежитием' },
  {
    ID: 22032,
    label: 'Заведующий объединенной редакцией',
  },
  { ID: 22034, label: 'Заведующий опытным полем' },
  {
    ID: 22035,
    label:
      'Заведующий отделением (в сельском, охотничьем, лесном и рыбном хозяйстве)',
  },
  {
    ID: 22037,
    label:
      'Заведующий отделением (на транспорте, в связи, материально-техническом снабжении и сбыте)',
  },
  {
    ID: 22038,
    label: 'Заведующий отделением (в прочих отраслях)',
  },
  {
    ID: 22040,
    label:
      'Заведующий отделом (в сельском, охотничьем, лесном и рыбном хозяйстве)',
  },
  { ID: 22041, label: 'Заведующий отделом (в торговле)' },
  {
    ID: 22043,
    label: 'Заведующий отделом (специализированным в прочих отраслях)',
  },
  {
    ID: 22044,
    label: 'Заведующий отделом (финансово-экономическим и административным)',
  },
  {
    ID: 22046,
    label: 'Заведующий отделом (по управлению кадрами и трудовыми отношениями)',
  },
  {
    ID: 22047,
    label: 'Заведующий отделом (по маркетингу и сбыту продукции)',
  },
  {
    ID: 22050,
    label: 'Заведующий отделом (рекламно-информационным)',
  },
  {
    ID: 22052,
    label: 'Заведующий отделом (материально-технического снабжения)',
  },
  {
    ID: 22053,
    label: 'Заведующий отделом (компьютерного обеспечения)',
  },
  {
    ID: 22055,
    label: 'Заведующий отделом (научно-технического развития)',
  },
  {
    ID: 22056,
    label: 'Заведующий отделом (функциональным в прочих областях деятельности)',
  },
  {
    ID: 22058,
    label: 'Заведующий очистными сооружениями',
  },
  { ID: 22061, label: 'Заведующий пакгаузом' },
  { ID: 22064, label: 'Заведующий парикмахерской' },
  {
    ID: 22067,
    label: 'Заведующий передвижной выставкой',
  },
  { ID: 22070, label: 'Заведующий питомником' },
  {
    ID: 22073,
    label: 'Заведующий платформой (пассажирской, сортировочной)',
  },
  {
    ID: 22077,
    label:
      'Заведующий площадкой (на транспорте, в связи, материально-техническом снабжении и сбыте)',
  },
  {
    ID: 22080,
    label: 'Заведующий площадкой (в прочих отраслях)',
  },
  { ID: 22082, label: 'Заведующий пляжем' },
  {
    ID: 22086,
    label: 'Заведующий подсобным производством (в строительстве)',
  },
  {
    ID: 22088,
    label: 'Заведующий подсобным производством (на транспорте)',
  },
  {
    ID: 22091,
    label: 'Заведующий практикой (производственной, учебной)',
  },
  { ID: 22094, label: 'Заведующий прачечной' },
  { ID: 22097, label: 'Заведующий (начальник) приемной' },
  {
    ID: 22100,
    label:
      'Заведующий производством (на предприятиях социально-бытового обслуживания населения)',
  },
  {
    ID: 22101,
    label: 'Заведующий производством (шеф-повар)',
  },
  {
    ID: 22104,
    label: 'Заведующий пунктом (приемным, проката и др.)',
  },
  { ID: 22108, label: 'Заведующий редакцией' },
  { ID: 22111, label: 'Заведующий рынком' },
  { ID: 22116, label: 'Заведующий санпропускником' },
  { ID: 22119, label: 'Заведующий свалкой' },
  {
    ID: 22123,
    label: 'Заведующий (начальник) секретариатом',
  },
  {
    ID: 22125,
    label: 'Заведующий Секретариатом Совета Безопасности Российской Федерации',
  },
  {
    ID: 22126,
    label: 'Заведующий секретариатом судебного состава',
  },
  {
    ID: 22127,
    label: 'Заведующий секретариатом судебной коллегии',
  },
  {
    ID: 22132,
    label: 'Заведующий сектором (специализированным)',
  },
  {
    ID: 22134,
    label: 'Заведующий сектором (научно-технического развития)',
  },
  {
    ID: 22135,
    label:
      'Заведующий сектором (функциональным в прочих областях деятельности)',
  },
  { ID: 22138, label: 'Заведующий секцией' },
  { ID: 22141, label: 'Заведующий складом' },
  {
    ID: 22144,
    label:
      'Заведующий станцией (в сельском, охотничьем, лесном и рыбном хозяйстве)',
  },
  {
    ID: 22146,
    label: 'Заведующий станцией (в промышленности)',
  },
  {
    ID: 22147,
    label: 'Заведующий станцией (в прочих отраслях)',
  },
  { ID: 22150, label: 'Заведующий столовой' },
  { ID: 22152, label: 'Заведующий студией' },
  { ID: 22153, label: 'Заведующий техническим архивом' },
  { ID: 22155, label: 'Заведующий товарным комплексом' },
  { ID: 22158, label: 'Заведующий труппой' },
  {
    ID: 22160,
    label: 'Заведующий учебно-консультационным пунктом',
  },
  {
    ID: 22161,
    label: 'Заведующий фильмобазой (фильмохранилищем)',
  },
  { ID: 22164, label: 'Заведующий фильмотекой' },
  {
    ID: 22165,
    label: 'Заведующий филиалом отделения Сбербанка',
  },
  {
    ID: 22167,
    label: 'Заведующий фондом (справочно-информационным)',
  },
  {
    ID: 22168,
    label: 'Заведующий фондом (в прочих областях деятельности)',
  },
  { ID: 22170, label: 'Заведующий фонотекой' },
  { ID: 22174, label: 'Заведующий фотографией' },
  { ID: 22176, label: 'Заведующий фотолабораторией' },
  { ID: 22181, label: 'Заведующий хозяйством' },
  { ID: 22184, label: 'Заведующий хранилищем' },
  { ID: 22185, label: 'Заведующий центральным складом' },
  { ID: 22186, label: 'Заведующий цеховой бухгалтерией' },
  { ID: 22187, label: 'Заведующий цехом' },
  {
    ID: 22189,
    label:
      'Заведующий частью (музыкальной, постановочной, учебной, художественной и др.)',
  },
  { ID: 22192, label: 'Заведующий экспедицией' },
  { ID: 22194, label: 'Заведующий этажом гостиницы' },
  {
    ID: 22200,
    label:
      'Заместитель начальника департамента (управления) в Аппарате Правительства Российской Федерации',
  },
  {
    ID: 22201,
    label:
      'Заместитель начальника департамента федерального органа исполнительной власти',
  },
  {
    ID: 22204,
    label:
      'Заместитель начальника отдела в аппарате Верховного Суда Российской Федерации',
  },
  {
    ID: 22205,
    label:
      'Заместитель начальника отдела в аппарате Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 22206,
    label:
      'Заместитель начальника отдела в аппарате Конституционного Суда Российской Федерации',
  },
  {
    ID: 22212,
    label:
      'Заместитель начальника отдела в составе департамента (управления) в Аппарате Правительства Российской Федерации',
  },
  {
    ID: 22213,
    label:
      'Заместитель начальника отдела в составе департамента, управления федерального органа исполнительной власти',
  },
  {
    ID: 22215,
    label:
      'Заместитель начальника отдела в составе управления Администрации Президента Российской Федерации',
  },
  {
    ID: 22219,
    label:
      'Заместитель начальника отдела в составе управления в аппарате Верховного Суда Российской Федерации',
  },
  {
    ID: 22220,
    label:
      'Заместитель начальника отдела в составе управления в аппарате Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 22222,
    label:
      'Заместитель начальника отдела в составе управления в аппарате Генеральной прокуратуры Российской Федерации',
  },
  {
    ID: 22223,
    label:
      'Заместитель начальника отдела в составе управления в Аппарате Государственной Думы Федерального Собрания',
  },
  {
    ID: 22224,
    label:
      'Заместитель начальника отдела в составе управления в аппарате Конституционного Суда Российской Федерации',
  },
  {
    ID: 22225,
    label:
      'Заместитель начальника отдела в составе управления в Аппарате Совета Федерации Федерального Собрания',
  },
  {
    ID: 22227,
    label:
      'Заместитель начальника отдела в составе управления в аппарате Центральной избирательной комиссии Российской Федерации',
  },
  {
    ID: 22228,
    label:
      'Заместитель начальника отдела в составе управления Президента Российской Федерации',
  },
  {
    ID: 22230,
    label:
      'Заместитель начальника отдела федерального органа исполнительной власти',
  },
  {
    ID: 22233,
    label:
      'Заместитель начальника управления Администрации Президента Российской Федерации',
  },
  {
    ID: 22234,
    label:
      'Заместитель начальника управления в аппарате Верховного Суда Российской Федерации',
  },
  {
    ID: 22236,
    label:
      'Заместитель начальника управления в аппарате Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 22237,
    label:
      'Заместитель начальника управления в аппарате Генеральной прокуратуры Российской Федерации',
  },
  {
    ID: 22239,
    label:
      'Заместитель начальника управления в Аппарате Государственной Думы Федерального Собрания',
  },
  {
    ID: 22240,
    label:
      'Заместитель начальника управления в аппарате Конституционного Суда Российской Федерации',
  },
  {
    ID: 22242,
    label:
      'Заместитель начальника управления в Аппарате Совета Федерации Федерального Собрания',
  },
  {
    ID: 22243,
    label:
      'Заместитель начальника управления в аппарате Центральной избирательной комиссии Российской Федерации',
  },
  {
    ID: 22245,
    label: 'Заместитель начальника управления Президента Российской Федерации',
  },
  {
    ID: 22246,
    label: 'Заместитель начальника управления федерального министерства',
  },
  {
    ID: 22248,
    label:
      'Заместитель начальника управления федерального органа исполнительной власти (кроме федерального министерства)',
  },
  {
    ID: 22249,
    label: 'Заместитель Председателя Верховного Суда Российской Федерации',
  },
  {
    ID: 22252,
    label:
      'Заместитель Председателя Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 22253,
    label:
      'Заместитель Председателя Государственной Думы Федерального Собрания',
  },
  {
    ID: 22254,
    label:
      'Заместитель председателя комитета (комиссии) Государственной Думы Федерального Собрания',
  },
  {
    ID: 22255,
    label:
      'Заместитель председателя комитета (комиссии) Совета Федерации Федерального Собрания',
  },
  {
    ID: 22256,
    label:
      'Заместитель Председателя Конституционного Суда Российской Федерации',
  },
  {
    ID: 22258,
    label: 'Заместитель Председателя Правительства Российской Федерации',
  },
  {
    ID: 22259,
    label:
      'Заместитель председателя совета, комиссии, комитета при Президенте Российской Федерации',
  },
  {
    ID: 22261,
    label: 'Заместитель Председателя Совета Федерации Федерального Собрания',
  },
  {
    ID: 22262,
    label:
      'Заместитель Председателя Судебной палаты по информационным спорам при Президенте Российской Федерации',
  },
  {
    ID: 22264,
    label: 'Заместитель Председателя Счетной палаты',
  },
  {
    ID: 22265,
    label: 'Заместитель председателя федерального суда',
  },
  {
    ID: 22267,
    label:
      'Заместитель Председателя Центральной избирательной комиссии Российской Федерации',
  },
  {
    ID: 22270,
    label:
      'Заместитель Руководителя Администрации Президента Российской Федерации',
  },
  {
    ID: 22271,
    label:
      'Заместитель Руководителя Аппарата Государственной Думы Федерального Собрания',
  },
  {
    ID: 22273,
    label:
      'Заместитель руководителя аппарата комитета (комиссии) Государственной Думы Федерального Собрания',
  },
  {
    ID: 22274,
    label:
      'Заместитель руководителя аппарата комитета (комиссии) Совета Федерации Федерального Собрания',
  },
  {
    ID: 22276,
    label:
      'Заместитель Руководителя Аппарата Правительства Российской Федерации',
  },
  {
    ID: 22279,
    label:
      'Заместитель Руководителя Аппарата Совета Федерации Федерального Собрания',
  },
  {
    ID: 22280,
    label:
      'Заместитель руководителя аппарата Центральной избирательной комиссии Российской Федерации',
  },
  {
    ID: 22282,
    label:
      'Заместитель руководителя Секретариата Заместителя Председателя Правительства Российской Федерации',
  },
  {
    ID: 22283,
    label:
      'Заместитель руководителя Секретариата заместителя Председателя Совета Федерации Федерального Собрания',
  },
  {
    ID: 22285,
    label:
      'Заместитель руководителя Секретариата Конституционного Суда Российской Федерации',
  },
  {
    ID: 22286,
    label:
      'Заместитель руководителя Секретариата первого заместителя Председателя Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 22288,
    label:
      'Заместитель руководителя Секретариата первого заместителя Председателя Государственной Думы Федерального Собрания',
  },
  {
    ID: 22289,
    label:
      'Заместитель руководителя Секретариата Первого заместителя Председателя Правительства Российской Федерации',
  },
  {
    ID: 22291,
    label:
      'Заместитель руководителя Секретариата Председателя Верховного Суда Российской Федерации',
  },
  {
    ID: 22292,
    label:
      'Заместитель руководителя Секретариата Председателя Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 22294,
    label:
      'Заместитель руководителя Секретариата Председателя Государственной Думы Федерального Собрания',
  },
  {
    ID: 22297,
    label:
      'Заместитель руководителя Секретариата Председателя Конституционного Суда Российской Федерации',
  },
  {
    ID: 22298,
    label:
      'Заместитель руководителя Секретариата Председателя Правительства Российской Федерации',
  },
  {
    ID: 22300,
    label:
      'Заместитель руководителя Секретариата Председателя Совета Федерации Федерального Собрания',
  },
  {
    ID: 22303,
    label:
      'Заместитель руководителя федерального органа исполнительной власти (кроме федерального министерства)',
  },
  {
    ID: 22305,
    label: 'Заместитель Секретаря Совета Безопасности Российской Федерации',
  },
  {
    ID: 22310,
    label: 'Заместитель федерального министра',
  },
  { ID: 22321, label: 'Звукооператор' },
  { ID: 22324, label: 'Звукооформитель' },
  { ID: 22327, label: 'Звукооформитель радиовещания' },
  { ID: 22330, label: 'Звукорежиссер' },
  { ID: 22332, label: 'Зоолог' },
  { ID: 22337, label: 'Зоотехник' },
  {
    ID: 22338,
    label: 'Зоотехник государственной заводской конюшни',
  },
  {
    ID: 22340,
    label:
      'Зоотехник отделения (комплекса, сельскохозяйственного участка, фермы)',
  },
  { ID: 22343, label: 'Зубной врач' },
  { ID: 22345, label: 'Зубной техник' },
  { ID: 22446, label: 'Инженер' },
  { ID: 22449, label: 'Инженер-авиамоделист' },
  { ID: 22452, label: 'Инженер авиационного отряда' },
  {
    ID: 22455,
    label: 'Инженер аэрофотосъемочного производства',
  },
  { ID: 22458, label: 'Инженер бортовой авиаотряда' },
  {
    ID: 22461,
    label: 'Инженер-диспетчер по движению флота (по флоту)',
  },
  {
    ID: 22465,
    label: 'Инженер инженерно-авиационной службы',
  },
  { ID: 22467, label: 'Инженер-инспектор' },
  { ID: 22468, label: 'Инженер-инспектор бортовой' },
  {
    ID: 22473,
    label: 'Инженер-инспектор по безопасности полетов',
  },
  {
    ID: 22475,
    label: 'Инженер(механик)-испытатель бортовой',
  },
  {
    ID: 22476,
    label: 'Инженер-испытатель летающей лаборатории бортовой',
  },
  { ID: 22479, label: 'Инженер-инструктор бортовой' },
  { ID: 22488, label: 'Инженер-исследователь' },
  {
    ID: 22489,
    label: 'Инженер-исследователь подводного аппарата',
  },
  { ID: 22491, label: 'Инженер-конструктор' },
  { ID: 22495, label: 'Инженер-контролер' },
  { ID: 22497, label: 'Инженер-лаборант' },
  { ID: 22500, label: 'Инженер-лесопатолог' },
  {
    ID: 22503,
    label: 'Инженер линейных сооружений связи и абонентских устройств',
  },
  { ID: 22506, label: 'Инженер-мелиоратор' },
  { ID: 22509, label: 'Инженер-механик' },
  {
    ID: 22513,
    label: 'Инженер-механик линейный по флоту',
  },
  { ID: 22516, label: 'Инженер-микробиолог' },
  {
    ID: 22521,
    label: 'Инженер по аварийно-спасательным работам',
  },
  {
    ID: 22524,
    label: 'Инженер по автоматизированным системам управления производством',
  },
  {
    ID: 22527,
    label:
      'Инженер по активным воздействиям на гидрометеорологические процессы',
  },
  {
    ID: 22530,
    label: 'Инженер по безопасности движения',
  },
  {
    ID: 22533,
    label:
      'Инженер по борьбе с аварийными разливами нефти и нефтепродуктов в море',
  },
  {
    ID: 22536,
    label: 'Инженер по бурению (буровым работам)',
  },
  {
    ID: 22539,
    label: 'Инженер по буровзрывным (взрывным) работам',
  },
  { ID: 22542, label: 'Инженер по вентиляции' },
  {
    ID: 22544,
    label: 'Инженер по внедрению новой техники и технологии',
  },
  { ID: 22546, label: 'Инженер по вышкостроению' },
  { ID: 22548, label: 'Инженер по глинистым растворам' },
  { ID: 22551, label: 'Инженер по горным работам' },
  {
    ID: 22554,
    label: 'Инженер по горюче-смазочным материалам',
  },
  {
    ID: 22557,
    label: 'Инженер по диагностике авиационной техники',
  },
  { ID: 22560, label: 'Инженер по добыче нефти и газа' },
  { ID: 22562, label: 'Инженер пожарной охраны' },
  { ID: 22564, label: 'Инженер по заливке скважин' },
  { ID: 22567, label: 'Инженер по защите информации' },
  { ID: 22569, label: 'Инженер по звукозаписи' },
  { ID: 22572, label: 'Инженер по землеустройству' },
  {
    ID: 22574,
    label: 'Инженер по инвентаризации строений и сооружений',
  },
  { ID: 22575, label: 'Инженер по инструменту' },
  {
    ID: 22578,
    label: 'Инженер по испытанию и обработке пленки',
  },
  { ID: 22581, label: 'Инженер по испытаниям' },
  { ID: 22583, label: 'Инженер по качеству' },
  {
    ID: 22585,
    label: 'Инженер по комплектации оборудования',
  },
  {
    ID: 22587,
    label: 'Инженер по контрольно-измерительным приборам и автоматике',
  },
  { ID: 22590, label: 'Инженер по креплению' },
  { ID: 22591, label: 'Инженер по креплению скважин' },
  { ID: 22593, label: 'Инженер по лесовосстановлению' },
  {
    ID: 22597,
    label: 'Инженер по лесосырьевым ресурсам',
  },
  {
    ID: 22599,
    label: 'Инженер по летно-методической работе',
  },
  { ID: 22602, label: 'Инженер по метрологии' },
  {
    ID: 22605,
    label: 'Инженер по автоматизации и механизации производственных процессов',
  },
  {
    ID: 22608,
    label: 'Инженер по механизации трудоемких процессов',
  },
  {
    ID: 22611,
    label: 'Инженер по надежности авиационной техники',
  },
  {
    ID: 22614,
    label: 'Инженер по надзору за строительством',
  },
  {
    ID: 22615,
    label: 'Инженер по надзору за строительством флота',
  },
  { ID: 22618, label: 'Инженер по наладке и испытаниям' },
  {
    ID: 22620,
    label:
      'Инженер по наладке, совершенствованию технологии и эксплуатации электрических станций и сетей',
  },
  {
    ID: 22623,
    label: 'Инженер по научно-технической информации',
  },
  { ID: 22625, label: 'Инженер по нормированию труда' },
  {
    ID: 22627,
    label: 'Инженер по опробованию и испытанию скважин',
  },
  {
    ID: 22629,
    label: 'Инженер по организации движения спецавтотранспорта',
  },
  {
    ID: 22632,
    label: 'Инженер по организации и нормированию труда',
  },
  {
    ID: 22635,
    label: 'Инженер по организации обеспечения авиационной техникой',
  },
  {
    ID: 22638,
    label: 'Инженер по организации перевозок',
  },
  { ID: 22640, label: 'Инженер по организации труда' },
  {
    ID: 22642,
    label: 'Инженер по организации управления производством',
  },
  {
    ID: 22644,
    label: 'Инженер по организации эксплуатации и ремонту',
  },
  {
    ID: 22647,
    label: 'Инженер по организации эксплуатации и ремонту зданий и сооружений',
  },
  {
    ID: 22650,
    label: 'Инженер по орнитологическому обеспечению безопасности полетов',
  },
  { ID: 22653, label: 'Инженер по охране и защите леса' },
  {
    ID: 22656,
    label: 'Инженер по охране окружающей среды (эколог)',
  },
  { ID: 22659, label: 'Инженер по охране труда' },
  {
    ID: 22662,
    label: 'Инженер по патентной и изобретательской работе',
  },
  {
    ID: 22665,
    label: 'Инженер по подводно-взрывным работам',
  },
  {
    ID: 22668,
    label: 'Инженер по подводно-техническим работам',
  },
  {
    ID: 22671,
    label: 'Инженер по подготовке и транспортировке нефти',
  },
  { ID: 22675, label: 'Инженер по подготовке кадров' },
  {
    ID: 22678,
    label: 'Инженер по подготовке производства',
  },
  {
    ID: 22681,
    label: 'Инженер по поддержанию пластового давления',
  },
  {
    ID: 22685,
    label: 'Инженер по приему локомотивов (вагонов) в депо',
  },
  {
    ID: 22687,
    label: 'Инженер по приему ремонтного фонда и выдаче готовой продукции',
  },
  {
    ID: 22690,
    label: 'Инженер по приему электроподвижного состава',
  },
  {
    ID: 22693,
    label:
      'Инженер по проектно-сметной работе (в транспортном и городском строительстве)',
  },
  {
    ID: 22696,
    label:
      'Инженер по проектно-сметной работе (в промышленном и гражданском строительстве)',
  },
  { ID: 22699, label: 'Инженер по профадаптации' },
  {
    ID: 22702,
    label: 'Инженер по радиовещательному оборудованию',
  },
  { ID: 22705, label: 'Инженер по радиолокации' },
  {
    ID: 22706,
    label: 'Инженер по радионавигации и радиолокации',
  },
  {
    ID: 22708,
    label: 'Инженер по радионавигации, радиолокации и связи',
  },
  {
    ID: 22711,
    label: 'Инженер по ракетно-артиллерийской технике',
  },
  { ID: 22714, label: 'Инженер по расчетам и режимам' },
  { ID: 22718, label: 'Инженер по ремонту' },
  {
    ID: 22721,
    label:
      'Инженер по ремонту и наладке электроэнергетического оборудования атомной станции',
  },
  { ID: 22725, label: 'Инженер по сварке' },
  {
    ID: 22729,
    label: 'Инженер по светотехническому обеспечению полетов',
  },
  {
    ID: 22733,
    label: 'Инженер по сложным работам в бурении (капитальном ремонте) скважин',
  },
  {
    ID: 22736,
    label: 'Инженер по специальным применениям авиации',
  },
  { ID: 22739, label: 'Инженер по стандартизации' },
  {
    ID: 22742,
    label: 'Инженер по судоподъемным работам',
  },
  {
    ID: 22745,
    label: 'Инженер по телевизионному оборудованию',
  },
  {
    ID: 22748,
    label: 'Инженер по теплофикации сельскохозяйственного предприятия',
  },
  {
    ID: 22751,
    label: 'Инженер по техническим средствам обучения',
  },
  {
    ID: 22752,
    label: 'Инженер по техническим средствам реабилитации инвалидов',
  },
  {
    ID: 22754,
    label:
      'Инженер по эксплуатации вентиляционных систем и санитарно-технического оборудования',
  },
  {
    ID: 22756,
    label: 'Инженер по эксплуатации специального оборудования автомобилей',
  },
  {
    ID: 22760,
    label: 'Инженер по эксплуатации теплотехнического оборудования',
  },
  { ID: 22762, label: 'Инженер по техническому надзору' },
  {
    ID: 22766,
    label: 'Инженер по техническому обслуживанию авиационной техники',
  },
  { ID: 22770, label: 'Инженер по транспорту' },
  {
    ID: 22773,
    label: 'Инженер по управлению блоком атомной станции',
  },
  {
    ID: 22776,
    label:
      'Инженер по управлению реактором (ускорителем, ядерно-физической установкой)(в научно-исследовательских организациях)',
  },
  {
    ID: 22778,
    label:
      'Инженер по управлению реактором (ускорителем, ядерно-физической установкой) (в энергетике)',
  },
  {
    ID: 22782,
    label: 'Инженер по управлению турбиной атомной станции',
  },
  {
    ID: 22785,
    label:
      'Инженер по эксплуатации авиационного оборудования объективного контроля',
  },
  {
    ID: 22788,
    label: 'Инженер по эксплуатации аэродромов',
  },
  {
    ID: 22791,
    label:
      'Инженер по эксплуатации аэрофотосъемочного (фотолабораторного) оборудования',
  },
  {
    ID: 22794,
    label: 'Инженер по эксплуатации воздушных судов (систем воздушных судов)',
  },
  {
    ID: 22797,
    label:
      'Инженер по эксплуатации гидрометеорологических приборов, оборудования и систем',
  },
  {
    ID: 22800,
    label: 'Инженер по эксплуатации машинно-тракторного парка',
  },
  {
    ID: 22803,
    label: 'Инженер по эксплуатации нефтегазопроводов',
  },
  {
    ID: 22806,
    label: 'Инженер по эксплуатации оборудования газовых объектов',
  },
  {
    ID: 22809,
    label:
      'Инженер по эксплуатации сооружений и оборудования водопроводно-канализационного хозяйства',
  },
  {
    ID: 22811,
    label:
      'Инженер по эксплуатации технических средств железных дорог (метрополитенов)',
  },
  {
    ID: 22812,
    label: 'Инженер по эксплуатации тренажеров',
  },
  {
    ID: 22815,
    label: 'Инженер по электротехническому обеспечению полетов',
  },
  {
    ID: 22818,
    label: 'Инженер по электрохимической защите',
  },
  { ID: 22819, label: 'Инженер по энергонадзору' },
  {
    ID: 22821,
    label: 'Инженер преобразовательного комплекса',
  },
  { ID: 22824, label: 'Инженер-программист' },
  { ID: 22827, label: 'Инженер-проектировщик' },
  { ID: 22830, label: 'Инженер-протезист' },
  { ID: 22833, label: 'Инженер-радиолог' },
  { ID: 22836, label: 'Инженер-радиофизик' },
  { ID: 22839, label: 'Инженер-радиохимик' },
  {
    ID: 22842,
    label: 'Инженер садово-паркового хозяйства',
  },
  { ID: 22845, label: 'Инженер специального флота' },
  { ID: 22847, label: 'Инженер социальной связи' },
  {
    ID: 22848,
    label: 'Инженер средств радио и телевидения',
  },
  { ID: 22851, label: 'Инженер-таксатор' },
  { ID: 22854, label: 'Инженер-технолог' },
  { ID: 22857, label: 'Инженер-технолог-протезист' },
  { ID: 22860, label: 'Инженер-химик' },
  { ID: 22864, label: 'Инженер-электроник' },
  { ID: 22867, label: 'Инженер-электрорадионавигатор' },
  { ID: 22870, label: 'Инженер электросвязи' },
  { ID: 22873, label: 'Инженер-энергетик' },
  { ID: 22890, label: 'Инкассатор' },
  { ID: 22893, label: 'Инокорреспондент' },
  { ID: 22896, label: 'Инспектор' },
  {
    ID: 22898,
    label: 'Инспектор во взрывоопасных производствах',
  },
  {
    ID: 22901,
    label: 'Инспектор военизированной охраны',
  },
  { ID: 22904, label: 'Инспектор-врач' },
  {
    ID: 22907,
    label: 'Инспектор высших учебных заведений',
  },
  { ID: 22910, label: 'Инспектор газотехнический' },
  { ID: 22913, label: 'Инспектор горно-технический' },
  { ID: 22915, label: 'Инспектор-делопроизводитель' },
  {
    ID: 22916,
    label: 'Инспектор дипломно-паспортного бюро и групп морских портов',
  },
  { ID: 22919, label: 'Инспектор дорожный' },
  {
    ID: 22920,
    label: 'Инспектор дорожный по охране окружающей среды',
  },
  { ID: 22922, label: 'Инспектор-капитан' },
  { ID: 22925, label: 'Инспектор кредитный' },
  {
    ID: 22928,
    label: 'Инспектор летно-производственной службы',
  },
  {
    ID: 22931,
    label: 'Инспектор манежа (ведущий представление)',
  },
  { ID: 22933, label: 'Инспектор морской' },
  { ID: 22939, label: 'Инспектор обменного пункта' },
  { ID: 22941, label: 'Инспектор-пилот' },
  {
    ID: 22944,
    label: 'Инспектор по военно-техническим видам спорта',
  },
  {
    ID: 22947,
    label: 'Инспектор по военно-технической подготовке',
  },
  {
    ID: 22950,
    label: 'Инспектор по дошкольному воспитанию, внешкольной работе',
  },
  { ID: 22953, label: 'Инспектор по инвентаризации' },
  { ID: 22956, label: 'Инспектор по кадрам' },
  {
    ID: 22960,
    label: 'Инспектор по кадрам загранплавания',
  },
  {
    ID: 22963,
    label: 'Инспектор по качеству и приемке строительно-монтажных работ',
  },
  {
    ID: 22965,
    label: 'Инспектор по контролю за исполнением поручений',
  },
  {
    ID: 22968,
    label: 'Инспектор по контролю за техническим содержанием зданий',
  },
  { ID: 22971, label: 'Инспектор по контролю за ценами' },
  { ID: 22974, label: 'Инспектор по котлонадзору' },
  {
    ID: 22977,
    label: 'Инспектор по оборонно-массовой работе',
  },
  {
    ID: 22980,
    label: 'Инспектор по организации инкассации и перевозке ценностей',
  },
  {
    ID: 22984,
    label: 'Инспектор по основной деятельности',
  },
  { ID: 22987, label: 'Инспектор по охране детства' },
  {
    ID: 22990,
    label: 'Инспектор по охране труда и технике безопасности',
  },
  {
    ID: 22993,
    label: 'Инспектор по расследованию аварий судов',
  },
  { ID: 22996, label: 'Инспектор портового надзора' },
  { ID: 22998, label: 'Инспектор по туризму' },
  {
    ID: 23002,
    label:
      'Инспектор по учебной, воспитательной, методической работе, производственному обучению и начальной военной подготовке',
  },
  {
    ID: 23005,
    label: 'Инспектор по учету и бронированию военнообязанных',
  },
  {
    ID: 23008,
    label: 'Инспектор по учету и распределению жилой площади',
  },
  {
    ID: 23011,
    label:
      'Инспектор по эксплуатационным, производственно-техническим и организационным вопросам',
  },
  { ID: 23013, label: 'Инспектор-приемщик заводской' },
  { ID: 23016, label: 'Инспектор-провизор' },
  { ID: 23019, label: 'Инспектор-проводник бортовой' },
  { ID: 23022, label: 'Инспектор-ревизор' },
  { ID: 23025, label: 'Инспектор санитарный' },
  {
    ID: 23029,
    label:
      'Инспектор средних профессиональных и профессионально-технических учебных заведений',
  },
  { ID: 23031, label: 'Инспектор торговый' },
  {
    ID: 23032,
    label: 'Инспектор центра занятости населения',
  },
  {
    ID: 23034,
    label: 'Инспектор школ (гимназий, лицеев)',
  },
  { ID: 23037, label: 'Инспектор электросвязи' },
  { ID: 23040, label: 'Инспектор энергоинспекции' },
  { ID: 23050, label: 'Инструктор' },
  { ID: 23052, label: 'Инструктор авиапожарной команды' },
  { ID: 23055, label: 'Инструктор взрывных работ' },
  { ID: 23058, label: 'Инструктор водной станции' },
  {
    ID: 23063,
    label: 'Инструктор военного комиссариата',
  },
  {
    ID: 23066,
    label: 'Инструктор вспомогательной команды',
  },
  {
    ID: 23069,
    label: 'Инструктор газоспасательной станции',
  },
  { ID: 23070, label: 'Инструктор гражданской обороны' },
  { ID: 23071, label: 'Инструктор-дезинфектор' },
  { ID: 23072, label: 'Инструктор-дактилолог' },
  { ID: 23074, label: 'Инструктор-дельтапланерист' },
  {
    ID: 23077,
    label: 'Инструктор клуба служебного собаководства',
  },
  {
    ID: 23080,
    label: 'Инструктор - космонавт-испытатель',
  },
  {
    ID: 23084,
    label: 'Инструктор - космонавт-исследователь',
  },
  { ID: 23086, label: 'Инструктор крановых бригад' },
  {
    ID: 23087,
    label: 'Инструктор культурно-просветительного учреждения',
  },
  {
    ID: 23090,
    label: 'Инструктор легководолазного дела',
  },
  { ID: 23093, label: 'Инструктор летчик-методист' },
  {
    ID: 23096,
    label: 'Инструктор малокалиберного, пневматического тира',
  },
  {
    ID: 23101,
    label: 'Инструктор-методист контрольно-спасательной службы (отряда)',
  },
  {
    ID: 23103,
    label: 'Инструктор-методист по адаптивной физической культуре',
  },
  {
    ID: 23104,
    label: 'Инструктор-методист по альпинизму',
  },
  {
    ID: 23107,
    label: 'Инструктор-методист по дельтапланеризму',
  },
  {
    ID: 23108,
    label: 'Инструктор-методист по лечебной физкультуре',
  },
  {
    ID: 23110,
    label: 'Инструктор-методист по производственной гимнастике',
  },
  {
    ID: 23113,
    label:
      'Инструктор-методист по техническим и военно-прикладным видам спорта',
  },
  { ID: 23116, label: 'Инструктор-методист по туризму' },
  {
    ID: 23119,
    label: 'Инструктор-методист спортивной школы',
  },
  {
    ID: 23122,
    label: 'Инструктор-методист физкультурно-спортивных организаций',
  },
  { ID: 23125, label: 'Инструктор-парашютист' },
  {
    ID: 23128,
    label: 'Инструктор парашютно-десантной подготовки',
  },
  { ID: 23131, label: 'Инструктор парашютной вышки' },
  {
    ID: 23134,
    label: 'Инструктор парашютной и десантно-пожарной службы',
  },
  { ID: 23137, label: 'Инструктор парашютной службы' },
  {
    ID: 23140,
    label: 'Инструктор парашютно-пожарной (десантно-пожарной) команды (группы)',
  },
  {
    ID: 23144,
    label: 'Инструктор-переводчик по обслуживанию глухих рабочих',
  },
  {
    ID: 23147,
    label: 'Инструктор по военно-морской подготовке',
  },
  {
    ID: 23148,
    label: 'Инструктор по гигиеническому воспитанию',
  },
  {
    ID: 23150,
    label: 'Инструктор по заготовительной деятельности',
  },
  {
    ID: 23153,
    label: 'Инструктор по лечебной физкультуре',
  },
  {
    ID: 23156,
    label:
      'Инструктор по организации службы отряда военизированной, пожарной и сторожевой охраны',
  },
  {
    ID: 23159,
    label: 'Инструктор по организационно-массовой работе',
  },
  {
    ID: 23162,
    label: 'Инструктор по основной деятельности',
  },
  {
    ID: 23165,
    label: 'Инструктор по противопожарной профилактике',
  },
  { ID: 23168, label: 'Инструктор по спорту' },
  { ID: 23171, label: 'Инструктор по трудовой терапии' },
  { ID: 23174, label: 'Инструктор по труду' },
  {
    ID: 23177,
    label: 'Инструктор по физической культуре',
  },
  { ID: 23180, label: 'Инструктор-проводник бортовой' },
  {
    ID: 23183,
    label: 'Инструктор производственного обучения рабочих массовых профессий',
  },
  {
    ID: 23186,
    label: 'Инструктор служебного собаководства',
  },
  { ID: 23188, label: 'Инструктор слухового кабинета' },
  {
    ID: 23192,
    label: 'Инструктор спортивного рыболовства',
  },
  { ID: 23195, label: 'Инструктор тренажера' },
  {
    ID: 23197,
    label: 'Инструктор учебно-тренировочного пункта',
  },
  { ID: 23204, label: 'Искусствовед' },
  { ID: 23207, label: 'Ихтиолог' },
  { ID: 23209, label: 'Ихтиопатолог' },
  { ID: 23307, label: 'Казначей' },
  { ID: 23309, label: 'Калибровщик' },
  { ID: 23311, label: 'Калькулятор' },
  {
    ID: 23315,
    label: 'Кандидат в космонавты-испытатели',
  },
  {
    ID: 23318,
    label: 'Кандидат в космонавты-исследователи',
  },
  { ID: 23321, label: 'Капельмейстер' },
  { ID: 23324, label: 'Капитан' },
  { ID: 23327, label: 'Капитан-директор' },
  { ID: 23330, label: 'Капитан-кранмейстер' },
  {
    ID: 23333,
    label:
      'Капитан-механик (водолазного, спасательного судна, моторного катера)',
  },
  { ID: 23336, label: 'Капитан-наставник' },
  { ID: 23339, label: 'Капитан подводного аппарата' },
  {
    ID: 23342,
    label: 'Капитан порта (портового пункта)',
  },
  { ID: 23345, label: 'Капитан портового надзора' },
  { ID: 23348, label: 'Капитан рейда' },
  { ID: 23351, label: 'Капитан сдаточный' },
  {
    ID: 23354,
    label:
      'Капитан (старший моторист-рулевой) патрульного, спасательного катера',
  },
  { ID: 23357, label: 'Капитан (старшина, шкипер)' },
  { ID: 23360, label: 'Капитан флота' },
  { ID: 23363, label: 'Картограф' },
  { ID: 23366, label: 'Картограф-составитель' },
  { ID: 23367, label: 'Каскадер' },
  { ID: 23369, label: 'Кассир' },
  { ID: 23372, label: 'Кассир (билетный)' },
  {
    ID: 23375,
    label: 'Кассир багажный, товарный (грузовой)',
  },
  { ID: 23378, label: 'Кассир-эксперт' },
  { ID: 23381, label: 'Кинооператор' },
  {
    ID: 23384,
    label: 'Кинооператор (фотооператор) бортовой',
  },
  {
    ID: 23387,
    label: 'Кинооператор комбинированных съемок',
  },
  { ID: 23388, label: 'Кинооператор-корреспондент' },
  { ID: 23391, label: 'Кинооператор-постановщик' },
  { ID: 23393, label: 'Кинорежиссер' },
  {
    ID: 23396,
    label: 'Классификатор текстильного сырья',
  },
  { ID: 23399, label: 'Классный воспитатель' },
  { ID: 23403, label: 'Кодировщик' },
  { ID: 23406, label: 'Кодификатор' },
  { ID: 23409, label: 'Колорист' },
  { ID: 23412, label: 'Командир авиационного звена' },
  { ID: 23415, label: 'Командир авиационного отряда' },
  { ID: 23418, label: 'Командир авиационной эскадрильи' },
  { ID: 23421, label: 'Командир взвода' },
  {
    ID: 23424,
    label:
      'Командир военизированного отряда по активному воздействию на гидрометеорологические процессы',
  },
  {
    ID: 23427,
    label:
      'Командир военизированной части по активному воздействию на гидрометеорологические процессы',
  },
  { ID: 23431, label: 'Командир воздушного судна' },
  {
    ID: 23435,
    label: 'Командир вспомогательной горноспасательной команды',
  },
  {
    ID: 23438,
    label: 'Командир дноочистительного снаряда',
  },
  { ID: 23441, label: 'Командир землесоса, земснаряда' },
  { ID: 23444, label: 'Командир-наставник' },
  { ID: 23447, label: 'Командир огневой точки' },
  {
    ID: 23450,
    label: 'Командир отделения (горно-спасательной, пожарной части)',
  },
  { ID: 23453, label: 'Командир отряда' },
  {
    ID: 23456,
    label: 'Командир (пилот, летчик) воздушного судна - инструктор',
  },
  {
    ID: 23459,
    label:
      'Командир пункта (газоспасательного, горно-спасательного, подземного)',
  },
  {
    ID: 23462,
    label: 'Командир учебно-тренировочного центра',
  },
  { ID: 23465, label: 'Командир части' },
  { ID: 23468, label: 'Комендант' },
  {
    ID: 23472,
    label: 'Комендант аэровокзала (агентства)',
  },
  {
    ID: 23475,
    label: 'Комендант аэродрома (дельтадрома)',
  },
  { ID: 23481, label: 'Комендант здания' },
  { ID: 23483, label: 'Комендант лагеря' },
  { ID: 23486, label: 'Комендант объекта' },
  { ID: 23489, label: 'Комиссар аварийный' },
  { ID: 23492, label: 'Комментатор' },
  { ID: 23496, label: 'Коммивояжер' },
  { ID: 23500, label: 'Конструктор' },
  { ID: 23502, label: 'Конструктор обуви' },
  { ID: 23504, label: 'Конструктор одежды' },
  { ID: 23506, label: 'Консул' },
  { ID: 23507, label: 'Консул Российской Федерации' },
  { ID: 23508, label: 'Консул-советник' },
  { ID: 23509, label: 'Консультант' },
  {
    ID: 23510,
    label:
      'Консультант (в территориальном органе федерального органа исполнительной власти, федеральном суде)',
  },
  {
    ID: 23511,
    label: 'Консультант в Администрации Президента Российской Федерации',
  },
  {
    ID: 23512,
    label: 'Консультант в аппарате Верховного Суда Российской Федерации',
  },
  {
    ID: 23515,
    label:
      'Консультант в аппарате Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 23516,
    label:
      'Консультант в аппарате Генеральной прокуратуры Российской Федерации',
  },
  {
    ID: 23517,
    label: 'Консультант в Аппарате Государственной Думы Федерального Собрания',
  },
  {
    ID: 23520,
    label: 'Консультант в аппарате Конституционного Суда Российской Федерации',
  },
  {
    ID: 23521,
    label: 'Консультант в Аппарате Правительства Российской Федерации',
  },
  {
    ID: 23524,
    label: 'Консультант в Аппарате Совета Федерации Федерального Собрания',
  },
  {
    ID: 23525,
    label: 'Консультант в аппарате Счетной палаты Российской Федерации',
  },
  {
    ID: 23527,
    label:
      'Консультант в аппарате Центральной избирательной комиссии Российской Федерации',
  },
  {
    ID: 23529,
    label:
      'Консультант в центральном аппарате федерального органа исполнительной власти',
  },
  {
    ID: 23533,
    label: 'Консультант государственной нотариальной конторы',
  },
  {
    ID: 23536,
    label: 'Консультант издательства, редакции газет и журналов',
  },
  { ID: 23537, label: 'Консультант по налогам и сборам' },
  {
    ID: 23538,
    label: 'Консультант по профессиональной реабилитации инвалидов',
  },
  {
    ID: 23539,
    label: 'Консультант по экономическим вопросам',
  },
  {
    ID: 23548,
    label: 'Контролер (Сберегательного банка)',
  },
  { ID: 23551, label: 'Контролер билетов' },
  { ID: 23554, label: 'Контролер ломбарда' },
  {
    ID: 23557,
    label: 'Контролер пассажирского транспорта',
  },
  { ID: 23560, label: 'Контролер перонный (билетный)' },
  { ID: 23562, label: 'Контролер-ревизор' },
  {
    ID: 23563,
    label: 'Контролер-ревизор пассажирских поездов',
  },
  { ID: 23566, label: 'Контролер рынка' },
  {
    ID: 23569,
    label: 'Контролер технический почтовых вагонов',
  },
  { ID: 23572, label: 'Контролер узла связи' },
  { ID: 23574, label: 'Контролер фильмов кинопроката' },
  { ID: 23578, label: 'Конфекционер' },
  { ID: 23581, label: 'Концертмейстер' },
  { ID: 23584, label: 'Концертмейстер по классу балета' },
  { ID: 23587, label: 'Концертмейстер по классу вокала' },
  { ID: 23590, label: 'Копировщик' },
  { ID: 23593, label: 'Корректор' },
  {
    ID: 23596,
    label: 'Корректор морских карт и руководств для плавания',
  },
  { ID: 23599, label: 'Корреспондент' },
  {
    ID: 23602,
    label: 'Корреспондент издательства, редакции газет и журналов',
  },
  { ID: 23605, label: 'Корреспондент собственный' },
  { ID: 23608, label: 'Корреспондент специальный' },
  { ID: 23611, label: 'Космонавт-испытатель' },
  { ID: 23614, label: 'Космонавт-исследователь' },
  { ID: 23617, label: 'Кранмейстер' },
  { ID: 23621, label: 'Крупчатник' },
  { ID: 23624, label: 'Крупье' },
  { ID: 23628, label: 'Крупянщик' },
  { ID: 23632, label: 'Культорганизатор' },
  {
    ID: 23635,
    label: 'Культорганизатор детских внешкольных учреждений',
  },
  { ID: 23690, label: 'Лаборант' },
  { ID: 23691, label: 'Лаборант (средней квалификации)' },
  {
    ID: 23694,
    label: 'Лаборант-исследователь (в области физики)',
  },
  {
    ID: 23696,
    label: 'Лаборант-исследователь (в области химии)',
  },
  {
    ID: 23699,
    label: 'Лаборант-исследователь (в области биологии)',
  },
  {
    ID: 23703,
    label: 'Лаборант-исследователь (в области бактериологии и фармакологии)',
  },
  { ID: 23705, label: 'Ландшафтный архитектор' },
  { ID: 23706, label: 'Лектор (экскурсовод)' },
  {
    ID: 23709,
    label: 'Лесник (государственный инспектор по охране леса)',
  },
  {
    ID: 23713,
    label: 'Лесничий (старший государственный инспектор по охране леса)',
  },
  { ID: 23717, label: 'Летчик' },
  { ID: 23720, label: 'Летчик-инструктор' },
  {
    ID: 23723,
    label: 'Летчик - инструктор парашютной подготовки',
  },
  { ID: 23726, label: 'Летчик-испытатель' },
  { ID: 23729, label: 'Летчик-наблюдатель' },
  { ID: 23733, label: 'Литературный сотрудник' },
  { ID: 23736, label: 'Логопед' },
  { ID: 23739, label: 'Лоцман' },
  { ID: 23785, label: 'Маркшейдер' },
  {
    ID: 23788,
    label: 'Маркшейдер карьера, рудника, шахты',
  },
  { ID: 23791, label: 'Массажист' },
  { ID: 23796, label: 'Мастер' },
  {
    ID: 23799,
    label:
      'Мастер аварийно-спасательных, судоподъемных, подводно-технических и других специальных работ',
  },
  {
    ID: 23802,
    label: 'Мастер базы технического обслуживания флота',
  },
  { ID: 23806, label: 'Мастер буровой' },
  {
    ID: 23809,
    label: 'Мастер буровой глубокого (структурно-поискового) бурения',
  },
  { ID: 23812, label: 'Мастер буровой скважин' },
  { ID: 23815, label: 'Мастер вагонного депо' },
  { ID: 23817, label: 'Мастер водолазных работ' },
  { ID: 23821, label: 'Мастер выправительных работ' },
  { ID: 23824, label: 'Мастер газодымозащитной службы' },
  { ID: 23827, label: 'Мастер грузового района' },
  {
    ID: 23831,
    label: 'Мастер дистанции (гражданских сооружений, электроснабжения и др.)',
  },
  { ID: 23834, label: 'Мастер дорожный' },
  { ID: 23837, label: 'Мастер дренажной шахты' },
  {
    ID: 23840,
    label: 'Мастер контрольный (участка, цеха)',
  },
  {
    ID: 23843,
    label: 'Мастер леса (участковый государственный инспектор по охране леса)',
  },
  { ID: 23846, label: 'Мастер локомотивного депо' },
  {
    ID: 23851,
    label: 'Мастер мастерской специальной техники и оборудования',
  },
  { ID: 23855, label: 'Мастер мостовой' },
  {
    ID: 23857,
    label: 'Мастер на лесосеках и первичном лесосплаве',
  },
  {
    ID: 23859,
    label: 'Мастер объединенных мастерских метрополитена',
  },
  {
    ID: 23861,
    label: 'Мастер парка (понтонного, такелажного)',
  },
  {
    ID: 23864,
    label: 'Мастер по благоустройству территорий аэропорта',
  },
  {
    ID: 23867,
    label: 'Мастер погрузочно-разгрузочных работ',
  },
  {
    ID: 23870,
    label: 'Мастер по добыче нефти, газа и конденсата',
  },
  { ID: 23873, label: 'Мастер по добыче рыбы' },
  {
    ID: 23876,
    label: 'Мастер поезда (восстановительного, рельсосварочного)',
  },
  { ID: 23879, label: 'Мастер по исследованию скважин' },
  {
    ID: 23881,
    label: 'Мастер по комплексной автоматизации и телемеханике',
  },
  { ID: 23890, label: 'Мастер по обработке рыбы' },
  {
    ID: 23893,
    label: 'Мастер по опробованию (испытанию) скважин',
  },
  {
    ID: 23895,
    label: 'Мастер по освоению и ремонту нагнетательных скважин',
  },
  { ID: 23900, label: 'Мастер по подготовке газа' },
  {
    ID: 23902,
    label: 'Мастер по подготовке и стабилизации нефти',
  },
  { ID: 23905, label: 'Мастер по промысловой геофизике' },
  {
    ID: 23911,
    label: 'Мастер по проходке горных выработок',
  },
  { ID: 23914, label: 'Мастер по ремонту' },
  {
    ID: 23917,
    label: 'Мастер по ремонту гидросооружений',
  },
  {
    ID: 23920,
    label:
      'Мастер по ремонту, наладке, испытаниям и пуску оборудования атомных станций',
  },
  {
    ID: 23922,
    label: 'Мастер по ремонту оборудования (в промышленности)',
  },
  {
    ID: 23923,
    label: 'Мастер по ремонту оборудования (на транспорте)',
  },
  {
    ID: 23929,
    label: 'Мастер по ремонту приборов и аппаратуры',
  },
  {
    ID: 23935,
    label: 'Мастер по ремонту скважин (капитальному, подземному)',
  },
  {
    ID: 23936,
    label: 'Мастер по ремонту технологического оборудования',
  },
  { ID: 23938, label: 'Мастер по ремонту транспорта' },
  {
    ID: 23942,
    label: 'Мастер по ремонту экспедиционного оборудования и снаряжения',
  },
  { ID: 23944, label: 'Мастер портовых мастерских' },
  {
    ID: 23947,
    label: 'Мастер по сложным работам в бурении (капитальном ремонте) скважин',
  },
  { ID: 23950, label: 'Мастер пошивочной мастерской' },
  {
    ID: 23953,
    label: 'Мастер по эксплуатации и ремонту машин и механизмов',
  },
  {
    ID: 23956,
    label: 'Мастер по эксплуатации оборудования газовых объектов',
  },
  {
    ID: 23959,
    label: 'Мастер преобразовательного комплекса',
  },
  {
    ID: 23962,
    label: 'Мастер производственного обучения',
  },
  {
    ID: 23963,
    label: 'Мастер производственной лаборатории',
  },
  {
    ID: 23968,
    label: 'Мастер промывочно-пропарочной станции (поезда, пункта)',
  },
  { ID: 23972, label: 'Мастер проходки шахты' },
  { ID: 23974, label: 'Мастер путевых работ' },
  {
    ID: 23977,
    label: 'Мастер ремонтно-механической мастерской',
  },
  {
    ID: 23980,
    label: 'Мастер ремонтно-отстойного пункта',
  },
  {
    ID: 23983,
    label: 'Мастер ремонтно-строительной группы',
  },
  { ID: 23986, label: 'Мастер службы' },
  {
    ID: 23989,
    label:
      'Мастер станции по освидетельствованию надувных спасательных средств и контрольно-измерительных приборов',
  },
  {
    ID: 23991,
    label: 'Мастер строительных и монтажных работ',
  },
  { ID: 23993, label: 'Мастер типографии' },
  { ID: 23996, label: 'Мастер тоннельный' },
  { ID: 23998, label: 'Мастер участка' },
  { ID: 24001, label: 'Мастер учебного полигона' },
  { ID: 24004, label: 'Мастер учебного центра' },
  {
    ID: 24007,
    label: 'Мастер учебно-производственной мастерской',
  },
  { ID: 24010, label: 'Мастер хлебопекарни' },
  {
    ID: 24011,
    label: 'Мастер-художник по созданию и реставрации музыкальных инструментов',
  },
  { ID: 24013, label: 'Мастер цеха' },
  { ID: 24016, label: 'Мастер электродепо' },
  {
    ID: 24019,
    label: 'Мастер электрорадионавигационной камеры',
  },
  { ID: 24026, label: 'Математик' },
  {
    ID: 24028,
    label:
      'Машинист-инструктор бригад путевых машин и моторно-рельсового транспорта',
  },
  {
    ID: 24029,
    label: 'Машинист-инструктор локомотивных бригад',
  },
  { ID: 24031, label: 'Машинистка' },
  {
    ID: 24033,
    label: 'Машинистка, работающая с иностранным текстом',
  },
  { ID: 24035, label: 'Машинистка редакции' },
  { ID: 24038, label: 'Медицинская сестра' },
  { ID: 24039, label: 'Медицинская сестра милосердия' },
  { ID: 24040, label: 'Медицинский дезинфектор' },
  { ID: 24041, label: 'Медицинский психолог' },
  { ID: 24042, label: 'Медицинский регистратор' },
  { ID: 24043, label: 'Медицинский лабораторный техник' },
  { ID: 24045, label: 'Медицинский статистик' },
  { ID: 24046, label: 'Медицинский технолог' },
  { ID: 24047, label: 'Менеджер' },
  {
    ID: 24048,
    label: 'Менеджер (в сельском охотничьем, лесном и рыбном хозяйстве)',
  },
  { ID: 24049, label: 'Менеджер (в промышленности)' },
  { ID: 24050, label: 'Менеджер (в строительстве)' },
  { ID: 24051, label: 'Менеджер (в торговле)' },
  {
    ID: 24053,
    label: 'Менеджер (в общественном питании и гостиничном обслуживании)',
  },
  {
    ID: 24054,
    label:
      'Менеджер (на транспорте, в связи, материально-техническом снабжении и сбыте)',
  },
  {
    ID: 24057,
    label: 'Менеджер (в коммерческой деятельности)',
  },
  {
    ID: 24059,
    label: 'Менеджер (в социально-бытовом обслуживании населения)',
  },
  { ID: 24060, label: 'Менеджер (в прочих отраслях)' },
  {
    ID: 24062,
    label:
      'Менеджер [в финансово-экономических и административных подразделениях (службах)]',
  },
  { ID: 24063, label: 'Менеджер по персоналу' },
  {
    ID: 24068,
    label:
      'Менеджер [в подразделениях (службах) по маркетингу и сбыту продукции]',
  },
  { ID: 24071, label: 'Менеджер по рекламе' },
  {
    ID: 24072,
    label: 'Менеджер в подразделениях (службах) компьютерного обеспечения',
  },
  {
    ID: 24074,
    label: 'Менеджер в подразделениях (службах) научно-технического развития',
  },
  {
    ID: 24075,
    label: 'Менеджер [в прочих функциональных подразделениях (службах)]',
  },
  { ID: 24078, label: 'Метеоролог' },
  { ID: 24080, label: 'Методист' },
  {
    ID: 24083,
    label:
      'Методист библиотеки, дома народного творчества, клубного учреждения, музея, центра (научно-методического, народной культуры и др.)',
  },
  {
    ID: 24086,
    label: 'Методист внешкольного учреждения',
  },
  {
    ID: 24089,
    label:
      'Методист образовательного учреждения, методического, учебно-методического кабинета (центра), фильмотеки',
  },
  {
    ID: 24092,
    label: 'Методист по дошкольному воспитанию',
  },
  {
    ID: 24095,
    label: 'Методист пожарно-технического центра',
  },
  { ID: 24098, label: 'Методист по летной подготовке' },
  { ID: 24101, label: 'Методист по физической культуре' },
  {
    ID: 24104,
    label: 'Методист по составлению кинопрограмм',
  },
  {
    ID: 24105,
    label: 'Методист по экологическому просвещению',
  },
  {
    ID: 24107,
    label: 'Метрдотель (администратор торгового зала)',
  },
  { ID: 24110, label: 'Механик' },
  { ID: 24112, label: 'Механик (судовой)' },
  { ID: 24115, label: 'Механик автомобильной колонны' },
  {
    ID: 24118,
    label: 'Механик-бригадир пассажирского поезда',
  },
  {
    ID: 24121,
    label: 'Механик бурильно-гидрографической машины',
  },
  { ID: 24123, label: 'Механик вагона-транспортера' },
  { ID: 24127, label: 'Механик вездехода' },
  { ID: 24130, label: 'Механик гаража' },
  { ID: 24132, label: 'Механик гидроузла (шлюза)' },
  {
    ID: 24135,
    label: 'Механик грузового района (участка)',
  },
  { ID: 24138, label: 'Механик группы отряда' },
  {
    ID: 24141,
    label: 'Механик дизельной и холодильной установок',
  },
  { ID: 24142, label: 'Механик дренажной шахты' },
  {
    ID: 24145,
    label: 'Механик изотермических вагонов для перевозки живой рыбы',
  },
  { ID: 24148, label: 'Механик-инструктор бортовой' },
  {
    ID: 24151,
    label: 'Механик линейный флота (по флоту)',
  },
  { ID: 24153, label: 'Механик льдозавода' },
  { ID: 24156, label: 'Механик маяка' },
  {
    ID: 24158,
    label: 'Механик на дноочистительном снаряде',
  },
  {
    ID: 24160,
    label: 'Механик на землесосе, земснаряде',
  },
  { ID: 24163, label: 'Механик-наладчик' },
  { ID: 24166, label: 'Механик-наставник' },
  {
    ID: 24169,
    label:
      'Механик перегрузочных машин (по погрузочно-разгрузочным механизмам)',
  },
  {
    ID: 24172,
    label: 'Механик по буровым, горным работам',
  },
  { ID: 24175, label: 'Механик по крановому хозяйству' },
  { ID: 24177, label: 'Механик по подъемным установкам' },
  { ID: 24180, label: 'Механик по ремонту оборудования' },
  { ID: 24183, label: 'Механик по ремонту транспорта' },
  { ID: 24185, label: 'Механик портового флота' },
  { ID: 24188, label: 'Механик по судовым системам' },
  { ID: 24192, label: 'Механик производства' },
  {
    ID: 24195,
    label: 'Механик радионавигационной системы',
  },
  {
    ID: 24198,
    label: 'Механик рефрижераторного поезда (секции)',
  },
  {
    ID: 24201,
    label: 'Механик рефрижераторных установок',
  },
  { ID: 24204, label: 'Механик участка' },
  { ID: 24207, label: 'Механик учебного полигона' },
  { ID: 24210, label: 'Механик флота (по флоту)' },
  { ID: 24213, label: 'Механик цеха' },
  { ID: 24216, label: 'Миколог' },
  { ID: 24219, label: 'Микробиолог' },
  { ID: 24223, label: 'Минералог' },
  { ID: 24228, label: 'Министр' },
  { ID: 24230, label: 'Мировой судья' },
  {
    ID: 24232,
    label: 'Младшая медицинская сестра по уходу за больными',
  },
  { ID: 24234, label: 'Младшая сестра милосердия' },
  { ID: 24236, label: 'Младший воспитатель' },
  { ID: 24237, label: 'Младший фармацевт' },
  { ID: 24241, label: 'Модельер' },
  { ID: 24243, label: 'Модельер-конструктор' },
  { ID: 24246, label: 'Монтажер' },
  { ID: 24248, label: 'Музейный смотритель' },
  { ID: 24252, label: 'Музыкальный оформитель' },
  { ID: 24255, label: 'Музыкальный руководитель' },
  { ID: 24259, label: 'Мэр' },
  { ID: 24265, label: 'Медицинская сестра-анестезист' },
  {
    ID: 24266,
    label: 'Медицинская сестра врача общей практики',
  },
  { ID: 24267, label: 'Медицинская сестра кабинета' },
  { ID: 24268, label: 'Медицинская сестра операционная' },
  { ID: 24270, label: 'Медицинская сестра патронажная' },
  { ID: 24271, label: 'Медицинская сестра перевязочной' },
  { ID: 24272, label: 'Медицинская сестра по массажу' },
  {
    ID: 24273,
    label: 'Медицинская сестра по физиотерапии',
  },
  { ID: 24275, label: 'Медицинская сестра процедурной' },
  {
    ID: 24276,
    label: 'Медицинская сестра стерилизационной',
  },
  { ID: 24358, label: 'Наблюдатель судовой' },
  { ID: 24364, label: 'Нарядчик' },
  { ID: 24365, label: 'Нарядчик багажного отделения' },
  {
    ID: 24367,
    label: 'Нарядчик локомотивных (поездных, рефрижераторных) бригад',
  },
  {
    ID: 24372,
    label: 'Научный сотрудник (в области физики и астрономии)',
  },
  {
    ID: 24373,
    label: 'Научный сотрудник (в области метеорологии)',
  },
  {
    ID: 24376,
    label: 'Научный сотрудник (в области химии)',
  },
  {
    ID: 24382,
    label: 'Научный сотрудник (в области геологии и геофизики)',
  },
  {
    ID: 24386,
    label: 'Научный сотрудник (в области математики)',
  },
  {
    ID: 24388,
    label: 'Научный сотрудник (в области статистики)',
  },
  {
    ID: 24392,
    label: 'Научный сотрудник (в области информатики и вычислительной техники)',
  },
  {
    ID: 24394,
    label: 'Научный сотрудник (в области биологии)',
  },
  {
    ID: 24395,
    label: 'Научный сотрудник (в области бактериологии и фармакологии)',
  },
  {
    ID: 24397,
    label: 'Научный сотрудник (в области медицины)',
  },
  {
    ID: 24398,
    label: 'Научный сотрудник (в области образования)',
  },
  {
    ID: 24399,
    label: 'Научный сотрудник (в области права)',
  },
  {
    ID: 24400,
    label: 'Научный сотрудник (в области экономики)',
  },
  {
    ID: 24401,
    label: 'Научный сотрудник (в области социологии)',
  },
  {
    ID: 24403,
    label: 'Научный сотрудник (в области философии, истории и политологии)',
  },
  {
    ID: 24405,
    label: 'Научный сотрудник (в области филологии)',
  },
  {
    ID: 24406,
    label: 'Научный сотрудник (в области психологии)',
  },
  { ID: 24410, label: 'Начальник автоколонны' },
  { ID: 24411, label: 'Начальник автохозяйства' },
  {
    ID: 24412,
    label:
      'Начальник агентства (рекламно-информационного, транспортно-экспедиционного и др.)',
  },
  { ID: 24415, label: 'Начальник (директор) арсенала' },
  { ID: 24418, label: 'Начальник архива' },
  { ID: 24422, label: 'Начальник аэровокзала' },
  { ID: 24424, label: 'Начальник аэроклуба' },
  {
    ID: 24428,
    label: 'Начальник базы (в промышленности)',
  },
  {
    ID: 24429,
    label:
      'Начальник базы (на транспорте, в связи, материально-техническом снабжении и сбыте)',
  },
  {
    ID: 24430,
    label: 'Начальник базы (в прочих отраслях)',
  },
  { ID: 24432, label: 'Начальник бригады' },
  {
    ID: 24436,
    label: 'Начальник бюро (в промышленности)',
  },
  {
    ID: 24437,
    label: 'Начальник бюро (в строительстве)',
  },
  {
    ID: 24439,
    label:
      'Начальник бюро (на транспорте, в связи, материально-техническом снабжении и сбыте)',
  },
  {
    ID: 24440,
    label: 'Начальник бюро (специализированного в прочих отраслях)',
  },
  {
    ID: 24442,
    label: 'Начальник бюро (научно-технического развития)',
  },
  {
    ID: 24445,
    label: 'Начальник вагона (почтового, путеобследовательского и др.)',
  },
  { ID: 24448, label: 'Начальник вахты шлюза' },
  { ID: 24452, label: 'Начальник вокзала' },
  { ID: 24458, label: 'Начальник (заведующий) гаража' },
  { ID: 24470, label: 'Начальник гидроузла (шлюза)' },
  { ID: 24473, label: 'Начальник главного управления' },
  {
    ID: 24474,
    label: 'Начальник Главного управления федерального казначейства',
  },
  {
    ID: 24476,
    label: 'Начальник городской телефонной сети',
  },
  {
    ID: 24479,
    label: 'Начальник государственной инспекции',
  },
  {
    ID: 24482,
    label: 'Начальник группы (в промышленности)',
  },
  {
    ID: 24484,
    label: 'Начальник группы (в строительстве)',
  },
  {
    ID: 24485,
    label:
      'Начальник группы (на транспорте, в связи, материально техническом снабжении и сбыте)',
  },
  {
    ID: 24487,
    label: 'Начальник группы (в прочих отраслях)',
  },
  {
    ID: 24491,
    label:
      'Начальник департамента (управления) в Аппарате Правительства Российской Федерации',
  },
  {
    ID: 24493,
    label: 'Начальник департамента федерального органа исполнительной власти',
  },
  { ID: 24499, label: 'Начальник депо' },
  {
    ID: 24502,
    label: 'Начальник детского приемника-распределителя',
  },
  {
    ID: 24505,
    label: 'Начальник дирекции международных и туристских перевозок',
  },
  {
    ID: 24508,
    label:
      'Начальник дирекции по изданию и экспедированию знаков почтовой оплаты',
  },
  {
    ID: 24509,
    label: 'Начальник дирекции по обслуживанию пассажиров',
  },
  {
    ID: 24512,
    label: 'Начальник дирекции строящегося метрополитена',
  },
  { ID: 24515, label: 'Начальник дистанции' },
  { ID: 24518, label: 'Начальник дока (докмейстер)' },
  {
    ID: 24523,
    label: 'Начальник дороги (лесовозной, подвесной канатной и др.)',
  },
  { ID: 24526, label: 'Начальник драги' },
  { ID: 24530, label: 'Начальник железной дороги' },
  { ID: 24533, label: 'Начальник запани' },
  { ID: 24538, label: 'Начальник инспекции' },
  {
    ID: 24539,
    label: 'Начальник инспекции в аппарате Счетной палаты Российской Федерации',
  },
  {
    ID: 24542,
    label: 'Начальник камеры (навигационной, электрорадионавигационной и др.)',
  },
  {
    ID: 24545,
    label: 'Начальник канала (группы каналов)',
  },
  {
    ID: 24549,
    label:
      'Начальник караула (военизированной охраны, пожарной части, службы безопасности)',
  },
  { ID: 24554, label: 'Начальник карьера' },
  {
    ID: 24558,
    label: 'Начальник кассы (билетной на вокзале, главной, городской билетной)',
  },
  {
    ID: 24563,
    label:
      'Начальник клуба (дельтапланерного, служебного собаководства, спортивного, спортивно-технического, стрелково-спортивного)',
  },
  {
    ID: 24570,
    label:
      'Начальник команды (военизированной, пожарной и сторожевой охраны, пожарно-спасательной, служебного собаководства)',
  },
  {
    ID: 24576,
    label: 'Начальник комплекса (в промышленности)',
  },
  {
    ID: 24578,
    label:
      'Начальник комплекса (на транспорте, в связи, материально-техническом снабжении и сбыте)',
  },
  {
    ID: 24579,
    label: 'Начальник комплекса (в прочих отраслях)',
  },
  {
    ID: 24584,
    label:
      'Начальник конторы (на транспорте, в связи, материально - техническом снабжении и сбыте)',
  },
  {
    ID: 24585,
    label: 'Начальник конторы (в социально-бытовом обслуживании населения)',
  },
  {
    ID: 24587,
    label: 'Начальник конторы (в прочих отраслях)',
  },
  {
    ID: 24593,
    label:
      'Начальник лаборатории (в сельском, охотничьем, лесном и рыбном хозяйстве)',
  },
  {
    ID: 24594,
    label: 'Начальник лаборатории (в промышленности)',
  },
  {
    ID: 24597,
    label: 'Начальник лаборатории (в строительстве)',
  },
  {
    ID: 24598,
    label: 'Начальник лаборатории (в торговле)',
  },
  {
    ID: 24599,
    label: 'Начальник лаборатории (на предприятиях общественного питания)',
  },
  {
    ID: 24600,
    label:
      'Начальник лаборатории (на транспорте, в связи, материально-техническом снабжении и сбыте)',
  },
  {
    ID: 24602,
    label:
      'Начальник лаборатории (на предприятиях социально - бытового обслуживания населения)',
  },
  {
    ID: 24603,
    label: 'Начальник лаборатории (в прочих отраслях)',
  },
  {
    ID: 24607,
    label: 'Начальник лагеря (оборонно-спортивного, оздоровительного и др.)',
  },
  { ID: 24610, label: 'Начальник лесничества' },
  { ID: 24614, label: 'Начальник лесного хозяйства' },
  { ID: 24617, label: 'Начальник лесобиржи' },
  { ID: 24621, label: 'Начальник лесопитомника' },
  { ID: 24624, label: 'Начальник лесопункта' },
  {
    ID: 24627,
    label:
      'Начальник летно-испытательного подразделения (базы, комплекса, станции, центра)',
  },
  {
    ID: 24629,
    label: 'Начальник летно-методического кабинета',
  },
  { ID: 24633, label: 'Начальник лоцманской вахты' },
  { ID: 24636, label: 'Начальник льдозавода' },
  {
    ID: 24642,
    label: 'Начальник маршрута городского транспорта',
  },
  {
    ID: 24648,
    label: 'Начальник (заведующий) мастерской (в промышленности)',
  },
  {
    ID: 24649,
    label:
      'Начальник (заведующий) мастерской (на транспорте, в связи, материально-техническом снабжении и сбыте)',
  },
  {
    ID: 24650,
    label: 'Начальник (заведующий) мастерской (в прочих отраслях)',
  },
  { ID: 24657, label: 'Начальник маяка' },
  { ID: 24659, label: 'Начальник метрополитена' },
  {
    ID: 24663,
    label: 'Начальник механизированного зернохранилища',
  },
  {
    ID: 24668,
    label:
      'Начальник нефтегазоразведки (партии) глубокого (структурно-поискового) бурения',
  },
  { ID: 24669, label: 'Начальник нижнего склада' },
  { ID: 24671, label: 'Начальник объединения' },
  {
    ID: 24678,
    label:
      'Начальник отдела (в сельском, охотничьем, лесном и рыбном хозяйстве)',
  },
  {
    ID: 24680,
    label: 'Начальник отдела (в промышленности)',
  },
  {
    ID: 24681,
    label: 'Начальник отдела (а строительстве)',
  },
  { ID: 24683, label: 'Начальник отдела (в торговле)' },
  {
    ID: 24684,
    label:
      'Начальник отдела (на предприятиях общественного питания и в гостиницах)',
  },
  {
    ID: 24686,
    label:
      'Начальник отдела (на транспорте, в связи, материально техническом снабжении и сбыте)',
  },
  {
    ID: 24691,
    label:
      'Начальник отдела (на предприятиях, осуществляющих коммерческую деятельность)',
  },
  {
    ID: 24692,
    label:
      'Начальник отдела (на предприятиях социально-бытового обслуживания населения)',
  },
  {
    ID: 24693,
    label: 'Начальник отдела (специализированного в прочих отраслях)',
  },
  {
    ID: 24695,
    label: 'Начальник отдела (финансово-экономического и административного)',
  },
  {
    ID: 24696,
    label: 'Начальник отдела (управления кадрами и трудовыми отношениями)',
  },
  {
    ID: 24698,
    label: 'Начальник отдела (по маркетингу и сбыту продукции)',
  },
  {
    ID: 24699,
    label: 'Начальник отдела (рекламно-информационного)',
  },
  {
    ID: 24701,
    label: 'Начальник отдела (материально-технического снабжения)',
  },
  {
    ID: 24702,
    label: 'Начальник отдела (компьютерного обеспечения)',
  },
  {
    ID: 24704,
    label: 'Начальник отдела (научно-технического развития)',
  },
  {
    ID: 24705,
    label: 'Начальник отдела (функционального в прочих областях деятельности)',
  },
  {
    ID: 24711,
    label: 'Начальник отдела в аппарате Верховного Суда Российской Федерации',
  },
  {
    ID: 24712,
    label:
      'Начальник отдела в аппарате Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 24714,
    label:
      'Начальник отдела в аппарате Конституционного Суда Российской Федерации',
  },
  {
    ID: 24717,
    label:
      'Начальник отдела в аппарате Центральной избирательной комиссии Российской Федерации',
  },
  {
    ID: 24721,
    label:
      'Начальник отдела в составе департамента (управления) в Аппарате Правительства Российской Федерации',
  },
  {
    ID: 24723,
    label:
      'Начальник отдела в составе департамента, управления федерального органа исполнительной власти',
  },
  {
    ID: 24724,
    label:
      'Начальник отдела в составе управления Администрации Президента Российской Федерации',
  },
  {
    ID: 24726,
    label:
      'Начальник отдела в составе управления в аппарате Верховного Суда Российской Федерации',
  },
  {
    ID: 24729,
    label:
      'Начальник отдела в составе управления в аппарате Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 24730,
    label:
      'Начальник отдела в составе управления в аппарате Генеральной прокуратуры Российской Федерации',
  },
  {
    ID: 24733,
    label:
      'Начальник отдела в составе управления в Аппарате Государственной Думы Федерального Собрания',
  },
  {
    ID: 24734,
    label:
      'Начальник отдела в составе управления в аппарате Конституционного Суда Российской Федерации',
  },
  {
    ID: 24735,
    label:
      'Начальник отдела в составе управления в Аппарате Совета Федерации Федерального Собрания',
  },
  {
    ID: 24736,
    label:
      'Начальник отдела в составе управления в аппарате Центральной избирательной комиссии Российской Федерации',
  },
  {
    ID: 24738,
    label:
      'Начальник отдела в составе управления Президента Российской Федерации',
  },
  {
    ID: 24742,
    label: 'Начальник отдела федерального органа исполнительной власти',
  },
  {
    ID: 24752,
    label: 'Начальник отделения (в промышленности)',
  },
  {
    ID: 24753,
    label:
      'Начальник отделения (на транспорте, в связи, материально-техническом снабжении и сбыте)',
  },
  {
    ID: 24754,
    label: 'Начальник отделения (специализированного в прочих отраслях)',
  },
  {
    ID: 24755,
    label: 'Начальник отделения (службы безопасности)',
  },
  {
    ID: 24756,
    label: 'Начальник отделения (финансово-экономического и административного)',
  },
  {
    ID: 24757,
    label:
      'Начальник отделения (функционального в прочих областях деятельности)',
  },
  {
    ID: 24765,
    label: 'Начальник отряда (в сельском хозяйстве)',
  },
  {
    ID: 24766,
    label: 'Начальник отряда (в промышленности)',
  },
  {
    ID: 24774,
    label: 'Начальник отряда (на транспорте)',
  },
  {
    ID: 24775,
    label: 'Начальник отряда (в прочих отраслях)',
  },
  {
    ID: 24778,
    label: 'Начальник охраны (объекта, участка)',
  },
  {
    ID: 24781,
    label: 'Начальник парка (машинного, понтонного, резервуарного и др.)',
  },
  { ID: 24784, label: 'Начальник паромной переправы' },
  { ID: 24787, label: 'Начальник пароходства' },
  {
    ID: 24792,
    label: 'Начальник партии (в промышленности)',
  },
  {
    ID: 24793,
    label: 'Начальник партии (на транспорте)',
  },
  {
    ID: 24796,
    label: 'Начальник передвижного автоклуба',
  },
  {
    ID: 24798,
    label: 'Начальник площадки (в промышленности)',
  },
  {
    ID: 24799,
    label: 'Начальник площадки (на транспорте)',
  },
  {
    ID: 24801,
    label:
      'Начальник подразделения (поисково-спасательного, специализированного монтажно-эксплуатационного и др.)',
  },
  {
    ID: 24807,
    label:
      'Начальник поезда (восстановительного, пассажирского, рефрижераторного и др.)',
  },
  {
    ID: 24811,
    label: 'Начальник позиции (стартовой, технической)',
  },
  {
    ID: 24815,
    label: 'Начальник порта (морского рыбного, приписного и др.)',
  },
  { ID: 24817, label: 'Начальник портового надзора' },
  { ID: 24818, label: 'Начальник портового флота' },
  { ID: 24823, label: 'Начальник поста (на транспорте)' },
  {
    ID: 24826,
    label: 'Начальник поста (в прочих отраслях)',
  },
  {
    ID: 24827,
    label: 'Начальник поста иммиграционного контроля',
  },
  { ID: 24829, label: 'Начальник почтамта' },
  {
    ID: 24832,
    label: 'Начальник Приемной Государственной Думы Федерального Собрания',
  },
  { ID: 24834, label: 'Начальник пристани' },
  { ID: 24837, label: 'Начальник причала' },
  {
    ID: 24841,
    label: 'Начальник производства (в промышленности)',
  },
  {
    ID: 24842,
    label: 'Начальник производства (на транспорте)',
  },
  {
    ID: 24845,
    label: 'Начальник (заведующий) производственной лаборатории',
  },
  { ID: 24847, label: 'Начальник проходки шахты' },
  {
    ID: 24850,
    label: 'Начальник (заведующий) пункта (в сельском хозяйстве)',
  },
  {
    ID: 24853,
    label: 'Начальник пункта (в промышленности)',
  },
  {
    ID: 24855,
    label: 'Начальник пункта (на транспорте)',
  },
  {
    ID: 24856,
    label: 'Начальник пункта (в прочих отраслях)',
  },
  { ID: 24859, label: 'Начальник путевой машины' },
  { ID: 24862, label: 'Начальник радиостанции' },
  {
    ID: 24865,
    label: 'Начальник радиотелевизионной передающей станции',
  },
  { ID: 24871, label: 'Начальник разреза' },
  { ID: 24874, label: 'Начальник разъезда' },
  {
    ID: 24878,
    label: 'Начальник района (грузового, нефтеналивного, сетевого и др.)',
  },
  {
    ID: 24882,
    label: 'Начальник расчета пожарной машины',
  },
  {
    ID: 24886,
    label: 'Начальник реактора (ускорителя, ядерно-физической установки)',
  },
  {
    ID: 24891,
    label:
      'Начальник резерва бригад (кондукторских, локомотивных рефрижераторных поездов)',
  },
  {
    ID: 24894,
    label: 'Начальник резерва проводников пассажирских вагонов',
  },
  { ID: 24898, label: 'Начальник рудника' },
  { ID: 24902, label: 'Начальник сборной команды' },
  {
    ID: 24903,
    label: 'Начальник сектора (в сельском хозяйстве)',
  },
  {
    ID: 24904,
    label: 'Начальник сектора (специализированного в прочих отраслях)',
  },
  {
    ID: 24906,
    label: 'Начальник сектора (научно-технического развития)',
  },
  {
    ID: 24907,
    label: 'Начальник сектора (функционального в прочих областях деятельности)',
  },
  {
    ID: 24912,
    label:
      'Начальник склада (горюче-смазочных материалов, грузового, материально-технического и др.)',
  },
  {
    ID: 24915,
    label: 'Начальник скотопрогонного тракта',
  },
  {
    ID: 24920,
    label: 'Начальник службы (в промышленности)',
  },
  {
    ID: 24921,
    label:
      'Начальник службы (на транспорте, в связи, материально техническом снабжении и сбыте)',
  },
  {
    ID: 24923,
    label:
      'Начальник (заведующий) службы (специализированной в прочих отраслях)',
  },
  {
    ID: 24924,
    label: 'Начальник службы (финансово-экономической и административной)',
  },
  {
    ID: 24926,
    label: 'Начальник службы (материально-технического снабжения)',
  },
  {
    ID: 24927,
    label: 'Начальник службы (функциональной в прочих областях деятельности)',
  },
  {
    ID: 24932,
    label:
      'Начальник службы делопроизводства в Администрации Президента Российской Федерации',
  },
  {
    ID: 24933,
    label:
      'Начальник службы делопроизводства в Аппарате Государственной Думы Федерального Собрания',
  },
  {
    ID: 24935,
    label:
      'Начальник службы делопроизводства в Аппарате Правительства Российской Федерации',
  },
  {
    ID: 24936,
    label:
      'Начальник службы делопроизводства в Аппарате Совета Федерации Федерального Собрания',
  },
  {
    ID: 24945,
    label: 'Начальник смены (в промышленности)',
  },
  {
    ID: 24950,
    label: 'Начальник смены (на транспорте и в связи)',
  },
  {
    ID: 24951,
    label: 'Начальник смены (в прочих отраслях)',
  },
  {
    ID: 24952,
    label:
      'Начальник смены атомной станции (дежурный диспетчер атомной станции)',
  },
  {
    ID: 24954,
    label:
      'Начальник сортировочной горки (автоматизированной, механизированной)',
  },
  {
    ID: 24959,
    label: 'Начальник состава почтовых вагонов',
  },
  {
    ID: 24962,
    label: 'Начальник состава экспедиции (зимовочного, сезонного)',
  },
  { ID: 24965, label: 'Начальник сплавного рейда' },
  {
    ID: 24968,
    label:
      'Начальник станции (в сельском, охотничьем, лесном и рыбном хозяйстве)',
  },
  {
    ID: 24972,
    label: 'Начальник станции (в промышленности)',
  },
  {
    ID: 24973,
    label: 'Начальник станции (на транспорте и в связи)',
  },
  {
    ID: 24976,
    label: 'Начальник станции (в прочих отраслях)',
  },
  {
    ID: 24979,
    label:
      'Начальник стационарной платформы (в разведочном и эксплуатационном бурении)',
  },
  { ID: 24988, label: 'Начальник (директор) судоверфи' },
  {
    ID: 24990,
    label: 'Начальник сушильно-очистительной башни',
  },
  { ID: 24993, label: 'Начальник таможни' },
  { ID: 24996, label: 'Начальник телеграфа' },
  {
    ID: 25000,
    label: 'Начальник (заведующий) типографии',
  },
  { ID: 25006, label: 'Начальник тони' },
  {
    ID: 25012,
    label: 'Начальник трудовой колонии для несовершеннолетних',
  },
  { ID: 25015, label: 'Начальник тюрьмы' },
  { ID: 25019, label: 'Начальник тяговой подстанции' },
  {
    ID: 25022,
    label:
      'Начальник узла (радионавигации и радиолокации, связи, телефонного и др.)',
  },
  {
    ID: 25027,
    label:
      'Начальник управления (в сельском, охотничьем, лесном и рыбном хозяйстве)',
  },
  {
    ID: 25028,
    label: 'Начальник управления (в промышленности)',
  },
  {
    ID: 25042,
    label: 'Начальник управления (специализированного в прочих отраслях)',
  },
  {
    ID: 25046,
    label: 'Начальник управления Администрации Президента Российской Федерации',
  },
  {
    ID: 25048,
    label:
      'Начальник управления в аппарате Верховного Суда Российской Федерации',
  },
  {
    ID: 25049,
    label:
      'Начальник управления в аппарате Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 25051,
    label:
      'Начальник управления в аппарате Генеральной Прокуратуры Российской Федерации',
  },
  {
    ID: 25053,
    label:
      'Начальник управления в Аппарате Государственной Думы Федерального Собрания',
  },
  {
    ID: 25057,
    label:
      'Начальник управления в аппарате Конституционного Суда Российской Федерации',
  },
  {
    ID: 25059,
    label:
      'Начальник управления в Аппарате Совета Федерации Федерального Собрания',
  },
  {
    ID: 25061,
    label:
      'Начальник управления в аппарате Центральной избирательной комиссии Российской Федерации',
  },
  {
    ID: 25063,
    label: 'Начальник управления Президента Российской Федерации',
  },
  {
    ID: 25064,
    label: 'Начальник управления федерального органа исполнительной власти',
  },
  {
    ID: 25066,
    label:
      'Начальник управления федерального органа исполнительной власти - член коллегии',
  },
  {
    ID: 25076,
    label:
      'Начальник установки (буровой, газодобывающей, кислородной, обогатительной, регенерационной, углекислотной и др.)',
  },
  {
    ID: 25079,
    label:
      'Начальник участка (в сельском, охотничьем, лесном и рыбном хозяйстве)',
  },
  {
    ID: 25080,
    label: 'Начальник участка (в промышленности)',
  },
  {
    ID: 25081,
    label: 'Начальник участка (в строительстве)',
  },
  {
    ID: 25082,
    label: 'Начальник участка (в прочих отраслях)',
  },
  {
    ID: 25086,
    label: 'Начальник (заведующий) учебного полигона',
  },
  {
    ID: 25087,
    label: 'Начальник учебного пункта (городка)',
  },
  { ID: 25089, label: 'Начальник училища' },
  { ID: 25092, label: 'Начальник фабрики' },
  { ID: 25094, label: 'Начальник федерального надзора' },
  {
    ID: 25095,
    label:
      'Начальник филиала (на транспорте, в связи, материально-техническом снабжении и сбыте)',
  },
  {
    ID: 25097,
    label: 'Начальник филиала (в прочих отраслях)',
  },
  {
    ID: 25101,
    label: 'Начальник хозяйства (в промышленности)',
  },
  {
    ID: 25102,
    label:
      'Начальник хозяйства (на предприятиях социально-бытового обслуживания населения)',
  },
  {
    ID: 25107,
    label: 'Начальник центра (на транспорте и в связи)',
  },
  {
    ID: 25108,
    label: 'Начальник центра (в прочих отраслях)',
  },
  {
    ID: 25111,
    label: 'Начальник центральной заводской лаборатории',
  },
  { ID: 25114, label: 'Начальник цеха' },
  {
    ID: 25119,
    label: 'Начальник части (в промышленности)',
  },
  {
    ID: 25120,
    label: 'Начальник части (специализированной в прочих отраслях)',
  },
  {
    ID: 25122,
    label: 'Начальник части (финансово-экономической и административной)',
  },
  { ID: 25126, label: 'Начальник шахты' },
  {
    ID: 25129,
    label: 'Начальник школы (начальствующего состава, технической)',
  },
  {
    ID: 25134,
    label:
      'Начальник штаба (авиационного, гражданской обороны, предприятия, училища)',
  },
  {
    ID: 25138,
    label: 'Начальник экспедиции (в сельском хозяйстве)',
  },
  {
    ID: 25140,
    label: 'Начальник экспедиции (в промышленности)',
  },
  {
    ID: 25141,
    label: 'Начальник экспедиции (в прочих отраслях)',
  },
  { ID: 25144, label: 'Начальник элеватора' },
  {
    ID: 25149,
    label: 'Начальник электронно-вычислительной машины',
  },
  { ID: 25152, label: 'Начальник электроподстанции' },
  { ID: 25156, label: 'Начальник электростанции' },
  {
    ID: 25162,
    label: 'Начальник эстакады (наливной, реагентного хозяйства)',
  },
  { ID: 25172, label: 'Нормировщик' },
  { ID: 25176, label: 'Нотариус' },
  { ID: 25277, label: 'Обозреватель' },
  { ID: 25279, label: 'Обозреватель политический' },
  {
    ID: 25282,
    label: 'Обозреватель по экономическим вопросам',
  },
  { ID: 25288, label: 'Океанолог' },
  {
    ID: 25292,
    label: 'Оператор бюро информации о подходе и прибытии грузов',
  },
  {
    ID: 25294,
    label: 'Оператор бюро по учету перехода вагонов',
  },
  { ID: 25297, label: 'Оператор вагонного депо' },
  { ID: 25300, label: 'Оператор видеозаписи' },
  {
    ID: 25303,
    label: 'Оператор диспетчерского тренажера',
  },
  {
    ID: 25306,
    label:
      'Оператор диспетчерской движения и погрузочно-разгрузочных работ на автомобильном (морском, речном) транспорте',
  },
  {
    ID: 25308,
    label: 'Оператор диспетчерской (производственно-диспетчерской) службы',
  },
  { ID: 25312, label: 'Оператор звукозаписи' },
  { ID: 25316, label: 'Оператор-инструктор бортовой' },
  { ID: 25319, label: 'Оператор-испытатель бортовой' },
  {
    ID: 25327,
    label: 'Оператор механизированного расчета в гостинице',
  },
  {
    ID: 25331,
    label:
      'Оператор наземных средств управления беспилотным летательным аппаратом',
  },
  {
    ID: 25334,
    label: 'Оператор по диспетчерскому обслуживанию лифтов',
  },
  {
    ID: 25337,
    label: 'Оператор по обработке перевозочных документов',
  },
  {
    ID: 25340,
    label: 'Оператор при дежурном по локомотивному депо',
  },
  {
    ID: 25345,
    label:
      'Оператор при дежурном помощнике начальника оперативно-распорядительного отдела управления железной дороги',
  },
  {
    ID: 25348,
    label: 'Оператор при дежурном по отделению железной дороги',
  },
  {
    ID: 25354,
    label: 'Оператор при дежурном по станции',
  },
  {
    ID: 25358,
    label: 'Оператор при маневровом диспетчере железнодорожной станции',
  },
  {
    ID: 25363,
    label: 'Оператор промывочно-пропарочной станции',
  },
  {
    ID: 25366,
    label: 'Оператор тележурналистского комплекта',
  },
  {
    ID: 25369,
    label: 'Оператор электродепо метрополитена',
  },
  { ID: 25372, label: 'Оптометрист' },
  {
    ID: 25379,
    label: 'Организатор путешествий (экскурсий)',
  },
  { ID: 25384, label: 'Организатор ритуала' },
  {
    ID: 25388,
    label: 'Оргсекретарь (ассоциации, союза, федерации)',
  },
  { ID: 25389, label: 'Орнитолог' },
  {
    ID: 25394,
    label: 'Ответственный дежурный по министерству (ведомству)',
  },
  { ID: 25398, label: 'Ответственный редактор' },
  { ID: 25403, label: 'Ответственный секретарь' },
  {
    ID: 25405,
    label: 'Ответственный секретарь коллегии',
  },
  {
    ID: 25408,
    label: 'Ответственный секретарь структурного агентства (редакции)',
  },
  { ID: 25409, label: 'Ответственный секретарь суда' },
  { ID: 25412, label: 'Охотовед' },
  { ID: 25416, label: 'Охранник' },
  { ID: 25419, label: 'Оценщик' },
  {
    ID: 25420,
    label: 'Оценщик интеллектуальной собственности',
  },
  {
    ID: 25421,
    label: 'Оценщик (эксперт по оценке имущества)',
  },
  { ID: 25457, label: 'Палеограф' },
  { ID: 25460, label: 'Палеонтолог' },
  { ID: 25463, label: 'Парашютист-испытатель' },
  { ID: 25465, label: 'Парашютист-спасатель' },
  { ID: 25469, label: 'Парашютист-укладчик парашютов' },
  { ID: 25474, label: 'Паспортист' },
  {
    ID: 25478,
    label: 'Педагог дополнительного образования',
  },
  { ID: 25481, label: 'Педагог-организатор' },
  { ID: 25484, label: 'Педагог-психолог' },
  { ID: 25487, label: 'Педагог социальный' },
  {
    ID: 25496,
    label:
      'Первый заместитель Председателя Верховного Суда Российской Федерации',
  },
  {
    ID: 25498,
    label:
      'Первый заместитель Председателя Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 25499,
    label:
      'Первый заместитель Председателя Государственной Думы Федерального Собрания',
  },
  {
    ID: 25501,
    label: 'Первый заместитель Председателя Правительства Российской Федерации',
  },
  {
    ID: 25503,
    label:
      'Первый заместитель Руководителя Администрации Президента Российской Федерации',
  },
  {
    ID: 25505,
    label:
      'Первый заместитель Руководителя Аппарата Государственной Думы Федерального Собрания',
  },
  {
    ID: 25506,
    label:
      'Первый заместитель Руководителя Аппарата Правительства Российской Федерации',
  },
  {
    ID: 25507,
    label:
      'Первый заместитель Руководителя Аппарата Совета Федерации Федерального Собрания',
  },
  {
    ID: 25510,
    label:
      'Первый заместитель руководителя федерального органа исполнительной власти (кроме федерального министерства)',
  },
  {
    ID: 25513,
    label:
      'Первый заместитель Секретаря Совета Безопасности Российской Федерации',
  },
  {
    ID: 25516,
    label: 'Первый заместитель федерального министра',
  },
  {
    ID: 25520,
    label: 'Первый помощник Президента Российской Федерации',
  },
  { ID: 25531, label: 'Переводчик' },
  { ID: 25533, label: 'Переводчик-дактилолог' },
  { ID: 25534, label: 'Переводчик синхронный' },
  {
    ID: 25535,
    label: 'Переводчик технической литературы',
  },
  { ID: 25539, label: 'Переписчик нот' },
  { ID: 25541, label: 'Переписчик нот по Брайлю' },
  { ID: 25544, label: 'Петрограф' },
  { ID: 25547, label: 'Пилот' },
  { ID: 25549, label: 'Пилот-инструктор' },
  { ID: 25552, label: 'Планшетист' },
  {
    ID: 25555,
    label: 'Поверенный в делах Российской Федерации',
  },
  {
    ID: 25559,
    label: 'Полномочный представитель Правительства Российской Федерации',
  },
  {
    ID: 25560,
    label: 'Полномочный представитель Президента Российской Федерации',
  },
  { ID: 25563, label: 'Помощник врача-паразитолога' },
  { ID: 25564, label: 'Помощник врача-эпидемиолога' },
  { ID: 25565, label: 'Помощник председателя суда' },
  { ID: 25566, label: 'Помощник прокурора' },
  {
    ID: 25570,
    label: 'Помощник (советник) аудитора Счетной палаты Российской Федерации',
  },
  {
    ID: 25571,
    label:
      'Помощник (советник) заместителя Председателя Верховного Суда Российской Федерации',
  },
  {
    ID: 25572,
    label:
      'Помощник (советник) заместителя Председателя Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 25573,
    label:
      'Помощник (советник) заместителя Председателя Государственной Думы Федерального Собрания',
  },
  {
    ID: 25574,
    label:
      'Помощник (советник) заместителя Председателя Конституционного Суда Российской Федерации',
  },
  {
    ID: 25575,
    label:
      'Помощник (советник) Заместителя Председателя Правительства Российской Федерации',
  },
  {
    ID: 25576,
    label:
      'Помощник (советник) заместителя Председателя Совета Федерации Федерального Собрания',
  },
  {
    ID: 25577,
    label:
      'Помощник (советник) заместителя Председателя Центральной избирательной комиссии Российской Федерации',
  },
  {
    ID: 25583,
    label:
      'Помощник (советник) первого заместителя Председателя Верховного Суда Российской Федерации',
  },
  {
    ID: 25584,
    label:
      'Помощник (советник) первого заместителя Председателя Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 25585,
    label:
      'Помощник (советник) первого заместителя Председателя Государственной Думы Федерального Собрания',
  },
  {
    ID: 25586,
    label:
      'Помощник (советник) Первого заместителя Председателя Правительства Российской Федерации',
  },
  {
    ID: 25592,
    label:
      'Помощник (советник) Председателя Верховного Суда Российской Федерации',
  },
  {
    ID: 25593,
    label:
      'Помощник (советник) Председателя Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 25594,
    label:
      'Помощник (советник) Председателя Государственной Думы Федерального Собрания',
  },
  {
    ID: 25595,
    label:
      'Помощник (советник) Председателя Конституционного Суда Российской Федерации',
  },
  {
    ID: 25596,
    label:
      'Помощник (советник) Председателя Правительства Российской Федерации',
  },
  {
    ID: 25597,
    label:
      'Помощник (советник) Председателя Совета Федерации Федерального Собрания',
  },
  {
    ID: 25598,
    label:
      'Помощник (советник) Председателя Центральной избирательной комиссии Российской Федерации',
  },
  {
    ID: 25600,
    label:
      'Помощник (советник) Председателя Счетной палаты Российской Федерации',
  },
  {
    ID: 25604,
    label: 'Помощник (советник) Президента Российской Федерации',
  },
  {
    ID: 25607,
    label:
      'Помощник (советник) руководителя федерального органа исполнительной власти (кроме федерального министра)',
  },
  {
    ID: 25608,
    label:
      'Помощник (советник) судьи Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 25609,
    label: 'Помощник (советник) судьи Верховного Суда Российской Федерации',
  },
  {
    ID: 25610,
    label:
      'Помощник (советник) судьи Конституционного Суда Российской Федерации',
  },
  {
    ID: 25611,
    label:
      'Помощник (советник) судьи-секретаря Конституционного Суда Российской Федерации',
  },
  {
    ID: 25617,
    label: 'Помощник (советник) федерального министра',
  },
  { ID: 25620, label: 'Помощник энтомолога' },
  { ID: 25627, label: 'Портье' },
  { ID: 25633, label: 'Посланник' },
  {
    ID: 25638,
    label:
      'Чрезвычайный и Полномочный Посол Российской Федерации (в иностранном государстве)',
  },
  { ID: 25640, label: 'Посол по особым поручениям' },
  { ID: 25642, label: 'Постановщик трюков' },
  { ID: 25645, label: 'Почвовед' },
  { ID: 25646, label: 'Почвовед (средней квалификации)' },
  {
    ID: 25655,
    label:
      'Председатель ассоциации (конфедерации, федерации) (общественно-экономической организации)',
  },
  {
    ID: 25656,
    label:
      'Председатель ассоциации (конфедерации, федерации) (гуманитарной или другой специальной организации)',
  },
  {
    ID: 25660,
    label: 'Председатель Верховного Суда Российской Федерации',
  },
  {
    ID: 25663,
    label: 'Председатель Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 25665,
    label: 'Председатель государственного комитета',
  },
  {
    ID: 25669,
    label: 'Председатель Государственной Думы Федерального Собрания',
  },
  {
    ID: 25674,
    label: 'Председатель государственной телерадиокомпании',
  },
  {
    ID: 25678,
    label: 'Председатель комитета (комиссии)',
  },
  {
    ID: 25682,
    label:
      'Председатель комитета (комиссии) Государственной Думы Федерального Собрания',
  },
  {
    ID: 25683,
    label:
      'Председатель комитета (комиссии) Совета Федерации Федерального Собрания',
  },
  {
    ID: 25685,
    label: 'Председатель Конституционного Суда Российской Федерации',
  },
  {
    ID: 25690,
    label:
      'Председатель кооператива (в сельском, охотничьем, лесном и рыбном хозяйстве)',
  },
  {
    ID: 25691,
    label: 'Председатель кооператива (в промышленности)',
  },
  {
    ID: 25692,
    label: 'Председатель кооператива (в строительстве)',
  },
  {
    ID: 25693,
    label: 'Председатель кооператива (в торговле)',
  },
  {
    ID: 25694,
    label:
      'Председатель кооператива (в общественном питании и гостиничном обслуживании)',
  },
  {
    ID: 25695,
    label:
      'Председатель кооператива (на транспорте, в связи, материально-техническом снабжении и сбыте)',
  },
  {
    ID: 25696,
    label: 'Председатель кооператива (в коммерческой деятельности)',
  },
  {
    ID: 25697,
    label:
      'Председатель кооператива (в социально-бытовом обслуживании населения)',
  },
  {
    ID: 25698,
    label: 'Председатель кооператива (в прочих отраслях)',
  },
  { ID: 25703, label: 'Председатель объединения' },
  { ID: 25706, label: 'Председатель отделения' },
  {
    ID: 25711,
    label:
      'Председатель подкомитета комитета Государственной Думы Федерального Собрания',
  },
  {
    ID: 25714,
    label: 'Председатель Правительства Российской Федерации',
  },
  { ID: 25720, label: 'Председатель правления' },
  { ID: 25723, label: 'Председатель президиума' },
  {
    ID: 25726,
    label: 'Председатель производственного объединения',
  },
  { ID: 25728, label: 'Председатель Сбербанка' },
  {
    ID: 25729,
    label: 'Председатель секции (творческой)',
  },
  {
    ID: 25733,
    label:
      'Председатель совета (научно-технического, учебно-методического, ученого (медицинского, методического), физкультурно-спортивного, экспертного)',
  },
  {
    ID: 25736,
    label:
      'Председатель совета, комиссии, комитета при Президенте Российской Федерации',
  },
  {
    ID: 25739,
    label: 'Председатель Совета Федерации Федерального Собрания',
  },
  { ID: 25744, label: 'Председатель спортивного клуба' },
  { ID: 25747, label: 'Председатель суда' },
  {
    ID: 25750,
    label:
      'Председатель Судебной палаты по информационным спорам при Президенте Российской Федерации',
  },
  {
    ID: 25753,
    label: 'Председатель Счетной палаты Российской Федерации',
  },
  { ID: 25757, label: 'Председатель федерального суда' },
  {
    ID: 25758,
    label: 'Председатель федеральной комиссии',
  },
  {
    ID: 25760,
    label: 'Председатель Центрального банка Российской Федерации',
  },
  {
    ID: 25763,
    label:
      'Председатель Центральной избирательной комиссии Российской Федерации',
  },
  {
    ID: 25773,
    label: 'Представитель военного представительства',
  },
  {
    ID: 25775,
    label: 'Представитель МИД - руководитель дипломатического агентства',
  },
  {
    ID: 25777,
    label:
      'Постоянный представитель (представитель, постоянный наблюдатель) Российской Федерации при международной организации (в иностранном государстве)',
  },
  {
    ID: 25779,
    label: 'Торговый представитель Российской Федерации',
  },
  {
    ID: 25781,
    label: 'Представитель федерального органа исполнительной власти',
  },
  { ID: 25790, label: 'Президент' },
  {
    ID: 25793,
    label: 'Президент академии (наук, художеств)',
  },
  {
    ID: 25795,
    label:
      'Президент ассоциации (концерна, корпорации и др.) (общественно-экономической организации)',
  },
  {
    ID: 25796,
    label:
      'Президент ассоциации (концерна, корпорации и др.) (гуманитарной или другой специальной организации)',
  },
  { ID: 25800, label: 'Президент Российской Федерации' },
  {
    ID: 25812,
    label: 'Преподаватель (в колледжах, университетах и других вузах)',
  },
  { ID: 25813, label: 'Преподаватель (в средней школе)' },
  {
    ID: 25814,
    label: 'Преподаватель (в системе специального образования)',
  },
  {
    ID: 25815,
    label:
      'Преподаватель (средней квалификации в системе специального образования)',
  },
  {
    ID: 25816,
    label: 'Преподаватель (в начальной школе)',
  },
  {
    ID: 25817,
    label: 'Преподаватель (в системе дошкольного воспитания и обучения)',
  },
  {
    ID: 25819,
    label:
      'Преподаватель-организатор (в колледжах, университетах и других вузах)',
  },
  {
    ID: 25820,
    label: 'Преподаватель-организатор (в средней школе)',
  },
  {
    ID: 25821,
    label: 'Преподаватель-организатор (в системе специального образования)',
  },
  {
    ID: 25824,
    label: 'Преподаватель-стажер (в колледжах, университетах и других вузах)',
  },
  {
    ID: 25825,
    label: 'Преподаватель-стажер (в средней школе)',
  },
  {
    ID: 25826,
    label: 'Преподаватель-стажер (в системе специального образования)',
  },
  {
    ID: 25827,
    label:
      'Преподаватель-стажер (средней квалификации в системе специального образования)',
  },
  {
    ID: 25828,
    label: 'Преподаватель-стажер (в начальной школе)',
  },
  {
    ID: 25829,
    label: 'Преподаватель-стажер (в системе дошкольного воспитания и обучения)',
  },
  { ID: 25835, label: 'Пресс-секретарь' },
  {
    ID: 25837,
    label: 'Пресс-секретарь Президента Российской Федерации',
  },
  { ID: 25843, label: 'Префект' },
  { ID: 25847, label: 'Пробирер' },
  { ID: 25850, label: 'Провизор' },
  { ID: 25851, label: 'Провизор (средней квалификации)' },
  { ID: 25852, label: 'Провизор-интерн' },
  {
    ID: 25853,
    label: 'Провизор-интерн (средней квалификации)',
  },
  { ID: 25854, label: 'Провизор-аналитик' },
  { ID: 25855, label: 'Провизор-стажер' },
  { ID: 25856, label: 'Провизор-технолог' },
  { ID: 25857, label: 'Программист' },
  {
    ID: 25858,
    label: 'Продюсер телевизионных и радиопрограмм',
  },
  {
    ID: 25859,
    label:
      'Производитель аварийно-спасательных, подводно-технических и других специальных работ',
  },
  { ID: 25860, label: 'Продюсер' },
  {
    ID: 25862,
    label: 'Производитель гидрографических работ',
  },
  {
    ID: 25863,
    label: 'Производитель капитальных выправительных и путевых работ',
  },
  {
    ID: 25864,
    label: 'Производитель работ (прораб) (в промышленности)',
  },
  {
    ID: 25865,
    label: 'Производитель работ (прораб) (в строительстве)',
  },
  { ID: 25868, label: 'Прокурор' },
  { ID: 25869, label: 'Прокурор-криминалист' },
  { ID: 25873, label: 'Проректор вуза' },
  { ID: 25876, label: 'Профессор' },
  { ID: 25879, label: 'Профконсультант' },
  { ID: 25883, label: 'Психолог' },
  { ID: 25983, label: 'Радист-инструктор бортовой' },
  { ID: 25986, label: 'Радист-испытатель бортовой' },
  {
    ID: 25989,
    label: 'Распорядитель Дворца Бракосочетания',
  },
  {
    ID: 25991,
    label: 'Распорядитель танцевального вечера',
  },
  { ID: 25995, label: 'Ревизор' },
  {
    ID: 25997,
    label: 'Ревизор автомобильного транспорта',
  },
  { ID: 25998, label: 'Ревизор весового хозяйства' },
  {
    ID: 25999,
    label: 'Ревизор движения отделения железной дороги',
  },
  { ID: 26002, label: 'Ревизор-инспектор налоговый' },
  {
    ID: 26005,
    label: 'Ревизор-инструктор по контролю пассажирских поездов',
  },
  { ID: 26007, label: 'Ревизор коммерческий' },
  { ID: 26008, label: 'Ревизор коммерческий дорожный' },
  {
    ID: 26009,
    label: 'Ревизор по безопасности движения',
  },
  {
    ID: 26011,
    label: 'Ревизор по проверке деятельности арбитражных судов',
  },
  {
    ID: 26013,
    label: 'Ревизор по производственно-техническим и экономическим вопросам',
  },
  {
    ID: 26015,
    label: 'Ревизор по холодильному хозяйству',
  },
  { ID: 26021, label: 'Регистратор' },
  {
    ID: 26022,
    label: 'Регистратор прав на недвижимое имущество и сделок с ним',
  },
  { ID: 26025, label: 'Редактор' },
  { ID: 26028, label: 'Редактор карт' },
  { ID: 26031, label: 'Редактор карт технический' },
  { ID: 26033, label: 'Редактор-консультант' },
  { ID: 26035, label: 'Редактор контрольный переводов' },
  { ID: 26037, label: 'Редактор музыкальный' },
  { ID: 26039, label: 'Редактор научный' },
  { ID: 26041, label: 'Редактор-переводчик' },
  {
    ID: 26043,
    label: 'Редактор руководств для плавания',
  },
  { ID: 26045, label: 'Редактор-стилист' },
  { ID: 26047, label: 'Редактор телефильмов' },
  { ID: 26049, label: 'Редактор технический' },
  { ID: 26051, label: 'Редактор художественный' },
  { ID: 26058, label: 'Режиссер' },
  { ID: 26059, label: 'Режиссер монтажа' },
  { ID: 26061, label: 'Режиссер-постановщик' },
  { ID: 26063, label: 'Режиссер радиовещания' },
  { ID: 26064, label: 'Режиссер телевидения' },
  { ID: 26069, label: 'Ректор' },
  { ID: 26073, label: 'Рентгенолаборант' },
  { ID: 26076, label: 'Репетитор по балету' },
  { ID: 26077, label: 'Репетитор по вокалу' },
  { ID: 26079, label: 'Репетитор по технике речи' },
  { ID: 26088, label: 'Референт' },
  {
    ID: 26090,
    label: 'Референт (в представительстве, консульском учреждении)',
  },
  {
    ID: 26091,
    label: 'Референт в Администрации Президента Российской Федерации',
  },
  {
    ID: 26093,
    label: 'Референт в аппарате Конституционного Суда Российской Федерации',
  },
  {
    ID: 26096,
    label: 'Референт по основной деятельности',
  },
  {
    ID: 26099,
    label: 'Референт Председателя Совета Федерации Федерального Собрания',
  },
  {
    ID: 26101,
    label: 'Референт Президента Российской Федерации',
  },
  {
    ID: 26110,
    label: 'Руководитель Администрации Президента Российской Федерации',
  },
  {
    ID: 26114,
    label:
      'Руководитель Аналитического центра при Президенте Российской Федерации',
  },
  {
    ID: 26118,
    label: 'Руководитель Аппарата Государственной Думы Федерального Собрания',
  },
  {
    ID: 26119,
    label: 'Руководитель аппарата депутатского объединения',
  },
  {
    ID: 26120,
    label:
      'Руководитель аппарата комитета (комиссии) Государственной Думы Федерального Собрания',
  },
  {
    ID: 26121,
    label:
      'Руководитель аппарата комитета (комиссии) Совета Федерации Федерального Собрания',
  },
  {
    ID: 26123,
    label: 'Руководитель Аппарата Правительства Российской Федерации',
  },
  {
    ID: 26124,
    label: 'Руководитель Аппарата Совета Федерации Федерального Собрания',
  },
  {
    ID: 26125,
    label: 'Руководитель аппарата Счетной палаты Российской Федерации',
  },
  {
    ID: 26126,
    label:
      'Руководитель аппарата Центральной избирательной комиссии Российской Федерации',
  },
  {
    ID: 26136,
    label: 'Руководитель бригады (изыскательской, проектной организации)',
  },
  {
    ID: 26137,
    label: 'Руководитель бюро медико-социальной экспертизы',
  },
  {
    ID: 26141,
    label:
      'Руководитель высшего исполнительного органа государственной власти субъекта Российской Федерации',
  },
  {
    ID: 26147,
    label: 'Руководитель главного бюро медико-социальной экспертизы',
  },
  {
    ID: 26148,
    label:
      'Руководитель главного секретариата телевизионной и радиовещательной компании',
  },
  {
    ID: 26149,
    label: 'Руководитель группы (в промышленности)',
  },
  {
    ID: 26150,
    label: 'Руководитель группы (на транспорте)',
  },
  {
    ID: 26151,
    label: 'Руководитель группы (специализированной в прочих отраслях)',
  },
  {
    ID: 26152,
    label: 'Руководитель группы (научно-технического развития)',
  },
  {
    ID: 26153,
    label:
      'Руководитель группы (функциональной в прочих областях деятельности)',
  },
  {
    ID: 26154,
    label: 'Руководитель группы по инвентаризации строений и сооружений',
  },
  {
    ID: 26158,
    label: 'Руководитель департамента федерального министерства',
  },
  {
    ID: 26159,
    label: 'Руководитель (директор) федеральной службы',
  },
  {
    ID: 26162,
    label: 'Руководитель допризывной подготовки (военный руководитель)',
  },
  {
    ID: 26163,
    label:
      'Руководитель межрегионального территориального органа федерального органа исполнительной власти',
  },
  {
    ID: 26164,
    label: 'Руководитель миссии Российской Федерации',
  },
  {
    ID: 26165,
    label:
      'Руководитель кружка (клуба по интересам, коллектива, любительского объединения, секции, студии, туристской группы)',
  },
  {
    ID: 26166,
    label: 'Руководитель педагогических программ',
  },
  { ID: 26168, label: 'Руководитель полетов' },
  {
    ID: 26169,
    label:
      'Руководитель представительства федерального органа исполнительной власти',
  },
  { ID: 26170, label: 'Руководитель Пресс-службы' },
  {
    ID: 26171,
    label: 'Руководитель производственной практики',
  },
  {
    ID: 26172,
    label:
      'Руководитель подразделения объекта использования атомной энергии по физической защите',
  },
  {
    ID: 26177,
    label: 'Руководитель реабилитационного учреждения',
  },
  {
    ID: 26178,
    label: 'Руководитель протокола Президента Российской Федерации',
  },
  {
    ID: 26179,
    label:
      'Руководитель секретариата аудитора Счетной палаты Российской Федерации',
  },
  {
    ID: 26181,
    label:
      'Руководитель Секретариата заместителя Председателя Государственной Думы Федерального Собрания',
  },
  {
    ID: 26182,
    label:
      'Руководитель Секретариата заместителя Председателя Конституционного Суда Российской Федерации',
  },
  {
    ID: 26183,
    label:
      'Руководитель Секретариата Заместителя Председателя Правительства Российской Федерации',
  },
  {
    ID: 26184,
    label:
      'Руководитель Секретариата заместителя Председателя Совета Федерации Федерального Собрания',
  },
  {
    ID: 26186,
    label:
      'Руководитель Секретариата Конституционного Суда Российской Федерации',
  },
  {
    ID: 26188,
    label:
      'Руководитель Секретариата первого заместителя Председателя Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 26189,
    label:
      'Руководитель Секретариата первого заместителя Председателя Государственной Думы Федерального Собрания',
  },
  {
    ID: 26190,
    label:
      'Руководитель Секретариата Первого заместителя Председателя Правительства Российской Федерации',
  },
  {
    ID: 26194,
    label:
      'Руководитель Секретариата Председателя Верховного Суда Российской Федерации',
  },
  {
    ID: 26195,
    label:
      'Руководитель Секретариата Председателя Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 26196,
    label:
      'Руководитель Секретариата Председателя Государственной Думы Федерального Собрания',
  },
  {
    ID: 26197,
    label:
      'Руководитель Секретариата Председателя Конституционного Суда Российской Федерации',
  },
  {
    ID: 26198,
    label:
      'Руководитель Секретариата Председателя Правительства Российской Федерации',
  },
  {
    ID: 26199,
    label:
      'Руководитель Секретариата Председателя Совета Федерации Федерального Собрания',
  },
  {
    ID: 26200,
    label:
      'Руководитель Секретариата Председателя Счетной палаты Российской Федерации',
  },
  {
    ID: 26201,
    label:
      'Руководитель секретариата председателя федерального арбитражного суда (округа, субъекта Российской Федерации)',
  },
  {
    ID: 26203,
    label:
      'Руководитель Секретариата Совета Государственной Думы Федерального Собрания',
  },
  {
    ID: 26204,
    label:
      'Руководитель Секретариата судьи - секретаря Конституционного Суда Российской Федерации',
  },
  {
    ID: 26206,
    label:
      'Руководитель Службы протокола Администрации Президента Российской Федерации',
  },
  {
    ID: 26209,
    label:
      'Руководитель службы учета и контроля ядерных материалов (радиоактивных веществ) объекта использования атомной энергии',
  },
  {
    ID: 26216,
    label: 'Руководитель студенческого исследовательского бюро',
  },
  {
    ID: 26220,
    label: 'Руководитель творческой мастерской',
  },
  {
    ID: 26221,
    label:
      'Руководитель территориального органа федерального органа исполнительной власти',
  },
  {
    ID: 26224,
    label:
      'Руководитель федерального органа исполнительной власти (кроме федерального министерства)',
  },
  {
    ID: 26225,
    label:
      'Руководитель органа исполнительной власти субъекта Российской Федерации',
  },
  {
    ID: 26234,
    label: 'Руководитель физического воспитания',
  },
  {
    ID: 26238,
    label: 'Руководитель части (литературно-драматургической, музыкальной)',
  },
  { ID: 26241, label: 'Рыбовод' },
  { ID: 26341, label: 'Секретарь руководителя' },
  { ID: 26349, label: 'Секретарь (дипломатический)' },
  {
    ID: 26351,
    label: 'Секретарь комитета (общественной организации)',
  },
  { ID: 26353, label: 'Секретарь-машинистка' },
  { ID: 26357, label: 'Секретарь незрячего специалиста' },
  { ID: 26366, label: 'Секретарь пленума' },
  { ID: 26370, label: 'Секретарь правления' },
  { ID: 26375, label: 'Секретарь президиума' },
  {
    ID: 26380,
    label: 'Секретарь приемной Президента Российской Федерации',
  },
  {
    ID: 26389,
    label:
      'Секретарь совета (научно-технического, ученого, художественно-технического)',
  },
  {
    ID: 26395,
    label: 'Секретарь Совета Безопасности Российской Федерации',
  },
  { ID: 26405, label: 'Секретарь-стенографистка' },
  { ID: 26409, label: 'Секретарь суда' },
  {
    ID: 26410,
    label: 'Секретарь суда в аппарате Верховного Суда Российской Федерации',
  },
  { ID: 26416, label: 'Секретарь судебного заседания' },
  {
    ID: 26417,
    label:
      'Секретарь судебного заседания в аппарате Верховного Суда Российской Федерации',
  },
  { ID: 26422, label: 'Секретарь творческого союза' },
  {
    ID: 26426,
    label: 'Секретарь учебной части (диспетчер)',
  },
  { ID: 26429, label: 'Секретарь федеральной комиссии' },
  {
    ID: 26431,
    label: 'Секретарь федерации (по видам спорта)',
  },
  {
    ID: 26436,
    label: 'Секретарь Центральной избирательной комиссии Российской Федерации',
  },
  { ID: 26450, label: 'Синоптик' },
  { ID: 26456, label: 'Скульптор' },
  { ID: 26460, label: 'Следователь' },
  {
    ID: 26463,
    label: 'Следователь (средней квалификации)',
  },
  {
    ID: 26467,
    label: 'Следователь по особо важным делам',
  },
  { ID: 26470, label: 'Смотритель' },
  { ID: 26473, label: 'Смотритель здания' },
  {
    ID: 26476,
    label: 'Смотритель кладбища (колумбария)',
  },
  { ID: 26480, label: 'Советник' },
  { ID: 26481, label: 'Советник (в области права)' },
  {
    ID: 26486,
    label: 'Советник (в области политологии)',
  },
  {
    ID: 26496,
    label: 'Советник в Администрации Президента Российской Федерации',
  },
  {
    ID: 26498,
    label: 'Советник в аппарате Верховного Суда Российской Федерации',
  },
  {
    ID: 26500,
    label: 'Советник в Аппарате Государственной Думы Федерального Собрания',
  },
  {
    ID: 26502,
    label: 'Советник в аппарате Конституционного Суда Российской Федерации',
  },
  {
    ID: 26503,
    label: 'Советник в Аппарате Правительства Российской Федерации',
  },
  {
    ID: 26505,
    label: 'Советник в Аппарате Совета Федерации Федерального Собрания',
  },
  {
    ID: 26506,
    label:
      'Советник в аппарате Центральной избирательной комиссии Российской Федерации',
  },
  {
    ID: 26507,
    label:
      'Советник в центральном аппарате федерального органа исполнительной власти',
  },
  { ID: 26509, label: 'Советник-посланник' },
  { ID: 26513, label: 'Советник экономический' },
  {
    ID: 26522,
    label: 'Составитель лоций (руководств для плавания)',
  },
  { ID: 26527, label: 'Социальный работник' },
  { ID: 26531, label: 'Социолог' },
  { ID: 26534, label: 'Спасатель' },
  { ID: 26537, label: 'Спектроскопист' },
  { ID: 26541, label: 'Специалист' },
  {
    ID: 26550,
    label:
      'Специалист (в представительстве, территориальном органе федерального органа исполнительной власти, федеральном суде, прокуратуре)',
  },
  {
    ID: 26552,
    label:
      'Специалист I категории в Администрации Президента Российской Федерации',
  },
  {
    ID: 26554,
    label:
      'Специалист I категории в аппарате Верховного Суда Российской Федерации',
  },
  {
    ID: 26555,
    label:
      'Специалист I категории в аппарате Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 26557,
    label:
      'Специалист I категории в аппарате Генеральной прокуратуры Российской Федерации',
  },
  {
    ID: 26558,
    label:
      'Специалист I категории в Аппарате Государственной Думы Федерального Собрания',
  },
  {
    ID: 26560,
    label:
      'Специалист I категории в аппарате Конституционного Суда Российской Федерации',
  },
  {
    ID: 26561,
    label:
      'Специалист I категории в Аппарате Правительства Российской Федерации',
  },
  {
    ID: 26563,
    label:
      'Специалист I категории в Аппарате Совета Федерации Федерального Собрания',
  },
  {
    ID: 26564,
    label:
      'Специалист I категории в аппарате Центральной избирательной комиссии Российской Федерации',
  },
  {
    ID: 26566,
    label:
      'Специалист I категории в центральном аппарате федерального органа исполнительной власти',
  },
  {
    ID: 26568,
    label:
      'Специалист II категории в аппарате Верховного Суда Российской Федерации',
  },
  {
    ID: 26569,
    label:
      'Специалист II категории в аппарате Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 26571,
    label:
      'Специалист II категории в аппарате Генеральной прокуратуры Российской Федерации',
  },
  {
    ID: 26572,
    label:
      'Специалист II категории в аппарате Конституционного Суда Российской Федерации',
  },
  {
    ID: 26573,
    label:
      'Специалист II категории в центральном аппарате федерального органа исполнительной власти',
  },
  {
    ID: 26575,
    label: 'Специалист военного представительства',
  },
  { ID: 26577, label: 'Специалист гражданской обороны' },
  {
    ID: 26578,
    label: 'Специалист по автотехнической экспертизе (эксперт-автотехник)',
  },
  { ID: 26579, label: 'Специалист по защите информации' },
  { ID: 26583, label: 'Специалист по кадрам' },
  { ID: 26585, label: 'Специалист по маркетингу' },
  {
    ID: 26586,
    label: 'Специалист по материальному обеспечению сборных команд',
  },
  {
    ID: 26587,
    label: 'Специалист по подготовке сборных команд',
  },
  { ID: 26588, label: 'Специалист по социальной работе' },
  {
    ID: 26589,
    label: 'Специалист по профессиональной ориентации инвалидов',
  },
  {
    ID: 26591,
    label: 'Специалист по реабилитации инвалидов',
  },
  { ID: 26592, label: 'Специалист по физиологии труда' },
  {
    ID: 26593,
    label: 'Специалист по экологическому просвещению',
  },
  {
    ID: 26594,
    label: 'Специалист по экономическому обеспечению сборных команд',
  },
  { ID: 26595, label: 'Специалист по эргономике' },
  { ID: 26596, label: 'Специалист Сбербанка' },
  {
    ID: 26598,
    label: 'Специалист-эксперт в Администрации Президента Российской Федерации',
  },
  {
    ID: 26600,
    label:
      'Специалист-эксперт в Аппарате Государственной Думы Федерального Собрания',
  },
  {
    ID: 26602,
    label: 'Специалист-эксперт в Аппарате Правительства Российской Федерации',
  },
  {
    ID: 26604,
    label:
      'Специалист-эксперт в Аппарате Совета Федерации Федерального Собрания',
  },
  {
    ID: 26606,
    label:
      'Специалист-эксперт в аппарате Центральной избирательной комиссии Российской Федерации',
  },
  { ID: 26617, label: 'Спортсмен-инструктор' },
  {
    ID: 26621,
    label: 'Стажер государственного нотариуса',
  },
  {
    ID: 26626,
    label: 'Стажер-исследователь (в области физики и астрономии)',
  },
  {
    ID: 26627,
    label: 'Стажер-исследователь (в области метеорологии)',
  },
  {
    ID: 26628,
    label: 'Стажер-исследователь (в области химии)',
  },
  {
    ID: 26629,
    label: 'Стажер-исследователь (в области геологии и геофизики)',
  },
  {
    ID: 26630,
    label: 'Стажер-исследователь (в области математики)',
  },
  {
    ID: 26631,
    label: 'Стажер-исследователь (в области статистики)',
  },
  {
    ID: 26632,
    label:
      'Стажер-исследователь (в области информатики и вычислительной техники)',
  },
  {
    ID: 26633,
    label: 'Стажер-исследователь (в области биологии)',
  },
  {
    ID: 26634,
    label: 'Стажер-исследователь (в области бактериологии и фармакологии)',
  },
  {
    ID: 26635,
    label: 'Стажер-исследователь (в области медицины)',
  },
  {
    ID: 26636,
    label: 'Стажер-исследователь (в области образования)',
  },
  {
    ID: 26637,
    label: 'Стажер-исследователь (в области права)',
  },
  {
    ID: 26638,
    label: 'Стажер-исследователь (в области экономики)',
  },
  {
    ID: 26639,
    label: 'Стажер-исследователь (в области социологии)',
  },
  {
    ID: 26640,
    label: 'Стажер-исследователь (в области философии, истории и политологии)',
  },
  {
    ID: 26641,
    label: 'Стажер-исследователь (в области филологии)',
  },
  {
    ID: 26642,
    label: 'Стажер-исследователь (в области психологии)',
  },
  {
    ID: 26652,
    label: 'Старший бортовой инженер (механик) авиационного отряда',
  },
  {
    ID: 26656,
    label: 'Старший бортовой радист авиационного отряда',
  },
  {
    ID: 26657,
    label:
      'Старший врач станций (отделений) скорой медицинской помощи (горноспасательных частей)',
  },
  {
    ID: 26658,
    label:
      'Старший государственный инспектор по охране территории государственного природного заповедника',
  },
  {
    ID: 26659,
    label: 'Старший инспектор в аппарате Счетной палаты Российской Федерации',
  },
  {
    ID: 26660,
    label: 'Старший инспектор летно-производственной службы центральной базы',
  },
  {
    ID: 26663,
    label:
      'Старший инспектор парашютной и десантно-пожарной службы центральной базы',
  },
  {
    ID: 26667,
    label: 'Старший инспектор по гидросооружениям',
  },
  {
    ID: 26668,
    label:
      'Старший инспектор по надзору за подконтрольным оборудованием атомной станции',
  },
  {
    ID: 26669,
    label: 'Старший инспектор по эксплуатации атомной станции',
  },
  {
    ID: 26670,
    label:
      'Старший инспектор по эксплуатации электростанций и предприятий сетей',
  },
  {
    ID: 26676,
    label: 'Старший инструктор-методист спортивной школы',
  },
  {
    ID: 26678,
    label: 'Старший инструктор-методист физкультурно-спортивных организаций',
  },
  {
    ID: 26682,
    label:
      'Старший консультант в аппарате Верховного Суда Российской Федерации',
  },
  {
    ID: 26684,
    label:
      'Старший консультант в аппарате Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 26686,
    label:
      'Старший консультант в аппарате Конституционного Суда Российской Федерации',
  },
  { ID: 26691, label: 'Старший механик-капитан' },
  { ID: 26694, label: 'Старший механик-командир' },
  {
    ID: 26697,
    label: 'Старший механик на плавучем кране',
  },
  {
    ID: 26701,
    label: 'Старший механик подводного аппарата',
  },
  {
    ID: 26705,
    label: 'Старший оператор диспетчерской движения флота',
  },
  { ID: 26709, label: 'Старший судебный пристав' },
  {
    ID: 26710,
    label: 'Старший судебный пристав военного суда',
  },
  {
    ID: 26712,
    label: 'Старший тренер-преподаватель по спорту',
  },
  { ID: 26713, label: 'Старший тренер сборной команды' },
  {
    ID: 26716,
    label: 'Старший штурман авиационного предприятия',
  },
  {
    ID: 26719,
    label: 'Старший штурман авиационного училища',
  },
  {
    ID: 26722,
    label: 'Старший штурман учебного авиационного центра',
  },
  { ID: 26727, label: 'Старший электромеханик-капитан' },
  { ID: 26730, label: 'Старший электромеханик-командир' },
  {
    ID: 26732,
    label: 'Старший электромеханик на плавучем кране',
  },
  { ID: 26739, label: 'Статистик' },
  { ID: 26740, label: 'Статс-секретарь' },
  { ID: 26743, label: 'Стенографистка' },
  { ID: 26749, label: 'Стивидор' },
  { ID: 26753, label: 'Строитель кораблей' },
  { ID: 26758, label: 'Судебный пристав' },
  { ID: 26760, label: 'Судебный пристав-исполнитель' },
  {
    ID: 26761,
    label:
      'Судебный пристав по обеспечению установленного порядка деятельности судов',
  },
  { ID: 26762, label: 'Судебный пристав военного суда' },
  { ID: 26763, label: 'Судья' },
  {
    ID: 26766,
    label: 'Судья Верховного Суда Российской Федерации',
  },
  {
    ID: 26768,
    label: 'Судья Высшего Арбитражного Суда Российской Федерации',
  },
  {
    ID: 26770,
    label: 'Судья Конституционного Суда Российской Федерации',
  },
  {
    ID: 26771,
    label:
      'Судья Конституционного (уставного) суда субъекта Российской Федерации',
  },
  {
    ID: 26775,
    label: 'Судья по испытанию племенных лошадей',
  },
  { ID: 26778, label: 'Судья по спорту' },
  {
    ID: 26782,
    label: 'Судья-секретарь Конституционного Суда Российской Федерации',
  },
  { ID: 26786, label: 'Судья федерального суда' },
  { ID: 26796, label: 'Супрефект' },
  { ID: 26800, label: 'Суфлер' },
  { ID: 26804, label: 'Счетовод' },
  { ID: 26904, label: 'Табельщик' },
  { ID: 26908, label: 'Таксировщик' },
  {
    ID: 26910,
    label: 'Таксировщик перевозочных документов',
  },
  { ID: 26913, label: 'Тарификатор' },
  { ID: 26917, label: 'Телеоператор' },
  { ID: 26918, label: 'Телеоператор-постановщик' },
  { ID: 26921, label: 'Теплотехник' },
  { ID: 26923, label: 'Териолог' },
  { ID: 26927, label: 'Техник' },
  { ID: 26930, label: 'Техник авиационный' },
  {
    ID: 26934,
    label:
      'Техник (механик) авиационный по эксплуатации авиационного оборудования объективного контроля',
  },
  {
    ID: 26937,
    label:
      'Техник (механик) авиационный по эксплуатации воздушных судов (систем воздушных судов)',
  },
  { ID: 26941, label: 'Техник-агрометеоролог' },
  { ID: 26944, label: 'Техник аэродромной службы' },
  { ID: 26947, label: 'Техник-аэролог' },
  { ID: 26950, label: 'Техник-аэрофотограмметрист' },
  {
    ID: 26953,
    label: 'Техник аэрофотографической лаборатории',
  },
  {
    ID: 26957,
    label: 'Техник аэрофотосъемочного производства',
  },
  { ID: 26961, label: 'Техник бортовой' },
  {
    ID: 26965,
    label: 'Техник вычислительного (информационно-вычислительного) центра',
  },
  { ID: 26969, label: 'Техник-геодезист' },
  { ID: 26973, label: 'Техник-геолог' },
  { ID: 26977, label: 'Техник-геофизик' },
  { ID: 26980, label: 'Техник-гидрогеолог' },
  { ID: 26983, label: 'Техник-гидролог' },
  { ID: 26986, label: 'Техник-гидротехник' },
  { ID: 26989, label: 'Техник-дозиметрист' },
  { ID: 26992, label: 'Техник-картограф' },
  { ID: 26996, label: 'Техник-конструктор' },
  { ID: 26999, label: 'Техник-лаборант' },
  { ID: 27003, label: 'Техник-лесопатолог' },
  { ID: 27006, label: 'Техник-маркшейдер' },
  { ID: 27009, label: 'Техник-метеоролог' },
  {
    ID: 27012,
    label: 'Техник-метролог (техник по метрологии)',
  },
  { ID: 27015, label: 'Техник объективного контроля' },
  { ID: 27018, label: 'Техник-океанолог' },
  {
    ID: 27021,
    label: 'Техник по автоматизации производственных процессов',
  },
  {
    ID: 27024,
    label: 'Техник по аэронавигационной информации',
  },
  { ID: 27027, label: 'Техник по бурению' },
  { ID: 27029, label: 'Техник по добыче нефти и газа' },
  { ID: 27032, label: 'Техник по защите информации' },
  { ID: 27035, label: 'Техник по звукозаписи' },
  {
    ID: 27037,
    label: 'Техник по инвентаризации строений и сооружений',
  },
  { ID: 27038, label: 'Техник по инструменту' },
  { ID: 27041, label: 'Техник по наладке и испытаниям' },
  {
    ID: 27044,
    label: 'Техник по обработке полетной информации',
  },
  { ID: 27046, label: 'Техник по планированию' },
  { ID: 27047, label: 'Техник по племенному делу' },
  {
    ID: 27050,
    label: 'Техник по подготовке и транспортировке нефти и газа',
  },
  {
    ID: 27053,
    label: 'Техник по подготовке производства',
  },
  {
    ID: 27056,
    label: 'Техник по радионавигации, радиолокации и связи',
  },
  {
    ID: 27057,
    label: 'Техник по расшифровке лент скоростемеров',
  },
  {
    ID: 27060,
    label:
      'Техник по светотехническому и электротехническому обеспечению полетов',
  },
  {
    ID: 27063,
    label: 'Техник по специальным применениям авиации',
  },
  { ID: 27066, label: 'Техник по стандартизации' },
  {
    ID: 27069,
    label: 'Техник по техническим средствам обучения',
  },
  {
    ID: 27070,
    label: 'Техник по техническим средствам реабилитации инвалидов',
  },
  { ID: 27072, label: 'Техник по труду' },
  { ID: 27075, label: 'Техник по учету' },
  {
    ID: 27078,
    label: 'Техник по электрохимической защите',
  },
  {
    ID: 27081,
    label: 'Техник по эксплуатации и ремонту спортивной техники',
  },
  {
    ID: 27084,
    label:
      'Техник по эксплуатации и ремонту технических средств обработки полетной информации',
  },
  {
    ID: 27087,
    label: 'Техник по эксплуатации нефтепроводов',
  },
  {
    ID: 27090,
    label: 'Техник по эксплуатации оборудования газовых объектов',
  },
  {
    ID: 27093,
    label:
      'Техник по эксплуатации сетей и сооружений водопроводно-канализационного хозяйства',
  },
  {
    ID: 27095,
    label:
      'Техник по эксплуатации технических средств железных дорог (метрополитенов)',
  },
  {
    ID: 27096,
    label: 'Техник по эксплуатации тренажеров',
  },
  { ID: 27099, label: 'Техник-программист' },
  { ID: 27102, label: 'Техник-проектировщик' },
  { ID: 27105, label: 'Техник-протезист' },
  { ID: 27108, label: 'Техник-смотритель' },
  { ID: 27111, label: 'Техник-таксатор' },
  { ID: 27114, label: 'Техник-радиооператор' },
  { ID: 27117, label: 'Техник-теплотехник' },
  { ID: 27120, label: 'Техник-технолог' },
  { ID: 27123, label: 'Техник-энергетик' },
  {
    ID: 27135,
    label:
      'Технический руководитель (в сельском, охотничьем, лес ном и рыбном хозяйстве)',
  },
  {
    ID: 27136,
    label: 'Технический руководитель (в промышленности)',
  },
  { ID: 27142, label: 'Технолог' },
  { ID: 27145, label: 'Технолог-наставник' },
  { ID: 27150, label: 'Товаровед' },
  { ID: 27154, label: 'Токсиколог' },
  { ID: 27159, label: 'Топограф' },
  { ID: 27162, label: 'Транспортный экспедитор' },
  {
    ID: 27163,
    label: 'Тренер - ветеринарный врач сборной команды',
  },
  { ID: 27164, label: 'Тренер' },
  { ID: 27165, label: 'Тренер-врач сборной команды' },
  { ID: 27166, label: 'Тренер команды' },
  { ID: 27167, label: 'Тренер-инженер сборной команды' },
  { ID: 27168, label: 'Тренер-преподаватель по спорту' },
  {
    ID: 27169,
    label: 'Тренер-массажист сборной команды',
  },
  { ID: 27170, label: 'Тренер-механик сборной команды' },
  {
    ID: 27171,
    label: 'Тренер - оператор видеозаписи сборной команды',
  },
  {
    ID: 27172,
    label: 'Тренер - преподаватель по адаптивной физической культуре',
  },
  { ID: 27173, label: 'Тренер сборной команды' },
  { ID: 27174, label: 'Укладчик текста' },
  {
    ID: 27178,
    label: 'Уполномоченный конторы по импорту скота',
  },
  { ID: 27183, label: 'Уполномоченный по делам религии' },
  {
    ID: 27188,
    label: 'Уполномоченный по правам человека',
  },
  {
    ID: 27192,
    label: 'Уполномоченный по приемке судов от судостроительных заводов',
  },
  {
    ID: 27194,
    label: 'Уполномоченный Российской Федерации',
  },
  {
    ID: 27195,
    label: 'Уполномоченный федерального органа исполнительной власти',
  },
  { ID: 27196, label: 'Уполномоченный фонда' },
  { ID: 27206, label: 'Управляющий делами' },
  {
    ID: 27208,
    label: 'Управляющий делами Президента Российской Федерации',
  },
  {
    ID: 27212,
    label: 'Управляющий контрольно-испытательной станцией',
  },
  {
    ID: 27215,
    label: 'Управляющий отделением (банка и др.)',
  },
  {
    ID: 27218,
    label: 'Управляющий отделением (фермой, сельскохозяйственным участком)',
  },
  { ID: 27221, label: 'Управляющий трестом' },
  { ID: 27224, label: 'Управляющий учебным хозяйством' },
  {
    ID: 27227,
    label:
      'Участковый государственный инспектор по охране территории государственного природного заповедника',
  },
  { ID: 27229, label: 'Ученый секретарь' },
  {
    ID: 27234,
    label: 'Ученый - хранитель Государственного эталона',
  },
  { ID: 27238, label: 'Учетчик' },
  { ID: 27244, label: 'Учитель' },
  { ID: 27245, label: 'Учитель (средней квалификации)' },
  { ID: 27247, label: 'Учитель-дефектолог' },
  {
    ID: 27248,
    label: 'Учитель-дефектолог (средней квалификации)',
  },
  { ID: 27250, label: 'Учитель-логопед' },
  {
    ID: 27251,
    label: 'Учитель-логопед (средней квалификации)',
  },
  { ID: 27302, label: 'Фабрикатор' },
  { ID: 27306, label: 'Фармаколог' },
  { ID: 27309, label: 'Фармацевт' },
  {
    ID: 27310,
    label: 'Фармацевт (средней квалификации)',
  },
  { ID: 27311, label: 'Фасовщица' },
  { ID: 27317, label: 'Федеральный министр' },
  { ID: 27328, label: 'Фельдшер' },
  {
    ID: 27330,
    label: 'Фельдшер-лаборант (медицинский лабораторный техник)',
  },
  { ID: 27336, label: 'Физик' },
  { ID: 27339, label: 'Физиолог' },
  { ID: 27345, label: 'Фотодактилоскопист' },
  { ID: 27348, label: 'Фотокорреспондент' },
  { ID: 27352, label: 'Фототехник' },
  { ID: 27361, label: 'Фрахтовщик' },
  { ID: 27392, label: 'Химик' },
  { ID: 27396, label: 'Хореограф' },
  { ID: 27400, label: 'Хормейстер' },
  { ID: 27406, label: 'Хранитель фондов' },
  { ID: 27408, label: 'Хранитель экспонатов' },
  { ID: 27412, label: 'Хронометражист' },
  { ID: 27417, label: 'Художественный руководитель' },
  { ID: 27421, label: 'Художник' },
  { ID: 27423, label: 'Художник-бутафор' },
  { ID: 27425, label: 'Художник-глазопротезист' },
  { ID: 27428, label: 'Художник-гример' },
  { ID: 27430, label: 'Художник-декоратор' },
  {
    ID: 27431,
    label: 'Художник-декоратор (средней квалификации)',
  },
  { ID: 27434, label: 'Художник-зарисовщик' },
  { ID: 27438, label: 'Художник компьютерной графики' },
  { ID: 27439, label: 'Художник-конструктор (дизайнер)' },
  {
    ID: 27440,
    label: 'Художник-конструктор (дизайнер) (средней квалификации)',
  },
  { ID: 27443, label: 'Художник-кукловод' },
  { ID: 27447, label: 'Художник лаковой миниатюры' },
  { ID: 27450, label: 'Художник-модельер' },
  { ID: 27454, label: 'Художник-мультипликатор' },
  {
    ID: 27456,
    label: 'Художник народных художественных промыслов',
  },
  { ID: 27459, label: 'Художник-оформитель' },
  {
    ID: 27460,
    label: 'Художник-оформитель (средней квалификации)',
  },
  {
    ID: 27462,
    label: 'Художник-оформитель игровых кукол',
  },
  {
    ID: 27463,
    label: 'Художник-оформитель игровых кукол (средней квалификации)',
  },
  {
    ID: 27465,
    label: 'Художник по комбинированным съемкам',
  },
  { ID: 27467, label: 'Художник по свету' },
  { ID: 27470, label: 'Художник-постановщик' },
  {
    ID: 27472,
    label: 'Художник-постановщик телевидения',
  },
  { ID: 27476, label: 'Художник-реставратор' },
  { ID: 27479, label: 'Художник-ретушер' },
  { ID: 27482, label: 'Художник-скульптор' },
  { ID: 27485, label: 'Художник-шрифтовик телевидения' },
  {
    ID: 27489,
    label: 'Художник эктопротезного кабинета',
  },
  { ID: 27529, label: 'Частный детектив' },
  { ID: 27530, label: 'Чертежник' },
  { ID: 27534, label: 'Чертежник-конструктор' },
  {
    ID: 27541,
    label:
      'Член комитета (комиссии) Государственной Думы Федерального Собрания',
  },
  {
    ID: 27543,
    label: 'Член комитета (комиссии) Совета Федерации Федерального Собрания',
  },
  {
    ID: 27551,
    label: 'Член президиума академии (наук, художеств)',
  },
  {
    ID: 27552,
    label: 'Член президиума регионального отделения',
  },
  {
    ID: 27556,
    label:
      'Член редакционной коллегии (издательства, редакции газет и журналов)',
  },
  {
    ID: 27563,
    label:
      'Член Судебной палаты по информационным спорам при Президенте Российской Федерации',
  },
  {
    ID: 27566,
    label:
      'Член Центральной избирательной комиссии Российской Федерации (замещающий должность на постоянной основе)',
  },
  { ID: 27575, label: 'Чтец' },
  { ID: 27606, label: 'Шапитмейстер' },
  { ID: 27609, label: 'Шеф-инженер' },
  { ID: 27610, label: 'Шеф-повар' },
  { ID: 27611, label: 'Шеф-редактор' },
  { ID: 27614, label: 'Шипчандлер' },
  { ID: 27618, label: 'Шифровальщик' },
  { ID: 27623, label: 'Шкипер' },
  { ID: 27627, label: 'Шкипер рейда' },
  { ID: 27634, label: 'Штурман (в авиации)' },
  { ID: 27635, label: 'Штурман (на флоте)' },
  { ID: 27638, label: 'Штурман авиационного отряда' },
  { ID: 27640, label: 'Штурман авиационной эскадрильи' },
  {
    ID: 27644,
    label:
      'Штурман аэроклуба (авиационно-технического спортивного, сверхлегкой авиации и др.)',
  },
  { ID: 27647, label: 'Штурман дежурный аэропорта' },
  { ID: 27650, label: 'Штурман-инспектор' },
  { ID: 27653, label: 'Штурман-инструктор' },
  { ID: 27657, label: 'Штурман-инструктор тренажера' },
  { ID: 27661, label: 'Штурман-испытатель' },
  { ID: 27664, label: 'Штурман наведения' },
  { ID: 27667, label: 'Штурман-оператор' },
  {
    ID: 27669,
    label:
      'Штурман-оператор авиационной космической и радиолокационной дальней навигации',
  },
  {
    ID: 27673,
    label: 'Штурман центра (службы, бюро) аэронавигационной информации',
  },
  {
    ID: 27677,
    label:
      'Штурман центрального (регионального) командно-диспетчерского пункта',
  },
  { ID: 27681, label: 'Штурман экипажа' },
  { ID: 27722, label: 'Эвакуатор' },
  { ID: 27728, label: 'Экономист' },
  {
    ID: 27732,
    label: 'Экономист вычислительного (информационно-вычислительного) центра',
  },
  { ID: 27736, label: 'Экономист-демограф' },
  {
    ID: 27740,
    label:
      'Экономист по бухгалтерскому учету и анализу хозяйственной деятельности',
  },
  {
    ID: 27743,
    label: 'Экономист по договорной и претензионной работе',
  },
  {
    ID: 27744,
    label: 'Экономист по материально-техническому снабжению',
  },
  { ID: 27745, label: 'Экономист по планированию' },
  { ID: 27746, label: 'Экономист по сбыту' },
  {
    ID: 27750,
    label: 'Экономист по составлению расписаний движения воздушных судов',
  },
  { ID: 27755, label: 'Экономист по труду' },
  { ID: 27759, label: 'Экономист по финансовой работе' },
  { ID: 27765, label: 'Экскурсовод' },
  { ID: 27770, label: 'Экспедитор' },
  { ID: 27772, label: 'Экспедитор по перевозке грузов' },
  { ID: 27779, label: 'Эксперт' },
  {
    ID: 27780,
    label:
      'Эксперт (в представительстве федерального органа исполнительной власти)',
  },
  {
    ID: 27783,
    label: 'Эксперт по анализу факторов условий труда',
  },
  {
    ID: 27786,
    label: 'Эксперт по внешнеэкономическим вопросам',
  },
  {
    ID: 27789,
    label: 'Эксперт по комплектованию музейного и выставочного фонда',
  },
  {
    ID: 27792,
    label:
      'Эксперт-физик по контролю за источниками ионизирующих и неионизирующих излучений',
  },
  { ID: 27806, label: 'Электрик-испытатель бортовой' },
  { ID: 27809, label: 'Электрик участка' },
  { ID: 27812, label: 'Электрик цеха' },
  { ID: 27817, label: 'Электромеханик' },
  { ID: 27819, label: 'Электромеханик (судовой)' },
  {
    ID: 27821,
    label: 'Электромеханик линейный флота (по флоту)',
  },
  {
    ID: 27824,
    label: 'Электромеханик линейных сооружений связи и абонентских устройств',
  },
  { ID: 27826, label: 'Электромеханик маяка' },
  {
    ID: 27829,
    label: 'Электромеханик на дноочистительном снаряде',
  },
  {
    ID: 27831,
    label: 'Электромеханик на землесосе, земснаряде',
  },
  { ID: 27834, label: 'Электромеханик-наставник' },
  {
    ID: 27837,
    label: 'Электромеханик перегрузочных машин',
  },
  {
    ID: 27840,
    label: 'Электромеханик подводного аппарата',
  },
  {
    ID: 27843,
    label: 'Электромеханик по подъемным установкам',
  },
  {
    ID: 27846,
    label: 'Электромеханик радионавигационной системы',
  },
  { ID: 27850, label: 'Электромеханик связи' },
  {
    ID: 27853,
    label: 'Электромеханик средств радио и телевидения',
  },
  {
    ID: 27854,
    label: 'Электромеханик телевидения (радиовещания)',
  },
  { ID: 27856, label: 'Электромеханик участка' },
  { ID: 27858, label: 'Электромеханик флота (по флоту)' },
  { ID: 27862, label: 'Электроник' },
  { ID: 27866, label: 'Энергетик' },
  { ID: 27869, label: 'Энергетик гидроузла (шлюза)' },
  { ID: 27872, label: 'Энергетик участка' },
  { ID: 27875, label: 'Энергетик цеха' },
  { ID: 27879, label: 'Энергодиспетчер' },
  { ID: 27883, label: 'Энтомолог' },
  { ID: 27887, label: 'Энтофитопатолог' },
  { ID: 27891, label: 'Эпидемиолог' },
  { ID: 27931, label: 'Юрисконсульт' },
  {
    ID: 27933,
    label: 'Юрисконсульт (средней квалификации)',
  },
  { ID: 30018, label: 'Автоклавщик' },
  { ID: 30057, label: 'Анодчик' },
  {
    ID: 30126,
    label: 'Аппаратчик восстановления гексафторида',
  },
  {
    ID: 30130,
    label: 'Аппаратчик в производстве драгоценных металлов',
  },
  {
    ID: 30132,
    label: 'Аппаратчик в производстве металлических порошков',
  },
  {
    ID: 30138,
    label:
      'Аппаратчик в производстве ультрадисперсных порошков (металлических)',
  },
  { ID: 30171, label: 'Аппаратчик газовых смесей' },
  { ID: 30173, label: 'Аппаратчик газоочистки' },
  {
    ID: 30175,
    label: 'Аппаратчик газоразделительного производства',
  },
  { ID: 30180, label: 'Аппаратчик галоидирования' },
  { ID: 30182, label: 'Аппаратчик гидролиза' },
  { ID: 30188, label: 'Аппаратчик-гидрометаллург' },
  {
    ID: 30214,
    label: 'Аппаратчик деструкции фторопластов',
  },
  {
    ID: 30266,
    label: 'Аппаратчик изготовления микросфер',
  },
  {
    ID: 30322,
    label: 'Аппаратчик конденсационно-испарительной установки',
  },
  {
    ID: 30330,
    label: 'Аппаратчик коррозионных испытаний',
  },
  { ID: 30355, label: 'Аппаратчик металлопокрытий' },
  {
    ID: 30366,
    label: 'Аппаратчик-наладчик электролизно-обменной цепочки',
  },
  {
    ID: 30368,
    label: 'Аппаратчик нанесения металлического порошка',
  },
  {
    ID: 30374,
    label: 'Аппаратчик на приготовлении смесей и растворов',
  },
  { ID: 30427, label: 'Аппаратчик обработки силикагеля' },
  {
    ID: 30430,
    label: 'Аппаратчик ожижения водорода и гелия',
  },
  { ID: 30448, label: 'Аппаратчик опытных процессов' },
  {
    ID: 30495,
    label: 'Аппаратчик пассивации газоразделительного оборудования',
  },
  {
    ID: 30506,
    label: 'Аппаратчик переработки водородосодержащих соединений лития',
  },
  {
    ID: 30533,
    label: 'Аппаратчик по изготовлению источников ионизирующего излучения',
  },
  {
    ID: 30535,
    label: 'Аппаратчик по изготовлению керамических изделий',
  },
  {
    ID: 30537,
    label: 'Аппаратчик по изготовлению пластических составов',
  },
  {
    ID: 30539,
    label: 'Аппаратчик по изготовлению термитных изделий из ксенона',
  },
  {
    ID: 30541,
    label: 'Аппаратчик покрытия изделий из спецматериалов, источников',
  },
  {
    ID: 30549,
    label: 'Аппаратчик получения гексафторида урана',
  },
  {
    ID: 30551,
    label: 'Аппаратчик получения гидроокиси лития',
  },
  {
    ID: 30563,
    label: 'Аппаратчик получения карбонатов лития',
  },
  {
    ID: 30606,
    label: 'Аппаратчик получения трифторида хлора',
  },
  { ID: 30608, label: 'Аппаратчик получения фреонов' },
  {
    ID: 30610,
    label: 'Аппаратчик получения фтористого аммония',
  },
  {
    ID: 30612,
    label: 'Аппаратчик получения фтористого водорода',
  },
  {
    ID: 30614,
    label: 'Аппаратчик получения фтористого кальция',
  },
  {
    ID: 30617,
    label:
      'Аппаратчик получения фтористых соединений редких и редкоземельных элементов',
  },
  {
    ID: 30619,
    label: 'Аппаратчик получения фторсодержащих соединений',
  },
  {
    ID: 30621,
    label: 'Аппаратчик получения химически чистой плавиковой кислоты',
  },
  {
    ID: 30624,
    label: 'Аппаратчик получения хлорида лития',
  },
  { ID: 30626, label: 'Аппаратчик по напылению селеном' },
  {
    ID: 30634,
    label:
      'Аппаратчик по переработке, разделению и очистке химических соединений металлов',
  },
  {
    ID: 30636,
    label: 'Аппаратчик по подготовке продукта',
  },
  {
    ID: 30650,
    label: 'Аппаратчик по разделению благородных и редкоземельных элементов',
  },
  {
    ID: 30670,
    label: 'Аппаратчик приготовления газовых смесей',
  },
  {
    ID: 30711,
    label: 'Аппаратчик приготовления пресс-порошков',
  },
  { ID: 30721, label: 'Аппаратчик приготовления смазок' },
  {
    ID: 30723,
    label: 'Аппаратчик приготовления суспензии',
  },
  { ID: 30766, label: 'Аппаратчик производства граната' },
  {
    ID: 30781,
    label: 'Аппаратчик производства изотопов',
  },
  {
    ID: 30833,
    label:
      'Аппаратчик производства полония, препаратов и источников на его основе',
  },
  {
    ID: 30835,
    label: 'Аппаратчик производства препаратов ионизирующего излучения',
  },
  { ID: 30862, label: 'Аппаратчик производства ТИ' },
  {
    ID: 30892,
    label: 'Аппаратчик прокалки и восстановления',
  },
  { ID: 30904, label: 'Аппаратчик разгонки' },
  {
    ID: 30920,
    label: 'Аппаратчик рафинирования металлов',
  },
  {
    ID: 30926,
    label: 'Аппаратчик ректификации органических соединений',
  },
  {
    ID: 30950,
    label: 'Аппаратчик синтеза меченых соединений',
  },
  { ID: 30989, label: 'Аппаратчик сульфоаммонизации' },
  { ID: 30997, label: 'Аппаратчик сушки и помола' },
  { ID: 30998, label: 'Аппаратчик сушки и прокалки' },
  {
    ID: 31048,
    label: 'Аппаратчик установки разделения стабильных изотопов',
  },
  { ID: 31075, label: 'Аппаратчик фторирования' },
  { ID: 31101, label: 'Аппаратчик-щелочник' },
  { ID: 31107, label: 'Аппаратчик электродиализа' },
  {
    ID: 31109,
    label: 'Аппаратчик электролизно-обменной цепочки',
  },
  {
    ID: 31273,
    label: 'Брикетировщик стружки из радиоактивных материалов',
  },
  {
    ID: 31312,
    label: 'Вакуумщик по испытанию оборудования и изделий на герметичность',
  },
  { ID: 31314, label: 'Вакуумщик-испытатель' },
  { ID: 31325, label: 'Вальцовщик изделий из ВМ' },
  { ID: 31358, label: 'Вальцовщик холодного металла' },
  { ID: 31426, label: 'Вжигальщик' },
  { ID: 31485, label: 'Волочильщик цветных металлов' },
  {
    ID: 31658,
    label: 'Гидрировщик в производстве лития',
  },
  { ID: 31665, label: 'Гидрорезчик специзделий' },
  { ID: 31727, label: 'Гофрировщик' },
  { ID: 31859, label: 'Дозировщик реагентов' },
  { ID: 31911, label: 'Дробильщик' },
  {
    ID: 31921,
    label: 'Дробильщик-размольщик в производстве ферритовых изделий',
  },
  { ID: 32047, label: 'Заготовщик пластмасс' },
  {
    ID: 32109,
    label: 'Загрузчик-выгрузчик электролизных ванн',
  },
  { ID: 32162, label: 'Заливщик' },
  { ID: 32239, label: 'Засыпщик' },
  {
    ID: 32328,
    label: 'Изготовитель источников щелочных металлов',
  },
  {
    ID: 32352,
    label: 'Изготовитель медицинских изделий из фторопласта',
  },
  {
    ID: 32466,
    label: 'Изготовитель фильтров и прокладок',
  },
  { ID: 32577, label: 'Испытатель-взрывник' },
  {
    ID: 32581,
    label: 'Испытатель детонаторных устройств',
  },
  { ID: 32583, label: 'Испытатель изделий' },
  {
    ID: 32596,
    label: 'Испытатель на критическом стенде',
  },
  {
    ID: 32598,
    label: 'Испытатель пожарных лестниц и ограждений крыш',
  },
  { ID: 32604, label: 'Испытатель специзделий' },
  { ID: 32746, label: 'Кинофотооператор' },
  {
    ID: 32758,
    label: 'Кладовщик инструментальной кладовой',
  },
  { ID: 32760, label: 'Кладовщик склада спецпродукции' },
  {
    ID: 32844,
    label: 'Комплектовщик готовой продукции и отходов',
  },
  { ID: 32877, label: 'Комплектовщик специзделий' },
  {
    ID: 32881,
    label: 'Комплектовщик сырья, материалов и готовой продукции',
  },
  {
    ID: 32937,
    label: 'Контролер внутреннего полигона предприятия',
  },
  { ID: 32941, label: 'Контролер в производстве лития' },
  {
    ID: 32943,
    label: 'Контролер в производстве металлов',
  },
  {
    ID: 32949,
    label: 'Контролер детонаторных устройств',
  },
  {
    ID: 32956,
    label:
      'Контролер изделий на автоматизированных измерительных средствах (системах) контроля',
  },
  {
    ID: 33008,
    label: 'Контролер основного производства',
  },
  {
    ID: 33014,
    label: 'Контролер по драгоценной продукции',
  },
  {
    ID: 33025,
    label: 'Контролер прессовых и слесарно-сборочных работ',
  },
  { ID: 33027, label: 'Контролер прессовых работ' },
  {
    ID: 33035,
    label: 'Контролер-приемщик сборок технологических каналов',
  },
  { ID: 33037, label: 'Контролер продукции обогащения' },
  {
    ID: 33039,
    label: 'Контролер продукции цветной металлургии',
  },
  { ID: 33042, label: 'Контролер производства изотопов' },
  {
    ID: 33044,
    label: 'Контролер производства керамических изделий',
  },
  { ID: 33046, label: 'Контролер производства ТВЭЛ' },
  { ID: 33048, label: 'Контролер-расчетчик' },
  {
    ID: 33050,
    label: 'Контролер производства ферритовых изделий',
  },
  {
    ID: 33052,
    label: 'Контролер сборки, разборки специзделий',
  },
  {
    ID: 33054,
    label: 'Контролер сборки, разборки ядерных боеприпасов',
  },
  {
    ID: 33056,
    label: 'Контролер сборочно-снаряжательных работ',
  },
  {
    ID: 33062,
    label: 'Контролер слесарно-сборочных, разборочных работ',
  },
  { ID: 33064, label: 'Контролер спецпроизводства' },
  {
    ID: 33077,
    label:
      'Контролер технического надзора по инструменту и технологической оснастке',
  },
  { ID: 33082, label: 'Контролер-учетчик' },
  {
    ID: 33084,
    label:
      'Контролер хранения, транспортирования ядерных боеприпасов и ядерно-делящихся материалов',
  },
  {
    ID: 33092,
    label: 'Контролер электровакуумного производства',
  },
  { ID: 33107, label: 'Концентраторщик' },
  {
    ID: 33167,
    label: 'Крановщик-сборщик, разборщик специзделий',
  },
  {
    ID: 33253,
    label: 'Лаборант аэрозольной лаборатории',
  },
  { ID: 33254, label: 'Лаборант взрывных испытаний' },
  { ID: 33256, label: 'Лаборант входного контроля' },
  {
    ID: 33258,
    label: 'Лаборант газового анализа и тонких пленок',
  },
  { ID: 33260, label: 'Лаборант-геофизик' },
  { ID: 33262, label: 'Лаборант-геохимик' },
  { ID: 33264, label: 'Лаборант-голографист' },
  { ID: 33266, label: 'Лаборант диффузионной сварки' },
  {
    ID: 33268,
    label: 'Лаборант испытаний электротехнических материалов',
  },
  {
    ID: 33270,
    label: 'Лаборант-испытатель спецаппаратуры',
  },
  {
    ID: 33272,
    label: 'Лаборант-испытатель электровакуумных материалов и спаев',
  },
  {
    ID: 33274,
    label: 'Лаборант-испытатель электровакуумных специзделий',
  },
  { ID: 33277, label: 'Лаборант-коррозионист' },
  {
    ID: 33279,
    label: 'Лаборант лаборатории спецпродуктов',
  },
  {
    ID: 33281,
    label: 'Лаборант лаборатории чувствительных элементов',
  },
  { ID: 33283, label: 'Лаборант масс-спектрометрист' },
  { ID: 33285, label: 'Лаборант-металлофизик' },
  {
    ID: 33288,
    label: 'Лаборант механических и климатических испытаний',
  },
  {
    ID: 33290,
    label: 'Лаборант неразрушающих методов контроля',
  },
  {
    ID: 33291,
    label: 'Лаборант обработки фильмовой информации физического эксперимента',
  },
  { ID: 33292, label: 'Лаборант определения плотности' },
  {
    ID: 33294,
    label: 'Лаборант органических материалов',
  },
  { ID: 33296, label: 'Лаборант-пирометаллург' },
  {
    ID: 33297,
    label: 'Лаборант по вибрационным испытаниям',
  },
  {
    ID: 33298,
    label: 'Лаборант по газодинамическим испытаниям',
  },
  {
    ID: 33300,
    label: 'Лаборант по измерениям физических производственных факторов',
  },
  {
    ID: 33303,
    label: 'Лаборант по инерционным испытаниям',
  },
  {
    ID: 33305,
    label: 'Лаборант по испытанию сверхпроводящих материалов',
  },
  {
    ID: 33307,
    label: 'Лаборант по испытаниям газовых систем специзделий',
  },
  {
    ID: 33309,
    label: 'Лаборант по климатическим испытаниям',
  },
  {
    ID: 33311,
    label: 'Лаборант по механической обработке металлов',
  },
  {
    ID: 33314,
    label: 'Лаборант по обработке металлов давлением',
  },
  {
    ID: 33316,
    label: 'Лаборант по обработке экспериментальных и расчетных данных',
  },
  {
    ID: 33318,
    label: 'Лаборант по определению электрических характеристик',
  },
  { ID: 33320, label: 'Лаборант по тепловым испытаниям' },
  { ID: 33323, label: 'Лаборант порошковой металлургии' },
  { ID: 33325, label: 'Лаборант-препаратор' },
  {
    ID: 33327,
    label: 'Лаборант приготовления электродов',
  },
  { ID: 33329, label: 'Лаборант-приемщик проб' },
  {
    ID: 33331,
    label: 'Лаборант радиоактивационных методов',
  },
  { ID: 33333, label: 'Лаборант-радиофизик' },
  { ID: 33335, label: 'Лаборант-радиохимик' },
  { ID: 33337, label: 'Лаборант-радиоэлектроник' },
  { ID: 33339, label: 'Лаборант-расчетчик' },
  {
    ID: 33342,
    label: 'Лаборант-расчетчик характеристик надежности',
  },
  { ID: 33344, label: 'Лаборант-рентгеногаммаграфист' },
  {
    ID: 33347,
    label: 'Лаборант-рентгенолог газодинамической лаборатории',
  },
  {
    ID: 33349,
    label: 'Лаборант специальной лаборатории стабильных изотопов',
  },
  {
    ID: 33352,
    label: 'Лаборант-рентгеноэлектронографист',
  },
  {
    ID: 33354,
    label: 'Лаборант теплотехнических стендов и установок',
  },
  { ID: 33356, label: 'Лаборант-тензометрист' },
  {
    ID: 33358,
    label: 'Лаборант ускорительной установки',
  },
  { ID: 33360, label: 'Лаборант-теплофизик' },
  {
    ID: 33362,
    label: 'Лаборант-физик лаборатории спецпродукта',
  },
  {
    ID: 33364,
    label: 'Лаборант физико-химических исследований',
  },
  { ID: 33367, label: 'Лаборант физической лаборатории' },
  {
    ID: 33369,
    label: 'Лаборант-физик-гамма-спектрометрист',
  },
  {
    ID: 33371,
    label: 'Лаборант химико-технологических исследований',
  },
  {
    ID: 33373,
    label: 'Лаборант хроматографического анализа',
  },
  {
    ID: 33375,
    label: 'Лаборант экспериментальных стендов и установок',
  },
  {
    ID: 33377,
    label: 'Лаборант электровакуумных специзделий',
  },
  {
    ID: 33379,
    label: 'Лаборант электромагнитной установки',
  },
  { ID: 33380, label: 'Лаборант-фотометрист' },
  {
    ID: 33381,
    label: 'Лаборант электротехнической лаборатории',
  },
  {
    ID: 33383,
    label: 'Лаборант электрофизической обработки материалов',
  },
  { ID: 33385, label: 'Лаборант-электрометаллург' },
  { ID: 33387, label: 'Лаборант-электрохимик' },
  {
    ID: 33389,
    label: 'Лаборант ядерной физической лаборатории',
  },
  { ID: 33391, label: 'Литейщик керамических изделий' },
  { ID: 33400, label: 'Литейщик-прессовщик' },
  {
    ID: 33630,
    label: 'Машинист-водитель машины разграждения',
  },
  {
    ID: 33712,
    label: 'Машинист дробильно-помольносортировочных механизмов',
  },
  { ID: 33778, label: 'Машинист конвейера' },
  { ID: 33871, label: 'Машинист мельниц' },
  {
    ID: 33970,
    label: 'Машинист паровых турбин атомных электрических станций',
  },
  {
    ID: 33972,
    label: 'Машинист паровых турбин специального назначения',
  },
  {
    ID: 34217,
    label: 'Машинист станции высокого давления',
  },
  { ID: 34446, label: 'Механик-артиллерист' },
  {
    ID: 34447,
    label: 'Механик экспериментальных стендов и установок',
  },
  {
    ID: 34557,
    label: 'Монтажник детонаторных устройств',
  },
  { ID: 34567, label: 'Монтажник на ремонте ванн' },
  { ID: 34834, label: 'Навесчик' },
  { ID: 34857, label: 'Нагревальщик цветных металлов' },
  {
    ID: 35184,
    label: 'Обжигальщик отходов производства',
  },
  { ID: 35431, label: 'Оклейщик' },
  {
    ID: 35482,
    label: 'Оператор автоматической линии изготовления ТВЭЛ',
  },
  {
    ID: 35494,
    label: 'Оператор автоматической регулирующей станции температуры',
  },
  { ID: 35520, label: 'Оператор взвешивания изделий' },
  { ID: 35522, label: 'Оператор вибростенда' },
  {
    ID: 35557,
    label: 'Оператор геотехнологических скважин',
  },
  { ID: 35559, label: 'Оператор герметизации блочков' },
  {
    ID: 35581,
    label:
      'Оператор дистанционного управления транспортными средствами внутришахтного транспорта',
  },
  {
    ID: 35615,
    label: 'Оператор исследовательской горячей камеры',
  },
  {
    ID: 35625,
    label: 'Оператор ковочно-прессовых агрегатов',
  },
  { ID: 35627, label: 'Оператор-комплектовщик' },
  {
    ID: 35635,
    label: 'Оператор контактно-стыковой сварки',
  },
  {
    ID: 35670,
    label: 'Оператор линии по обработке цветных металлов',
  },
  {
    ID: 35727,
    label:
      'Оператор на агрегатах непрерывного обезжиривания, травления, оксидирования и нанесения гальванических покрытий',
  },
  {
    ID: 35737,
    label: 'Оператор намагничивания ферритовых изделий',
  },
  {
    ID: 35757,
    label: 'Оператор нормализации деталей из ВМ',
  },
  {
    ID: 35869,
    label:
      'Оператор по поддержанию пластового давления и химической обработке скважин',
  },
  {
    ID: 35933,
    label:
      'Оператор производственного водоснабжения вентиляционных и газовых систем',
  },
  {
    ID: 35947,
    label: 'Оператор пульта технических средств охраны и пожарной сигнализации',
  },
  { ID: 35949, label: 'Оператор пульта управления' },
  {
    ID: 35958,
    label: 'Оператор пульта управления поверхностным шахтным комплексом',
  },
  {
    ID: 35959,
    label: 'Оператор пульта управления приготовлением закладочной смеси',
  },
  {
    ID: 35961,
    label: 'Оператор пульта электромагнитной установки',
  },
  {
    ID: 35971,
    label: 'Оператор радиохимического производства',
  },
  { ID: 36001, label: 'Оператор реактора' },
  { ID: 36026, label: 'Оператор снаряжения ТВЭЛ' },
  {
    ID: 36036,
    label: 'Оператор специального перфоратора',
  },
  {
    ID: 36052,
    label: 'Оператор стендов и установок радиационной техники',
  },
  { ID: 36144, label: 'Оператор установок спецпоезда' },
  {
    ID: 36162,
    label: 'Оператор центрального щита управления сублиматного производства',
  },
  { ID: 36190, label: 'Оператор щита КИПиА' },
  { ID: 36192, label: 'Оператор щита парогенераторной' },
  {
    ID: 36193,
    label: 'Оператор щита технологического контроля',
  },
  {
    ID: 36194,
    label: 'Оператор экспериментальных стендов и установок',
  },
  { ID: 36299, label: 'Отгазовщик' },
  { ID: 36361, label: 'Отжигальщик цветных металлов' },
  {
    ID: 36410,
    label: 'Оформитель технической документации',
  },
  { ID: 36541, label: 'Пескоструйщик' },
  {
    ID: 36584,
    label: 'Печевой йодиодного рафинирования',
  },
  { ID: 36588, label: 'Печевой на дистилляции металлов' },
  {
    ID: 36595,
    label: 'Печевой по восстановлению термическим способом',
  },
  {
    ID: 36597,
    label: 'Печевой по переработке отходов и оборотов производства',
  },
  { ID: 36612, label: 'Плавильщик' },
  {
    ID: 36652,
    label: 'Плавильщик электронно-лучевой плавки',
  },
  { ID: 36664, label: 'Пластикатчик' },
  { ID: 36677, label: 'Повар детского питания' },
  { ID: 36722, label: 'Подготовщик изделий к спеканию' },
  { ID: 36737, label: 'Подготовщик тары' },
  {
    ID: 36864,
    label: 'Помощник машиниста реактивной установки',
  },
  { ID: 36910, label: 'Портной по пошиву мягкой тары' },
  { ID: 36939, label: 'Правильщик проката и труб' },
  { ID: 36973, label: 'Прессовщик' },
  { ID: 36978, label: 'Прессовщик брикетов' },
  { ID: 36982, label: 'Прессовщик ВМ' },
  { ID: 37027, label: 'Прессовщик керамических изделий' },
  { ID: 37055, label: 'Прессовщик на гидропрессах' },
  {
    ID: 37059,
    label: 'Прессовщик на печах горячего прессования',
  },
  { ID: 37086, label: 'Прессовщик порошковых смесей' },
  { ID: 37118, label: 'Прессовщик твердых сплавов' },
  { ID: 37131, label: 'Прессовщик ферритовых изделий' },
  {
    ID: 37151,
    label: 'Приборист газодинамической лаборатории',
  },
  { ID: 37197, label: 'Приготовитель паст и пленок' },
  {
    ID: 37253,
    label: 'Приемщик драгоценных металлов и сырья',
  },
  { ID: 37360, label: 'Прокальщик' },
  {
    ID: 37362,
    label: 'Прокальщик металлических порошков',
  },
  { ID: 37370, label: 'Прокатчик горячего металла' },
  { ID: 37372, label: 'Прокатчик ленты из порошка' },
  {
    ID: 37394,
    label: 'Промывальщик медно-никелевого порошка',
  },
  {
    ID: 37535,
    label: 'Рабочий по изготовлению опытных изделий',
  },
  { ID: 37537, label: 'Рабочий полигона' },
  {
    ID: 37539,
    label: 'Рабочий по подготовке спецпродукта',
  },
  {
    ID: 37548,
    label:
      'Рабочий производства по изготовлению детонирующего шнура, зарядов и других комплектующих, содержащих взрывчатые вещества',
  },
  { ID: 37617, label: 'Раздельщик лома и специзделий' },
  { ID: 37797, label: 'Растворщик реагентов' },
  { ID: 37819, label: 'Расчехловщик' },
  { ID: 37845, label: 'Регенераторщик спецсмазок' },
  {
    ID: 37866,
    label: 'Регулировщик хвостового хозяйства',
  },
  { ID: 37903, label: 'Резчик ленты' },
  { ID: 37969, label: 'Резчик фольги' },
  { ID: 38266, label: 'Сборщик-снаряжальщик' },
  { ID: 38274, label: 'Сборщик технологических каналов' },
  { ID: 38285, label: 'Сборщик ферритовых изделий' },
  { ID: 38343, label: 'Сварщик-оператор' },
  { ID: 38345, label: 'Сварщик-перемотчик' },
  { ID: 38347, label: 'Сварщик спеченных изделий' },
  {
    ID: 38354,
    label: 'Сварщик электровакуумных приборов',
  },
  { ID: 38388, label: 'Сепараторщик' },
  { ID: 38389, label: 'Сепараторщик' },
  { ID: 38448, label: 'Слесарь-вакуумщик' },
  { ID: 38451, label: 'Слесарь-доводчик' },
  { ID: 38453, label: 'Слесарь-доводчик специзделий' },
  {
    ID: 38455,
    label: 'Слесарь-испытатель транспортных агрегатов',
  },
  {
    ID: 38498,
    label:
      'Слесарь по обслуживанию оборудования технологического регулирования',
  },
  {
    ID: 38553,
    label:
      'Слесарь по техническому обслуживанию оборудования газоразделительного производства',
  },
  { ID: 38562, label: 'Слесарь-сборщик' },
  {
    ID: 38566,
    label: 'Слесарь-сборщик универсальных сборных приспособлений (УСП)',
  },
  {
    ID: 38568,
    label: 'Слесарь-сборщик, разборщик ГК, ЦЧ',
  },
  {
    ID: 38570,
    label: 'Слесарь-сборщик, разборщик специзделий',
  },
  {
    ID: 38573,
    label: 'Слесарь-сборщик, разборщик ядерных боеприпасов',
  },
  { ID: 38575, label: 'Слесарь-склейщик' },
  { ID: 38615, label: 'Снаряжальщик детонаторов' },
  { ID: 38617, label: 'Снаряжальщик-доводчик' },
  {
    ID: 38741,
    label: 'Спайщик стеклоизделий в электрических печах с газовым наполнением',
  },
  { ID: 38744, label: 'Спекальщик' },
  { ID: 38746, label: 'Спекальщик ферритовых изделий' },
  { ID: 38748, label: 'Спекальщик шихты' },
  { ID: 38757, label: 'Стабилизаторщик' },
  { ID: 38759, label: 'Стабилизаторщик изделий холодом' },
  {
    ID: 38797,
    label: 'Станочник по обработке деталей из взрывчатых материалов',
  },
  { ID: 38919, label: 'Сушильщик' },
  {
    ID: 39162,
    label: 'Токарь по обработке радиоактивных материалов',
  },
  { ID: 39241, label: 'Трубопрокатчик' },
  { ID: 39297, label: 'Упаковщик' },
  { ID: 39299, label: 'Упаковщик в производстве лития' },
  { ID: 39359, label: 'Фильтровальщик' },
  { ID: 39361, label: 'Флотатор' },
  {
    ID: 39466,
    label: 'Фотокинолаборант газодинамической лаборатории',
  },
  { ID: 39468, label: 'Фотолаборант-расчетчик' },
  { ID: 39487, label: 'Фрезеровщик специзделий' },
  { ID: 39554, label: 'Чехловщик' },
  { ID: 39623, label: 'Шихтовщик' },
  { ID: 39629, label: 'Шлифовщик' },
  { ID: 39634, label: 'Шлифовщик брикетов' },
  { ID: 39647, label: 'Шлифовщик керамических изделий' },
  {
    ID: 39653,
    label: 'Шлифовщик металлооптических изделий',
  },
  { ID: 39673, label: 'Шлифовщик ферритовых изделий' },
  { ID: 39714, label: 'Штамповщик оболочек' },
  { ID: 39717, label: 'Штамповщик слюдяных изделий' },
  {
    ID: 39750,
    label: 'Электровакуумщик по напылению и насыщению',
  },
  { ID: 39770, label: 'Электролизник' },
  {
    ID: 39772,
    label: 'Электролизник расплавленных солей',
  },
  { ID: 39775, label: 'Электролизник хлористого лития' },
  { ID: 40064, label: 'Администратор баз данных' },
  {
    ID: 40067,
    label: 'Администратор вычислительной сети',
  },
  {
    ID: 40069,
    label: 'Администратор зала (предприятий общественного питания)',
  },
  {
    ID: 40070,
    label: 'Администратор информационной безопасности вычислительной сети',
  },
  {
    ID: 40759,
    label: 'Главный инженер отдела капитального строительства',
  },
  {
    ID: 40890,
    label: 'Главный специалист атомной станции',
  },
  {
    ID: 40988,
    label:
      'Главный специалист службы сбыта, хранения, транспортирования и контроля спецпродукции',
  },
  {
    ID: 41008,
    label: 'Главный технолог атомной станции',
  },
  { ID: 41017, label: 'Главный физик' },
  { ID: 41212, label: 'Дежурный оперативный' },
  { ID: 41330, label: 'Директор базы (туристической)' },
  {
    ID: 41599,
    label: 'Директор хозяйства (подсобного сельского)',
  },
  {
    ID: 41687,
    label: 'Диспетчер отдела эксплуатации автохозяйства',
  },
  { ID: 41786, label: 'Диспетчер энергослужбы' },
  {
    ID: 41969,
    label: 'Заведующий кабинетом охраны труда',
  },
  {
    ID: 42143,
    label: 'Заведующий станцией (автомобильной заправочной)',
  },
  { ID: 42457, label: 'Инженер-вакуумщик' },
  { ID: 42459, label: 'Инженер газоспасательной службы' },
  { ID: 42460, label: 'Инженер-дефектоскопист' },
  { ID: 42462, label: 'Инженер-дозиметрист' },
  { ID: 42474, label: 'Инженер-испытатель' },
  {
    ID: 42490,
    label: 'Инженер-исследователь по неразрушающему контролю и диагностике',
  },
  {
    ID: 42492,
    label: 'Инженер-конструктор-системотехник',
  },
  { ID: 42493, label: 'Инженер-конструктор-схемотехник' },
  {
    ID: 42494,
    label: 'Инженер контрольно-методической группы',
  },
  {
    ID: 42496,
    label: 'Инженер контроля качества сборки, разборки ядерных боеприпасов',
  },
  { ID: 42498, label: 'Инженер-коррозионист' },
  { ID: 42499, label: 'Инженер лаборатории' },
  { ID: 42504, label: 'Инженер-масс-спектрометрист' },
  { ID: 42507, label: 'Инженер-металловед' },
  { ID: 42508, label: 'Инженер-металлофизик' },
  { ID: 42514, label: 'Инженер-металлург' },
  {
    ID: 42517,
    label: 'Инженер отдела эксплуатации автохозяйства',
  },
  {
    ID: 42525,
    label:
      'Инженер по автоматизированным системам управления технологическими процессами',
  },
  { ID: 42526, label: 'Инженер по авторскому надзору' },
  {
    ID: 42555,
    label: 'Инженер по гражданской обороне и чрезвычайным ситуациям',
  },
  {
    ID: 42556,
    label: 'Инженер по грузовой и коммерческой работе',
  },
  {
    ID: 42558,
    label:
      'Инженер по диагностике технического состояния автомобилей, дорожно-строительных машин и механизмов',
  },
  {
    ID: 42561,
    label: 'Инженер по дозиметрическому контролю',
  },
  {
    ID: 42573,
    label: 'Инженер по инженерно-техническим средствам физической защиты',
  },
  {
    ID: 42586,
    label: 'Инженер по комплектации спецпродукции',
  },
  {
    ID: 42588,
    label:
      'Инженер по контрольно-измерительным приборам и автоматике на радиационной установке',
  },
  {
    ID: 42589,
    label:
      'Инженер по контрольным проверкам, рекламациям и переаттестации спецпродукции',
  },
  {
    ID: 42617,
    label: 'Инженер по наладке и дозиметрии радиационной техники',
  },
  {
    ID: 42626,
    label: 'Инженер по оперативному планированию и учету в энергохозяйстве',
  },
  {
    ID: 42676,
    label: 'Инженер по подготовке кадров для работы на атомном реакторе',
  },
  {
    ID: 42697,
    label: 'Инженер по промышленной безопасности',
  },
  {
    ID: 42700,
    label: 'Инженер по работе с источниками ионизирующих излучений',
  },
  {
    ID: 42701,
    label: 'Инженер по радиационной безопасности',
  },
  {
    ID: 42703,
    label: 'Инженер по радиационному контролю',
  },
  { ID: 42709, label: 'Инженер по радиосвязи' },
  { ID: 42722, label: 'Инженер по сбыту спецпродукции' },
  { ID: 42737, label: 'Инженер по спецучету' },
  {
    ID: 42769,
    label: 'Инженер по транспортированию спецгрузов',
  },
  {
    ID: 42774,
    label:
      'Инженер по управлению (оператор пульта управления) критическим стендом',
  },
  {
    ID: 42775,
    label: 'Инженер по управлению радиационной установкой',
  },
  {
    ID: 42783,
    label:
      'Инженер по усовершенствованию контуров и систем исследовательского ядерного реактора',
  },
  {
    ID: 42784,
    label: 'Инженер по учету сырья и готовой продукции',
  },
  {
    ID: 42786,
    label: 'Инженер по хранению спецпродукции',
  },
  {
    ID: 42798,
    label: 'Инженер по эксплуатации и ремонту гидротехнических сооружений',
  },
  {
    ID: 42805,
    label: 'Инженер по эксплуатации оборудования',
  },
  { ID: 42820, label: 'Инженер по ядерной безопасности' },
  { ID: 42834, label: 'Инженер-радиометрист' },
  {
    ID: 42841,
    label: 'Инженер секретного отдела (части, бюро, группы)',
  },
  { ID: 42843, label: 'Инженер - системный программист' },
  { ID: 42844, label: 'Инженер-спектрометрист' },
  { ID: 42852, label: 'Инженер-теплофизик' },
  {
    ID: 42853,
    label: 'Инженер технического отдела автохозяйства',
  },
  { ID: 42858, label: 'Инженер-физик' },
  {
    ID: 42859,
    label: 'Инженер-физик по расчетам и режимам',
  },
  { ID: 42861, label: 'Инженер-физико-химик' },
  {
    ID: 42862,
    label:
      'Инженер-химик службы эксплуатации исследовательского ядерного реактора',
  },
  { ID: 42863, label: 'Инженер-химик энергохозяйства' },
  {
    ID: 42865,
    label: 'Инженер химико-бактериологической лаборатории',
  },
  { ID: 42866, label: 'Инженер-электрик' },
  {
    ID: 42871,
    label: 'Инженер электротехнической лаборатории',
  },
  {
    ID: 42874,
    label:
      'Инженер-энергетик службы (группы) релейной защиты, автоматики, измерений и телемеханики',
  },
  {
    ID: 42991,
    label:
      'Инспектор по проведению профилактических осмотров водителей автомототранспортных средств',
  },
  {
    ID: 43167,
    label: 'Инструктор по службе (профессиональной подготовке)',
  },
  {
    ID: 43451,
    label: 'Командир отделения ведомственной охраны',
  },
  {
    ID: 43476,
    label: 'Комендант ведомственной охраны объекта',
  },
  {
    ID: 43832,
    label: 'Мастер дорожно-эксплуатационного участка',
  },
  {
    ID: 43835,
    label: 'Мастер дорожный (на железной дороге)',
  },
  { ID: 43838, label: 'Мастер зеленого хозяйства' },
  {
    ID: 43882,
    label:
      'Мастер по контролю технического состояния автомобилей, дорожно-строительных машин и механизмов',
  },
  { ID: 44449, label: 'Начальник военно-учетного стола' },
  {
    ID: 44488,
    label:
      'Начальник группы в службе эксплуатации исследовательского ядерного реактора (ускорителя заряженных частиц, экспериментальной установки)',
  },
  {
    ID: 44489,
    label: 'Начальник группы ведомственной охраны',
  },
  {
    ID: 44490,
    label:
      'Начальник группы (бюро), лаборатории в составе конструкторского, технологического, исследовательского, расчетного, экспериментального и других основных отделов',
  },
  { ID: 44516, label: 'Начальник дистанции пути' },
  {
    ID: 44517,
    label: 'Начальник дистанции сигнализации и связи',
  },
  {
    ID: 44524,
    label: 'Начальник дорожно-эксплуатационного участка',
  },
  { ID: 44529, label: 'Начальник железнодорожного цеха' },
  { ID: 44540, label: 'Начальник испытательного стенда' },
  { ID: 44541, label: 'Начальник испытательной группы' },
  {
    ID: 44543,
    label:
      'Начальник (главный инженер) исследовательского ядерного реактора (ускорителя заряженных частиц, экспериментальной установки)',
  },
  {
    ID: 44544,
    label: 'Начальник исследовательской группы',
  },
  {
    ID: 44550,
    label: 'Начальник караула ведомственной охраны',
  },
  {
    ID: 44571,
    label: 'Начальник команды (в составе отряда) ведомственной охраны',
  },
  { ID: 44580, label: 'Начальник компрессорной станции' },
  {
    ID: 44581,
    label: 'Начальник конструкторского отдела (службы)',
  },
  { ID: 44588, label: 'Начальник критического стенда' },
  {
    ID: 44604,
    label: 'Начальник лаборатории радиационного контроля',
  },
  {
    ID: 44664,
    label:
      'Начальник научно-исследовательского подразделения, в состав которого входят научно-исследовательские отделы и лаборатории',
  },
  {
    ID: 44672,
    label: 'Начальник объединенной энергослужбы',
  },
  {
    ID: 44706,
    label:
      'Начальник отдела автоматизированных систем управления технологическими процессами',
  },
  {
    ID: 44739,
    label:
      'Начальник отдела (штаба) гражданской обороны и чрезвычайных ситуаций',
  },
  {
    ID: 44740,
    label:
      'Начальник отдела контроля качества сборки, разборки ядерных боеприпасов',
  },
  {
    ID: 44741,
    label: 'Начальник отдела подготовки производства',
  },
  {
    ID: 44743,
    label: 'Начальник отдела промышленной безопасности',
  },
  {
    ID: 44744,
    label: 'Начальник отдела систем автоматизированного проектирования',
  },
  {
    ID: 44745,
    label: 'Начальник отдела (бюро) технического контроля',
  },
  {
    ID: 44746,
    label: 'Начальник отдела хранения и перевозок спецпродукции',
  },
  {
    ID: 44747,
    label: 'Начальник отдела эксплуатации автохозяйства',
  },
  {
    ID: 44776,
    label: 'Начальник отряда (в составе управления) ведомственной охраны',
  },
  {
    ID: 44794,
    label: 'Начальник патентно-лицензионного отдела',
  },
  {
    ID: 44838,
    label: 'Начальник проектно-сметного отдела (бюро, группы)',
  },
  {
    ID: 44857,
    label: 'Начальник пункта технического обслуживания вагонов',
  },
  {
    ID: 44860,
    label: 'Начальник радиационной установки',
  },
  { ID: 44866, label: 'Начальник радиоузла' },
  { ID: 44887, label: 'Начальник реакторного отделения' },
  {
    ID: 44895,
    label: 'Начальник ремонтно-механических мастерских',
  },
  {
    ID: 44899,
    label:
      'Начальник самостоятельного научно-исследовательского отдела (лаборатории, бюро, группы) по комплексной защите информации',
  },
  {
    ID: 44900,
    label:
      'Начальник самостоятельного научно-технического отдела (бюро, группы) по комплексной защите информации',
  },
  {
    ID: 44901,
    label:
      'Начальник самостоятельного отдела (лаборатории) (конструкторского, исследовательского, расчетного, экспериментального)',
  },
  {
    ID: 44908,
    label: 'Начальник секретного отдела организации',
  },
  {
    ID: 44909,
    label:
      'Начальник (руководитель) секретного отдела (части, бюро, группы) цеха, сектора и других производственных структур организации',
  },
  { ID: 44913, label: 'Начальник складского хозяйства' },
  {
    ID: 44928,
    label: 'Начальник службы атомной станции',
  },
  {
    ID: 44937,
    label: 'Начальник службы подвижного состава',
  },
  { ID: 44938, label: 'Начальник службы пути' },
  {
    ID: 44939,
    label: 'Начальник службы сигнализации и связи',
  },
  {
    ID: 44940,
    label: 'Начальник службы эксплуатации (движения)',
  },
  {
    ID: 44941,
    label:
      'Начальник службы эксплуатации (заместитель главного инженера) исследовательского ядерного реактора (ускорителя заряженных частиц, экспериментальной установки)',
  },
  {
    ID: 44942,
    label:
      'Начальник смены исследовательского ядерного реактора (ускорителя заряженных частиц, экспериментальной установки)',
  },
  {
    ID: 44943,
    label: 'Начальник смены цеха электростанции',
  },
  { ID: 44944, label: 'Начальник смены электростанции' },
  { ID: 44963, label: 'Начальник спецсооружения' },
  { ID: 44964, label: 'Начальник спецхранилища' },
  {
    ID: 44997,
    label: 'Начальник тепловой электростанции',
  },
  {
    ID: 44998,
    label: 'Начальник технического отдела автохозяйства',
  },
  { ID: 45007, label: 'Начальник транспортного отдела' },
  { ID: 45013, label: 'Начальник турбинного отделения' },
  {
    ID: 45062,
    label:
      'Начальник управления (отдельного отряда, отдельной команды, отдельной группы) ведомственной охраны',
  },
  {
    ID: 45098,
    label: 'Начальник химико-бактериологической лаборатории',
  },
  {
    ID: 45109,
    label: 'Начальник центра (отдела) по связям с общественностью',
  },
  {
    ID: 45157,
    label: 'Начальник электротехнической лаборатории',
  },
  {
    ID: 45158,
    label: 'Начальник энергоблока атомной станции',
  },
  { ID: 45159, label: 'Начальник энергоинспекции' },
  {
    ID: 45291,
    label: 'Оперативный дежурный отряда ведомственной охраны',
  },
  {
    ID: 45309,
    label: 'Оператор диспетчерской службы железнодорожного транспорта',
  },
  { ID: 45417, label: 'Охранник ведомственной охраны' },
  { ID: 45844, label: 'Приборист цеха' },
  { ID: 46102, label: 'Риэлтер' },
  {
    ID: 46115,
    label:
      'Руководитель аналитической группы подразделения по комплексной защите информации',
  },
  {
    ID: 46155,
    label: 'Руководитель группы подразделения по комплексной защите информации',
  },
  { ID: 46472, label: 'Смотритель зданий и сооружений' },
  { ID: 46514, label: 'Сопровождающий спецгрузы' },
  {
    ID: 46584,
    label: 'Специалист по мобилизационной подготовке экономики',
  },
  {
    ID: 46590,
    label: 'Специалист по связям с общественностью',
  },
  { ID: 46733, label: 'Старшина ведомственной охраны' },
  { ID: 46928, label: 'Техник абонентского бюро' },
  { ID: 46988, label: 'Техник дистанции пути' },
  { ID: 46990, label: 'Техник-испытатель' },
  {
    ID: 47022,
    label:
      'Техник по автоматизированным системам управления технологическими процессами (техник-конструктор-системотехник)',
  },
  {
    ID: 47036,
    label: 'Техник по инженерно-техническим средствам физической защиты',
  },
  {
    ID: 47039,
    label: 'Техник по комплектации спецпродукции',
  },
  {
    ID: 47040,
    label: 'Техник по контрольно-измерительным приборам и автоматике',
  },
  {
    ID: 47042,
    label: 'Техник по наладке и дозиметрии радиационной техники',
  },
  { ID: 47043, label: 'Техник по нормированию труда' },
  {
    ID: 47061,
    label: 'Техник по сопровождению спецгрузов',
  },
  { ID: 47064, label: 'Техник по спецучету' },
  { ID: 47074, label: 'Техник по учету в автохозяйстве' },
  {
    ID: 47076,
    label: 'Техник по учету в энергохозяйстве',
  },
  {
    ID: 47077,
    label: 'Техник по учету сырья и готовой продукции',
  },
  {
    ID: 47079,
    label: 'Техник по хранению спецпродукции',
  },
  {
    ID: 47080,
    label: 'Техник по эксплуатации и ремонту оборудования',
  },
  { ID: 47106, label: 'Техник связи (АТС)' },
  { ID: 47107, label: 'Техник секретного отдела' },
  {
    ID: 47109,
    label: 'Техник службы подвижного состава',
  },
  { ID: 47110, label: 'Техник службы пути' },
  { ID: 47112, label: 'Техник службы эксплуатации' },
  {
    ID: 47122,
    label: 'Техник-электрик-наладчик электронного оборудования',
  },
  {
    ID: 47337,
    label: 'Физик (контролирующий) критического стенда',
  },
  {
    ID: 47855,
    label:
      'Электромеханик устройств сигнализации, централизации, блокировки и средств связи',
  },
];
export default code;
