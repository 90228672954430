const riskManagement = [
  {
    dependence:
      'Заражение работника вследствие воздействия микроорганизмов-продуцентов, препаратов, содержащих живые клетки и споры микроорганизмов в воздухе, воде, на поверхностях',
    ID: '1.1.1',
    label:
      'Соблюдение требований охраны труда и санитарно-гигиенических требований, применение СИЗ',
  },
  {
    dependence:
      'Заболевание работника, связанное с воздействием патогенных микроорганизмов',
    ID: '1.2.1',
    label:
      'Соблюдение требований охраны труда и санитарно-гигиенических требований, применение СИЗ',
  },
  {
    dependence:
      'Травма или заболевание вследствие отсутствия защиты от вредных (травмирующих) факторов, от которых защищают СИЗ',
    ID: '2.1.1',
    label:
      'Регулярная проверка СИЗ на состояние работоспособности и комплектности. Назначить локальным нормативным актом ответственное лицо за учет выдачи СИЗ и их контроль за состоянием, комплектностью',
  },
  {
    dependence:
      'Травма или заболевание вследствие отсутствия защиты от вредных (травмирующих) факторов, от которых защищают СИЗ',
    ID: '2.1.2',
    label:
      'Ведение в организации личных карточек учета выдачи СИЗ. Фактический учет выдачи и возврата СИЗ.',
  },
  {
    dependence:
      'Травма или заболевание вследствие отсутствия защиты от вредных (травмирующих) факторов, от которых защищают СИЗ',
    ID: '2.1.3',
    label:
      'Точное выполнение требований по уходу, хранению СИЗ. Обеспечение сохранения эффективности СИЗ при хранении, химчистке, ремонте, стирке, обезвреживании, дегазации, дезактивации',
  },
  {
    dependence:
      'Травма или заболевание вследствие отсутствия защиты от вредных (травмирующих) факторов, от которых защищают СИЗ',
    ID: '2.2.1',
    label:
      'Применение СИЗ соответствующего вида и способа защиты. Выдача СИЗ соответствующего типа в зависимости от вида опасности',
  },
  {
    dependence:
      'Травма или заболевание вследствие отсутствия защиты от вредных (травмирующих) факторов, от которых защищают СИЗ',
    ID: '2.3.1',
    label:
      'Приобретение СИЗ в специализированных магазинах. Закупка СИЗ, имеющих действующий сертификат и (или) декларацию соответствия',
  },
  {
    dependence:
      'Травма или заболевание вследствие отсутствия защиты от вредных (травмирующих) факторов, от которых защищают СИЗ',
    ID: '2.3.2',
    label:
      'Наличие входного контроля при поступлении СИЗ в организацию. Проверка наличия инструкций по использованию СИЗ, даты изготовления, срока годности/эксплуатации, от каких вредных факторов защищает СИЗ, документа о соответствии СИЗ нормам эффективности и качества (сертификат/декларация соответствия СИЗ требованиям технического регламента Таможенного Союза "О безопасности средств индивидуальной защиты" (ТР ТС 019/2011) (Официальный сайт Комиссии Таможенного союза http://www.tsouz.ru/, 15.12.2011; Официальный сайт Евразийского экономического союза http://www.eaeunion.org/, 05.03.2020)',
  },
  {
    dependence:
      'Падение при спотыкании или поскальзывании, при передвижении по скользким поверхностям или мокрым полам',
    ID: '3.1.1',
    label: 'Использование противоскользящих напольных покрытий',
  },
  {
    dependence:
      'Падение при спотыкании или поскальзывании, при передвижении по скользким поверхностям или мокрым полам',
    ID: '3.1.2',
    label: 'Использование противоскользящих покрытий для малых слоев грязи',
  },
  {
    dependence:
      'Падение при спотыкании или поскальзывании, при передвижении по скользким поверхностям или мокрым полам',
    ID: '3.1.3',
    label:
      'Использование незакрепленных покрытий с сопротивлением скольжению на обратной стороне (например, ковров, решеток и другое)',
  },
  {
    dependence:
      'Падение при спотыкании или поскальзывании, при передвижении по скользким поверхностям или мокрым полам',
    ID: '3.1.4',
    label:
      'Исключение применения различных напольных покрытий с большой разницей в сопротивлении к скольжению',
  },
  {
    dependence:
      'Падение при спотыкании или поскальзывании, при передвижении по скользким поверхностям или мокрым полам',
    ID: '3.1.5',
    label:
      'Предотвращение накопления влаги во влажных помещениях (применение подходящих вариантов дренажа и вентиляции воздуха)',
  },
  {
    dependence:
      'Падение при спотыкании или поскальзывании, при передвижении по скользким поверхностям или мокрым полам',
    ID: '3.1.6',
    label:
      'Предотвращение воздействия факторов, связанных с погодными условиями (Монтаж кровли на рабочих местах на открытом воздухе)',
  },
  {
    dependence:
      'Падение при спотыкании или поскальзывании, при передвижении по скользким поверхностям или мокрым полам',
    ID: '3.1.7',
    label:
      'Нанесение противоскользящих средств (опилок, антиобледенительных средств, песка)',
  },
  {
    dependence:
      'Падение при спотыкании или поскальзывании, при передвижении по скользким поверхностям или мокрым полам',
    ID: '3.1.8',
    label:
      'Своевременная уборка покрытий (поверхностей), подверженных воздействию факторов природы (снег, дождь, грязь)',
  },
  {
    dependence:
      'Падение при спотыкании или поскальзывании, при передвижении по скользким поверхностям или мокрым полам',
    ID: '3.1.9',
    label:
      'Своевременный уход за напольной поверхностью (Предотвращение попадания жирных и маслянистых веществ)',
  },
  {
    dependence:
      'Падение при спотыкании или поскальзывании, при передвижении по скользким поверхностям или мокрым полам',
    ID: '3.1.10',
    label:
      'Химическая обработка для увеличения шероховатости поверхности механическая и термическая последующая обработка (Шлифование, фрезерование, лазерно-техническое восстановление)',
  },
  {
    dependence:
      'Падение при спотыкании или поскальзывании, при передвижении по скользким поверхностям или мокрым полам',
    ID: '3.1.11',
    label: 'Установка полос противоскольжения на наклонных поверхностях',
  },
  {
    dependence:
      'Падение при спотыкании или поскальзывании, при передвижении по скользким поверхностям или мокрым полам',
    ID: '3.1.12',
    label: 'Выполнение инструкций по охране труда',
  },
  {
    dependence:
      'Падение при спотыкании или поскальзывании, при передвижении по скользким поверхностям или мокрым полам',
    ID: '3.1.13',
    label: 'Обеспечение специальной (рабочей) обувью',
  },
  {
    dependence: 'Падение с высоты или из-за перепада высот на поверхности',
    ID: '3.2.1',
    label:
      'Заполнение материалом углублений, отверстий, в которые можно попасть при падении (например, с помощью разделительных защитных устройств)',
  },
  {
    dependence: 'Падение с высоты или из-за перепада высот на поверхности',
    ID: '3.2.2',
    label:
      'Защита опасных мест (использование неподвижных металлических листов, пластин)',
  },
  {
    dependence: 'Падение с высоты или из-за перепада высот на поверхности',
    ID: '3.2.3',
    label:
      'Закрытие небезопасных участков (крепление поручней или других опор на небезопасных поверхностях)',
  },
  {
    dependence: 'Падение с высоты или из-за перепада высот на поверхности',
    ID: '3.2.4',
    label: 'Установка противоскользящих полос на наклонных поверхностях',
  },
  {
    dependence: 'Падение с высоты или из-за перепада высот на поверхности',
    ID: '3.2.5',
    label: 'Устранение приподнятых краев тротуара',
  },
  {
    dependence: 'Падение с высоты или из-за перепада высот на поверхности',
    ID: '3.2.6',
    label: 'Использование поручня или иных опор',
  },
  {
    dependence: 'Падение с высоты или из-за перепада высот на поверхности',
    ID: '3.2.7',
    label:
      'Исключение нахождения на полу посторонних предметов, их своевременная уборка',
  },
  {
    dependence: 'Падение с высоты или из-за перепада высот на поверхности',
    ID: '3.2.8',
    label:
      'Устранение или предотвращение возникновения беспорядка на рабочем месте',
  },
  {
    dependence: 'Падение с высоты или из-за перепада высот на поверхности',
    ID: '3.2.9',
    label:
      'Устранение ступеней разной высоты и глубины в местах подъема (спуска)',
  },
  {
    dependence: 'Падение с высоты или из-за перепада высот на поверхности',
    ID: '3.2.10',
    label:
      'Освещение, обеспечивающее видимость ступеней и краев ступеней. Расположение освещения, обеспечивающее достаточную видимость ступенек и краев ступеней, использование при необходимости дополнительной цветовой кодировки. Обеспечение хорошей различимости края первой и последней ступеньки',
  },
  {
    dependence: 'Падение с высоты или из-за перепада высот на поверхности',
    ID: '3.2.11',
    label:
      'Обеспечение достаточного уровня освещенности и контрастности на рабочих местах (в рабочих зонах): уровня освещения, контраста, отсутствия иллюзий восприятия',
  },
  {
    dependence: 'Падение с высоты или из-за перепада высот на поверхности',
    ID: '3.2.12',
    label:
      'Размещение маркированных ограждений и/или уведомлений (знаки, таблички, объявления)',
  },
  {
    dependence: 'Падение с высоты или из-за перепада высот на поверхности',
    ID: '3.2.13',
    label: 'Выполнение инструкций по охране труда',
  },
  {
    dependence: 'Падение с высоты или из-за перепада высот на поверхности',
    ID: '3.2.14',
    label: 'Обеспечение специальной (рабочей) обувью',
  },
  {
    dependence:
      'Падение из-за отсутствия ограждения, из-за обрыва троса, в котлован, в шахту при подъеме или спуске при нештатной ситуации',
    ID: '3.3.1',
    label:
      'Избегать перепадов высоты, краев и участков, лежащих глубже в непосредственной близости от рабочих мест, маршрутов движения, стендов, рабочих мест на рабочем оборудовании и системах',
  },
  {
    dependence:
      'Падение из-за отсутствия ограждения, из-за обрыва троса, в котлован, в шахту при подъеме или спуске при нештатной ситуации',
    ID: '3.3.2',
    label:
      'Исключение при планировании зданий размещения технического оборудования на крышах или размещение такого оборудования на достаточно большом расстоянии от кромок спуска',
  },
  {
    dependence:
      'Падение из-за отсутствия ограждения, из-за обрыва троса, в котлован, в шахту при подъеме или спуске при нештатной ситуации',
    ID: '3.3.3',
    label:
      'Расположение элементов управления и оборудования для эксплуатации и обслуживания на высоте, доступной с наземной стойки',
  },
  {
    dependence:
      'Падение из-за отсутствия ограждения, из-за обрыва троса, в котлован, в шахту при подъеме или спуске при нештатной ситуации',
    ID: '3.3.4',
    label: 'Автоматизация и использование роботов для очистки фасадов',
  },
  {
    dependence:
      'Падение из-за отсутствия ограждения, из-за обрыва троса, в котлован, в шахту при подъеме или спуске при нештатной ситуации',
    ID: '3.3.5',
    label: 'Использование датчиков или камер для удаленного контроля',
  },
  {
    dependence:
      'Падение из-за отсутствия ограждения, из-за обрыва троса, в котлован, в шахту при подъеме или спуске при нештатной ситуации',
    ID: '3.3.6',
    label: 'Установка устройств, предотвращающих падение',
  },
  {
    dependence:
      'Падение из-за отсутствия ограждения, из-за обрыва троса, в котлован, в шахту при подъеме или спуске при нештатной ситуации',
    ID: '3.3.7',
    label: 'Защита опасных зон от несанкционированного доступа',
  },
  {
    dependence:
      'Падение из-за отсутствия ограждения, из-за обрыва троса, в котлован, в шахту при подъеме или спуске при нештатной ситуации',
    ID: '3.3.8',
    label:
      'Использование в качестве СИЗ системы крепления человека к якорному устройству таким образом, чтобы предотвратить падение или остановить падение человека',
  },
  {
    dependence:
      'Падение из-за отсутствия ограждения, из-за обрыва троса, в котлован, в шахту при подъеме или спуске при нештатной ситуации',
    ID: '3.3.9',
    label:
      'Регулировка высоты рабочих мест на стационарных объектах. Создание фиксированных по высоте рабочих мест и входов (маршрутов движения) для повторяющихся работ на высоте, например, при уборке балконов, систем доступа, мостков, лестниц',
  },
  {
    dependence:
      'Падение из-за внезапного появления на пути следования большого перепада высот',
    ID: '3.4.1',
    label:
      'Соблюдение установленных норм: Максимальный перепад высот между краем падения или рабочим местом/маршрутом движения и зоной удара: Защитные леса на крыше - 1,50 м, все остальные защитные леса - 2,00 м, Защитные сетки: 6,00 м или 3,00 м по краю, Сети рабочей платформы - 2,00 м',
  },
  {
    dependence: 'Падение с транспортного средства',
    ID: '3.5.1',
    label:
      'Установка ограждений рабочих помещений, расположенных в опасных зонах на высоте',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.1.1',
    label:
      'Исключение выполнения работ вблизи водоемов, на палубах судов и нефтяных платформах',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.1.2',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.1.3',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.1.4',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.1.5',
    label:
      'Проведение дополнительных инструктажей, практических занятий и тренировок, связанных с выполнением работ на водоемах и вблизи их',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.1.6',
    label:
      'Назначение ответственного лица, контролирующего выполнение работ на водоемах и вблизи их',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.2.1',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.2.2',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.2.3',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.2.4',
    label: 'Размещение плакатов (табличек) с предупредительными надписями',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.2.5',
    label:
      'Размещение защитных ограждений, исключающих вероятность падения работника',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.2.6',
    label:
      'Назначение ответственного лица за безопасное выполнение работ и контроль',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.3.1',
    label:
      'Проведение дополнительных инструктажей, практических занятий и тренировок, связанных с выполнением работ на водоемах и вблизи их',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.3.2',
    label:
      'Назначение ответственного лица, контролирующего выполнение работ на водоемах и вблизи их.',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.3.3',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Утопление в результате падения в воду',
    ID: '4.3.4',
    label:
      'Обеспечение дополнительными СИЗ при выполнении спасательных операций на льду',
  },
  {
    dependence: 'Утопление в результате падения в емкость с жидкостью',
    ID: '4.4.1',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence: 'Утопление в результате падения в емкость с жидкостью',
    ID: '4.4.2',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Утопление в результате падения в емкость с жидкостью',
    ID: '4.4.3',
    label: 'Исключение работ внутри либо вблизи технологических емкостей.',
  },
  {
    dependence: 'Утопление в результате падения в емкость с жидкостью',
    ID: '4.4.4',
    label: 'Размещение плакатов (табличек) с предупредительными надписями',
  },
  {
    dependence: 'Утопление в результате падения в емкость с жидкостью',
    ID: '4.4.5',
    label:
      'Размещение защитных ограждений, исключающих вероятность падения работника в технологическую емкость',
  },
  {
    dependence: 'Утопление в результате падения в емкость с жидкостью',
    ID: '4.4.6',
    label:
      'Назначение ответственного лица за безопасное выполнение работ и контроль выполнения таких работ',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.5.1',
    label: 'Исключение работ внутри либо вблизи технологических емкостей',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.5.2',
    label: 'Размещение плакатов (табличек) с предупредительными надписями',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.5.3',
    label:
      'Назначение ответственного лица за безопасное выполнение работ и контроль',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.5.4',
    label:
      'Исключение выполнения работ в момент естественного (природного) затопления шахты',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.5.5',
    label:
      'Исключение выполнения работ во время технологического (вынужденного) затопления шахты',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.5.6',
    label:
      'Исключение выполнения работ во время аварии, повлекшей за собой затопление шахты',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.5.7',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.5.8',
    label:
      'Назначение ответственного лица за безопасное выполнение работ и контроль',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.5.9',
    label:
      'Размещение плакатов (табличек) с предупредительными надписями о проведении работ по затоплению шахты',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.5.10',
    label:
      'Организация эффективной системы дистанционного общения и оповещения между производственными участками',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.6.1',
    label: 'Исключение работ внутри либо вблизи технологических емкостей',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.6.2',
    label: 'Размещение плакатов (табличек) с предупредительными надписями',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.6.3',
    label:
      'Назначение ответственного лица за безопасное выполнение работ и контроль',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.6.4',
    label:
      'Исключение выполнения работ во время естественного (природного) затопления шахты',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.6.5',
    label:
      'Исключение выполнения работ во время технологического (вынужденного) затопления шахты',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.6.6',
    label:
      'Исключение выполнения работ во время аварии, повлекшей за собой затопление шахты',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.6.7',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.6.8',
    label:
      'Назначение ответственного лица за безопасное выполнение работ и контроль',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.6.9',
    label:
      'Размещение плакатов (табличек) с предупредительными надписями о проведении работ по затоплению шахты',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.6.10',
    label:
      'Организация эффективной системы дистанционного общения и оповещения между производственными участками',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.7.1',
    label: 'Исключение работ внутри либо вблизи технологических емкостей',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.7.2',
    label: 'Размещение плакатов (табличек) с предупредительными надписями',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.7.3',
    label:
      'Назначение ответственного лица за безопасное выполнение работ и контроль',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.7.4',
    label:
      'Исключение выполнения работ во время естественного (природного) затопления шахты',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.7.5',
    label:
      'Исключение выполнения работ во время технологического (вынужденного) затопления шахты',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.7.6',
    label:
      'Исключение выполнения работ во время аварии, повлекшей за собой затопление шахты',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.7.7',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.7.8',
    label:
      'Назначение ответственного лица за безопасное выполнение работ и контроль',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.7.9',
    label:
      'Размещение плакатов (табличек) с предупредительными надписями о проведении работ по затоплению шахты',
  },
  {
    dependence: 'Утопление в результате падения или попадания в воду',
    ID: '4.7.10',
    label:
      'Организация эффективной системы дистанционного общения и оповещения между производственными участками',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '5.1.1',
    label:
      'Соблюдение требований безопасности при монтаже подземных конструкций',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '5.1.2',
    label: 'Соблюдение правил эксплуатации подземных конструкций',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '5.1.3',
    label:
      'Установка системы контроля естественных природных подземных толчков и колебаний земной поверхности, наводнений, либо постоянное получение данной информации от сторонних источников',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '5.1.4',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '5.1.5',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '5.1.6',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '5.2.1',
    label:
      'Соблюдение требований безопасности при монтаже подземных конструкций',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '5.2.2',
    label: 'Соблюдение правил эксплуатации подземных конструкций',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '5.2.3',
    label:
      'Установка системы контроля естественных природных подземных толчков и колебаний земной поверхности, наводнений, либо постоянное получение данной информации от сторонних источников',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '5.2.4',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '5.2.5',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '5.2.6',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence:
      'Травма в результате заваливания или раздавливания, ожоги вследствие пожара, утопление при попадании в жидкость',
    ID: '5.3.1',
    label:
      'Соблюдение требований безопасности при монтаже подземных конструкций',
  },
  {
    dependence:
      'Травма в результате заваливания или раздавливания, ожоги вследствие пожара, утопление при попадании в жидкость',
    ID: '5.3.2',
    label: 'Соблюдение правил эксплуатации подземных конструкций',
  },
  {
    dependence:
      'Травма в результате заваливания или раздавливания, ожоги вследствие пожара, утопление при попадании в жидкость',
    ID: '5.3.3',
    label:
      'Установка системы контроля естественных природных подземных толчков и колебаний земной поверхности, наводнений, либо постоянное получение данной информации от сторонних источников',
  },
  {
    dependence:
      'Травма в результате заваливания или раздавливания, ожоги вследствие пожара, утопление при попадании в жидкость',
    ID: '5.3.4',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence:
      'Травма в результате заваливания или раздавливания, ожоги вследствие пожара, утопление при попадании в жидкость',
    ID: '5.3.5',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence:
      'Травма в результате заваливания или раздавливания, ожоги вследствие пожара, утопление при попадании в жидкость',
    ID: '5.3.6',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence:
      'Травма в результате заваливания или раздавливания, ожоги вследствие пожара, утопление при попадании в жидкость',
    ID: '5.3.7',
    label:
      'Своевременное прекращение работы и оставление подземного сооружения до его разрушения',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '6.1.1',
    label:
      'Соблюдение требований безопасности при монтаже наземных конструкций',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '6.1.2',
    label: 'Соблюдение правил эксплуатации наземных конструкций',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '6.1.3',
    label:
      'Установка системы контроля естественных природных подземных толчков и колебаний земной поверхности, наводнений, либо постоянное получение данной информации от сторонних источников',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '6.1.4',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '6.1.5',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Травма в результате заваливания или раздавливания',
    ID: '6.1.6',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence:
      'Травма в результате заваливания или раздавливания, ожоги вследствие пожара, утопление при попадании в жидкость',
    ID: '6.2.1',
    label:
      'Соблюдение требований безопасности при монтаже наземных конструкций',
  },
  {
    dependence:
      'Травма в результате заваливания или раздавливания, ожоги вследствие пожара, утопление при попадании в жидкость',
    ID: '6.2.2',
    label: 'Соблюдение правил эксплуатации наземных конструкций',
  },
  {
    dependence:
      'Травма в результате заваливания или раздавливания, ожоги вследствие пожара, утопление при попадании в жидкость',
    ID: '6.2.3',
    label:
      'Установка системы контроля естественных природных подземных толчков и колебаний земной поверхности, наводнений, либо постоянное получение данной информации от сторонних источников',
  },
  {
    dependence:
      'Травма в результате заваливания или раздавливания, ожоги вследствие пожара, утопление при попадании в жидкость',
    ID: '6.2.4',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence:
      'Травма в результате заваливания или раздавливания, ожоги вследствие пожара, утопление при попадании в жидкость',
    ID: '6.2.5',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence:
      'Травма в результате заваливания или раздавливания, ожоги вследствие пожара, утопление при попадании в жидкость',
    ID: '6.2.6',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence:
      'Травма в результате заваливания или раздавливания, ожоги вследствие пожара, утопление при попадании в жидкость',
    ID: '6.2.7',
    label:
      'Своевременное прекращение работы и оставление наземного сооружения до его разрушения',
  },
  {
    dependence: 'Наезд транспорта на человека',
    ID: '7.1.1',
    label:
      'Соблюдение правил дорожного движения и правил перемещения транспортных средств по территории работодателя, соблюдение скоростного режима, применение исправных транспортных средств, соответствующих требованиям безопасности',
  },
  {
    dependence: 'Наезд транспорта на человека',
    ID: '7.1.2',
    label:
      'Подача звуковых сигналов при движении и своевременное применение систем торможения в случае обнаружения на пути следования транспорта человека',
  },
  {
    dependence: 'Наезд транспорта на человека',
    ID: '7.1.3',
    label:
      'Разделение маршрутов движения людей и транспортных средств, исключающих случайный выход людей на пути движения транспорта, а также случайный выезд транспорта на пути движения людей, в том числе с применением отбойников и ограждений',
  },
  {
    dependence: 'Наезд транспорта на человека',
    ID: '7.1.4',
    label: 'Оборудование путей пересечения пешеходными переходами, светофорами',
  },
  {
    dependence: 'Травмирование в результате дорожно-транспортного происшествия',
    ID: '7.2.1',
    label:
      'Соблюдение правил дорожного движения и правил перемещения транспортных средств внутри территории работодателя. Разделение маршрутов движения людей и транспортных средств, исключающих случайный выход людей на пути движения транспорта, а также случайный выезд транспорта на пути движения людей, оборудование путей пересечения пешеходными переходами, светофорами',
  },
  {
    dependence:
      'Раздавливание человека, находящегося между двумя сближающимися транспортными средствами',
    ID: '7.3.1',
    label:
      'Соблюдение правил дорожного движения и правил перемещения транспортных средств внутри территории работодателя, разделение маршрутов движения людей и транспортных средств, исключающих случайный выход людей на пути движения транспорта, оборудование путей пересечения пешеходными переходами, светофорами',
  },
  {
    dependence:
      'Опрокидывание транспортного средства при нарушении способов установки и строповки грузов',
    ID: '7.4.1',
    label:
      'Соблюдение предельной грузоподъемности транспортных средств, соблюдение требований охраны труда при подъеме, перемещении, размещении грузов, соблюдение требований к строповке грузов',
  },
  {
    dependence: 'Опрокидывание транспортного средства при проведении работ',
    ID: '7.5.1',
    label:
      'Обеспечение устойчивого положения транспортного средства, исключающего его внезапное неконтролируемое перемещение',
  },
  {
    dependence:
      'Удары, порезы, проколы, уколы, затягивания, наматывания, абразивные воздействия подвижными частями оборудования',
    ID: '8.1.1',
    label: 'Использование блокировочных устройств',
  },
  {
    dependence:
      'Удары, порезы, проколы, уколы, затягивания, наматывания, абразивные воздействия подвижными частями оборудования',
    ID: '8.1.2',
    label:
      'Применение средств индивидуальной защиты специальных рабочих костюмов, халатов или роб, исключающих попадание свисающих частей одежды на быстродвижущиеся элементы производственного оборудования',
  },
  {
    dependence:
      'Удары, порезы, проколы, уколы, затягивания, наматывания, абразивные воздействия подвижными частями оборудования',
    ID: '8.1.3',
    label:
      'Применение комплексной защиты. Дистанционное управление производственным оборудованием, применяемого в опасных для нахождения человека зонах работы машин и механизмов. Осуществление контроля и регулирование работы опасного производственного оборудования из удаленных мест',
  },
  {
    dependence:
      'Удары, порезы, проколы, уколы, затягивания, наматывания, абразивные воздействия подвижными частями оборудования',
    ID: '8.1.4',
    label:
      'Применение предупредительной сигнализации, контрольно-измерительных приборов и автоматики',
  },
  {
    dependence:
      'Удары, порезы, проколы, уколы, затягивания, наматывания, абразивные воздействия подвижными частями оборудования',
    ID: '8.1.5',
    label:
      'Допуск к работе работника, прошедшего обучение и обладающего знаниями в объеме предусмотренным техническим описанием данного оборудования и общими правилами безопасности',
  },
  {
    dependence:
      'Удары, порезы, проколы, уколы, затягивания, наматывания, абразивные воздействия подвижными частями оборудования',
    ID: '8.1.6',
    label:
      'Определение круга лиц, осуществляющих контроль за состоянием и безопасной эксплуатацией движущихся элементов производственного оборудования',
  },
  {
    dependence:
      'Удары, порезы, проколы, уколы, затягивания, наматывания, абразивные воздействия подвижными частями оборудования',
    ID: '8.1.7',
    label:
      'Проведение, в установленные сроки, испытания производственного оборудования специальными службами государственного контроля',
  },
  {
    dependence:
      'Удары, порезы, проколы, уколы, затягивания, наматывания, абразивные воздействия подвижными частями оборудования',
    ID: '8.1.8',
    label: 'Соблюдение государственных нормативных требований охраны труда',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.1',
    label: 'Изменение производственного процесса',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.2',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.3',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.4',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.5',
    label:
      'Применение средств коллективной защиты, направленных на экранирование, изоляцию работника от воздействия факторов, в том числе вентиляции',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.6',
    label:
      'Применение систем аварийной остановки производственных процессов, предотвращающих наступление неблагоприятных последствий',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.7',
    label:
      'Подбор и применение рабочего оборудования с целью снижения влияния факторов производственной среды и трудового процесса',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.8',
    label:
      'Снижение времени неблагоприятного воздействия факторов производственной среды и трудового процесса на работника',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.9',
    label:
      'Механизация и автоматизация погрузочно-разгрузочных работ, способов транспортирования сырьевых материалов, готовой продукции и отходов производства',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.10',
    label:
      'Очистка оборудования, загрязненного веществами, обладающими остронаправленным механизмом действия, до начала работ по ремонту и обслуживанию такого оборудования',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.11',
    label:
      'Наличие аварийного комплекта СИЗ на складах хранения веществ, обладающих остронаправленным механизмом действия',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.12',
    label:
      'Использование станков и инструмента для механической обработки материалов и изделий, сопровождающейся выделением газов, паров и аэрозолей, совместно с системами удаления указанных веществ',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.13',
    label:
      'Не допущение очистки оборудования, вентиляционных систем, заготовок, готовых изделий, полов и стен от пыли сжатым воздухом без применения СИЗ',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.14',
    label:
      'Удаление воздуха из помещений системами вентиляции способом, исключающим прохождение его через зону дыхания работающих на постоянных рабочих местах',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.15',
    label:
      'Оснащение устройствами местной вытяжной вентиляции промышленного оборудования, характеризующегося выделением пыли, эксплуатация которого приводит к превышению гигиенических нормативов в воздухе рабочей зоны с постоянными рабочими местами',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.16',
    label:
      'Проведение работ с концентрированными кислотами и щелочами в изолированных помещениях с использованием аппаратуры, оборудованной местной вытяжной вентиляцией',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.17',
    label:
      'Использование для работы с веществами, обладающими остронаправленным механизмом действия, герметичного оборудования или систем автоматизированного и/или дистанционного управления процессом',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.18',
    label:
      'Размещение пультов управления технологическими процессами в изолированных помещениях при создании в них избыточного давления',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.19',
    label:
      'Оборудование емкостей, сборников, мерных сосудов технологических жидкостей, розлив которых приводит к формированию в рабочей зоне уровней загрязнения, превышающих гигиенические нормативы, системой сигнализации о максимальном допустимом уровне заполнения, использование уровнемеров для контроля содержания в емкостях таких технологических жидкостей',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.20',
    label:
      'Установка в рабочих помещениях гидрантов, фонтанчиков с автоматическим включением или души для немедленного смывания химических веществ, обладающих раздражающим действием, при их попадании на кожные покровы и слизистые оболочки глаз',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.21',
    label:
      'Применение технических средств, оборудованных двигателями внутреннего сгорания, в закрытых помещениях и замкнутых пространствах при наличии нейтрализаторов выхлопных газов или системы отвода газов',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.22',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.23',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.24',
    label: 'Рациональное чередование режимов труда и отдыха',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.25',
    label: 'Использование средств индивидуальной защиты',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.26',
    label:
      'Регулярное техническое обслуживание и ремонт технологического оборудования, инструмента и приспособлений',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.27',
    label: 'Устройство кабин наблюдения и дистанционного управления',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.28',
    label:
      'Оборудование технологических линий электрическими блокировками, обеспечивающими, в первую очередь, пуск аспирационных систем и газопылеулавливающих установок, а затем технологического оборудования',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.29',
    label:
      'Оборудование укрытиями узлов перегрузки исходных материалов, полуфабрикатов и готовой продукции, подсоединенными к аспирационным системам с аппаратами для очистки воздуха',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.30',
    label:
      'Механизация и автоматизация, применение дистанционного управления операциями и производственными процессами',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.31',
    label: 'Герметизация технологического оборудования',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.32',
    label: 'Своевременное удаление и обезвреживание производственных отходов',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.33',
    label:
      'Приготовление рабочих составов химических веществ при работающей вентиляции с использованием соответствующих СИЗ',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.34',
    label:
      'Осуществление слива использованных растворов из аппаратов способом, исключающим контакт работников с растворами, попадание растворов на пол помещения, выделение вредных веществ в воздух рабочей зоны',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.35',
    label:
      'Размещение химических веществ в складских помещениях по технологическим картам, разработанным в соответствии с паспортами безопасности химической продукции.',
  },
  {
    dependence:
      'Отравление воздушными взвесями вредных химических веществ в воздухе рабочей зоны',
    ID: '9.1.36',
    label: 'Хранение химических веществ с учетом их совместимости',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.2.1',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.2.2',
    label: 'Изменение производственного процесса',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.2.3',
    label:
      'Подбор и применение рабочего оборудования с целью снижения влияния факторов производственной среды и трудового процесса',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.2.4',
    label:
      'Использование станков и инструментов для механической обработки материалов и изделий, сопровождающихся выделением газов, паров и аэрозолей, совместно с системами удаления указанных веществ',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.2.5',
    label:
      'Установка в рабочих помещениях гидрантов, фонтанчиков с автоматическим включением или душа для немедленного смывания химических веществ, обладающих раздражающим действием, при их попадании на кожные покровы и слизистые оболочки глаз',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.2.6',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.2.7',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.2.8',
    label: 'Использование СИЗ',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.2.9',
    label: 'Герметизация технологического оборудования',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.3.1',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.3.2',
    label: 'Изменение производственного процесса',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.3.3',
    label:
      'Подбор и применение рабочего оборудования с целью снижения влияния факторов производственной среды и трудового процесса',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.3.4',
    label:
      'Использование станков и инструмента для механической обработки материалов и изделий, сопровождающихся выделением газов, паров и аэрозолей, совместно с системами удаления указанных веществ',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.3.5',
    label:
      'Установка в рабочих помещениях гидрантов, фонтанчиков с автоматическим включением или душа для немедленного смывания химических веществ, обладающих раздражающим действием, при их попадании на кожные покровы и слизистые оболочки глаз',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.3.6',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.3.7',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.3.8',
    label: 'Использование средств индивидуальной защиты',
  },
  {
    dependence: 'Заболевания кожи (дерматиты)',
    ID: '9.3.9',
    label: 'Герметизация технологического оборудования',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.1',
    label: 'Изменение производственного процесса',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.2',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.3',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.4',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.5',
    label:
      'Применение средств коллективной защиты, направленных на экранирование, изоляцию работника от воздействия факторов, в том числе вентиляции',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.6',
    label:
      'Применение систем аварийной остановки производственных процессов, предотвращающих наступление неблагоприятных последствий',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.7',
    label:
      'Подбор и применение рабочего оборудования с целью снижения влияния факторов производственной среды и трудового процесса',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.8',
    label:
      'Снижение времени неблагоприятного воздействия факторов производственной среды и трудового процесса на работника',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.9',
    label:
      'Механизация и автоматизация погрузочно-разгрузочных работ, способов транспортирования сырьевых материалов, готовой продукции и отходов производства',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.10',
    label:
      'Очистка оборудования, загрязненного веществами, обладающими остронаправленным механизмом действия, до начала работ по ремонту и обслуживанию такого оборудования',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.11',
    label:
      'Наличие аварийного комплекта СИЗ на складах хранения веществ, обладающих остронаправленным механизмом действия',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.12',
    label:
      'Использование станков и инструмента для механической обработки материалов и изделий, сопровождающихся выделением газов, паров и аэрозолей, совместно с системами удаления указанных веществ',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.13',
    label:
      'Недопущение очистки оборудования, вентиляционных систем, заготовок, готовых изделий, полов и стен от пыли сжатым воздухом без применения СИЗ',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.14',
    label:
      'Удаление воздуха из помещений системами вентиляции способом, исключающим прохождение его через зону дыхания работающих на постоянных рабочих местах',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.15',
    label:
      'Проведение работ с концентрированными кислотами и щелочами в изолированных помещениях с использованием аппаратуры, оборудованной местной вытяжной вентиляцией',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.16',
    label:
      'Использование для работы с веществами, обладающими остронаправленным механизмом действия, герметичного оборудования или систем автоматизированного и/или дистанционного управления процессом',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.17',
    label:
      'Размещение пультов управления технологическими процессами в изолированных помещениях при создании в них избыточного давления',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.18',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.19',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.20',
    label: 'Использование СИЗ',
  },
  {
    dependence:
      'Отравления при вдыхании и попадании на кожу высокоопасных веществ',
    ID: '9.4.21',
    label: 'Герметизация технологического оборудования',
  },
  {
    dependence:
      'Отравление при вдыхании паров вредных жидкостей, газов, пыли, тумана, дыма и твердых веществ',
    ID: '9.5.1',
    label: 'Изменение производственного процесса',
  },
  {
    dependence:
      'Отравление при вдыхании паров вредных жидкостей, газов, пыли, тумана, дыма и твердых веществ',
    ID: '9.5.2',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence:
      'Отравление при вдыхании паров вредных жидкостей, газов, пыли, тумана, дыма и твердых веществ',
    ID: '9.5.3',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence:
      'Отравление при вдыхании паров вредных жидкостей, газов, пыли, тумана, дыма и твердых веществ',
    ID: '9.5.4',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence:
      'Отравление при вдыхании паров вредных жидкостей, газов, пыли, тумана, дыма и твердых веществ',
    ID: '9.5.5',
    label:
      'Применение средств коллективной защиты, направленных на экранирование, изоляцию работника от воздействия факторов, в том числе вентиляции',
  },
  {
    dependence:
      'Отравление при вдыхании паров вредных жидкостей, газов, пыли, тумана, дыма и твердых веществ',
    ID: '9.5.6',
    label:
      'Применение систем аварийной остановки производственных процессов, предотвращающих наступление неблагоприятных последствий',
  },
  {
    dependence:
      'Отравление при вдыхании паров вредных жидкостей, газов, пыли, тумана, дыма и твердых веществ',
    ID: '9.5.7',
    label:
      'Подбор и применение рабочего оборудования с целью снижения влияния факторов производственной среды и трудового процесса',
  },
  {
    dependence:
      'Отравление при вдыхании паров вредных жидкостей, газов, пыли, тумана, дыма и твердых веществ',
    ID: '9.5.8',
    label:
      'Снижение времени неблагоприятного воздействия факторов производственной среды и трудового процесса на работника',
  },
  {
    dependence:
      'Отравление при вдыхании паров вредных жидкостей, газов, пыли, тумана, дыма и твердых веществ',
    ID: '9.5.9',
    label:
      'Механизация и автоматизация погрузочно-разгрузочных работ, способов транспортирования сырьевых материалов, готовой продукции и отходов производства',
  },
  {
    dependence:
      'Отравление при вдыхании паров вредных жидкостей, газов, пыли, тумана, дыма и твердых веществ',
    ID: '9.5.10',
    label:
      'Использование станков и инструмента для механической обработки материалов и изделий, сопровождающихся выделением газов, паров и аэрозолей, совместно с системами удаления указанных веществ',
  },
  {
    dependence:
      'Отравление при вдыхании паров вредных жидкостей, газов, пыли, тумана, дыма и твердых веществ',
    ID: '9.5.11',
    label:
      'Удаление воздуха из помещений системами вентиляции способом, исключающим прохождение его через зону дыхания работающих на постоянных рабочих местах',
  },
  {
    dependence:
      'Отравление при вдыхании паров вредных жидкостей, газов, пыли, тумана, дыма и твердых веществ',
    ID: '9.5.12',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Отравление при вдыхании паров вредных жидкостей, газов, пыли, тумана, дыма и твердых веществ',
    ID: '9.5.13',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence:
      'Отравление при вдыхании паров вредных жидкостей, газов, пыли, тумана, дыма и твердых веществ',
    ID: '9.5.14',
    label: 'Использование средств индивидуальной защиты',
  },
  {
    dependence:
      'Отравление при вдыхании паров вредных жидкостей, газов, пыли, тумана, дыма и твердых веществ',
    ID: '9.5.15',
    label: 'Герметизация технологического оборудования',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.1',
    label: 'Изменение производственного процесса',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.2',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.3',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.4',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.5',
    label:
      'Применение средств коллективной защиты, направленных на экранирование, изоляцию работника от воздействия факторов, в том числе вентиляции',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.6',
    label:
      'Применение систем аварийной остановки производственных процессов, предотвращающих наступление неблагоприятных последствий',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.7',
    label:
      'Подбор и применение рабочего оборудования с целью снижения влияния факторов производственной среды и трудового процесса',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.8',
    label:
      'Снижение времени неблагоприятного воздействия факторов производственной среды и трудового процесса на работника',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.9',
    label:
      'Механизация и автоматизация погрузочно-разгрузочных работ, способов транспортирования сырьевых материалов, готовой продукции и отходов производства',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.10',
    label:
      'Оборудование транспортеров для транспортировки пылящих материалов средствами пылеудаления и (или) пылеподавления',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.11',
    label:
      'Механизация или автоматизация технологических процессов, характеризующихся применением, образованием и выделением пыли, либо внедрение способов подавления пыли в процессе ее образования с применением воды или других средств',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.12',
    label:
      'Недопущение рассева порошковых материалов на открытых сигах, снабжение оборудования укрытиями или аспирационными устройствами, разделение порошковых материалов по фракциям с помощью устройств, обеспеченных укрытием и находящихся под разрежением',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.13',
    label:
      'Осуществление выгрузки сыпучих материалов из мешков, бочек и другой мелкой тары в складских помещениях способом, исключающим попадание пыли в воздух рабочей зоны, или с применением средств защиты органов дыхания',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.14',
    label:
      'Погрузка и разгрузка сыпучих, порошкообразных материалов большими объемами в транспортные средства, вагоны, контейнеры, емкости в местах, площадках, помещениях, оборудованных устройствами для локализации или аспирации пыли',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.15',
    label:
      'Сушка порошковых и пастообразных материалов в закрытых аппаратах непрерывного действия, оборудованных системами вытяжной вентиляции, или системами рециркуляции',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.16',
    label:
      'Недопущение производства пескоструйных работ в закрытых помещениях с применением сухого песка, проведение очистки изделий дробью, металлическим песком и песком с водой в герметичном оборудовании с дистанционным управлением или с использованием изолирующего костюма',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.17',
    label:
      'Недопущение очистки оборудования, вентиляционных систем, заготовок, готовых изделий, полов и стен от пыли сжатым воздухом без применения СИЗ',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.18',
    label:
      'Удаление воздуха из помещений системами вентиляции способом, исключающим прохождение его через зону дыхания работающих на постоянных рабочих местах',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.19',
    label:
      'Оснащение промышленного оборудования, характеризующегося выделением пыли, эксплуатация которого приводит к превышению гигиенических нормативов в воздухе рабочей зоны с постоянными рабочими местами, устройствами местной вытяжной вентиляции',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.20',
    label:
      'Размещение пультов управления технологическими процессами в изолированных помещениях при создании в них избыточного давления',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.21',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.22',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.23',
    label: 'Рациональное чередование режимов труда и отдыха',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.24',
    label: 'Использование СИЗ',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.25',
    label:
      'Регулярное техническое обслуживание и ремонт технологического оборудования, инструмента и приспособлений',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.26',
    label:
      'Оборудование технологических линий электрическими блокировками, обеспечивающими, в первую очередь, пуск аспирационных систем и газопылеулавливающих установок, а затем технологического оборудования',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.27',
    label:
      'Оборудование укрытиями узлов перегрузки исходных материалов, полуфабрикатов и готовой продукции подсоединенными к аспирационным системам с аппаратами для очистки воздуха',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.28',
    label:
      'Механизация и автоматизация, применение дистанционного управления операциями и производственными процессами',
  },
  {
    dependence:
      'Заболевания кожи (дерматиты) при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.6.29',
    label: 'Герметизация технологического оборудования',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.1',
    label: 'Изменение производственного процесса',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.2',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.3',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.4',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.5',
    label:
      'Применение средств коллективной защиты, направленных на экранирование, изоляцию работника от воздействия факторов, в том числе вентиляции',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.6',
    label:
      'Применение систем аварийной остановки производственных процессов, предотвращающих наступление неблагоприятных последствий',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.7',
    label:
      'Подбор и применение рабочего оборудования с целью снижения влияния факторов производственной среды и трудового процесса',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.8',
    label:
      'Снижение времени неблагоприятного воздействия факторов производственной среды и трудового процесса на работника',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.9',
    label:
      'Механизация и автоматизация погрузочно-разгрузочных работ, способов транспортирования сырьевых материалов, готовой продукции и отходов производства',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.10',
    label:
      'Оборудование транспортеров для транспортировки пылящих материалов средствами пылеудаления и (или) пылеподавления',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.11',
    label:
      'Механизация или автоматизация технологических процессов, характеризующихся применением, образованием и выделением пыли, либо внедрение способов подавления пыли в процессе ее образования с применением воды или других средств',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.12',
    label:
      'Недопущение рассева порошковых материалов на открытых ситах, снабжение оборудования укрытиями или аспирационными устройствами, разделение порошковых материалов по фракциям с помощью устройств, обеспеченных укрытием и находящихся под разрежением',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.13',
    label:
      'Выгрузка сыпучих материалов из мешков, бочек и другой мелкой тары в складских помещениях способом, исключающим попадание пыли в воздух рабочей зоны, или с применением средств защиты органов дыхания',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.14',
    label:
      'Погрузка и разгрузка сыпучих, порошкообразных материалов большими объемами в транспортные средства, вагоны, контейнеры, емкости в местах, площадках, помещениях, оборудованных устройствами для локализации или аспирации пыли',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.15',
    label:
      'Сушка порошковых и пастообразных материалов в закрытых аппаратах непрерывного действия, оборудованных системами вытяжной вентиляции, или системами рециркуляции',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.16',
    label:
      'Недопущение производства пескоструйных работ в закрытых помещениях с применением сухого песка, проведение очистки изделий дробью, металлическим песком и песком с водой в герметичном оборудовании с дистанционным управлением или с использованием изолирующего костюма',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.17',
    label:
      'Недопущение очистки оборудования, вентиляционных систем, заготовок, готовых изделий, полов и стен от пыли сжатым воздухом без применения СИЗ',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.18',
    label:
      'Удаление воздуха из помещений системами вентиляции способом, исключающим прохождение его через зону дыхания работающих на постоянных рабочих местах.',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.19',
    label:
      'Оснащение промышленного оборудования, характеризующегося выделением пыли, эксплуатация которого приводит к превышению гигиенических нормативов в воздухе рабочей зоны с постоянными рабочими местами, устройствами местной вытяжной вентиляции',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.20',
    label:
      'Размещение пультов управления технологическими процессами в изолированных помещениях при создании в них избыточного давления',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.21',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.22',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.23',
    label: 'Рациональное чередование режимов труда и отдыха',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.24',
    label: 'Использование средств индивидуальной защиты',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.25',
    label:
      'Регулярное техническое обслуживание и ремонт технологического оборудования, инструмента и приспособлений',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.26',
    label:
      'Оборудование технологических линий электрическими блокировками, обеспечивающими, в первую очередь, пуск аспирационных систем и газопылеулавливающих установок, а затем технологического оборудования',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.27',
    label:
      'Оборудование укрытиями узлов перегрузки исходных материалов, полуфабрикатов и готовой продукции, подсоединенными к аспирационным системам с аппаратами для очистки воздуха',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.28',
    label:
      'Механизация и автоматизация, применение дистанционного управления операциями и производственными процессами',
  },
  {
    dependence:
      'Травма оболочек и роговицы глаза при воздействии химических веществ, не указанных в пунктах 9.2 - 9.6',
    ID: '9.7.29',
    label: 'Герметизация технологического оборудования',
  },
  {
    dependence: 'Травмы, ожоги вследствие пожара или взрыва',
    ID: '10.1.1',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence: 'Травмы, ожоги вследствие пожара или взрыва',
    ID: '10.1.2',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence: 'Травмы, ожоги вследствие пожара или взрыва',
    ID: '10.1.3',
    label: 'Изменение производственного процесса',
  },
  {
    dependence: 'Травмы, ожоги вследствие пожара или взрыва',
    ID: '10.1.4',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence: 'Травмы, ожоги вследствие пожара или взрыва',
    ID: '10.1.5',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Травмы, ожоги вследствие пожара или взрыва',
    ID: '10.1.6',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence: 'Травмы, ожоги вследствие пожара или взрыва',
    ID: '10.1.7',
    label:
      'Применение систем аварийной остановки производственных процессов, предотвращающих наступление неблагоприятных последствий',
  },
  {
    dependence: 'Травмы, ожоги вследствие пожара или взрыва',
    ID: '10.1.8',
    label: 'Устройство кабин наблюдения и дистанционного управления',
  },
  {
    dependence: 'Травмы, ожоги вследствие пожара или взрыва',
    ID: '10.1.9',
    label:
      'Механизация и автоматизация, применение дистанционного управления операциями и производственными процессами',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в замкнутых технологических емкостях',
    ID: '11.1.1',
    label:
      'Назначение лиц, ответственных за организацию и безопасное проведение работ',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в замкнутых технологических емкостях',
    ID: '11.1.2',
    label: 'Периодический осмотр средств коллективной и индивидуальной защиты',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в замкнутых технологических емкостях',
    ID: '11.1.3',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в замкнутых технологических емкостях',
    ID: '11.1.4',
    label:
      'Блокировка (тепловая, электрическая, механическая) оборудования и процессов (в том числе блокировка для обеспечения защиты от проникновения технологических и хозяйственно-бытовых жидкостей, стоков и газов в рабочую зону) в соответствующем ограниченном и (или) замкнутом пространстве',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в замкнутых технологических емкостях',
    ID: '11.1.5',
    label:
      'Исключение опасной работы (процедуры) в ограниченном и (или) замкнутом пространстве и (или) сокращение времени ее выполнения',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в замкнутых технологических емкостях',
    ID: '11.1.6',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в замкнутых технологических емкостях',
    ID: '11.1.7',
    label:
      'Дублирование средств измерения параметров рабочей среды или индикаторов (средств сигнализации)',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в замкнутых технологических емкостях',
    ID: '11.1.8',
    label: 'Дублирование средств связи',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в замкнутых технологических емкостях',
    ID: '11.1.9',
    label: 'Использование коллективных средств защиты, в том числе вентиляции',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в замкнутых технологических емкостях',
    ID: '11.1.10',
    label: 'Использование средств индивидуальной защиты',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в замкнутых технологических емкостях',
    ID: '11.1.11',
    label:
      'Организация выдачи исправных средств измерений (сигнализации), средств связи, средств индивидуальной защиты в соответствии с указаниями эксплуатационной документации изготовителя, а также обеспечение своевременности их обслуживания, периодической проверки, браковки',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в замкнутых технологических емкостях',
    ID: '11.1.12',
    label:
      'Проведение очистки ограниченных и (или) замкнутых пространств от вредных веществ до входа работников',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в замкнутых технологических емкостях',
    ID: '11.1.13',
    label: 'Измерения параметров среды',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в замкнутых технологических емкостях',
    ID: '11.1.14',
    label:
      'Использование средств измерений и сигнализации о недостатке кислорода и (или) загазованности воздуха',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.1',
    label:
      'Назначение лиц, ответственных за организацию и безопасное проведение работ',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.2',
    label: 'Периодический осмотр средств коллективной и индивидуальной защиты',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.3',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.4',
    label:
      'Блокировка (тепловая, электрическая, механическая) оборудования и процессов (в том числе блокировка для обеспечения защиты от проникновения технологических и хозяйственно-бытовых жидкостей, стоков и газов в рабочую зону) в соответствующем ограниченном и (или) замкнутом пространстве',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.5',
    label:
      'Исключение опасной работы (процедуры) в ограниченном и (или) замкнутом пространстве и (или) сокращение времени ее выполнения',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.6',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.7',
    label:
      'Дублирование средств измерения параметров рабочей среды или индикаторов (средств сигнализации)',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.8',
    label: 'Дублирование средств связи',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.9',
    label: 'Использование коллективных средств защиты, в том числе вентиляции',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.10',
    label: 'Использование средств индивидуальной защиты',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.11',
    label:
      'Организация выдачи исправных средств измерений (сигнализации), средств связи, средств индивидуальной защиты в соответствии с указаниями эксплуатационной документации изготовителя, а также обеспечение своевременности их обслуживания, периодической проверки, браковки',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.12',
    label:
      'Проведение очистки ограниченных и (или) замкнутых пространств от вредных веществ до входа работников',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.13',
    label: 'Измерение параметров среды',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.14',
    label:
      'Использование средств измерений и сигнализации о недостатке кислорода и (или) загазованности воздуха',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.15',
    label:
      'Механизация и автоматизация, применение дистанционного управления операциями и производственными процессами',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.16',
    label: 'Устройство кабин наблюдения и дистанционного управления',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.17',
    label:
      'Применение систем аварийной остановки производственных процессов, предотвращающих наступление неблагоприятных последствий',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.18',
    label:
      'Установка средства контроля за организацией технологического процесса, в том числе дистанционные и автоматические',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за вытеснения его другими газами или жидкостями',
    ID: '11.2.19',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в подземных сооружениях',
    ID: '11.3.1',
    label:
      'Назначение лиц, ответственных за организацию и безопасное проведение работ',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в подземных сооружениях',
    ID: '11.3.2',
    label: 'Периодический осмотр средств коллективной и индивидуальной защиты',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в подземных сооружениях',
    ID: '11.3.3',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в подземных сооружениях',
    ID: '11.3.4',
    label:
      'Блокировка (тепловая, электрическая, механическая) оборудования и процессов (в том числе блокировка для обеспечения защиты от проникновения технологических и хозяйственно-бытовых жидкостей, стоков и газов в рабочую зону) в соответствующем ограниченном и (или) замкнутом пространстве',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в подземных сооружениях',
    ID: '11.3.5',
    label:
      'Исключение опасной работы (процедуры) в ограниченном и (или) замкнутом пространстве и (или) сокращение времени ее выполнения',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в подземных сооружениях',
    ID: '11.3.6',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в подземных сооружениях',
    ID: '11.3.7',
    label:
      'Дублирование средств измерения параметров рабочей среды или индикаторов (средств сигнализации)',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в подземных сооружениях',
    ID: '11.3.8',
    label: 'Дублирование средств связи',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в подземных сооружениях',
    ID: '11.3.9',
    label: 'Использование коллективных средств защиты, в том числе вентиляции',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в подземных сооружениях',
    ID: '11.3.10',
    label: 'Использование средств индивидуальной защиты',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в безвоздушных средах',
    ID: '11.4.1',
    label:
      'Назначение лиц, ответственных за организацию и безопасное проведение работ',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в безвоздушных средах',
    ID: '11.4.2',
    label: 'Периодический осмотр средств коллективной и индивидуальной защиты',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в безвоздушных средах',
    ID: '11.4.3',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в безвоздушных средах',
    ID: '11.4.4',
    label:
      'Блокировка (тепловая, электрическая, механическая) оборудования и процессов (в том числе блокировка для обеспечения защиты от проникновения технологических и хозяйственно-бытовых жидкостей, стоков и газов в рабочую зону) в соответствующем ограниченном и (или) замкнутом пространстве',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в безвоздушных средах',
    ID: '11.4.5',
    label:
      'Исключение опасной работы (процедуры) в ограниченном и (или) замкнутом пространстве и (или) сокращение времени ее выполнения',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в безвоздушных средах',
    ID: '11.4.6',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в безвоздушных средах',
    ID: '11.4.7',
    label:
      'Дублирование средств измерения параметров рабочей среды или индикаторов (средств сигнализации)',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в безвоздушных средах',
    ID: '11.4.8',
    label: 'Дублирование средств связи',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в безвоздушных средах',
    ID: '11.4.9',
    label: 'Использование коллективных средств защиты, в том числе вентиляции',
  },
  {
    dependence:
      'Развитие гипоксии или удушья из-за недостатка кислорода в безвоздушных средах',
    ID: '11.4.10',
    label: 'Использование средств индивидуальной защиты',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.1',
    label: 'Изменение производственного процесса',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.2',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.3',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.4',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.5',
    label:
      'Применение средств коллективной защиты, направленных на экранирование, изоляцию работника от воздействия факторов, в том числе вентиляции',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.6',
    label:
      'Применение систем аварийной остановки производственных процессов, предотвращающих наступление неблагоприятных последствий',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.7',
    label:
      'Подбор и применение рабочего оборудования с целью снижения влияния факторов производственной среды и трудового процесса',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.8',
    label:
      'Снижение времени неблагоприятного воздействия факторов производственной среды и трудового процесса на работника',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.9',
    label:
      'Механизация и автоматизация погрузочно-разгрузочных работ, способов транспортирования сырьевых материалов, готовой продукции и отходов производства',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.10',
    label:
      'Оборудование транспортеров для транспортировки пылящих материалов средствами пылеудаления и (или) пылеподавления',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.11',
    label:
      'Механизация или автоматизация технологических процессов, характеризующихся применением, образованием и выделением пыли, либо внедрение способов подавления пыли в процессе ее образования с применением воды или других средств',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.12',
    label:
      'Недопущение рассева порошковых материалов на открытых ситах, снабжение оборудования укрытиями или аспирационными устройствами, разделение порошковых материалов по фракциям с помощью устройств, обеспеченных укрытием и находящихся под разрежением',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.13',
    label:
      'Выгрузка сыпучих материалов из мешков, бочек и другой мелкой тары в складских помещениях способом, исключающим попадание пыли в воздух рабочей зоны, или с применением средств защиты органов дыхания',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.14',
    label:
      'Погрузка и разгрузка сыпучих, порошкообразных материалов большими объемами в транспортные средства, вагоны, контейнеры, емкости в местах, площадках, помещениях, оборудованных устройствами для локализации или аспирации пыли',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.15',
    label:
      'Сушка порошковых и пастообразных материалов в закрытых аппаратах непрерывного действия, оборудованных системами вытяжной вентиляции, или системами рециркуляции',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.16',
    label:
      'Недопущение производства пескоструйных работ в закрытых помещениях с применением сухого песка, проведение очистки изделий дробью, металлическим песком и песком с водой в герметичном оборудовании с дистанционным управлением или с использованием изолирующего костюма',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.17',
    label:
      'Недопущение очистки оборудования, вентиляционных систем, заготовок, готовых изделий, полов и стен от пыли сжатым воздухом без применения СИЗ',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.18',
    label:
      'Удаление воздуха из помещений системами вентиляции способом, исключающим прохождение его через зону дыхания работающих на постоянных рабочих местах',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.19',
    label:
      'Оснащение промышленного оборудования, характеризующегося выделением пыли, эксплуатация которого приводит к превышению гигиенических нормативов в воздухе рабочей зоны с постоянными рабочими местами, устройствами местной вытяжной вентиляции',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.20',
    label:
      'Размещение пультов управления технологическими процессами в изолированных помещениях при создании в них избыточного давления',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.21',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.22',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.23',
    label: 'Рациональное чередование режимов труда и отдыха',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.24',
    label: 'Использование средств индивидуальной защиты',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.25',
    label:
      'Регулярное техническое обслуживание и ремонт технологического оборудования, инструмента и приспособлений',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.26',
    label:
      'Оборудование технологических линий электрическими блокировками, обеспечивающими, в первую очередь, пуск аспирационных систем и газопылеулавливающих установок, а затем технологического оборудования',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.27',
    label:
      'Оборудование укрытиями узлов перегрузки исходных материалов, полуфабрикатов и готовой продукции, подсоединенными к аспирационным системам с аппаратами для очистки воздуха',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.28',
    label:
      'Механизация и автоматизация, применение дистанционного управления операциями и производственными процессами',
  },
  {
    dependence: 'Повреждение органов дыхания частицами пыли',
    ID: '12.1.29',
    label: 'Герметизация технологического оборудования',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.1',
    label: 'Изменение производственного процесса',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.2',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.3',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.4',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.5',
    label:
      'Применение средств коллективной защиты. направленных на экранирование, изоляцию работника от воздействия факторов, в том числе вентиляции',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.6',
    label:
      'Применение систем аварийной остановки производственных процессов, предотвращающих наступление неблагоприятных последствий',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.7',
    label:
      'Подбор и применение рабочего оборудования с целью снижения влияния факторов производственной среды и трудового процесса',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.8',
    label:
      'Снижение времени неблагоприятного воздействия факторов производственной среды и трудового процесса на работника',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.9',
    label:
      'Механизация и автоматизация погрузочно-разгрузочных работ, способов транспортирования сырьевых материалов, готовой продукции и отходов производства',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.10',
    label:
      'Оборудование транспортеров для транспортировки пылящих материалов средствами пылеудаления и (или) пылеподавления',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.11',
    label:
      'Механизация или автоматизация технологических процессов, характеризующихся применением, образованием и выделением пыли, либо реализация способов подавления пыли в процессе ее образования с применением воды или других средств',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.12',
    label:
      'Недопущение рассева порошковых материалов на открытых ситах, снабжение оборудования укрытиями или аспирационными устройствами, разделение порошковых материалов по фракциям с помощью устройств, обеспеченных укрытием и находящихся под разрежением',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.13',
    label:
      'Выгрузка сыпучих материалов из мешков, бочек и другой мелкой тары в складских помещениях способом, исключающим попадание пыли в воздух рабочей зоны, или с применением средств защиты органов дыхания',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.14',
    label:
      'Погрузка и разгрузка сыпучих, порошкообразных материалов большими объемами в транспортные средства, вагоны, контейнеры, емкости в местах, площадках, помещениях, оборудованных устройствами для локализации или аспирации пыли',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.15',
    label:
      'Сушка порошковых и пастообразных материалов в закрытых аппаратах непрерывного действия, оборудованных системами вытяжной вентиляции или системами рециркуляции',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.16',
    label:
      'Недопущение производства пескоструйных работ в закрытых помещениях с применением сухого песка, проведение очистки изделий дробью, металлическим песком и песком с водой в герметичном оборудовании с дистанционным управлением или с использованием изолирующего костюма',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.17',
    label:
      'Недопущение очистки оборудования, вентиляционных систем, заготовок, готовых изделий, полов и стен от пыли сжатым воздухом без применения СИЗ',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.18',
    label:
      'Удаление воздуха из помещений системами вентиляции способом, исключающим прохождение его через зону дыхания работающих на постоянных рабочих местах',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.19',
    label:
      'Оснащение промышленного оборудования, характеризующегося выделением пыли, эксплуатация которого приводит к превышению гигиенических нормативов в воздухе рабочей зоны с постоянными рабочими местами, устройствами местной вытяжной вентиляции',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.20',
    label:
      'Размещение пультов управления технологическими процессами в изолированных помещениях при создании в них избыточного давления',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.21',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.22',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.23',
    label: 'Рациональное чередование режимов труда и отдыха',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.24',
    label: 'Использование средств индивидуальной защиты',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.25',
    label:
      'Регулярное техническое обслуживание и ремонт технологического оборудования, инструмента и приспособлений',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.26',
    label:
      'Оборудование технологических линий электрическими - блокировками, обеспечивающими, в первую очередь, пуск аспирационных систем и газопылеулавливающих установок, а затем технологического оборудования',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.27',
    label:
      'Оборудование укрытиями узлов перегрузки исходных материалов, полуфабрикатов и готовой продукции, подсоединенными к аспирационным системам с аппаратами для очистки воздуха',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.28',
    label:
      'Механизация и автоматизация, применение дистанционного управления операциями и производственными процессами',
  },
  {
    dependence:
      'Повреждение глаз и кожных покровов вследствие воздействия пыли',
    ID: '12.2.29',
    label: 'Герметизация технологического оборудования',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.1',
    label: 'Изменение производственного процесса',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.2',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.3',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.4',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.5',
    label:
      'Применение средств коллективной защиты, направленных на экранирование, изоляцию работника от воздействия факторов, в том числе вентиляции',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.6',
    label:
      'Применение систем аварийной остановки производственных процессов, предотвращающих наступление неблагоприятных последствий',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.7',
    label:
      'Подбор и применение рабочего оборудования с целью снижения влияния факторов производственной среды и трудового процесса',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.8',
    label:
      'Снижение времени неблагоприятного воздействия факторов производственной среды и трудового процесса на работника',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.9',
    label:
      'Механизация и автоматизация погрузочно-разгрузочных работ, способов транспортирования сырьевых материалов, готовой продукции и отходов производства',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.10',
    label:
      'Использование станков и инструмента для механической обработки материалов и изделий, сопровождающихся вы делением газов, паров и аэрозолей, совместно с системами удаления указанных веществ',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.11',
    label:
      'Удаление воздуха из помещений системами вентиляции способом, исключающим прохождение его через зону дыхания работающих на постоянных рабочих местах.',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.12',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.13',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.14',
    label: 'Рациональное чередование режимов труда и отдыха',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.15',
    label: 'Использование средств индивидуальной защиты',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.16',
    label:
      'Регулярное техническое обслуживание и ремонт технологического оборудования, инструмента и приспособлений',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.17',
    label:
      'Оборудование технологических линий электрическими блокировками, обеспечивающими, в первую очередь, пуск аспирационных систем и газопылеулавливающих установок, а затем технологического оборудования',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.18',
    label:
      'Оборудование укрытиями узлов перегрузки исходных материалов, полуфабрикатов и готовой продукции, подсоединенными к аспирационным системам с аппаратами для очистки воздуха',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.19',
    label:
      'Механизация и автоматизация, применение дистанционного управления операциями и производственными процессами',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей вредных химических веществ',
    ID: '12.3.20',
    label: 'Герметизация технологического оборудования',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.1',
    label: 'Изменение производственного процесса',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.2',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.3',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.4',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.5',
    label:
      'Применение средств коллективной защиты, направленных на экранирование, изоляцию работника от воздействия факторов, в том числе вентиляции',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.6',
    label:
      'Применение систем аварийной остановки производственных процессов, предотвращающих наступление неблагоприятных последствий',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.7',
    label:
      'Подбор и применение рабочего оборудования с целью снижения влияния факторов производственной среды и трудового процесса',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.8',
    label:
      'Снижение времени неблагоприятного воздействия факторов производственной среды и трудового процесса на работника',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.9',
    label:
      'Механизация и автоматизация погрузочно-разгрузочных работ, способов транспортирования сырьевых материалов, готовой продукции и отходов производства',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.10',
    label:
      'Использование станков и инструмента для механической обработки материалов и изделий, сопровождающихся выделением газов, паров и аэрозолей, совместно с системами удаления указанных веществ',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.11',
    label:
      'Удаление воздуха из помещений системами вентиляции способом, исключающим прохождение его через зону дыхания работающих на постоянных рабочих местах',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.12',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.13',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.14',
    label: 'Рациональное чередование режимов труда и отдыха',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.15',
    label: 'Использование средств индивидуальной защиты',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.16',
    label:
      'Регулярное техническое обслуживание и ремонт технологического оборудования, инструмента и приспособлений',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.17',
    label:
      'Оборудование технологических линий электрическими блокировками, обеспечивающими, в первую очередь, пуск аспирационных систем и газопылеулавливающих установок, а затем технологического оборудования',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.18',
    label:
      'Оборудование укрытиями узлов перегрузки исходных материалов, полуфабрикатов и готовой продукции, подсоединенными к аспирационным системам с аппаратами для очистки воздуха',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.19',
    label:
      'Механизация и автоматизация, применение дистанционного управления операциями и производственными процессами',
  },
  {
    dependence:
      'Повреждение органов дыхания вследствие воздействия воздушных взвесей, содержащих смазочные масла',
    ID: '12.4.20',
    label: 'Герметизация технологического оборудования',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.1',
    label: 'Изменение производственного процесса',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.2',
    label:
      'Отказ от операции, характеризующейся наличием вредных и опасных производственных факторов',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.3',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.4',
    label:
      'Установка средств контроля за организацией технологического процесса, в том числе дистанционных и автоматических',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.5',
    label:
      'Применение средств коллективной защиты, направленных на экранирование, изоляцию работника от воздействия факторов, в том числе вентиляция',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.6',
    label:
      'Применение систем аварийной остановки производственных процессов, предотвращающих наступление неблагоприятных последствий',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.7',
    label:
      'Подбор и применение рабочего оборудования с целью снижения влияния факторов производственной среды и трудового процесса',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.8',
    label:
      'Снижение времени неблагоприятного воздействия факторов производственной среды и трудового процесса на работника',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.9',
    label:
      'Механизация и автоматизация погрузочно-разгрузочных работ, способов транспортирования сырьевых материалов, готовой продукции и отходов производства',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.10',
    label:
      'Использование станков и инструмента для механической обработки материалов и изделий, сопровождающихся выделением газов, паров и аэрозолей, совместно с системами удаления указанных веществ',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.11',
    label:
      'Удаление воздуха из помещений системами вентиляции способом, исключающим прохождение его через зону дыхания работающих на постоянных рабочих местах',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.12',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.13',
    label: 'Замена опасной работы (процедуры) менее опасной',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.14',
    label: 'Рациональное чередование режимов труда и отдыха',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.15',
    label: 'Использование средств индивидуальной защиты',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.16',
    label:
      'Регулярное техническое обслуживание и ремонт технологического оборудования, инструмента и приспособлений',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.17',
    label:
      'Оборудование технологических линий электрическими блокировками, обеспечивающими, в первую очередь, пуск аспирационных систем и газопылеулавливающих установок, а затем технологического оборудования',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.18',
    label:
      'Оборудование укрытиями узлов перегрузки исходных материалов, полуфабрикатов и готовой продукции, подсоединенными к аспирационным системам с аппаратами для очистки воздуха',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.19',
    label:
      'Механизация и автоматизация, применение дистанционного управления операциями и производственными процессами',
  },
  {
    dependence:
      'Воздействие на органы дыхания воздушных взвесей, содержащих чистящие и обезжиривающие вещества',
    ID: '12.5.20',
    label: 'Герметизация технологического оборудования',
  },
  {
    dependence:
      'Ожог при контакте незащищенных частей тела с поверхностью предметов, имеющих высокую температуру',
    ID: '13.1.1',
    label:
      'Применение закрытых систем (ограждений) для горячих сред, установка изоляции, разделяющих защитных устройств, уменьшение площади контакта',
  },
  {
    dependence:
      'Ожог при контакте незащищенных частей тела с поверхностью предметов, имеющих высокую температуру',
    ID: '13.1.2',
    label:
      'Организация обучения, инструктажей, стажировки, проверки знаний, установка предупреждающих знаков, визуальных и звуковых предупреждающих сигналов, утверждение правил поведения на рабочих местах',
  },
  {
    dependence:
      'Ожог при контакте незащищенных частей тела с поверхностью предметов, имеющих высокую температуру',
    ID: '13.1.3',
    label: 'Правильное применение СИЗ',
  },
  {
    dependence:
      'Ожог от воздействия на незащищенные участки тела материалов, жидкостей или газов, имеющих высокую температуру',
    ID: '13.2.1',
    label:
      'Применение закрытых систем (ограждений) для горячих сред, установка изоляции, разделяющих защитных устройств, уменьшение площади контакта',
  },
  {
    dependence:
      'Ожог от воздействия на незащищенные участки тела материалов, жидкостей или газов, имеющих высокую температуру',
    ID: '13.2.2',
    label:
      'Организация обучения, инструктажей, стажировки, проверки знаний, установка предупреждающих знаков, визуальных и звуковых предупреждающих сигналов, утверждение правил поведения на рабочих местах',
  },
  {
    dependence:
      'Ожог от воздействия на незащищенные участки тела материалов, жидкостей или газов, имеющих высокую температуру',
    ID: '13.2.3',
    label: 'Правильное применение СИЗ',
  },
  {
    dependence:
      'Тепловой удар при длительном нахождении в помещении с высокой температурой воздуха',
    ID: '13.3.1',
    label:
      'Организация обучения, инструктажей, стажировки, проверки знаний, установка предупреждающих знаков, визуальных и звуковых предупреждающих сигналов, утверждение правил поведения на рабочих местах',
  },
  {
    dependence:
      'Тепловой удар при длительном нахождении в помещении с высокой температурой воздуха',
    ID: '13.3.2',
    label:
      'Правильное применение СИЗ, прекращение выполнения работ при повышении температуры воздуха.',
  },
  {
    dependence:
      'Тепловой удар при длительном нахождении вблизи открытого пламени',
    ID: '13.4.1',
    label:
      'Организация обучения, инструктажей, стажировки, проверки знаний, установка предупреждающих знаков, визуальных и звуковых предупреждающих сигналов, утверждение правил поведения на рабочих местах',
  },
  {
    dependence:
      'Тепловой удар при длительном нахождении вблизи открытого пламени',
    ID: '13.4.2',
    label:
      'Правильное применение СИЗ, прекращение выполнения работ при не обусловленном производственным процессом появлении открытого пламени',
  },
  {
    dependence:
      'Ожог кожных покровов и слизистых оболочек вследствие воздействия открытого пламени',
    ID: '13.5.1',
    label:
      'Организация обучения, инструктажей, стажировки, проверки знаний, установка предупреждающих знаков, визуальных и звуковых предупреждающих сигналов, утверждение правил поведения на рабочих местах',
  },
  {
    dependence:
      'Ожог кожных покровов и слизистых оболочек вследствие воздействия открытого пламени',
    ID: '13.5.2',
    label:
      'Правильное применение СИЗ, прекращение выполнения работ при не обусловленном производственным процессом появлении открытого пламени',
  },
  {
    dependence:
      'Ожог кожных покровов и слизистых оболочек вследствие воздействия открытого пламени',
    ID: '13.5.3',
    label: 'Прекращение выполнения работ при появлении открытого пламени.',
  },
  {
    dependence: 'Ожог роговицы глаза',
    ID: '13.6.1',
    label:
      'Применение закрытых систем (ограждений) для горячих сред, установка изоляции, разделяющих защитных устройств, уменьшение площади контакта',
  },
  {
    dependence: 'Ожог роговицы глаза',
    ID: "13.6.2 '",
    label:
      'Организация обучения, инструктажей, стажировки, проверки знаний, установка предупреждающих знаков, визуальных и звуковых предупреждающих сигналов, утверждение правил поведения на рабочих местах',
  },
  {
    dependence: 'Ожог роговицы глаза',
    ID: '13.6.3',
    label: 'Правильное применение СИЗ',
  },
  {
    dependence:
      'Ожог вследствие воздействия на незащищенные участки тела материалов, жидкостей или газов, имеющих высокую температуру',
    ID: '13.7.1',
    label:
      'Применение закрытых систем (ограждений) для холодных сред, установка изоляции, разделяющих защитных устройств, уменьшение площади контакта',
  },
  {
    dependence:
      'Ожог вследствие воздействия на незащищенные участки тела материалов, жидкостей или газов, имеющих высокую температуру',
    ID: '13.7.2',
    label:
      'Организация обучения, инструктажей, стажировки, проверки знаний, установка предупреждающих знаков, визуальных и звуковых предупреждающих сигналов, утверждение правил поведения на рабочих местах',
  },
  {
    dependence:
      'Ожог вследствие воздействия на незащищенные участки тела материалов, жидкостей или газов, имеющих высокую температуру',
    ID: '13.7.3',
    label: 'Правильное применение СИЗ',
  },
  {
    dependence:
      'Тепловой удар от воздействия окружающих поверхностей оборудования, имеющих высокую температуру',
    ID: '13.8.1',
    label:
      'Применение закрытых систем (ограждений) для холодных сред, установка изоляции, разделяющих защитных устройств, уменьшение площади контакта',
  },
  {
    dependence:
      'Тепловой удар от воздействия окружающих поверхностей оборудования, имеющих высокую температуру',
    ID: '13.8.2',
    label:
      'Организация обучения, инструктажей, стажировки, проверки знаний, установка предупреждающих знаков, визуальных и звуковых предупреждающих сигналов, утверждение правил поведения на рабочих местах',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.8.3',
    label: 'Правильное применение СИЗ',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.9.1',
    label:
      'Охлаждение нагретых материалов, изделий и передвижного оборудования непосредственно в рабочих помещениях на специальном участке, оборудованном устройством для местного удаления выделяемого тепла и защиты работающих от теплового облучения',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.9.2',
    label:
      'Автоматизация или обеспечение устройствами дистанционного наблюдения производственных процессов и отдельных операций, сопровождающихся образованием и выделением конвекционного и лучистого тепла свыше установленных гигиеническими нормативами значений, или обеспечены СИЗ работников, занятых на данных производственных процессах',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.9.3',
    label:
      'Организация воздушного душирования в случае невозможности применения местных укрытий и отсосов на постоянных рабочих местах у источников тепла, создающих уровни теплового излучения и температуры воздуха выше действующих гигиенических нормативов',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.9.4',
    label: 'Теплоизоляция горячих поверхностей.',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.9.5',
    label: 'Экранирование тепловых излучений.',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.9.6',
    label: 'Рациональное чередование режимов труда и отдыха',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.9.7',
    label: 'Применение вентиляции',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.9.8',
    label: 'Кондиционирование воздуха',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.9.9',
    label: 'Рациональное размещение оборудования',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.9.10',
    label: 'Работа с дистанционным управлением и наблюдением',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.9.11',
    label: 'Внедрение рациональных технологических процессов и оборудования',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.9.12',
    label:
      'Использование рациональной тепловой изоляции оборудования различными видами теплоизоляционных материалов',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.9.13',
    label: 'Устройство защиты работающих различными видами экранов',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.9.14',
    label:
      'Устройство рациональной вентиляции и отопления, лучистого обогрева постоянных рабочих мест и отдельных участков',
  },
  {
    dependence:
      'Ожог кожных покровов работника вследствие контакта с поверхностью имеющую высокую температуру',
    ID: '13.9.15',
    label:
      'Использование СИЗ: спецодежды, спецобуви, средств защиты рук и головных уборов.',
  },
  {
    dependence:
      'Тепловой удар при длительном нахождении на открытом воздухе при прямом воздействии лучей солнца на незащищенную поверхность головы',
    ID: '13.10.1',
    label:
      'Организация обучения, инструктажей, стажировки, проверки знаний, установка предупреждающих знаков, визуальных и звуковых предупреждающих сигналов, утверждение правил поведения на рабочих местах',
  },
  {
    dependence:
      'Тепловой удар при длительном нахождении на открытом воздухе при прямом воздействии лучей солнца на незащищенную поверхность головы',
    ID: '13.10.2',
    label:
      'Правильное применение СИЗ, прекращение выполнения работ при воздействии лучей солнца',
  },
  {
    dependence:
      'Заболевания вследствие переохлаждения организма, обморожение мягких тканей из-за контакта с поверхностью, имеющую низкую температуру, с охлажденной жидкостью или газом',
    ID: '14.1.1',
    label:
      'Ограждение участков технологического оборудования с использованием хладагентов, покрытие теплоизолирующим материалом металлических поверхностей ручных инструментов, металлических ручек и задвижек технологического оборудования с использованием хладагентов',
  },
  {
    dependence:
      'Заболевания вследствие переохлаждения организма, обморожение мягких тканей из-за контакта с поверхностью, имеющую низкую температуру, с охлажденной жидкостью или газом',
    ID: '14.1.2',
    label:
      'Использование СИЗ: спецодежды, спецобуви, средств защиты рук и головных уборов.',
  },
  {
    dependence:
      'Заболевания вследствие переохлаждения организма, обморожение мягких тканей из-за контакта с поверхностью, имеющую низкую температуру, с охлажденной жидкостью или газом',
    ID: '14.1.3',
    label: 'Рациональное чередование режимов труда и отдыха',
  },
  {
    dependence:
      'Заболевания вследствие переохлаждения организма, обморожение мягких тканей из-за контакта с поверхностью, имеющую низкую температуру, с охлажденной жидкостью или газом',
    ID: '14.1.4',
    label: 'Рациональное размещение оборудования',
  },
  {
    dependence:
      'Заболевания вследствие переохлаждения организма, обморожение мягких тканей из-за контакта с поверхностью, имеющую низкую температуру, с охлажденной жидкостью или газом',
    ID: '14.1.5',
    label: 'Работа с дистанционным управлением и наблюдением',
  },
  {
    dependence:
      'Заболевания вследствие переохлаждения организма, обморожение мягких тканей из-за контакта с поверхностью, имеющую низкую температуру, с охлажденной жидкостью или газом',
    ID: '14.1.6',
    label: 'Внедрение рациональных технологических процессов и оборудования',
  },
  {
    dependence:
      'Заболевания вследствие переохлаждения организма, обморожение мягких тканей из-за контакта с поверхностью, имеющую низкую температуру, с охлажденной жидкостью или газом',
    ID: '14.1.7',
    label:
      'Создание комнат обогрева для работающих в условиях воздействия пониженных температур',
  },
  {
    dependence: 'Заболевания вследствие переохлаждения организма',
    ID: '15.1.1',
    label: 'Использование оборотных циклов воды',
  },
  {
    dependence: 'Заболевания вследствие переохлаждения организма',
    ID: '15.1.2',
    label: 'Непрерывность механизации или автоматизации',
  },
  {
    dependence: 'Заболевания вследствие переохлаждения организма',
    ID: '15.1.3',
    label: 'Ограничение контакта работающих с водой и водными растворами',
  },
  {
    dependence: 'Заболевания вследствие переохлаждения организма',
    ID: '15.1.4',
    label:
      'Установка устройств для механического открывания и автоматического закрывания загрузочно-выгрузочных отверстий',
  },
  {
    dependence: 'Заболевания вследствие переохлаждения организма',
    ID: '15.1.5',
    label:
      'Оборудование устройств для визуального контроля и отбора проб, приспособлениями, обеспечивающими герметичность оборудования',
  },
  {
    dependence: 'Заболевания вследствие переохлаждения организма',
    ID: '15.1.6',
    label:
      'Обеспечение укрытиями с устройством систем вытяжной вентиляции оборудования, непосредственно используемого для организации технологического процесса, в котором используется вода и водные технологические растворы, которое не исключает поступление водных паров в рабочую зону, или реализация мероприятий, направленных на снижение поступления воды и водных паров в рабочую зону',
  },
  {
    dependence: 'Заболевания вследствие переохлаждения организма',
    ID: '15.1.7',
    label: 'Рациональное чередование режимов труда и отдыха',
  },
  {
    dependence: 'Заболевания вследствие переохлаждения организма',
    ID: '15.1.8',
    label: 'Применение вентиляции',
  },
  {
    dependence: 'Заболевания вследствие переохлаждения организма',
    ID: '15.1.9',
    label: 'Кондиционирование воздуха',
  },
  {
    dependence: 'Заболевания вследствие переохлаждения организма',
    ID: '15.1.10',
    label: 'Рациональное размещение оборудования',
  },
  {
    dependence: 'Заболевания вследствие переохлаждения организма',
    ID: '15.1.11',
    label: 'Работа с дистанционным управлением и наблюдением',
  },
  {
    dependence: 'Заболевания вследствие переохлаждения организма',
    ID: '15.1.12',
    label: 'Внедрение рациональных технологических процессов и оборудования',
  },
  {
    dependence: 'Заболевания вследствие переохлаждения организма',
    ID: '15.1.13',
    label: 'Применение СИЗ',
  },
  {
    dependence: 'Заболевания вследствие перегрева или переохлаждения организма',
    ID: '16.1.1',
    label: 'Кондиционирование воздуха',
  },
  {
    dependence: 'Заболевания вследствие перегрева или переохлаждения организма',
    ID: '16.1.2',
    label: 'Рациональное размещение оборудования',
  },
  {
    dependence: 'Заболевания вследствие перегрева или переохлаждения организма',
    ID: '16.1.3',
    label: 'Работа с дистанционным управлением и наблюдением',
  },
  {
    dependence: 'Заболевания вследствие перегрева или переохлаждения организма',
    ID: '16.1.4',
    label: 'Внедрение рациональных технологических процессов и оборудования',
  },
  {
    dependence: 'Заболевания вследствие перегрева или переохлаждения организма',
    ID: '16.1.5',
    label: 'Устройство защиты работающих с применением различных видов экранов',
  },
  {
    dependence: 'Заболевания вследствие перегрева или переохлаждения организма',
    ID: '16.1.6',
    label: 'Применение СИЗ',
  },
  {
    dependence:
      'Травмы вследствие воздействия высокой скорости движения воздуха',
    ID: '16.2.1',
    label: 'Устройство защиты работающих с применением различных видов экранов',
  },
  {
    dependence:
      'Травмы вследствие воздействия высокой скорости движения воздуха',
    ID: '16.2.2',
    label: 'Работа с дистанционным управлением и наблюдением',
  },
  {
    dependence:
      'Травмы вследствие воздействия высокой скорости движения воздуха',
    ID: '16.2.3',
    label: 'Применение СИЗ',
  },
  {
    dependence: 'Декомпрессионная болезнь, баротравмы легких',
    ID: '17.1.1',
    label:
      'Соблюдение длительности рабочего времени и продолжительности вышлюзования (декомпрессии).',
  },
  {
    dependence: 'Декомпрессионная болезнь, баротравмы легких',
    ID: '17.1.2',
    label:
      'Применение ступенчатой декомпрессии при проведении водолазных работ',
  },
  {
    dependence: 'Декомпрессионная болезнь, баротравмы легких',
    ID: '17.1.3',
    label:
      'Контроль количества и качества подаваемого в кессон сжатого воздуха',
  },
  {
    dependence: 'Декомпрессионная болезнь, баротравмы легких',
    ID: '17.1.4',
    label:
      'Предупреждение переохлаждения тела, приводящего к сужению сосудов и затруднению десатурации азота.',
  },
  {
    dependence: 'Декомпрессионная болезнь, баротравмы легких',
    ID: '17.1.5',
    label:
      'Контроль за исправным состоянием снаряжения, техники, технологического оборудования, инструмента и приспособлений',
  },
  {
    dependence: 'Декомпрессионная болезнь, баротравмы легких',
    ID: '17.1.6',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence: 'Декомпрессионная болезнь, баротравмы легких',
    ID: '17.1.7',
    label:
      'Исключение опасной работы, приостановка или запрет на производство работ',
  },
  {
    dependence: 'Декомпрессионная болезнь, баротравмы легких',
    ID: '17.1.8',
    label:
      'Выбор рационального (наиболее безопасного) метода проведения и способа выполнения работы',
  },
  {
    dependence: 'Декомпрессионная болезнь, баротравмы легких',
    ID: '17.1.9',
    label:
      'Ограничение времени воздействия неблагоприятных, опасных и вредных факторов на работников',
  },
  {
    dependence: 'Декомпрессионная болезнь, баротравмы легких',
    ID: '17.1.10',
    label:
      'Использование СИЗ и средств коллективной защиты, а также защитных устройств и приспособлений',
  },
  {
    dependence: 'Декомпрессионная болезнь, баротравмы легких',
    ID: '17.1.11',
    label:
      'Планирование и заблаговременная отработка действий в нештатных и аварийных ситуациях',
  },
  {
    dependence: 'Декомпрессионная болезнь, баротравмы легких',
    ID: '17.1.12',
    label:
      'Оборудование рабочих мест приспособлениями, устройствами, обеспечивающих защищенность, снижение или исключение воздействия вредных и опасных факторов на работников',
  },
  {
    dependence: 'Декомпрессионная болезнь, баротравмы легких',
    ID: '17.1.13',
    label:
      'Контроль за качеством подаваемого воздуха и дыхательных газовых смесей',
  },
  {
    dependence: 'Декомпрессионная болезнь, баротравмы легких',
    ID: '17.1.14',
    label: 'Рациональное чередование режимов труда и отдыха',
  },
  {
    dependence:
      'Заболевания, связанные с работой в условиях пониженного барометрического давления, обострение общих заболеваний вследствие пониженного барометрического давления',
    ID: '18.1.1',
    label:
      'Контроль за исправным состоянием снаряжения, техники, технологического оборудования, инструмента и приспособлений',
  },
  {
    dependence:
      'Заболевания, связанные с работой в условиях пониженного барометрического давления, обострение общих заболеваний вследствие пониженного барометрического давления',
    ID: '18.1.2',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Заболевания, связанные с работой в условиях пониженного барометрического давления, обострение общих заболеваний вследствие пониженного барометрического давления',
    ID: '18.1.3',
    label:
      'Исключение опасной работы, приостановка или запрет на производство работ',
  },
  {
    dependence:
      'Заболевания, связанные с работой в условиях пониженного барометрического давления, обострение общих заболеваний вследствие пониженного барометрического давления',
    ID: '18.1.4',
    label:
      'Выбор рационального (наиболее безопасного) метода проведения и способа выполнения работы',
  },
  {
    dependence:
      'Заболевания, связанные с работой в условиях пониженного барометрического давления, обострение общих заболеваний вследствие пониженного барометрического давления',
    ID: '18.1.5',
    label:
      'Ограничение времени воздействия неблагоприятных, опасных и вредных факторов на работников',
  },
  {
    dependence:
      'Заболевания, связанные с работой в условиях пониженного барометрического давления, обострение общих заболеваний вследствие пониженного барометрического давления',
    ID: '18.1.6',
    label:
      'Использование СИЗ и средств коллективной защиты, а также защитных устройств и приспособлений',
  },
  {
    dependence:
      'Заболевания, связанные с работой в условиях пониженного барометрического давления, обострение общих заболеваний вследствие пониженного барометрического давления',
    ID: '18.1.7',
    label:
      'Планирование и заблаговременная отработка действий в нештатных и аварийных ситуациях',
  },
  {
    dependence:
      'Заболевания, связанные с работой в условиях пониженного барометрического давления, обострение общих заболеваний вследствие пониженного барометрического давления',
    ID: '18.1.8',
    label:
      'Оборудование рабочих мест приспособлениями, устройствами, обеспечивающих защищенность, снижение или исключение воздействия вредных и опасных факторов на работников',
  },
  {
    dependence:
      'Заболевания, связанные с работой в условиях пониженного барометрического давления, обострение общих заболеваний вследствие пониженного барометрического давления',
    ID: '18.1.9',
    label: 'Рациональное чередование режимов труда и отдыха',
  },
  {
    dependence:
      'Баротравма, декомпрессионная болезнь, вызванные резким изменением барометрического давления',
    ID: '19.1.1',
    label:
      'Соблюдение длительности рабочего времени при выполнении работ в условиях измененного барометрического давления (водолазные работы) и продолжительности вышлюзования (декомпрессии)',
  },
  {
    dependence:
      'Баротравма, декомпрессионная болезнь, вызванные резким изменением барометрического давления',
    ID: '19.1.2',
    label:
      'Предупреждение переохлаждения тела, приводящего к сужению сосудов и затруднению десатурации азота',
  },
  {
    dependence:
      'Баротравма, декомпрессионная болезнь, вызванные резким изменением барометрического давления',
    ID: '19.1.3',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Баротравма, декомпрессионная болезнь, вызванные резким изменением барометрического давления',
    ID: '19.1.4',
    label:
      'Исключение опасной работы, приостановка или запрет на производство работ',
  },
  {
    dependence:
      'Баротравма, декомпрессионная болезнь, вызванные резким изменением барометрического давления',
    ID: '19.1.5',
    label:
      'Выбор рационального (наиболее безопасного) метода проведения и способа выполнения работы',
  },
  {
    dependence:
      'Баротравма, декомпрессионная болезнь, вызванные резким изменением барометрического давления',
    ID: '19.1.6',
    label:
      'Ограничение времени воздействия неблагоприятных, опасных и вредных факторов на работников',
  },
  {
    dependence:
      'Баротравма, декомпрессионная болезнь, вызванные резким изменением барометрического давления',
    ID: '19.1.7',
    label:
      'Использование СИЗ и средств коллективной защиты, а также защитных устройств и приспособлений',
  },
  {
    dependence:
      'Баротравма, декомпрессионная болезнь, вызванные резким изменением барометрического давления',
    ID: '19.1.8',
    label:
      'Планирование и заблаговременная отработка действий в нештатных и аварийных ситуациях',
  },
  {
    dependence:
      'Баротравма, декомпрессионная болезнь, вызванные резким изменением барометрического давления',
    ID: '19.1.9',
    label:
      'Оборудование рабочих мест приспособлениями, устройствами, обеспечивающими защищенность, снижение или исключение воздействия вредных и опасных факторов на работников',
  },
  {
    dependence:
      'Баротравма, декомпрессионная болезнь, вызванные резким изменением барометрического давления',
    ID: '19.1.10',
    label: 'Рациональное чередование режимов труда и отдыха',
  },
  {
    dependence:
      'Снижение остроты слуха, тугоухость, глухота, повреждение мембранной перепонки уха, связанные с воздействием повышенного уровня шума и других неблагоприятных характеристик шума',
    ID: '20.1.1',
    label:
      'Обозначение зон с эквивалентным уровнем звука выше гигиенических нормативов знаками безопасности',
  },
  {
    dependence:
      'Снижение остроты слуха, тугоухость, глухота, повреждение мембранной перепонки уха, связанные с воздействием повышенного уровня шума и других неблагоприятных характеристик шума',
    ID: '20.1.2',
    label:
      'Применение технологических процессов, машин и оборудования, характеризующихся более низкими уровнями шума',
  },
  {
    dependence:
      'Снижение остроты слуха, тугоухость, глухота, повреждение мембранной перепонки уха, связанные с воздействием повышенного уровня шума и других неблагоприятных характеристик шума',
    ID: '20.1.3',
    label: 'Применение дистанционного управления и автоматического контроля',
  },
  {
    dependence:
      'Снижение остроты слуха, тугоухость, глухота, повреждение мембранной перепонки уха, связанные с воздействием повышенного уровня шума и других неблагоприятных характеристик шума',
    ID: '20.1.4',
    label:
      'Применение звукоизолирующих ограждений-кожухов, кабин управления технологическим процессом',
  },
  {
    dependence:
      'Снижение остроты слуха, тугоухость, глухота, повреждение мембранной перепонки уха, связанные с воздействием повышенного уровня шума и других неблагоприятных характеристик шума',
    ID: '20.1.5',
    label: 'Устройство звукопоглощающих облицовок и объемных поглотителей шума',
  },
  {
    dependence:
      'Снижение остроты слуха, тугоухость, глухота, повреждение мембранной перепонки уха, связанные с воздействием повышенного уровня шума и других неблагоприятных характеристик шума',
    ID: '20.1.6',
    label:
      'Установка глушителей аэродинамического шума, создаваемого пневматическими ручными машинами, вентиляторами, компрессорными и другими технологическими установками',
  },
  {
    dependence:
      'Снижение остроты слуха, тугоухость, глухота, повреждение мембранной перепонки уха, связанные с воздействием повышенного уровня шума и других неблагоприятных характеристик шума',
    ID: '20.1.7',
    label:
      'Применение рациональных архитектурно-планировочных решений производственных зданий, помещений, а также расстановки технологического оборудования, машин и организации рабочих мест',
  },
  {
    dependence:
      'Снижение остроты слуха, тугоухость, глухота, повреждение мембранной перепонки уха, связанные с воздействием повышенного уровня шума и других неблагоприятных характеристик шума',
    ID: '20.1.8',
    label: 'Разработка и применение режимов труда и отдыха',
  },
  {
    dependence:
      'Снижение остроты слуха, тугоухость, глухота, повреждение мембранной перепонки уха, связанные с воздействием повышенного уровня шума и других неблагоприятных характеристик шума',
    ID: '20.1.9',
    label: 'Использование СИЗ',
  },
  {
    dependence:
      'События, связанные с возможностью не услышать звуковой сигнал об опасности',
    ID: '20.2.1',
    label:
      'Обозначение зон с эквивалентным уровнем звука выше гигиенических нормативов знаками безопасности',
  },
  {
    dependence:
      'События, связанные с возможностью не услышать звуковой сигнал об опасности',
    ID: '20.2.2',
    label:
      'Применение технологических процессов, машин и оборудования, характеризующихся более низкими уровнями шума',
  },
  {
    dependence:
      'События, связанные с возможностью не услышать звуковой сигнал об опасности',
    ID: '20.2.3',
    label: 'Применение дистанционного управления и автоматического контроля',
  },
  {
    dependence:
      'События, связанные с возможностью не услышать звуковой сигнал об опасности',
    ID: '20.2.4',
    label:
      'Применение звукоизолирующих ограждений-кожухов, кабин управления технологическим процессом',
  },
  {
    dependence:
      'События, связанные с возможностью не услышать звуковой сигнал об опасности',
    ID: '20.2.5',
    label: 'Устройство звукопоглощающих облицовок и объемных поглотителей шума',
  },
  {
    dependence:
      'События, связанные с возможностью не услышать звуковой сигнал об опасности',
    ID: '20.2.6',
    label:
      'Установка глушителей аэродинамического шума, создаваемого пневматическими ручными машинами, вентиляторами, компрессорными и другими технологическими установками',
  },
  {
    dependence:
      'События, связанные с возможностью не услышать звуковой сигнал об опасности',
    ID: '20.2.7',
    label:
      'Применение рациональных архитектурно-планировочных решений производственных зданий, помещений, а также расстановки технологического оборудования, машин и организации рабочих мест',
  },
  {
    dependence:
      'События, связанные с возможностью не услышать звуковой сигнал об опасности',
    ID: '20.2.8',
    label: 'Разработка и применение режимов труда и отдыха',
  },
  {
    dependence:
      'События, связанные с возможностью не услышать звуковой сигнал об опасности',
    ID: '20.2.9',
    label: 'Использование СИЗ',
  },
  {
    dependence:
      'События, связанные с возможностью не услышать звуковой сигнал об опасности',
    ID: '20.2.10',
    label:
      'Установка дополнительной визуальной (цветовой) сигнализации, указывающей об опасности',
  },
  {
    dependence:
      'Обусловленные воздействием ультразвука снижение уровня слуха (тугоухость), вегетососудистая дистония, астенический синдром',
    ID: '20.3.1',
    label:
      'Обозначение знаками безопасности зон с эквивалентным уровнем ультразвука выше гигиенических нормативов',
  },
  {
    dependence:
      'Обусловленные воздействием ультразвука снижение уровня слуха (тугоухость), вегетососудистая дистония, астенический синдром',
    ID: '20.3.2',
    label: 'Дистанционное управление источниками ультразвука',
  },
  {
    dependence:
      'Обусловленные воздействием ультразвука снижение уровня слуха (тугоухость), вегетососудистая дистония, астенический синдром',
    ID: '20.3.3',
    label: 'Применение автоматического контроля работы источников ультразвука',
  },
  {
    dependence:
      'Обусловленные воздействием ультразвука снижение уровня слуха (тугоухость), вегетососудистая дистония, астенический синдром',
    ID: '20.3.4',
    label:
      'Применение звукоизолирующих ограждений-кожухов, кабин управления технологическим процессом',
  },
  {
    dependence:
      'Обусловленные воздействием ультразвука снижение уровня слуха (тугоухость), вегетососудистая дистония, астенический синдром',
    ID: '20.3.5',
    label: 'Изоляция источников ультразвука',
  },
  {
    dependence:
      'Обусловленные воздействием ультразвука снижение уровня слуха (тугоухость), вегетососудистая дистония, астенический синдром',
    ID: '20.3.6',
    label:
      'Применение рациональных архитектурно-планировочных решений производственных зданий, помещений, а также расстановки технологического оборудования, машин и организации рабочих мест',
  },
  {
    dependence:
      'Обусловленные воздействием ультразвука снижение уровня слуха (тугоухость), вегетососудистая дистония, астенический синдром',
    ID: '20.3.7',
    label: 'Разработка и применение режимов труда и отдыха',
  },
  {
    dependence:
      'Обусловленные воздействием ультразвука снижение уровня слуха (тугоухость), вегетососудистая дистония, астенический синдром',
    ID: '20.3.8',
    label: 'Использование СИЗ',
  },
  {
    dependence:
      'Воздействие локальной вибрации на руки работника при использовании ручных механизмов (сужение сосудов, болезнь белых пальцев)',
    ID: '21.1.1',
    label:
      'Внесение конструктивных и технологических изменений в источник образования механических колебаний',
  },
  {
    dependence:
      'Воздействие локальной вибрации на руки работника при использовании ручных механизмов (сужение сосудов, болезнь белых пальцев)',
    ID: '21.1.2',
    label:
      'Использование средств вибропоглощения за счет применения пружинных и резиновых амортизаторов, прокладок',
  },
  {
    dependence:
      'Воздействие локальной вибрации на руки работника при использовании ручных механизмов (сужение сосудов, болезнь белых пальцев)',
    ID: '21.1.3',
    label: 'Использование СИЗ',
  },
  {
    dependence:
      'Воздействие локальной вибрации на руки работника при использовании ручных механизмов (сужение сосудов, болезнь белых пальцев)',
    ID: '21.1.4',
    label:
      'Применение вибробезопасного оборудования, виброизолирующих, виброгасящих и вибропоглощающих устройств, обеспечивающих снижение уровня вибрации',
  },
  {
    dependence:
      'Воздействие локальной вибрации на руки работника при использовании ручных механизмов (сужение сосудов, болезнь белых пальцев)',
    ID: '21.1.5',
    label:
      'Организация обязательных перерывов в работе (ограничение длительного непрерывного воздействия вибрации)',
  },
  {
    dependence: 'Воздействие общей вибрации на тело работника',
    ID: '21.2.1',
    label:
      'Уменьшение вибрации на пути распространения средствами виброизоляции и вибропоглощения, применения дистанционного или автоматического управления',
  },
  {
    dependence: 'Воздействие общей вибрации на тело работника',
    ID: '21.2.2',
    label:
      'Конструирование и изготовление оборудования, создающего вибрацию, в комплекте с виброизоляторами',
  },
  {
    dependence: 'Воздействие общей вибрации на тело работника',
    ID: '21.2.3',
    label:
      'Использование машин и оборудования в соответствии с их назначением, предусмотренным нормативно-технической документацией',
  },
  {
    dependence: 'Воздействие общей вибрации на тело работника',
    ID: '21.2.4',
    label:
      'Исключение контакта работающих с вибрирующими поверхностями за пределами рабочего места или рабочей зоны',
  },
  {
    dependence: 'Воздействие общей вибрации на тело работника',
    ID: '21.2.5',
    label:
      'Запрет пребывания на вибрирующей поверхности производственного оборудования во время его работы',
  },
  {
    dependence: 'Воздействие общей вибрации на тело работника',
    ID: '21.2.6',
    label:
      'Своевременный ремонт машин и оборудования (с балансировкой движущихся частей), проверкой крепления агрегатов к полу, фундаменту, строительным конструкциям с последующим лабораторным контролем вибрационных характеристик',
  },
  {
    dependence: 'Воздействие общей вибрации на тело работника',
    ID: '21.2.7',
    label:
      'Своевременный ремонт путей, поверхностей для перемещения машин, поддерживающих конструкций',
  },
  {
    dependence: 'Воздействие общей вибрации на тело работника',
    ID: '21.2.8',
    label:
      'Установка стационарного оборудования на отдельные фундаменты и поддерживающие конструкции зданий и сооружений',
  },
  {
    dependence: 'Воздействие общей вибрации на тело работника',
    ID: '21.2.9',
    label:
      'Ограничение времени воздействия на работника уровней вибрации, превышающих гигиенические нормативы',
  },
  {
    dependence: 'Воздействие общей вибрации на тело работника',
    ID: '21.2.10',
    label:
      'Организация обязательных перерывов в работе (ограничение длительного непрерывного воздействия вибрации)',
  },
  {
    dependence: 'Воздействие общей вибрации на тело работника',
    ID: '21.2.11',
    label: 'Применение вибропоглощения и виброизоляции',
  },
  {
    dependence:
      'Удар работника или падение на работника предмета, тяжелого инструмента или груза, упавшего при перемещении или подъеме',
    ID: '22.1.1',
    label:
      'Повышение уровня механизации и автоматизации, использование современной высокопроизводительной техники (применение приборов, машин, приспособлений, позволяющих осуществлять производственные процессы без физических усилий человека, лишь под его контролем)',
  },
  {
    dependence:
      'Удар работника или падение на работника предмета, тяжелого инструмента или груза, упавшего при перемещении или подъеме',
    ID: '22.1.2',
    label:
      'Исключение веса груза, превышающего грузоподъемность средства его перемещения (разделение на несколько операций с менее тяжелым грузом)',
  },
  {
    dependence:
      'Удар работника или падение на работника предмета, тяжелого инструмента или груза, упавшего при перемещении или подъеме',
    ID: '22.1.3',
    label:
      'Оптимальная логистика, организация небольшого промежуточного склада наиболее коротких удобных путей переноса груза',
  },
  {
    dependence:
      'Удар работника или падение на работника предмета, тяжелого инструмента или груза, упавшего при перемещении или подъеме',
    ID: '22.1.4',
    label:
      'Соблюдение эргономических характеристик рабочего места (благоприятные позы и эффективные движения)',
  },
  {
    dependence:
      'Удар работника или падение на работника предмета, тяжелого инструмента или груза, упавшего при перемещении или подъеме',
    ID: '22.1.5',
    label:
      'Обеспечение безопасных условий труда (ровный нескользкий пол, достаточная видимость, удобная одежда, обувь)',
  },
  {
    dependence:
      'Удар работника или падение на работника предмета, тяжелого инструмента или груза, упавшего при перемещении или подъеме',
    ID: '22.1.6',
    label:
      'Снижение темпа работы, достаточное время восстановления, смена стрессовой деятельности на более спокойную (соблюдение режима труда и отдыха, графиков сменности)',
  },
  {
    dependence:
      'Повреждение костно-мышечного аппарата работника при физических перегрузках',
    ID: '23.1.1',
    label: 'Проведение инструктажа на рабочем месте',
  },
  {
    dependence:
      'Повреждение костно-мышечного аппарата работника при физических перегрузках',
    ID: '23.1.2',
    label:
      'Улучшение организации работы (изменение рабочей позы (стоя/сидя), чередование рабочих поз)',
  },
  {
    dependence:
      'Повреждение костно-мышечного аппарата работника при физических перегрузках',
    ID: '23.1.3',
    label: 'Применение механизированных, подручных средств',
  },
  {
    dependence:
      'Повреждение костно-мышечного аппарата работника при физических перегрузках',
    ID: '23.1.4',
    label:
      'Соблюдение требований государственных стандартов, исключение нарушений основных требований эргономики',
  },
  {
    dependence:
      'Повреждение костно-мышечного аппарата работника при физических перегрузках',
    ID: '23.1.5',
    label: 'Соблюдение режимов труда и отдыха',
  },
  {
    dependence:
      'Повреждение костно-мышечного аппарата работника при физических перегрузках',
    ID: '23.1.6',
    label:
      'Организация рабочего места для наиболее безопасного и эффективного труда работника, исходя из физических и психических особенностей человека',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.1.1',
    label: 'Обогащение рабочих задач',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.1.2',
    label: 'Чередование вида работ',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.1.3',
    label:
      'Сочетание решение умственно сложных задач с монотонной деятельностью',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.1.4',
    label: 'Автоматизация, механизация или изменение вида деятельности',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.1.5',
    label:
      'Проведение специальной оценки условий труда с разработкой и реализацией мероприятий по снижению напряженности трудового процесса',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.2.1',
    label: 'Организация предварительного уведомления о требованиях к работе',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.2.2',
    label: 'Разделение нового вида работы на несколько сотрудников',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.2.3',
    label: 'Обеспечить координацию с начальством и подчиненными',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.2.4',
    label: 'Соблюдение эргономических характеристик рабочего места',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.2.5',
    label: 'Организация обучения по новому виду работы',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.2.6',
    label: 'Соблюдение эргономических характеристик рабочего места',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.2.7',
    label: 'Проведение целевого инструктажа',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.2.8',
    label: 'Назначение ответственного лица за выполнение работ',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.3.1',
    label: 'Обеспечение равного распределения задач',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.3.2',
    label: 'Обеспечение четкого распределения задач и ролей',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.3.3',
    label: 'Поручение достижимых целей',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.3.4',
    label: 'Планирование регулярных встреч коллектива',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.3.5',
    label: 'Оперативное разрешение конфликтов',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.3.6',
    label: 'Организация повышения квалификации',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.3.7',
    label: 'Формирование взаимного уважения',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.4.1',
    label: 'Чередование видов работ',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.4.2',
    label: 'Соблюдение режима труда и отдыха',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.4.3',
    label: 'Соблюдение эргономических характеристик рабочего места',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.4.4',
    label: 'Обеспечение достаточной видимости и восприятия информации',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.4.5',
    label: 'Приобретение дополнительных средств для комфортной работы',
  },
  {
    dependence: 'Психоэмоциональные перегрузки',
    ID: '24.4.6',
    label:
      'Проведение специальной оценки условий труда с разработкой и реализацией мероприятий по снижению напряженности трудового процесса',
  },
  {
    dependence: 'Укус животного',
    ID: '25.1.1',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence: 'Укус животного',
    ID: '25.1.2',
    label:
      'Размещение у помещений с опасными животными шумовых отпугивающих средств и необходимого инвентаря',
  },
  {
    dependence: 'Укус животного',
    ID: '25.1.3',
    label: 'Размещение плакатов (табличек) с предупредительными надписями',
  },
  {
    dependence: 'Укус животного',
    ID: '25.1.4',
    label:
      'Допуск к уходу за животными работников, обученных безопасным методам обращения с ними',
  },
  {
    dependence: 'Укус животного',
    ID: '25.1.5',
    label:
      'Кормление животных и уборка их помещений с помощью специального инвентаря, который поддерживается исправным, является легким, удобным и достаточно длинным, позволяющим достать любую точку пола клетки животного, не подходя вплотную к решетке или сетке',
  },
  {
    dependence: 'Укус животного',
    ID: '25.1.6',
    label:
      'Использование СИЗ и средств коллективной защиты, а также защитных устройств и приспособлений',
  },
  {
    dependence: 'Укус животного',
    ID: '25.1.7',
    label: 'Исключение опасной работы (процедуры)',
  },
  {
    dependence: 'Укус животного',
    ID: '25.1.8',
    label: 'Применение дистанционного управления и автоматического контроля',
  },
  {
    dependence: 'Укус животного',
    ID: '25.1.9',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Укус животного',
    ID: '25.1.10',
    label:
      'Оснащение рабочих мест (зон) аптечками с набором профилактических средств',
  },
  {
    dependence: 'Травма, нанесенная зубами и когтями животного',
    ID: '25.2.1',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence: 'Травма, нанесенная зубами и когтями животного',
    ID: '25.2.2',
    label:
      'Размещение у помещений с опасными животными шумовых отпугивающих средств и необходимого инвентаря',
  },
  {
    dependence: 'Травма, нанесенная зубами и когтями животного',
    ID: '25.2.3',
    label: 'Размещение плакатов (табличек) с предупредительными надписями',
  },
  {
    dependence: 'Травма, нанесенная зубами и когтями животного',
    ID: '25.2.4',
    label:
      'Допуск к уходу за животными работников, обученных безопасным методам обращения с ними',
  },
  {
    dependence: 'Травма, нанесенная зубами и когтями животного',
    ID: '25.2.5',
    label:
      'Кормление животных и уборка их помещений с помощью специального инвентаря, который поддерживается исправным, является легким, удобным и достаточно длинным, позволяющим достать любую точку пола клетки животного, не подходя вплотную к решетке или сетке',
  },
  {
    dependence: 'Травма, нанесенная зубами и когтями животного',
    ID: '25.2.6',
    label:
      'Использование СИЗ и средств коллективной защиты, а также защитных устройств и приспособлений',
  },
  {
    dependence: 'Травма, нанесенная зубами и когтями животного',
    ID: '25.2.7',
    label: 'Исключение опасной работы (процедуры)',
  },
  {
    dependence: 'Травма, нанесенная зубами и когтями животного',
    ID: '25.2.8',
    label: 'Применение дистанционного управления и автоматического контроля',
  },
  {
    dependence: 'Травма, нанесенная зубами и когтями животного',
    ID: '25.2.9',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Травма, нанесенная зубами и когтями животного',
    ID: '25.2.10',
    label:
      'Оснащение рабочих мест (зон) аптечками с набором профилактических средств',
  },
  {
    dependence: 'Раздавливание животным',
    ID: '25.3.1',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence: 'Раздавливание животным',
    ID: '25.3.2',
    label:
      'Размещение у помещений с опасными животными шумовых отпугивающих средств и необходимого инвентаря',
  },
  {
    dependence: 'Раздавливание животным',
    ID: '25.3.3',
    label: 'Размещение плакатов (табличек) с предупредительными надписями',
  },
  {
    dependence: 'Раздавливание животным',
    ID: '25.3.4',
    label:
      'Допуск к уходу за животными работников, обученных безопасным методам обращения с ними',
  },
  {
    dependence: 'Раздавливание животным',
    ID: '25.3.5',
    label:
      'Кормление животных и уборка их помещений с помощью специального инвентаря, который поддерживается исправным, является легким, удобным и достаточно длинным, позволяющим достать любую точку пола клетки животного, не подходя вплотную к решетке или сетке',
  },
  {
    dependence: 'Раздавливание животным',
    ID: '25.3.6',
    label:
      'Использование СИЗ и средств коллективной защиты, а также защитных устройств и приспособлений',
  },
  {
    dependence: 'Раздавливание животным',
    ID: '25.3.7',
    label: 'Исключение опасной работы (процедуры)',
  },
  {
    dependence: 'Раздавливание животным',
    ID: '25.3.8',
    label: 'Применение дистанционного управления и автоматического контроля',
  },
  {
    dependence: 'Раздавливание животным',
    ID: '25.3.9',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Раздавливание животным',
    ID: '25.3.10',
    label:
      'Оснащение рабочих мест (зон) аптечками с набором профилактических средств',
  },
  {
    dependence: 'Заражение животным',
    ID: '25.4.1',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence: 'Заражение животным',
    ID: '25.4.2',
    label:
      'Размещение у помещений с опасными животными шумовых отпугивающих средств и необходимого инвентаря',
  },
  {
    dependence: 'Заражение животным',
    ID: '25.4.3',
    label: 'Размещение плакатов (табличек) с предупредительными надписями',
  },
  {
    dependence: 'Заражение животным',
    ID: '25.4.4',
    label:
      'Допуск к уходу за животными работников, обученных безопасным методам обращения с ними',
  },
  {
    dependence: 'Заражение животным',
    ID: '25.4.5',
    label:
      'Кормление животных и уборка их помещений с помощью специального инвентаря, который поддерживается исправным, является легким, удобным и достаточно длинным, позволяющим достать любую точку пола клетки животного, не подходя вплотную к решетке или сетке',
  },
  {
    dependence: 'Заражение животным',
    ID: '25.4.6',
    label:
      'Использование СИЗ и средств коллективной защиты, а также защитных устройств и приспособлений',
  },
  {
    dependence: 'Заражение животным',
    ID: '25.4.7',
    label: 'Исключение опасной работы (процедуры)',
  },
  {
    dependence: 'Заражение животным',
    ID: '25.4.8',
    label: 'Применение дистанционного управления и автоматического контроля',
  },
  {
    dependence: 'Заражение животным',
    ID: '25.4.9',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Заражение животным',
    ID: '25.4.10',
    label:
      'Соблюдение правил личной гигиены, содержание в чистоте рабочего места, инвентаря, оборудования',
  },
  {
    dependence: 'Заражение животным',
    ID: '25.4.11',
    label:
      'После работы с инфицированными или подозрительными на заражение животными, а также трупом животного проведение дезинфекции рук 0,5% раствором хлорамина с последующим мытьем рук теплой водой с мылом',
  },
  {
    dependence: 'Заражение животным',
    ID: '25.4.12',
    label:
      'Оснащение рабочих мест (зон) аптечками с набором профилактических средств',
  },
  {
    dependence: 'Нападение животного',
    ID: '25.5.1',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence: 'Нападение животного',
    ID: '25.5.2',
    label:
      'Размещение у помещений с опасными животными шумовых отпугивающих средств и необходимого инвентаря.',
  },
  {
    dependence: 'Нападение животного',
    ID: '25.5.3',
    label: 'Размещение плакатов (табличек) с предупредительными надписями',
  },
  {
    dependence: 'Нападение животного',
    ID: '25.5.4',
    label:
      'Допуск к уходу за животными работников, обученных безопасным методам обращения с ними',
  },
  {
    dependence: 'Нападение животного',
    ID: '25.5.5',
    label:
      'Кормление животных и уборка их помещений с помощью специального инвентаря, который поддерживается исправным, является легким, удобным и достаточно длинным, позволяющим достать любую точку пола клетки животного, не подходя вплотную к решетке или сетке',
  },
  {
    dependence: 'Нападение животного',
    ID: '25.5.6',
    label:
      'Использование СИЗ и средств коллективной защиты, а также защитных устройств и приспособлений',
  },
  {
    dependence: 'Нападение животного',
    ID: '25.5.7',
    label: 'Исключение опасной работы (процедуры)',
  },
  {
    dependence: 'Нападение животного',
    ID: '25.5.8',
    label: 'Применение дистанционного управления и автоматического контроля',
  },
  {
    dependence: 'Нападение животного',
    ID: '25.5.9',
    label:
      'Оснащение рабочих мест (зон) аптечками с набором профилактических средств',
  },
  {
    dependence: 'Нападение животного',
    ID: '25.5.10',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Отравление ядами животного происхождения',
    ID: '25.6.1',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence: 'Отравление ядами животного происхождения',
    ID: '25.6.2',
    label:
      'Размещение у помещений с опасными животными шумовых отпугивающих средств и необходимого инвентаря.',
  },
  {
    dependence: 'Отравление ядами животного происхождения',
    ID: '25.6.3',
    label: 'Размещение плакатов (табличек) с предупредительными надписями',
  },
  {
    dependence: 'Отравление ядами животного происхождения',
    ID: '25.6.4',
    label:
      'Допуск к уходу за животными работников, обученных безопасным методам обращения с ними',
  },
  {
    dependence: 'Отравление ядами животного происхождения',
    ID: '25.6.5',
    label:
      'Кормление животных и уборка их помещений с помощью специального инвентаря, который поддерживается исправным, является легким, удобным и достаточно длинным, позволяющим достать любую точку пола клетки животного, не подходя вплотную к решетке или сетке',
  },
  {
    dependence: 'Отравление ядами животного происхождения',
    ID: '25.6.6',
    label:
      'Использование СИЗ и средств коллективной защиты, а также защитных устройств и приспособлений',
  },
  {
    dependence: 'Отравление ядами животного происхождения',
    ID: '25.6.7',
    label: 'Исключение опасной работы (процедуры)',
  },
  {
    dependence: 'Отравление ядами животного происхождения',
    ID: '25.6.8',
    label: 'Применение дистанционного управления и автоматического контроля',
  },
  {
    dependence: 'Отравление ядами животного происхождения',
    ID: '25.6.9',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Отравление ядами животного происхождения',
    ID: '25.6.10',
    label:
      'Соблюдение правил личной гигиены, содержание в чистоте рабочего места, инвентаря, оборудования',
  },
  {
    dependence: 'Отравление ядами животного происхождения',
    ID: '25.6.11',
    label:
      'После работы с инфицированными или подозрительными на заражение животными, а также трупом животного проведение дезинфекции рук 0,5% раствором хлорамина с последующим мытьем рук теплой водой с мылом',
  },
  {
    dependence: 'Отравление ядами животного происхождения',
    ID: '25.6.12',
    label:
      'Оснащение рабочих мест (зон) аптечками с набором профилактических средств',
  },
  {
    dependence: 'Воздействие выделений животного',
    ID: '25.7.1',
    label:
      'Организации первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence: 'Воздействие выделений животного',
    ID: '25.7.2',
    label:
      'Размещение у помещений с опасными животными шумовых отпугивающих средств и необходимого инвентаря',
  },
  {
    dependence: 'Воздействие выделений животного',
    ID: '25.7.3',
    label: 'Размещение плакатов (табличек) с предупредительными надписями',
  },
  {
    dependence: 'Воздействие выделений животного',
    ID: '25.7.4',
    label:
      'Допуск к уходу за животными работников, обученных безопасным методам обращения с ними',
  },
  {
    dependence: 'Воздействие выделений животного',
    ID: '25.7.5',
    label:
      'Кормление животных и уборка их помещений с помощью специального инвентаря, который поддерживается исправным, является легким, удобным и достаточно длинным, позволяющим достать любую точку пола клетки животного, не подходя вплотную к решетке или сетке',
  },
  {
    dependence: 'Воздействие выделений животного',
    ID: '25.7.6',
    label:
      'Использование СИЗ и средств коллективной защиты, а также защитных устройств и приспособлений',
  },
  {
    dependence: 'Воздействие выделений животного',
    ID: '25.7.7',
    label: 'Исключение опасной работы (процедуры)',
  },
  {
    dependence: 'Воздействие выделений животного',
    ID: '25.7.8',
    label: 'Применение дистанционного управления и автоматического контроля',
  },
  {
    dependence: 'Воздействие выделений животного',
    ID: '25.7.9',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Воздействие выделений животного',
    ID: '25.7.10',
    label:
      'Соблюдение правил личной гигиены, содержание в чистоте рабочего места, инвентаря, оборудования',
  },
  {
    dependence: 'Воздействие выделений животного',
    ID: '25.7.11',
    label:
      'После работы с инфицированными или подозрительными на заражение животными, а также трупом животного проведение дезинфекции рук 0,5% раствором хлорамина с последующим мытьем рук теплой водой с мылом',
  },
  {
    dependence: 'Воздействие выделений животного',
    ID: '25.7.12',
    label:
      'Оснащение рабочих мест (зон) аптечками с набором профилактических средств',
  },
  {
    dependence:
      'Аллергическая реакция, вызванная укусом насекомого или паукообразного, отравление при попадании в организм при укусе яда насекомого или паукообразного',
    ID: '26.1.1',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence:
      'Аллергическая реакция, вызванная укусом насекомого или паукообразного, отравление при попадании в организм при укусе яда насекомого или паукообразного',
    ID: '26.1.2',
    label:
      'Использование СИЗ и средств коллективной защиты, а также защитных устройств и приспособлений',
  },
  {
    dependence:
      'Аллергическая реакция, вызванная укусом насекомого или паукообразного, отравление при попадании в организм при укусе яда насекомого или паукообразного',
    ID: '26.1.3',
    label: 'Исключение опасной работы (процедуры)',
  },
  {
    dependence:
      'Аллергическая реакция, вызванная укусом насекомого или паукообразного, отравление при попадании в организм при укусе яда насекомого или паукообразного',
    ID: '26.1.4',
    label: 'Применение дистанционного управления и автоматического контроля',
  },
  {
    dependence:
      'Аллергическая реакция, вызванная укусом насекомого или паукообразного, отравление при попадании в организм при укусе яда насекомого или паукообразного',
    ID: '26.1.5',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence:
      'Аллергическая реакция, вызванная укусом насекомого или паукообразного, отравление при попадании в организм при укусе яда насекомого или паукообразного',
    ID: '26.1.6',
    label:
      'Соблюдение правил личной гигиены, содержание в чистоте рабочего места, инвентаря, оборудования',
  },
  {
    dependence:
      'Аллергическая реакция, вызванная укусом насекомого или паукообразного, отравление при попадании в организм при укусе яда насекомого или паукообразного',
    ID: '26.1.7',
    label:
      'Оснащение рабочих мест (зон) аптечками с набором профилактических средств',
  },
  {
    dependence: 'Попадание в организм насекомого или паукообразного',
    ID: '26.2.1',
    label:
      'Организация первичного и периодического обучения работников безопасным методам и приемам выполнения работ, проведение соответствующих стажировок, инструктажей и проверок знаний по охране труда',
  },
  {
    dependence: 'Попадание в организм насекомого или паукообразного',
    ID: '26.2.2',
    label:
      'Использование СИЗ и средств коллективной защиты, а также защитных устройств и приспособлений',
  },
  {
    dependence: 'Попадание в организм насекомого или паукообразного',
    ID: '26.2.3',
    label: 'Исключение опасной работы (процедуры)',
  },
  {
    dependence: 'Попадание в организм насекомого или паукообразного',
    ID: '26.2.4',
    label: 'Применение дистанционного управления и автоматического контроля',
  },
  {
    dependence: 'Попадание в организм насекомого или паукообразного',
    ID: '26.2.5',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence: 'Попадание в организм насекомого или паукообразного',
    ID: '26.2.6',
    label:
      'Соблюдение правил личной гигиены, содержание в чистоте рабочего места, инвентаря, оборудования',
  },
  {
    dependence: 'Попадание в организм насекомого или паукообразного',
    ID: '26.2.7',
    label:
      'Оснащение рабочих мест (зон) аптечками с набором профилактических средств',
  },
  {
    dependence:
      'Заражение инфекционным заболеванием или гельминтозом (паразитическими червями) через укусы кровососущих насекомых или паукообразных',
    ID: '26.3.1',
    label:
      'Оснащение рабочих мест (зон) аптечками с набором профилактических средств',
  },
  {
    dependence:
      'Заражение инфекционным заболеванием или гельминтозом (паразитическими червями) через укусы кровососущих насекомых или паукообразных',
    ID: '26.3.2',
    label:
      'Использование СИЗ и средств коллективной защиты, а также защитных устройств и приспособлений',
  },
  {
    dependence:
      'Заражение инфекционным заболеванием или гельминтозом (паразитическими червями) через укусы кровососущих насекомых или паукообразных',
    ID: '26.3.3',
    label: 'Исключение опасной работы (процедуры)',
  },
  {
    dependence:
      'Заражение инфекционным заболеванием или гельминтозом (паразитическими червями) через укусы кровососущих насекомых или паукообразных',
    ID: '26.3.4',
    label: 'Применение дистанционного управления и автоматического контроля',
  },
  {
    dependence:
      'Заражение инфекционным заболеванием или гельминтозом (паразитическими червями) через укусы кровососущих насекомых или паукообразных',
    ID: '26.3.5',
    label: 'Механизация и автоматизация процессов',
  },
  {
    dependence:
      'Заражение инфекционным заболеванием или гельминтозом (паразитическими червями) через укусы кровососущих насекомых или паукообразных',
    ID: '26.3.6',
    label:
      'Соблюдение правил личной гигиены, содержание в чистоте рабочего места, инвентаря, оборудования',
  },
  {
    dependence:
      'Контакт с частями электрооборудования, находящимися под напряжением',
    ID: '27.1.1',
    label:
      'Изоляция токоведущих частей электрооборудования, применение СИЗ, соблюдение требований охраны труда, применение ограждений, сигнальных цветов, табличек, указателей и знаков безопасности',
  },
  {
    dependence: 'Отсутствие заземления или неисправность электрооборудования',
    ID: '27.2.1',
    label:
      'Вывод неисправного электрооборудования из эксплуатации, своевременный ремонт и техническое обслуживание электрооборудования, применение ограждений, сигнальных цветов, табличек, указателей и знаков безопасности',
  },
  {
    dependence:
      'Нарушение правил эксплуатации и ремонта электрооборудования, неприменение СИЗ',
    ID: '27.3.1',
    label:
      'Применение СИЗ, соблюдение требований охраны труда, вывод неисправного электрооборудования из эксплуатации, своевременный ремонт и техническое обслуживание электрооборудования, применение ограждений, сигнальных цветов, табличек, указателей и знаков безопасности',
  },
  {
    dependence: 'Воздействие электрической дуги',
    ID: '27.4.1',
    label: 'Применение СИЗ, соблюдение требований охраны труда',
  },
  {
    dependence: 'Поражение электрическим током',
    ID: '27.5.1',
    label: 'Применение СИЗ, соблюдение требований охраны труда',
  },
  {
    dependence:
      'Ожог, пожар или взрыв при искровом зажигании взрыво-пожароопасной среды',
    ID: '27.6.1',
    label: 'Применение СИЗ, соблюдение требований охраны труда',
  },
  {
    dependence:
      'Ожог, пожар или взрыв при искровом зажигании взрыво-пожароопасной среды',
    ID: '27.6.2',
    label:
      'Применение знаков безопасности, исключение источников новообразования во взрыво-пожароопасной среде',
  },
  {
    dependence: 'Поражение электрическим током',
    ID: '27.7.1',
    label:
      'Применение СИЗ, соблюдение требований охраны труда, вывод неисправного электрооборудования из эксплуатации, своевременный ремонт и техническое обслуживание электрооборудования, применение ограждений, сигнальных цветов, табличек, указателей и знаков безопасности',
  },
  {
    dependence: 'Психофизическая нагрузка',
    ID: '28.1.1',
    label: 'Исключение нежелательных контактов при выполнении работ',
  },
  {
    dependence: 'Психофизическая нагрузка',
    ID: '28.1.2',
    label: 'Определение задач и ответственности',
  },
  {
    dependence: 'Психофизическая нагрузка',
    ID: '28.1.3',
    label: 'Учет, анализ и оценка инцидентов',
  },
  {
    dependence: 'Психофизическая нагрузка',
    ID: '28.1.4',
    label: 'Пространственное разделение',
  },
  {
    dependence: 'Психофизическая нагрузка',
    ID: '28.1.5',
    label:
      'Достаточное для выполнения работы и не раздражающее по яркости освещение',
  },
  {
    dependence: 'Психофизическая нагрузка',
    ID: '28.1.6',
    label:
      'Организация видеонаблюдения за рабочей зоной и устройство сигнализации ("тревожные кнопки")',
  },
  {
    dependence: 'Психофизическая нагрузка',
    ID: '28.1.7',
    label: 'Обучение сотрудников методам выхода из конфликтных ситуаций',
  },
  {
    dependence: 'Психофизическая нагрузка',
    ID: '28.1.8',
    label:
      'Защита доступа к особо ценным вещам, документам, в том числе с применением темпокасс',
  },
  {
    dependence: 'Психофизическая нагрузка',
    ID: '28.1.9',
    label: 'Прохождение обучения по оказанию первой помощи',
  },
  {
    dependence: 'Психофизическая нагрузка',
    ID: '28.1.10',
    label:
      'Исключение одиночной работы, мониторинг (постоянный или периодический через заданное время) с контактом с одиночными работниками',
  },
];
export default riskManagement;
